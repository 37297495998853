import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_COMPANY_USERS,
    URL_CONNECT_COMPANY_USERS_STATUS
} from "../../../constants/Urls";
import {
    CONNECT_API_CALL_REQUEST,
    CONNECT_API_CALL_SUCCESS,
    CONNECT_API_CALL_FAILURE, CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS, CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE, CONNECT_USERS_UUID_STATUS_REQUEST
} from '../../../constants/ActionTypes';

// function that makes the api request and returns a Promise for response
const fetchConnectClient = async ({payload}) =>
    await axios.post(baseUrl + URL_CONNECT_COMPANY_USERS, payload, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/** http : connect users uuid status **/
const fetchConnectClientStatus = async ({payload}) =>
    await axios.post(baseUrl + URL_CONNECT_COMPANY_USERS_STATUS, payload, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


// worker saga: makes the api call when watcher saga sees the action
export function* connectGetClientSaga(payload) {
    try {
        const response = yield call(fetchConnectClient, payload);
        const {
            data,
            message
        } = response;
        if (data) {
            yield put({type: CONNECT_API_CALL_SUCCESS, payload: data});
        }
        if (message) {
            yield put({type: CONNECT_API_CALL_FAILURE, error: message});
        }

    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({type: CONNECT_API_CALL_FAILURE, error});
    }
};

/** handler : connect users uuid status **/
export function* sagaConnectUsersUUIDStatus(payload) {
    try {
        const response = yield call(fetchConnectClientStatus, payload);
        const { data, message } = response;

        if(data) {
            yield put({ type: CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS, payload:data });
        }
        if(message) {
            yield put({ type: CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE, error:message });
        }

    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE, error });
    }
}


// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getConnectClient() {
    yield takeEvery(CONNECT_API_CALL_REQUEST, connectGetClientSaga);
}

/** request : connect users uuid status **/
export function* getConnectClientStatus() {
    yield takeEvery(CONNECT_USERS_UUID_STATUS_REQUEST, sagaConnectUsersUUIDStatus);
}

