import {
    COUNTRY_GET_ALL_COUNTRIES,
    COUNTRY_GET_ALL_COUNTRIES_ERROR,
    COUNTRY_GET_ALL_COUNTRIES_NONE,
    COUNTRY_GET_ALL_COUNTRIES_SUCCESS,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS,
    WOVO_CLIENTS_GET_PAGINATED_LIST,
    WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR,
    WOVO_CLIENTS_GET_PAGINATED_LIST_NONE,
    WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS,
    WOVO_CLIENTS_SAVE_CLIENT,
    WOVO_CLIENTS_SAVE_CLIENT_ERROR,
    WOVO_CLIENTS_SAVE_CLIENT_NONE,
    WOVO_CLIENTS_SAVE_CLIENT_SUCCESS,
    WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT,
    WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_ERROR,
    WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_NONE,
    WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_SUCCESS
} from "../../constants/actions/clients/actionTypesWoVoClients";

/****
 * wovo clients  : to get paginated list
 ****/

export function wovoClientsGetPaginatedList(params) {
    return {
        type: WOVO_CLIENTS_GET_PAGINATED_LIST,
        payload: params
    };
}

/****
 * wovo clients  : to get paginated list : success
 ****/
export function wovoClientsGetPaginatedListSuccess(params) {
    return {
        type: WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS,
        payload: params
    };
}

/****
 * wovo clients  : to get paginated list : fail
 ****/
export function wovoClientsGetPaginatedListError(params) {
    return {
        type: WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR,
        payload: params
    };
}

/****
 * wovo clients  : to get paginated list : none
 ****/
export function wovoClientsGetPaginatedListNone() {
    return {
        type: WOVO_CLIENTS_GET_PAGINATED_LIST_NONE
    };
}

/****
 * wovo clients  : to set selected view client
 ****/

export function wovoSetSelectedViewClient(params) {
    return {
        type: WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT,
        payload: params
    };
}

/****
 * wovo clients  : to set selected view client : success
 ****/
export function wovoSetSelectedViewClientSuccess(params) {
    return {
        type: WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_SUCCESS,
        payload: params
    };
}

/****
 * wovo clients  : to set selected view client : fail
 ****/
export function wovoSetSelectedViewClientError(params) {
    return {
        type: WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_ERROR,
        payload: params
    };
}

/****
 * wovo clients  : to set selected view client : none
 ****/
export function wovoSetSelectedViewClientNone() {
    return {
        type: WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_NONE
    };
}

/****
 * wovo clients  : to save client
 ****/

export function wovoClientsSaveClient(params) {
    return {
        type: WOVO_CLIENTS_SAVE_CLIENT,
        payload: params
    };
}

/****
 * wovo clients  : to save client : success
 ****/
export function wovoClientsSaveClientSuccess(params) {
    return {
        type: WOVO_CLIENTS_SAVE_CLIENT_SUCCESS,
        payload: params
    };
}

/****
 * wovo clients  : to save client : fail
 ****/
export function wovoClientsSaveClientError(params) {
    return {
        type: WOVO_CLIENTS_SAVE_CLIENT_ERROR,
        payload: params
    };
}

/****
 * wovo clients  : to save client : none
 ****/
export function wovoClientsSaveClientNone() {
    return {
        type: WOVO_CLIENTS_SAVE_CLIENT_NONE
    };
}

/****
 * wovo clients  : to get client by client id
 ****/
export function wovoClientsGetClientByKey(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_BY_KEY,
        payload: params
    };
}

/****
 * wovo clients  : to get client by client id : success
 ****/
export function wovoClientsGetClientByKeySuccess(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS,
        payload: params
    };
}

/****
 * wovo clients  : to get client by client id : fail
 ****/
export function wovoClientsGetClientByKeyError(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR,
        payload: params
    };
}

/****
 * wovo clients  : to get client by client id : none
 ****/
export function wovoClientsGetClientByKeyNone() {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE
    };
}

/****
 * countries  : to get all countries
 ****/

export function getAllCountriesList(params) {
    return {
        type: COUNTRY_GET_ALL_COUNTRIES,
        payload: params
    };
}

/****
 * countries  : to get all countries : success
 ****/
export function getAllCountriesListSuccess(params) {
    return {
        type: COUNTRY_GET_ALL_COUNTRIES_SUCCESS,
        payload: params
    };
}

/****
 * countries  : to get all countries : fail
 ****/
export function getAllCountriesListError(params) {
    return {
        type: COUNTRY_GET_ALL_COUNTRIES_ERROR,
        payload: params
    };
}

/****
 * countries  : to get all countries : none
 ****/
export function getAllCountriesListNone() {
    return {
        type: COUNTRY_GET_ALL_COUNTRIES_NONE
    };
}

/****
 * wovo clients  : to get client keys by user
 ****/
export function wovoClientsGetClientKeysByUser(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER,
        payload: params
    };
}

/****
 * wovo clients  : to get client keys by user : success
 ****/
export function wovoClientsGetClientKeysByUserSuccess(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS,
        payload: params
    };
}

/****
 * wovo clients  : to get client keys by user : fail
 ****/
export function wovoClientsGetClientKeysByUserError(params) {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR,
        payload: params
    };
}

/****
 * wovo clients  : to get client keys by user : none
 ****/
export function wovoClientsGetClientKeysByUserNone() {
    return {
        type: WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE
    };
}
