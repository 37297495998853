import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";

import axios from "axios";

import {
    URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_DETAILS,
    URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_SET_STATUS,
    URL_ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT,
    URL_ADMIN_CONTROLS_RESET_SURVEY_RESPONSE_CACHED_DATA,
    URL_ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID,
    URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA,
    URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA_STATUS,
    URL_ADMIN_CONTROLS_SURVEY_DUPLICATE_RESPONSE_COUNT,
    URL_ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS, URL_ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE,
    URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_STATUS,
    URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_SYNC
} from "../../../constants/Urls";
import {baseUrl} from "../../../util/keys";
import AppUtils from "../../../util/util";
import {
    ADMIN_CONTROLS_GET_SURVEY_DETAILS,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT,
    ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID,
    ADMIN_CONTROLS_SET_SURVEY_STATUS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES, ADMIN_CONTROLS_SURVEY_RESPONSES_RESET,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS
} from "../../../constants/actions/adminControls/survey/actionAdminControls";
import {
    adminControlsDownloadAllSurveyResponsesError,
    adminControlsDownloadAllSurveyResponsesStatusError,
    adminControlsDownloadAllSurveyResponsesStatusSuccess,
    adminControlsDownloadAllSurveyResponsesSuccess,
    adminControlsGetSurveyDetailsError,
    adminControlsGetSurveyDetailsSuccess,
    adminControlsGetSurveyDuplicateResponsesError,
    adminControlsGetSurveyDuplicateResponsesSuccess,
    adminControlsIncreaseSurveyResponsesByCountError,
    adminControlsIncreaseSurveyResponsesByCountSuccess,
    adminControlsResetSurveyCachedDataError,
    adminControlsResetSurveyCachedDataSuccess,
    adminControlsSaveSurveyResponseByRequestIdError,
    adminControlsSaveSurveyResponseByRequestIdSuccess,
    adminControlsSetSurveyStatusError,
    adminControlsSetSurveyStatusSuccess,
    adminControlsUpdateSurveyDemoGraphicDetailsError,
    adminControlsUpdateSurveyDemoGraphicDetailsSuccess, adminControlsWCDownloadDiffFileError, adminControlsWCDownloadDiffFileSuccess,
    adminControlsWCGetClientUCMSCustomFiledStatusError,
    adminControlsWCGetClientUCMSCustomFiledStatusSuccess,
    adminControlsWCSyncClientUCMSCustomFieldsError,
    adminControlsWCSyncClientUCMSCustomFieldsSuccess
} from "../../../actions";


/************ methods : http *******************/

/** http : To get survey status details **/
const httpGetSurveyStatusDetails = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_DETAILS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : To set survey status **/
const httpSetSurveyStatus = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_SET_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To get survey duplicate responses **/
const httpGetSurveyDuplicateResponses = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_SURVEY_DUPLICATE_RESPONSE_COUNT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To generate all surveys raw data **/
const httpGenerateAllResponsesRawData = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To generate all surveys raw data : uuid **/
const httpGenerateAllResponsesRawDataUUID = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : to update survey responses demo graphic details **/
const httpUpdateSurveyDemoGraphicDetails = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To increase survey responses by count **/
const httpIncreaseSurveyResponsesByCount = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To reset survey response cached data **/
const httpResetSurveyCachedData = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_RESET_SURVEY_RESPONSE_CACHED_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To save survey response by request id **/
const httpSaveSurveyResponseByRequestId = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : get client ucms custom field status **/
const httpGetClientUCMSCustomFieldStatus = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : to sync custom fields from ucms to pyhton **/
const httpSyncClientUCMSCustomFields = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_SYNC, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : to download diff file **/
const httpDownloadWcDiffFile = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/


//

/** handler : to sync client ucms custom fields **/
function* handleHttpDownloadWcDiffFile({payload}) {
    try {
        
        let result = yield call(httpDownloadWcDiffFile, payload);
        
        if (result.message) {
            yield put(adminControlsWCDownloadDiffFileError(result.response));
        } else {
            yield put(adminControlsWCDownloadDiffFileSuccess(result.data));
        }
    } catch (error) {
        yield put(adminControlsWCDownloadDiffFileError(error));
    }
}

/** handler : to sync client ucms custom fields **/
function* handleHttpSyncClientUCMSCustomFields({payload}) {
    try {
        
        let result = yield call(httpSyncClientUCMSCustomFields, payload);
        
        if (result.message) {
            yield put(adminControlsWCSyncClientUCMSCustomFieldsError(result.response));
        } else {
            yield put(adminControlsWCSyncClientUCMSCustomFieldsSuccess(result.data));
        }
    } catch (error) {
        yield put(adminControlsWCSyncClientUCMSCustomFieldsError(error));
    }
}

/** handler : get client ucms custom field status **/
function* handleHttpGetClientUCMSCustomFieldStatus({payload}) {
    try {
        
        let result = yield call(httpGetClientUCMSCustomFieldStatus, payload);
        
        if (result.message) {
            yield put(adminControlsWCGetClientUCMSCustomFiledStatusError(result.response));
        } else {
            yield put(adminControlsWCGetClientUCMSCustomFiledStatusSuccess(result.data));
        }
    } catch (error) {
        yield put(adminControlsWCGetClientUCMSCustomFiledStatusError(error));
    }
}

/** http : To get survey status details **/
function* handleHttpSaveSurveyResponseByRequestId({payload}) {
    try {
        
        let result = yield call(httpSaveSurveyResponseByRequestId, payload);
        
        if (result.message) {
            yield put(adminControlsSaveSurveyResponseByRequestIdError(result.response));
        } else {
            yield put(adminControlsSaveSurveyResponseByRequestIdSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsSaveSurveyResponseByRequestIdError(error));
    }
}

/** http : To get survey status details **/
function* handleHttpIncreaseSurveyResponsesByCount({payload}) {
    try {
        
        let result = yield call(httpIncreaseSurveyResponsesByCount, payload);
        
        if (result.message) {
            yield put(adminControlsIncreaseSurveyResponsesByCountError(result.response));
        } else {
            yield put(adminControlsIncreaseSurveyResponsesByCountSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsIncreaseSurveyResponsesByCountError(error));
    }
}

/** http : To get survey status details **/
function* handleHttpResetSurveyCachedData({payload}) {
    try {
        
        let result = yield call(httpResetSurveyCachedData, payload);
        
        if (result.message) {
            yield put(adminControlsResetSurveyCachedDataError(result.response));
        } else {
            yield put(adminControlsResetSurveyCachedDataSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsResetSurveyCachedDataError(error));
    }
}

/** http : To get survey status details **/
function* handleHttpGetSurveyStatusDetails({payload}) {
    try {
        
        let result = yield call(httpGetSurveyStatusDetails, payload);
        
        if (result.message) {
            yield put(adminControlsGetSurveyDetailsError(result.response));
        } else {
            yield put(adminControlsGetSurveyDetailsSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsGetSurveyDetailsError(error));
    }
}

/** http : To get survey status details **/
function* handleHttpSetSurveyStatus({payload}) {
    try {
        
        let result = yield call(httpSetSurveyStatus, payload);
        
        if (result.message) {
            yield put(adminControlsSetSurveyStatusError(result.response));
        } else {
            yield put(adminControlsSetSurveyStatusSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsSetSurveyStatusError(error));
    }
}

function* handleHttpGetSurveyDuplicateResponses(data) {
    try {
        
        let result = yield call(httpGetSurveyDuplicateResponses, data.payload);
        
        if (result.message) {
            yield put(adminControlsGetSurveyDuplicateResponsesError(result.response));
        } else {
            yield put(adminControlsGetSurveyDuplicateResponsesSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsGetSurveyDuplicateResponsesError(error));
    }
}

function* handleHttpGenerateAllResponsesRawData(data) {
    try {
        
        let result = yield call(httpGenerateAllResponsesRawData, data.payload);
        
        if (result.message) {
            yield put(adminControlsDownloadAllSurveyResponsesError(result.response));
        } else {
            yield put(adminControlsDownloadAllSurveyResponsesSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsDownloadAllSurveyResponsesError(error));
    }
}

function* handleHttpGenerateAllResponsesRawDataUUID(data) {
    try {
        
        let result = yield call(httpGenerateAllResponsesRawDataUUID, data.payload);
        
        if (result.message) {
            yield put(adminControlsDownloadAllSurveyResponsesStatusError(result.response));
        } else {
            yield put(adminControlsDownloadAllSurveyResponsesStatusSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsDownloadAllSurveyResponsesStatusError(error));
    }
}

function* handleHttpUpdateSurveyDemoGraphicDetails(data) {
    try {
        
        let result = yield call(httpUpdateSurveyDemoGraphicDetails, data.payload);
        
        if (result.message) {
            yield put(adminControlsUpdateSurveyDemoGraphicDetailsError(result.response));
        } else {
            yield put(adminControlsUpdateSurveyDemoGraphicDetailsSuccess(result));
        }
    } catch (error) {
        yield put(adminControlsUpdateSurveyDemoGraphicDetailsError(error));
    }
}

/************ methods : export access *******************/

/** To get survey status details **/
export function* getSurveyStatusDetails() {
    yield takeEvery(ADMIN_CONTROLS_GET_SURVEY_DETAILS, handleHttpGetSurveyStatusDetails);
}

/** To set survey status **/
export function* setSurveyStatus() {
    yield takeEvery(ADMIN_CONTROLS_SET_SURVEY_STATUS, handleHttpSetSurveyStatus);
}

/** To set survey duplicate responses **/
export function* getSurveyDuplicateResponses() {
    yield takeEvery(ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES, handleHttpGetSurveyDuplicateResponses);
}

/** To generate all surveys raw data **/
export function* generateAllResponsesRawData() {
    yield takeEvery(ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD, handleHttpGenerateAllResponsesRawData);
}

/** To generate all surveys raw data : uuid **/
export function* generateAllResponsesRawDataUUID() {
    yield takeEvery(ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS, handleHttpGenerateAllResponsesRawDataUUID);
}

/** To update survey responses demo graphic details **/
export function* updateSurveyDemoGraphicDetails() {
    yield takeEvery(ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS, handleHttpUpdateSurveyDemoGraphicDetails);
}

/** To increase survey responses by count **/
export function* increaseSurveyResponsesByCount() {
    yield takeEvery(ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT, handleHttpIncreaseSurveyResponsesByCount);
}

/** To reset survey cached data  **/
export function* resetSurveyCachedData() {
    yield takeEvery(ADMIN_CONTROLS_SURVEY_RESPONSES_RESET, handleHttpResetSurveyCachedData);
}

/** to save survey responses by request id **/
export function* saveSurveyResponseByRequestId() {
    yield takeEvery(ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID, handleHttpSaveSurveyResponseByRequestId);
}

/** watcher : get client ucms custom field status **/
export function* getClientUCMSCustomFieldStatus() {
    yield takeEvery(ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS, handleHttpGetClientUCMSCustomFieldStatus);
}

/** watcher : get client ucms custom field status **/
export function* syncClientUCMSCustomFields() {
    yield takeEvery(ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS, handleHttpSyncClientUCMSCustomFields);
}

/** watcher : get client ucms custom field status **/
export function* downloadWcDiffFile() {
    yield takeEvery(ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE, handleHttpDownloadWcDiffFile);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveyStatusDetails),
        fork(setSurveyStatus),
        fork(getSurveyDuplicateResponses),
        fork(generateAllResponsesRawData),
        fork(generateAllResponsesRawDataUUID),
        fork(updateSurveyDemoGraphicDetails),
        fork(increaseSurveyResponsesByCount),
        fork(resetSurveyCachedData),
        fork(saveSurveyResponseByRequestId),
        fork(getClientUCMSCustomFieldStatus),
        fork(syncClientUCMSCustomFields),
        fork(downloadWcDiffFile)
    ]);
}
