import {takeEvery, call, put, all, fork} from "redux-saga/effects"

import {
    INDUSTRY_TYPE_NEW_EDIT_SAVE,
    INDUSTRY_TYPE_GET_ALL_RECORDS,
    INDUSTRY_TYPE_UPDATE_STATUS
} from "../../../constants/ActionTypes";

import {
    saveIndustryTypeError,
    saveIndustryTypeSuccess,
    getIndustryTypesSuccess,
    IndustryTypeUpdateStatusSuccess
} from "../../../actions/adminSettings/industry/ActionsIndustry";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    URL_ADMIN_SETTINGS_INDUSTRY_CREATE,
    URL_ADMIN_SETTINGS_INDUSTRY_LIST
} from "../../../constants/Urls";

import AppUtils from "../../../util/util";


/************ methods : http *******************/

/**** Https : Industry type Save ****/
const httpSaveIndustryTypes = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_SETTINGS_INDUSTRY_CREATE, data,AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : Industry type update ****/
const httpUpdateIndustryTypes = async (data) =>
    await axios.patch(baseUrl + URL_ADMIN_SETTINGS_INDUSTRY_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Industry type : Get List ****/
const httpGetIndustryTypes = async (data)=>
    await axios.post(baseUrl + URL_ADMIN_SETTINGS_INDUSTRY_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/************ methods : http response handler *******************/

/** http : Industry Type save/Edit : http response handler **/
function* handleHttpSaveIndustryType({payload}) {
    try {
        let dataValue = yield call( payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateIndustryTypes : httpSaveIndustryTypes,payload)

            if (dataValue.message) {
                yield put(saveIndustryTypeError(dataValue.message))
            } else {
                yield put(saveIndustryTypeSuccess(dataValue))
            }
    }
    catch (error) {
        yield put(saveIndustryTypeError(error))
    }
}

/** http : Industry Type Get : http response handler **/
function* handleHttpGetAllIndustryType() {
    try {
        let dataValue = yield call(httpGetIndustryTypes)
        if (dataValue.message) {
            yield put(saveIndustryTypeError(dataValue.message))
        } else {
            yield put(getIndustryTypesSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(saveIndustryTypeError(error))
    }
}

/** http : Industry Type update status : http response handler **/
function* handleHttpUpdateIndustryTypeStatus({payload}) {
    try {
        let dataValue = yield call(httpUpdateIndustryTypes, payload);
        if (dataValue.message) {
            yield put(saveIndustryTypeError(dataValue.message));
        } else {
            yield put(IndustryTypeUpdateStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveIndustryTypeError(error));
    }
}

/************ methods : export access *******************/

/**** Listeners : Industry Type save ****/
export function* saveIndustryType(){
yield takeEvery(INDUSTRY_TYPE_NEW_EDIT_SAVE,handleHttpSaveIndustryType)
}

/**** Listeners : Industry Type Get ****/
export function* getIndustryTypeList(){
    yield takeEvery(INDUSTRY_TYPE_GET_ALL_RECORDS,handleHttpGetAllIndustryType)
}

/**** Listeners : Industry Type Update Status ****/
export function* updateIndustryTypeStatus(){
    yield takeEvery(INDUSTRY_TYPE_UPDATE_STATUS,handleHttpUpdateIndustryTypeStatus)
}

export default function* rootSaga() {
    yield all([
        fork(saveIndustryType),
        fork(getIndustryTypeList),
        fork(updateIndustryTypeStatus)
    ])
}