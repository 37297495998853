import {
    SURVEY_CATEGORY_NEW_EDIT_SAVE,
    SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
    SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP,
    SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR,
    SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR,
    SURVEY_CATEGORY_GET_ALL_RECORDS,
    SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR,
    SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS,
    SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_CATEGORY_UPDATE_STATUS,
    SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS,
    SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS,
    SURVEY_CATEGORY_UPDATE_STATUS_ERROR,
    SURVEY_CATEGORY_UPDATE_STATUS_NONE
} from "../../../constants/ActionTypes";


/****
 Survey category : status update
 ****/
export function surveyCategoryUpdateStatus(params) {
    return {
        type: SURVEY_CATEGORY_UPDATE_STATUS,
        payload : params
    };
}

/****
 Survey category : status update in progress
 ****/
export function surveyCategoryUpdateStatusInProgress() {
    return {
        type: SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
 Survey category : status update to none
 ****/
export function surveyCategoryUpdateStatusNone() {
    return {
        type: SURVEY_CATEGORY_UPDATE_STATUS_NONE
    };
}

/****
 Survey category : status update : success
 ****/
export function surveyCategoryUpdateStatusSuccess(params) {
    return {
        type: SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}

/****
 Survey category : status update : error
 ****/
export function surveyCategoryUpdateStatusError(params) {
    return {
        type: SURVEY_CATEGORY_UPDATE_STATUS_ERROR,
        payload : params
    };
}

/****
 Survey category : to get all records
 ****/
export function getSurveyCategories() {
    return {
        type: SURVEY_CATEGORY_GET_ALL_RECORDS
    };
}

/****
 Survey category : to get all records : in progress
 ****/
export function getSurveyCategoriesInProgress() {
    return {
        type: SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Survey category : to get all records : error
 ****/
export function getSurveyCategoriesError(params) {
    return {
        type: SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}

/****
 Survey category : to get all records : success
 ****/
export function getSurveyCategoriesSuccess(params) {
    return {
        type: SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Survey category : to reset error popup status
 ****/
export function resetErrorMessage( params ) {
    return {
        type: SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR,
        payload : params
    };
}

/****
 Survey category : to hide new edit popup
 ****/
export function hideNewEditSurveyCategoryPopup( params ) {
    return {
        type: SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}

/****
 Survey category : to display new edit popup
 ****/
export function displayNewEditSurveyCategoryPopup( params ) {
    return {
        type: SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/**** Survey category : save action ****/
export function saveSurveyCategory( params ) {
    return {
        type: SURVEY_CATEGORY_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Survey category : save success action ****/
export function saveSurveyCategorySuccess(data) {
    return {
        type: SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Survey category : save error action ****/
export function saveSurveyCategoryError(data) {
    return {
        type: SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Survey category : save in progress action ****/
export function saveSurveyCategoryProgress() {
    return {
        type: SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS
    };
}

