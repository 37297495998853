import {
	REPORT_FILTERS_CUSTOM_FIELD_SELECTED,
	REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES,
	REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
	REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
	REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE,
	REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET,
	ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
	ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR,
	ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE,
	ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS,
	UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
} from 'constants/actions/reports/actionsCustomFields';

export function updateCustomFieldValues(payload) {
	return {
		type: REPORT_FILTERS_CUSTOM_FIELD_SELECTED,
		payload: payload,
	};
}

/****
 * Report filter : to get custom fields with values
 ****/
export function getReportFilterCustomFieldsWithValues(params) {
	return {
		type: REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES,
		payload: params,
	};
}

export function resetCustomFields(payload) {
	return {
		type: REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET,
		payload: payload,
	};
}

/****
 * Report filter : to get contact by id : success
 ****/
export function getReportFilterCustomFieldsWithValuesSuccess(params) {
	return {
		type: REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
		payload: params,
	};
}
/****
 * Report filter : to get contact by id : fail
 ****/
export function getReportFilterCustomFieldsWithValuesError(params) {
	return {
		type: REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
		payload: params,
	};
}
/****
 * Report filter : to get contact by id : none
 ****/
export function getReportFilterCustomFieldsWithValuesNone() {
	return {
		type: REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE,
	};
}

//====================Account Custom Field Functions=================================

export function updateAccountCustomFieldValues(payload) {
	return {
		type: UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
		payload: payload,
	};
}

/****
 * Survey Questionnaire : to get account custom fields with values
 ****/
export function getAccountCustomFieldValues(params) {
	return {
		type: ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
		payload: params,
	};
}

/****
 * Survey Questionnaire : account custom fields fetch failure
 ****/
export function getAccountCustomFieldValuesError(params) {
	return {
		type: ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR,
		payload: params,
	};
}

/****
 * Survey Questionnaire : to get account custom field: success
 ****/
export function getAccountCustomFieldValuesSuccess(params) {
	return {
		type: ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS,
		payload: params,
	};
}

/****
 * Survey Questionnaire : to get contact by id : none
 ****/
export function getAccountCustomFieldValuesNone() {
	return {
		type: ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE,
	};
}
