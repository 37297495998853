import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    surveyInviteOutreachSuccess,
    surveyInviteOutreachError,
    surveyInviteCreateSuccess,
    surveyInviteCreateError,
    surveyInviteGetListSuccess,
    surveyInviteGetListError,
    surveyInviteGetSpecificSuccess,
    surveyInviteGetSpecificError,
    surveyInviteUpdateSpecificSuccess,
    surveyInviteUpdateSpecificError,
    surveyInviteUpdateSurveyPeriodSuccess,
    surveyInviteUpdateSurveyPeriodError,
    surveyInviteSendReminderSuccess,
    surveyInviteSendReminderError,
    surveyInviteGetInvitedMediaSuccess,
    surveyInviteGetInvitedMediaError
} from "actions";

import {
    SURVEY_INVITE_OUTREACH,
    SURVEY_INVITE_SEND_REMINDER,
    SURVEY_INVITE_CREATE,
    SURVEY_INVITE_GET_LIST,
    SURVEY_INVITE_GET_SPECIFIC_INVITE,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS
} from "constants/actions/survey/survey/actionTypesSurveyInvite";

import axios from "axios";

import {
    URL_SURVEY_INVITE_OUTREACH,
    URL_SURVEY_INVITE_GET_PATCH,
    URL_SURVEY_INVITE_CREATE,
    URL_SURVEY_INVITE_UPDATE_SURVEY_PERIOD,
    URL_SURVEY_INVITE_SEND_REMINDER,
    URL_SURVEY_INVITE_INVITED_MEDIA_DETAILS
} from "constants/Urls";
import { baseUrl } from "util/keys";
import AppUtils from "../../../util/util";
import { HTTP_STATUS_CODE } from "constants/config";

/************ methods : http *******************/

/** http : Survey Invite : for outreach api **/

const httpSurveyInviteOutreach = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_INVITE_OUTREACH, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/** http : Survey Invite : for create survey invite api **/

const httpSurveyInviteCreate = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_INVITE_CREATE, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/** http : Survey Invite : To get survey invite detail list **/

const httpSurveyInviteGetList = async (url) => {
    return await axios.get(url, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/** http : Survey Invite : To get specific survey invite detail **/

const httpSurveyInviteGetSpecific = async (url) => {
    return await axios.get(url, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/** http : Survey Invite : To update specific survey invite **/

const httpSurveyInviteUpdateSpecific = async (url, data) => {
    return await axios.patch(url, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/** http : Survey Invite : To update survey period in survey invite **/

const httpSurveyInviteUpdateSurveyPeriod = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_INVITE_UPDATE_SURVEY_PERIOD, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
}

const httpSurveyInviteSendReminder = async (url, data) => {
    return await axios.patch(url, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
}

const httpSurveyInviteGetInvitedMedia = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_INVITE_INVITED_MEDIA_DETAILS, data, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/************ methods : http response handler *******************/

/** http : Survey Invite : http outreach handler **/
function* handleHttpSurveyInviteOutreach({ payload }) {
    try {
        let response = yield call(httpSurveyInviteOutreach, payload);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteOutreachSuccess(response.data));
        } else {
            yield put(surveyInviteOutreachError(response));
        }
    } catch (error) {
        yield put(surveyInviteOutreachError(error))
    }
}

/** http : Survey Invite : http create survey invite handler **/
function* handleHttpSurveyInviteCreate({payload}) {
    try {
        let response = yield call(httpSurveyInviteCreate, payload);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteCreateSuccess(response));
        } else {
            yield put(surveyInviteCreateError(response));
        }
    } catch (error) {
        yield put(surveyInviteCreateError(error))
    }
}

/** http : Survey Invite : http get survey invite list handler **/

function* handleHttpGetSurveyInvitesList(payload) {
    try {
        const { client_id, survey_id } = payload.payload;
        let url = baseUrl + URL_SURVEY_INVITE_GET_PATCH + `?client_id=${client_id}&survey_id=${survey_id}`;
        let response = yield call(httpSurveyInviteGetList, url);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteGetListSuccess(response.data));
        } else {
            yield put(surveyInviteGetListError(response.message));
        }
    } catch (error) {
        yield put(surveyInviteGetListError(error))
    }
}

/** http : Survey Invite : http get specific survey invite detail handler **/

function* handleHttpSurveyInviteGetSpecific({payload}) {
    try {
        let url = baseUrl + URL_SURVEY_INVITE_GET_PATCH + payload + '/'
        let response = yield call(httpSurveyInviteGetSpecific, url);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteGetSpecificSuccess(response.data));
        }
        else {
            yield put(surveyInviteGetSpecificError(response.message))
        }
    } catch (error) {
        yield put(surveyInviteGetSpecificError(error))
    }
}

/** http : Survey Invite : http update specific survey invite handler**/

function* handleHttpSurveyInviteUpdateSpecific({payload}) {
    try {
        const { id } = payload;
        delete payload.id;
        let url = baseUrl + URL_SURVEY_INVITE_GET_PATCH + `${id}` + '/'
        let response = yield call(httpSurveyInviteUpdateSpecific, url, payload);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteUpdateSpecificSuccess(response.data));
        }
        else {
            yield put(surveyInviteUpdateSpecificError(response.message))
        }
    } catch (error) {
        yield put(surveyInviteUpdateSpecificError(error))
    }
}

/** http : Survey Invite : http update survey period handler**/
function* handleHttpSurveyInviteUpdateSurveyPeriod({ payload }) {
    try {
        let response = yield call(httpSurveyInviteUpdateSurveyPeriod, payload);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteUpdateSurveyPeriodSuccess(response.data));
        }
        else {
            yield put(surveyInviteUpdateSurveyPeriodError(response.message))
        }
    } catch (error) {
        yield put(surveyInviteUpdateSurveyPeriodError(error))
    }
}

function* handleHttpSurveyInviteSendReminder({ payload }){
    try{
        let url = baseUrl + URL_SURVEY_INVITE_SEND_REMINDER
        let response = yield call(httpSurveyInviteSendReminder, url, payload);
        if (typeof response !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteSendReminderSuccess(response));
        }
        else{
            yield put(surveyInviteSendReminderError(response))
        }
    }catch (error) {
            yield put(surveyInviteSendReminderError(error))
    }
}

function* handleHttpSurveyInviteGetInvitedMedia({payload}){
    try{
        let response = yield call(httpSurveyInviteGetInvitedMedia, payload);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(surveyInviteGetInvitedMediaSuccess(response));
        }
        else{
            yield put(surveyInviteGetInvitedMediaError(response))
        }
    }catch (error){
        yield put(surveyInviteGetInvitedMediaError(error))
    }
}

/************ methods : export access *******************/

export function* surveyInviteOutreach() {
    yield takeEvery(SURVEY_INVITE_OUTREACH, handleHttpSurveyInviteOutreach);
}

export function* surveyInviteCreate() {
    yield takeEvery(SURVEY_INVITE_CREATE, handleHttpSurveyInviteCreate);
}

export function* surveyInviteGetList() {
    yield takeEvery(SURVEY_INVITE_GET_LIST, handleHttpGetSurveyInvitesList);
}

export function* surveyInviteGetSpecific() {
    yield takeEvery(SURVEY_INVITE_GET_SPECIFIC_INVITE, handleHttpSurveyInviteGetSpecific);
}

export function* surveyInviteUpdateSpecific() {
    yield takeEvery(SURVEY_INVITE_UPDATE_SPECIFIC_INVITE, handleHttpSurveyInviteUpdateSpecific);
}

export function* surveyInviteUpdateSurveyPeriod(){
    yield takeEvery(SURVEY_INVITE_UPDATE_SURVEY_PERIOD, handleHttpSurveyInviteUpdateSurveyPeriod);
}

export function* surveyInviteSendReminder(){
    yield takeEvery(SURVEY_INVITE_SEND_REMINDER, handleHttpSurveyInviteSendReminder);
}

export function* surveyInviteGetInvitedMediaData(){
    yield takeEvery(SURVEY_INVITE_INVITED_MEDIA_DETAILS, handleHttpSurveyInviteGetInvitedMedia)
}

export default function* rootSaga() {
    yield all([
        fork(surveyInviteOutreach),
        fork(surveyInviteCreate),
        fork(surveyInviteGetList),
        fork(surveyInviteGetSpecific),
        fork(surveyInviteUpdateSpecific),
        fork(surveyInviteUpdateSurveyPeriod),
        fork(surveyInviteSendReminder),
        fork(surveyInviteGetInvitedMediaData)
    ])
}