import {
    TRANSLATION_SUPPORTED_LANG_SAVE,
    TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS,
    TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS,
    TRANSLATION_SUPPORTED_LANG_SAVE_NONE,
    TRANSLATION_SUPPORTED_LANG_GET_LIST,
    TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS,
    TRANSLATION_SUPPORTED_SAVED_LANG_DELETE,
    TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE, DISPLAY_ERROR_NOTIFICATION, HIDE_ERROR_NOTIFICATION,
}

    from "../constants/ActionTypes";

/****
 Translation : To get all supported languages
 ****/
export function getSupportedLanguages( ) {
    return {
        type: TRANSLATION_SUPPORTED_LANG_GET_LIST
    };
}

/****
 Translation : To get all supported languages success
 ****/
export function getSupportedLanguagesSuccess( data ) {
    return {
        type: TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS,
        payload: data
    };
}


/**** Translation Settings  : Language save ****/
export function saveSupportedLanguage( params ) {
    return {
        type: TRANSLATION_SUPPORTED_LANG_SAVE,
        payload : params
    };
}

/**** Translation Settings  : save success****/
export function saveSupportedLanguageSuccess(data) {
    return {
        type: TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS,
        payload: data
    };
}


/**** New Language : save in progress action ****/
export function saveSupportedLanguageInProgress() {
    return {
        type: TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS
    };
}

/**** New Language : reset progress action ****/
export function saveSupportedLanguageNone() {
    return {
        type: TRANSLATION_SUPPORTED_LANG_SAVE_NONE
    };
}

/**** Saved Language : delete ****/

export function deleteSavedSupportedLanguage(params){
    return{
        type:TRANSLATION_SUPPORTED_SAVED_LANG_DELETE,
        payload:params
    }
}



/*** To reset the progress *****/

export function deleteSupportedLanguageNone() {
    return{
        type:TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE
    }

}

export const showErrorMessage = (val) => {
    return {
        type: DISPLAY_ERROR_NOTIFICATION,
        payload: val
    };
};

export const hideCommonErrorMessage = () => {
    return {
        type: HIDE_ERROR_NOTIFICATION
    };
};
