import {
    ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS
} from "../../../constants/actions/adminControls/survey/actionAdminControls";

/**** admin controls : wc : get client ucms custom field status ****/
export function adminControlsWCGetClientUCMSCustomFiledStatus(params) {
    return {
        type: ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS,
        payload: params
    };
}

/**** admin controls : wc : get client ucms custom field status : success  ****/
export function adminControlsWCGetClientUCMSCustomFiledStatusSuccess(data) {
    return {
        type: ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS,
        payload: data
    };
}


/**** admin controls : wc : get client ucms custom field status : error  ****/
export function adminControlsWCGetClientUCMSCustomFiledStatusError() {
    return {
        type: ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR
    };
}

/**** admin controls : wc : get client ucms custom field status : none  ****/
export function adminControlsWCGetClientUCMSCustomFiledStatusNone() {
    return {
        type: ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE
    };
}

/**** admin controls : wc : sync client ucms custom fields ****/
export function adminControlsWCSyncClientUCMSCustomFields(params) {
    return {
        type: ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS,
        payload: params
    };
}

/**** admin controls : wc : sync client ucms custom fields : success  ****/
export function adminControlsWCSyncClientUCMSCustomFieldsSuccess(data) {
    return {
        type: ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS,
        payload: data
    };
}


/**** admin controls : wc : sync client ucms custom fields : error  ****/
export function adminControlsWCSyncClientUCMSCustomFieldsError() {
    return {
        type: ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR
    };
}

/**** admin controls : wc : sync client ucms custom fields : none  ****/
export function adminControlsWCSyncClientUCMSCustomFieldsNone() {
    return {
        type: ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE
    };
}


/**** admin controls : wc : to download diff file ****/
export function adminControlsWCDownloadDiffFile(params) {
    return {
        type: ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE,
        payload: params
    };
}

/**** admin controls : wc : to download diff file : success  ****/
export function adminControlsWCDownloadDiffFileSuccess(data) {
    return {
        type: ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS,
        payload: data
    };
}


/**** admin controls : wc : to download diff file : error  ****/
export function adminControlsWCDownloadDiffFileError() {
    return {
        type: ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR
    };
}

/**** admin controls : wc : to download diff file : none  ****/
export function adminControlsWCDownloadDiffFileNone() {
    return {
        type: ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE
    };
}
