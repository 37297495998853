import React from "react";
import { WOVO_PRIVACY_POLICY_URL } from "../../constants/config";
import IntlMessages from "util/IntlMessages";


export const CustomCookieBanner = ({ handleButtonClick }) => {
    return (
        <div className="wovo_cookie_banner">
            <div className="wovo_cookie_banner_content">
                <p style={{ wordBreak: 'break-word' }}>
                    <span>
                        <IntlMessages id="DIC_KEY_COOKIE_BANNER_CONTENT" /> 
                        <a href={WOVO_PRIVACY_POLICY_URL} target="_blank">
                             <IntlMessages id="DIC_KEY_COOKIE_BANNER_LABEL_PRIVACY_POLICY" />
                        </a>
                    </span>
                </p>
                <button
                    className="wovo_cookie_banner_button"
                    onClick={() => handleButtonClick('accept_cookie', true)}>
                     <IntlMessages id="DIC_KEY_COOKIE_BANNER_LABEL_ACCEPT" />
                </button>
            </div>
        </div>
    );
};