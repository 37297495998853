import {
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS
} from "../../constants/actions";

/****
 * contact settings : to get custom fields config
 ****/
export function contactSettingsGetClientCustomFields(params) {
    return {
        type: CONTACT_SETTINGS_GET_CUSTOM_FIELDS,
        payload: params
    };
}

/****
 * contact settings : to get custom fields config : success
 ****/
export function contactSettingsGetClientCustomFieldsSuccess(params) {
    return {
        type: CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS,
        payload: params
    };
}

/****
 * contact settings : to get custom fields config : fail
 ****/
export function contactSettingsGetClientCustomFieldsError(params) {
    return {
        type: CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR,
        payload: params
    };
}

/****
 * contact settings : to get custom fields config : none
 ****/
export function contactSettingsGetClientCustomFieldsNone() {
    return {
        type: CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE
    };
}

/****
 * contact settings : to remove custom field values
 ****/
export function contactSettingsRemoveCustomFieldValues(params) {
    return {
        type: CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES,
        payload: params
    };
}

/****
 * contact settings : to remove custom field values : success
 ****/
export function contactSettingsRemoveCustomFieldValuesSuccess(params) {
    return {
        type: CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: params
    };
}

/****
 * contact settings : to remove custom field values : fail
 ****/
export function contactSettingsRemoveCustomFieldValuesError(params) {
    return {
        type: CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR,
        payload: params
    };
}

/****
 * contact settings : to remove custom field values : none
 ****/
export function contactSettingsRemoveCustomFieldValuesNone() {
    return {
        type: CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE
    };
}

