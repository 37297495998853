import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import AppUtils from "../../../../util/util";
import{
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_IN_PROGRESS
} from "../../../../constants/ActionTypes";
import {HTTP_STATUS_CODE} from "../../../../constants/config";


const initialData = {
    loader: false,
    alertMessage: '',
    isErrorMessage: false,
    connectSummaryTableDownloadStatus:HTTP_REQUEST_STATUS.NOT_STARTED,
    connectSummaryTableDownloadLink: "",
    connectSummaryTableResponseUUIDCounter: 0,
    connectSummaryTableResponseUUID: ''
}

const ReducerConnectSummaryTableDownload = (state = initialData, action) => {

    switch (action.type) {

        /**** connect summary table : Download summary table none *****/     
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE:
            return {
                ...state,
                connectSummaryTableDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                connectSummaryTableDownloadLink: "",
                loader: false
            };           
        
       /**** connect summary table : Downloadsummary table error *****/
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR:
            return {
                ...state,
                connectSummaryTableDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                //alertMessage: AppUtils.getErrorStringFromResponse(action.payload),
                alertMessage: action.payload,
                loader: false
            };
        
        /**** connect summary table : Download summary table in progress *****/        
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS:
            return {
                ...state,
                connectSummaryTableDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                connectSummaryTableDownloadLink: "",
                loader: true
            };            

        /**** connect summary table : Download summary table : success ****/
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS:{
            return {
                ...state,
                connectSummaryTableResponseUUID : action.payload.uuid,
                connectSummaryTableResponseUUIDCounter : 1
            }
        }

        /**** connect summary table : Download summary table : in progress ****/
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_IN_PROGRESS:{
            return {
                ...state,
                connectSummaryTableResponseUUID : "",
                connectSummaryTableResponseUUIDCounter : 0,
                connectSummaryTableDownloadStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        };

        /**** connect summary table : Download summary table : Success ****/
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS:{
            if (action.payload.status === "SUCCESS" || action.payload.status === HTTP_STATUS_CODE['SUCCESS']) {

                let connectSummaryTableDownloadLink = "";

                if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" ) {
                    let data = action.payload.status === "SUCCESS" ? action.payload.data.data : action.payload.data;
                    connectSummaryTableDownloadLink = AppUtils.getAppBaseUrl()  + data;
                    connectSummaryTableDownloadLink = AppUtils.redirectDownloadProdToReports(connectSummaryTableDownloadLink);
                }

                return {
                    ...state,
                    connectSummaryTableDownloadStatus : HTTP_REQUEST_STATUS.SUCCESS,
                    connectSummaryTableResponseUUID: "",
                    connectSummaryTableResponseUUIDCounter: 0,
                    connectSummaryTableDownloadLink : connectSummaryTableDownloadLink
                };
            } else if (action.payload.status === "IN_PROGRESS") {
                return {
                    ...state,
                    connectSummaryTableResponseUUID: action.payload.uuid,
                    connectSummaryTableResponseUUIDCounter: state.connectSummaryTableResponseUUIDCounter + 1,
                }
            } else if (action.payload.status === "NOT_FOUND") {
                return {
                    connectSummaryTableResponseUUID: "",
                    connectSummaryTableResponseUUIDCounter: 0,
                }
            }

            break;
        }

        /**** connect summary table : Download summary table : Error ****/
        case CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR:{
            return {
                ...state,
                connectSummaryTableDownloadStatus : HTTP_REQUEST_STATUS.FAIL,
                connectSummaryTableResponseUUID : "",
                connectSummaryTableResponseUUIDCounter : 0,
                alertMessage: action.payload
            }
        }
            
        default:
            return state;
    }
}

export default ReducerConnectSummaryTableDownload;
