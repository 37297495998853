import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import CompanyInfo from "../../components/companyInfo";
import Avatar from "@material-ui/core/Avatar";
import {LOGO_BACKGROUND} from "../../constants/customUIConfig";
import { MOBILE_VIEW } from 'constants/constants';

class SideNav extends React.PureComponent {

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    renderBottomLogo() {
        return (
            <span className="d-flex flex-row align-items-center" style={{marginLeft: "12px"}}>
                <img src={require("assets/images/logos/ls_logo.svg")} alt="Wovo" title="Wovo" height={"36px"} style={{marginLeft : "10px"}}/>
                <span className="nav_bar_app_sub_name">{"By Labor Solutions"}</span>
            </span>
        )
    }

    render() {
        const {navCollapsed, drawerType, width, navigationStyle, enableMaintenanceBanner, showBanner} = this.props;
        let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
        let type = 'permanent';
        if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
            type = 'temporary';
        }

        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = 'temporary';
        }
        return (
            <div className={`app-sidebar d-none ${drawerStyle}`}>
                <Drawer className="app-sidebar-content"
                    variant={type}
                    open={type.includes('temporary') ? navCollapsed : true}
                    onClose={this.onToggleCollapsedNav}
                    classes={{
                        paper: `side-nav ${(enableMaintenanceBanner === true && showBanner === true && window.innerWidth > MOBILE_VIEW) ? 'add_banner_to_top' : ''}`,
                    }}
                >
                    <CompanyInfo history={this.props.history}/>

                    <SidenavContent/>

                    {this.renderBottomLogo()}

                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = ({settings, ReducerMaintenanceBanner}) => {
    const {navCollapsed, drawerType, width, navigationStyle} = settings;
    const {enableBanner, showBanner} = ReducerMaintenanceBanner;
    return {navCollapsed, drawerType, width, navigationStyle, enableMaintenanceBanner : enableBanner, showBanner}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));

