
export const E_LEARNING_REPORTS_SET_FILTER_VIEW = 'E_LEARNING_REPORTS_SET_FILTER_VIEW';
export const E_LEARNING_REPORTS_SET_FILTER_GROUP_BY = 'E_LEARNING_REPORTS_SET_FILTER_GROUP_BY';
export const E_LEARNING_REPORTS_SET_FILTER_METRIC = 'E_LEARNING_REPORTS_SET_FILTER_METRIC';
export const E_LEARNING_REPORTS_SET_FILTER_LESSON = 'E_LEARNING_REPORTS_SET_FILTER_LESSON';
export const E_LEARNING_REPORTS_RESET_FILTERS = 'E_LEARNING_REPORTS_RESET_FILTERS';

export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE';

export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR';
export const E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE = 'E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE';

/**E Learning Raw data */
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID';
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS';
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR';
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE';

export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS';
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS';
export const E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR = 'E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR';

export const E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER = 'E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER';
export const E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER = 'E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER';
