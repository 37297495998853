import {
     GET_BRAND_CHILD_CLIENT_COUNTRIES,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_SUCCESS,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_ERROR,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_NONE,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_INPROGRESS
} from '../../constants/actions/countries/actionConfigCountries';

/**Fetch all the brand's child company countries */
export function getChildClientCountries(params) {
     return {
          type: GET_BRAND_CHILD_CLIENT_COUNTRIES,
          payload: params
     }
};

/**set the status of fetch countries */
export function getChildClientCountriesInProgress() {
     return {
          type: GET_BRAND_CHILD_CLIENT_COUNTRIES_INPROGRESS
     }
};

/** To be called on fetch countries success*/
export function getChildClientCountriesSuccess(params) {
     return {
          type: GET_BRAND_CHILD_CLIENT_COUNTRIES_SUCCESS,
          payload: params
     }
};

/** To be called on fetch countries error*/
export function getChildClientCountriesError(params) {
     return {
          type: GET_BRAND_CHILD_CLIENT_COUNTRIES_ERROR,
          payload: params
     }
};

/** To be called to set the fetch status to intial*/
export function getChildClientCountriesNone() {
     return {
          type: GET_BRAND_CHILD_CLIENT_COUNTRIES_NONE
     }
};