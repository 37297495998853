import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../util/keys";
import {store} from '../../../MainApp';
import AppUtils from "../../../util/util";
import {
    SURVEY_GET_SUMMARY_TABLE_DATA ,
    SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA  
} from "../../../constants/actions/survey/survey/actionTypesSurvey";
import {
    getSurveySummaryTableDataSuccess,
    getSurveySummaryTableDataError,
    downloadSurveySummaryTableDataSuccess,
    downloadSurveySummaryTableDataError   
} from "../../../actions/survey/summaryTable/ActionsSurveySummaryTable";
import {
    URL_SURVEY_GET_SUMMARY_TABLE_DATA_QUESTIONS, 
    URL_SURVEY_GET_SUMMARY_TABLE_DATA_CATEGORIES,
    URL_SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA
} from "../../../constants/Urls";
import {
    SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS,
    HTTP_STATUS_CODE
} from "../../../constants/config";

/************ methods : http *******************/

/*** http : Survey Summary Table : get summary table data***/
const httpGetSurveySummaryTableDataForQuestions = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_GET_SUMMARY_TABLE_DATA_QUESTIONS , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/*** http : Survey Summary Table : get summary table data***/
const httpGetSurveySummaryTableDataForCategories = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_GET_SUMMARY_TABLE_DATA_CATEGORIES , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/*** http : Survey Template Summary Table : download summary table Category data***/
const httpDownloadSurveySummaryTableData = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/************ methods : http response handler *******************/

/** http : Survey Summary Table : http response handler **/
function* handleHttpGetSurveySummaryTableData({ payload }) {
    try {

        let dataValue = yield call(payload.report_type === SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_QUESTION ?
            httpGetSurveySummaryTableDataForQuestions : httpGetSurveySummaryTableDataForCategories, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getSurveySummaryTableDataSuccess(dataValue));
        } else {
            yield put(getSurveySummaryTableDataError(dataValue.response.data.error));
        }
    } catch (error) {
        yield put(getSurveySummaryTableDataError(error));
    }
}

/** http : Survey Summary Table : http response handler **/
function* handleHttpDownloadSurveySummaryTableData({payload}) {
    try {
        let dataValue = yield call(httpDownloadSurveySummaryTableData, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(downloadSurveySummaryTableDataSuccess(dataValue));
        } else {
            yield put(downloadSurveySummaryTableDataError(dataValue.response.data.error));
        }
    } catch (error) {
        yield put(downloadSurveySummaryTableDataError(error));
    }
}

/************ methods : export access *******************/

/** Survey Summary Table: get Table data **/
export function* getSurveySummaryTableListData() {
    yield takeEvery(SURVEY_GET_SUMMARY_TABLE_DATA, handleHttpGetSurveySummaryTableData);
}

/** Survey Summary Table: get Table data **/
export function* downloadSurveySummaryTableListData() {
    yield takeEvery(SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA, handleHttpDownloadSurveySummaryTableData);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveySummaryTableListData),
        fork(downloadSurveySummaryTableListData)
    ]);
}