import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import {LOGO_BACKGROUND} from "../../constants/customUIConfig";

class CompanyInfo extends React.Component {

    render() {

        return (
            <span className="user-profile d-flex flex-row align-items-center" style={{marginLeft: "10px"}}>
                <Avatar className="size-60" style={{background: LOGO_BACKGROUND, marginRight: "4px"}}>
                    <img src={require("assets/images/logos/wovo_logo.svg")} alt="Wovo" title="Wovo" height={"52px"}/>
                </Avatar>

                <span className="nav_bar_app_name">{"WOVO"}</span>
            </span>);
    }
}

const mapStateToProps = ({settings, auth}) => {
    const {locale} = settings;
    const {
        user,
        avatarName
    } = auth;

    return {
        locale,
        user,
        avatarName
    }
};

export default connect(mapStateToProps, {
    userSignOut
})(CompanyInfo);


