import { 
    
    PAYSLIP_GET_PAGINATED_LIST, 
    PAYSLIP_GET_PAGINATED_LIST_PROGRESS,    
    PAYSLIP_GET_PAGINATED_LIST_ERROR,    
    PAYSLIP_GET_PAGINATED_LIST_NONE,     
    PAYSLIP_GET_PAGINATED_LIST_SUCCESS,
    PAYSLIP_PREVIEW_DETAILS_UPDATE,
    SEND_PAYSLIP,
    SEND_PAYSLIP_SUCCESS,
    SEND_PAYSLIP_ERROR,
    SEND_PAYSLIP_NONE,
    SEND_PAYSLIP_INPROGRESS,
    UPDATE_PAYSLIP,
    UPDATE_PAYSLIP_SUCCESS,
    UPDATE_PAYSLIP_ERROR,
    UPDATE_PAYSLIP_NONE,
    UPDATE_PAYSLIP_INPROGRESS,
    RELOAD_PAYSLIP_LIST,
    VERIFY_PAYSLIP_DATA_UPLOADED,
    VERIFY_PAYSLIP_DATA_UPLOADED_ERROR,
    VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS,
    VERIFY_PAYSLIP_DATA_UPLOADED_NONE,
    VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_INPROGRESS,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR,
    SET_SELECTED_INPUT_VALUES,
    UPDATE_UPLOAD_CONTAINER_TEXT,
    PAYSLIP_RESET_STATE
} from "../../constants/actions/payslip/actionTypesPayslip";

/****
 *PaySlip:to get paginated list
 ****/
export function paySlipGetPaginatedList(params) {
    return {
        type: PAYSLIP_GET_PAGINATED_LIST,
        payload: params
    };
}

/****
 *PaySlip:to get paginated list
 ****/
 export function paySlipGetPaginatedListProgress() {
    return {
        type: PAYSLIP_GET_PAGINATED_LIST_PROGRESS
    };
}

/****
 *PaySlip:to get paginated list:success
 ****/
export function paySlipGetPaginatedListSuccess(params) {
    return {
        type: PAYSLIP_GET_PAGINATED_LIST_SUCCESS,
        payload: params
    };
}

/****
 *PaySlip:to get paginated list:fail
 ****/
export function paySlipGetPaginatedListError(params) {
    return {
        type: PAYSLIP_GET_PAGINATED_LIST_ERROR,
        payload: params
    };
}

/****
 *PaySlip: to get paginated list:none
 ****/
export function paySlipGetPaginatedListNone() {
    return {
        type: PAYSLIP_GET_PAGINATED_LIST_NONE
    };
}

/**To update state properties of preview page */
export function updatePayslipPreviewDetials(data) {
    return {
         type: PAYSLIP_PREVIEW_DETAILS_UPDATE,
         payload: data
    }
}

/****
Payslip : send payslip API call
****/
export function sendPayslip(params) {
    return {
         type: SEND_PAYSLIP,
         payload: params
    }
}

/****
Payslip : send payslip API call in progress
****/
export function sendPayslipInProgress() {
    return {
         type: SEND_PAYSLIP_INPROGRESS
    }
}

/****
Payslip : Send payslip API call succeded
****/
export function sendPayslipSuccess(params) {
    return {
         type: SEND_PAYSLIP_SUCCESS,
         payload: params
    }
}

/****
Payslip : send payslip api call errored
****/
export function sendPayslipError(params) {
    return {
         type: SEND_PAYSLIP_ERROR,
         payload: params
    }
}

/****
Payslip : send payslip api call reset status
****/
export function sendPayslipNone() {
    return {
         type: SEND_PAYSLIP_NONE
    }
}

/****
Payslip : update payslip API call (applies to all updates like cancel, delete, re-send)
****/
export function updatePayslip(params) {
    return {
         type: UPDATE_PAYSLIP,
         payload: params
    }
}

/****
Payslip : update payslip API call in progress
****/
export function updatePayslipInProgress() {
    return {
         type: UPDATE_PAYSLIP_INPROGRESS
    }
}

/****
Payslip : update payslip API call succeded
****/
export function updatePayslipSuccess(params) {
    return {
         type: UPDATE_PAYSLIP_SUCCESS,
         payload: params
    }
}

/****
Payslip : update payslip api call errored
****/
export function updatePayslipError(params) {
    return {
         type: UPDATE_PAYSLIP_ERROR,
         payload: params
    }
}

/****
Payslip : update payslip api call reset status
****/
export function updatePayslipNone() {
    return {
         type: UPDATE_PAYSLIP_NONE
    }
}

/***
* Payslip: To reload the payslip list
*/
export function reloadPayslipList(data) {
    return {
         type: RELOAD_PAYSLIP_LIST,
         payload: data
    }
}


/**To verify the data in excel uploaded */
export function verifyPayslipDataUploaded(params) {
    return {
         type: VERIFY_PAYSLIP_DATA_UPLOADED,
         payload: params
    }
}

/**To verify the data in excel uploaded is in progress */
export function verifyPayslipDataUploadedInProgress() {
    return {
         type: VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS
    }
}

/**To verify the data in excel uploaded is successful */
export function verifyPayslipDataUploadedSuccess(params) {
    return {
         type: VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS,
         payload: params
    }
}

/**To verify the data in excel uploaded is errored  */
export function verifyPayslipDataUploadedError(params) {
    return {
         type: VERIFY_PAYSLIP_DATA_UPLOADED_ERROR,
         payload: params
    }
}

/**To verify the data in excel uploaded , status is defaulted  */
export function verifyPayslipDataUploadedNone() {
    return {
         type: VERIFY_PAYSLIP_DATA_UPLOADED_NONE
    }
}

// ==========================================================================

/**To donwload new payslip template*/
export function downloadTemplateAttachmentPayslip(params) {
    return {
         type: NEW_PAYSLIP_ATTACHMENT_DOWNLOAD,
         payload: params
    }
}

/**To donwload new payslip template success*/
export function downloadTemplateAttachmentPayslipSuccess(params) {
    return {
         type: NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS,
         payload: params
    }
}

/**To donwload new payslip template In progress*/
export function downloadTemplateAttachmentPayslipInprogress() {
    return {
         type: NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_INPROGRESS
    }
}

/**To donwload new payslip template None*/
export function downloadTemplateAttachmentPayslipNone() {
    return {
         type: NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE
    }
}

/**To donwload new payslip template Error*/
export function downloadTemplateAttachmentPayslipError(params) {
    return {
         type: NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR,
         payload: params
    }
}

// ============================================================================

/**To update payslip input values */
export function updatePayslipInputValues(params) {
    return {
         type: SET_SELECTED_INPUT_VALUES,
         payload: params
    }
}

/**To update upload excel container text */
export function updateUploadContainerText(params) {
    return {
         type: UPDATE_UPLOAD_CONTAINER_TEXT,
         payload: params
    }
}

/**To reset state for new payslip */
export function resetState() {
    return {
         type: PAYSLIP_RESET_STATE
    }
}
