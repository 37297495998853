import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { baseUrl } from "../../../util/keys";
import {
    URL_COMPANY_POST_RAW_DATA_UUID,
    URL_COMPANY_POST_RAW_DATA_UUID_STATUS,
    URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID,
    URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID_STATUS
} from "../../../constants/Urls";
import {
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID
} from "../../../constants/ActionTypes";
import AppUtils from "../../../util/util";
import {
    companyPostRawDataResponsesUUIDSuccess,
    companyPostRawDataResponsesUUIDError,
    companyPostRawDataDownloadUUIDSuccess,
    companyPostRawDataDownloadUUIDError,
    companyPostRawDataDownloadUUIDStatusError,
    companyPostRawDataDownloadUUIDStatusSuccess,
    companyPostRawDataResponsesUUIDStatusError,
    companyPostRawDataResponsesUUIDStatusSuccess
} from "../../../actions/reports/companyPost/ActionCompanyPostRawData";
import { HTTP_STATUS_CODE } from "../../../constants/config";

/************ methods : http *******************/

/** http : Get company post raw data uuid**/
const httpGetCompanyPostRawDataResponseUUID = async (data) => {
    return await axios.post(baseUrl + URL_COMPANY_POST_RAW_DATA_UUID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/** http : Get company post raw data uuid status**/
const httpGetCompanyPostRawDataResponseUUIDStatus = async (data) => {
    return await axios.post(baseUrl + URL_COMPANY_POST_RAW_DATA_UUID_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/** http : Get company post raw data download uuid**/
const httpGetCompanyPostRawDataDownloadUUID = async (data) => {
    return await axios.post(baseUrl + URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/** http : Get company post raw data download uuid status**/
const httpGetCompanyPostRawDataUUIDStatus = async (data) => {
    return await axios.post(baseUrl + URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/************ methods : http response handler *******************/

/** http : get company post raw data : http response handler **/
function* handleHttpGetCompanyPostRawDataUUID({ payload }) {
    try {
        let result = yield call(httpGetCompanyPostRawDataResponseUUID, payload);

        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(companyPostRawDataResponsesUUIDSuccess(result));
        } else {
            yield put(companyPostRawDataResponsesUUIDError(result.message));
        }
    } catch (error) {
        yield put(companyPostRawDataResponsesUUIDError(error));
    }
}

/** http : get company post raw data : http response handler **/
function* handleHttpGetCompanyPostRawDataUUIDStatus({ payload }) {
    try {
        let result = yield call(httpGetCompanyPostRawDataResponseUUIDStatus, payload);

        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(companyPostRawDataResponsesUUIDStatusSuccess(result));
        } else {
            yield put(companyPostRawDataResponsesUUIDStatusError(result.message));
        }
    } catch (error) {
        yield put(companyPostRawDataResponsesUUIDStatusError(error));
    }
}

/** http : companypost raw data download : http response handler **/
function* handleHttpCompanyPostRawDataDownloadUUID({ payload }) {
    try {
        let result = yield call(httpGetCompanyPostRawDataDownloadUUID, payload);

        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(companyPostRawDataDownloadUUIDSuccess(result));
        } else {
            yield put(companyPostRawDataDownloadUUIDError(result.message));
        }
    } catch (error) {
        yield put(companyPostRawDataDownloadUUIDError(error));
    }
}

/** http : company post raw data download uuid : http response handler **/
function* handleHttpCompanyPostRawDataDownloadUUIDStatus({ payload }) {
    try {
        let result = yield call(httpGetCompanyPostRawDataUUIDStatus, payload);

        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(companyPostRawDataDownloadUUIDStatusSuccess(result));
        } else {
            yield put(companyPostRawDataDownloadUUIDStatusError(result.message));
        }
    } catch (error) {
        yield put(companyPostRawDataDownloadUUIDStatusError(error));
    }
}

/************ methods : export access *******************/

/**To get company post raw data uuid*/
export function* getCompanyPostRawDataResponseUUID() {
    yield takeEvery(COMPANY_POST_RAW_DATA_RESPONSES_UUID, handleHttpGetCompanyPostRawDataUUID);
}

/**To get company post raw data uuid status*/
export function* getCompanyPostRawDataResponseUUIDStatus() {
    yield takeEvery(COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS, handleHttpGetCompanyPostRawDataUUIDStatus);
}

/**To get company post raw data uuid */
export function* companyPostRawDataDownloadUUIDDownload() {
    yield takeEvery(COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID, handleHttpCompanyPostRawDataDownloadUUID);
}

/**To get company post raw data uuid status*/
export function* companyPostRawDataUUIDDownloadStatus() {
    yield takeEvery(COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS, handleHttpCompanyPostRawDataDownloadUUIDStatus);
}

export default function* rootSaga() {
    yield all([
        fork(getCompanyPostRawDataResponseUUID),
        fork(getCompanyPostRawDataResponseUUIDStatus),
        fork(companyPostRawDataDownloadUUIDDownload),
        fork(companyPostRawDataUUIDDownloadStatus)
    ]);
}

