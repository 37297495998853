import React from "react";
import {
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR
} from "../../../../constants/actions/survey/survey/actionTypesSurvey";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import AppUtils from "../../../../util/util";

const initialData = {
    loader: false,
    alertMessageRawData: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    surveyRawDataResponseDownloadStatus:HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyRawDataResponseDownloadLink: "",

    surveyRawDataResponseUUID: "",
    surveyRawDataResponseUUIDCounter: 0,

}


const ReducerSurveyReportsRawdata = (state = initialData, action) => {

    switch (action.type) {

        /**** survey raw data : Download raw data uuid success*****/
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS:{

            return {
                ...state,
                surveyRawDataResponseUUID : action.payload.uuid,
                surveyRawDataResponseUUIDCounter : 1
            }
        }

        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS:{
            return {
                ...state,
                surveyRawDataResponseUUID : "",
                surveyRawDataResponseUUIDCounter : 0,
                surveyRawDataResponseDownloadStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        }

        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS:{
            if (action.payload.status === "SUCCESS") {

                let surveyRawDataResponseDownloadLink = "";

                if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" ) {
                    surveyRawDataResponseDownloadLink = AppUtils.getAppBaseUrl()  + action.payload.data.data;
                }

                return {
                    ...state,
                    surveyRawDataResponseDownloadStatus : HTTP_REQUEST_STATUS.SUCCESS,
                    surveyRawDataResponseUUID: "",
                    surveyRawDataResponseUUIDCounter: 0,
                    surveyRawDataResponseDownloadLink : surveyRawDataResponseDownloadLink
                };
            } else if (action.payload.status === "IN_PROGRESS") {
                return {
                    ...state,
                    surveyRawDataResponseUUID: action.payload.uuid,
                    surveyRawDataResponseUUIDCounter: state.surveyRawDataResponseUUIDCounter + 1,
                }
            } else if (action.payload.status === "NOT_FOUND") {
                return {
                    surveyRawDataResponseUUID: "",
                    surveyRawDataResponseUUIDCounter: 0,
                }
            }

            break;
        }

        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR:{
            return {
                ...state,
                surveyRawDataResponseDownloadStatus : HTTP_REQUEST_STATUS.FAIL,
                surveyRawDataResponseUUID : "",
                surveyRawDataResponseUUIDCounter : 0,
                alertMessageRawData: action.payload.error
            }
        }



        /**** survey raw data : Download rawdata in progress *****/
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS:
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS:
            return {
                ...state,
                surveyRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyRawDataResponseDownloadLink: ""
            };
            
        /**** survey raw data : Download rawdata none *****/
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE:
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE:
            return {
                ...state,
                surveyRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyRawDataResponseDownloadLink: ""
            };           
        
       /**** survey raw data : Download rawdata error *****/
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR:
            return {
                ...state,
                surveyRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessageRawData: AppUtils.getErrorStringFromResponse(action.payload)
            };

        /**** survey raw data : Download raw data : success ****/
        case SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS:
            let surveyRawDataResponseDownloadLink = "";

            if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" ) {
               surveyRawDataResponseDownloadLink = AppUtils.getAppBaseUrl()  + action.payload.data;
            }

            return {
                ...state,
                surveyRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                alertMessageRawData: action.payload.message,
                surveyRawDataResponseDownloadLink:surveyRawDataResponseDownloadLink    
            };

        default:
            return state;
    }
}


export default ReducerSurveyReportsRawdata;