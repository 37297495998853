import {SURVEY_HISTORY_GET_LIST, SURVEY_HISTORY_GET_LIST_FAIL, SURVEY_HISTORY_GET_LIST_IN_PROGRESS, SURVEY_HISTORY_GET_LIST_NONE, SURVEY_HISTORY_GET_LIST_SUCCESS} from "../../../constants/actions/survey/survey/actioinTypesSurveyHistory";

/****
 *Survey History: For View History list
 ****/

export function getSurveyHistoryList(params) {
    return {
        type: SURVEY_HISTORY_GET_LIST,
        payload: params
    };
}

/****
 *Survey History: List progress
 ****/
export function getSurveyHistoryListProgress() {
    return {
        type: SURVEY_HISTORY_GET_LIST_IN_PROGRESS
    };
}

/****
 *Survey History: List success
 ****/
export function getSurveyHistoryListSuccess(params) {
    return {
        type: SURVEY_HISTORY_GET_LIST_SUCCESS,
        payload: params
    };
}

/****
 *Survey History: List error
 ****/
export function getSurveyHistoryListError(params) {
    return {
        type: SURVEY_HISTORY_GET_LIST_FAIL,
        payload: params
    };
}

/****
 *Survey History: List None
 ****/
export function getSurveyHistoryListNone() {
    return {
        type: SURVEY_HISTORY_GET_LIST_NONE
    };
}
