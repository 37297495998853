/** Survey Invite for outreach api */
export const SURVEY_INVITE_OUTREACH = 'SURVEY_INVITE_OUTREACH';
export const SURVEY_INVITE_OUTREACH_NONE = 'SURVEY_INVITE_OUTREACH_NONE';
export const SURVEY_INVITE_OUTREACH_INPROGRESS = 'SURVEY_INVITE_OUTREACH_INPROGRESS';
export const SURVEY_INVITE_OUTREACH_SUCCESS = 'SURVEY_INVITE_OUTREACH_SUCCESS';
export const SURVEY_INVITE_OUTREACH_FAIL = 'SURVEY_INVITE_OUTREACH_FAIL';

/** Survey Invite create api */
export const SURVEY_INVITE_CREATE = 'SURVEY_INVITE_CREATE';
export const SURVEY_INVITE_CREATE_NONE = 'SURVEY_INVITE_CREATE_NONE';
export const SURVEY_INVITE_CREATE_INPROGRESS = 'SURVEY_INVITE_CREATE_INPROGRESS';
export const SURVEY_INVITE_CREATE_SUCCESS = 'SURVEY_INVITE_CREATE_SUCCESS';
export const SURVEY_INVITE_CREATE_FAIL = 'SURVEY_INVITE_CREATE_FAIL';

/** Survey Invite to get invite list*/  
export const SURVEY_INVITE_GET_LIST = 'SURVEY_INVITE_GET_LIST';
export const SURVEY_INVITE_GET_LIST_NONE = 'SURVEY_INVITE_GET_LIST_NONE';
export const SURVEY_INVITE_GET_LIST_INPROGRESS = 'SURVEY_INVITE_GET_LIST_INPROGRESS';
export const SURVEY_INVITE_GET_LIST_SUCCESS = 'SURVEY_INVITE_GET_LIST_SUCCESS';
export const SURVEY_INVITE_GET_LIST_FAIL = 'SURVEY_INVITE_GET_LIST_FAIL';

/** Survey Invite to get particular survey invite*/  
export const SURVEY_INVITE_GET_SPECIFIC_INVITE = 'SURVEY_INVITE_GET_SPECIFIC_INVITE';
export const SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE = 'SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE';
export const SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS = 'SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS';
export const SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS = 'SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS';
export const SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL = 'SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL';

/** Survey Invite invited media detail*/
export const SURVEY_INVITE_INVITED_MEDIA_DETAILS = 'SURVEY_INVITE_INVITED_MEDIA_DETAILS';  
export const SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS = 'SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS';  
export const SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS = 'SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS';  
export const SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL = 'SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL';  
export const SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE = 'SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE';  

/** Survey Invite to update particular survey invite*/  

export const SURVEY_INVITE_UPDATE_SPECIFIC_INVITE = 'SURVEY_INVITE_UPDATE_SPECIFIC_INVITE';
export const SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE = 'SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE';
export const SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS = 'SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS';
export const SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS = 'SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS';
export const SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL = 'SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL';

/** Survey Invite Delivery Report api */
export const SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT = 'SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT';
export const SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_NONE = 'SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_NONE';
export const SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_INPROGRESS = 'SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_INPROGRESS';
export const SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_SUCCESS = 'SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_SUCCESS';
export const SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_FAIL = 'SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT_FAIL';

/**Survey Invite Send Reminder api */
export const SURVEY_INVITE_SEND_REMINDER = 'SURVEY_INVITE_SEND_REMINDER';
export const SURVEY_INVITE_SEND_REMINDER_NONE = 'SURVEY_INVITE_SEND_REMINDER_NONE';
export const SURVEY_INVITE_SEND_REMINDER_INPROGRESS = 'SURVEY_INVITE_SEND_REMINDER_INPROGRESS';
export const SURVEY_INVITE_SEND_REMINDER_SUCCESS = 'SURVEY_INVITE_SEND_REMINDER_SUCCESS';
export const SURVEY_INVITE_SEND_REMINDER_FAIL = 'SURVEY_INVITE_SEND_REMINDER_FAIL';

/** Survey Invite to reload survey invite list*/  
export const SURVEY_INVITE_LIST_RELOAD = 'SURVEY_INVITE_LIST_RELOAD';

/** Survey Invite set selected inputs from single response */
export const SURVEY_INVITE_SET_SELECTED_INPUTS = 'SURVEY_INVITE_SET_SELECTED_INPUTS'

/** Survey Invite for resetting the value to default */
export const SURVEY_INVITE_RESET_SELECTED_INPUTS = 'SURVEY_INVITE_RESET_SELECTED_INPUTS'

/**Survey Invite create Status reset */
export const SURVEY_INVITE_CREATE_STATUS_RESET = 'SURVEY_INVITE_CREATE_STATUS_RESET';

/**Survey Invite outreach Status reset */
export const SURVEY_INVITE_OUTREACH_STATUS_RESET = 'SURVEY_INVITE_OUTREACH_STATUS_RESET';

/**Survey Invite update date range for survey period */
export const SURVEY_INVITE_UPDATE_SURVEY_PERIOD = 'SURVEY_INVITE_UPDATE_SURVEY_PERIOD';
export const SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS = 'SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS';
export const SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS = 'SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS';
export const SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL = 'SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL';
export const SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE = 'SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE';




