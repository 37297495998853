
export const CONTACT_SETTINGS_GET_CUSTOM_FIELDS = 'CONTACT_SETTINGS_GET_CUSTOM_FIELDS';
export const CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS = 'CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS';
export const CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR = 'CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR';
export const CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE = 'CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE';

export const CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES = 'CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES';
export const CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS = 'CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS';
export const CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR = 'CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR';
export const CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE = 'CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE';

