import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ISO_DATE_FORMAT } from 'constants/config';
import moment from 'moment-timezone';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class DatePicker extends React.Component {
  constructor(props) {

    super(props);

    let date = moment.tz(this.props.userTimeZone).format(ISO_DATE_FORMAT);

    const currentDate = new Date(date);

    this.state = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
  }

  componentDidMount() {
    const { initialDay, initialMonth, initialYear } = this.props;

    if (initialDay) {
      this.setState({ day: initialDay < 10 ? initialDay.toString().slice(1) : initialDay });
    }

    if (initialMonth) {
      this.setState({ month: initialMonth < 10 ? initialMonth.toString().slice(1) : initialMonth });
    }

    if (initialYear) {
      this.setState({ year: initialYear });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const { initialDay, initialMonth, initialYear } = this.props;
    const { day, month, year } = this.state;

    if (typeof prevProps.initialDay !== 'undefined' &&
      prevProps.initialDay !== initialDay &&
      typeof prevState.day !== 'undefined' &&
      prevState.day === day) {
        this.setState({ day: initialDay < 10 ? initialDay.toString().slice(1) : initialDay });
    }

    if (typeof prevProps.initialMonth !== 'undefined' &&
      prevProps.initialMonth !== initialMonth &&
      typeof prevState.month !== 'undefined' &&
      prevState.month === month) {
        this.setState({ month: initialMonth < 10 ? initialMonth.toString().slice(1) : initialMonth });
    }

    if (typeof prevProps.initialYear !== 'undefined' &&
      prevProps.initialYear !== initialYear &&
      typeof prevState.year !== 'undefined' &&
      prevState.year === year) {
        this.setState({ year: initialYear });
    }
  }

  handleDayChange = event => {
    const day = event.target.value;
    this.setState({ day }, () => {
      if (this.props.onDateChange) {
        this.props.onDateChange(this.state.day, this.state.month, this.state.year);
      }
    });
  };

  handleMonthChange = event => {
    const month = event.target.value;
    this.setState({ month }, () => {
      if (this.props.onDateChange) {
        this.props.onDateChange(this.state.day, this.state.month, this.state.year);
      }
    });
  };

  handleYearChange = event => {
    const year = event.target.value;
    this.setState({ year }, () => {
      if (this.props.onDateChange) {
        this.props.onDateChange(this.state.day, this.state.month, this.state.year);
      }
    });
  };

  getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  render() {
    const { classes } = this.props;
    const { day, month, year } = this.state;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();
    const daysInMonth = this.getDaysInMonth(month, year);

    return (
      <div className='row ml-1'>
        <FormControl className={classes.formControl} style={{minWidth: 100}}>
          <InputLabel id="day-label">Day</InputLabel>
          <Select
            required
            labelId="day-label"
            id="day"
            value={day}
            onChange={this.handleDayChange}
            MenuProps={{
              style: { zIndex: 35001 }
            }}
            //disabled={selectedOption === 'Immediate'}
          >
            {Array.from(Array(daysInMonth).keys()).map(num => {
              const selectedDay = num + 1;
              return (
                <MenuItem
                  key={selectedDay}
                  value={selectedDay}
                  disabled={year === currentYear && month === currentMonth && selectedDay < currentDay}
                >
                  {selectedDay < 10 ? `0${selectedDay}` : selectedDay}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} style={{minWidth: 100}}>
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            required
            labelId="month-label"
            id="month"
            value={month}
            onChange={this.handleMonthChange}
            MenuProps={{
              style: { zIndex: 35001 }
            }}
            //disabled={selectedOption === 'Immediate'}
          >
            {Array.from(Array(12).keys()).map(num => {
              const selectedMonth = num + 1;
              return (
                <MenuItem
                  key={selectedMonth}
                  value={selectedMonth}
                  disabled={year === currentYear && selectedMonth < currentMonth}
                >
                  {selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} style={{minWidth: 100}}>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            required
            labelId="year-label"
            id="year"
            value={year}
            onChange={this.handleYearChange}
            MenuProps={{
              style: { zIndex: 35001 }
            }}
            //disabled={selectedOption === 'Immediate'}
          >
            {Array.from(Array(50).keys()).map(num => {
              const selectedYear = num + currentYear;
              return (
                <MenuItem
                  key={selectedYear}
                  value={selectedYear}
                  disabled={selectedYear < currentYear}
                >
                  {selectedYear}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(DatePicker);
