import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import AppUtils from '../../../util/util';
import { HTTP_STATUS_CODE } from 'constants/config';
import { baseUrl } from 'util/keys';
import {
	getReportFilterCustomFieldsWithValuesError,
	getReportFilterCustomFieldsWithValuesSuccess,
	getAccountCustomFieldValuesSuccess,
	getAccountCustomFieldValuesError,
} from 'actions/reports/filters/actionsCustomFields';

import { getWorkerContactCustomFieldsWithValuesSuccess } from 'actions/wc/ActionsWorkerContacts';

import {
	URL_E_LEARNING_CUSTOM_FIELDS_WITH_VALUES,
	URL_ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
} from '../../../constants/Urls';
import {
	REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES,
	ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
} from '../../../constants/actions/reports/actionsCustomFields';

/** http : worker contact : to get custom fields with values **/
const httpRFGetCustomFieldsWithValues = async (data) =>
	await axios
		.get(
			`${URL_E_LEARNING_CUSTOM_FIELDS_WITH_VALUES}?client_id=${data.client_id}`,
			AppUtils.getHttpHeaders()
		)
		.then((response) => response)
		.catch((error) => {
			return error;
		});

/** http : Survey Template Quick Insights : get account custom fields**/
const httpGetAccountCustomFieldValues = async (url) => {
	return await axios
		.get(url, AppUtils.getHttpHeaders())
		.then((response) => response)
		.catch((error) => error);
};

/** worker contact : to get custom fields with values **/
function* handleHttpRFGetCustomFieldsWithValues({ payload }) {
	try {
		let result = yield call(httpRFGetCustomFieldsWithValues, payload);

		if (result.message) {
			if (result.response.data) {
				yield put(
					getReportFilterCustomFieldsWithValuesError(result.response.data)
				);
			} else {
				yield put(getReportFilterCustomFieldsWithValuesError(result.response));
			}
		} else {
			if (result.data) {
				yield put(getReportFilterCustomFieldsWithValuesSuccess(result.data));
				yield put(getWorkerContactCustomFieldsWithValuesSuccess(result.data));
			} else {
				yield put(getReportFilterCustomFieldsWithValuesSuccess(result));
				yield put(getWorkerContactCustomFieldsWithValuesSuccess(result));
			}
		}
	} catch (error) {
		yield put(getReportFilterCustomFieldsWithValuesError(error));
	}
}

/** http : Account custom field : http response handler **/
function* handleHttpGetAccountCustomFieldValues(payload) {
	try {
		const { parent_clientinfo_id } = payload.payload;
		let url =
			baseUrl +
			URL_ACCOUNT_CUSTOM_FIELD_WITH_VALUES +
			`?parent_clientinfo_id=${parent_clientinfo_id}`;
		let response = yield call(httpGetAccountCustomFieldValues, url);
		if (
			typeof response.status !== 'undefined' &&
			(response.status === HTTP_STATUS_CODE['SUCCESS'] ||
				response.status === HTTP_STATUS_CODE['OK'])
		) {
			yield put(getAccountCustomFieldValuesSuccess(response.data));
		} else {
			yield put(getAccountCustomFieldValuesError(response.message));
		}
	} catch (error) {
		yield put(getAccountCustomFieldValuesError(error));
	}
}

/** WC custom field fetch **/
export function* wcGetCustomFieldsWithValues() {
	yield takeEvery(
		REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES,
		handleHttpRFGetCustomFieldsWithValues
	);
}

/** Account custom field fetch **/
export function* getAccountCustomFieldValues() {
	yield takeEvery(
		ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
		handleHttpGetAccountCustomFieldValues
	);
}

export default function* rootSaga() {
	yield all([
		fork(wcGetCustomFieldsWithValues),
		fork(getAccountCustomFieldValues),
	]);
}
