import {
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS,
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_ERROR,
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS,
    SURVEY_NEW_TEMPLATE_HIDE_LOADER,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_NONE,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO,
    SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE,
    SURVEY_NEW_TEMPLATE_SET_TEMPLATE,
    SURVEY_NEW_TEMPLATE_SHOW_LOADER,
    SURVEY_TEMPLATE_GET_QUESTIONS,
    SURVEY_TEMPLATE_GET_QUESTIONS_ERROR,
    SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUESTIONS_NONE,
    SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS,
    SURVEY_TEMPLATE_SET_STATUS,
    SURVEY_TEMPLATE_SET_STATUS_ERROR,
    SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS,
    SURVEY_TEMPLATE_SET_STATUS_NONE,
    SURVEY_TEMPLATE_SET_STATUS_SUCCESS,
    SURVEY_TEMPLATE_SET_STEP,
    SURVEY_TEMPLATE_REDEPLOY, 
    SURVEY_TEMPLATE_REDEPLOY_SUCCESS, 
    SURVEY_TEMPLATE_REDEPLOY_ERROR,
    SURVEY_TEMPLATE_REDEPLOY_NONE,
    SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS,
    SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
    SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE
} from "../../../constants/ActionTypes";

import {
    SURVEY_TEMPLATE_GET_NEXT_LIST,
    SURVEY_TEMPLATE_GET_NEXT_LIST_NONE,
    SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS,
    SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";


/****
 Survey template : set the step
 ****/
export function setSurveyTemplateStep(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STEP,
        payload : params
    };
}

/****
  Survey template : to get questions
 ****/
export function getSurveyTemplateQuestions(params) {
    return {
        type: SURVEY_TEMPLATE_GET_QUESTIONS,
        payload : params
    };
}


/****
  Survey template : to get questions : success
 ****/
export function getSurveyTemplateQuestionsSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS,
        payload : params
    };
}

/****
  Survey template : to get questions : error
 ****/
export function getSurveyTemplateQuestionsError(params) {
    return {
        type: SURVEY_TEMPLATE_GET_QUESTIONS_ERROR,
        payload : params
    };
}

/****
  Survey template : to get questions: in progress
 ****/
export function getSurveyTemplateQuestionsInProgress() {
    return {
        type: SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS
    };
}

/****
  Survey template : to get questions: none
 ****/
export function getSurveyTemplateQuestionsNone() {
    return {
        type: SURVEY_TEMPLATE_GET_QUESTIONS_NONE
    };
}



/****
  Survey template : to set current template
 ****/
export function setSurveyTemplate(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_SET_TEMPLATE,
        payload : params
    };
}

/****
  Survey template : get all records
 ****/
export function surveyTemplateGetAllRecords(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS,
        payload : params
    };
}

/****
  Survey template : get all records : success
 ****/
export function surveyTemplateGetAllRecordsSuccess(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
  Survey template : get all records : success
 ****/
export function surveyTemplateGetAllRecordsError(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}

/****
  Survey template : get all records : in progress
 ****/
export function surveyTemplateGetAllRecordsInProgress() {
    return {
        type: SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
  Survey template : to show loader
 ****/
export function showSurveyTemplateLoader() {
    return {
        type: SURVEY_NEW_TEMPLATE_SHOW_LOADER
    };
}

/****
  Survey template : to hide loader
 ****/
export function hideSurveyTemplateLoader() {
    return {
        type: SURVEY_NEW_TEMPLATE_HIDE_LOADER
    };
}

/****
  Survey template : save status to in none
 ****/
export function saveSurveyTemplateProgressNone(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_NONE,
        payload : params
    };
}

/****
 Survey template : save status to in progress
 ****/
export function saveSurveyTemplateInProgress(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
        payload : params
    };
}

/****
 Survey template : record save error
 ****/
export function saveSurveyTemplateInfoError(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR,
        payload : params
    };
}

/****
 Survey template : record save success
 ****/
export function saveSurveyTemplateInfoSuccess(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS,
        payload : params
    };
}

/****
 Survey template : record save
 ****/
export function saveSurveyTemplateInfo(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO,
        payload : params
    };
}


/****
 Survey template : record save
 ****/
export function updateSurveyTemplateInfo(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO,
        payload : params
    };
}

/****
 Survey template : to set survey source type
 ****/
export function setSurveyTemplateSourceType(params) {
    return {
        type: SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE,
        payload : params
    };
}

/****
 Survey template : to set status
 ****/
export function setSurveyTemplateStatus(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STATUS,
        payload : params
    };
}


/****
 Survey template : to set status : in progress
 ****/
export function setSurveyTemplateStatusInProgress(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS,
        payload : params
    };
}

/****
 Survey template : to set status : none
 ****/
export function setSurveyTemplateStatusNone(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STATUS_NONE,
        payload : params
    };
}

/****
 Survey template : to set status : error
 ****/
export function setSurveyTemplateStatusError(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STATUS_ERROR,
        payload : params
    };
}

/****
 Survey template : to set status : success
 ****/
export function setSurveyTemplateStatusSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_SET_STATUS_SUCCESS,
        payload : params
    };
}


/****
 Survey template : paginated list : Error
 ****/
export function getSurveyTemplatePaginatedListError(params) {
    return {
        type: SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR,
        payload : params
    };
}

/****
 Survey template : paginated list : success
 ****/
export function getSurveyTemplatePaginatedListSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS,
        payload : params
    };
}

/****
 Survey template  : paginated list : none
 ****/
export function getSurveyTemplatePaginatedListNone() {
    return {
        type: SURVEY_TEMPLATE_GET_NEXT_LIST_NONE
    };
}

/****
 Survey template : paginated list :
 ****/
export function getSurveyTemplatePaginatedList(params) {
    return {
        type: SURVEY_TEMPLATE_GET_NEXT_LIST,
        payload : params
    };
}

/****
 Survey template : get record by id : Error
 ****/
export function getSurveyTemplateByIdError(params) {
    return {
        type: SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR,
        payload : params
    };
}

/****
 Survey template : get record by id : success
 ****/
export function getSurveyTemplateByIdSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS,
        payload : params
    };
}

/****
 Survey template : get record by id : none
 ****/
export function getSurveyTemplateByIdNone() {
    return {
        type: SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE
    };
}

/****
 Survey template : get record by id
 ****/
export function getSurveyTemplateById(params) {
    return {
        type: SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID,
        payload : params
    };
}

/****
Survey template redeploy : record save
 ****/
export function redeploySurveyTemplate(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY,
        payload: params
    };
}

/****
    Survey template redeploy : record save success
 ****/
export function redeploySurveyTemplateSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_SUCCESS,
        payload: params
    };
}

/****
Survey template redeploy : record save Error
****/
export function redeploySurveyTemplateError(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_ERROR,
        payload: params
    };
}

/****
Survey template redeploy : none
****/
export function redeploySurveyTemplateNone() {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_NONE
    };
}

/****
Survey template Redeploy : get series questions
 ****/
export function getSurveyTemplateRedeploySeriesQuestions(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS,
        payload: params
    };
}

/****
Survey template Redeploy : get series questions success
 ****/
export function getSurveyTemplateRedeploySeriesQuestionsSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
        payload: params
    };
}

/****
Survey template Redeploy : get series questions Error
 ****/
export function getSurveyTemplateRedeploySeriesQuestionsError(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
        payload: params
    };
}

/****
Survey template Redeploy : get series questions none
 ****/
export function getSurveyTemplateRedeploySeriesQuestionsNone() {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE
    };
}

/****
Survey template Redeploy : save series questions
 ****/
 export function saveSurveyTemplateRedeploySeriesQuestions(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS,
        payload: params
    };
}

/****
Survey template Redeploy : save series questions success
 ****/
export function saveSurveyTemplateRedeploySeriesQuestionsSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
        payload: params
    };
}

/****
Survey template Redeploy : save series questions Error
 ****/
export function saveSurveyTemplateRedeploySeriesQuestionsError(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
        payload: params
    };
}

/****
Survey template Redeploy : save series questions none
 ****/
export function saveSurveyTemplateRedeploySeriesQuestionsNone() {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE
    };
}

/***
Survey template Redeploy : to get line graph details
 ****/
 export function getSurveyTemplateRedeployQuestionSummary(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY,
        payload: params
    }
}

/***
Survey template Redeploy : to get line graph details :  Error
 ****/
export function getSurveyTemplateRedeployQuestionSummaryError(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
        payload: params
    }
}

/***
Survey template Redeploy : to get line graph details :  success
 ****/
export function getSurveyTemplateRedeployQuestionSummarySuccess(params) {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
        payload: params
    }
}

/***
Survey template Redeploy : to get line graph details : none
 ****/
export function getSurveyTemplateRedeployQuestionSummaryNone() {
    return {
        type: SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE
    }
}
