/**Download excel template for reset registration */
export const RESET_REGISTRATION_DOWNLOAD_TEMPLATE = "RESET_REGISTRATION_DOWNLOAD_TEMPLATE";
export const RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS = "RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS";
export const RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR = "RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR";
export const RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS = "RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS";
export const RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE = "RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE";

/**Verify file upload */
export const RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION = "RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION";
export const RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS = "RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS";
export const RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR = "RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR";
export const RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS = "RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS";
export const RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE = "RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE";

/**reset registration list */
export const RESET_REGISTRATION_GET_LIST = "RESET_REGISTRATION_GET_LIST";
export const RESET_REGISTRATION_GET_LIST_SUCCESS = "RESET_REGISTRATION_GET_LIST_SUCCESS";
export const RESET_REGISTRATION_GET_LIST_ERROR = "RESET_REGISTRATION_GET_LIST_ERROR";
export const RESET_REGISTRATION_GET_LIST_INPROGRESS = "RESET_REGISTRATION_GET_LIST_INPROGRESS";
export const RESET_REGISTRATION_GET_LIST_NONE = "RESET_REGISTRATION_GET_LIST_NONE";

/**update summary data */
export const UPDATE_RESET_REGISTRATION_SUMMARY_DATA = 'UPDATE_RESET_REGISTRATION_SUMMARY_DATA';

/**Download Report */
export const RESET_REGISTRATION_DOWNLOAD_REPORT = 'RESET_REGISTRATION_DOWNLOAD_REPORT';
export const RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS = 'RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS';
export const RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR = 'RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR';

/**reset state */
export const RESET_REGISTRATION_GLOBAL_STATE = 'RESET_REGISTRATION_GLOBAL_STATE';