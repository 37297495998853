import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    CLIENT_PIC_GET_ALL_RECORDS,
    CLIENT_PIC_NEW_EDIT_SAVE,
    CLIENT_PIC_UPDATE_STATUS
} from "../../../constants/ActionTypes";

import {
    getClientPICSuccess,
    saveClientPICError,
    saveClientPICSuccess,
    clientPICUpdateStatusSuccess
} from "../../../../src/actions/adminSettings/clientPIC/ActionsClientPIC";

import {
    URL_ADMIN_SETTINGS_CLIENTPIC_CREATE,
    URL_ADMIN_SETTINGS_CLIENTPIC_LIST,
    URL_ADMIN_SETTINGS_CLIENTPIC_DELETE
    }
    from "../../../constants/Urls";

import AppUtils from "../../../util/util";


/************ methods : http *******************/

/****
 Https : Client Pic Save
 ****/
const httpSaveClientPIC = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_SETTINGS_CLIENTPIC_CREATE, data,AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/*****
 Https: Edit ClientPic 
 *******/

 const httpUpdateClientPIC = async (data) =>
 await axios.patch(baseUrl + URL_ADMIN_SETTINGS_CLIENTPIC_CREATE, data,AppUtils.getHttpHeaders())
     .then(authUser => authUser)
     .catch(error => error);

/****
 Https : get ClientPic
 ****/
const httpGetClientPIC = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_SETTINGS_CLIENTPIC_LIST, data,AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/***
 Https: Update Status
 * ***/

const httpUpdateStatusClientPIC = async (data) =>
    await axios.patch(baseUrl + URL_ADMIN_SETTINGS_CLIENTPIC_DELETE,data,AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/************ methods : http response handler *******************/

/** http : ClientPIC : http response handler **/

function* handleHttpSaveClientPIC({payload}) {
    try {

        let dataValue = yield call(payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateClientPIC : httpSaveClientPIC, payload);

        if (dataValue.message) {
            yield put(saveClientPICError(dataValue.message));
        } else {
            yield put(saveClientPICSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveClientPICError(error));
    }
}

/** http : ClientPIC list : http response handler **/

function* handleHttpGetClientPIC({payload}) {
    try {

        let dataValue = yield call(httpGetClientPIC, payload);

        if (dataValue.message) {
            yield put(saveClientPICError(dataValue.message));
        } else {
            yield put(getClientPICSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(saveClientPICError(error));
    }
}

/** http : Client PIC status update : http response handler **/

function* handleHttpUpdateStatusClientPIC({payload}) {
    try {
        let dataValue = yield call(httpUpdateStatusClientPIC, payload);
		
        if (dataValue.message) {
            yield put(saveClientPICError(dataValue.message));
        } else {
            yield put(clientPICUpdateStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveClientPICError(error));
    }

}


/************ methods : export access *******************/

/****
 Receivers : Client Pic save
 ****/

export function* saveClientPIC() {
    yield takeEvery(CLIENT_PIC_NEW_EDIT_SAVE, handleHttpSaveClientPIC);
}

/****
Receivers: Client Pic get list
****/

export function* getClientPIC() {
    yield takeEvery(CLIENT_PIC_GET_ALL_RECORDS, handleHttpGetClientPIC);
}

/****
Receivers: Client Pic Update
****/

export function* updateClientPIC() {
    yield takeEvery(CLIENT_PIC_UPDATE_STATUS, handleHttpUpdateStatusClientPIC)

}



export default function* rootSaga() {
    yield all([
        fork(saveClientPIC),
        fork(getClientPIC),
        fork(updateClientPIC),
         ]);
}


