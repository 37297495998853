import {
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
    SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE,
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE
} from "../../../constants/ActionTypes";

/****
 Survey Reporting Category : status update
 ****/
export function surveyReportingCategoryUpdateStatus(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_UPDATE_STATUS,
        payload : params
    };
}

/****
 Survey Reporting Category : status update in progress
 ****/
export function surveyReportingCategoryUpdateStatusInProgress() {
    return {
        type: SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
 Survey Reporting Category : status update to none
 ****/
export function surveyReportingCategoryUpdateStatusNone() {
    return {
        type: SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE
    };
}

/****
 Survey Reporting Category : status update : success
 ****/
export function surveyReportingCategoryUpdateStatusSuccess(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}

/****
 Survey Reporting Category : status update : error
 ****/
export function surveyReportingCategoryUpdateStatusError(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR,
        payload : params
    };
}


/****
 Survey Reporting Category : to get all records
 ****/
export function getSurveyReportingCategories(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS,
         payload : params
    };
}

/****
 Survey Reporting Category : to get all records : in progress
 ****/
export function getSurveyReportingCategoriesInProgress() {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS
    };
}


/****
 Survey Reporting Category : to get all records : error
 ****/
export function getSurveyReportingCategoriesError(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}

/****
 Survey Reporting Category : to get all records : success
 ****/
export function getSurveyReportingCategoriesSuccess(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Survey Reporting Category : to reset error popup status
 ****/
export function resetErrorMessage( params ) {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR,
        payload : params
    };
}

/****
 Survey Reporting Category : to reset error popup status
 ****/
export function resetReportingCategorySaveStatus() {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR
    };
}

/****
 Survey Reporting Category : to hide new edit popup
 ****/
export function hideNewEditSurveyReportingCategoryPopup( params ) {
    return {
        type: SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}

/****
 Survey Reporting Category : to display new edit popup
 ****/
export function displayNewEditSurveyReportingCategoryPopup( params ) {
    return {
        type: SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/**** Survey Reporting Category : save action ****/
export function saveSurveyReportingCategory( params ) {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Survey Reporting Category : save success action ****/
export function saveSurveyReportingCategorySuccess(data) {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Survey Reporting Category : save error action ****/
export function saveSurveyReportingCategoryError(data) {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Survey Reporting Category : save in progress action ****/
export function saveSurveyReportingCategoryProgress() {
    return {
        type: SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS
    };
}

/****
 Survey Reporting Category : to get all records
 ****/
export function getActiveSurveyReportingCategoriesList(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS,
         payload : params
    };
}

/****
 Survey Reporting Category : to get all active records : success
 ****/
export function getActiveSurveyReportingCategoriesSuccess(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Survey Reporting Category : to get all records by language
 ****/
export function getActiveSurveyReportingCategoriesListByLanguageSelected(params) {
    return {
        type: SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE,
         payload : params
    };
}