import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {APP_NAVIGATION_PATHS} from "../../constants/config";
import StorageHandler from "../../util/storageHandler";

const popoverStyle = {
    marginTop: 44,
    minWidth: 120,
    paddingTop: 0,
    paddingBottom: 0
};

class UserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
    };

    handleClick = event => {
        try {
            this.setState({open: true, anchorEl: event.currentTarget});
        } catch (ex) {
            console.error("handleClick  : error : ", ex);
        }
    };

    handleRequestClose = () => {
        try {
            this.setState({open: false});
        } catch (ex) {
            console.error("handleRequestClose : error : ", ex);
        }
    };

    handleSettingsClick = () => {
        try {
            this.setState({open: false});
            this.props.history.push(APP_NAVIGATION_PATHS.USER_SETTINGS);
        } catch (ex) {
            console.error("handleSettingsClick : error : ", ex);
        }
    };

    handleUserSignOut = () => {
        try {
            const {user} = this.props;
            StorageHandler.eraseCookie("access_token");
            this.handleRequestClose();
            StorageHandler.saveUserState(user['user_name'], {});
            this.props.userSignOut();
        } catch (ex) {
            console.error("handleUserSignOut : error : ", ex);
        }
    };

    render() {

        const {avatarName} = this.props;

        return (
            <span className="user-profile d-flex flex-row align-items-center" style={{marginLeft: "22px"}}>
                <Avatar className="bg-primary size-50 mr-2" onClick={this.handleClick}><h3 className="m-0 text-white">{avatarName}</h3></Avatar>

                <Menu className="user-info"
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.open}
                      onClose={this.handleRequestClose}
                      PaperProps={{style: popoverStyle}}
                >
                    <MenuItem onClick={this.handleSettingsClick}>
                        <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/><IntlMessages id="popup.setting"/>
                    </MenuItem>
                    <MenuItem onClick={this.handleUserSignOut}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/><IntlMessages id="popup.logout"/>
                    </MenuItem>
                </Menu>
            </span>);
    }
}

const mapStateToProps = ({settings, auth}) => {
    const {locale} = settings;
    const {
        user,
        avatarName
    } = auth;

    return {
        locale,
        user,
        avatarName
    }
};

export default connect(mapStateToProps, {
    userSignOut
})(UserInfo);


