import React from 'react';
import { COMPANY_POST_REPORTS_DOWNLOAD, POST_TYPE, UUID_STATUS } from "constants/config";
import AppUtils from "../../../util/util";
import {
    REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE,
    REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE
} from '../../../constants/actions/reports/actionsReportFilters';
import {
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    AUTH_UPDATE_MENU_CONFIGURATION
} from "../../../constants/ActionTypes";
import { HTTP_REQUEST_STATUS } from "../../../constants/constants";
import IntlMessages from "util/IntlMessages";

let postType = [
    {
        key: POST_TYPE.ALL,
        value: POST_TYPE.ALL,
        label: <IntlMessages id="DIC_COMPANY_POST_TYPE_ALL" />,
        isAll: true
    },
    {
        key: POST_TYPE.NEWS_LETTER,
        value: POST_TYPE.NEWS_LETTER,
        label: <IntlMessages id="DIC_COMPANY_POST_TYPE_NEWS_LETTER" />
    },
    {
        key: POST_TYPE.SURVEY,
        value: POST_TYPE.SURVEY,
        label: <IntlMessages id="DIC_COMPANY_POST_TYPE_SURVEY" />
    },
    {
        key: POST_TYPE.BROADCAST,
        value: POST_TYPE.BROADCAST,
        label: <IntlMessages id="DIC_COMPANY_POST_TYPE_BROADCAST" />
    }
];

const companyPostDownloadFilters = [
    {
        id : "COMPANY_POST_RAW_DATA_DOWNLOAD",
        label: "DIC_REPORTS_COMPANY_POST_RAW_DATA_DOWNLOAD",
        value: COMPANY_POST_REPORTS_DOWNLOAD.DOWNLOAD_RAW_DATA,
        icon: "excelIcon",
        isConfig : true
    }
];

let selectionRange = AppUtils.getDefaultDateRangeSelection();
let timeResult = AppUtils.getReportsDates();

const initialData = {
    postType: postType,
    companyPostSelectionRange: selectionRange,
    reportEndDateStr: timeResult['end_date_str'],
    reportStartDateStr: timeResult['start_date_str'],
    companyPostRefreshCounter: 0,
    tableHeaders: [],
    tableData: [],
    loader: false,
    alertMessage: '',
    companyPostRawDataFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    companyPostRawDataResponseUUID: "",
    companyPostRawDataResponseUUIDCounter: 0,
    companyPostDownloadFilters: [],
    companyPostRawDataDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    companyPostRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    companyPostRawDataResponseDownloadLink: "",
    companyPostRawDataDownloadUUID: "",
    companyPostRawDataDownloadUUIDCounter: 0
};

const ReducerCompanyPostReports = (state = initialData, action) => {

    switch (action.type) {

        case AUTH_UPDATE_MENU_CONFIGURATION:{
            return {
                ...state,
                companyPostDownloadFilters : updateCompanyPostDownloadFilters(companyPostDownloadFilters)
            }
        }

        /**to update company post date range */
        case REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE: {
            return {
                ...state,
                companyPostSelectionRange: action.payload
            };
        }

        /** to set company post date range**/
        case REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE: {

            let timeResult = AppUtils.getReportsDates(action.payload.startDate, action.payload.endDate);

            return {
                ...state,
                reportEndDateStr: timeResult['end_date_str'],
                reportStartDateStr: timeResult['start_date_str'],
                companyPostRefreshCounter: state.companyPostRefreshCounter + 1
            };
        }

        /***************************************CompanyPost Raw data Responses UUID/status**************************** */

        /** to set state, on successful api call**/
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS:
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS: {

            let tableHeaders = [];
            let tableData = [];
            let companyPostRawDataResponseUUID = state.companyPostRawDataResponseUUID;
            let companyPostRawDataResponseUUIDCounter = state.companyPostRawDataResponseUUIDCounter;
            let companyPostRawDataFetchStatus = state.companyPostRawDataFetchStatus;
            let loader = state.loader;

            if (action.payload && action.payload.message) {

                let message = action.payload.message;

                switch (message['status']) {
                    case UUID_STATUS.SUCCESS: {

                        let data = message.data && message.data.data;

                        if (data) {
                            tableHeaders = getCompanyPostRawDataTableHeaders(data);
                            tableData = data;
                        }
                        companyPostRawDataResponseUUID = "";
                        companyPostRawDataFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        loader = false;
                        break;
                    }
                    case UUID_STATUS.IN_PROGRESS: {
                        companyPostRawDataResponseUUID = message['uuid'];
                        companyPostRawDataResponseUUIDCounter += 1;
                        break;
                    }
                    case UUID_STATUS.NOT_FOUND: {
                        companyPostRawDataResponseUUIDCounter = 0;
                        companyPostRawDataResponseUUID = "";
                        companyPostRawDataFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        loader = false;
                        break;
                    }
                }
            }

            return {
                ...state,
                tableHeaders: tableHeaders,
                tableData: tableData,
                companyPostRawDataFetchStatus: companyPostRawDataFetchStatus,
                companyPostRawDataResponseUUID: companyPostRawDataResponseUUID,
                companyPostRawDataResponseUUIDCounter: companyPostRawDataResponseUUIDCounter,
                loader: loader
            };
        }

        /** to set state, on failed api call**/
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR:
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR: {
            return {
                ...state,
                alertMessage: action.payload,
                companyPostRawDataFetchStatus: HTTP_REQUEST_STATUS.FAIL,
                loader: false,
                tableHeaders: [],
                tableData: [],
                companyPostRawDataResponseUUID: "",
                companyPostRawDataResponseUUIDCounter: 0,
            };
        }

        /** to set state, for none**/
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE: {
            return {
                ...state,
                companyPostRawDataFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false,
                tableHeaders: [],
                tableData: [],
                companyPostRawDataResponseUUID: "",
                companyPostRawDataResponseUUIDCounter: 0,
            };
        }

        /** to set state, on api call in progress**/
        case COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS: {
            return {
                ...state,
                companyPostRawDataFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                tableHeaders: [],
                tableData: [],
                companyPostRawDataResponseUUID: "",
                companyPostRawDataResponseUUIDCounter: 0,
                loader: true
            };
        }

        /****************************************CompanyPost Raw data Download UUID/status****************************** */

        /** to set state, on api call for raw data UUID success**/
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS:
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS: {

            let companyPostRawDataResponseDownloadLink = state.companyPostRawDataResponseDownloadLink;
            let companyPostRawDataDownloadUUID = state.companyPostRawDataDownloadUUID;
            let companyPostRawDataDownloadUUIDCounter = state.companyPostRawDataDownloadUUIDCounter;
            let loader = state.loader;
            let companyPostRawDataDownloadUUIDStatus = state.companyPostRawDataDownloadUUIDStatus;

            if (action.payload && action.payload.message) {

                let message = action.payload.message;

                switch (message['status']) {
                    case UUID_STATUS.SUCCESS: {
                        companyPostRawDataResponseDownloadLink = AppUtils.getAppBaseUrl() + action.payload.message.data.data;
                        companyPostRawDataResponseDownloadLink = AppUtils.redirectDownloadProdToReports(companyPostRawDataResponseDownloadLink);
                        companyPostRawDataDownloadUUID = "";
                        loader = false;
                        companyPostRawDataDownloadUUIDStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        break;
                    }
                    case UUID_STATUS.IN_PROGRESS: {
                        companyPostRawDataDownloadUUID = action.payload.message.uuid;
                        companyPostRawDataDownloadUUIDCounter += 1;
                        break;
                    }
                    case UUID_STATUS.NOT_FOUND: {
                        companyPostRawDataDownloadUUID = "";
                        companyPostRawDataResponseDownloadLink = "";
                        companyPostRawDataDownloadUUIDStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        loader = false;
                        break;
                    }
                }

            }

            return {
                ...state,
                companyPostRawDataDownloadUUIDStatus: companyPostRawDataDownloadUUIDStatus,
                companyPostRawDataResponseDownloadLink: companyPostRawDataResponseDownloadLink,
                companyPostRawDataDownloadUUID: companyPostRawDataDownloadUUID,
                companyPostRawDataDownloadUUIDCounter: companyPostRawDataDownloadUUIDCounter,
                loader: loader
            };
        }

        /** to set state, on api call for raw data UUID in progress**/
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS: {
            return {
                ...state,
                companyPostRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                companyPostRawDataResponseDownloadLink: "",
                companyPostRawDataDownloadUUIDCounter: 0,
                companyPostRawDataDownloadUUID: "",
                loader: true
            };
        }

        /** to set state, raw data UUID none**/
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE: {
            return {
                ...state,
                companyPostRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                companyPostRawDataResponseDownloadLink: "",
                companyPostRawDataDownloadUUID: "",
                companyPostRawDataDownloadUUIDCounter: 0
            };
        }

        /** to set state, on api call for raw data UUID failure**/
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR:
        case COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR: {
            return {
                ...state,
                companyPostRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.FAIL,
                companyPostRawDataDownloadUUID: "",
                companyPostRawDataDownloadUUIDCounter: 0,
                alertMessage: action.payload
            };
        }

        default:
            return {
                ...state
            };
    }
};

/****To get the table Headers ****/
function getCompanyPostRawDataTableHeaders(data) {

    let headers = [];

    try {
        if (data && data.length > 0) {
            headers = Object.keys(data[0]);
        }
    } catch (ex) {
        console.error('getCompanyPostRawDataTableHeaders : Error :' + ex);
    }
    return headers;
}

function updateCompanyPostDownloadFilters(data) {
    let result = [];
    try {
        let isValid;
        let moduleKey;

        for( let i = 0; i < data.length; i++ ) {

            isValid = true;

            if ( data[i]["isConfig"] ) {
                moduleKey = AppUtils.getModuleKeyBasedOnKey(data[i]["id"]);

                if ( moduleKey !== ""){
                    isValid = AppUtils.isModuleHasAccess(moduleKey);
                }
            }

            if ( isValid ) {
                result.push(data[i]);
            }
        }

    } catch (ex) {
        console.error("updateCompanyPostDownloadFilters : error : ", ex);
    }
    return result;
}

export default ReducerCompanyPostReports;