import IntlMessages from "../../../../util/IntlMessages";
import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import commonStyles from "../../../../util/commonStyle";

import {
    TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS,
    TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP,
    TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP,
    TRANSLATION_FONT_LIST_SORT,
    TRANSLATION_FONT_NEW_EDIT_SAVE,
    TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_FONT_NEW_EDIT_SAVE_NONE,
    TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS,
    TRANSLATION_FONT_UPDATE_STATUS,
    TRANSLATION_FONT_UPDATE_STATUS_ERROR,
    TRANSLATION_FONT_UPDATE_STATUS_NONE,
    TRANSLATION_FONT_UPDATE_STATUS_SUCCESS,
} from "../../../../constants/ActionTypes";
import {TABLE_SORT_CONFIG} from "../../../../constants/config";


const translationFontsTblHeader = [

    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_FONTS_SL_NO"/>
    },

    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_FONTS_NAME"/>
    },
    {
        id: 'class_name',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_FONTS_CLASS_NAME"/>
    },
    {
        id: 'font_file_name',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_FONTS_FILE_NAME"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_FONTS_ACTIONS"/>
    },
];

const translationFontItems = [
    {
        'key': 'fontName',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_FONTS_NAME",
        'isMandatory': true,
    },
    {
        'key': 'fontClassName',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_FONTS_CLASS_NAME",
        'isMandatory': true,

    },
    {
        'key': 'fontFile',
        'type': 'file',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_FONTS_FILE"
    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'style': {
            paddingBottom: "10%",
            marginTop: "1%",
            marginLeft: "-10px"
        },
        'localisation': "DIC_SETTINGS_TRANSLATION_FONTS_IS_ACTIVE"
    },
];

const initialData = {
    translationFontsTblHeader: translationFontsTblHeader,
    translationFontItems: translationFontItems,
    translationFontSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationFontUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationFontsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    listSortOrder: TABLE_SORT_CONFIG.asc,
    isPopUpVisible: false,
    alertMessage: '',
    selectedItem: null,
    translationFontsData: [],
    translationActiveFonts: []
};

const ReducerTranslationFonts = (state = initialData, action) => {
    switch (action.type) {

        case TRANSLATION_FONT_LIST_SORT: {
            let order = action.payload.order;
            let orderBy = action.payload.orderBy;
            let translationFontsData = state.translationFontsData;

            if (translationFontsData.length > 0) {
                translationFontsData = order === TABLE_SORT_CONFIG.desc ? translationFontsData.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)) : translationFontsData.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
            }

            return {
                ...state,
                listSortOrder: order,
                translationFontsData: translationFontsData
            }
        }

        case TRANSLATION_FONT_UPDATE_STATUS_SUCCESS:{

            return {
                ...state,
                translationFontUpdateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }

        case TRANSLATION_FONT_UPDATE_STATUS_ERROR:{
            return {
                ...state,
                translationFontUpdateStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error
            }
        }

        case TRANSLATION_FONT_UPDATE_STATUS:{

            return {
                ...state,
                translationFontUpdateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case TRANSLATION_FONT_UPDATE_STATUS_NONE:
            return {
                ...state,
                translationFontUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            };

        case TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                translationFontUpdateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            };


        case TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS:

            let data = action.payload.data.data;
            let order = action.payload.order;
            let orderBy = action.payload.orderBy;
            let translationFontsData = state.translationFontsData;

            if (data.length > 0) {
                translationFontsData = order === TABLE_SORT_CONFIG.desc ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)) : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
            }

            let translationActiveFonts = generateActiveFontsData(data);

            return {
                ...state,
                translationFontsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                translationFontsData: translationFontsData,
                translationActiveFonts: translationActiveFonts
            };

        /**** Translation font save error ****/
        case TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isPopUpVisible: false,
                selectedItem: null,
                translationFontSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        /**** Translation font save success ****/
        case TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isPopUpVisible: false,
                selectedItem: null,
                translationFontSaveStatus: HTTP_REQUEST_STATUS.SUCCESS
            };

        /**** Translation font http status to not started ****/
        case TRANSLATION_FONT_NEW_EDIT_SAVE_NONE:
            return {
                ...state,
                translationFontSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        /**** translation font http status to in progress ****/
        case TRANSLATION_FONT_NEW_EDIT_SAVE:
            return {
                ...state,
                translationFontSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        /**** to display translation font popup ****/

        case TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedItem: action.payload
            };

        /**** to hide translation font  popup ****/

        case TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false
            };

        default:
            return state;

    }
}

function generateActiveFontsData(data) {
    let result = [];

    try {
        for ( let i = 0 ; i < data.length; i++ ){
            result.push({
                value: data[i]['id'],
                id: data[i]['id'],
                key: data[i]['id'],
                label: data[i]['name'],
                class_name : data[i]['class_name'],
                font_file_name: data[i]['font_file_name'],
                is_active: data[i]['is_active'],
                name: data[i]['name']
            });
        }

    } catch (ex) {
        console.error("generateActiveFontsData : error : ", ex);
    }

    return result;
}


export default ReducerTranslationFonts;