import React from 'react';
import { ReactComponent as DownloadIcon } from 'assets/vendors/wovo/downloadIcon.svg';
import {DOWNLOAD_ICON_DEFAULT_COLOR} from '../../constants/customUIConfig';

const DownloadButton = ({ className, width="20", height="21", colorFill=DOWNLOAD_ICON_DEFAULT_COLOR }) =>
    <div className={className}>
        <DownloadIcon
            width={width}
            height={height}
            fill={colorFill}>
        </DownloadIcon>
    </div>

export default DownloadButton;