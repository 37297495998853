//Worker Contact Custom Fields

export const REPORT_FILTERS_CUSTOM_FIELD_SELECTED =
	'REPORT_FILTERS_CUSTOM_FIELD_SELECTED';
export const REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES =
	'REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES';
export const REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS =
	'REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS';
export const REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR =
	'REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR';
export const REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE =
	'REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE';

//Account Custom Fields

export const ACCOUNT_CUSTOM_FIELD_WITH_VALUES =
	'ACCOUNT_CUSTOM_FIELD_WITH_VALUES';
export const ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR =
	'ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR';
export const ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS =
	'ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS';
export const ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE =
	'ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE';
export const UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES =
	'UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES';
export const REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET =
	'REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET';
