import axios from "axios";
import {
    URL_COUNTRY_GET_ALL_RECORDS,
    URL_WOVO_CLIENTS_GET_CLIENT_BY_KEY,
    URL_WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER,
    URL_WOVO_CLIENTS_GET_PAGINATED_LIST,
    URL_WOVO_CLIENTS_SAVE_CLIENT
} from "../../constants/Urls";
import AppUtils from "../../util/util";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    getAllCountriesListError,
    getAllCountriesListSuccess,
    wovoClientsGetClientByKeyError,
    wovoClientsGetClientByKeySuccess,
    wovoClientsGetClientKeysByUserError,
    wovoClientsGetClientKeysByUserSuccess,
    wovoClientsGetPaginatedListError,
    wovoClientsGetPaginatedListSuccess,
    wovoClientsSaveClientError,
    wovoClientsSaveClientSuccess
} from "../../actions";
import {
    COUNTRY_GET_ALL_COUNTRIES,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER,
    WOVO_CLIENTS_GET_PAGINATED_LIST,
    WOVO_CLIENTS_SAVE_CLIENT
} from "../../constants/actions/clients/actionTypesWoVoClients";


/** http : wovo clients : to get paginated list **/
const httpWoVoClientsGetPaginatedList = async (data) =>
    await axios.post(URL_WOVO_CLIENTS_GET_PAGINATED_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : wovo clients : to save clients data  **/
const httpWoVoClientsSaveClients = async (data) =>
    await axios.post(URL_WOVO_CLIENTS_SAVE_CLIENT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : wovo clients : to get client details by key  **/
const httpWoVoClientsGetClientByKey = async (data) =>
    await axios.post(URL_WOVO_CLIENTS_GET_CLIENT_BY_KEY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : countries : to get all countries  **/
const httpGetAllCountriesList = async (data) =>
    await axios.post(URL_COUNTRY_GET_ALL_RECORDS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : wovo clients : to get client keys by user  **/
const httpWoVoClientsGetClientKeysByUser = async (data) =>
    await axios.post(URL_WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** worker contacts : download worker contacts template : http response handler **/
function* handleHttpWoVoClientsGetPaginatedList({payload}) {
    try {
        
        let result = yield call(httpWoVoClientsGetPaginatedList, payload);
        
        if (result.message) {
            if (result.response.data) {
                yield put(wovoClientsGetPaginatedListError(result.response.data));
            } else {
                yield put(wovoClientsGetPaginatedListError(result.response));
            }
            
        } else {
            if (result.data) {
                yield put(wovoClientsGetPaginatedListSuccess(result.data));
            } else {
                yield put(wovoClientsGetPaginatedListSuccess(result));
            }
        }
        
    } catch (error) {
        yield put(wovoClientsGetPaginatedListError(error));
    }
}

/** wovo clients : to save client data : http response handler **/
function* handleHttpWoVoClientsSaveClient({payload}) {
    try {
        
        let result = yield call(httpWoVoClientsSaveClients, payload);
        
        if (result.message) {
            yield put(wovoClientsSaveClientError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(wovoClientsSaveClientSuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(wovoClientsSaveClientError(error));
    }
}

/** wovo clients : to save client data : http response handler **/
function* handleHttpWoVoClientsGetClientByKey({payload}) {
    try {
        
        let result = yield call(httpWoVoClientsGetClientByKey, payload);
        
        if (result.message) {
            yield put(wovoClientsGetClientByKeyError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(wovoClientsGetClientByKeySuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(wovoClientsGetClientByKeyError(error));
    }
}

/** countries : to get all countries list : http response handler **/
function* handleHttpGetAllCountriesList({payload}) {
    try {
        
        let result = yield call(httpGetAllCountriesList, payload);
        
        if (result.message) {
            yield put(getAllCountriesListError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(getAllCountriesListSuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(getAllCountriesListError(error));
    }
}

/** wovo clients : to get client keys by user : http response handler **/
function* handleHttpWoVoClientsGetClientKeysByUser({payload}) {
    try {
        
        let result = yield call(httpWoVoClientsGetClientKeysByUser, payload);
        
        if (result.message) {
            yield put(wovoClientsGetClientKeysByUserError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(wovoClientsGetClientKeysByUserSuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(wovoClientsGetClientKeysByUserError(error));
    }
}

/** wovo clients : to get paginated list **/
export function* woVoClientsGetPaginatedList() {
    yield takeEvery(WOVO_CLIENTS_GET_PAGINATED_LIST, handleHttpWoVoClientsGetPaginatedList);
}

/** wovo clients : to save client data **/
export function* woVoClientsSaveClient() {
    yield takeEvery(WOVO_CLIENTS_SAVE_CLIENT, handleHttpWoVoClientsSaveClient);
}

/** wovo clients : to client details by key **/
export function* woVoClientsGetClientByKey() {
    yield takeEvery(WOVO_CLIENTS_GET_CLIENT_BY_KEY, handleHttpWoVoClientsGetClientByKey);
}

/** countries : to get all countries list **/
export function* getAllCountriesList() {
    yield takeEvery(COUNTRY_GET_ALL_COUNTRIES, handleHttpGetAllCountriesList);
}

/** wovo clients : to client keys by user **/
export function* woVoClientsGetClientKeysByUser() {
    yield takeEvery(WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER, handleHttpWoVoClientsGetClientKeysByUser);
}


export default function* rootSaga() {
    yield all([
        fork(woVoClientsGetPaginatedList),
        fork(woVoClientsSaveClient),
        fork(woVoClientsGetClientByKey),
        fork(getAllCountriesList),
        fork(woVoClientsGetClientKeysByUser)
    ])
}
