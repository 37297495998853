import {HTTP_REQUEST_STATUS} from "../../constants/constants";
import {
    CLIENT_MODULE_ADMIN_LIST,
    CLIENT_MODULE_ADMIN_LIST_ERROR,
    CLIENT_MODULE_ADMIN_LIST_NONE,
    CLIENT_MODULE_ADMIN_LIST_SUCCESS,
    CLIENT_MODULE_CONFIGURATION_SET_MODULE,
    CLIENT_MODULE_GET_CONFIGURATION,
    CLIENT_MODULE_GET_CONFIGURATION_ERROR,
    CLIENT_MODULE_GET_CONFIGURATION_NONE,
    CLIENT_MODULE_GET_CONFIGURATION_SUCCESS,
    CLIENT_MODULE_NEW_MODULE_SAVE,
    CLIENT_MODULE_NEW_MODULE_SAVE_ERROR,
    CLIENT_MODULE_NEW_MODULE_SAVE_NONE,
    CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS,
    CLIENT_MODULE_RESET_CURRENT_MODULE,
    CLIENT_MODULE_SET_CONFIGURATION,
    CLIENT_MODULE_SET_CONFIGURATION_ERROR,
    CLIENT_MODULE_SET_CONFIGURATION_NONE,
    CLIENT_MODULE_SET_CONFIGURATION_SUCCESS,
    CLIENT_MODULE_SET_CURRENT_MODULE
} from "../../constants/actions/clientModules/actionConfigClientModules";
import IntlMessages from "../../util/IntlMessages";
import React from "react";
import {SIGNIN_USER_SUCCESS} from "../../constants/ActionTypes";


let newClientModuleConfigFields = [
    {
        'key': 'module_title',
        'type': 'textfield',
        'style': {},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_TITLE",
        'isMandatory': true,
        'containerClass': 'col-md-8 col-sm-12 col-12'
    },
    {
        'key': 'module_key',
        'type': 'textfield',
        'style': {},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_MODULE_KEY",
        'isMandatory': true,
        'containerClass': 'col-md-8 col-sm-12 col-12'
    },
    {
        'key': 'module_parent',
        'type': 'dropdown',
        'style': {},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_PARENT",
        'isMandatory': false,
        'containerClass': 'col-md-8 col-sm-12 col-12'
    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'style': {marginTop: "-2rem"},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_IS_ACTIVE"
    },
    {
        'key': 'is_enabled_by_default',
        'type': 'checkbox',
        'style': {},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_IS_BY_DEFAULT_ENABLED"
    },
    {
        'key': 'is_enabled_for_brand',
        'type': 'checkbox',
        'style': {},
        'localisation': "DIC_KEY_ADMIN_CLIENT_MODULES_FILED_IS_ENABLED_FOR_BRAND"
    }
];

const clientModulesListHeaders = [
    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_KEY_ADMIN_CLIENT_MODULES_TBL_HEADER_S_NO"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_KEY_ADMIN_CLIENT_MODULES_TBL_HEADER_NAME"/>
    },
    {
        id: 'module_key',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_KEY_ADMIN_CLIENT_MODULES_TBL_HEADER_KEY"/>
    },
    {
        id: 'parent',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_KEY_ADMIN_CLIENT_MODULES_TBL_HEADER_PARENT"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_KEY_ADMIN_CLIENT_MODULES_TBL_HEADER_ACTIONS"/>
    }
];

const initialData = {
    newClientModuleConfigFields: newClientModuleConfigFields,
    clientModulesListHeaders: clientModulesListHeaders,

    listLoadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    saveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    configLoaderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    configSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessage: "",
    currentClientModule: {},
    adminClientModulesList: [],
    clientModulesDropdown: [],
    moduleTreeData: [],
    moduleParentMap: {},
    selectedClientConfiguration: {},
    moduleConfig: {},
    userRole: ""
};


const ReducerClientModules = (state = initialData, action) => {


    switch (action.type) {

        case SIGNIN_USER_SUCCESS: {
            let user = action.payload.user;
            let moduleConfig = user["module_config"];
            let userRole = user["role"];

            return {
                ...state,
                moduleConfig: moduleConfig,
                userRole: userRole
            }
        }

        case CLIENT_MODULE_SET_CURRENT_MODULE : {
            return {
                ...state,
                currentClientModule: action.payload
            }
        }

        case CLIENT_MODULE_RESET_CURRENT_MODULE: {
            return {
                ...state,
                currentClientModule: {}
            }
        }

        case CLIENT_MODULE_NEW_MODULE_SAVE: {

            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS: {

            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }

        case CLIENT_MODULE_NEW_MODULE_SAVE_ERROR: {

            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error
            }
        }

        case CLIENT_MODULE_NEW_MODULE_SAVE_NONE: {

            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""

            }
        }

        case CLIENT_MODULE_ADMIN_LIST: {

            return {
                ...state,
                listLoadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case CLIENT_MODULE_ADMIN_LIST_SUCCESS: {

            let data = updateClientModules(action.payload.data.data);
            let clientModulesDropdown = parseClientModules(data);
            let moduleTreeData = createClientModuleTreeData(data);

            return {
                ...state,
                listLoadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                adminClientModulesList: data,
                clientModulesDropdown: clientModulesDropdown,
                moduleTreeData: moduleTreeData['treeData'],
                moduleParentMap : moduleTreeData['parentMap']
            }
        }

        case CLIENT_MODULE_ADMIN_LIST_ERROR: {

            return {
                ...state,
                listLoadStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case CLIENT_MODULE_ADMIN_LIST_NONE: {

            return {
                ...state,
                listLoadStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case CLIENT_MODULE_GET_CONFIGURATION: {

            return {
                ...state,
                configLoaderStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                selectedClientConfiguration: {}
            }
        }
        case CLIENT_MODULE_GET_CONFIGURATION_SUCCESS: {

            return {
                ...state,
                configLoaderStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
                selectedClientConfiguration: parseClientModuleConfiguration(action.payload.data.data)
            }
        }
        case CLIENT_MODULE_GET_CONFIGURATION_ERROR: {

            return {
                ...state,
                configLoaderStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error
            }
        }
        case CLIENT_MODULE_GET_CONFIGURATION_NONE: {

            return {
                ...state,
                configLoaderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case CLIENT_MODULE_SET_CONFIGURATION: {

            return {
                ...state,
                configSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        case CLIENT_MODULE_SET_CONFIGURATION_SUCCESS: {
            let selectedClientConfiguration = parseClientModuleConfiguration(action.payload.data.data)

            return {
                ...state,
                configSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
                selectedClientConfiguration: selectedClientConfiguration
            }
        }
        case CLIENT_MODULE_SET_CONFIGURATION_ERROR: {

            return {
                ...state,
                configSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error
            }
        }
        case CLIENT_MODULE_SET_CONFIGURATION_NONE: {

            return {
                ...state,
                configSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case CLIENT_MODULE_CONFIGURATION_SET_MODULE: {
            let selectedClientConfiguration = state.selectedClientConfiguration;
            let moduleParentMap = state.moduleParentMap;

            let currentId = action.payload;
            let currentStatus = selectedClientConfiguration[currentId]["is_active"];
            let requiredIds = [currentId];
            
            if ( typeof moduleParentMap[currentId] != "undefined" ) {
                let items =  currentStatus ? moduleParentMap[currentId]['childs'] : moduleParentMap[currentId]['parents']
                requiredIds = requiredIds.concat(items);
            }

            for (let i = 0 ; i < requiredIds.length; i++ ) {
                selectedClientConfiguration[requiredIds[i]]["is_active"] = !currentStatus;
            }

            return {
                ...state,
                selectedClientConfiguration
            }
        }


        default:
            return state;
    }

};

function parseClientModules(data) {
    let result = [];

    try {

        for (let i = 0; i < data.length; i++) {
            result.push({
                'id': data[i]['id'],
                'key': data[i]['id'],
                'value': data[i]['id'],
                'label': data[i]['title'] + " ( " + data[i]['module_key'] + " )",
            });
        }
    } catch (ex) {
        console.error("parseClientModules : error : ", ex);
    }

    return result;
}

function updateClientModules(data) {
    try {

        let parentId;
        let parentName;


        for (let i = 0; i < data.length; i++) {
            parentId = "";
            parentName = "";


            if (data[i]["parent"] != null && typeof data[i]["parent"] != "undefined") {
                parentId = data[i]["parent"]["id"];
                parentName = data[i]["parent"]["title"];

            }

            data[i]["parentId"] = parentId;
            data[i]["parentName"] = parentName;
        }
    } catch (ex) {
        console.error("updateClientModules : error : ", ex);
    }

    return data;
}


function createClientModuleTreeData(data) {

    let result = {
        'treeData' : [],
        'parentMap' : {}
    };

    try {
        let resultJSON = {};
        let configs = {};

        for (let i = 0; i < data.length; i++) {

            if (data[i]["parentId"] === "") {
                resultJSON[data[i]["id"]] = {
                    id: data[i]["id"],
                    key: data[i]["id"],
                    value: data[i]["id"],
                    title: data[i]["title"],
                    module_key: data[i]["module_key"],
                    childs: []
                }
            } else {
                configs[data[i]["id"]] = {
                    id: data[i]["id"],
                    key: data[i]["id"],
                    value: data[i]["id"],
                    title: data[i]["title"],
                    module_key: data[i]["module_key"],
                    parentId: data[i]["parentId"],
                    childs: []
                }
            }
        }

        let processChildItems = function (parent, item) {
            let isProcessed = false;

            try {

                if (item["parentId"] === parent["id"]) {
                    parent["childs"].push(item);
                    isProcessed = true;
                } else {
                    for (let i = 0; i < parent.childs.length; i++) {
                        isProcessed = processChildItems(parent.childs[i], item);
                        if (isProcessed) {
                            break;
                        }
                    }
                }

            } catch (ex) {
                console.error("createClientModuleTreeData : processChildItems : error : ", ex);
            }

            return isProcessed;

        }

        let counter = 0;

        while (Object.keys(configs).length > 0) {

            if (counter > 1000) {
                break
            }

            for (let item in configs) {
                let isProcessed = false;

                for (let resultItem in resultJSON) {
                    isProcessed = processChildItems(resultJSON[resultItem], configs[item])

                    if (isProcessed) {
                        break;
                    }
                }

                if (isProcessed) {
                    delete configs[item];
                    break;
                }
            }
        }

        for (let item in resultJSON) {
            result['treeData'].push(resultJSON[item]);
        }

        let parentMap = {};

        let processParentMap = function (parentIds, childs, level){
            let childIds = [];
            try{

                for(let i = 0 ; i < childs.length; i++ ) {
                    let currentId = childs[i].id;

                    let currentParents = [];

                    for (let j = 0 ; j < parentIds.length; j++ ){
                        currentParents.push(parentIds[j]);
                    }

                    currentParents.push(currentId);

                    parentMap[currentId] = {
                        'item' : childs[i]["module_key"],
                        'parents' : parentIds,
                        'childs' : processParentMap(currentParents, childs[i].childs, level +1 )
                    };

                    childIds.push(currentId);
                    childIds = childIds.concat(parentMap[currentId]['childs']);
                }

            } catch ( ex ) {
                console.error("processParentMap : error : ", ex );
            }

            return childIds;

        };

        let treeData = result["treeData"];

        for (let i = 0 ; i < treeData.length; i++ ) {

            parentMap[treeData[i].id] = {
                'item' : treeData[i]["module_key"],
                'parents' : [],
                'childs' : processParentMap([treeData[i].id], treeData[i].childs, 0),
            }

        }

        result['parentMap'] = parentMap;

    } catch (ex) {
        console.error("createClientModuleTreeData : error : ", ex);
    }

    return result;
}

function parseClientModuleConfiguration(data) {
    let result = {};
    try {

        for (let i = 0; i < data.length; i++) {
            result[data[i]["client_module"]] = data[i];
        }

    } catch (ex) {
        console.error("parseClientModuleConfiguration : error : ", ex);
    }

    return result;
}


export default ReducerClientModules;
