import { 
    SURVEY_INVITE_OUTREACH,
    SURVEY_INVITE_OUTREACH_NONE,
    SURVEY_INVITE_OUTREACH_INPROGRESS,
    SURVEY_INVITE_OUTREACH_SUCCESS,
    SURVEY_INVITE_OUTREACH_FAIL,
    SURVEY_INVITE_OUTREACH_STATUS_RESET,

    SURVEY_INVITE_CREATE,
    SURVEY_INVITE_CREATE_NONE,
    SURVEY_INVITE_CREATE_INPROGRESS,
    SURVEY_INVITE_CREATE_SUCCESS,
    SURVEY_INVITE_CREATE_FAIL,
    SURVEY_INVITE_CREATE_STATUS_RESET,

    SURVEY_INVITE_GET_LIST,
    SURVEY_INVITE_GET_LIST_NONE,
    SURVEY_INVITE_GET_LIST_INPROGRESS,
    SURVEY_INVITE_GET_LIST_SUCCESS,
    SURVEY_INVITE_GET_LIST_FAIL,

    SURVEY_INVITE_GET_SPECIFIC_INVITE,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL,

    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL,
    SURVEY_INVITE_LIST_RELOAD,

    SURVEY_INVITE_SET_SELECTED_INPUTS,
    SURVEY_INVITE_RESET_SELECTED_INPUTS,
    
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL,

    SURVEY_INVITE_SEND_REMINDER,
    SURVEY_INVITE_SEND_REMINDER_NONE,
    SURVEY_INVITE_SEND_REMINDER_INPROGRESS,
    SURVEY_INVITE_SEND_REMINDER_SUCCESS,
    SURVEY_INVITE_SEND_REMINDER_FAIL,

    SURVEY_INVITE_INVITED_MEDIA_DETAILS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE

} from "constants/actions/survey/survey/actionTypesSurveyInvite";

/****
 *Survey Invite: For outreach
 ****/
 export function surveyInviteOutreach(params) {
    return {
        type: SURVEY_INVITE_OUTREACH,
        payload: params
    };
}

/****
 *Survey Invite: For outreach
 ****/
 export function surveyInviteOutreachProgress() {
    return {
        type: SURVEY_INVITE_OUTREACH_INPROGRESS
    };
}

/****
 *Survey Invite: For outreach success
 ****/
export function surveyInviteOutreachSuccess(params) {
    return {
        type: SURVEY_INVITE_OUTREACH_SUCCESS,
        payload: params
    };
}

/****
 *Survey Invite: For outreach error
 ****/
export function surveyInviteOutreachError(params) {
    return {
        type: SURVEY_INVITE_OUTREACH_FAIL,
        payload: params
    };
}

/****
 *Survey Invite: For outreach none
 ****/
export function surveyInviteOutreachNone() {
    return {
        type: SURVEY_INVITE_OUTREACH_NONE
    };
}

export function surveyInviteOutreachStatusReset() {
    return {
        type : SURVEY_INVITE_OUTREACH_STATUS_RESET
    };
}

// =============================================================================

/****
 *Survey Invite: For create
 ****/
 export function surveyInviteCreate(params) {
    return {
        type: SURVEY_INVITE_CREATE,
        payload: params
    };
}

/****
 *Survey Invite: For create progress
 ****/
 export function surveyInviteCreateProgress() {
    return {
        type: SURVEY_INVITE_CREATE_INPROGRESS
    };
}

/****
 *Survey Invite: For create success
 ****/
export function surveyInviteCreateSuccess(params) {
    return {
        type: SURVEY_INVITE_CREATE_SUCCESS,
        payload: params
    };
}

/****
 *Survey Invite: For create error
 ****/
export function surveyInviteCreateError(params) {
    return {
        type: SURVEY_INVITE_CREATE_FAIL,
        payload: params
    };
}

/****
 *Survey Invite: For create none
 ****/
export function surveyInviteCreateNone() {
    return {
        type: SURVEY_INVITE_CREATE_NONE
    };
}

export function surveyInviteCreateStatusReset(){
    return {
        type: SURVEY_INVITE_CREATE_STATUS_RESET
    };
}

// =============================================================================

/****
 *Survey Invite: For list
 ****/
 export function surveyInviteGetList(params) {
    return {
        type: SURVEY_INVITE_GET_LIST,
        payload: params
    };
}

/****
 *Survey Invite: List progress
 ****/
 export function surveyInviteGetListProgress() {
    return {
        type: SURVEY_INVITE_GET_LIST_INPROGRESS
    };
}

/****
 *Survey Invite: List success
 ****/
export function surveyInviteGetListSuccess(params) {
    return {
        type: SURVEY_INVITE_GET_LIST_SUCCESS,
        payload: params
    };
}

/****
 *Survey Invite: List error
 ****/
export function surveyInviteGetListError(params) {
    return {
        type: SURVEY_INVITE_GET_LIST_FAIL,
        payload: params
    };
}

/****
 *Survey Invite: List None
 ****/
export function surveyInviteGetListNone() {
    return {
        type: SURVEY_INVITE_GET_LIST_NONE
    };
}

// =============================================================================

/****
 *Survey Invite: Get Specific Survey Invite
 ****/
 export function surveyInviteGetSpecific(params) {
    return {
        type: SURVEY_INVITE_GET_SPECIFIC_INVITE,
        payload: params
    };
}

/****
 *Survey Invite: Get Specific Survey Invite progress
 ****/
 export function surveyInviteGetSpecificProgress() {
    return {
        type: SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS
    };
}

/****
 *Survey Invite: Get Specific Survey Invite success
 ****/
export function surveyInviteGetSpecificSuccess(params) {
    return {
        type: SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS,
        payload: params
    };
}

/****
 *Survey Invite: Get Specific Survey Invite error
 ****/
export function surveyInviteGetSpecificError(params) {
    return {
        type: SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL,
        payload: params
    };
}

/****
 *Survey Invite: Get Specific Survey Invite None
 ****/
export function surveyInviteGetSpecificNone() {
    return {
        type: SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE
    };
}

// =============================================================================

/****
 *Survey Invite: Update Specific Survey Invite
 ****/
 export function surveyInviteUpdateSpecific(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SPECIFIC_INVITE,
        payload: params
    };
}

/****
 *Survey Invite: Update Specific Survey Invite progress
 ****/
 export function surveyInviteUpdateSpecificProgress() {
    return {
        type: SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS
    };
}

/****
 *Survey Invite: Update Specific Survey Invite success
 ****/
export function surveyInviteUpdateSpecificSuccess(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS,
        payload: params
    };
}

/****
 *Survey Invite: Update Specific Survey Invite error
 ****/
export function surveyInviteUpdateSpecificError(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL,
        payload: params
    };
}

/****
 *Survey Invite: Update Specific Survey Invite None
 ****/
export function surveyInviteUpdateSpecificNone() {
    return {
        type: SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE
    };
}

// ============================Survey Period=================================================

/**
 * Survey period update for survey invite
 */
 export function surveyInviteUpdateSurveyPeriod(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SURVEY_PERIOD,
        payload: params
    };
}
/**
 * Survey period update for survey invite: Inprogress
 */
 export function surveyInviteUpdateSurveyPeriodInProgress() {
    return {
        type: SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS
    };
}
/**
 * Survey period update for survey invite: Success
 */
export function surveyInviteUpdateSurveyPeriodSuccess(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS,
        payload: params
    };
}
/**
 * Survey period update for survey invite: error
 */
export function surveyInviteUpdateSurveyPeriodError(params) {
    return {
        type: SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL,
        payload: params
    };
}
/**
 * Survey period update for survey invite: None
 */
export function surveyInviteUpdateSurveyPeriodNone() {
    return {
        type: SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE
    };
}

//============================================================================= 
export function surveyInviteListReload(data){
    return {
        type: SURVEY_INVITE_LIST_RELOAD,
        payload : data
    }
}

export function surveyInviteUpdateInputValues(params){
    return {
        type  : SURVEY_INVITE_SET_SELECTED_INPUTS,
        payload : params
    }
}

export function surveyInviteResetInputValues(){
    return {
        type: SURVEY_INVITE_RESET_SELECTED_INPUTS
    }
}

//=============================Send Reminder Api===================

/**
 * Survey reminder for survey invite
 */
export function surveyInviteSendReminder(params){
    return {
        type: SURVEY_INVITE_SEND_REMINDER,
        payload: params
    }
}

/**
 * Survey reminder for survey invite: inprogress
 */
export function surveyInviteSendReminderInprogress(){
    return {
        type: SURVEY_INVITE_SEND_REMINDER_INPROGRESS
    }
}

/**
 * Survey reminder for survey invite: success
 */
export function surveyInviteSendReminderSuccess(params){
    return {
        type: SURVEY_INVITE_SEND_REMINDER_SUCCESS,
        payload: params
    }
}

/**
 * Survey reminder for survey invite: error
 */
export function surveyInviteSendReminderError(params){
    return {
        type: SURVEY_INVITE_SEND_REMINDER_FAIL,
        payload: params
    }
}

/**
 * Survey reminder for survey invite: none
 */
export function surveyInviteSendReminderNone(){
    return {
        type: SURVEY_INVITE_SEND_REMINDER_NONE
    }
}

//=============================Invited Media Api===================

export function surveyInviteGetInvitedMediaData(params){
    return {
        type: SURVEY_INVITE_INVITED_MEDIA_DETAILS,
        payload: params
    }
}

export function surveyInviteGetInvitedMediaInprogress(){
    return {
        type: SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS
    }
}

export function surveyInviteGetInvitedMediaSuccess(params){
    return {
        type: SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS,
        payload: params
    }
}

export function surveyInviteGetInvitedMediaError(params){
    return {
        type: SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL,
        payload: params
    }
}

export function surveyInviteGetInvitedMediaNone(){
    return {
        type: SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE
    }
}

