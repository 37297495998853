import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";
import {
    SURVEY_QUESTION_TYPE_GET_ALL_RECORDS,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS
} from "../../../constants/ActionTypes";

import {
    getSurveyQuestionTypesSuccess,
    saveSurveyQuestionTypeError,
    saveSurveyQuestionTypeSuccess,
    surveyQuestionTypeUpdateStatusSuccess
} from "../../../actions";

import {
    URL_SURVEY_QUESTION_TYPE_LIST,
    URL_SURVEY_QUESTION_TYPE_SAVE,
    URL_SURVEY_QUESTION_TYPE_UPDATE,
    URL_SURVEY_QUESTION_TYPE_UPDATE_STATUS
} from "../../../constants/Urls";
import AppUtils from "../../../util/util";


/************ methods : http *******************/
/** http : Survey Question type : http save **/
const httpSaveSurveyQuestionTypes = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_TYPE_SAVE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question type : http update **/
const httpUpdateQuestionTypes = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_QUESTION_TYPE_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question Types : all records **/
const httpGetSurveyQuestionTypes = async () =>
    await axios.get(baseUrl + URL_SURVEY_QUESTION_TYPE_LIST, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question Types : http update status **/
const httpSurveyQuestionTypeUpdateStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_TYPE_UPDATE_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/************ methods : http response handler *******************/

/** http : Survey Question types : http response handler **/
function* handleHttpSaveSurveyQuestionType({payload}) {
    try {

        let result = yield call( payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateQuestionTypes : httpSaveSurveyQuestionTypes,payload);

        if (result.message) {
            yield put(saveSurveyQuestionTypeError(result.response));
        } else {
            yield put(saveSurveyQuestionTypeSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyQuestionTypeError(error));
    }
}

/** http : Survey Question types list : http response handler **/
function* handleHttpGetSurveyQuestionTypesListResponse() {
    try {

        let result = yield call(httpGetSurveyQuestionTypes);

        if (result.message) {
            yield put(saveSurveyQuestionTypeError(result.response));
        } else {
            yield put(getSurveyQuestionTypesSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyQuestionTypeError(error));
    }
}

/** http : Survey Question Types status update : http response handler **/
function* handleHttpUpdateSurveyQuestionTypesStatusResponse({payload}) {
    try {

        let result = yield call(httpSurveyQuestionTypeUpdateStatus, payload);

        if (result.message) {
            yield put(saveSurveyQuestionTypeError(result.response));
        } else {
            yield put(surveyQuestionTypeUpdateStatusSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyQuestionTypeError(error));
    }
}


/************ methods : export access *******************/

/** Survey Category : save **/
export function* saveSurveyQuestionType() {
    yield takeEvery(SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE, handleHttpSaveSurveyQuestionType);
}

/** Survey question types : get all records **/
export function* getSurveyQuestionTypeList() {
    yield takeEvery(SURVEY_QUESTION_TYPE_GET_ALL_RECORDS, handleHttpGetSurveyQuestionTypesListResponse);
}

/** Survey question type : update status **/
export function* updateSurveyQuestionTypeStatus() {
    yield takeEvery(SURVEY_QUESTION_TYPE_UPDATE_STATUS, handleHttpUpdateSurveyQuestionTypesStatusResponse);
}

export default function* rootSaga() {
    yield all([
        fork(saveSurveyQuestionType),
        fork(getSurveyQuestionTypeList),
        fork(updateSurveyQuestionTypeStatus),
    ]);
}


