
export const ADMIN_CONTROLS_GET_SURVEY_DETAILS = 'ADMIN_CONTROLS_GET_SURVEY_DETAILS';
export const ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS = 'ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS';
export const ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR = 'ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR';
export const ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE = 'ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE';


export const ADMIN_CONTROLS_SET_SURVEY_STATUS = 'ADMIN_CONTROLS_SET_SURVEY_STATUS';
export const ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS = 'ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS';
export const ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR = 'ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR';
export const ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE = 'ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE';

/** survey duplicate responses **/
export const ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES = 'ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES';
export const ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS = 'ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS';
export const ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR = 'ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR';
export const ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE = 'ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE';

/** all surveys raw data report **/
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE';

/** all surveys raw data report : uuid **/
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR';
export const ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE = 'ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE';

/** to update surveys demo graphic details **/
export const ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS = 'ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS';
export const ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS = 'ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS';
export const ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR = 'ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR';
export const ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE = 'ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE';

/** to increase survey response counter **/
export const ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT = 'ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT';
export const ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS = 'ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS';
export const ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR = 'ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR';
export const ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE = 'ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE';

/** to reset survey response cached data **/
export const ADMIN_CONTROLS_SURVEY_RESPONSES_RESET = 'ADMIN_CONTROLS_SURVEY_RESPONSES_RESET';
export const ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS = 'ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS';
export const ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR = 'ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR';
export const ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE = 'ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE';

/** to save survey response by request id **/
export const ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID = 'ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID';
export const ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS = 'ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS';
export const ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR = 'ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR';
export const ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE = 'ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE';

/** to get client ucms custom field status  **/
export const ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS = 'ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS';
export const ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS = 'ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS';
export const ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR = 'ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR';
export const ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE = 'ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE';

/** to sync client ucms custom fields  **/
export const ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS = 'ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS';
export const ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS = 'ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS';
export const ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR = 'ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR';
export const ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE = 'ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE';

/** to download wc diff file  **/
export const ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE = 'ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE';
export const ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS = 'ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS';
export const ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR = 'ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR';
export const ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE = 'ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE';

