import {
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE,
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT,
    SURVEY_SURVEY_QUESTION_DELETE,
    SURVEY_SURVEY_QUESTION_DELETE_ERROR,
    SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS,
    SURVEY_SURVEY_QUESTION_DELETE_NONE,
    SURVEY_SURVEY_QUESTION_DELETE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DELETE,
    SURVEY_TEMPLATE_QUESTION_DELETE_ERROR,
    SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS,
    SURVEY_TEMPLATE_QUESTION_DELETE_NONE,
    SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
    SURVEY_SURVEY_QUESTION_DUPLICATE,
    SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR,
    SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS,
    SURVEY_SURVEY_QUESTION_DUPLICATE_NONE,
    SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,    
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS,
    SURVEY_QUESTION_RANDOMIZE,
    SURVEY_QUESTION_RANDOMIZE_ERROR,
    SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS,
    SURVEY_QUESTION_RANDOMIZE_NONE,
    SURVEY_QUESTION_RANDOMIZE_SUCCESS,
    SURVEY_UPDATE_RANDOMIZE_OPTION
} from "../../../constants/actions/survey/questions/actionTypesSurveyQuestions";
import {SURVEY_QUESTIONS_SET_CURRENT_ITEM} from "../../../constants/actions/survey/survey/actionTypesSurvey";


/****
  To set current template or current survey for questions layout
 ****/
export function setQuestionsCurrentItem(params) {
    return {
        type: SURVEY_QUESTIONS_SET_CURRENT_ITEM,
        payload : params
    };
}

/****
  To enable new question layout
 ****/
export function enableQuestionLayout() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT
    };
}


/****
  To disable new question layout
 ****/
export function disableQuestionLayout() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT
    };
}

/****
  Question action : cancel Survey question : enable
 ****/
export function cancelQuestionLayout() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE
    };
}

/****
  Question action : cancel Survey question : disable
 ****/
export function disableCancelQuestion() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE
    };
}

/****
  Question action : delete Survey question : enable
 ****/
export function enableDeleteQuestion() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE
    };
}

/****
  Question action : delete Survey question : disable
 ****/
export function disableDeleteQuestion() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE
    };
}

/****
  Question action : Add Survey question : enable
 ****/
export function enableAddQuestion() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE
    };
}

/****
  Question action : Add Survey question : disable
 ****/
export function disableAddQuestion() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE
    };
}

/****
  New Survey question : save
 ****/
export function saveNewSurveyQuestion(params) {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
        payload : params
    };
}

/****
  New Survey question : none
 ****/
export function saveNewSurveyQuestionNone() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE
    };
}

/****
  New Survey question : save success
 ****/
export function saveNewSurveyQuestionSuccess(params) {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
        payload : params
    };
}

/****
  New Survey question : save error
 ****/
export function saveNewSurveyQuestionError(params) {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
        payload : params
    };
}


/****
  New Survey question : save in progress
 ****/
export function saveNewSurveyQuestionInProgress() {
    return {
        type: SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS
    };
}


/****
  Survey template question : save
 ****/
export function saveSurveyTemplateQuestion(params) {
    return {
        type: SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
        payload : params
    };
}

/****
  Survey question : save : success
 ****/
export function saveSurveyTemplateQuestionSuccess(params) {
    return {
        type: SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
        payload : params
    };
}

/****
  Survey question : save : error
 ****/
export function saveSurveyTemplateQuestionError(params) {
    return {
        type: SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
        payload : params
    };
}

/****
  Survey question : save : in progress
 ****/
export function saveSurveyTemplateQuestionInProgress() {
    return {
        type: SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS
    };
}


/****
  Survey question : save status : none
 ****/
export function saveSurveyTemplateQuestionNone() {
    return {
        type: SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE
    };
}


/****
  Survey template question : delete
 ****/
export function deleteSurveyTemplateQuestion(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DELETE,
        payload : params
    };
}

/****
  Survey template  question : delete : success
 ****/
export function deleteSurveyTemplateQuestionSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
        payload : params
    };
}

/****
  Survey template  question : delete : error
 ****/
export function deleteSurveyTemplateQuestionError(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DELETE_ERROR,
        payload : params
    };
}

/****
  Survey template  question : save : in progress
 ****/
export function deleteSurveyTemplateQuestionInProgress() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS
    };
}


/****
  Survey template question : delete status : none
 ****/
export function deleteSurveyTemplateQuestionNone() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DELETE_NONE
    };
}


/****
  Survey question : delete
 ****/
export function deleteSurveyQuestion(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DELETE,
        payload : params
    };
}

/****
  Survey question : delete : success
 ****/
export function deleteSurveyQuestionSuccess(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DELETE_SUCCESS,
        payload : params
    };
}

/****
  Survey question : delete : error
 ****/
export function deleteSurveyQuestionError(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DELETE_ERROR,
        payload : params
    };
}

/****
  Survey  question : save : in progress
 ****/
export function deleteSurveyQuestionInProgress() {
    return {
        type: SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS
    };
}


/****
  Survey question : delete status : none
 ****/
export function deleteSurveyQuestionNone() {
    return {
        type: SURVEY_SURVEY_QUESTION_DELETE_NONE
    };
}

/****
  Survey question : duplicate
 ****/
export function duplicateSurveyQuestion(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DUPLICATE,
        payload : params
    };
}

/****
  Survey question : duplicate : success
 ****/
export function duplicateSurveyQuestionSuccess(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS,
        payload : params
    };
}

/****
  Survey question : duplicate : error
 ****/
export function duplicateSurveyQuestionError(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR,
        payload : params
    };
}

/****
  Survey  question : duplicate : in progress
 ****/
export function duplicateSurveyQuestionInProgress() {
    return {
        type: SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS
    };
}


/****
  Survey question : duplicate status : none
 ****/
export function duplicateSurveyQuestionNone() {
    return {
        type: SURVEY_SURVEY_QUESTION_DUPLICATE_NONE
    };
}

/****
  Survey template question : duplicate
 ****/
export function duplicateSurveyTemplateQuestion(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DUPLICATE,
        payload : params
    };
}

/****
  Survey template  question : duplicate : success
 ****/
export function duplicateSurveyTemplateQuestionSuccess(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,
        payload : params
    };
}

/****
  Survey template  question : duplicate : error
 ****/
export function duplicateSurveyTemplateQuestionError(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR,
        payload : params
    };
}

/****
  Survey template  question : duplicate : in progress
 ****/
export function duplicateSurveyTemplateQuestionInProgress() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS
    };
}


/****
  Survey template question : duplicate status : none
 ****/
export function duplicateSurveyTemplateQuestionNone() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE
    };
}


/****
 Survey : to change the survey question order
 ****/
export function updateSurveyQuestionOrder(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_ORDER_CHANGE,
        payload : params
    };
}

/****
 Survey : to change the survey question order sucess
 ****/
export function updateSurveyQuestionOrderSucess(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS,
        payload : params
    };
}

/****
 Survey : to change the survey question order error
 ****/
export function updateSurveyQuestionOrderError(params) {
    return {
        type: SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR,
        payload : params
    };
}

/****
  Survey question : reorder status : none
 ****/
export function updateSurveyQuestionOrderNone() {
    return {
        type: SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE
    };
}

/****
  Survey   question : re order : in progress
 ****/
export function updateSurveyQuestionOrderInprogress() {
    return {
        type: SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS
    };
}

/****
 Survey template : to change the survey question order
 ****/
export function updateSurveyTemplateQuestionOrder(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE,
        payload : params
    };
}

/****
 Survey template: to change the survey question order sucess
 ****/
export function updateSurveyTemplateQuestionOrderSucess(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS,
        payload : params
    };
}

/****
 Survey template : to change the survey question order error
 ****/
export function updateSurveyTemplateQuestionOrderError(params) {
    return {
        type: SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR,
        payload : params
    };
}

/****
  Survey template : reorder status : none
 ****/
export function updateSurveyTemplateQuestionOrderNone() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE
    };
}

/****
  Survey template  question : re order : in progress
 ****/
export function updateSurveyTemplateQuestionOrderInprogress() {
    return {
        type: SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS
    };
}

/****
  Survey questions randomize order
 ****/
  export function surveyQuestionRandomize(params) {
    return {
        type: SURVEY_QUESTION_RANDOMIZE,
        payload: params
    };
}

/****
  Survey questions randomize in progress
 ****/
  export function surveyQuestionRandomizeInProgress() {
    return {
        type: SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS
    };
}

/****
  Survey questions randomize success
 ****/
  export function surveyQuestionRandomizeSuccess(params) {
    return {
        type: SURVEY_QUESTION_RANDOMIZE_SUCCESS,
        payload: params
    };
}

/****
  Survey questions randomize error
 ****/
  export function surveyQuestionRandomizeError(params) {
    return {
        type: SURVEY_QUESTION_RANDOMIZE_ERROR,
        payload: params
    };
}

/****
  Survey questions randomize none
 ****/
  export function surveyQuestionRandomizeNone() {
    return {
        type: SURVEY_QUESTION_RANDOMIZE_NONE
    };
}

/****
  Survey questions randomize option update
 ****/
  export function surveyRandomizeOptionUpdate(params) {
    return {
        type: SURVEY_UPDATE_RANDOMIZE_OPTION,
        payload: params
    };
};