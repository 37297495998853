import { HTTP_REQUEST_STATUS } from '../../../constants/constants';
import { SURVEY_QUESTION_TYPES_VALUES } from '../../../constants/config';
import {
	SURVEY_QUESTION_BANK_GET_ALL_RECORDS,
	SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS,
	SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS,
	SURVEY_QUESTION_BANK_NEW_EDIT_SAVE,
	SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS,
	SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE,
	SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR,
	SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE,
	SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS,
	SURVEY_QUESTION_BANK_UPDATE_STATUS_ERROR,
	SURVEY_QUESTION_BANK_SET_STEP,
	SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED,
	SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED,
	SURVEY_QUESTION_BANK_UPLOAD,
	SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS,
	SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR,
	SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE,
} from '../../../constants/ActionTypes';

export const SURVEY_QUESTION_BANK_STEPS = {
	NONE: 'NONE',
	VIEW: 'VIEW',
	EDIT: 'EDIT',
	NEW: 'NEW',
};

export const SURVEY_QUESTION_BANK_ACTIONS = {
	VIEW: 'VIEW',
	EDIT: 'EDIT',
	DEACTIVATE: 'DEACTIVATE',
	ACTIVATE: 'ACTIVATE',
};

export const SURVEY_QUESTION_BANK_OPTIONS_MENU = {
	DEACTIVATE: [
		{
			label: 'DIC_SURVEY_QUESTION_BANK_OPTIONS_MENU_EDIT',
			value: SURVEY_QUESTION_BANK_ACTIONS.EDIT,
		},
		{
			label: 'DIC_SURVEY_QUESTION_BANK_OPTIONS_MENU_DEACTIVATE',
			value: SURVEY_QUESTION_BANK_ACTIONS.DEACTIVATE,
		},
	],
	ACTIVATE: [
		{
			label: 'DIC_SURVEY_QUESTION_BANK_OPTIONS_MENU_EDIT',
			value: SURVEY_QUESTION_BANK_ACTIONS.EDIT,
		},
		{
			label: 'DIC_SURVEY_QUESTION_BANK_OPTIONS_MENU_ACTIVE',
			value: SURVEY_QUESTION_BANK_ACTIONS.ACTIVATE,
		},
	],
};

const initialData = {
	currentQuestionBankStep: SURVEY_QUESTION_BANK_STEPS.EDIT,
	currentQuestionBankStepTitle: '',
	currentQuestionBank: null,
	optionsContent: SURVEY_QUESTION_BANK_OPTIONS_MENU,
	surveyQuestionBank: [],
	filterQuestionBank: {},
	loader: false,
	uploadLoader: false,
	alertMessage: '',
	isSuccessMessage: false,
	isErrorMessage: false,
	uploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionBankSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionBankFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionBankUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
};

const ReducerSurveyQuestionBank = (state = initialData, action) => {
	switch (action.type) {
		/** to set current question bank step **/
		case SURVEY_QUESTION_BANK_SET_STEP: {
			let title = '';
			switch (action.payload) {
				case SURVEY_QUESTION_BANK_STEPS.EDIT: {
					title = 'DIC_SURVEY_QUESTION_BANK_EDIT_QUESTION_BANK';
					break;
				}

				case SURVEY_QUESTION_BANK_STEPS.NONE: {
					title = 'DIC_SURVEY_QUESTION_BANK_VIEW_QUESTION_BANK';
					break;
				}

				case SURVEY_QUESTION_BANK_STEPS.NEW: {
					title = 'DIC_SURVEY_QUESTION_BANK_NEW_QUESTION_BANK';
					break;
				}

				default:
					break;
			}

			return {
				...state,
				currentQuestionBankStep: action.payload,
				currentQuestionBankStepTitle: title,
			};
		}

		case SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED: {
			return {
				...state,
				currentQuestionBank: action.payload,
			};
		}

		case SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED: {
			return {
				...state,
				currentQuestionBank: null,
			};
		}

		case SURVEY_QUESTION_BANK_GET_ALL_RECORDS:
			return {
				...state,
				questionBankFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				loader: true,
			};

		case SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS:
			return {
				...state,
				loader: false,
				questionBankFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				surveyQuestionBank: action.payload.data.data,
				filterQuestionBank: {},
				optionsContent: state.optionsContent,
			};

		case SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS:
			let filterQuestionBank = generateFilteredQuestionBank(
				action.payload.data.data
			);

			return {
				...state,
				loader: false,
				questionBankFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				filterQuestionBank: filterQuestionBank,
				optionsContent: state.optionsContent,
				surveyQuestionBank: action.payload.data.data,
			};

		/**** question bank http status to in progress ****/
		case SURVEY_QUESTION_BANK_NEW_EDIT_SAVE:
			return {
				...state,
				questionBankSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				loader: true,
			};

		/**** question bank save success ****/
		case SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS:
			let currentQuestionBank = state.currentQuestionBank;

			return {
				...state,
				alertMessage: action.payload.data.message,
				isErrorMessage: false,
				isSuccessMessage: true,
				questionBankSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
				loader: false,
				currentQuestionBank: currentQuestionBank,
				currentQuestionBankStep: SURVEY_QUESTION_BANK_STEPS.NONE,
			};

		/**** question bank http status to not started ****/
		case SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE:
			return {
				...state,
				questionBankSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
			};

		/**** question bank save error ****/
		case SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR:
			return {
				...state,
				alertMessage: action.payload.data.error,
				isErrorMessage: true,
				isSuccessMessage: false,
				questionBankSaveStatus: HTTP_REQUEST_STATUS.FAIL,
				loader: false,
			};

		case SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS:
			return {
				...state,
				questionBankUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
				alertMessage: action.payload.data.message,
			};

		case SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE:
			return {
				...state,
				questionBankUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				alertMessage: '',
			};

		case SURVEY_QUESTION_BANK_UPDATE_STATUS_ERROR:
			return {
				...state,
				questionBankUpdateStatusStatus: HTTP_REQUEST_STATUS.FAIL,
				alertMessage: action.payload.data.message,
				loader: false,
			};
		/** Question bankfile upload save **/
		case SURVEY_QUESTION_BANK_UPLOAD: {
			return {
				...state,
				uploadLoader: true,
				uploadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
			};
		}

		/** Question bank file upload save success **/
		case SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS: {
			return {
				...state,
				uploadLoader: false,
				uploadStatus: HTTP_REQUEST_STATUS.SUCCESS,
				alertMessage: action.payload.data.message,
			};
		}

		/** Question bank file upload save error **/
		case SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR: {
			return {
				...state,
				uploadStatus: HTTP_REQUEST_STATUS.FAIL,
				uploadLoader: false,
				alertMessage: action.payload.data.error,
			};
		}

		/** Question bank file upload save in progress **/
		case SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE: {
			return {
				...state,
				uploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				uploadLoader: false,
				alertMessage: '',
			};
		}

		default:
			return { ...state };
	}
};

function generateFilteredQuestionBank(surveyQuestionBank) {
	try {
		if (surveyQuestionBank) {
			let surveyQuestionBankList = {};
			let multiSelect = {};
			let singleSelect = {};
			let rating = {};

			for (let i = 0; i < surveyQuestionBank.length; i++) {
				if (
					surveyQuestionBank[i]['question_type'] ===
					SURVEY_QUESTION_TYPES_VALUES['CHECK_BOX']
				) {
					multiSelect = filteredLanguages(surveyQuestionBank[i], multiSelect);
				} else if (
					surveyQuestionBank[i]['question_type'] ===
					SURVEY_QUESTION_TYPES_VALUES['RADIO_BUTTON']
				) {
					singleSelect = filteredLanguages(surveyQuestionBank[i], singleSelect);
				} else if (
					surveyQuestionBank[i]['question_type'] ===
					SURVEY_QUESTION_TYPES_VALUES['RATING_SCALE']
				) {
					rating = filteredLanguages(surveyQuestionBank[i], rating);
				}
			}

			surveyQuestionBankList[
				SURVEY_QUESTION_TYPES_VALUES['CHECK_BOX']
			] = generateFilteredQuestionBankTitle(multiSelect);
			surveyQuestionBankList[
				SURVEY_QUESTION_TYPES_VALUES['RADIO_BUTTON']
			] = generateFilteredQuestionBankTitle(singleSelect);
			surveyQuestionBankList[
				SURVEY_QUESTION_TYPES_VALUES['RATING_SCALE']
			] = generateFilteredQuestionBankTitle(rating);

			return surveyQuestionBankList;
		}
	} catch (ex) {
		console.log('generateFilteredQuestionBank : error : ', ex);
	}
}

function filteredLanguages(surveyQuestionBank, prevValues) {
	let nextValues = prevValues;
	let currentQuestionBankTranslations = surveyQuestionBank.title_translations;

	try {
		for (let j = 0; j < currentQuestionBankTranslations.length; j++) {
			let currentLanguageCode =
				currentQuestionBankTranslations[j]['language']['language_code'];

			if (
				nextValues[currentLanguageCode] &&
				currentLanguageCode in nextValues
			) {
				nextValues[currentLanguageCode].push({
					...surveyQuestionBank,
					label: currentQuestionBankTranslations[j]['translation'],
				});
			} else {
				nextValues[currentLanguageCode] = [
					{
						...surveyQuestionBank,
						label: currentQuestionBankTranslations[j]['translation'],
					},
				];
			}
		}
		return nextValues;
	} catch (ex) {
		console.log('filteredLanguages : error : ', ex);
	}
}

function generateFilteredQuestionBankTitle(questionBank) {
	try {
		for (let key in questionBank) {
			if (Object.hasOwnProperty.call(questionBank, key)) {
				let language = questionBank[key];
				let optionsArr = [];

				for (let item of language) {
					let tmpObj = { label: item.label };

					if (optionsArr.length === 0) {
						tmpObj.options = [
							{ option_count: item.options_count, value: { ...item } },
						];
						optionsArr.push(tmpObj);
					} else {
						let index = optionsArr.findIndex(
							(optionObj) => optionObj.label === item.label
						);
						if (index === -1) {
							tmpObj.options = [
								{ option_count: item.options_count, value: { ...item } },
							];
							optionsArr.push(tmpObj);
						} else {
							if (optionsArr[index]) {
								optionsArr[index].options.push({
									option_count: item.options_count,
									value: { ...item },
								});
							}
						}
					}
				}
				questionBank[key] = optionsArr;
			}
		}

		return questionBank;
	} catch (ex) {
		console.log('generateFilteredQuestionBankTitle : error : ', ex);
	}
}

export default ReducerSurveyQuestionBank;
