import {call, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_GET_CASE_CONNECT_GENERAL, URL_CONNECT_GET_CASE_CONNECT_GENERAL_STATUS
} from "../../../constants/Urls";

import {
    CONNECT_API_GENERAL_REQUEST,
    CONNECT_API_GENERAL_SUCCESS,
    CONNECT_API_GENERAL_TYPE_FAILURE,
    CONNECT_GENERAL_UUID_STATUS_REQUEST,
    CONNECT_GENERAL_UUID_STATUS_REQUEST_FAILURE,
    CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS
} from '../../../constants/ActionTypes';

// function that makes the api request and returns a Promise for response
const fetchConnectGeneral = async ({payload}) =>
    await axios.post(baseUrl + URL_CONNECT_GET_CASE_CONNECT_GENERAL, payload, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

// function that makes the api request and returns a Promise for response
const fetchConnectGeneralStatus = async ({payload}) =>
    await axios.post(baseUrl + URL_CONNECT_GET_CASE_CONNECT_GENERAL_STATUS, payload, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


// worker saga: makes the api call when watcher saga sees the action
export function* connectGeneralSaga(payload) {
    try {
        const response = yield call(fetchConnectGeneral, payload);
        const {
            data,
            message
        } = response;
        if (data) {
            yield put({type: CONNECT_API_GENERAL_SUCCESS, payload: data});
        }
        if (message) {
            yield put({type: CONNECT_API_GENERAL_TYPE_FAILURE, error: message});
        }
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({type: CONNECT_API_GENERAL_TYPE_FAILURE, error});
    }
}

// worker saga: makes the api call when watcher saga sees the action
export function* connectGeneralStatusSaga(payload) {
    try {
        const response = yield call(fetchConnectGeneralStatus, payload);
        const {
            data,
            message
        } = response;
        if (data) {
            yield put({type: CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS, payload: data});
        }
        if (message) {
            yield put({type: CONNECT_GENERAL_UUID_STATUS_REQUEST_FAILURE, error: message});
        }
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({type: CONNECT_GENERAL_UUID_STATUS_REQUEST_FAILURE, error});
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getConnectGeneralSaga() {
    yield takeEvery(CONNECT_API_GENERAL_REQUEST, connectGeneralSaga);
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getConnectGeneralStatus() {
    yield takeEvery(CONNECT_GENERAL_UUID_STATUS_REQUEST, connectGeneralStatusSaga);
}


