import {
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_ERROR,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_IN_PROGRESS,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_NONE,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_SUCCESS,
    USER_SETTINGS_UPDATE_TIME_ZONE,
    USER_SETTINGS_UPDATE_TIME_ZONE_ERROR,
    USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS,
    USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_ERROR,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_IN_PROGRESS,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_NONE,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_SUCCESS
} from "../../../../constants/actions/user/actionUserSettings";


/****
 * To update user settings
 ****/
export function updateUserPreferredLanguage(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE,
        payload: params
    };
}

/****
 * To update user settings : success
 ****/
export function updateUserPreferredLanguageSuccess(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS,
        payload: params
    };
}

/****
 * To update user settings : error
 ****/
export function updateUserPreferredLanguageError(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR,
        payload: params
    };
}

/****
 * To update user settings
 ****/
export function updateUserTimeZone(params) {
    return {
        type: USER_SETTINGS_UPDATE_TIME_ZONE,
        payload: params
    };
}

/****
 * To update user settings : success
 ****/
export function updateUserTimeZoneSuccess(params) {
    return {
        type: USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS,
        payload: params
    };
}

/****
 * To update user settings : error
 ****/
export function updateUserTimeZoneError(params) {
    return {
        type: USER_SETTINGS_UPDATE_TIME_ZONE_ERROR,
        payload: params
    };
}

/****
 * To update user settings : in progress
 ****/
export function updateUserTimeZoneInProgress(params) {
    return {
        type: USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS,
        payload: params
    };
}

/****
 * To update user settings : in progress
 ****/
export function updateUserPreferredLanguageInProgress(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS,
        payload: params
    };
}

/****
 * To update user settings : none
 ****/
export function updateUserPreferredLanguageNone(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE,
        payload: params
    };
}


/****
 * To update user preferred client
 ****/
export function updateUserPreferredClient(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_CLIENT,
        payload: params
    };
}

/****
 * To update user preferred client : success
 ****/
export function updateUserPreferredClientSuccess(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS,
        payload: params
    };
}

/****
 * To update user preferred client : error
 ****/
export function updateUserPreferredClientError(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR,
        payload: params
    };
}

/****
 * To update user preferred client : in progress
 ****/
export function updateUserPreferredClientInProgress(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS,
        payload: params
    };
}

/****
 * To update user preferred client : none
 ****/
export function updateUserPreferredClientNone(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE,
        payload: params
    };
}


/****
 * To update user preferred theme colour
 ****/
export function updateUserPreferredThemeColor(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR,
        payload: params
    };
}

/****
 * To update user preferred theme colour : success
 ****/
export function updateUserPreferredThemeColorSuccess(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_SUCCESS,
        payload: params
    };
}

/****
 * To update user preferred theme colour : error
 ****/
export function updateUserPreferredThemeColorError(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_ERROR,
        payload: params
    };
}

/****
 * To update user preferred theme colour : in progress
 ****/
export function updateUserPreferredThemeColorInProgress(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_IN_PROGRESS,
        payload: params
    };
}

/****
 * To update user preferred theme colour : none
 ****/
export function updateUserPreferredThemeColorNone(params) {
    return {
        type: USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_NONE,
        payload: params
    };
}


/****
 * To update user profile image
 ****/
export function updateUserProfileImage(params) {
    return {
        type: USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE,
        payload: params
    };
}

/****
 * To update user profile image : success
 ****/
export function updateUserProfileImagerSuccess(params) {
    return {
        type: USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_SUCCESS,
        payload: params
    };
}

/****
 * To update user profile image : error
 ****/
export function updateUserProfileImageError(params) {
    return {
        type: USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_ERROR,
        payload: params
    };
}

/****
 * To update user profile image : in progress
 ****/
export function updateUserProfileImageInProgress(params) {
    return {
        type: USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_IN_PROGRESS,
        payload: params
    };
}

/****
 * To update user profile image : none
 ****/
export function updateUserProfileImageNone(params) {
    return {
        type: USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_NONE,
        payload: params
    };
}

