import AppUtils from "../util/util";
import {

    TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS,
    TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS,
    TRANSLATION_SUPPORTED_LANG_SAVE_SHOW_ERROR,
    TRANSLATION_SUPPORTED_LANG_SAVE_HIDE_ERROR,
    TRANSLATION_SUPPORTED_LANG_SAVE_NONE,
    TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS,
    TRANSLATION_SUPPORTED_SAVED_LANG_EDIT,
    TRANSLATION_SUPPORTED_SAVED_LANG_DELETE,
    TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE

}
    from "../constants/ActionTypes";

import {HTTP_REQUEST_STATUS} from "../constants/constants"
import IntlMessages from "../util/IntlMessages";
import React from "react";


const supportedLanguageTblHeader = [

    {
        id: 'serial_number',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_SERIAL_NO"/>
    },

    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_LANG_NAME"/>
    },
    {
        id: 'language_code',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_LANG_CODE"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_LANG_ACTIONS"/>
    },
];

const initialSettings = {
    loader: false,
    isSuccessMessage: false,
    isErrorMessage: false,
    alertMessage: '',
    translationSavedLang: {},
    LanguageSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    LanguageEditStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    LanguageDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    supportedLanguagesList: [],
    supportedLanguageTblHeader: supportedLanguageTblHeader,
    supportedLanguagesMap: [],
    supportedLanguagesMapDropDown: [],
    uiSupportedLanguagesMap : []

}


const Translations = (state = initialSettings, action) => {
    switch (action.type) {

        case TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS:

            const supportedLanguagesList = action.payload.map((item) => ({
                ...item,
                "defaultName": item['name'],
                "name": AppUtils.getTranslationFromKey(item['translation_key']),
            }))
            let supportedLanguagesMap = generateLanguagesMap(supportedLanguagesList, 'is_active');
            let uiSupportedLanguagesMap = generateLanguagesMap(supportedLanguagesList, 'is_ui_language');
            let dropDownValues = [];
            dropDownValues.push({

                "value": -1,
                "id": -1,
                "key": -1,
                "label": AppUtils.getTranslationFromKey('DIC_KEY_MULTISELECT_DROPDOWN_CHIP_ALL_LABEL'),
                "language_code": -1
            });

            for ( let i = 0 ; i < supportedLanguagesMap.length; i++ ) {
                dropDownValues.push(supportedLanguagesMap[i]);
            }

            return {
                ...state,
                supportedLanguagesList,
                supportedLanguagesMap: supportedLanguagesMap,
                supportedLanguagesMapDropDown : dropDownValues,
                uiSupportedLanguagesMap : uiSupportedLanguagesMap,
                loader: false,
            };

        case TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_LANG_SAVE_SUCCESS"/>,
                isSuccessMessage: true,
                LanguageSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
            };

        case TRANSLATION_SUPPORTED_LANG_SAVE_NONE:
            return {
                ...state,
                alertMessage: '',
                LanguageSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isSuccessMessage: false,
                isErrorMessage: false,
                loader: false
            };

        case TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS:
            return {
                ...state,
                LanguageSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case TRANSLATION_SUPPORTED_LANG_SAVE_SHOW_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true,
                loader: false
            };
        case TRANSLATION_SUPPORTED_LANG_SAVE_HIDE_ERROR:
            return {
                ...state,
                alertMessage: '',
                isErrorMessage: false,
                loader: false
            };
        case TRANSLATION_SUPPORTED_SAVED_LANG_EDIT:
            return {
                ...state,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANS_SUP_LANG_SAVED_EDIT_SUCCESS"/>,
                isSuccessMessage: true,
                LanguageEditStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
            };
        case TRANSLATION_SUPPORTED_SAVED_LANG_DELETE:
            return {
                ...state,
                alertMessage: <IntlMessages id={"DIC_SETTINGS_TRANS_SUP_LANG_DELETE_SUCCESS"}/>,
                isSuccessMessage: true,
                LanguageDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            }
        case TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE: {
            return {
                ...state,
                alertMessage: '',
                LanguageDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isSuccessMessage: false,
                isErrorMessage: false,
                loader: false
            }
        }

        default:
            return state;

    }
};

function generateLanguagesMap(items, flagKey) {
    let result = [];

    try {

        for (let i = 0; i < items.length; i++) {

            if (!items[i][flagKey]) {
                continue;
            }

            result.push({

                "value": items[i]['language_code'],
                "id": items[i]['id'],
                "key": items[i]['id'],
                "label": items[i]['name'],
                "translation_key": items[i]['translation_key'],
                "language_code": items[i]['language_code'],
                "is_translated": items[i]['is_translated'],
                "fonts": items[i]['fonts']
            });
        }

    } catch (e) {
        console.error("Error in generateLanguagesMap:", e);
    }

    return result;
}


export default Translations;
