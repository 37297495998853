import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../util/util";

import {
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES
} from "../../constants/actions";
import {
    contactSettingsGetClientCustomFieldsError,
    contactSettingsGetClientCustomFieldsSuccess,
    contactSettingsRemoveCustomFieldValuesError,
    contactSettingsRemoveCustomFieldValuesSuccess
} from "../../actions";
import {
    URL_CONTACT_SETTINGS_GET_CUSTOM_FIELDS,
    URL_CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES
} from "../../constants/Urls";


/************ methods : http *******************/
/** http : contact settings : to get client custom fields **/
const httpContactSettingsGetClientCustomFields = async (data) =>
    await axios.post(URL_CONTACT_SETTINGS_GET_CUSTOM_FIELDS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : contact settings : to remove custom field values **/
const httpContactSettingsRemoveCustomFieldValues = async (data) =>
    await axios.post(URL_CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/

/** handler :  contact settings : to get client custom fields  **/
function* handleHttpContactSettingsGetClientCustomFields({payload}) {
    try {
        let result = yield call(httpContactSettingsGetClientCustomFields, payload);
        
        if (result.message) {
            yield put(contactSettingsGetClientCustomFieldsError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(contactSettingsGetClientCustomFieldsSuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(contactSettingsGetClientCustomFieldsError(error));
    }
}

/** handler : contact settings : to remove custom field values **/
function* handleHttpContactSettingsRemoveCustomFieldValues({payload}) {
    try {
        let result = yield call(httpContactSettingsRemoveCustomFieldValues, payload);
        
        if (result.message) {
            yield put(contactSettingsRemoveCustomFieldValuesError(result.response.data ? result.response.data : result.response));
        } else {
            yield put(contactSettingsRemoveCustomFieldValuesSuccess(result.data ? result.data : result));
        }
    } catch (error) {
        yield put(contactSettingsRemoveCustomFieldValuesError(error));
    }
}


/************ methods : export access *******************/


/** contact settings : to get client custom fields  **/
export function* contactSettingsGetClientCustomFields() {
    yield takeEvery(CONTACT_SETTINGS_GET_CUSTOM_FIELDS, handleHttpContactSettingsGetClientCustomFields);
}

/** contact settings : to remove custom field values  **/
export function* contactSettingsRemoveCustomFieldValues() {
    yield takeEvery(CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES, handleHttpContactSettingsRemoveCustomFieldValues);
}

export default function* rootSaga() {
    yield all([
        fork(contactSettingsGetClientCustomFields),
        fork(contactSettingsRemoveCustomFieldValues)
    ]);
}


