import {

    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_NONE,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA

} from "../../../../constants/actions/survey/survey/actionTypesSurvey";



/****
 Survey reports : To Download the responses
 ****/

export function surveyRawdataDownloadResponses(params) {
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD,
        payload : params
    };
 }

 /****
 Survey reports : To Download the responses success
 ****/

 export function surveyRawdataDownloadResponsesSuccess(data){
        return {
            type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
            payload: data  
     };
 }

/****
Survey reports : To Download the responses error
 ****/

export function surveyRawdataDownloadResponsesError(params){
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
        payload: params
    }
}

/****
Survey reports : To Download the responses in progress
 ****/

export function surveyRawdataDownloadResponsesInProgress(params){
     return{
         type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
         payload: params
     };
 }

 /****
 Survey reports : To Download the responses None
 ****/

export function surveyRawdataDownloadResponsesNone(){
    return{
        type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE
    };
}

/****
 Survey reports : To Download the responses as uuid
 ****/

export function surveyRawDataDownloadResponsesUUID(params) {
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
        payload : params
    };
}

/****
 Survey reports : To Download the responses as uuid : success
 ****/

export function surveyRawDataDownloadResponsesUUIDSuccess(data){
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
        payload: data
    };
}

/****
 Survey reports : To Download the responses as uuid : error
 ****/

export function surveyRawDataDownloadResponsesUUIDError(params){
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
        payload: params
    }
}

/****
 Survey reports : To Download the responses uuid : in progress
 ****/

export function surveyRawDataDownloadResponsesUUIDInProgress(params){
    return{
        type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS,
        payload: params
    };
}

/****
 Survey reports : To Download the responses uuid : None
 ****/

export function surveyRawDataDownloadResponsesUUIDNone(){
    return{
        type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE
    };
}


/****
 Survey reports : To Download the responses as uuid
 ****/

export function surveyRawDataDownloadResponsesUUIDData(params) {
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA,
        payload : params
    };
}

/****
 Survey reports : To Download the responses as uuid data : success
 ****/

export function surveyRawDataDownloadResponsesUUIDDataSuccess(data){
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS,
        payload: data
    };
}

/****
 Survey reports : To Download the responses as uuid data : error
 ****/

export function surveyRawDataDownloadResponsesUUIDDataError(params){
    return {
        type: SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR,
        payload: params
    }
}

/****
 Survey reports : To Download the responses uuid data: in progress
 ****/

export function surveyRawDataDownloadResponsesUUIDDataInProgress(params){
    return{
        type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS,
        payload: params
    };
}

/****
 Survey reports : To Download the responses uuid data : None
 ****/

export function surveyRawDataDownloadResponsesUUIDDataNone(){
    return{
        type:SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_NONE
    };
}