

export const SURVEY_CLONE_SHOW = 'SURVEY_CLONE_SHOW';
export const SURVEY_CLONE_HIDE = 'SURVEY_CLONE_HIDE';

export const SURVEY_CLONE_SET_CLONE_TYPE = 'SURVEY_CLONE_SET_CLONE_TYPE';
export const SURVEY_CLONE_SET_FILTER = 'SURVEY_CLONE_SET_FILTER';

export const SURVEY_CLONE_SURVEY_START = 'SURVEY_CLONE_SURVEY_START';
export const SURVEY_CLONE_SURVEY_SAVE_SUCCESS = 'SURVEY_CLONE_SURVEY_SAVE_SUCCESS';
export const SURVEY_CLONE_SURVEY_SAVE_ERROR = 'SURVEY_CLONE_SURVEY_SAVE_ERROR';
export const SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS = 'SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS';
export const SURVEY_CLONE_SURVEY_SAVE_NONE = 'SURVEY_CLONE_SURVEY_SAVE_NONE';

export const SURVEY_CLONE_SURVEY_TEMPLATE_START = 'SURVEY_CLONE_SURVEY_TEMPLATE_START';
export const SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS = 'SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS';
export const SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR = 'SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR';
export const SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS = 'SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS';
export const SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE = 'SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE';

export const SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE = 'SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE';
export const SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS = 'SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS';
export const SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR = 'SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR';
export const SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_IN_PROGRESS = 'SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_IN_PROGRESS';
export const SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE = 'SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE';

