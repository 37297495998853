import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS,

} from "../../../constants/ActionTypes";

import {
    HTTP_TIMEOUT,
    URL_SURVEY_REPORTING_CATEGORY_LIST,
    URL_SURVEY_REPORTING_CATEGORY_SAVE, 
    URL_SURVEY_REPORTING_CATEGORY_UPDATE,
    URL_SURVEY_REPORTING_CATEGORY_UPDATE_STATUS,
    URL_SURVEY_REPORTING_CATEGORY_ACTIVE_LIST
} from "../../../constants/Urls";

import { store } from '../../../MainApp';

import {
    getSurveyReportingCategoriesError, 
    getSurveyReportingCategoriesSuccess,
    saveSurveyReportingCategoryError,
    saveSurveyReportingCategorySuccess,
    surveyReportingCategoryUpdateStatusError,
    surveyReportingCategoryUpdateStatusSuccess,
    getActiveSurveyReportingCategoriesSuccess
} from "../../../actions";
import AppUtils from "../../../util/util";



/************ methods : http *******************/

/** http : Survey Reporting Category : http save **/
const httpSaveSurveyReportingCategory = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REPORTING_CATEGORY_SAVE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Reporting Category : http update **/
const httpUpdateSurveyReportingCategory = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_REPORTING_CATEGORY_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Reporting Category : all records **/
const httpGetSurveyReportingCategories = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REPORTING_CATEGORY_LIST,data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Reporting Category : http update status **/
const httpSurveyReportingCategoryUpdateStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REPORTING_CATEGORY_UPDATE_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : Survey Reporting Category : all active records **/
const httpGetActiveSurveyReportingCategories = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REPORTING_CATEGORY_ACTIVE_LIST,data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/

/** http : Survey Reporting Category : http response handler **/
function* handleHttpSaveSurveyReportingCategoryResponse({payload}) {
    try {

        let result = yield call( payload.id ? httpUpdateSurveyReportingCategory : httpSaveSurveyReportingCategory,payload);

        if (result.message) {
            yield put(saveSurveyReportingCategoryError(result.response));
        } else {
            yield put(saveSurveyReportingCategorySuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyReportingCategoryError(error));
    }
}

/** http : Survey Reporting Category list : http response handler **/
function* handleHttpGetSurveyReportingCategoriesListResponse({payload}) {
    try {

        let result = yield call(httpGetSurveyReportingCategories, payload);

        if (result.message) {
            yield put(getSurveyReportingCategoriesError(result.response));
        } else {
            yield put(getSurveyReportingCategoriesSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyReportingCategoriesError(error));
    }
}

/** http : Survey Reporting Category status update : http response handler **/
function* handleHttpUpdateSurveyReportingCategoryStatusResponse({payload}) {
    try {

        let result = yield call(httpSurveyReportingCategoryUpdateStatus, payload);

        if (result.message) {
            yield put(surveyReportingCategoryUpdateStatusError(result.response));
        } else {
            yield put(surveyReportingCategoryUpdateStatusSuccess(result));
        }
    } catch (error) {
        yield put(surveyReportingCategoryUpdateStatusError(error));
    }
}

/** http : Survey Reporting Category list : http response handler **/
function* handleHttpGetActiveSurveyReportingCategoriesListResponse({payload}) {
    try {

        let result = yield call(httpGetActiveSurveyReportingCategories, payload);

        if (result.message) {
            yield put(getSurveyReportingCategoriesError(result.response));
        } else {
            yield put(getActiveSurveyReportingCategoriesSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyReportingCategoriesError(error));
    }
}

/************ methods : export access *******************/

/** Survey Reporting Category : save **/
export function* saveSurveyReportingCategory() {
    yield takeEvery(SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE, handleHttpSaveSurveyReportingCategoryResponse);
}

/** Survey Reporting Category : get all records **/
export function* getSurveyReportingCategoriesList() {
    yield takeEvery(SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS, handleHttpGetSurveyReportingCategoriesListResponse);
}

/** Survey Reporting Category : update status **/
export function* updateSurveyReportingCategoryStatus() {
    yield takeEvery(SURVEY_REPORTING_CATEGORY_UPDATE_STATUS, handleHttpUpdateSurveyReportingCategoryStatusResponse);
}

/** Survey Reporting Category : get all active records **/
export function* getActiveSurveyReportingCategoriesList() {
    yield takeEvery(SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS, handleHttpGetActiveSurveyReportingCategoriesListResponse);
}

export default function* rootSaga() {
    yield all([
        fork(saveSurveyReportingCategory),
        fork(getSurveyReportingCategoriesList),
        fork(updateSurveyReportingCategoryStatus),
        fork(getActiveSurveyReportingCategoriesList),
    ]);
}
