import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LSInfoIcon from 'components/LSInfoIcon';
import UIUtils from 'util/ui_utils';
import IntlMessages from 'util/IntlMessages';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

/**
 * 
 * maxCount and textCount can be provided by the configuration
 * maxCount is max value and textCount is min value
 * By providing textCount, we can customize the char count starting number,
 * And Max count will also restricted based on the starting count
 */
const CharacterCounterTextareaWithoutInfoAndLabel = ({ params }) => {

    const {
        maxTextCount,
        minRows, 
        placeholder,
        changeCallback,
        textCount,
        maxCount
    } = params;

    const countRef = useRef();
    
    const [count, setTextCount] = useState(textCount);

    useEffect(()=>{
        if(typeof textCount !== 'undefined' && textCount !== '' && textCount !== null && !isNaN(textCount)) {
            setTextCount(countRef.current.value.length + textCount)
        }
        else {
            setTextCount(countRef.current.value.length)
        }
    }, [countRef])

    const handleChange = (event) => {

        const newText = event.target.value;
        const newTextCount = newText.length;

        if(typeof textCount !== 'undefined' && textCount !== '' && textCount !== null && !isNaN(textCount)) {
            setTextCount(newTextCount + textCount)
        }
        else {
            setTextCount(newTextCount);
        }

        if (changeCallback) {
            changeCallback(params['key'], newText);
        }
    };

    return (
        <div className="input_container_horizontal_send_survey">
            <div className="input_container_horizontal_input_parent_send_survey">
                <div className="label_input_with_icon_container_input">
                    <TextareaAutosize
                        className="form-control form-control-lg payslip_new_input_focus"
                        margin="normal"
                        fullWidth
                        ref={countRef}
                        minRows={minRows}
                        aria-label="minimum height"
                        maxLength={(typeof textCount !== 'undefined' && textCount !== '' && textCount !== null && !isNaN(textCount)) ? maxCount - textCount : maxCount}
                        onChange={(event) => {
                            if (changeCallback) {
                                changeCallback(params['key'], event.target.value)
                            }
                            handleChange(event)
                        }}
                        placeholder={placeholder}
                        value={params['value']}
                    />
                </div>
            </div>
            <h5 className="input_container_horizontal_input_parent_character_count_payslip_send_survey" style={{color: '#242424'}}>
                {count}/{<span>{maxCount}<IntlMessages id={maxTextCount} /></span>}
            </h5>
        </div>
    );
};

export default withStyles(styles)(CharacterCounterTextareaWithoutInfoAndLabel);
