import {
    SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW,
    SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE,
    SURVEY_TRANSLATIONS_DOWNLOAD,
    SURVEY_TRANSLATIONS_DOWNLOAD_ERROR,
    SURVEY_TRANSLATIONS_DOWNLOAD_NONE,
    SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS,
    SURVEY_TRANSLATIONS_HIDE_POPUP,
    SURVEY_TRANSLATIONS_SHOW_POPUP,
    SURVEY_TRANSLATIONS_UPLOAD,
    SURVEY_TRANSLATIONS_UPLOAD_ERROR,
    SURVEY_TRANSLATIONS_UPLOAD_NONE,
    SURVEY_TRANSLATIONS_UPLOAD_SUCCESS
} from "../../../constants/actions/survey/survey/actionsSurveyUtils";

/****
 Survey or questionnaire : to download translations
 ****/
export function downloadSurveyQuestionnaireTranslations(params) {
    return {
        type: SURVEY_TRANSLATIONS_DOWNLOAD,
        payload: params
    };
}

/****
 Survey or questionnaire : to download translations : success
 ****/
export function downloadSurveyQuestionnaireTranslationsSuccess(params) {
    return {
        type: SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS,
        payload: params
    };
}


/****
 Survey or questionnaire : to download translations : error
 ****/
export function downloadSurveyQuestionnaireTranslationsError(params) {
    return {
        type: SURVEY_TRANSLATIONS_DOWNLOAD_ERROR,
        payload: params
    };
}

/****
 Survey or questionnaire : to download translations : none
 ****/
export function downloadSurveyQuestionnaireTranslationsNone(params) {
    return {
        type: SURVEY_TRANSLATIONS_DOWNLOAD_NONE,
        payload: params
    };
}

/****
 Survey or questionnaire : to upload translations
 ****/
export function uploadSurveyQuestionnaireTranslations(params) {
    return {
        type: SURVEY_TRANSLATIONS_UPLOAD,
        payload: params
    };
}

/****
 Survey or questionnaire : to upload translations : success
 ****/
export function uploadSurveyQuestionnaireTranslationsSuccess(params) {
    return {
        type: SURVEY_TRANSLATIONS_UPLOAD_SUCCESS,
        payload: params
    };
}


/****
 Survey or questionnaire : to upload translations : error
 ****/
export function uploadSurveyQuestionnaireTranslationsError(params) {
    return {
        type: SURVEY_TRANSLATIONS_UPLOAD_ERROR,
        payload: params
    };
}

/****
 Survey or questionnaire : to upload translations : none
 ****/
export function uploadSurveyQuestionnaireTranslationsNone(params) {
    return {
        type: SURVEY_TRANSLATIONS_UPLOAD_NONE,
        payload: params
    };
}

/****
 Survey or questionnaire : to show translations popup
 ****/
export function showSurveyQuestionnaireTranslationsPopup() {
    return {
        type: SURVEY_TRANSLATIONS_SHOW_POPUP
    };
}

/****
 Survey or questionnaire : to hide translations popup
 ****/
export function hideSurveyQuestionnaireTranslationsPopup() {
    return {
        type: SURVEY_TRANSLATIONS_HIDE_POPUP
    };
}

/****
 Survey or questionnaire : to set navigation view
 ****/
export function setSurveyQuestionnaireNavigationView(params) {
    return {
        type: SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW,
        payload: params
    };
}

/****
 Survey or questionnaire : to set questionnaire status
 ****/
export function setSurveyQuestionnaireStatus(params) {
    return {
        type: SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE,
        payload: params
    };
}
