import { HTTP_REQUEST_STATUS } from "../../constants/constants";
import {
  WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD,
  WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR,
  WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE,
  WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS,
  WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE,
  WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR,
  WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE,
  WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS,
  WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST,
  WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR,
  WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE,
  WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS,
  WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL,
  WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR,
  WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE,
  WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS,
  WC_GET_PAGINATED_LIST,
  WC_GET_PAGINATED_LIST_ERROR,
  WC_GET_PAGINATED_LIST_NONE,
  WC_GET_PAGINATED_LIST_SUCCESS,
  WC_WORKER_CONTACT_DEACTIVATE_CONTACT,
  WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR,
  WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE,
  WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS,
  WC_WORKER_CONTACT_GET_CONTACT,
  WC_WORKER_CONTACT_GET_CONTACT_ERROR,
  WC_WORKER_CONTACT_GET_CONTACT_NONE,
  WC_WORKER_CONTACT_GET_CONTACT_SUCCESS,
  WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES,
  WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
  WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE,
  WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
  WC_WORKER_CONTACT_GROUPS_ACTIVATE,
  WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR,
  WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE,
  WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS,
  WC_WORKER_CONTACT_GROUPS_DEACTIVATE,
  WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR,
  WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE,
  WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS,
  WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS,
  WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS,
  WC_WORKER_CONTACT_SAVE_CONTACT,
  WC_WORKER_CONTACT_SAVE_CONTACT_ERROR,
  WC_WORKER_CONTACT_SAVE_CONTACT_NONE,
  WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS,
  WC_WORKER_CONTACTS_DEACTIVATE,
  WC_WORKER_CONTACTS_DEACTIVATE_ERROR,
  WC_WORKER_CONTACTS_DEACTIVATE_NONE,
  WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS,
  WC_WORKER_CONTACTS_REACTIVATE,
  WC_WORKER_CONTACTS_REACTIVATE_ERROR,
  WC_WORKER_CONTACTS_REACTIVATE_NONE,
  WC_WORKER_CONTACTS_REACTIVATE_SUCCESS,
  WC_WORKER_CONTACTS_DELETE,
  WC_WORKER_CONTACTS_DELETE_ERROR,
  WC_WORKER_CONTACTS_DELETE_NONE,
  WC_WORKER_CONTACTS_DELETE_SUCCESS,
  WC_WORKER_CONTACTS_DOWNLOAD,
  WC_WORKER_CONTACTS_DOWNLOAD_ERROR,
  WC_WORKER_CONTACTS_DOWNLOAD_NONE,
  WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS,
  WC_WORKER_CONTACTS_GROUPS_FILTER_DATA,
  WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST,
  WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR,
  WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE,
  WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS,
  WC_WORKER_CONTACTS_GROUPS_GET_LIST,
  WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR,
  WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE,
  WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS,
  WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP,
  WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR,
  WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE,
  WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS,
  WC_WORKER_CONTACTS_SET_CURRENT_CONTACT,
} from "../../constants/actions/wc/actionTypesWorkerContacts";

import React from "react";
import IntlMessages from "../../util/IntlMessages";
import {
  CLIENT_CUSTOM_FIELD_TYPES,
  DEFAULT_DATE_DELIMITER,
  DEFAULT_DATE_FORMAT,
  PAGINATION_DATA_STATUS,
  WC_CONTACT_AGE_GROUP_CONFIG,
  WC_CONTACT_GENDER_TYPES,
  WC_CONTACT_SMART_PHONE_TYPES,
  WC_CONTACT_TENURE_GROUP_CONFIG,
  WC_IMPORT_STATUS_STRING_KEYS,
  WC_JOB_ROLES,
  WC_JOB_ROLES_KEYS,
  WORKER_CONTACTS_CONFIG,
} from "../../constants/config";
import AppUtils from "../../util/util";
import UIUtils from "../../util/ui_utils";
import { baseServerUrl } from "../../util/keys";
import IconListItem from "../../components/IconListItem";
import { Tooltip } from "@material-ui/core";

function getLabelIcon(localizationKey, tooltipTitle) {
  return (
    <div className="filter_icon_alert_info_parent_with_padding ">
      <label className="filter_icon_info_title_no_icon">
        <IntlMessages id={localizationKey} />
      </label>

      {tooltipTitle && (
        <Tooltip
          title={
            <span className="ls_theme_tooltip">
              <IntlMessages id={tooltipTitle} />{" "}
            </span>
          }
        >
          <div className="filter_icon_info_title_icon hand"></div>
        </Tooltip>
      )}
    </div>
  );
}

const wcInfoSliders = [
  {
    image: require("../../assets/images/wc/wc_info_slider_1.svg"),
    title: (
      <IntlMessages
        id={"DIC_KEY_WC_VIEW_CONTAINER_HEADER_LABEL_GETTING_START_WITH_CONTACT"}
      />
    ),
    content: (
      <IntlMessages id="DIC_KEY_WC_VIEW_CONTAINER_SUB_HEADER_LABEL_GETTING_START_WITH_CONTACT" />
    ),
  },
  {
    image: require("../../assets/images/wc/wc_info_slider_2.svg"),
    title: (
      <IntlMessages
        id={"DIC_KEY_WC_VIEW_CONTAINER_HEADER_LABEL_ADD_STANDARD_DEMOGRAPHICS"}
      />
    ),
    content: (
      <IntlMessages id="DIC_KEY_WC_VIEW_CONTAINER_SUB_HEADER_LABEL_ADD_STANDARD_DEMOGRAPHICS" />
    ),
  },
  {
    image: require("../../assets/images/wc/wc_info_slider_3.svg"),
    title: (
      <IntlMessages
        id={"DIC_KEY_WC_VIEW_CONTAINER_HEADER_LABEL_ADD_CUSTOM_DEMOGRAPHICS"}
      />
    ),
    content: (
      <IntlMessages id="DIC_KEY_WC_VIEW_CONTAINER_SUB_HEADER_LABEL_ADD_CUSTOM_DEMOGRAPHICS" />
    ),
  },
];

const WC_LIST_MULTI_SELECT_ACTIONS = {
  DEACTIVATE: "DEACTIVATE",
  EXPORT: "EXPORT",
  ASSIGN_GROUPS: "ASSIGN_GROUPS",
  DELETE: "DELETE",
  ACTIVATE: "ACTIVATE",
  REACTIVATE: "REACTIVATE",
};

const WC_LIST_STATUS_ACTIONS = {
  ALL: "WC_LIST_STATUS_ALL",
  ACTIVE: "WC_LIST_STATUS_ACTIVE",
  INACTIVE: "WC_LIST_STATUS_INACTIVE",
  UPDATE_NEEDED: "WC_LIST_STATUS_UPDATE_NEEDED",
};

const wcImportTableHeader = [
  {
    id: "id",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_ID" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal ls_table_first_column",
  },
  {
    id: "user_name",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_CREATED_BY" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "created_date_label",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_CREATED_DATE" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "file_name",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_NAME" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal text_underline",
  },
  {
    id: "content_size",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_FILE_SIZE" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "status_label",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_STATUS" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "logs_label",
    label: <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_LOGS" />,
    visible: true,
    sortable: false,
    isClickable: true,
    className: "ls_table_column_text_link",
  },
];

const wcGroupsTableHeader = [
  {
    id: "list_checkbox",
    label: (
      <div className="info_round_checkbox ls_table_header_checkbox_icon" />
    ),
    visible: true,
    sortable: false,
    isDynamicComponent: true,
  },
  {
    id: "name",
    label: <IntlMessages id="DIC_KEY_WC_CONTACT_GROUPS_TBL_GROUP_NAME" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "wc_groups_table_name",
  },
  {
    id: "description",
    label: (
      <IntlMessages id="DIC_KEY_WC_CONTACT_GROUPS_TBL_GROUP_DESCRIPTION" />
    ),
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "participants",
    label: <IntlMessages id="DIC_KEY_WC_CONTACT_GROUPS_TBL_PARTICIPANTS" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "status",
    label: <IntlMessages id="DIC_KEY_WC_CONTACT_GROUPS_TBL_STATUS" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "modified_date_label",
    label: <IntlMessages id="DIC_KEY_WC_CONTACT_GROUPS_TBL_MODIFIED_DATE" />,
    visible: true,
    sortable: true,
    className: "ls_table_column_text_normal",
  },
];

const workerContactsTableHeaderConfig = [
  /*{
        id: 'info_error',
        label: <div className="info_round_icon ls_table_header_info_icon"/>,
        visible: true,
        sortable: false
    },*/
  {
    id: "list_checkbox",
    label: (
      <div className="info_round_checkbox ls_table_header_checkbox_icon" />
    ),
    visible: true,
    sortable: false,
    isDynamicComponent: true,
  },
  {
    id: "name",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_NAME" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_highlight",
  },
  {
    id: "employee_id",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_EMPLOYEE_ID" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "phone_number_label",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_PHONE" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "groups_label",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_GROUPS" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "status",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_STATUS" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
  {
    id: "modified_date_label",
    label: <IntlMessages id="DIC_KEY_WC_TABLE_DATE_MODIFIED" />,
    visible: true,
    sortable: true,
    isClickable: true,
    className: "ls_table_column_text_normal",
  },
];

const workerContactsListFilterConfig = [
  {
    key: "wc_select_actions",
    type: UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
    localisation: "DIC_KEY_WC_TABLE_FILTER_ACTIONS",
    parent_class: "table_filter_action_dropdown float_right ",
  },
  {
    key: "wc_button_search",
    type: UIUtils.UI_COMPONENT_TYPES.ICON_SQUARE_BUTTON_SEARCH,
    localisation: "",
    isStar: false,
    isMandatory: false,
    isRight: true,
  },
  {
    key: "wc_search_input",
    type: UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
    localisation: "",
    isStar: false,
    isMandatory: false,
  },
  {
    key: "wc_status_dropdown",
    type: UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
    isValueTitle: true,
    localisation: "",
    isStar: false,
    isMandatory: false,
    parent_class: "table_search_input_dropdown",
  },
];

const workerContactsGroupsListFilterConfig = [
  {
    key: "wc_groups_select_actions",
    type: UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
    localisation: "DIC_KEY_WC_CONTACT_GROUPS_TABLE_FILTER_ACTIONS",
    parent_class: "table_filter_action_dropdown float_right ",
  },
  {
    key: "wc_groups_search_input",
    type: UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
    localisation: "",
    isStar: false,
    isMandatory: false,
  },
  {
    key: "wc_groups_status_dropdown",
    type: UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
    isValueTitle: true,
    localisation: "",
    isStar: false,
    isMandatory: false,
    parent_class: "table_search_input_dropdown",
  },
];

const workerContactsViewFilterConfig = [
  {
    key: "wc_view_action_save_n_preview",
    type: UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
    title: <IntlMessages id="DIC_KEY_WC_VIEW_ACTION_LABEL_SAVE_AND_PREVIEW" />,
    parent_class: "float_right ",
  },
  {
    key: "wc_view_action_save",
    type: UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
    title: <IntlMessages id="DIC_KEY_WC_VIEW_ACTION_LABEL_SAVE" />,
    parent_class: "float_right ",
  },
  {
    key: "wc_view_action_deactivate",
    type: UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
    title: <IntlMessages id="DIC_KEY_WC_VIEW_ACTION_LABEL_DEACTIVATE" />,
    parent_class: "float_right mr-2",
  },
  {
    key: "wc_view_action_header",
    type: UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
    title: (
      <IntlMessages id="DIC_KEY_WC_VIEW_ACTION_LABEL_DETAIL_INFORMATION" />
    ),
  },
];

const wcMultiSelectActionsConfig = [
  {
    content: (
      <IconListItem
        iconClass={"menu_popover_item_icon_export"}
        headerText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_EXPORT_HEADER"}
        subHeaderText={
          "DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_EXPORT_SUB_HEADER"
        }
      />
    ),
    value: WC_LIST_MULTI_SELECT_ACTIONS.EXPORT,
    id: WC_LIST_MULTI_SELECT_ACTIONS.EXPORT,
    key: WC_LIST_MULTI_SELECT_ACTIONS.EXPORT,
    disable: false,
  },
  {
    content: (
      <IconListItem
        iconClass={"menu_popover_item_icon_deactivate"}
        headerText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_DEACTIVATE_HEADER"}
        subHeaderContent={
          <span className="menu_popover_item_sub_header_parent">
            <span className="menu_popover_item_sub_header_warning">
              <IntlMessages id="DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_DEACTIVATE_SUB_HEADER_WARNING" />
            </span>
            <span className="menu_popover_item_sub_header">
              <IntlMessages id="DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_DEACTIVATE_SUB_HEADER" />
            </span>
          </span>
        }
      />
    ),
    value: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
    id: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
    key: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
    disable: false,
  },
  /* {
        "content": <IconListItem
            iconClass={"menu_popover_item_icon_add_groups"}
            headerText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_ADD_GROUPS_HEADER"}
            subHeaderText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_ADD_GROUPS_SUB_HEADER"}
        />,
        "value": WC_LIST_MULTI_SELECT_ACTIONS.ASSIGN_GROUPS,
        "id": WC_LIST_MULTI_SELECT_ACTIONS.ASSIGN_GROUPS,
        "key": WC_LIST_MULTI_SELECT_ACTIONS.ASSIGN_GROUPS
    },*/
  /*{
        "content": <IconListItem
            iconClass={"menu_popover_item_icon_delete"}
            headerText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_DELETE_HEADER"}
            subHeaderText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_DELETE_SUB_HEADER"}
        />,
        "value": WC_LIST_MULTI_SELECT_ACTIONS.DELETE,
        "id": WC_LIST_MULTI_SELECT_ACTIONS.DELETE,
        "key": WC_LIST_MULTI_SELECT_ACTIONS.DELETE
    }*/
];
const wcMultiSelectReactiveActionsConfig = [
  {
    content: (
      <IconListItem
        iconClass={"menu_popover_item_icon_reactivate"}
        headerText={"DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_REACTIVATE_HEADER"}
        subHeaderContent={
          <span className="menu_popover_item_sub_header_parent">
            <span className="menu_popover_item_sub_header_warning">
              <IntlMessages id="DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_REACTIVATE_SUB_HEADER_WARNING" />
            </span>
            <span className="menu_popover_item_sub_header">
              <IntlMessages id="DIC_KEY_WC_FILTER_MULTI_SELECT_ACTION_REACTIVATE_SUB_HEADER" />
            </span>
          </span>
        }
      />
    ),
    value: WC_LIST_MULTI_SELECT_ACTIONS.REACTIVATE,
    id: WC_LIST_MULTI_SELECT_ACTIONS.REACTIVATE,
    key: WC_LIST_MULTI_SELECT_ACTIONS.REACTIVATE,
  },
];

const wcMultiStatusActionsConfig = [
  {
    content: (
      <IconListItem headerText={"DIC_KEY_WC_LIST_FILTER_VIEW_STATUS_ALL"} />
    ),
    value: WC_LIST_STATUS_ACTIONS.ALL,
    id: WC_LIST_STATUS_ACTIONS.ALL,
    key: WC_LIST_STATUS_ACTIONS.ALL,
  },
  {
    content: (
      <IconListItem headerText={"DIC_KEY_WC_LIST_FILTER_VIEW_STATUS_ACTIVE"} />
    ),
    value: WC_LIST_STATUS_ACTIONS.ACTIVE,
    id: WC_LIST_STATUS_ACTIONS.ACTIVE,
    key: WC_LIST_STATUS_ACTIONS.ACTIVE,
  },
  {
    content: (
      <IconListItem
        headerText={"DIC_KEY_WC_LIST_FILTER_VIEW_STATUS_INACTIVE"}
      />
    ),
    value: WC_LIST_STATUS_ACTIONS.INACTIVE,
    id: WC_LIST_STATUS_ACTIONS.INACTIVE,
    key: WC_LIST_STATUS_ACTIONS.INACTIVE,
  },
];

const wcGroupsMultiStatusActionsConfig = [
  {
    content: (
      <IconListItem
        headerText={"DIC_KEY_WC_GROUPS_LIST_FILTER_VIEW_STATUS_ALL"}
      />
    ),
    value: WC_LIST_STATUS_ACTIONS.ALL,
    id: WC_LIST_STATUS_ACTIONS.ALL,
    key: WC_LIST_STATUS_ACTIONS.ALL,
  },
  {
    content: (
      <IconListItem
        headerText={"DIC_KEY_WC_GROUPS_LIST_FILTER_VIEW_STATUS_ACTIVE"}
      />
    ),
    value: WC_LIST_STATUS_ACTIONS.ACTIVE,
    id: WC_LIST_STATUS_ACTIONS.ACTIVE,
    key: WC_LIST_STATUS_ACTIONS.ACTIVE,
  },
  {
    content: (
      <IconListItem
        headerText={"DIC_KEY_WC_GROUPS_LIST_FILTER_VIEW_STATUS_INACTIVE"}
      />
    ),
    value: WC_LIST_STATUS_ACTIONS.INACTIVE,
    id: WC_LIST_STATUS_ACTIONS.INACTIVE,
    key: WC_LIST_STATUS_ACTIONS.INACTIVE,
  },
];

const wcGroupsMultiSelectActionsConfig = [
  {
    content: (
      <IconListItem
        iconClass={"menu_popover_item_icon_deactivate"}
        headerText={
          "DIC_KEY_WC_GROUPS_FILTER_MULTI_SELECT_ACTION_DEACTIVATE_HEADER"
        }
        subHeaderText={
          "DIC_KEY_WC_GROUPS_FILTER_MULTI_SELECT_ACTION_DEACTIVATE_SUB_HEADER"
        }
      />
    ),
    value: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
    id: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
    key: WC_LIST_MULTI_SELECT_ACTIONS.DEACTIVATE,
  },
  {
    content: (
      <IconListItem
        iconClass={"menu_popover_item_icon_deactivate"}
        headerText={
          "DIC_KEY_WC_GROUPS_FILTER_MULTI_SELECT_ACTION_ACTIVATE_HEADER"
        }
        subHeaderText={
          "DIC_KEY_WC_GROUPS_FILTER_MULTI_SELECT_ACTION_ACTIVATE_SUB_HEADER"
        }
      />
    ),
    value: WC_LIST_MULTI_SELECT_ACTIONS.ACTIVATE,
    id: WC_LIST_MULTI_SELECT_ACTIONS.ACTIVATE,
    key: WC_LIST_MULTI_SELECT_ACTIONS.ACTIVATE,
  },
];

const wcFilterContactsAlert = {
  key: "WC_FILTER_ALERT",
  title: (
    <div className="filter_icon_alert_info_parent">
      <div className="filter_icon_alert_info" />
      <div className="filter_icon_alert_info_title">
        <IntlMessages id="DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_ALERT" />
      </div>
      <span className="filter_icon_alert_settings" />
    </div>
  ),
  type: UIUtils.UI_COMPONENT_TYPES.LS_BADGE_LIST,
  items: [
    {
      key: "ALERT_ALL",
      label: (
        <IntlMessages id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_ALERT_ALL"} />
      ),
      defaultValue: true,
      isAll: true,
    },
    {
      key: "ALERT_UPDATE_NEEDED",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_ALERT_UPDATE_NEEDED"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
    {
      key: "ALERT_UPDATED",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_ALERT_UPDATED"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
  ],
};

const wcFilterAppliedFilters = {
  key: "WC_FILTER_APPLIED_FILTERS",
  title: (
    <div className="filter_icon_alert_info_parent">
      <div className="filter_icon_info_title_no_icon">
        <IntlMessages id="DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APPLIED_FILTERS" />
      </div>
    </div>
  ),
  type: UIUtils.UI_COMPONENT_TYPES.CHIP_ACTIVE_ARRAY_REMOVABLE,
};

const wcFilterParticipantStatus = {
  key: "WC_FILTER_STATUS",
  title: getLabelIcon("DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STATUS"),
  type: UIUtils.UI_COMPONENT_TYPES.LS_BADGE_LIST,
  items: [
    {
      key: "STATUS_ALL",
      label: (
        <IntlMessages id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STATUS_ALL"} />
      ),
      defaultValue: true,
      isAll: true,
    },
    {
      key: "STATUS_ACTIVE",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STATUS_ACTIVE"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
    {
      key: "STATUS_INACTIVE",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STATUS_INACTIVE"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
  ],
};

const wcFilterParticipantBasicInfo = {
  key: "WC_FILTER_BASIC_INFO",
  title: getLabelIcon(
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO",
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_TOOLTIP"
  ),
  parent_class: "filter_input_container_child",
  type: UIUtils.UI_COMPONENT_TYPES.LS_INPUT_LIST_NO_TITLE,
  items: [
    {
      key: "BASIC_INFO_EMPLOYEE_ID",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_EMPLOYEE_ID",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_EMPLOYEE_ID",
      type: UIUtils.UI_COMPONENT_TYPES.INPUT_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
    },
    {
      key: "BASIC_INFO_FULL_NAME",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_FULL_NAME",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_FULL_NAME",
      type: UIUtils.UI_COMPONENT_TYPES.INPUT_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
    },
    {
      key: "BASIC_INFO_PHONE",
      placeHolderKey: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_PHONE",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_BASIC_INFO_PHONE",
      type: UIUtils.UI_COMPONENT_TYPES.INPUT_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
    },
  ],
};

const wcFilterParticipantSelfRegistered = {
  key: "WC_FILTER_SELF_REGISTERED",
  title: (
    <div className="filter_icon_alert_info_parent">
      <div className="filter_icon_info_title_no_icon">
        <IntlMessages id="DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APP_SELF_REGISTERED" />
      </div>
    </div>
  ),
  type: UIUtils.UI_COMPONENT_TYPES.LS_BADGE_LIST,
  items: [
    {
      key: "APP_SELF_REGISTERED_ALL",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APP_SELF_REGISTERED_ALL"}
        />
      ),
      defaultValue: true,
      isAll: true,
    },
    {
      key: "APP_SELF_REGISTERED_YES",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APP_SELF_REGISTERED_YES"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
    {
      key: "APP_SELF_REGISTERED_NO",
      label: (
        <IntlMessages
          id={"DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APP_SELF_REGISTERED_NO"}
        />
      ),
      defaultValue: false,
      activeClass: "ls_theme_badge_active",
    },
  ],
};

const wcFilterParticipantStandardInfo = {
  key: "WC_FILTER_STANDARD_INFO",
  title: getLabelIcon(
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO",
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_TOOLTIP"
  ),
  parent_class: "filter_input_container_child",
  type: UIUtils.UI_COMPONENT_TYPES.LS_INPUT_LIST_NO_TITLE,
  items: [
    {
      key: "STANDARD_INFO_GENDER",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER",
      type: UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE,
      className: "",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: "STANDARD_INFO_GENDER_MALE",
          key: "STANDARD_INFO_GENDER_MALE",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_MALE",
          label: "",
        },
        {
          id: "STANDARD_INFO_GENDER_FEMALE",
          key: "STANDARD_INFO_GENDER_FEMALE",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_FEMALE",
          label: "",
        },
        {
          id: "STANDARD_INFO_GENDER_OTHERS",
          key: "STANDARD_INFO_GENDER_OTHERS",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_OTHERS",
          label: "",
        },
        {
          id: "STANDARD_INFO_GENDER_UNDISCLOSED",
          key: "STANDARD_INFO_GENDER_UNDISCLOSED",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_UNDISCLOSED",
          label: "",
        },
      ],
    },
    {
      key: "STANDARD_INFO_DOB",
      placeHolderKey: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_DOB",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_DOB",
      type: UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["key"],
          labelKey: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["key"],
          labelKey: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["key"],
          labelKey: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["key"],
          labelKey: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["key"],
          labelKey: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          labelKey:
            WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          label: "",
        },
      ],
    },
    {
      key: "STANDARD_INFO_START_DATE",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_START_DATE",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_START_DATE",
      type: UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["key"],
          labelKey: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["key"],
          labelKey: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["key"],
          labelKey: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["translationKey"],
          label: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          labelKey:
            WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          label: "",
        },
      ],
    },
    {
      key: "STANDARD_INFO_SMART_PHONE",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER",
      label:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER",
      type: UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: "STANDARD_INFO_SMART_PHONE_YES",
          key: "STANDARD_INFO_SMART_PHONE_YES",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_YES",
          label: "",
        },
        {
          id: "STANDARD_INFO_SMART_PHONE_NO",
          key: "STANDARD_INFO_SMART_PHONE_NO",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_NO",
          label: "",
        },
        {
          id: "STANDARD_INFO_SMART_PHONE_UNKNOWN",
          key: "STANDARD_INFO_SMART_PHONE_UNKNOWN",
          labelKey:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_UNDISCLOSED",
          label: "",
        },
      ],
    },
  ],
};

const filterAudiencesStandardInfo = {
  key: "WC_FILTER_STANDARD_INFO",
  title: getLabelIcon(
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO",
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_TOOLTIP"
  ),
  parent_class: "filter_input_container_child",
  type: UIUtils.UI_COMPONENT_TYPES.LS_INPUT_LIST_NO_TITLE,
  items: [
    {
      key: "JOB_ROLE",
      placeHolderKey: "DIC_KEY_WC_VIEW_JOB_ROLE",
      label: "DIC_KEY_WC_VIEW_JOB_ROLE",
      type: "multiselect_react_chips_dropdown",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: 1,
          key: WC_JOB_ROLES_KEYS.WORKER,
          // value: WC_JOB_ROLES.WORKER,
          localisation: "DIC_KEY_WC_VIEW_JOB_ROLE_WORKER",
          value: "DIC_KEY_WC_VIEW_JOB_ROLE_WORKER",
          labelKey: "",
        },
        {
          id: 2,
          key: WC_JOB_ROLES_KEYS.SUPERVISOR,
          // value: WC_JOB_ROLES.SUPERVISOR,
          localisation: "DIC_KEY_WC_VIEW_JOB_ROLE_SUPERVISOR",
          value: "DIC_KEY_WC_VIEW_JOB_ROLE_SUPERVISOR",
          labelKey: "",
        },
        {
          id: 3,
          key: WC_JOB_ROLES_KEYS.MANAGER,
          // value: WC_JOB_ROLES.MANAGER,
          localisation: "DIC_KEY_WC_VIEW_JOB_ROLE_MANAGER",
          value: "DIC_KEY_WC_VIEW_JOB_ROLE_MANAGER",
          labelKey: "",
        },
        {
          id: 4,
          key: WC_JOB_ROLES_KEYS.HEAD,
          // value: WC_JOB_ROLES.HEAD,
          localisation: "DIC_KEY_WC_VIEW_JOB_ROLE_HEAD",
          value: "DIC_KEY_WC_VIEW_JOB_ROLE_HEAD",
          labelKey: "",
        },
      ],
    },

    {
      key: "STANDARD_INFO_GENDER",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER",
      type: "multiselect_react_chips_dropdown",
      className: "",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: "STANDARD_INFO_GENDER_MALE",
          key: "STANDARD_INFO_GENDER_MALE",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_MALE",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_MALE",
          labelKey: "",
        },
        {
          id: "STANDARD_INFO_GENDER_FEMALE",
          key: "STANDARD_INFO_GENDER_FEMALE",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_FEMALE",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_FEMALE",
          labelKey: "",
        },
        {
          id: "STANDARD_INFO_GENDER_OTHERS",
          key: "STANDARD_INFO_GENDER_OTHERS",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_OTHERS",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_OTHERS",
          labelKey: "",
        },
        {
          id: "STANDARD_INFO_GENDER_UNDISCLOSED",
          key: "STANDARD_INFO_GENDER_UNDISCLOSED",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_UNDISCLOSED",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GENDER_UNDISCLOSED",
          labelKey: "",
        },
      ],
    },

    {
      key: "STANDARD_INFO_DOB",
      placeHolderKey: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_DOB",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_DOB",
      type: "multiselect_react_chips_dropdown",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["key"],
          localisation: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["-1_18"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["key"],
          localisation: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["18_30"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["key"],
          localisation: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["30_40"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["key"],
          localisation: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["40_50"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["key"],
          localisation: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["50_-1"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          key: WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          localisation:
            WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          value: WC_CONTACT_AGE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          labelKey: "",
        },
      ],
    },
    {
      key: "STANDARD_INFO_START_DATE",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_START_DATE",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_START_DATE",
      type: "multiselect_react_chips_dropdown",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["key"],
          localisation:
            WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["translationKey"],
          value: WC_CONTACT_TENURE_GROUP_CONFIG["-1_1"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["key"],
          localisation: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["translationKey"],
          value: WC_CONTACT_TENURE_GROUP_CONFIG["1_3"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["key"],
          localisation:
            WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["translationKey"],
          value: WC_CONTACT_TENURE_GROUP_CONFIG["3_-1"]["translationKey"],
          labelKey: "",
        },
        {
          id: WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          key: WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["key"],
          localisation:
            WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          value:
            WC_CONTACT_TENURE_GROUP_CONFIG["UNDISCLOSED"]["translationKey"],
          labelKey: "",
        },
      ],
    },
    {
      key: "STANDARD_INFO_SMART_PHONE",
      placeHolderKey:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER",
      label:
        "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER",
      type: "multiselect_react_chips_dropdown",
      defaultValue: "",
      variant: "outlined",
      items: [
        {
          id: "STANDARD_INFO_SMART_PHONE_YES",
          key: "STANDARD_INFO_SMART_PHONE_YES",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_YES",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_YES",
          labelKey: "",
        },
        {
          id: "STANDARD_INFO_SMART_PHONE_NO",
          key: "STANDARD_INFO_SMART_PHONE_NO",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_NO",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_NO",
          labelKey: "",
        },
        {
          id: "STANDARD_INFO_SMART_PHONE_UNKNOWN",
          key: "STANDARD_INFO_SMART_PHONE_UNKNOWN",
          localisation:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_UNDISCLOSED",
          value:
            "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_SMART_PHONE_USER_UNDISCLOSED",
          labelKey: "",
        },
      ],
    },
  ],
};

const wcFilterParticipantCustomDemographics = {
  key: "WC_FILTER_CUSTOM_DEMO_GRAPHIC",
  title: getLabelIcon(
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_CUSTOM_DEMOGRAPHIC",
    "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_CUSTOM_DEMOGRAPHIC_TOOLTIP"
  ),
  parent_class: "filter_input_container_child",
  type: UIUtils.UI_COMPONENT_TYPES.LS_INPUT_LIST_NO_TITLE,
  items: [
    {
      key: "STANDARD_INFO_GROUP",
      placeHolderKey: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GROUP",
      label: "DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_STANDARD_INFO_GROUP",
      type: UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
      items: [],
    },
  ],
};

const wcFilterParticipantDateModified = {
  key: "WC_FILTER_DATE_MODIFIED",
  title: (
    <div className="filter_icon_alert_info_parent">
      <div className="filter_icon_info_title_no_icon">
        <IntlMessages id="DIC_KEY_WC_LIST_FILTER_HEADER_TITLE_APP_DATE_MODIFIED" />
      </div>
    </div>
  ),
  type: UIUtils.UI_COMPONENT_TYPES.BADGE_DATE_RANGE,
  items: [],
};

const JOB_ROLE_TOAST = (
  <div>
    <div>
      <span className="wc_job_role_toast_header mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_WORKERS" />
      </span>
      <span className="wc_job_role_toast_content mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_WORKERS_INFO" />
      </span>
    </div>
    <div>
      <span className="wc_job_role_toast_header mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_SUPERVISORS" />
      </span>
      <span className="wc_job_role_toast_content mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_SUPERVISORS_INFO" />
      </span>
    </div>
    <div>
      <span className="wc_job_role_toast_header mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_MANAGERS" />
      </span>
      <span className="wc_job_role_toast_content mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_MANAGERS_INFO" />
      </span>
    </div>
    <div>
      <span className="wc_job_role_toast_header mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_HEADS" />
      </span>
      <span className="wc_job_role_toast_content mr-1">
        <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_TOOL_TIP_HEADS_INFO" />
      </span>
    </div>
  </div>
);

const wcGroupsAddFieldConfig = [
  {
    key: "wc_groups_name",
    title: "DIC_KEY_WC_CONTACT_GROUPS_EDIT_LABEL_TITLE",
    type: UIUtils.UI_COMPONENT_TYPES.TEXT_BOX_HIGHLIGHT_TITLE,
    parent_class: "row col-12 p-0 m-0 mb-4",
  },
  {
    key: "wc_groups_description",
    title: "DIC_KEY_WC_CONTACT_GROUPS_EDIT_LABEL_DESCRIPTION",
    type: UIUtils.UI_COMPONENT_TYPES.TEXT_BOX_HIGHLIGHT_TITLE,
    parent_class: "row col-12 p-0 m-0 mb-2",
  },
];

const wcViewBasicInformationConfig = [
  {
    key: "wc_basic_info_employee_id",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_EMPLOYEE_ID",
    isStar: true,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_EMPLOYEE_ID_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_basic_info_first_name",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_FIRST_NAME",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_FIRST_NAME_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_basic_info_last_name",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_LAST_NAME",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_LAST_NAME_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_basic_info_phone",
    // type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_PHONE_INPUT_WITH_INFO,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_PHONE",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_PHONE_PLACEHOLDER",
    variant: "outlined",
  },
  /* {
         'key': 'wc_basic_info_self_registered',
         'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_TEXT,
         'style': {},
         'labelClass': "pl-3",
         'localisation': "DIC_KEY_WC_VIEW_APP_SELF_REGISTERED",
         'isStar': false,
         'isMandatory': false,
         'parent_class': "form_container_root"
     },*/
  {
    key: "wc_basic_info_status",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_TEXT,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_STATUS",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
  },
];

const wcViewStandardDemographicConfig = [
  {
    key: "wc_standard_demo_gender",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_GENDER",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_GENDER_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_standard_demo_dob",
    type: UIUtils.UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_DATE_OF_BIRTH",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root wc_standard_demo_dob_margin ",
    placeholder: DEFAULT_DATE_FORMAT,
    placeholderKey: "DIC_KEY_WC_VIEW_DATE_OF_BIRTH_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_standard_demo_start_date",
    type: UIUtils.UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_START_DATE",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholder: DEFAULT_DATE_FORMAT,
    placeholderKey: "DIC_KEY_WC_VIEW_START_DATE_PLACEHOLDER",
    variant: "outlined",
  },
  {
    key: "wc_standard_demo_smart_phone",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_SMARTPHONE_USER",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_SMARTPHONE_USER_PLACEHOLDER",
    variant: "outlined",
    isOverflowEnabled: false,
  },
  {
    key: "wc_standard_demo_job_role",
    type: UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN_WITH_INFO,
    style: {},
    labelClass: "pl-3",
    localisation: "DIC_KEY_WC_VIEW_JOB_ROLE",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root wc_standard_demo_dob_margin",
    placeholderKey: "DIC_KEY_WC_VIEW_JOB_ROLE_PLACEHOLDER",
    variant: "outlined",
    isOverflowEnabled: true,
    toastLabel: JOB_ROLE_TOAST,
  },
];
const wcViewCustomDemographicConfig = [
  {
    key: "wc_standard_demo_group",
    type: "multiselect_react_chips_dropdown",
    style: {},
    labelClass: "",
    localisation: "DIC_KEY_WC_VIEW_GROUP",
    isStar: false,
    isMandatory: false,
    parent_class: "form_container_root",
    placeholderKey: "DIC_KEY_WC_VIEW_GROUP_PLACEHOLDER",
    variant: "outlined",
    isOverflowEnabled: true,
  },
];
const smartPhoneDropDownValues = [
  /*{
        key: WC_CONTACT_SMART_PHONE_TYPES.UNKNOWN,
        value: WC_CONTACT_SMART_PHONE_TYPES.UNKNOWN,
        label: <IntlMessages id="DIC_KEY_WC_VIEW_SMART_PHONE_UNKNOWN"/>
    },*/
  {
    key: WC_CONTACT_SMART_PHONE_TYPES.YES,
    value: WC_CONTACT_SMART_PHONE_TYPES.YES,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_SMART_PHONE_YES" />,
  },
  {
    key: WC_CONTACT_SMART_PHONE_TYPES.NO,
    value: WC_CONTACT_SMART_PHONE_TYPES.NO,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_SMART_PHONE_NO" />,
  },
];

const jobRolesDropDownValues = [
  {
    key: WC_JOB_ROLES.WORKER,
    value: WC_JOB_ROLES.WORKER,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_WORKER" />,
  },
  {
    key: WC_JOB_ROLES.SUPERVISOR,
    value: WC_JOB_ROLES.SUPERVISOR,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_SUPERVISOR" />,
  },
  {
    key: WC_JOB_ROLES.MANAGER,
    value: WC_JOB_ROLES.MANAGER,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_MANAGER" />,
  },
  {
    key: WC_JOB_ROLES.HEAD,
    value: WC_JOB_ROLES.HEAD,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_JOB_ROLE_HEAD" />,
  },
];

const genderDropDownValues = [
  {
    key: WC_CONTACT_GENDER_TYPES.MALE,
    value: WC_CONTACT_GENDER_TYPES.MALE,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_GENDER_MALE" />,
  },
  {
    key: WC_CONTACT_GENDER_TYPES.FEMALE,
    value: WC_CONTACT_GENDER_TYPES.FEMALE,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_GENDER_FEMALE" />,
  },
  {
    key: WC_CONTACT_GENDER_TYPES.OTHERS,
    value: WC_CONTACT_GENDER_TYPES.OTHERS,
    label: <IntlMessages id="DIC_KEY_WC_VIEW_GENDER_OTHERS" />,
  } /*,
    {
        key: WC_CONTACT_GENDER_TYPES.UNDISCLOSED,
        value: WC_CONTACT_GENDER_TYPES.UNDISCLOSED,
        label: <IntlMessages id="DIC_KEY_WC_VIEW_GENDER_UNDISCLOSED"/>
    }*/,
];

export const TABLE_PAGINATION_OPTIONS = [50, 100, 150, 200];

const initialData = {
  alertMessage: "",
  downloadWCTemplateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  downloadWCAttachmentStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  getImportListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  uploadContactsExcelStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  getWorkerContactListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsReactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsGroupsGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsGroupsSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcGetActiveContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcDeactivateContactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcDeactivateContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  wcActivateContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

  wcFilterAppliedFilters: wcFilterAppliedFilters,
  wcFilterContactsAlert: wcFilterContactsAlert,
  wcFilterParticipantStatus: wcFilterParticipantStatus,
  wcFilterParticipantBasicInfo: wcFilterParticipantBasicInfo,
  wcFilterParticipantSelfRegistered: wcFilterParticipantSelfRegistered,
  wcFilterParticipantStandardInfo: wcFilterParticipantStandardInfo,
  filterAudiencesStandardInfo: filterAudiencesStandardInfo,
  wcFilterParticipantCustomDemographics: wcFilterParticipantCustomDemographics,
  wcFilterParticipantDateModified: wcFilterParticipantDateModified,

  wcMultiSelectActionsConfig: wcMultiSelectActionsConfig,
  wcMultiStatusActionsConfig: wcMultiStatusActionsConfig,
  WC_LIST_MULTI_SELECT_ACTIONS: WC_LIST_MULTI_SELECT_ACTIONS,
  WC_LIST_STATUS_ACTIONS: WC_LIST_STATUS_ACTIONS,

  workerContactsTableHeaderConfig: workerContactsTableHeaderConfig,
  workerContactsData: [],
  workerContactsDataTotalRecords: 0,
  workerContactsDataStart: 0,
  workerContactsDataPages: {},
  workerContactsDataIdList: {},
  workerContactsContactGroupNames: {},
  workerContactsContactGroupDropDownValues: [],
  wcMultiSelectReactiveActionsConfig: wcMultiSelectReactiveActionsConfig,
  wcGroupsMultiStatusActionsConfig: wcGroupsMultiStatusActionsConfig,
  wcGroupsMultiSelectActionsConfig: wcGroupsMultiSelectActionsConfig,
  workerContactsListFilterConfig: workerContactsListFilterConfig,

  importListData: [],
  wcImportTableHeader: wcImportTableHeader,

  contactGroupsListData: [],
  contactGroupsListFilteredData: [],
  wcGroupsTableHeader: wcGroupsTableHeader,
  workerContactsGroupsListFilterConfig: workerContactsGroupsListFilterConfig,
  wcGroupsAddFieldConfig: wcGroupsAddFieldConfig,

  wcInfoSliders: wcInfoSliders,
  workerContactsViewFilterConfig: workerContactsViewFilterConfig,
  wcViewBasicInformationConfig: wcViewBasicInformationConfig,
  wcViewStandardDemographicConfig: wcViewStandardDemographicConfig,
  wcViewCustomDemographicConfig: wcViewCustomDemographicConfig,
  selectedContact: {},
  smartPhoneDropDownValues: smartPhoneDropDownValues,
  jobRolesDropDownValues: jobRolesDropDownValues,
  genderDropDownValues: genderDropDownValues,
  activeContactGroupsMap: [],
  clientParticipantCustomFields: [],
  TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,
};

const ReducerWorkerContacts = (state = initialData, action) => {
  switch (action.type) {
    case WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS: {
      let data = updateWCListFilterTranslations(
        wcFilterParticipantStandardInfo
      );

      return {
        ...state,
        wcFilterParticipantStandardInfo: data,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_ACTIVATE: {
      return {
        ...state,
        wcActivateContactGroupsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS: {
      let alertMessage = action.payload.message;

      return {
        ...state,
        wcActivateContactGroupsStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcActivateContactGroupsStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE: {
      return {
        ...state,
        wcActivateContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_GROUPS_DEACTIVATE: {
      return {
        ...state,
        wcDeactivateContactGroupsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS: {
      let alertMessage = action.payload.message;

      return {
        ...state,
        wcDeactivateContactGroupsStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcDeactivateContactGroupsStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE: {
      return {
        ...state,
        wcDeactivateContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_DEACTIVATE_CONTACT: {
      return {
        ...state,
        wcDeactivateContactStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS: {
      let alertMessage = action.payload.message;
      let data = state.selectedContact;

      if (typeof action.payload.data != "undefined") {
        data = action.payload.data;
      }

      return {
        ...state,
        wcDeactivateContactStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
        selectedContact: data,
      };
    }

    case WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcDeactivateContactStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE: {
      return {
        ...state,
        wcDeactivateContactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES: {
      return {
        ...state,
        clientParticipantCustomFields: [],
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
      };
    }

    case WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS: {
      let clientParticipantCustomFields = parseClientCustomFieldValues(
        action.payload
      );
      let wcFilterParticipantCustomDemographics =
        state.wcFilterParticipantCustomDemographics;

      if (action.payload) {
        wcFilterParticipantCustomDemographics.items = generateWCListFilterCustomDemographics(
          action.payload
        );
      }

      return {
        ...state,
        clientParticipantCustomFields: clientParticipantCustomFields,
        wcFilterParticipantCustomDemographics: wcFilterParticipantCustomDemographics,
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.SUCCESS,
      };
    }

    case WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR: {
      return {
        ...state,
        clientParticipantCustomFields: [],
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.FAIL,
      };
    }

    case WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE: {
      return {
        ...state,
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
      };
    }

    case WC_WORKER_CONTACT_GET_CONTACT: {
      return {
        ...state,
        wcContactsGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
      };
    }

    case WC_WORKER_CONTACT_GET_CONTACT_SUCCESS: {
      let data = state.selectedContact;

      if (typeof action.payload.data != "undefined") {
        data = action.payload.data;
      }
      return {
        ...state,
        selectedContact: data,
        wcContactsGetStatus: HTTP_REQUEST_STATUS.SUCCESS,
      };
    }

    case WC_WORKER_CONTACT_GET_CONTACT_ERROR: {
      return {
        ...state,
        wcContactsGetStatus: HTTP_REQUEST_STATUS.FAIL,
      };
    }

    case WC_WORKER_CONTACT_GET_CONTACT_NONE: {
      return {
        ...state,
        wcContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST: {
      return {
        ...state,
        wcGetActiveContactGroupsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS: {
      let activeContactGroupsMap = [];

      if (typeof action.payload.data != "undefined") {
        for (let i = 0; i < action.payload.data.length; i++) {
          activeContactGroupsMap.push({
            id: action.payload.data[i].id,
            value: action.payload.data[i].id,
            label: action.payload.data[i].name,
          });
        }
      }

      return {
        ...state,
        activeContactGroupsMap: activeContactGroupsMap,
        wcGetActiveContactGroupsStatus: HTTP_REQUEST_STATUS.SUCCESS,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR: {
      return {
        ...state,
        wcGetActiveContactGroupsStatus: HTTP_REQUEST_STATUS.FAIL,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE: {
      return {
        ...state,
        wcGetActiveContactGroupsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
      };
    }

    case WC_WORKER_CONTACT_SAVE_CONTACT: {
      return {
        ...state,
        wcContactsSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS: {
      let alertMessage = action.payload.message;
      let selectedContact = state.selectedContact;
      if (typeof action.payload.data != "undefined") {
        selectedContact = action.payload.data;
      }

      return {
        ...state,
        wcContactsSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
        selectedContact: selectedContact,
      };
    }

    case WC_WORKER_CONTACT_SAVE_CONTACT_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsSaveStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACT_SAVE_CONTACT_NONE: {
      return {
        ...state,
        wcContactsSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_SET_CURRENT_CONTACT: {
      return {
        ...state,
        selectedContact: action.payload,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_FILTER_DATA: {
      let searchItem = action.payload;
      let contactGroupsListData = state.contactGroupsListData;

      searchItem = searchItem.toUpperCase();

      let contactGroupsListFilteredData = contactGroupsListData.filter(function(
        item
      ) {
        return (
          searchItem === "" ||
          item.name.toUpperCase().indexOf(searchItem) != -1 ||
          item.description.toUpperCase().indexOf(searchItem) != -1
        );
      });

      return {
        ...state,
        contactGroupsListFilteredData: contactGroupsListFilteredData,
      };
    }

    case WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS: {
      let searchItem = action.payload;
      let contactGroupsListData = state.contactGroupsListData;

      let contactGroupsListFilteredData = contactGroupsListData.filter(function(
        item
      ) {
        let isValid =
          searchItem === "" || searchItem.value === WC_LIST_STATUS_ACTIONS.ALL;

        if (!isValid) {
          if (
            searchItem.value === WC_LIST_STATUS_ACTIONS.ACTIVE &&
            item["is_active"]
          ) {
            isValid = true;
          } else if (
            searchItem.value === WC_LIST_STATUS_ACTIONS.INACTIVE &&
            !item["is_active"]
          ) {
            isValid = true;
          }
        }

        return isValid;
      });

      return {
        ...state,
        contactGroupsListFilteredData: contactGroupsListFilteredData,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP: {
      return {
        ...state,
        wcContactsGroupsSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS: {
      let alertMessage = action.payload.message;

      return {
        ...state,
        wcContactsGroupsSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsGroupsSaveStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE: {
      return {
        ...state,
        wcContactsGroupsSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_LIST: {
      return {
        ...state,
        wcContactsGroupsGetListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
        contactGroupsListData: [],
        contactGroupsListFilteredData: [],
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS: {
      let data = action.payload.data;
      let alertMessage = action.payload.message;

      data = parseWorkerContactGroupsList(data);
      let contactGroupsListFilteredData = JSON.parse(JSON.stringify(data));

      return {
        ...state,
        wcContactsGroupsGetListStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
        contactGroupsListData: data,
        contactGroupsListFilteredData: contactGroupsListFilteredData,
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsGroupsGetListStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
        contactGroupsListData: [],
        contactGroupsListFilteredData: [],
      };
    }

    case WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE: {
      return {
        ...state,
        wcContactsGroupsGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DOWNLOAD: {
      return {
        ...state,
        wcContactsDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DOWNLOAD_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      if (
        typeof action.payload.data != "undefined" &&
        typeof action.payload.data.path != "undefined"
      ) {
        let filePath = baseServerUrl + "/" + action.payload.data.path;
        AppUtils.downloadFile(filePath);
      }

      return {
        ...state,
        wcContactsDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DOWNLOAD_NONE: {
      return {
        ...state,
        wcContactsDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DELETE: {
      return {
        ...state,
        wcContactsDeleteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DELETE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsDeleteStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DELETE_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      return {
        ...state,
        wcContactsDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DELETE_NONE: {
      return {
        ...state,
        wcContactsDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DEACTIVATE: {
      return {
        ...state,
        wcContactsDeactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_DEACTIVATE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsDeactivateStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      return {
        ...state,
        wcContactsDeactivateStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_DEACTIVATE_NONE: {
      return {
        ...state,
        wcContactsDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }
    case WC_WORKER_CONTACTS_REACTIVATE: {
      return {
        ...state,
        wcContactsReactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    case WC_WORKER_CONTACTS_REACTIVATE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        wcContactsReactivateStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_REACTIVATE_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      return {
        ...state,
        wcContactsReactivateStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    case WC_WORKER_CONTACTS_REACTIVATE_NONE: {
      return {
        ...state,
        wcContactsReactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    case WC_GET_PAGINATED_LIST: {
      let workerContactsData = state.workerContactsData;
      let workerContactsDataPages = state.workerContactsDataPages;
      let workerContactsDataIdList = state.workerContactsDataIdList;
      let workerContactsContactGroupNames =
        state.workerContactsContactGroupNames;
      let workerContactsContactGroupDropDownValues =
        state.workerContactsContactGroupDropDownValues;

      if (typeof action.payload.start === "undefined") {
        workerContactsData = [];
        workerContactsContactGroupNames = {};
        workerContactsContactGroupDropDownValues = [];
        workerContactsDataPages = {};
        workerContactsDataIdList = {};
      } else {
        let currentIndex = parseInt(
          action.payload.start /
            TABLE_PAGINATION_OPTIONS[TABLE_PAGINATION_OPTIONS.length - 1]
        );
        workerContactsDataPages[currentIndex]["dataStatus"] =
          PAGINATION_DATA_STATUS.IN_PROGRESS;
      }

      return {
        ...state,
        getWorkerContactListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        workerContactsData: workerContactsData,
        workerContactsDataPages: workerContactsDataPages,
        workerContactsContactGroupNames: workerContactsContactGroupNames,
        workerContactsContactGroupDropDownValues: workerContactsContactGroupDropDownValues,
        workerContactsDataIdList: workerContactsDataIdList,
      };
    }

    case WC_GET_PAGINATED_LIST_SUCCESS: {
      let workerContactsData = state.workerContactsData;
      let workerContactsDataPages = state.workerContactsDataPages;
      let workerContactsDataIdList = state.workerContactsDataIdList;
      let workerContactsDataTotalRecords = state.workerContactsDataTotalRecords;
      let workerContactsContactGroupNames =
        state.workerContactsContactGroupNames;
      let workerContactsContactGroupDropDownValues =
        state.workerContactsContactGroupDropDownValues;

      if (action.payload.data && action.payload.data["contact_group_names"]) {
        let contactGroupNames = action.payload.data["contact_group_names"];
        workerContactsContactGroupNames = {};

        for (let i = 0; i < contactGroupNames.length; i++) {
          workerContactsContactGroupNames[contactGroupNames[i]["id"] + ""] =
            contactGroupNames[i]["name"];
          workerContactsContactGroupDropDownValues.push({
            key: contactGroupNames[i]["id"],
            label: contactGroupNames[i]["name"],
          });
        }
      }

      if (action.payload.data && action.payload.data.data) {
        let content = action.payload.data;

        if (typeof content.start !== "undefined" && content.start === 0) {
          workerContactsDataTotalRecords = content.total;

          workerContactsData = new Array(workerContactsDataTotalRecords).fill(
            {}
          );

          let totalPages = Math.ceil(
            workerContactsDataTotalRecords / content.pagination_count
          );

          workerContactsDataPages = {};
          workerContactsDataIdList = {};

          for (let i = 1; i <= totalPages; i++) {
            workerContactsDataPages[i] = {
              dataStatus: PAGINATION_DATA_STATUS.NO_DATA,
              data: [],
            };
            workerContactsDataIdList[i] = [];
          }
        }

        let currentIndex = content.page - 1;
        workerContactsDataPages[currentIndex] = {
          dataStatus: PAGINATION_DATA_STATUS.COMPLETED,
          data: content.data,
        };

        let parsedData = parseWorkerContactPaginatedList(
          content.data,
          workerContactsContactGroupNames
        );

        for (let i = 0; i < parsedData.length; i++) {
          if (content.start + i < workerContactsData.length) {
            workerContactsData[content.start + i] = parsedData[i];

            if (typeof workerContactsDataIdList[currentIndex] == "undefined") {
              workerContactsDataIdList[currentIndex] = [];
            }
            workerContactsDataIdList[currentIndex].push(parsedData[i].id);
          }
        }
      }

      workerContactsData = JSON.parse(JSON.stringify(workerContactsData));

      return {
        ...state,
        getWorkerContactListStatus: HTTP_REQUEST_STATUS.SUCCESS,
        workerContactsData: workerContactsData,
        workerContactsDataPages: workerContactsDataPages,
        workerContactsDataIdList: workerContactsDataIdList,
        workerContactsDataTotalRecords: workerContactsDataTotalRecords,
        workerContactsContactGroupNames: workerContactsContactGroupNames,
        workerContactsContactGroupDropDownValues: workerContactsContactGroupDropDownValues,
      };
    }

    case WC_GET_PAGINATED_LIST_ERROR: {
      return {
        ...state,
        getWorkerContactListStatus: HTTP_REQUEST_STATUS.FAIL,
        workerContactsData: [],
      };
    }

    case WC_GET_PAGINATED_LIST_NONE: {
      return {
        ...state,
        getWorkerContactListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
      };
    }

    /** wc : upload excel **/
    case WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL: {
      return {
        ...state,
        uploadContactsExcelStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    /** wc : upload excel : success **/
    case WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      return {
        ...state,
        uploadContactsExcelStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    /** wc : upload excel : error **/
    case WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        uploadContactsExcelStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    /** wc : upload excel : none **/
    case WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE: {
      return {
        ...state,
        uploadContactsExcelStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
      };
    }

    case WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST: {
      return {
        ...state,
        getImportListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        importListData: [],
        alertMessage: "",
      };
    }

    case WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS: {
      let data = action.payload.data ? action.payload.data : [];
      let alertMessage = action.payload.message ? action.payload.message : "";

      data = parseImportListData(data);

      return {
        ...state,
        getImportListStatus: HTTP_REQUEST_STATUS.SUCCESS,
        importListData: data,
        alertMessage: alertMessage,
      };
    }

    case WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }

      return {
        ...state,
        getImportListStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    case WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE: {
      return {
        ...state,
        getImportListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    /** wc template download **/
    case WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE: {
      return {
        ...state,
        downloadWCTemplateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    /** wc template download : success **/
    case WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      if (action.payload.data && action.payload.data.path) {
        AppUtils.downloadFile(baseServerUrl + action.payload.data.path);
      }

      return {
        ...state,
        downloadWCTemplateStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    /** wc template download : fail **/
    case WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }
      return {
        ...state,
        downloadWCTemplateStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    /** wc template download : none **/
    case WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE: {
      return {
        ...state,
        downloadWCTemplateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    /** wc contact attachment download **/
    case WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD: {
      return {
        ...state,
        downloadWCAttachmentStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
        alertMessage: "",
      };
    }

    /** wc contact attachment download : success **/
    case WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS: {
      let alertMessage = action.payload.message ? action.payload.message : "";

      if (action.payload.data && action.payload.data.path) {
        AppUtils.downloadFile(baseServerUrl + action.payload.data.path);
      }

      return {
        ...state,
        downloadWCAttachmentStatus: HTTP_REQUEST_STATUS.SUCCESS,
        alertMessage: alertMessage,
      };
    }

    /** wc contact attachment download : fail **/
    case WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR: {
      let alertMessage = "";

      if (action.payload.error) {
        alertMessage = action.payload.error;
      }
      return {
        ...state,
        downloadWCAttachmentStatus: HTTP_REQUEST_STATUS.FAIL,
        alertMessage: alertMessage,
      };
    }

    /** wc contact attachment download : none **/
    case WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE: {
      return {
        ...state,
        downloadWCAttachmentStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        alertMessage: "",
      };
    }

    default:
      return { ...state };
  }
};

function updateWCListFilterTranslations(data) {
  let result = data;

  try {
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i]["items"]) {
        for (let j = 0; j < data.items[i]["items"].length; j++) {
          data.items[i]["items"][j]["label"] = AppUtils.getTranslationFromKey(
            data.items[i]["items"][j]["labelKey"]
          );
        }
      }
    }
  } catch (ex) {
    console.error("updateWCListFilterTranslations : error : ", ex);
  }

  return result;
}

function parseImportListData(data) {
  let result = data;

  try {
    for (let i = 0; i < result.length; i++) {
      if (
        typeof result[i]["status"] != "undefined" &&
        typeof WC_IMPORT_STATUS_STRING_KEYS[result[i]["status"]] != "undefined"
      ) {
        result[i]["status_label"] = (
          <IntlMessages
            id={WC_IMPORT_STATUS_STRING_KEYS[result[i]["status"]]}
          />
        );
      }

      if (typeof result[i]["created_date"] != "undefined") {
        result[i][
          "created_date_label"
        ] = AppUtils.getDateLabelFromDateDDMMYYYYAMPMWithTimeZone(
          result[i]["created_date"],
          DEFAULT_DATE_DELIMITER,
          true
        );
      }

      result[i]["opt_start_date_label"] = "-";

      if (typeof result[i]["opt_start_date"] != "undefined") {
        result[i][
          "opt_start_date_label"
        ] = AppUtils.getDateLabelFromDateDDMMYYYYAMPMWithTimeZone(
          result[i]["opt_start_date"],
          DEFAULT_DATE_DELIMITER
        );
      }

      result[i]["opt_end_date_label"] = "-";
      if (typeof result[i]["opt_end_date"] != "undefined") {
        result[i][
          "opt_end_date_label"
        ] = AppUtils.getDateLabelFromDateDDMMYYYYAMPMWithTimeZone(
          result[i]["opt_end_date"],
          DEFAULT_DATE_DELIMITER
        );
      }

      result[i]["logs_label"] = (
        <span className="wc_import_table_view_log">
          <IntlMessages id="DIC_KEY_WC_IMPORT_TBL_VIEW_LOG" />
        </span>
      );
    }
  } catch (ex) {
    console.error("parseImportListData : error : ", data);
  }

  return result;
}

function parseWorkerContactPaginatedList(
  data,
  workerContactsContactGroupNames
) {
  let result = data;
  try {
    for (let i = 0; i < result.length; i++) {
      result[i]["name"] = "-";
      result[i]["phone_number_label"] = "-";

      if (
        result[i]["first_name"] &&
        typeof result[i]["first_name"] != "undefined" &&
        result[i]["first_name"] != null &&
        result[i]["first_name"] !== "nan"
      ) {
        result[i]["name"] = result[i]["first_name"];
      }

      if (
        result[i]["phone_number"] &&
        typeof result[i]["phone_number"] != "undefined" &&
        result[i]["phone_number"] != null &&
        result[i]["phone_number"] !== "nan"
      ) {
        result[i]["phone_number_label"] = result[i]["phone_number"];
      }

      if (result[i]["full_name"] && result[i]["full_name"] !== "") {
        result[i]["name"] = result[i]["first_name"];
      }

      result[i]["status"] = AppUtils.getTranslationFromKey(
        result[i]["is_active"]
          ? "DIC_KEY_WC_TABLE_STATUS_ACTIVE"
          : "DIC_KEY_WC_TABLE_STATUS_INACTIVE"
      );

      if (typeof result[i]["modified_date"] != "undefined") {
        result[i][
          "modified_date_label"
        ] = AppUtils.getDateLabelFromDateDDMMYYYYWithTimeZone(
          result[i]["modified_date"],
          DEFAULT_DATE_DELIMITER
        );
      }

      if (
        typeof result[i]["employee_id"] === "undefined" ||
        result[i]["employee_id"] === null ||
        result[i]["employee_id"] === ""
      ) {
        result[i]["employee_id"] = "-";
      }

      result[i]["groups_label"] = "";
      result[i]["groups_label_tooltip"] = "";

      if (
        typeof result[i]["contact_groups_ids"] !== "undefined" &&
        result[i]["contact_groups_ids"] !== null &&
        result[i]["contact_groups_ids"].length > 0
      ) {
        let groupsLabel = "";

        for (let j = 0; j < result[i]["contact_groups_ids"].length; j++) {
          if (
            typeof workerContactsContactGroupNames[
              result[i]["contact_groups_ids"][j]
            ] != "undefined"
          ) {
            if (groupsLabel !== "") {
              groupsLabel += ", ";
            }

            groupsLabel +=
              workerContactsContactGroupNames[
                result[i]["contact_groups_ids"][j]
              ];
          }
        }

        result[i]["groups_label_tooltip"] = groupsLabel;

        if (
          groupsLabel.length >
          WORKER_CONTACTS_CONFIG["LIST_CONTACT_GROUP_COLUMN_LENGTH"]
        ) {
          groupsLabel =
            groupsLabel.substring(
              0,
              WORKER_CONTACTS_CONFIG["LIST_CONTACT_GROUP_COLUMN_LENGTH"]
            ) + " ...";
        }

        groupsLabel += " (" + result[i]["contact_groups_ids"].length + ") ";
        result[i]["groups_label"] = groupsLabel;
      }
    }
  } catch (ex) {
    console.error("parseWorkerContactPaginatedList : error : ", ex);
  }

  return result;
}

function parseWorkerContactGroupsList(data) {
  let result = data;

  try {
    for (let i = 0; i < result.length; i++) {
      result[i]["status"] = AppUtils.getTranslationFromKey(
        result[i]["is_active"]
          ? "DIC_KEY_WC_GROUPS_LIST_FILTER_VIEW_STATUS_ACTIVE"
          : "DIC_KEY_WC_GROUPS_LIST_FILTER_VIEW_STATUS_INACTIVE"
      );

      result[i]["modified_date_label"] = "-";

      if (
        typeof result[i]["modified_date"] != "undefined" &&
        result[i]["modified_date"] != null
      ) {
        result[i][
          "modified_date_label"
        ] = AppUtils.getDateLabelFromDateDDMMYYYYWithTimeZone(
          result[i]["modified_date"],
          DEFAULT_DATE_DELIMITER
        );
      }
    }
  } catch (ex) {
    console.error("parseWorkerContactGroupsList : error : ", data);
  }

  return result;
}

function generateWCListFilterCustomDemographics(data) {
  return data.map((cf) => {
    return {
      id: "CUSTOM_DEMOGRAPHIC_MY_CUSTOMS_" + cf.id,
      key: "CUSTOM_DEMOGRAPHIC_MY_CUSTOMS_" + cf.id,
      placeHolder: cf.name,
      label: cf.name,
      required: cf.required,
      type:
        cf.field_type === "MULTIPLE_SELECTION" ||
        cf.field_type === "SINGLE_SELECTION"
          ? UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE
          : UIUtils.UI_COMPONENT_TYPES.INPUT_NO_TITLE,
      defaultValue: "",
      variant: "outlined",
      items: (cf.custom_field_values || []).map((cfitm) => ({
        key: "CUSTOM_DEMOGRAPHIC_MY_CUSTOMS_" + cf.id,
        id: cfitm.id,
        label: cfitm.name,
      })),
    };
  });
}

let fieldTypes = {
  MULTIPLE_SELECTION: "multiselect_react_chips_dropdown",
  SINGLE_SELECTION: "outline_dropdown",
  EMAIL: "textfield",
  GENERAL: "textfield",
  DATE: "datefield",
  PHONE: "phone_number",
};

function parseClientCustomFieldValues(data) {
  try {
    data = data.map((field) => {
      let styles = { color: "#9DA3A3" };
      if (field.field_type === "MULTIPLE_SELECTION") {
        styles["margin-bottom"] = "1rem";
      }
      return {
        id: field.id,
        key: "custom-field-" + field.id,
        localisation: field.name,
        type: fieldTypes[field.field_type],
        fieldType: field.field_type,
        style: styles,
        required: field.required,
        custom_field_values: field.custom_field_values.map((field) => {
          return {
            id: field.id,
            key: field.id,
            name: field.name,
            label: field.name,
            value: field.id,
            headerValue: field.name,
          };
        }),
      };
    });
  } catch (ex) {
    console.error("parseClientCustomFieldValues : error : ", ex);
  }

  return data;
}

export default ReducerWorkerContacts;
