import {HTTP_REQUEST_STATUS} from "../../constants/constants";
import {
    ADMIN_CONTROLS_GET_SURVEY_DETAILS,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS, ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID, ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR, ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE, ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS,
    ADMIN_CONTROLS_SET_SURVEY_STATUS,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE, ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE,
    ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE,
    ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS
} from "../../constants/actions/adminControls/survey/actionAdminControls";
import {baseServerUrl} from "../../util/keys";
import {UUID_STATUS} from "../../constants/config";
import AppUtils from "../../util/util";
import {
    WC_SYNC_MS_SQL_WORKER_CONTACTS,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS
} from "../../constants/actions/wc/actionTypesWorkerContacts";

let filterConfig = [
    {
        'key': 'survey_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_ACTIVATE_SURVEY_ID",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    }
];

let configIncreaseSurvey = [
    {
        'key': 'survey_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_SURVEY_ID",
        'isMandatory': true,
        'containerClass': 'col-md-6 col-sm-6 col-6 m-0 mr-4 pl-0 pr-4'
    },
    {
        'key': 'client_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_CLIENT_ID",
        'isMandatory': true,
        'containerClass': 'col-md-6 col-sm-6 col-6 m-0 mr-4 pl-0 pr-4'
    },
    {
        'key': 'count',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_COUNT",
        'isMandatory': true,
        'containerClass': 'col-md-6 col-sm-6 col-6 m-0 mr-4 pl-0 pr-4'
    }
];

let configResetSurveyCache = [
    {
        'key': 'survey_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_RESET_CACHE_SURVEY_ID",
        'isMandatory': false,
        'containerClass': 'col-md-6 col-sm-6 col-6 m-0 mr-4 pl-0 pr-4'
    },
];

let configSurveyResponseSaveByRequestId = [
    {
        'key': 'request_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSE_SAVE_REQUEST_ID",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    },
];

let configWCOperations = [
    {
        'key': 'request_id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSE_SAVE_REQUEST_ID",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    },
];

let duplicateSurveyResponsesFilterConfig = [
    {
        'key': 'id',
        'type': 'textfield',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_ADMIN_DUPLICATE_RESPONSE_ID",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    },
    {
        'key': 'is_questionnaire',
        'type': 'checkbox',
        'localisation':
            "DIC_KEY_ADMIN_DUPLICATE_RESPONSE_IS_QUESTIONNAIRE"
    }
];

let surveyResponsesRawDataConfigFields = [
    {
        'key': 'start_date',
        'type': 'datefield',
        'style': {},
        'labelClass': "pb-3",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RAW_DATA_START_DATE",
        'isMandatory': true,
        'containerClass': 'col-md-6 col-sm-12 col-12'
    },
    {
        'key': 'end_date',
        'type': 'datefield',
        'style': {},
        'labelClass': "pb-3",
        'localisation': "DIC_KEY_ADMIN_SURVEY_RAW_DATA_END_DATE",
        'isMandatory': true,
        'containerClass': 'col-md-6 col-sm-12 col-12'
    },
    {
        'key': 'is_anonymous_users',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RAW_DATA_IS_ANONYMOUS"
    },
    {
        'key': 'is_wovo_users',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RAW_DATA_IS_USERS"
    }
];

let surveyResponsesUpdateDemoGraphicDetails = [
    {
        'key': 'is_gender',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_GENDER"
    },
    {
        'key': 'is_age',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_AGE"
    },
    {
        'key': 'is_tenure',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_TENURE"
    },
    {
        'key': 'is_identifier',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_IDENTIFIER"
    },
    {
        'key': 'is_dept_id',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_DEPT_ID"
    },
    {
        'key': 'is_dept_name',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_DEPT_NAME"
    },
    {
        'key': 'is_smart_phone',
        'type': 'checkbox',
        'localisation': "DIC_KEY_ADMIN_SURVEY_RESPONSES_DEMO_GRAPHIC_DETAILS_UPDATE_IS_SMART_PHONE"
    }
];


const initialData = {
    filterConfig: filterConfig,
    configIncreaseSurvey: configIncreaseSurvey,
    configResetSurveyCache: configResetSurveyCache,
    configSurveyResponseSaveByRequestId: configSurveyResponseSaveByRequestId,
    duplicateSurveyResponsesFilterConfig: duplicateSurveyResponsesFilterConfig,
    surveyResponsesRawDataConfigFields: surveyResponsesRawDataConfigFields,
    surveyResponsesUpdateDemoGraphicDetails: surveyResponsesUpdateDemoGraphicDetails,
    configWCOperations: configWCOperations,
    
    loaderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessage: "",
    surveyDetails: {},
    surveyData: [],
    saveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    duplicateSurveysList: [],
    duplicateSurveysListFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyRawDataStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyRawDataUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyRawDataStatusUUID: "",
    surveyRawDataStatusCounter: 0,
    
    customFieldDataUCMS: [],
    customFieldDataWOVO: [],
    
    
    surveyResponsesUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    wcSyncWorkerContactsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    wcGetUCMSCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.NOT_STARTED,
    wcAdminDownloadContactsDiff: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    
};


const ReducerAdminControls = (state = initialData, action) => {
    
    
    switch (action.type) {
        
        case ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE: {
            return {
                ...state,
                wcAdminDownloadContactsDiff: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_SUCCESS: {
            
            if (action.payload && action.payload.data && action.payload.data.file) {
                AppUtils.downloadFile(action.payload.data.file);
            }
            
            return {
                ...state,
                wcAdminDownloadContactsDiff: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_ERROR: {
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE_NONE: {
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS: {
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_SUCCESS: {
            let alertMessage = "";
            
            if (typeof action.payload.message != "undefined") {
                alertMessage = action.payload.message;
            }
            
            let customFieldDataUCMS = [];
            let customFieldDataWOVO = [];
            
            if (typeof action.payload.data.wovo != "undefined") {
                customFieldDataWOVO = action.payload.data.wovo;
            } else if (typeof action.payload.data.ucms != "undefined") {
                customFieldDataUCMS = action.payload.data.ucms;
            }
            
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage,
                customFieldDataWOVO: customFieldDataWOVO,
                customFieldDataUCMS: customFieldDataUCMS,
            }
        }
        
        case ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_ERROR: {
            
            let alertMessage = "";
            
            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }
            
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }
        
        case ADMIN_CONTROLS_WC_SYNC_CLIENT_UCMS_CUSTOM_FIELDS_NONE: {
            return {
                ...state,
                wcSyncUCMSCustomFields: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS: {
            
            return {
                ...state,
                wcGetUCMSCustomFieldsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                customFieldDataUCMS: [],
                customFieldDataWOVO: []
            }
        }
        
        case ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_SUCCESS: {
            
            let customFieldDataUCMS = [];
            let customFieldDataWOVO = [];
            
            if (typeof action.payload.data.wovo != "undefined") {
                customFieldDataWOVO = action.payload.data.wovo;
            } else if (typeof action.payload.data.ucms != "undefined") {
                customFieldDataUCMS = action.payload.data.ucms;
            }
            
            return {
                ...state,
                wcGetUCMSCustomFieldsStatus: HTTP_REQUEST_STATUS.SUCCESS,
                customFieldDataWOVO: customFieldDataWOVO,
                customFieldDataUCMS: customFieldDataUCMS,
                alertMessage: action.payload.message
                
            }
        }
        
        case ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_ERROR: {
            
            let alertMessage = "";
            
            if (typeof action.payload.message != "undefined") {
                alertMessage = action.payload.message;
            }
            
            return {
                ...state,
                wcGetUCMSCustomFieldsStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }
        
        case ADMIN_CONTROLS_WC_GET_CLIENT_UCMS_CUSTOM_FIELD_STATUS_NONE: {
            
            return {
                ...state,
                wcGetUCMSCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        
        case WC_SYNC_MS_SQL_WORKER_CONTACTS: {
            return {
                ...state,
                wcSyncWorkerContactsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS: {
            let alertMessage = action.payload.message ? action.payload.message : "";
            return {
                ...state,
                wcSyncWorkerContactsStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }
        
        case WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR: {
            let alertMessage = "";
            
            if (action.payload.error) {
                alertMessage = action.payload.error;
            }
            
            return {
                ...state,
                wcSyncWorkerContactsStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }
        
        case WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE: {
            return {
                ...state,
                wcSyncWorkerContactsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS: {
            
            return {
                ...state,
                surveyResponsesUpdateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS: {
            
            return {
                ...state,
                surveyResponsesUpdateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message + " ( " + action.payload.data.data.count + " ) "
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR: {
            
            return {
                ...state,
                surveyResponsesUpdateStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.message
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE: {
            
            return {
                ...state,
                surveyResponsesUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        case ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID:
        case ADMIN_CONTROLS_SURVEY_RESPONSES_RESET:
        case ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT: {
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS:
        case ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS:
        case ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS: {
            
            let alertMessage = "";
            
            if (typeof action.payload.data != "undefined") {
                alertMessage = action.payload.data.message;
            }
            
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }
        
        case ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR:
        case ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR:
        case ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR: {
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.message
            }
        }
        
        case ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE:
        case ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE:
        case ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE: {
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE: {
            
            return {
                ...state
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR: {
            
            return {
                ...state
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS: {
            
            let data = action.payload.data;
            let surveyRawDataStatusCounter = state.surveyRawDataStatusCounter;
            let surveyRawDataStatus = state.surveyRawDataStatus;
            let surveyRawDataUUIDStatus = state.surveyRawDataUUIDStatus;
            let surveyRawDataStatusUUID = state.surveyRawDataStatusUUID;
            
            if (data.status === UUID_STATUS["IN_PROGRESS"]) {
                surveyRawDataStatusCounter += 1;
                surveyRawDataStatus = HTTP_REQUEST_STATUS.IN_PROGRESS;
            } else if (data.status === UUID_STATUS["SUCCESS"]) {
                let downloadURL = baseServerUrl + data.data.data
                surveyRawDataStatusCounter = 0;
                surveyRawDataStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                surveyRawDataStatusUUID = "";
                AppUtils.downloadFile(downloadURL);
                
            } else if (data.status === UUID_STATUS["SUCCESS"]) {
                surveyRawDataStatusCounter = 0;
                surveyRawDataStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                surveyRawDataUUIDStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                surveyRawDataStatusUUID = "";
            }
            
            return {
                ...state,
                surveyRawDataStatusCounter: surveyRawDataStatusCounter,
                surveyRawDataStatus: surveyRawDataStatus,
                surveyRawDataUUIDStatus: surveyRawDataUUIDStatus,
                surveyRawDataStatusUUID: surveyRawDataStatusUUID
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS: {
            
            return {
                ...state
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE: {
            return {
                ...state,
                surveyRawDataStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyRawDataStatusUUID: "",
                surveyRawDataStatusCounter: 0
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR: {
            return {
                ...state,
                surveyRawDataStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyRawDataStatusUUID: "",
                surveyRawDataStatusCounter: 0
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS: {
            
            let data = action.payload.data;
            let surveyRawDataStatusUUID = state.surveyRawDataStatusUUID;
            let surveyRawDataStatusCounter = state.surveyRawDataStatusCounter;
            let surveyRawDataStatus = state.surveyRawDataStatus;
            
            if (data.status === UUID_STATUS["IN_PROGRESS"]) {
                surveyRawDataStatusUUID = data["uuid"];
                surveyRawDataStatusCounter += 1;
            }
            
            return {
                ...state,
                surveyRawDataStatusUUID: surveyRawDataStatusUUID,
                surveyRawDataStatusCounter: surveyRawDataStatusCounter,
                surveyRawDataStatus: surveyRawDataStatus
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD: {
            return {
                ...state,
                surveyRawDataStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyRawDataStatusUUID: "",
                surveyRawDataStatusCounter: 0
            }
        }
        
        case ADMIN_CONTROLS_SET_SURVEY_STATUS: {
            
            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS: {
            
            let alertMessage = "";
            
            if (typeof action.payload.data != "undefined") {
                alertMessage = action.payload.data.message;
            }
            
            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }
        
        case ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR: {
            
            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.message
            }
        }
        
        case ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE: {
            
            return {
                ...state,
                saveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        
        case ADMIN_CONTROLS_GET_SURVEY_DETAILS: {
            
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                surveyDetails: {},
                surveyData: []
            }
        }
        
        case ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS: {
            
            let data = [];
            let alertMessage = "";
            
            if (typeof action.payload.data != "undefined") {
                
                if (typeof action.payload.data.data != "undefined") {
                    data = action.payload.data.data;
                }
                
                alertMessage = action.payload.data.message;
            }
            
            let surveyData = parseSurveyDetails(data);
            
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage,
                surveyDetails: data,
                surveyData: surveyData
            }
        }
        
        case ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR: {
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.message,
                surveyDetails: {},
                surveyData: []
            }
        }
        
        case ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE: {
            return {
                ...state,
                loaderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES: {
            return {
                ...state,
                duplicateSurveysList: [],
                duplicateSurveysListFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        case ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS: {
            
            return {
                ...state,
                duplicateSurveysList: action.payload.data.data,
                duplicateSurveysListFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        case ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR: {
            return {
                ...state,
                duplicateSurveysList: [],
                duplicateSurveysListFetchStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }
        case ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE: {
            return {
                ...state,
                duplicateSurveysListFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        default:
            return state;
    }
    
};


function parseSurveyDetails(data) {
    let result = [];
    
    try {
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_ID',
            'value': data.id
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_NAME',
            'value': data.name
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_CLIENT',
            'value': data.client
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_START_DATE',
            'value': data.start_date
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_END_DATE',
            'value': data.end_date
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_STATUS',
            'value': data.status
        });
        
        let modifiedData = data['last_modified']['name'];
        
        if (modifiedData !== "") {
            modifiedData += "( " + data['last_modified']['user_name'] + " )";
        }
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_MODIFIED_BY',
            'value': modifiedData
        });
        
        result.push({
            'key': 'DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_MODIFIED_ON',
            'value': data['last_modified']['date']
        });
        
    } catch (ex) {
        console.error("parseSurveyDetails : error : ", ex);
    }
    
    return result;
}


export default ReducerAdminControls;
