
import {
    TRANSLATION_STRINGS_DOWNLOAD,
    TRANSLATION_STRINGS_DOWNLOAD_ERROR,
    TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS,
    TRANSLATION_STRINGS_DOWNLOAD_NONE,
    TRANSLATION_STRINGS_DOWNLOAD_SUCCESS,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_ERROR,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_IN_PROGRESS,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_NONE,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED,

    TRANSLATION_STRINGS_UPLOAD,
    TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR,
    TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS,
    TRANSLATION_STRINGS_UPLOAD_SAVE_NONE,
    TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS
} from "../../../../constants/actions/adminSettings/translations/strings/actionTranslationStrings";


/****
 * To get translations list file
 ****/
export function getTranslationsList( ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST
    };
}

/****
 * To get translations with translated strings list file
 ****/
export function getTranslationStringList( payload ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED,
        payload: payload
    };
}


/****
 * To get translations list file : success
 ****/
export function getTranslationsListSuccess( params ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS,
        payload : params
    };
}

/****
 * To get translations list file : ERROR
 ****/
export function getTranslationsListError( params ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST_ERROR,
        payload : params
    };
}

/****
 * To get translations list file : in progress
 ****/
export function getTranslationsListInProgress( ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST_IN_PROGRESS
    };
}

/****
 * To get translations list file : none
 ****/
export function getTranslationsListNone( ) {
    return {
        type: TRANSLATION_STRINGS_TRANSLATIONS_LIST_NONE
    };
}


/****
 * To upload translations json file
 ****/
export function uploadTranslationJSONFile( params ) {
    return {
        type: TRANSLATION_STRINGS_UPLOAD,
        payload : params
    };
}

/****
 * To upload translations json file : success
 ****/
export function uploadTranslationJSONFileSaveSuccess( params ) {
    return {
        type: TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS,
        payload : params
    };
}

/****
 * To upload translations json file : error
 ****/
export function uploadTranslationJSONFileSaveError( params ) {
    return {
        type: TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR,
        payload : params
    };
}

/****
 * To upload translations json file : in progress
 ****/
export function uploadTranslationJSONFileSaveInProgress( params ) {
    return {
        type: TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS,
        payload : params
    };
}

/****
 * To upload translations json file : none
 ****/
export function uploadTranslationJSONFileSaveNone( params ) {
    return {
        type: TRANSLATION_STRINGS_UPLOAD_SAVE_NONE,
        payload : params
    };
}

/****
 * To download translations json file
 ****/
export function downloadTranslationJSONFile( params ) {
    return {
        type: TRANSLATION_STRINGS_DOWNLOAD,
        payload : params
    };
}

/****
 * To download translations json file : success
 ****/
export function downloadTranslationJSONFileSuccess( params ) {
    return {
        type: TRANSLATION_STRINGS_DOWNLOAD_SUCCESS,
        payload : params
    };
}

/****
 * To download translations json file : error
 ****/
export function downloadTranslationJSONFileError( params ) {
    return {
        type: TRANSLATION_STRINGS_DOWNLOAD_ERROR,
        payload : params
    };
}

/****
 * To download translations json file : in progress
 ****/
export function downloadTranslationJSONFileInProgress( params ) {
    return {
        type: TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS,
        payload : params
    };
}

/****
 * To download translations json file : none
 ****/
export function downloadTranslationJSONFileNone( params ) {
    return {
        type: TRANSLATION_STRINGS_DOWNLOAD_NONE,
        payload : params
    };
}