import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../util/keys";

import {
    CLIENT_GET_ALL_CLIENTS
} from "../../constants/ActionTypes";
import {
    getClientsListSuccess,
    showErrorMessage,

} from "../../actions";
import {
    HTTP_TIMEOUT,
    URL_CLIENT_ALL, URL_SURVEY_QUESTION_TYPE_LIST,
} from "../../constants/Urls";
import AppUtils from "../../util/util";


/************ methods : http *******************/

const httpGetAllClients = async (data) =>

    await axios.get(baseUrl + URL_CLIENT_ALL, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/************ methods : http response handler *******************/

function* getAllClientsData() {
    try {
        let dataValue = yield call(httpGetAllClients, {});
        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getClientsListSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/************ methods : export access *******************/

export function* getAllClients() {
    yield takeEvery(CLIENT_GET_ALL_CLIENTS, getAllClientsData);
}

export default function* rootSaga() {
    yield all([
        fork(getAllClients),
    ]);
}
