import {
    CLIENT_SELECTED_CLIENT,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS, SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS,
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS,
    SURVEY_NEW_TEMPLATE_HIDE_LOADER, SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_NONE,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS,
    SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE,
    SURVEY_NEW_TEMPLATE_SET_TEMPLATE,
    SURVEY_NEW_TEMPLATE_SHOW_LOADER,
    SURVEY_TEMPLATE_GET_QUESTIONS_ERROR,
    SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUESTIONS_NONE,
    SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS,
    SURVEY_TEMPLATE_SET_STATUS_ERROR,
    SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS,
    SURVEY_TEMPLATE_SET_STATUS_NONE,
    SURVEY_TEMPLATE_SET_STATUS_SUCCESS,
    SURVEY_TEMPLATE_SET_STEP
} from "../../../constants/ActionTypes";

import {SURVEY_NEW_SURVEY_SET_STEP} from "../../../constants/actions/survey/survey/actionTypesSurvey";

import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import AppUtils from "../../../util/util";
import {
    SURVEY_STATUSES_VALUE, 
    SURVEY_STEPPER_STEPS,
    SURVEY_INFO_CARD_ITEM_VALUES
} from "../../../constants/config";

import IntlMessages from "../../../util/IntlMessages";
import React from "react";
import {
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS
} from "../../../constants/actions/survey/questions/actionTypesSurveyQuestions";

import {
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS
} from "../../../constants/actions/survey/clone/actionTypesSurveyCloneHandler";
import {WOVO_USER_ROLES} from "../../../constants/userRoles";
import {
    SURVEY_TEMPLATE_GET_NEXT_LIST,
    SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR,
    SURVEY_TEMPLATE_GET_NEXT_LIST_NONE,
    SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS, SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";


let sourceTypes = {
    "SCRATCH": "SCRATCH",
    "TEMPLATE": "TEMPLATE"
};


let templateSources = [
    {
        type: sourceTypes['SCRATCH'],
        icon: "file-plus",
        titleId: "DIC_SURVEY_NEW_TEMPLATE_SOURCE_SCRATCH",
        subTitleId: "DIC_SURVEY_NEW_TEMPLATE_SOURCE_SCRATCH_INFO",
        color: "#f44336",
        backgroundClass: "jr-blue-cyan-gradient-reverse hand",
        titleColor: "text-white",
        infoColor: "text-white",
        containerStyle: {
            paddingTop: "5%",
            paddingBottom: "5%"
        }
    },
    {
        type: sourceTypes['TEMPLATE'],
        icon: "folder-star-alt",
        titleId: "DIC_SURVEY_NEW_TEMPLATE_SOURCE_TEMPLATE",
        subTitleId: "DIC_SURVEY_NEW_TEMPLATE_SOURCE_TEMPLATE_INFO",
        color: "#ff9800",
        backgroundClass: "jr-pink-purple-gradient-reverse hand",
        titleColor: "text-white",
        infoColor: "text-white",
    }
];

let stepsFromScratch = [
    {
        type : SURVEY_STEPPER_STEPS.INFO,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_TEMPLATE_DETAILS",
        value: 0
    },
    {
        type : SURVEY_STEPPER_STEPS.QUESTIONS,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_QUESTIONS",
        value: 1
    },
    {
        type : SURVEY_STEPPER_STEPS.FINAL,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_FINALIZE",
        value: 2
    }
];

let stepsFromTemplate = [
    {
        type : SURVEY_STEPPER_STEPS.IMPORT,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_TEMPLATE_SOURCE",
        value: 0
    },
    {
        type : SURVEY_STEPPER_STEPS.INFO,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_TEMPLATE_DETAILS",
        value: 1
    },
    {
        type : SURVEY_STEPPER_STEPS.QUESTIONS,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_QUESTIONS",
        value: 2
    },
    {
        type : SURVEY_STEPPER_STEPS.FINAL,
        name: "DIC_SURVEY_NEW_TEMPLATE_STEP_FINALIZE",
        value: 3
    }
];

let templateFilters = [
    {
        key: 'survey_titles',
        type: "textfield",
        isMandatory: false,
        localisation: "DIC_SURVEY_TEMPLATE_LIST_FILTER_TITLE"
    },
    {
        key: 'supported_languages',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_TEMPLATE_LIST_FILTER_SUPPORTED_LANGUAGES"
    },
    {
        key: 'clients',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_TEMPLATE_LIST_FILTER_CLIENTS"
    },
    /*{
        key: 'categories',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_TEMPLATE_LIST_FILTER_SUPPORTED_CATEGORIES"
    },
    {
        key: 'survey_status',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_TEMPLATE_LIST_FILTER_STATUS"
    }*/
    {
        key: 'client_country',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_COUNTRY",
        isBrand : true
    }
];


let templateInfoConfigLanguageSelection = [
    [
        {
            'key': 'supported_languages',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_SUPPORTED_LANGUAGES",
            'isMandatory': true,
            'containerClass': 'col-md-6 col-sm-12 col-12',
            'items': []
        }
    ]
];


let templateInfoConfig = [
    [
        /*{
            'key': 'categories',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_CATEGORIES",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        },*/
        {
            'key': 'reportingCategories',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_SURVEY_REPORTING_CATEGORIES",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        },
        {
            'key': 'clients',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_CLIENTS",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        }
    ],
    [
        {
            'key': 'start_date',
            'type': 'datefield',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_START_DATE",
            'isMandatory': true,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        },
        {
            'key': 'end_date',
            'type': 'datefield',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_END_DATE",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12',
            'subLabel' :{
                'localisation': "DIC_SURVEY_NEW_SURVEY_END_DATE_CLEAR",
                'key' : 'end_date_clear',
                'className' : ' surveyEndDateClear '
            }
        }
    ],
    /*[

        {
            'key': 'is_locked',
            'type': 'switch',
            'style': {marginTop: "8%"},
            'labelClass': "pb-4 mr-4",
            'localisation': "DIC_SURVEY_NEW_TEMPLATE_LOCKED",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        }
    ]*/
];

export const SURVEY_TEMPLATE_VIEW_ACTIONS = {
    VIEW : "VIEW",
    EDIT : "EDIT",
    CLONE : "CLONE",
    DEACTIVATE : "DEACTIVATE",
    ACTIVATE : "ACTIVATE",
    REMOVE : "REMOVE",
    CREATE_SURVEY : "CREATE_SURVEY",
    REPORT : "REPORT"
};



export const SURVEY_TEMPLATE_OPTIONS_MENU = {
    DRAFT : [
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_EDIT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.EDIT
        },
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REMOVE",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REMOVE
        }
    ],
    ACTIVE : [
        /*{
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REPORT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REPORT
        },*/
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_DEACTIVATE",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.DEACTIVATE
        },
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_CREATE_SURVEY",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.CREATE_SURVEY
        },
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_EDIT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.EDIT
        }
    ],
    SCHEDULED : [
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_DEACTIVATE",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.DEACTIVATE
        },
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_EDIT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.EDIT
        }
    ],
    INACTIVE : [
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REPORT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REPORT
        }/*,
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REMOVE",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REMOVE
        }*/
    ],
    COMPLETED : [
        /*{
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REPORT",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REPORT
        }*//*,
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REMOVE",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.REMOVE
        }*/
    ],
    ACTIVE_ASSIGNED_LIST : [
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_CREATE_SURVEY",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.CREATE_SURVEY
        }
    ],
    SCHEDULED_ASSIGNED_LIST : [
        {
            label : "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_VIEW",
            value : SURVEY_TEMPLATE_VIEW_ACTIONS.VIEW
        }
    ]

};

export const SURVEY_TEMPLATE_STEPS = {
    NONE : "NONE",
    VIEW : "VIEW",
    EDIT : "EDIT",
    NEW : "NEW",
};

const initialData = {
    templateFilters : templateFilters,
    currentTemplateStep : SURVEY_TEMPLATE_STEPS.EDIT,
    currentTemplateStepTitle: "" ,
    surveyTemplates: [],
    surveyTemplatesMap: {},
    surveyGroupTemplatesMap: {},
    surveyTemplatesMapCount: 0,
    activeTemplates: [],
    totalTemplates : 0,
    listStartIndex : 0,
    currentPage : 0,
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    templateInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    templateGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    templateGetQuestionsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    templateDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    templateCloneStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    getTemplatePaginatedListStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
    templateSources: templateSources,
    sourceType: "",
    newTemplateStepperSteps: [],
    templateInfoConfig: templateInfoConfig,
    templateInfoConfigLanguageSelection: templateInfoConfigLanguageSelection,
    sourceTypes: sourceTypes,
    currentTemplate: null,
    currentTemplateQuestions: [],
    templateCloneParentId : "",
    isUserHasTemplateCreateAccess : false,
    questionnaireGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
};


const SurveyTemplates = (state = initialData, action) => {

    switch (action.type) {

        case SIGNIN_USER_SUCCESS: {

            let currentUserRole = action.payload.user["role"];
            let isUserHasTemplateCreateAccess = false;

            if ( currentUserRole === WOVO_USER_ROLES.SUPER_ADMIN ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1 ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_TCP ){
                isUserHasTemplateCreateAccess = true;
            }

            return {
                ...state,
                isUserHasTemplateCreateAccess : isUserHasTemplateCreateAccess
            }
        }

        /** survey template clone start : success **/
        case SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS:{

            let currentTemplate = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);

            return {
                ...state,
                templateCloneStatus : HTTP_REQUEST_STATUS.SUCCESS,
                currentTemplate: currentTemplate,
                alertMessage: action.payload.data.message
            }
        }

        /** survey template clone start : error **/
        case SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR:{
            return {
                ...state,
                templateCloneStatus : HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.message
            }
        }

        /** survey template clone start : reset **/
        case SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE:{
            return {
                ...state,
                templateCloneStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: "",
                templateCloneParentId : ""
            }
        }

        /** survey template clone start **/
        case SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE:{

            return {
                ...state,
                templateCloneStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                templateCloneParentId : action.payload.id
            }
        }

        case SIGNOUT_USER_SUCCESS:{

            return {
                ...state,
                templateFilters : templateFilters,
                currentTemplateStep : SURVEY_TEMPLATE_STEPS.EDIT,
                currentTemplateStepTitle: "" ,
                surveyTemplates: [],
                surveyTemplatesMap : {},
                surveyGroupTemplatesMap: {},
                activeTemplates: [],
                loader: false,
                alertMessage: '',
                isSuccessMessage: false,
                isErrorMessage: false,
                templateInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                templateGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                templateGetQuestionsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                templateDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                getTemplatePaginatedListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                templateSources: templateSources,
                sourceType: "",
                newTemplateStepperSteps: [],
                templateInfoConfig: templateInfoConfig,
                templateInfoConfigLanguageSelection: templateInfoConfigLanguageSelection,
                sourceTypes: sourceTypes,
                currentTemplate: null,
                currentTemplateQuestions: []
            }
        }

        case SURVEY_TEMPLATE_SET_STATUS_SUCCESS:{
            let currentTemplate = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);
            return {
                ...state,
                templateDeleteStatus : HTTP_REQUEST_STATUS.SUCCESS,
                currentTemplate : currentTemplate,
                alertMessage: action.payload.data.message,
            }
        }

        case SURVEY_TEMPLATE_SET_STATUS_ERROR:{

            return {
                ...state,
                templateDeleteStatus : HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.message
            }
        }

        case SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS:{
            return {
                ...state,
                templateDeleteStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage : ""
            }
        }

        case SURVEY_TEMPLATE_SET_STATUS_NONE:{
            return {
                ...state,
                templateDeleteStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage : ""
            }
        }

        case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS:
        case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS:{
            let currentTemplate = state.currentTemplate;

            if ( action.payload.data && action.payload.data.template ) {
                currentTemplate = AppUtils.updateSurveyTemplateDetails(action.payload.data.template, state.isUserHasTemplateCreateAccess);
                currentTemplate = JSON.parse(JSON.stringify(currentTemplate))
            }
            else if ( action.payload.data && action.payload.data.parent ) {
                currentTemplate = AppUtils.updateSurveyTemplateDetails(action.payload.data.parent, state.isUserHasTemplateCreateAccess);
                currentTemplate = JSON.parse(JSON.stringify(currentTemplate))
            }

            return {
                ...state,
                currentTemplate: currentTemplate,
                counter : state.counter + 1
            };
        }

        case SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS: {

            let {currentTemplate} = state;

            if ( action.payload.data && action.payload.data.data ){
                currentTemplate = AppUtils.deleteSurveyQuestion(currentTemplate, action.payload.data.data);
            }

            currentTemplate = AppUtils.updateSurveyTemplateDetails(currentTemplate, state.isUserHasTemplateCreateAccess);

            return {
                ...state,
                currentTemplate : currentTemplate,
                counter : state.counter + 1
            };

        }

        case SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS: {

            let {currentTemplate} = state;

            if ( action.payload.data && action.payload.data.data ){
                currentTemplate = action.payload.data.data;
                currentTemplate.questions = AppUtils.getSortedQuestions(currentTemplate.questions);
                currentTemplate = AppUtils.updateCurrentSurvey(currentTemplate);
                currentTemplate = AppUtils.updateSurveyTemplateDetails(currentTemplate, state.isUserHasTemplateCreateAccess);
            }

            return {
                ...state,
                currentTemplate : currentTemplate,
                counter : state.counter + 1
            };

        }

        case SURVEY_NEW_SURVEY_SET_STEP: {

            return {
                ...state,
                currentTemplateStep: action.payload
            };
        }

        /** to set current template vuew step **/
        case SURVEY_TEMPLATE_SET_STEP:{

            let title = "";
            switch (action.payload) {
                case SURVEY_TEMPLATE_STEPS.EDIT:{
                    title = "DIC_SURVEY_TEMPLATES_EDIT_TEMPLATE";
                    break;
                }

                case SURVEY_TEMPLATE_STEPS.VIEW:{
                    title = "DIC_SURVEY_TEMPLATES_VIEW_TEMPLATE";
                    break;
                }

                case SURVEY_TEMPLATE_STEPS.NEW:{
                    title = "DIC_SURVEY_TEMPLATES_NEW_TEMPLATE";
                    break;
                }

                default:
                    break;

            }

            return {
                ...state,
                currentTemplateStep: action.payload,
                currentTemplateStepTitle : title
            };
        }

        case SURVEY_NEW_TEMPLATE_SET_TEMPLATE: {
            return {
                ...state,
                currentTemplate: action.payload
            };
        }

        case SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE:
            return {
                ...state,
                sourceType: action.payload,
                newTemplateStepperSteps: action.payload === sourceTypes['TEMPLATE'] ? stepsFromTemplate : stepsFromScratch
            };

        /**** survey template : get all records : success ****/
        case SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS:
            return {
                ...state,
                templateGetRecordsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                activeTemplates: [],
                totalSurveys: 0,
                listStartIndex: 0,
                currentPage: 0
            };

        /** resetting state while changing the client **/
        case CLIENT_SELECTED_CLIENT:{

            return {
                ...state,
                loader: false,
                surveyTemplates: [],
                surveyTemplatesMap: {},
                surveyGroupTemplatesMap: {},
                templateGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                activeTemplates: [],
                totalSurveys: 0,
                listStartIndex: 0,
                currentPage: 0
            };
        }

        /**** survey template : to get templates list ****/
        case SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS:{

            return {
                ...state,
                loader: true,
                surveyTemplates: [],
                surveyTemplatesMap: {},
                surveyGroupTemplatesMap: {},
                templateGetRecordsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                activeTemplates: [],
                totalTemplates: 0,
                listStartIndex: 0,
                currentPage: 0
            };
        }

        /**** survey template : get all records : success ****/
        case SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS:
            let templates = [];
            let activeTemplates = [];
            let totalTemplates = state.totalTemplates;
            let listStartIndex = state.listStartIndex;
            let currentPage = state.currentPage;
            let surveyTemplatesMap = state.surveyTemplatesMap;
            let surveyGroupTemplatesMap = state.surveyGroupTemplatesMap;
            let surveyTemplatesMapCount = 0;

            if (action.payload.data && action.payload.data.data) {
                let data = action.payload.data.data;

                surveyGroupTemplatesMap = AppUtils.getRedeployedTemplates(surveyGroupTemplatesMap,data.templates, state.isUserHasTemplateCreateAccess);
                surveyTemplatesMapCount = data.templates.length;
                totalTemplates = data["total"];
                listStartIndex = listStartIndex + data["count"];
                currentPage = data["page"];
            }
            
            Object.keys(surveyGroupTemplatesMap).forEach(function (key) {
                templates.push(surveyGroupTemplatesMap[key]);
                if (surveyGroupTemplatesMap[key].status === SURVEY_STATUSES_VALUE.ACTIVE) {
                    activeTemplates.push(surveyGroupTemplatesMap[key]);
                }
                surveyTemplatesMap[surveyGroupTemplatesMap[key]["id"]] = surveyGroupTemplatesMap[key];
            });

            surveyTemplatesMap = generateQuestionnaireLanguageMap(templates);

            return {
                ...state,
                loader: false,
                surveyTemplates: templates,
                surveyTemplatesMapCount: surveyTemplatesMapCount,
                templateGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                activeTemplates: activeTemplates,
                totalTemplates : totalTemplates,
                listStartIndex : listStartIndex,
                currentPage : currentPage,
                surveyTemplatesMap: surveyTemplatesMap,
                surveyGroupTemplatesMap: surveyGroupTemplatesMap
            };

        case SURVEY_TEMPLATE_GET_NEXT_LIST :{
            return {
                ...state,
                getTemplatePaginatedListStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR :{
            return {
                ...state,
                getTemplatePaginatedListStatus : HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS :{
            let surveyTemplates = state.surveyGroupTemplatesMap;
            let activeTemplates = [];
            let totalTemplates = state.totalTemplates;
            let listStartIndex = state.listStartIndex;
            let currentPage = state.currentPage;
            let templates = [];
            let surveyTemplatesMapCount = state.surveyTemplatesMapCount;

            if (totalTemplates !== 0 && action.payload.data && action.payload.data.data) {
                let data = action.payload.data.data;
                totalTemplates = data["total"];
                listStartIndex = listStartIndex + data["count"];
                currentPage = data["page"];

                surveyTemplates = AppUtils.getRedeployedTemplates(surveyTemplates, data.templates, state.isUserHasTemplateCreateAccess );
                
                if (data.templates){
                    surveyTemplatesMapCount += data.templates.length;
                }
            }

            Object.keys(surveyTemplates).forEach(function (key) {
                templates.push(surveyTemplates[key]);
                if (surveyTemplates[key].status === SURVEY_STATUSES_VALUE.ACTIVE) {
                    activeTemplates.push(surveyTemplates[key]);
                }
            });

            return {
                ...state,
                getTemplatePaginatedListStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyGroupTemplatesMap: surveyTemplates,
                surveyTemplates: templates,
                surveyTemplatesMapCount: surveyTemplatesMapCount,
                activeTemplates: activeTemplates,
                totalTemplates : totalTemplates,
                listStartIndex : listStartIndex,
                currentPage : currentPage
            }
        }

        case SURVEY_TEMPLATE_GET_NEXT_LIST_NONE :{
            return {
                ...state,
                getTemplatePaginatedListStatus : HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID:{
            return {
                ...state,
                questionnaireGetStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS:{

            let currentTemplate = action.payload.data.data;
            currentTemplate.questions = AppUtils.getSortedQuestions(currentTemplate.questions);
            currentTemplate = AppUtils.updateSurveyTemplateDetails(currentTemplate, state.isUserHasTemplateCreateAccess);

            return {
                ...state,
                loader: false,
                currentTemplate: currentTemplate,
                questionnaireGetStatus : HTTP_REQUEST_STATUS.SUCCESS
            };
        }

        /**** survey template : to hide loader ****/
        case SURVEY_NEW_TEMPLATE_HIDE_LOADER:
            return {
                ...state,
                loader: false
            };

        /**** survey template : to show loader ****/
        case SURVEY_NEW_TEMPLATE_SHOW_LOADER:
            return {
                ...state,
                loader: true
            };

        case SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS:
            return {
                ...state,
                templateInfoSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        case SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_NONE:
            return {
                ...state,
                templateInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR:{
            return {
                ...state,
                loader: false,
                templateInfoSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error.message
            }
        }

        /**** survey template info save success callback ****/
        case SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS: {
            let currentTemplate = state.currentTemplate;

            if ( action.payload.data && action.payload.data.data ) {
                currentTemplate = action.payload.data.data;
                currentTemplate.questions = AppUtils.getSortedQuestions(currentTemplate.questions);
                currentTemplate = AppUtils.updateSurveyTemplateDetails(currentTemplate, state.isUserHasTemplateCreateAccess);
            }

            return {
                ...state,
                loader: false,
                templateInfoSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
                currentTemplate: currentTemplate,
                currentTemplateStep : SURVEY_TEMPLATE_STEPS.VIEW
            };
        }


        /**** survey template : get all questions : in progress ****/
        case SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS:
            return {
                ...state,
                templateGetQuestionsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                currentTemplateQuestions: []
            };
        /**** survey template : get all questions : none ****/
        case SURVEY_TEMPLATE_GET_QUESTIONS_NONE:
            return {
                ...state,
                templateGetQuestionsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                currentTemplateQuestions: []
            };

        /**** survey template : get all questions : success ****/
        case SURVEY_TEMPLATE_GET_QUESTIONS_ERROR:
            return {
                ...state,
                templateGetQuestionsStatus: HTTP_REQUEST_STATUS.FAIL
            };

        /**** survey template : get all questions : success ****/
        case SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                loader: false,
                currentTemplateQuestions: action.payload.data.data,
                templateGetQuestionsStatus: HTTP_REQUEST_STATUS.SUCCESS
            };

        case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS: {

            let currentTemplate = state.currentTemplate;

            if ( action.payload.data && action.payload.data.data ){
                currentTemplate = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);
            }

            return {
                ...state,
                currentTemplate : currentTemplate,
                counter : state.counter + 1
            };

        }

        default:
            return state;
    }

};

function generateQuestionnaireLanguageMap (questionnaires){

    let result = {};

    try{

        for ( let i = 0 ; i < questionnaires.length; i++ ) {

            let supportedLanguages = questionnaires[i]['supported_languages'];
            let languages = [];

            for ( let j = 0 ; j < supportedLanguages.length; j++ ) {
                languages.push(supportedLanguages[j]["language_code"]);
            }

            result[questionnaires[i]["id"]] = languages;
        }

    } catch ( ex ) {
        console.error("generateQuestionnaireLanguageMap : error : ", ex );
    }

    return result;
}


export default SurveyTemplates;
