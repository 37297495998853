import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";

import {
    SURVEY_TRANSLATIONS_DOWNLOAD,
    SURVEY_TRANSLATIONS_UPLOAD
} from "../../../constants/actions/survey/survey/actionsSurveyUtils";
import {
    URL_SURVEY_DOWNLOAD_TRANSLATION_FILE,
    URL_SURVEY_UPLOAD_TRANSLATION_FILE
} from "../../../constants/Urls";
import {
    downloadSurveyQuestionnaireTranslationsError,
    downloadSurveyQuestionnaireTranslationsSuccess,
    uploadSurveyQuestionnaireTranslationsError,
    uploadSurveyQuestionnaireTranslationsSuccess
} from "../../../actions";
import {baseUrl} from "../../../util/keys";
import AppUtils from "../../../util/util";

/************ methods : http *******************/

/**** Https : survey or questionnaire translations : download ****/
const httpDownloadSurveyQuestionnaireTranslations = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_DOWNLOAD_TRANSLATION_FILE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : survey or questionnaire translations : upload ****/
const httpUploadSurveyQuestionnaireTranslations = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_UPLOAD_TRANSLATION_FILE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/************ methods : http response handler *******************/

/**** http : survey or questionnaire translations : download : http response handler ****/
function* handleHttpDownloadSurveyQuestionnaireTranslations({payload}) {
    try {
        let dataValue = yield call(httpDownloadSurveyQuestionnaireTranslations, payload);

        if (dataValue.message) {
            yield put(downloadSurveyQuestionnaireTranslationsError(dataValue.response));
        } else {
            yield put(downloadSurveyQuestionnaireTranslationsSuccess(dataValue));
        }
    } catch (error) {
        yield put(downloadSurveyQuestionnaireTranslationsError(error));
    }
}

/**** http : survey or questionnaire translations : upload : http response handler ****/
function* handleHttpUploadSurveyQuestionnaireTranslations({payload}) {
    try {
        let dataValue = yield call(httpUploadSurveyQuestionnaireTranslations, payload);

        if (dataValue.message) {
            yield put(uploadSurveyQuestionnaireTranslationsError(dataValue.response));
        } else {
            yield put(uploadSurveyQuestionnaireTranslationsSuccess(dataValue));
        }
    } catch (error) {
        yield put(uploadSurveyQuestionnaireTranslationsError(error));
    }
}


/************ methods : export access *******************/

/**** Listeners : survey or questionnaire translations : download ****/
export function* downloadSurveyQuestionnaireTranslations() {
    yield takeEvery(SURVEY_TRANSLATIONS_DOWNLOAD, handleHttpDownloadSurveyQuestionnaireTranslations)
}

/**** Listeners : survey or questionnaire translations : upload ****/
export function* uploadSurveyQuestionnaireTranslations() {
    yield takeEvery(SURVEY_TRANSLATIONS_UPLOAD, handleHttpUploadSurveyQuestionnaireTranslations)
}

export default function* rootSaga() {
    yield all([
        fork(downloadSurveyQuestionnaireTranslations),
        fork(uploadSurveyQuestionnaireTranslations)
    ])
}