import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';

class CardMenu extends React.Component {

  render() {

    const {menuState, anchorEl, handleRequestClose, menuWidth, handleItemClick} = this.props;
    let {options} = this.props;

    if ( typeof options == "undefined" ) {
      options = [];
    }

    let isKeysArray = false;

    if ( options.length > 0 && typeof options[0].value != "undefined" ) {
      isKeysArray = true;
    }


    return (
      <Menu id="long-menu"
            anchorEl={anchorEl}
            open={menuState}
            onClose={handleRequestClose}

            MenuListProps={{
              style: {
                width: menuWidth || 150,
                paddingTop: 0,
                paddingBottom: 0
              },
            }}>



        { !isKeysArray && options.map(option =>
          <MenuItem key={option} onClick={handleRequestClose}>
            {option}
          </MenuItem>,
        )}

        { isKeysArray && options.map(option =>
            <MenuItem key={option.value} onClick={ (event) => {
              handleItemClick ? handleItemClick(option.value, event) : handleRequestClose(option.value)
            }}>
               <IntlMessages id= { option.label} />
            </MenuItem>,
        )}

      </Menu>
    );
  }

}

export default CardMenu;
