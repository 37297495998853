import{
    TRANSLATION_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
    TRANSLATION_CATEGORY_GET_ALL_RECORDS,
    TRANSLATION_CATEGORY_GET_ALL_RECORDS_ERROR,
    TRANSLATION_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS,
    TRANSLATION_CATEGORY_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_CATEGORY_HIDE_NEW_EDIT_POPUP,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE_NONE,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
    TRANSLATION_CATEGORY_UPDATE_STATUS,
    TRANSLATION_CATEGORY_UPDATE_STATUS_ERROR,
    TRANSLATION_CATEGORY_UPDATE_STATUS_IN_PROGRESS,
    TRANSLATION_CATEGORY_UPDATE_STATUS_NONE,
    TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS
} from "../../../../constants/ActionTypes";

/****
 Translation Category : to get all records
 ****/
export function getTranslationCategory() {
    return {
        type: TRANSLATION_CATEGORY_GET_ALL_RECORDS
    };
}

/****
 Translation Category : to get all records in progress
 ****/

export function getTranslationCategoryInProgress() {
    return {
        type: TRANSLATION_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Translation Category : to get all records :error
 ****/
export function getTranslationCategoryError(params) {
    return {
        type: TRANSLATION_CATEGORY_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}


/****
 Translation Category : to get all records : Success
 ****/
export function getTranslationCategorySuccess(params) {
    return {
        type: TRANSLATION_CATEGORY_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Translation Category : to display new edit popup
 ****/

export function displayNewEditTranslationCategoryPopup( params ) {
    return {
        type: TRANSLATION_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
 Translation Category : to hide new edit popup
 ****/

export function hideNewEditTranslationCategoryPopup( params ) {
    return {
        type: TRANSLATION_CATEGORY_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
  Translation Category : save action 
 *****/
export function saveTranslationCategory( params ) {
    return {
        type: TRANSLATION_CATEGORY_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Translation Category : save success action
  *****/

export function saveTranslationCategorySuccess(data) {
    return {
        type: TRANSLATION_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Translation Category : save error action 
 *****/
export function saveTranslationCategoryError(data) {
    return {
        type: TRANSLATION_CATEGORY_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Translation Category : save in progress action 
 *****/

export function saveTranslationCategoryProgress() {
    return {
        type: TRANSLATION_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    };
}

/**** Translation Category : save None 
 *****/

export function saveTranslationCategoryNone() {
    return {
        type: TRANSLATION_CATEGORY_NEW_EDIT_SAVE_NONE,
    };
}

/****
 Translation Category  : status update
 ****/

export function translationCategoryUpdateStatus(params) {
    return {
        type: TRANSLATION_CATEGORY_UPDATE_STATUS,
        payload : params
    };
}

/****
 Translation Category  : status update in progress
 ****/
export function translationCategoryUpdateStatusInProgress() {
    return {
        type: TRANSLATION_CATEGORY_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
  Translation Category  : Update status to None
 ****/
export function translationCategoryUpdateStatusNone() {
    return {
        type: TRANSLATION_CATEGORY_UPDATE_STATUS_NONE
    };
}

/****
   Translation Category : status update : success
 ****/
export function translationCategoryUpdateStatusSuccess(params) {
    return {
        type: TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}


/****
  Translation Category : status update : error
 ****/
export function translationCategoryUpdateStatusError(params) {
    return {
        type: TRANSLATION_CATEGORY_UPDATE_STATUS_ERROR,
        payload : params
    };
}
