import React from 'react';
import { HTTP_REQUEST_STATUS } from '../../../constants/constants';
import {
	SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
	SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS,
	SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE,
	SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
	SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
	SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT,
	SURVEY_SURVEY_QUESTION_DELETE_ERROR,
	SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS,
	SURVEY_SURVEY_QUESTION_DELETE_NONE,
	SURVEY_SURVEY_QUESTION_DELETE_SUCCESS,
	SURVEY_TEMPLATE_QUESTION_DELETE_ERROR,
	SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS,
	SURVEY_TEMPLATE_QUESTION_DELETE_NONE,
	SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
	SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR,
	SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS,
	SURVEY_SURVEY_QUESTION_DUPLICATE_NONE,
	SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS,
	SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR,
	SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS,
	SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE,
	SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,
	SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS,
	SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR,
	SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE,
	SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS,
	SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR,
	SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE,
	SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS,
	SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS,
} from '../../../constants/actions/survey/questions/actionTypesSurveyQuestions';

import {
	SURVEY_GET_SURVEY_BY_ID_SUCCESS,
	SURVEY_INFO_UPDATE_STATUS_SUCCESS,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS,
	SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED,
	SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED,
	SURVEY_QUESTIONS_SET_CURRENT_ITEM,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
	SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
	SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT,
	SURVEY_REDEPLOY_SURVEY_SUCCESS,
} from '../../../constants/actions/survey/survey/actionTypesSurvey';
import AppUtils from '../../../util/util';
import {
	SURVEY_TEMPLATE_SET_STATUS_SUCCESS,
	SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS,
	SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
	SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE,
	SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
	SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS,
	SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
	SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE,
	SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
} from '../../../constants/ActionTypes';
import { SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS } from '../../../constants/actions/survey/survey/actioTypesSurveyTemplate';

const initialData = {
	loader: false,
	alertMessage: '',
	selectedQuestion: null,

	questionSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionDuplicateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionReOrderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	deletedQuestion: '',
	isAddQuestion: false,
	isCancelQuestion: false,
	isDeleteQuestion: false,
	enableNewQuestion: false,
	isResetQuestionLayout: false,
	isEditQuestion: false,
	editQuestionId: '',
	currentItem: {},
	duplicateAlertMessage: '',
	reorderAlertMessage: '',
	saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	surveySeriesQuestions: [],
	surveySeriesLanguages: [],
	isSurveySeriesQuestionsLayoutVisible: false,
};

const SurveyQuestions = (state = initialData, action) => {
	switch (action.type) {
		case SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT: {
			return {
				...state,
				isSurveySeriesQuestionsLayoutVisible: true,
			};
		}

		case SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT: {
			return {
				...state,
				isSurveySeriesQuestionsLayoutVisible: false,
			};
		}

		case SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
			};
		}

		case SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.SUCCESS,
				isSurveySeriesQuestionsLayoutVisible: false,
				surveySeriesQuestions: [],
			};
		}

		case SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.FAIL,
			};
		}

		case SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				isSurveySeriesQuestionsLayoutVisible: false,
			};
		}

		case SURVEY_REDEPLOY_GET_SERIES_QUESTIONS: {
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				surveySeriesQuestions: [],
				surveySeriesLanguages: [],
				alertMessage: '',
			};
		}

		case SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS: {
			let surveySeriesQuestions = [];
			let surveySeriesLanguages = [];

			if (action.payload && action.payload.data && action.payload.data.data) {
				if (action.payload.data.data.questions) {
					surveySeriesQuestions = action.payload.data.data.questions;
				}

				if (action.payload.data.data.supported_languages) {
					let tempLanguages = action.payload.data.data.supported_languages;

					for (let key in tempLanguages) {
						surveySeriesLanguages.push({
							value: key,
							name: tempLanguages[key],
						});
					}
				}
			}

			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.SUCCESS,
				surveySeriesQuestions: surveySeriesQuestions,
				surveySeriesLanguages: surveySeriesLanguages,
			};
		}

		case SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR: {
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.FAIL,
			};
		}

		case SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE: {
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS: {
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				surveySeriesQuestions: [],
				alertMessage: '',
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS: {
			let surveySeriesQuestions = [];
			let surveySeriesLanguages = [];
			if (action.payload && action.payload.data && action.payload.data.data) {
				if (action.payload.data.data.questions) {
					surveySeriesQuestions = action.payload.data.data.questions;
				}
				if (action.payload.data.data.supported_languages) {
					let tempLanguages = action.payload.data.data.supported_languages;
					for (let key in tempLanguages) {
						surveySeriesLanguages.push({
							value: key,
							name: tempLanguages[key],
						});
					}
				}
			}
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.SUCCESS,
				surveySeriesQuestions: surveySeriesQuestions,
				surveySeriesLanguages: surveySeriesLanguages,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR: {
			return {
				...state,
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.FAIL,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE: {
			return {
				...state,
				alertMessage: '',
				getSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.SUCCESS,
				isSurveySeriesQuestionsLayoutVisible: false,
				surveySeriesQuestions: [],
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.FAIL,
			};
		}

		case SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE: {
			return {
				...state,
				saveSurveySeriesQuestionStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				isSurveySeriesQuestionsLayoutVisible: false,
			};
		}

		case SURVEY_GET_SURVEY_BY_ID_SUCCESS:
			return {
				...state,
				currentItem: AppUtils.updateCurrentSurvey(action.payload.data.data),
			};

		case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS:
			return {
				...state,
				currentItem: AppUtils.updateSurveyTemplateDetails(
					action.payload.data.data
				),
			};

		case SURVEY_QUESTIONS_SET_CURRENT_ITEM:
			return {
				...state,
				currentItem: action.payload,
			};

		case SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED:
			return {
				...state,
				isEditQuestion: true,
				editQuestionId: action.payload.selected_question,
			};
		case SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED:
			return {
				...state,
				isEditQuestion: false,
				editQuestionId: '',
			};

		/** survey question action : delete question : enable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT:
			return {
				...state,
				enableNewQuestion: true,
			};

		/** survey question action : delete question : enable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT:
			return {
				...state,
				enableNewQuestion: false,
			};

		/** survey question action : delete question : enable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE:
			return {
				...state,
				isDeleteQuestion: true,
			};

		/** survey question action : delete question : disable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE:
			return {
				...state,
				isDeleteQuestion: false,
			};

		/** survey question action : cancel question : enable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE:
			return {
				...state,
				isCancelQuestion: true,
				enableNewQuestion: false,
				isEditQuestion: false,
			};

		/** survey question action : cancel question : disable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE:
			return {
				...state,
				isCancelQuestion: false,
			};

		/** survey question action : add question : enable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE:
			return {
				...state,
				isAddQuestion: true,
			};

		case SURVEY_TEMPLATE_SET_STATUS_SUCCESS:
		case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS:
			return {
				...state,
				isAddQuestion: false,
				enableNewQuestion: false,
			};

		/** survey question action : add question : disable **/
		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE:
			return {
				...state,
				isAddQuestion: false,
			};

		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS: {
			let currentItem = state.currentItem;

			if (
				typeof action.payload.data != 'undefined' &&
				typeof action.payload.data.parent != 'undefined'
			) {
				currentItem = AppUtils.updateCurrentSurvey(action.payload.data.parent);
				currentItem = JSON.parse(JSON.stringify(currentItem));
			}

			return {
				...state,
				currentItem: currentItem,
				questionSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
				alertMessage: action.payload.data.message,
				selectedQuestion: {},
				enableNewQuestion: state.isAddQuestion,
				isResetQuestionLayout: true,
				isEditQuestion: false,
			};
		}

		case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS: {
			let currentItem = state.currentItem;

			if (
				typeof action.payload.data != 'undefined' &&
				typeof action.payload.data.parent != 'undefined'
			) {
				currentItem = AppUtils.updateSurveyTemplateDetails(
					action.payload.data.parent
				);
				currentItem = JSON.parse(JSON.stringify(currentItem));
			}

			return {
				...state,
				currentItem: currentItem,
				questionSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
				alertMessage: action.payload.data.message,
				selectedQuestion: {},
				enableNewQuestion: state.isAddQuestion,
				isResetQuestionLayout: true,
				isEditQuestion: false,
			};
		}

		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE:
			return {
				...state,
				questionSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				alertMessage: '',
				isResetQuestionLayout: false,
			};

		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR:
			return {
				...state,
				questionSaveStatus: HTTP_REQUEST_STATUS.FAIL,
				alertMessage:
					action.payload.data && action.payload.data.error
						? action.payload.data.error.message
						: '',
				isResetQuestionLayout: false,
			};

		case SURVEY_SURVEY_QUESTION_DELETE_SUCCESS:
		case SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS: {
			let deletedQuestion = action.payload.data.data;
			let { currentItem } = state;
			if (
				deletedQuestion !== '' &&
				typeof currentItem != 'undefined' &&
				typeof currentItem['questions'] != 'undefined'
			) {
				for (let i = 0; i < currentItem['questions'].length; i++) {
					if (currentItem['questions'][i].id === deletedQuestion) {
						currentItem['questions'].splice(i, 1);
						break;
					}
				}
			}

			return {
				...state,
				questionDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
				deletedQuestion: deletedQuestion,
				alertMessage: action.payload.data.message,
				currentItem: currentItem,
			};
		}

		case SURVEY_SURVEY_QUESTION_DELETE_ERROR:
			return {
				...state,
				questionDeleteStatus: HTTP_REQUEST_STATUS.FAIL,
				deletedQuestion: '',
				alertMessage: action.payload.data.message,
			};

		case SURVEY_TEMPLATE_QUESTION_DELETE_ERROR:
			return {
				...state,
				questionDeleteStatus: HTTP_REQUEST_STATUS.FAIL,
				deletedQuestion: '',
				alertMessage: action.payload.data.error.message,
			};

		case SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS:
		case SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS:
			return {
				...state,
				questionDeleteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				deletedQuestion: '',
				alertMessage: '',
			};

		case SURVEY_SURVEY_QUESTION_DELETE_NONE:
		case SURVEY_TEMPLATE_QUESTION_DELETE_NONE:
			return {
				...state,
				questionDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				deletedQuestion: '',
				alertMessage: '',
			};

		case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR:
			return {
				...state,
				questionSaveStatus: HTTP_REQUEST_STATUS.FAIL,
				alertMessage:
					action.payload && action.payload.data
						? action.payload.data.message
						: '',
				selectedQuestion: null,
			};

		case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS:
		case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS:
			return {
				...state,
				questionSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				alertMessage: '',
				selectedQuestion: null,
			};

		case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE:
			return {
				...state,
				questionSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				alertMessage: '',
			};

		case SURVEY_INFO_UPDATE_STATUS_SUCCESS:
			return {
				...state,
				alertMessage: action.payload.data.message,
			};

		/** survey question actions : duplicate question **/

		case SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS:
		case SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS:
			let { currentSurvey } = state;

			if (action.payload.data && action.payload.data.data) {
				currentSurvey = action.payload.data.data;
				currentSurvey.questions = AppUtils.getSortedQuestions(
					currentSurvey.questions
				);
				currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);
			}

			return {
				...state,
				questionDuplicateStatus: HTTP_REQUEST_STATUS.SUCCESS,
				duplicateAlertMessage: action.payload.data.message,
				currentItem: currentSurvey,
			};

		case SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR:
		case SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR:
			return {
				...state,
				questionDuplicateStatus: HTTP_REQUEST_STATUS.FAIL,
				duplicateAlertMessage: action.payload.data.message,
			};

		case SURVEY_SURVEY_QUESTION_DUPLICATE_NONE:
		case SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE:
			return {
				...state,
				questionDuplicateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				duplicateAlertMessage: '',
			};

		case SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS:
		case SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS:
			return {
				...state,
				questionDuplicateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				duplicateAlertMessage: '',
			};

		/** survey question actions : reorder question **/

		case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS:
		case SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS:
			return {
				...state,
				questionReOrderStatus: HTTP_REQUEST_STATUS.SUCCESS,
				reorderAlertMessage: action.payload.data.message,
				currentItem: action.payload.data.data,
			};

		case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE:
		case SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE:
			return {
				...state,
				questionReOrderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				reorderAlertMessage: '',
			};

		case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR:
		case SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR:
			return {
				...state,
				questionReOrderStatus: HTTP_REQUEST_STATUS.FAIL,
				reorderAlertMessage:
					action.payload.data && action.payload.data.error
						? action.payload.data.error.message
						: '',
			};

		case SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS:
		case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS:
			return {
				...state,
				questionReOrderStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
				reorderAlertMessage: '',
			};

		case SURVEY_REDEPLOY_SURVEY_SUCCESS: {
			let { currentSurvey } = state;

			if (action.payload.data && action.payload.data.data) {
				currentSurvey = action.payload.data.data;
				currentSurvey.questions = AppUtils.getSortedQuestions(
					currentSurvey.questions
				);
				currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);
			}

			return {
				...state,
				alertMessage: action.payload.data.message,
				currentItem: currentSurvey,
			};
		}

		default:
			return state;
	}
};

export default SurveyQuestions;
