import React from 'react';
import { IconButton, Tooltip } from "@material-ui/core";

const LSInfoIcon = ({ info, buttonStyle }) => {
     return (
          <Tooltip leaveDelay={10} title={info}>
               <IconButton
                    className='default_info_icon'
                    style={buttonStyle}
                    disableFocusRipple={true}
                    disableRipple={true}
                    disableTouchRipple={true}
               >
                    <div className='survey_setting_info_icon'></div>
               </IconButton>
          </Tooltip>
     )
};

export default LSInfoIcon;