import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';

let suggestions = [];

function renderInput(inputProps) {
    const {classes, ref, ...other} = inputProps;

    return (
    <TextField
      fullWidth
      inputref={ref}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
    );
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div" style={{'font-size': '14px'}}>
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{fontWeight: 300}}>
              {part.text}
            </span>
                    ) : (
                        <strong key={String(index)} style={{fontWeight: 500}}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function renderSuggestionsContainer(options) {
    const {containerProps, children} = options;

    return (
        <div className="position-relative z-index-20">
            <Paper {...containerProps} square>
                {children}
            </Paper>
        </div>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.label;
}

function getSuggestions(value, items, searchField) {

    if (typeof items == "undefined" || items.length === 0 || typeof searchField == "undefined" || searchField === "") {
        return [];
    }

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    suggestions = [];

    for (let i = 0; i < items.length; i++) {
        suggestions.push(
            {
                label: items[i][searchField]
            }
        )
    }

    return inputLength === 0
        ? []
        : suggestions.filter(suggestion => {
            const keep =
                count < 5 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            if (keep) {
                count += 1;
            }
            return keep;
        });
}

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
        height: 100,
        width: '100%',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing(3),
        left: 0,
        right: 0,
        zIndex: 10,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
});

class IntegrationAutoSuggest extends React.Component {

    state = {
        value: '',
        suggestions: [],
    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        if (this.props.onSelect) {
            this.props.onSelect(suggestion)
        }
    }

    handleSuggestionsFetchRequested = ({value}) => {
        const {items, searchField} = this.props;

        this.setState({
            suggestions: getSuggestions(value, items, searchField),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = (event, {newValue}) => {
        this.setState({
            value: newValue,
        });

        if (this.props.onChange) {
            this.props.onChange(this.props.content_id, newValue);
        }

        /*const {inputChange} = this.props;

        if ( inputChange ) {
            inputChange(event);
        }*/
    };

    render() {
        const {classes, placeHolder, inputChange} = this.props;


        return (
            <Autosuggest
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={{
                    classes,
                    placeholder: placeHolder,
                    value: this.state.value,
                    onChange: this.handleChange,
                }}
            />
        );
    }

}

IntegrationAutoSuggest.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntegrationAutoSuggest);
