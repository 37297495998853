import { 
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS,
    
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE,
    
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE,
    SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN,
    SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN,
    
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE
     } from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
    
    
     /****
      Survey Template: get Quick Insights
     ****/
    export function surveyTemplateGetQuickInsights(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUICK_INSIGHTS,
            payload:params
        };
    }
    
    /****
     Survey Template : get Quick insights data success
     ****/
    export function getSurveyTemplateQuickInsightsSuccess(data) {
        return {
            type: SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS,
            payload : data
        };
    }
    
    /****
     Survey Template : get Quick insights data error
     ****/
    export function getSurveyTemplateQuickInsightsError(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR,
            payload : params
        };
    }
    
    
    
    /****
      Survey Template: get Quick insights : in progress
     ****/
    export function surveyTemplateGetQuickInsightsInProgress() {
        return {
            type: SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS,
        };
    }
    
    
    
    /****
     Survey Template : get quick insights status to none
     ****/
    export function getSurveyTemplateQuickInsightsStatusNone(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE,
            payload : params
        };
    }
    
    /****
      Survey : get Question Summary
     ****/
    export function surveyTemplateGetQuestionSummary(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY,
            payload : params
        };
    }
    
    /****
     Survey Template : get Question Summary data success
     ****/
    export function getSurveyTemplateQuestionSummarySuccess(data) {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS,
            payload : data
        };
    }
    
     
    /****
     Survey Template : get Question Summary data error
     ****/
    export function getSurveyTemplateQuestionSummaryError(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR,
            payload : params
        };
    }
    
    /****
      Survey Template: get Question Summary : in progress
     ****/
    export function surveyTemplateGetQuestionSummaryInProgress() {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS,
        };
    }
    
    
    /****
     Survey Template : get Question Summary status to none
     ****/
    export function getSurveyTemplateQuestionSummaryStatusNone(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE,
            payload : params
        };
    }
    
    /****
     Survey Template : To get the Graph Type
     ****/
    
    export function setCurrentGraphType(params) {
        return {
            type: SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE,
            payload : params
        };
    }
    
    
    /****
     Survey Template : To Download the text responses
     ****/
    
     export function surveyTemplateDownloadResponses(params) {
        return {
            type: SURVEY_TEMPLATE_RESPONSES_DOWNLOAD,
            payload : params
        };
     }
    
     /****
     Survey Template : To Download the text responses success
     ****/
    
     export function surveyTemplateDownloadResponsesSuccess(data){
            return {
                type: SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS,
                payload: data  
         };
     }
    
    /****
     Survey Template : To Download the text responses error
     ****/
    
    export function surveyTemplateDownloadResponsesError(params){
        return {
            type: SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR,
            payload: params
        }
    }
    
    /****
     Survey Template : To Download the responses in progress
     ****/
    
    export function surveyTemplateDownloadResponsesInProgress(){
         return{
             type:SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS
         };
     }
    
     /****
     Survey Template : To Download the responses None
     ****/
    
    export function surveyTemplateDownloadResponsesNone(params){
        return{
            type:SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE,
            payload: params
        };
    }

      /****
     Survey Template : update survey dropdown
     ****/
    
    export function updateSurveyDropdown(params){
        return{
            type:SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN,
            payload: params
        };
    }

      /****
     Survey Template : update client dropdown
     ****/
    
    export function updateClientDropdown(params){
        return{
            type:SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN,
            payload: params
        };
    }

/****
Survey questionnaire quickinsights setting filter : on question order filter dropdow change
****/

export function surveyQuestionnaireFilterQuestionOrderChange(params) {
    return {
        type: SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
        payload: params
    };
}

/****
Survey questionnaire quickinsights setting filter : on responder access filter dropdow change
****/

export function surveyQuestionnaireFilterResponderAccessChange(params) {
    return {
        type: SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
        payload: params
    };
}

export function surveyQuestionnaireFilterCompanyStatusChange(params) {
    return {
        type: SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
        payload: params
    };
}

/****
Survey questionnaire quickinsights setting filter : reset
****/

export function surveyQuestionnaireSettingFilterReset() {
    return {
        type: SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET
    };
}

/****
Survey questionnaire quickinsights setting filter : country
****/
export function surveyQuestionnaireFilterUpdateSelectedCountries(params){
    return{
        type:SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES,
        payload: params
    };
}

/****
Survey questionnaire quickinsights setting filter : invited media
****/
export function surveyQuestionnaireFilterUpdateInvitedMedia(params){
    return{
        type:SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE,
        payload: params
    };
}