import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";
import {HTTP_TIMEOUT} from "../../../../constants/Urls";

import {
    URL_TRANSLATIONS_LANGUAGE_DOWNLOAD_JSON,
    URL_TRANSLATIONS_LANGUAGE_GET_TRANSLATIONS_LIST,
    URL_TRANSLATIONS_LANGUAGE_UPLOAD_JSON
} from "../../../../constants/Urls";


import AppUtils from "../../../../util/util";
import {
    TRANSLATION_STRINGS_DOWNLOAD,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST,
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED,
    TRANSLATION_STRINGS_UPLOAD
} from "../../../../constants/actions/adminSettings/translations/strings/actionTranslationStrings";
import {
    downloadTranslationJSONFileError,
    downloadTranslationJSONFileSuccess,
    getTranslationsListError,
    getTranslationsListSuccess,
    uploadTranslationJSONFileSaveError,
    uploadTranslationJSONFileSaveSuccess
} from "../../../../actions/adminSettings/translations/strings/actionsTranslationStrings";


const headers = { 
    headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: HTTP_TIMEOUT
}

/************ methods : http *******************/

/** http : translations file upload : http save **/
const httpUploadTranslationFile = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_LANGUAGE_UPLOAD_JSON, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : translations list : http get **/
const httpGetTranslationsList = async () =>
    await axios.get(baseUrl + URL_TRANSLATIONS_LANGUAGE_GET_TRANSLATIONS_LIST, headers)
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : translations file download : http **/
const httpDownloadTranslationFile = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_LANGUAGE_DOWNLOAD_JSON, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : translations list : http get **/
const httpGetTranslationsWithTranslatedStringList = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_LANGUAGE_GET_TRANSLATIONS_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/


/** http : translations file upload : http response handler **/
function* handleHttpUploadTranslationFile({payload}) {
    try {

        let result = yield call(httpUploadTranslationFile,payload);

        if (result.message) {
            yield put(uploadTranslationJSONFileSaveError(result.response));
        } else {
            yield put(uploadTranslationJSONFileSaveSuccess(result));
        }
    } catch (error) {
        yield put(uploadTranslationJSONFileSaveError(error));
    }
}

/** http : translations list : http response handler **/
function* handleHttpGetTranslationsList(_args) {
    try {

        let result = yield call(httpGetTranslationsList);

        if (result.message) {
            yield put(getTranslationsListError(result.response));
        } else {
            yield put(getTranslationsListSuccess(result));
        }
    } catch (error) {
        yield put(getTranslationsListError(error));
    }
}

/** http : translations list : http response handler **/
function* handleHttpGGetTranslationsWithTranslatedStringsList({payload}) {
    try {

        let result = yield call(httpGetTranslationsWithTranslatedStringList,payload);

        if (result.message) {
            yield put(getTranslationsListError(result.response));
        } else {
            yield put(getTranslationsListSuccess(result));
        }
    } catch (error) {
        yield put(getTranslationsListError(error));
    }
}

/** http : translations file download : http response handler **/
function* handleHttpDownloadTranslationFile({payload}) {
    try {

        let result = yield call(httpDownloadTranslationFile,payload);

        if (result.message) {
            yield put(downloadTranslationJSONFileError(result.response));
        } else {
            yield put(downloadTranslationJSONFileSuccess(result));
        }
    } catch (error) {
        yield put(downloadTranslationJSONFileError(error));
    }
}

/************ methods : export access *******************/

/** To upload translation file to server **/
export function* uploadTranslationFile() {
    yield takeEvery(TRANSLATION_STRINGS_UPLOAD, handleHttpUploadTranslationFile);
}

/** Toget translations list **/
export function* getTranslationsList() {
    yield takeEvery(TRANSLATION_STRINGS_TRANSLATIONS_LIST, handleHttpGetTranslationsList);
}


/** To download translation file from server **/
export function* downloadTranslationFile() {
    yield takeEvery(TRANSLATION_STRINGS_DOWNLOAD, handleHttpDownloadTranslationFile);
}

/** To get translations with translated strings list **/
export function* getTranslationsWithTranslatedStringsList() {
    yield takeEvery(TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED, handleHttpGGetTranslationsWithTranslatedStringsList);
}


export default function* rootSaga() {
    yield all([
        fork(uploadTranslationFile),
        fork(getTranslationsList),
        fork(downloadTranslationFile),
        fork(getTranslationsWithTranslatedStringsList)
    ]);
}
