import IntlMessages from "../../../util/IntlMessages";
import React from "react";
import {
    SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_CATEGORY_SHOW_LOADER,
    SURVEY_CATEGORY_HIDE_LOADER,
    SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_CATEGORY_NEW_EDIT_SHOW_ERROR,
    SURVEY_CATEGORY_NEW_EDIT_HIDE_ERROR,
    SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
    SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP,
    SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR,
    SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR,
    SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS,
    SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR,
    SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS,
    SURVEY_CATEGORY_UPDATE_STATUS_NONE,
    SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS,
    SURVEY_CATEGORY_UPDATE_STATUS_ERROR,

} from "../../../constants/ActionTypes";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";


const categoryHeaders = [
    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_CATEGORY_TBL_HEADER_S_NO"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_CATEGORY_TBL_HEADER_NAME"/>
    },
    {
        id: 'description',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SURVEY_CATEGORY_TBL_HEADER_DESCRIPTION"/>
    },
    {
        id: 'actions',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_CATEGORY_TBL_HEADER_ACTIONS"/>
    }
];

const initialData = {
    tableColumns: categoryHeaders,
    surveyCategories: [],
    surveyCategoriesMap: [],
    surveyCategoriesMapDropdown: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedCategory: null,


    categorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    categoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    categoryUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED

};

const SurveyCategories = (state = initialData, action) => {

    switch (action.type) {

        case SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                categoryUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
            };

        case SURVEY_CATEGORY_UPDATE_STATUS_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.error,
                isSuccessMessage: false,
                isErrorMessage: true,
            };

        case SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS:
            return {
                ...state,
                categoryUpdateStatusStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        case SURVEY_CATEGORY_UPDATE_STATUS_NONE:
            return {
                ...state,
                categoryUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS:
            return {
                ...state,
                categoryFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        case SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR:
            return {
                ...state,
                categoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS:

            let surveyCategoriesMap = generateCategoriesMap(action.payload.data.data);

            let dropDownValues = [];
            dropDownValues.push({
                key: -1,
                value: -1,
                label: "All"
            });

            for ( let i = 0 ; i < surveyCategoriesMap.length; i++ ) {
                dropDownValues.push(surveyCategoriesMap[i]);
            }

            return {
                ...state,
                surveyCategories: action.payload.data.data,
                surveyCategoriesMap: surveyCategoriesMap,
                surveyCategoriesMapDropdown : dropDownValues,
                isSuccessMessage: false,
                isErrorMessage: false,
                categoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR:
            return {
                ...state,
                alertMessage: '',
                isSuccessMessage: false,
                isErrorMessage: false,
            };

        case SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false,
                selectedCategory: null,
                alertMessage: '',
                isSuccessMessage: false,
                isErrorMessage: false,
                categorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
            };

        case SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedCategory: action.payload
            };

        case SURVEY_CATEGORY_NEW_EDIT_HIDE_ERROR:
            return {
                ...state,
                alertMessage: '',
                isErrorMessage: false
            };

        case SURVEY_CATEGORY_NEW_EDIT_SHOW_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                categorySaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.error,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: true,
                categorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false
            };

        case SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isErrorMessage: false,
                isSuccessMessage: true,
                isPopUpVisible: false,
                selectedCategory: null,
                categorySaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };

        case SURVEY_CATEGORY_SHOW_LOADER:
            return {
                ...state,
                loader: false
            };

        case SURVEY_CATEGORY_HIDE_LOADER:
            return {
                ...state,
                loader: false
            };

        default:
            return state;
    }

};

/****
 Util : for generating the category list map
 ****/
function generateCategoriesMap( data ) {
    let result = [];

    try{
        for ( let i = 0 ; i < data.length; i++ ){
            result.push({
                key: data[i]['id'],
                value: data[i]['id'],
                label: data[i]['name']
            })
        }
    } catch (ex) {
        console.error(ex);
    }
    return result;
}


export default SurveyCategories;
