/*** Survey Template Quick INsights */

export const SURVEY_TEMPLATE_GET_QUICK_INSIGHTS = 'SURVEY_TEMPLATE_GET_QUICK_INSIGHTS';
export const SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR = 'SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR';
export const SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS = 'SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS';
export const SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE = 'SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE';
export const SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS = 'SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS';


export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY';
export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR';
export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS';
export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE';
export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS';
export const SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE = 'SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE';

export const SURVEY_TEMPLATE_RESPONSES_DOWNLOAD = 'SURVEY_TEMPLATE_RESPONSES_DOWNLOAD';
export const SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR = 'SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR';
export const SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS = 'SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS';
export const SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS = 'SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS';
export const SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE = 'SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE';
export const SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN = 'SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN';
export const SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN = 'SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN';

/*** Survey Template Reports Raw data download as uuid status */

export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_NONE = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_NONE';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS';
export const SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE = 'SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE';

/**Survey Template summary table  */
export const SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA = 'SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA';
export const SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR = 'SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR';
export const SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS = 'SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS';
export const SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE = 'SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE';
export const SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS = 'SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS';

/**Survey Template summary table  download */
export const SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA = 'SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA';
export const SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR = 'SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR';
export const SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS = 'SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS';
export const SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE = 'SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE';
export const SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS = 'SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS';

export const SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE = 'SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE';
export const SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE = 'SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE';
export const SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD = 'SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD';


/** to get the survey template paginated list **/
export const SURVEY_TEMPLATE_GET_NEXT_LIST_IN_PROGRESS = "SURVEY_TEMPLATE_GET_NEXT_LIST_IN_PROGRESS";
export const SURVEY_TEMPLATE_GET_NEXT_LIST_NONE = "SURVEY_TEMPLATE_GET_NEXT_LIST_NONE";
export const SURVEY_TEMPLATE_GET_NEXT_LIST = "SURVEY_TEMPLATE_GET_NEXT_LIST";
export const SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS = 'SURVEY_TEMPLATE_GET_NEXT_LIST_SUCCESS';
export const SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR = 'SURVEY_TEMPLATE_GET_NEXT_LIST_ERROR';

/** to get the single survey template based on id **/
export const SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID = 'SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID';
export const SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS = 'SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS';
export const SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR = 'SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR';
export const SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE = 'SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE';

/**Survey quickinsights : filters change ation */
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE';
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE';
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET';
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE';
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES';

/**Survey SurveyInvitePage : status filters change action */
export const SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE = 'SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE';