import {APP_FONT_FILES_PATH} from "../constants/config";

var FontUtils = {

    appFontClasses: '"Roboto", "sans-serif", "Helvetica", "Arial"',
    customClassesForFont: ["body", ".sv_main", ".MuiInputBase-root", ".badge-tab-container button.MuiButtonBase-root"],
    languageFontMap: {
        "my": [
            {
                index: 0,
                cssName: "Tharlon",
                path: "/vendors/wovo/fonts/Tharlon-Regular.ttf"
            }
        ]
    },
    customFont: null,
    customCssStyle: null,


    /** to update font based on selected language if language is available in configuration **/
    updateFontBasedOnLanguage: function (languageCode) {
        try {

            FontUtils.removeFontFromDocument();

            if (typeof FontUtils.languageFontMap[languageCode] != "undefined") {

                let currentConfig = FontUtils.languageFontMap[languageCode][0];
                let url = 'url(' + currentConfig['path'] + ')';
                let fontName = currentConfig["cssName"];

                FontUtils.customFont = new FontFace(fontName, url);
                FontUtils.customFont.load().then(function (loaded_face) {
                    document.fonts.add(loaded_face);
                    document.body.style.fontFamily = fontName + ', ' + FontUtils.appFontClasses;
                    FontUtils.applyCustomFontStyle(fontName);

                }).catch(function (error) {
                    console.log("error called : ", FontUtils.customFont, error)
                });
            }

        } catch (ex) {
            console.error("updateFontBasedOnLanguage : error : ", ex);
        }
    },

    /** to remove previously applied custom font from document **/
    removeFontFromDocument: function () {
        try {

            if (FontUtils.customFont != null) {
                document.fonts.delete(FontUtils.customFont);
                document.body.style.fontFamily = FontUtils.appFontClasses;
            }

            FontUtils.removeCustomFontStyle()

        } catch (ex) {
            console.error("removeFontFromDocument : error : ", ex);
        }
    },

    /** to apply custom font style to css classes **/
    applyCustomFontStyle: function (fontName) {
        try {

            FontUtils.removeCustomFontStyle();

            let styleString = "";

            for (let i = 0; i < FontUtils.customClassesForFont.length; i++) {
                styleString += FontUtils.customClassesForFont[i] + "{ font-family: " + fontName + ", " + FontUtils.appFontClasses + " !important } ";
            }

            FontUtils.customCssStyle = document.createElement('style');
            FontUtils.customCssStyle.type = 'text/css';
            FontUtils.customCssStyle.innerHTML = styleString;
            document.getElementsByTagName('head')[0].appendChild(FontUtils.customCssStyle);
        } catch (ex) {
            console.error("applyCustomFontStyle : error : ", ex);
        }
    },

    /** to revert custom font style from css classes **/
    removeCustomFontStyle: function () {
        try {
            if (FontUtils.customCssStyle != null) {
                document.getElementsByTagName('head')[0].removeChild(FontUtils.customCssStyle);
                FontUtils.customCssStyle = null;
            }

        } catch (ex) {
            console.error("removeCustomFontStyle : error : ", ex);
        }
    },

    /** to update user preferred font **/
    updateUserPreferredFont: function (preferredFont) {
        try {

            FontUtils.removeFontFromDocument();

            if (typeof preferredFont == "undefined" || typeof preferredFont["id"] == "undefined") {
                return;
            }

            let url = 'url(' + APP_FONT_FILES_PATH + preferredFont["font_file_name"] + ')';
            let fontName = "'" + preferredFont["class_name"] + "'";

            FontUtils.customFont = new FontFace(fontName, url);

            FontUtils.customFont.load().then(function (loaded_face) {
                document.fonts.add(loaded_face);
                document.body.style.fontFamily = fontName + ', ' + FontUtils.appFontClasses;
                FontUtils.applyCustomFontStyle(fontName);

            }).catch(function (error) {
                console.log("error called : ", FontUtils.customFont, error)
            });

        } catch (ex) {
            console.error("updateFontBasedOnLanguage : error : ", ex);
        }
    }
}

export default FontUtils;