import {
	SURVEY_GET_NEXT_LIST,
	SURVEY_GET_NEXT_LIST_ERROR,
	SURVEY_GET_NEXT_LIST_IN_PROGRESS,
	SURVEY_GET_NEXT_LIST_NONE,
	SURVEY_GET_NEXT_LIST_SUCCESS,
	SURVEY_GET_SURVEY_BY_ID,
	SURVEY_GET_SURVEY_BY_ID_ERROR,
	SURVEY_GET_SURVEY_BY_ID_NONE,
	SURVEY_GET_SURVEY_BY_ID_SUCCESS,
	SURVEY_INFO_UPDATE_STATUS,
	SURVEY_INFO_UPDATE_STATUS_ERROR,
	SURVEY_INFO_UPDATE_STATUS_IN_PROGRESS,
	SURVEY_INFO_UPDATE_STATUS_NONE,
	SURVEY_INFO_UPDATE_STATUS_SUCCESS,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_IN_PROGRESS,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE,
	SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS,
	SURVEY_NEW_SURVEY_GET_ALL_RECORDS,
	SURVEY_NEW_SURVEY_GET_ALL_RECORDS_ERROR,
	SURVEY_NEW_SURVEY_GET_ALL_RECORDS_IN_PROGRESS,
	SURVEY_NEW_SURVEY_GET_ALL_RECORDS_NONE,
	SURVEY_NEW_SURVEY_GET_ALL_RECORDS_SUCCESS,
	SURVEY_NEW_SURVEY_GET_QUESTIONS_ERROR,
	SURVEY_NEW_SURVEY_GET_QUESTIONS_IN_PROGRESS,
	SURVEY_NEW_SURVEY_GET_QUESTIONS_NONE,
	SURVEY_NEW_SURVEY_GET_QUESTIONS_SUCCESS,
	SURVEY_NEW_SURVEY_HIDE_LOADER,
	SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED,
	SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS,
	SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE,
	SURVEY_NEW_SURVEY_SET_SOURCE_TYPE,
	SURVEY_NEW_SURVEY_SET_STEP,
	SURVEY_NEW_SURVEY_SET_SURVEY,
	SURVEY_NEW_SURVEY_SHOW_LOADER,
	SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS,
	SURVEY_NEW_CLOSING_MESSAGE_SAVE,
	SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS,
	SURVEY_NEW_OPENING_MESSAGE_SAVE,
	SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS,
	SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS,
	SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR,
	SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR,
	SURVEY_DELETE_NEW_OPENING_MESSAGE,
	SURVEY_DELETE_NEW_OPENING_MESSAGE_ERROR,
	SURVEY_DELETE_NEW_OPENING_MESSAGE_SUCCESS,
	SURVEY_DELETE_NEW_OPENING_MESSAGE_IN_PROGRESS,
	SURVEY_NEW_ENABLE_MESSAGE_LAYOUT,
	SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT,
	SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE,
	SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE,
	SURVEY_REDEPLOY_SURVEY,
	SURVEY_REDEPLOY_SURVEY_SUCCESS,
	SURVEY_REDEPLOY_SURVEY_ERROR,
	SURVEY_REDEPLOY_SURVEY_NONE,
	SURVEY_SET_REDEPLOY_STATUS,
	SURVEY_RESET_REDEPLOY_STATUS,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
	SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
	SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE,
	SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT,
	SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT,
	SURVEY_REDEPLOY_GET_QUESTION_SUMMARY,
	SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
	SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
	SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE,
	SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE,
	SURVEY_DELETE_NEW_OPENING_MESSAGE_NONE,
} from '../../../constants/actions/survey/survey/actionTypesSurvey';

export function enabledEditQuestion(params) {
	return {
		type: SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED,
		payload: params,
	};
}

export function disabledEditQuestion() {
	return {
		type: SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED,
	};
}

/****
  Survey : to generate survey url : Error
 ****/
export function generateSurveyWebUrlError(params) {
	return {
		type: SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR,
		payload: params,
	};
}

/****
  Survey : to generate survey url : success
 ****/
export function generateSurveyWebUrlSuccess(params) {
	return {
		type: SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS,
		payload: params,
	};
}

/****
  Survey : to generate survey url : none
 ****/
export function generateSurveyWebUrlNone(params) {
	return {
		type: SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE,
		payload: params,
	};
}

/****
  Survey : to generate survey url : in progress
 ****/
export function generateSurveyWebUrlInProgress(params) {
	return {
		type: SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_IN_PROGRESS,
		payload: params,
	};
}

/****
  Survey : to generate survey url
 ****/
export function generateSurveyWebUrl(params) {
	return {
		type: SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL,
		payload: params,
	};
}

/****
  Survey template : to set current template
 ****/
export function setCurrentSurvey(params) {
	return {
		type: SURVEY_NEW_SURVEY_SET_SURVEY,
		payload: params,
	};
}

/****
 Survey template : to set current template
 ****/
export function setCurrentSurveyStep(params) {
	return {
		type: SURVEY_NEW_SURVEY_SET_STEP,
		payload: params,
	};
}

/****
 Survey  : get all questions error
 ****/
export function getSurveyAllQuestionsError(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_QUESTIONS_ERROR,
		payload: params,
	};
}

/****
 Survey  : get all questions success
 ****/
export function getSurveyAllQuestionsSuccess(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_QUESTIONS_SUCCESS,
		payload: params,
	};
}

/****
 Survey  : get all questions status to none
 ****/
export function getSurveyAllQuestionsNone(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_QUESTIONS_NONE,
		payload: params,
	};
}

/****
 Survey  : get all questions status to in progress
 ****/
export function getSurveyAllQuestionsInProgress(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_QUESTIONS_IN_PROGRESS,
		payload: params,
	};
}

/****
 Survey  : get all records error
 ****/
export function getSurveyAllRecordsError(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_ALL_RECORDS_ERROR,
		payload: params,
	};
}

/****
 Survey  : get all records success
 ****/
export function getSurveyAllRecordsSuccess(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_ALL_RECORDS_SUCCESS,
		payload: params,
	};
}

/****
 Survey  : get all records status to none
 ****/
export function getSurveyAllRecordsStatusNone(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_ALL_RECORDS_NONE,
		payload: params,
	};
}

/****
    New template : record save error
 ****/
export function saveNewSurveyInfoError(params) {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR,
		payload: params,
	};
}

/****
 New Survey  : record save success
 ****/
export function saveNewSurveyInfoSuccess(params) {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS,
		payload: params,
	};
}

/****
 Survey new : save survey info
 ****/
export function saveNewSurveyInfo(params) {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO,
		payload: params,
	};
}

/****
 Survey update : update survey info
 ****/
export function updateSurveyInfo(params) {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE,
		payload: params,
	};
}

/****
 Survey new : save status to none
 ****/
export function saveNewSurveyNone() {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE,
	};
}

/****
 Survey new : save status to in progress
 ****/
export function saveNewSurveyInProgress() {
	return {
		type: SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
	};
}

/****
  Survey : to show loader
 ****/
export function showNewSurveyLoader() {
	return {
		type: SURVEY_NEW_SURVEY_SHOW_LOADER,
	};
}

/****
  Survey new : to hide loader
 ****/
export function hideSurveyTemplateLoader() {
	return {
		type: SURVEY_NEW_SURVEY_HIDE_LOADER,
	};
}

/****
  Survey : get all records
 ****/
export function surveyGetAllRecords(params) {
	return {
		type: SURVEY_NEW_SURVEY_GET_ALL_RECORDS,
		payload: params,
	};
}

/****
  Survey : get all records : in progress
 ****/
export function surveyGetAllRecordsInProgress() {
	return {
		type: SURVEY_NEW_SURVEY_GET_ALL_RECORDS_IN_PROGRESS,
	};
}

/****
 Survey : to set survey source type
 ****/
export function setNewSurveySourceType(params) {
	return {
		type: SURVEY_NEW_SURVEY_SET_SOURCE_TYPE,
		payload: params,
	};
}

/****
 Survey : update survey status : Error
 ****/
export function updateSurveyInfoStatusError(params) {
	return {
		type: SURVEY_INFO_UPDATE_STATUS_ERROR,
		payload: params,
	};
}

/****
 Survey : update survey status : success
 ****/
export function updateSurveyInfoStatusSuccess(params) {
	return {
		type: SURVEY_INFO_UPDATE_STATUS_SUCCESS,
		payload: params,
	};
}

/****
 Survey : update survey status : none
 ****/
export function updateSurveyInfoStatusNone() {
	return {
		type: SURVEY_INFO_UPDATE_STATUS_NONE,
	};
}

/****
 Survey : update survey status : in progress
 ****/
export function updateSurveyInfoStatusInProgress() {
	return {
		type: SURVEY_INFO_UPDATE_STATUS_IN_PROGRESS,
	};
}

/****
 Survey : update survey status
 ****/
export function updateSurveyInfoStatus(params) {
	return {
		type: SURVEY_INFO_UPDATE_STATUS,
		payload: params,
	};
}

/****
 Survey : paginated list : Error
 ****/
export function getSurveyPaginatedListError(params) {
	return {
		type: SURVEY_GET_NEXT_LIST_ERROR,
		payload: params,
	};
}

/****
 Survey : paginated list : success
 ****/
export function getSurveyPaginatedListSuccess(params) {
	return {
		type: SURVEY_GET_NEXT_LIST_SUCCESS,
		payload: params,
	};
}

/****
 Survey : paginated list : none
 ****/
export function getSurveyPaginatedListNone() {
	return {
		type: SURVEY_GET_NEXT_LIST_NONE,
	};
}

/****
 Survey : paginated list :
 ****/
export function getSurveyPaginatedList(params) {
	return {
		type: SURVEY_GET_NEXT_LIST,
		payload: params,
	};
}

/****
 Survey : get record by id : Error
 ****/
export function getSurveyByIdError(params) {
	return {
		type: SURVEY_GET_SURVEY_BY_ID_ERROR,
		payload: params,
	};
}

/****
 Survey : get record by id : success
 ****/
export function getSurveyByIdSuccess(params) {
	return {
		type: SURVEY_GET_SURVEY_BY_ID_SUCCESS,
		payload: params,
	};
}

/****
 Survey : get record by id : none
 ****/
export function getSurveyByIdNone() {
	return {
		type: SURVEY_GET_SURVEY_BY_ID_NONE,
	};
}

/****
 Survey : get record by id
 ****/
export function getSurveyById(params) {
	return {
		type: SURVEY_GET_SURVEY_BY_ID,
		payload: params,
	};
}

/****
 Survey : to set redeploy status
 ****/
export function setSurveyRedeployStatus(params) {
	return {
		type: SURVEY_SET_REDEPLOY_STATUS,
		payload: params,
	};
}

/****
 Survey : to set redeploy status
 ****/
export function resetSurveyRedeployStatus() {
	return {
		type: SURVEY_RESET_REDEPLOY_STATUS,
	};
}

/****
  Survey closing  message : save  : in progress
 ****/
export function saveSurveyClosingMessageInProgress() {
	return {
		type: SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS,
	};
}

/****
  Survey closing message : save
 ****/
export function saveSurveyClosingMessage(params) {
	return {
		type: SURVEY_NEW_CLOSING_MESSAGE_SAVE,
		payload: params,
	};
}

/****
  Survey opening  message : save  : in progress
 ****/
export function saveSurveyOpeningMessageInProgress() {
	return {
		type: SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS,
	};
}

/****
  Survey opening message : save
 ****/
export function saveSurveyOpeningMessage(params) {
	return {
		type: SURVEY_NEW_OPENING_MESSAGE_SAVE,
		payload: params,
	};
}

/****
  Survey closing message : save : sucess
 ****/
export function saveSurveyClosingMessageSuccess(params) {
	return {
		type: SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS,
		payload: params,
	};
}

/****
  Survey opening message : save : sucess
 ****/
export function saveSurveyOpeningMessageSuccess(params) {
	return {
		type: SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS,
		payload: params,
	};
}

/****
  Survey closing message : save : Error
 ****/
export function saveSurveyClosingMessageError(params) {
	return {
		type: SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR,
		payload: params,
	};
}

/****
  Survey opening message : save : Error
 ****/
export function saveSurveyOpeningMessageError(params) {
	return {
		type: SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR,
		payload: params,
	};
}

/****
  To enable new message layout
 ****/
export function enableMessageLayout(params) {
	return {
		type: SURVEY_NEW_ENABLE_MESSAGE_LAYOUT,
		payload: params,
	};
}

/****
  To enable new message layout
 ****/
export function enableCloseMessageLayout() {
	return {
		type: SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT,
	};
}

/****
  To disable Add opening message button
 ****/
export function disabledAddOpeningMessage() {
	return {
		type: SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE,
	};
}

/****
  To change the httpstatus
 ****/
export function setMessageSaveStatusToNone() {
	return {
		type: SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE,
	};
}

/****
Redeploy : survey record save
 ****/
export function redeploySurvey(params) {
	return {
		type: SURVEY_REDEPLOY_SURVEY,
		payload: params,
	};
}

/****
    Redeploy : record save success
 ****/
export function redeploySurveySuccess(params) {
	return {
		type: SURVEY_REDEPLOY_SURVEY_SUCCESS,
		payload: params,
	};
}

/****
Redeploy : record save Error
****/
export function redeploySurveyError(params) {
	return {
		type: SURVEY_REDEPLOY_SURVEY_ERROR,
		payload: params,
	};
}

/****
Redeploy : none
****/
export function redeploySurveyNone() {
	return {
		type: SURVEY_REDEPLOY_SURVEY_NONE,
	};
}

/****
 Redeploy : get series questions
 ****/
export function getSurveyRedeploySeriesQuestions(params) {
	return {
		type: SURVEY_REDEPLOY_GET_SERIES_QUESTIONS,
		payload: params,
	};
}

/****
 Redeploy : get series questions success
 ****/
export function getSurveyRedeploySeriesQuestionsSuccess(params) {
	return {
		type: SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS,
		payload: params,
	};
}

/****
 Redeploy : get series questions Error
 ****/
export function getSurveyRedeploySeriesQuestionsError(params) {
	return {
		type: SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR,
		payload: params,
	};
}

/****
 Redeploy : get series questions none
 ****/
export function getSurveyRedeploySeriesQuestionsNone() {
	return {
		type: SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE,
	};
}

/****
 Redeploy : save series questions
 ****/
export function saveSurveyRedeploySeriesQuestions(params) {
	return {
		type: SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS,
		payload: params,
	};
}

/****
 Redeploy : save series questions success
 ****/
export function saveSurveyRedeploySeriesQuestionsSuccess(params) {
	return {
		type: SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS,
		payload: params,
	};
}

/****
 Redeploy : save series questions Error
 ****/
export function saveSurveyRedeploySeriesQuestionsError(params) {
	return {
		type: SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR,
		payload: params,
	};
}

/****
 Redeploy : save series questions none
 ****/
export function saveSurveyRedeploySeriesQuestionsNone() {
	return {
		type: SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE,
	};
}

/****
 Redeploy : to get line graph details
 ****/
export function getSurveyRedeployQuestionSummary(params) {
	return {
		type: SURVEY_REDEPLOY_GET_QUESTION_SUMMARY,
		payload: params,
	};
}

/****
 Redeploy : to get line graph details :  success
 ****/
export function getSurveyRedeployQuestionSummarySuccess(params) {
	return {
		type: SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
		payload: params,
	};
}

/****
 Redeploy : to get line graph details :  Error
 ****/
export function getSurveyRedeployQuestionSummaryError(params) {
	return {
		type: SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
		payload: params,
	};
}

/****
 Redeploy : to get line graph details : none
 ****/
export function getSurveyRedeployQuestionSummaryNone() {
	return {
		type: SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE,
	};
}

/****
 Redeploy : to show series questions layout popup
 ****/
export function showSurveySeriesQuestionsLayout() {
	return {
		type: SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT,
	};
}

/****
 Redeploy : to hide series questions layout popup
 ****/
export function hideSurveySeriesQuestionsLayout() {
	return {
		type: SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT,
	};
}

/**
 * Survey Settings : Hide survey settings dialog
 */
export function hideSurveySettingsDialog() {
	return {
		type: SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE,
	};
}

/****
  Survey opening  message : delete  
 ****/
export function deleteNewSurveyOpeningMessage(params) {
	return {
		type: SURVEY_DELETE_NEW_OPENING_MESSAGE,
		payload: params,
	};
}
/****
  Survey opening  message : delete  : in progress
 ****/
export function deleteNewSurveyOpeningMessageInProgress(params) {
	return {
		type: SURVEY_DELETE_NEW_OPENING_MESSAGE_IN_PROGRESS,
	};
}
/****
  Survey opening  message : delete  : success
 ****/
export function deleteNewSurveyOpeningMessageSuccess(params) {
	return {
		type: SURVEY_DELETE_NEW_OPENING_MESSAGE_SUCCESS,
		payload: params,
	};
}
/****
  Survey opening  message : delete  : error
 ****/
export function deleteNewSurveyOpeningMessageError(params) {
	return {
		type: SURVEY_DELETE_NEW_OPENING_MESSAGE_ERROR,
		payload: params,
	};
}
/****
  Survey opening  message : delete  : none
 ****/
export function deleteNewSurveyOpeningMessageNone() {
	return {
		type: SURVEY_DELETE_NEW_OPENING_MESSAGE_NONE,
	};
}
