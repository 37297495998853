import {
    SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP,
    SURVEY_QUESTION_TYPE_GET_ALL_RECORDS,
    SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_ERROR,
    SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS,
    SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE,
    SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS_ERROR,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS_IN_PROGRESS,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE,
    SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS
} from "../../../constants/ActionTypes";


/****
 Survey question types : to get all records
 ****/
export function getSurveyQuestionTypes() {
    return {
        type: SURVEY_QUESTION_TYPE_GET_ALL_RECORDS
    };
}

/****
 Survey question types : to get all records : in progress
 ****/
export function getSurveyQuestionTypesInProgress() {
    return {
        type: SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Survey question types : to get all records : error
 ****/
export function getSurveyQuestionTypesError(params) {
    return {
        type: SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}

/****
 Survey question types : to get all records : success
 ****/
export function getSurveyQuestionTypesSuccess(params) {
    return {
        type: SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Survey question types : to display new edit popup
 ****/
export function displayNewEditSurveyQuestionTypePopup( params ) {
    return {
        type: SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}

/****
 Survey question types : to hide new edit popup
 ****/
export function hideNewEditSurveyQuestionTypePopup( params ) {
    return {
        type: SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}


/**** Survey question types : save action ****/
export function saveSurveyQuestionType( params ) {
    return {
        type: SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Survey question types : save success action ****/
export function saveSurveyQuestionTypeSuccess(data) {
    return {
        type: SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Survey question types : save error action ****/
export function saveSurveyQuestionTypeError(data) {
    return {
        type: SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Survey question types : save in progress action ****/
export function saveSurveyQuestionTypeProgress() {
    return {
        type: SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS
    };
}

/**** Survey question types : save in progress action ****/
export function saveSurveyQuestionTypeNone() {
    return {
        type: SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE
    };
}

/****
 Survey question type : status update
 ****/
export function surveyQuestionTypeUpdateStatus(params) {
    return {
        type: SURVEY_QUESTION_TYPE_UPDATE_STATUS,
        payload : params
    };
}

/****
 Survey question type : status update in progress
 ****/
export function surveyQuestionTypeUpdateStatusInProgress() {
    return {
        type: SURVEY_QUESTION_TYPE_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
 Survey question type : status update to none
 ****/
export function surveyQuestionTypeUpdateStatusNone() {
    return {
        type: SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE
    };
}

/****
 Survey question type : status update : success
 ****/
export function surveyQuestionTypeUpdateStatusSuccess(params) {
    return {
        type: SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}

/****
 Survey question type : status update : error
 ****/
export function surveyQuestionTypeUpdateStatusError(params) {
    return {
        type: SURVEY_QUESTION_TYPE_UPDATE_STATUS_ERROR,
        payload : params
    };
}