import {
  CLIENT_GET_ALL_CLIENTS_SUCCESS,
  CLIENT_SELECTED_CLIENT,
  CLIENT_UPDATE_CLIENT_IN_LIST,
  CLIENT_GET_BY_STATUS,
} from "../../constants/ActionTypes";
import IntlMessages from "../../util/IntlMessages";
import React from "react";
import AppUtils from "../../util/util";
import StorageHandler from "../../util/storageHandler";

const initialState = {
  clientsList: [],
  activeClientsList: [],
  clientsListMap: [],
  clientsListMapWithAll: [],
  childClientsList: [],
  dropdownChildClients: [],
  outlineDropdownChildClients: [],
  dropdownClientAndChildClients: [],
  selectedClient: {},
  isClientIsBrand: false,
  factoriesListMapWithAll: [],
  dropdownClientAndParentClients: [],
  userAssociatedClients: [],
  dropdownChildWOVOClients: [],
  childClientsByStatus: [],
  activeChildClients: [],
  activeChildWOVOClients: [],
};

const ReducerClients = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_SELECTED_CLIENT: {
      let selectedClient = action.payload.client;

      let childClientsList = getClientChildClients(
        state.clientsList,
        selectedClient
      );
      let dropdownChildClients = [];
      let outlineDropdownChildClients = [];
      let dropdownClientAndChildClients = [];
      let dropdownChildWOVOClients = [];
      let activeChildClients = [];
      let activeChildWOVOClients = [];

      if (childClientsList.length > 0) {
        dropdownChildClients = generateClientsMapWithAll(childClientsList);

        outlineDropdownChildClients = generateClientsMapWithAllDicKeys(
          childClientsList
        );

        let tempList = [];
        tempList.push(selectedClient);
        tempList = tempList.concat(childClientsList);
        dropdownClientAndChildClients = generateClientsMapWithAll(tempList);
        dropdownChildWOVOClients = generateClientsMapWithAll(
          childClientsList,
          true
        );

        activeChildClients = generateActiveClientsMap(
          childClientsList.filter((c) => c.is_active == true)
        );
        activeChildWOVOClients = generateActiveClientsMap(
          childClientsList.filter((c) => c.is_active == true),
          true
        );
      }

      if (selectedClient && typeof selectedClient.value == "undefined") {
        selectedClient.label = selectedClient.name;
        selectedClient.value = selectedClient.client_key;
      }

      let isClientIsBrand = AppUtils.isClientIsBrand(selectedClient);
      hideMenuItem(isClientIsBrand);

      return {
        ...state,
        selectedClient: { ...selectedClient },
        isClientIsBrand: isClientIsBrand,
        childClientsList: childClientsList,
        dropdownChildClients: [...dropdownChildClients],
        dropdownClientAndChildClients: [...dropdownClientAndChildClients],
        dropdownChildWOVOClients: dropdownChildWOVOClients,
        outlineDropdownChildClients: outlineDropdownChildClients,
        activeChildClients: activeChildClients,
        activeChildWOVOClients: activeChildWOVOClients,
      };
    }

    case CLIENT_GET_ALL_CLIENTS_SUCCESS:
      let allClients = [];
      if (action.payload.clients?.length > 0) {
        allClients = action.payload.clients.filter(
          (c) => c.client_key !== null
        );
      }
      let activeClientsList = allClients.filter((x) => x.is_active == true);
      let clientsListMap = generateClientsMap(allClients);
      let clientsListMapWithAll = generateClientsMapWithAll(allClients);
      let factoriesListMapWithAll = generateFactoriesMapWithAll(allClients);

      AppUtils.setAllClients(clientsListMapWithAll);

      for (let i = 0; i < allClients.length; i++) {
        allClients[i].label = allClients[i].name;
        allClients[i].value = allClients[i].client_key;
      }

      let selectedClient = {};
      let selectedClientKey = StorageHandler.getItem(
        StorageHandler.keys.PREFERRED_CLIENT
      );

      try {
        if (
          typeof selectedClientKey != "undefined" &&
          selectedClientKey !== "" &&
          selectedClientKey != null
        ) {
          for (let i = 0; i < allClients.length; i++) {
            if (
              allClients[i]["client_key"] !== null &&
              allClients[i]["client_key"].toString() ==
                selectedClientKey.toString()
            ) {
              selectedClient = allClients[i];
              break;
            }
          }
        }
      } catch (ex) {
        console.log(ex);
      }

      let userAssociatedClients = [];
      if (
        typeof action.payload !== "undefined" &&
        action.payload.user_associated_client_ids &&
        action.payload.user_associated_client_ids.length > 0
      ) {
        userAssociatedClients = allClients.filter((client) =>
          action.payload.user_associated_client_ids.includes(client.client_key)
        );
      }
      if (
        userAssociatedClients &&
        userAssociatedClients.length &&
        typeof selectedClient?.value === "undefined"
      ) {
        selectedClient = userAssociatedClients[0];
      }

      if (typeof selectedClient.value == "undefined") {
        if (allClients.length > 0) {
          selectedClient = allClients[0];
        }
      }

      AppUtils.setFilteredClients(allClients);

      let childClientsList = getClientChildClients(allClients, selectedClient);
      let dropdownChildClients = [];
      let outlineDropdownChildClients = [];
      let dropdownClientAndChildClients = [];
      let dropdownChildWOVOClients = [];
      let activeChildClients = [];
      let activeChildWOVOClients = [];

      if (childClientsList.length > 0) {
        dropdownChildClients = generateClientsMapWithAll(childClientsList);
        outlineDropdownChildClients = generateClientsMapWithAllDicKeys(
          childClientsList
        );

        activeChildClients = generateActiveClientsMap(
          childClientsList.filter((c) => c.is_active == true)
        );
        activeChildWOVOClients = generateActiveClientsMap(
          childClientsList.filter((c) => c.is_active == true),
          true
        );

        let tempList = [];
        tempList.push(selectedClient);
        tempList = tempList.concat(childClientsList);
        dropdownClientAndChildClients = generateClientsMapWithAll(tempList);
        dropdownChildWOVOClients = generateClientsMapWithAll(
          childClientsList,
          true
        );
      }

      let parentClientsList = getClientParentClients(
        allClients,
        selectedClient
      );
      let dropdownClientAndparentClients = [];
      if (parentClientsList.length > 0) {
        let tempList = [];
        tempList.push(selectedClient);
        tempList = tempList.concat(parentClientsList);
        dropdownClientAndparentClients = generateClientsMapWithAll(tempList);
      }

      let isClientIsBrand = AppUtils.isClientIsBrand(selectedClient);
      hideMenuItem(isClientIsBrand);

      AppUtils.setSelectedItem({ ...selectedClient });
      return {
        ...state,
        clientsList: allClients,
        activeClientsList: activeClientsList,
        clientsListMap: clientsListMap,
        clientsListMapWithAll: clientsListMapWithAll,
        selectedClient: { ...selectedClient },
        isClientIsBrand: isClientIsBrand,
        childClientsList: childClientsList,
        dropdownChildClients: dropdownChildClients,
        outlineDropdownChildClients: outlineDropdownChildClients,
        dropdownClientAndChildClients: dropdownClientAndChildClients,
        factoriesListMapWithAll: factoriesListMapWithAll,
        dropdownClientAndParentClients: dropdownClientAndparentClients,
        userAssociatedClients: userAssociatedClients,
        dropdownChildWOVOClients: dropdownChildWOVOClients,
        activeChildClients: activeChildClients,
        activeChildWOVOClients: activeChildWOVOClients,
      };

    case CLIENT_UPDATE_CLIENT_IN_LIST: {
      let client = action.payload.client;
      if (client && typeof client.value == "undefined") {
        client.label = client.name;
        client.value = client.client_key;
      }

      let newClientList = state.clientsList;
      let clientObjIndex = newClientList.findIndex(
        (obj) => obj.client_key == client.client_key
      );
      if (typeof clientObjIndex !== "undefined") {
        newClientList[clientObjIndex] = client;
      }
      let newActiveClientsList = newClientList.filter((x) => x.status == true);

      let newUserClientList = state.userAssociatedClients;
      let userClientObjIndex = newUserClientList.findIndex(
        (obj) => obj.client_key == client.client_key
      );
      if (typeof userClientObjIndex !== "undefined") {
        newUserClientList[userClientObjIndex] = client;
      }

      return {
        ...state,
        clientsList: newClientList,
        activeClientsList: newActiveClientsList,
        userAssociatedClients: newUserClientList,
      };
    }

    case CLIENT_GET_BY_STATUS: {
      let childClients = state.outlineDropdownChildClients;
      let childClientsByStatus = generateChildClientsByStatus(
        action.payload,
        childClients
      );

      return {
        ...state,
        childClientsByStatus: childClientsByStatus,
      };
    }

    default:
      return state;
  }
};

/****
 Util : for generating the client list map
 ****/
function generateClientsMap(data) {
  let result = [];

  try {
    for (let i = 0; i < data.length; i++) {
      result.push({
        key: data[i]["id"],
        value: data[i]["client_key"] ? data[i]["client_key"] : data[i]["id"],
        label: data[i]["name"],
        client_id: data[i]["client_id"],
      });
    }
  } catch (ex) {
    console.error(ex);
  }
  return result;
}

/****
 Util : for generating the client list map for dropdown with all options
 ****/
function generateClientsMapWithAll(data, excludeNonWOVOCompanies = false) {
  let result = [];

  try {
    result.push({
      key: -1,
      value: -1,
      label: <IntlMessages id={"DIC_CLIENTS_DROPDOWN_ALL"} />,
      client_id: -1,
      isAll: true,
    });

    for (let i = 0; i < data.length; i++) {
      if (excludeNonWOVOCompanies) {
        if (!data[i].is_anonymous) {
          result.push({
            key: data[i]["id"],
            value:
              data[i]["client_key"] !== -1
                ? data[i]["client_key"]
                : data[i]["id"],
            label: data[i]["name"],
            client_id: data[i]["client_id"],
          });
        }
      } else {
        result.push({
          key: data[i]["id"],
          value:
            data[i]["client_key"] !== -1
              ? data[i]["client_key"]
              : data[i]["id"],
          label: data[i]["name"],
          client_id: data[i]["client_id"],
        });
      }
    }
  } catch (ex) {
    console.error(ex);
  }
  return result;
}

/****
 Util : for generating active client list map for dropdown with all options
 ****/
function generateActiveClientsMap(data, excludeNonWOVOCompanies = false) {
  let result = [];

  try {
    result.push({
      key: -1,
      value: -1,
      label: <IntlMessages id={"DIC_CLIENTS_DROPDOWN_ALL"} />,
      client_id: -1,
      isAll: true,
    });

    for (let i = 0; i < data.length; i++) {
      if (excludeNonWOVOCompanies) {
        if (!data[i].is_anonymous) {
          result.push({
            key: data[i]["id"],
            value:
              data[i]["client_key"] !== -1
                ? data[i]["client_key"]
                : data[i]["id"],
            label: data[i]["name"],
            client_id: data[i]["client_id"],
          });
        }
      } else {
        result.push({
          key: data[i]["id"],
          value:
            data[i]["client_key"] !== -1
              ? data[i]["client_key"]
              : data[i]["id"],
          label: data[i]["name"],
          client_id: data[i]["client_id"],
        });
      }
    }
  } catch (ex) {
    console.error(ex);
  }
  return result;
}

/****
 Util : for generating the client list map for dropdown with all options
 ****/
function generateClientsMapWithAllDicKeys(data) {
  let result = [];

  try {
    result.push({
      key: "all",
      value: "all",
      label: "DIC_REPORTS_CONNECT_ALL",
      headerValue: "all",
    });

    for (let i = 0; i < data.length; i++) {
      if (data[i].is_anonymous) {
        continue;
      }

      result.push({
        key: data[i]["client_key"],
        value: data[i]["client_key"],
        label: data[i]["name"],
        status: data[i]["status"],
        headerValue: data[i]["client_key"],
      });
    }
  } catch (ex) {
    console.error("generateClientsMapWithAllDicKeys : ", ex);
  }
  return result;
}

/****
 Util : for generating the client list map for dropdown with all options
 ****/
function generateFactoriesMapWithAll(data) {
  let result = [];

  try {
    result.push({
      key: -1,
      value: -1,
      label: <IntlMessages id={"DIC_CLIENTS_DROPDOWN_ALL"} />,
      client_id: -1,
    });

    for (let i = 0; i < data.length; i++) {
      if (!AppUtils.isClientIsBrand(data[i])) {
        result.push({
          key: data[i]["id"],
          value: data[i]["client_key"] ? data[i]["client_key"] : data[i]["id"],
          label: data[i]["name"],
          client_id: data[i]["client_id"],
        });
      }
    }
  } catch (ex) {
    console.error(ex);
  }
  return result;
}

function getClientChildClients(allClients, selectedClient) {
  let result = [];
  try {
    if (typeof selectedClient.relation_mapping != "undefined") {
      const relatedClientIds = selectedClient.relation_mapping
        .filter((item) => item.relation_type === 1)
        .map((item) => item.relation);

      allClients.forEach((item) => {
        if (relatedClientIds.includes(item.id)) {
          result.push(item);
        }
      });
    }
    result = AppUtils.sortJSONListByKey(result, "name");
  } catch (ex) {
    console.log("ReducerClients : getClientChildClients : Error : ");
    console.log(ex);
  }

  return result;
}

function getClientParentClients(allClients, selectedClient) {
  let result = [];
  try {
    if (typeof selectedClient.relation_mapping != "undefined") {
      for (let i = 0; i < selectedClient.relation_mapping.length; i++) {
        for (let j = 0; j < allClients.length; j++) {
          if (
            allClients[j].id === selectedClient.relation_mapping[i].relation &&
            selectedClient.relation_mapping[i].relation_type === 0
          ) {
            result.push(allClients[j]);
            break;
          }
        }
      }
    }
  } catch (ex) {
    console.log("ReducerClients : getClientParentClients : Error : ");
    console.log(ex);
  }

  return result;
}

function isClientIsBrand(client) {
  let result = false;

  try {
    if (
      client != null &&
      typeof client != "undefined" &&
      typeof client.relation_mapping != "undefined" &&
      client.relation_mapping.length > 0
    ) {
      for (let i = 0; i < client.relation_mapping.length; i++) {
        if (client.relation_mapping[i].relation_type == 1) {
          result = true;
          break;
        }
      }
    }
  } catch (ex) {
    console.log("ReducerClients : isClientIsBrand : ERROR : " + ex.toString());
  }

  return result;
}

function hideMenuItem(isClientIsBrand) {
  try {
    let menuItemCompanyPost = document.getElementById("menu_item_company_post");

    if (menuItemCompanyPost != null) {
      if (isClientIsBrand) {
        menuItemCompanyPost.style.display = "none";
      } else {
        menuItemCompanyPost.style.display = "block";
      }
    }
  } catch (ex) {
    console.log("hideMenuItem : error : ", ex);
  }
}

function generateChildClientsByStatus(companyStatus, childClients) {
  let result = [];

  try {
    if (
      typeof companyStatus !== "undefined" &&
      typeof childClients !== "undefined" &&
      childClients.length > 0
    ) {
      result.push({
        key: "all",
        value: "all",
        label: "DIC_REPORTS_CONNECT_ALL",
      });

      for (let i = 0; i < childClients.length; i++) {
        if (
          typeof childClients[i]["status"] !== "undefined" &&
          Number(childClients[i]["status"]).toString() === companyStatus
        ) {
          result.push(childClients[i]);
        }
      }
    }
  } catch (err) {
    console.log("generateChildClientsByStatus : error : ", err);
  }
  return result;
}

export default ReducerClients;
