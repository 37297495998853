import {
    WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE,
    WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS,
    WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR,
    WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE,
    WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST,
    WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS,
    WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR,
    WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE,
    WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL,
    WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS,
    WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR,
    WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR,
    WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS,
    WC_SYNC_MS_SQL_WORKER_CONTACTS,
    WC_GET_PAGINATED_LIST,
    WC_GET_PAGINATED_LIST_SUCCESS,
    WC_GET_PAGINATED_LIST_ERROR,
    WC_GET_PAGINATED_LIST_NONE,
    WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE,
    WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR,
    WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS,
    WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD,
    WC_WORKER_CONTACTS_DOWNLOAD,
    WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS,
    WC_WORKER_CONTACTS_DOWNLOAD_ERROR,
    WC_WORKER_CONTACTS_DOWNLOAD_NONE,
    WC_WORKER_CONTACTS_DEACTIVATE_NONE,
    WC_WORKER_CONTACTS_DEACTIVATE_ERROR,
    WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS,
    WC_WORKER_CONTACTS_DEACTIVATE,
    WC_WORKER_CONTACTS_REACTIVATE_NONE,
    WC_WORKER_CONTACTS_REACTIVATE_ERROR,
    WC_WORKER_CONTACTS_REACTIVATE_SUCCESS,
    WC_WORKER_CONTACTS_REACTIVATE,
    WC_WORKER_CONTACTS_DELETE,
    WC_WORKER_CONTACTS_DELETE_SUCCESS,
    WC_WORKER_CONTACTS_DELETE_ERROR,
    WC_WORKER_CONTACTS_DELETE_NONE,
    WC_WORKER_CONTACTS_GROUPS_GET_LIST,
    WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS,
    WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR,
    WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE,
    WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE,
    WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR,
    WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS,
    WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP,
    WC_WORKER_CONTACTS_GROUPS_FILTER_DATA,
    WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_NONE,
    WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_ERROR,
    WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_SUCCESS,
    WC_WORKER_CONTACTS_GET_CONTACT_DETAILS,
    WC_WORKER_CONTACTS_SET_CURRENT_CONTACT,
    WC_WORKER_CONTACT_SAVE_CONTACT_NONE,
    WC_WORKER_CONTACT_SAVE_CONTACT_ERROR,
    WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS,
    WC_WORKER_CONTACT_SAVE_CONTACT,
    WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST,
    WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS,
    WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR,
    WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE,
    WC_WORKER_CONTACT_GET_CONTACT_NONE,
    WC_WORKER_CONTACT_GET_CONTACT_ERROR,
    WC_WORKER_CONTACT_GET_CONTACT_SUCCESS,
    WC_WORKER_CONTACT_GET_CONTACT,
    WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES,
    WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
    WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
    WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE,
    WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE,
    WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR,
    WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS,
    WC_WORKER_CONTACT_DEACTIVATE_CONTACT,
    WC_WORKER_CONTACT_GROUPS_DEACTIVATE,
    WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS,
    WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR,
    WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE,
    WC_WORKER_CONTACT_GROUPS_REACTIVATE,
    WC_WORKER_CONTACT_GROUPS_REACTIVATE_SUCCESS,
    WC_WORKER_CONTACT_GROUPS_REACTIVATE_ERROR,
    WC_WORKER_CONTACT_GROUPS_REACTIVATE_NONE,
    WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS,
    WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE,
    WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR,
    WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS,
    WC_WORKER_CONTACT_GROUPS_ACTIVATE,
    WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS
} from "../../constants/actions/wc/actionTypesWorkerContacts";

/****
 * worker contacts : download worker contacts upload template
 ****/
export function wcDownloadWorkerContactTemplate(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts upload template : success
 ****/
export function wcDownloadWorkerContactTemplateSuccess(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts upload template : fail
 ****/
export function wcDownloadWorkerContactTemplateError(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts upload template : none
 ****/
export function wcDownloadWorkerContactTemplateNone() {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE
    };
}

/****
 * worker contacts : import list
 ****/
export function wcGetWorkerContactsImportList(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST,
        payload: params
    };
}

/****
 * worker contacts : import list : success
 ****/
export function wcGetWorkerContactsImportListSuccess(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : import list : fail
 ****/
export function wcGetWorkerContactsImportListError(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR,
        payload: params
    };
}

/****
 * worker contacts : import list : none
 ****/
export function wcGetWorkerContactsImportListNone() {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE
    };
}

/****
 * worker contacts : upload contacts excel
 ****/
export function wcGetWorkerContactsUploadExcel(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL,
        payload: params
    };
}

/****
 * worker contacts : upload contacts excel : success
 ****/
export function wcGetWorkerContactsUploadExcelSuccess(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : upload contacts excel : fail
 ****/
export function wcGetWorkerContactsUploadExcelError(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR,
        payload: params
    };
}

/****
 * worker contacts : upload contacts excel : none
 ****/
export function wcGetWorkerContactsUploadExcelNone() {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE
    };
}

/****
 * worker contacts : to sync ms sql worker contacts to postgres
 ****/
export function wcSyncMsSqlWorkerContacts(params) {
    return {
        type: WC_SYNC_MS_SQL_WORKER_CONTACTS,
        payload: params
    };
}

/****
 * worker contacts : to sync ms sql worker contacts to postgres : success
 ****/
export function wcSyncMsSqlWorkerContactsSuccess(params) {
    return {
        type: WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : to sync ms sql worker contacts to postgres : fail
 ****/
export function wcSyncMsSqlWorkerContactsError(params) {
    return {
        type: WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR,
        payload: params
    };
}

/****
 * worker contacts : to sync ms sql worker contacts to postgres : none
 ****/
export function wcSyncMsSqlWorkerContactsNone() {
    return {
        type: WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE
    };
}

/****
 * worker contacts : to get paginated list
 ****/
export function wcGetPaginatedList(params) {
    return {
        type: WC_GET_PAGINATED_LIST,
        payload: params
    };
}

/****
 * worker contacts : to get paginated list : success
 ****/
export function wcGetPaginatedListSuccess(params) {
    return {
        type: WC_GET_PAGINATED_LIST_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : to get paginated list : fail
 ****/
export function wcGetPaginatedListError(params) {
    return {
        type: WC_GET_PAGINATED_LIST_ERROR,
        payload: params
    };
}

/****
 * worker contacts : to get paginated list : none
 ****/
export function wcGetPaginatedListNone() {
    return {
        type: WC_GET_PAGINATED_LIST_NONE
    };
}

/****
 * worker contacts : download worker contacts attachment
 ****/
export function wcDownloadWorkerContactAttachment(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts attachment : success
 ****/
export function wcDownloadWorkerContactAttachmentSuccess(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts attachment : fail
 ****/
export function wcDownloadWorkerContactAttachmentError(params) {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR,
        payload: params
    };
}

/****
 * worker contacts : download worker contacts attachment : none
 ****/
export function wcDownloadWorkerContactAttachmentNone() {
    return {
        type: WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE
    };
}

/****
 * worker contacts : download contacts
 ****/
export function wcWorkerContactDownload(params) {
    return {
        type: WC_WORKER_CONTACTS_DOWNLOAD,
        payload: params
    };
}

/****
 * worker contacts : download contacts : success
 ****/
export function wcWorkerContactDownloadSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : download contacts : fail
 ****/
export function wcWorkerContactDownloadError(params) {
    return {
        type: WC_WORKER_CONTACTS_DOWNLOAD_ERROR,
        payload: params
    };
}

/****
 * worker contacts : download contacts : none
 ****/
export function wcWorkerContactDownloadNone() {
    return {
        type: WC_WORKER_CONTACTS_DOWNLOAD_NONE
    };
}

/****
 * worker contacts : deactivate contacts
 ****/
export function wcWorkerContactDeactivate(params) {
    return {
        type: WC_WORKER_CONTACTS_DEACTIVATE,
        payload: params
    };
}

/****
 * worker contacts : deactivate contacts : success
 ****/
export function wcWorkerContactDeactivateSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : deactivate contacts : fail
 ****/
export function wcWorkerContactDeactivateError(params) {
    return {
        type: WC_WORKER_CONTACTS_DEACTIVATE_ERROR,
        payload: params
    };
}

/****
 * worker contacts : deactivate contacts : none
 ****/
export function wcWorkerContactDeactivateNone() {
    return {
        type: WC_WORKER_CONTACTS_DEACTIVATE_NONE
    };
}
/****
 * worker contacts : reactivate contacts
 ****/
export function wcWorkerContactReactivate(params) {
    return {
        type: WC_WORKER_CONTACTS_REACTIVATE,
        payload: params
    };
}

/****
 * worker contacts : reactivate contacts : success
 ****/
export function wcWorkerContactReactivateSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_REACTIVATE_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : reactivate contacts : fail
 ****/
export function wcWorkerContactReactivateError(params) {
    return {
        type: WC_WORKER_CONTACTS_REACTIVATE_ERROR,
        payload: params
    };
}

/****
 * worker contacts : reactivate contacts : none
 ****/
export function wcWorkerContactReactivateNone() {
    return {
        type: WC_WORKER_CONTACTS_REACTIVATE_NONE
    };
}

/****
 * worker contacts : delete contacts
 ****/
export function wcWorkerContactDelete(params) {
    return {
        type: WC_WORKER_CONTACTS_DELETE,
        payload: params
    };
}

/****
 * worker contacts : delete contacts : success
 ****/
export function wcWorkerContactDeleteSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_DELETE_SUCCESS,
        payload: params
    };
}

/****
 * worker contacts : delete contacts : fail
 ****/
export function wcWorkerContactDeleteError(params) {
    return {
        type: WC_WORKER_CONTACTS_DELETE_ERROR,
        payload: params
    };
}

/****
 * worker contacts : delete contacts : none
 ****/
export function wcWorkerContactDeleteNone() {
    return {
        type: WC_WORKER_CONTACTS_DELETE_NONE
    };
}

/****
 * worker contact groups : get list
 ****/
export function getWCWorkerContactGroupsList(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_LIST,
        payload: params
    };
}

/****
 * worker contact groups : get list : success
 ****/
export function getWCWorkerContactGroupsListSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS,
        payload: params
    };
}

/****
 * worker contact groups : get list : fail
 ****/
export function getWCWorkerContactGroupsListError(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR,
        payload: params
    };
}

/****
 * worker contact groups : get list : none
 ****/
export function getWCWorkerContactGroupsListNone() {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE
    };
}

/****
 * worker contact groups : save group
 ****/
export function saveWCWorkerContactGroup(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP,
        payload: params
    };
}

/****
 * worker contact groups : save group : success
 ****/
export function saveWCWorkerContactGroupSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS,
        payload: params
    };
}

/****
 * worker contact groups : save group : fail
 ****/
export function saveWCWorkerContactGroupError(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR,
        payload: params
    };
}

/****
 * worker contact groups : save group : none
 ****/
export function saveWCWorkerContactGroupNone() {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE
    };
}

/****
 * worker contact groups : filter data
 ****/
export function wcContactGroupsFilter(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_FILTER_DATA,
        payload: params
    };
}

/****
 * worker contact groups : filter data by group status
 ****/
export function wcContactGroupsFilterByGroupStatus(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS,
        payload: params
    };
}

/****
 * worker contact : get details by contact id
 ****/
export function getWCContactDetails(params) {
    return {
        type: WC_WORKER_CONTACTS_GET_CONTACT_DETAILS,
        payload: params
    };
}


/****
 * worker contact : get details by contact id : success
 ****/
export function getWCContactDetailsSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_SUCCESS,
        payload: params
    };
}

/****
 * worker contact : get details by contact id : fail
 ****/
export function getWCContactDetailsError(params) {
    return {
        type: WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_ERROR,
        payload: params
    };
}

/****
 * worker contact : get details by contact id : none
 ****/
export function getWCContactDetailsNone() {
    return {
        type: WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_NONE
    };
}

/****
 * worker contact : set details
 ****/
export function setWCContactDetails(params) {
    return {
        type: WC_WORKER_CONTACTS_SET_CURRENT_CONTACT,
        payload: params
    };
}


/****
 * worker contact : save contact
 ****/
export function saveWCWorkerContact(params) {
    return {
        type: WC_WORKER_CONTACT_SAVE_CONTACT,
        payload: params
    };
}

/****
 * worker contact : save contact : success
 ****/
export function saveWCWorkerContactSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS,
        payload: params
    };
}

/****
 * worker contact : save contact : fail
 ****/
export function saveWCWorkerContactError(params) {
    return {
        type: WC_WORKER_CONTACT_SAVE_CONTACT_ERROR,
        payload: params
    };
}

/****
 * worker contact : save contact : none
 ****/
export function saveWCWorkerContactNone() {
    return {
        type: WC_WORKER_CONTACT_SAVE_CONTACT_NONE
    };
}

/****
 * worker contact : contact groups : active list
 ****/
export function getWorkerContactActiveGroupList(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST,
        payload: params
    };
}

/****
 * worker contact : contact groups : active list : success
 ****/
export function getWorkerContactActiveGroupListSuccess(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS,
        payload: params
    };
}

/****
 * worker contact : contact groups : active list : fail
 ****/
export function getWorkerContactActiveGroupListError(params) {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR,
        payload: params
    };
}

/****
 * worker contact : contact groups : active list : none
 ****/
export function getWorkerContactActiveGroupListNone() {
    return {
        type: WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE
    };
}


/****
 * worker contact : to get contact by id
 ****/
export function getWorkerContact(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CONTACT,
        payload: params
    };
}

/****
 * worker contact : to get contact by id : success
 ****/
export function getWorkerContactSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CONTACT_SUCCESS,
        payload: params
    };
}

/****
 * worker contact : to get contact by id : fail
 ****/
export function getWorkerContactError(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CONTACT_ERROR,
        payload: params
    };
}

/****
 * worker contact : to get contact by id : none
 ****/
export function getWorkerContactNone() {
    return {
        type: WC_WORKER_CONTACT_GET_CONTACT_NONE
    };
}

/****
 * worker contact : to get custom fields with values
 ****/
export function getWorkerContactCustomFieldsWithValues(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES,
        payload: params
    };
}

/****
 * worker contact : to get contact by id : success
 ****/
export function getWorkerContactCustomFieldsWithValuesSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
        payload: params
    };
}
/****
 * worker contact : to get contact by id : fail
 ****/
export function getWorkerContactCustomFieldsWithValuesError(params) {
    return {
        type: WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
        payload: params
    };
}
/****
 * worker contact : to get contact by id : none
 ****/
export function getWorkerContactCustomFieldsWithValuesNone() {
    return {
        type: WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE
    };
}
/****
 * worker contact : to deactivate
 ****/
export function deactivateWorkerContact(params) {
    return {
        type: WC_WORKER_CONTACT_DEACTIVATE_CONTACT,
        payload: params
    };
}

/****
 * worker contact : to deactivate : success
 ****/
export function deactivateWorkerContactSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS,
        payload: params
    };
}

/****
 * worker contact : to deactivate : fail
 ****/
export function deactivateWorkerContactError(params) {
    return {
        type: WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR,
        payload: params
    };
}

/****
 * worker contact : to deactivate : none
 ****/
export function deactivateWorkerContactNone() {
    return {
        type: WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE
    };
}

/****
 * worker contact groups : deactivate groups
 ****/
export function wcWorkerContactGroupsDeactivate(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_DEACTIVATE,
        payload: params
    };
}

/****
 * worker contact groups : deactivate groups : success
 ****/
export function wcWorkerContactGroupsDeactivateSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS,
        payload: params
    };
}

/****
 * worker contact groups : deactivate groups : fail
 ****/
export function wcWorkerContactGroupsDeactivateError(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR,
        payload: params
    };
}

/****
 * worker contact groups : deactivate groups : none
 ****/
export function wcWorkerContactGroupsDeactivateNone() {
    return {
        type: WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE
    };
}

/****
 * worker contact groups : activate groups
 ****/
export function wcWorkerContactGroupsActivate(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_ACTIVATE,
        payload: params
    };
}

/****
 * worker contact groups : activate groups : success
 ****/
export function wcWorkerContactGroupsActivateSuccess(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS,
        payload: params
    };
}

/****
 * worker contact groups : activate groups : fail
 ****/
export function wcWorkerContactGroupsActivateError(params) {
    return {
        type: WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR,
        payload: params
    };
}

/****
 * worker contact groups : activate groups : none
 ****/
export function wcWorkerContactGroupsActivateNone() {
    return {
        type: WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE
    };
}


/****
 * worker contact list : to update filter translations
 ****/
export function updateWCListFilterTranslations() {
    return {
        type: WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS
    };
}
