import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    TRANSLATION_CATEGORY_GET_ALL_RECORDS,
    TRANSLATION_CATEGORY_NEW_EDIT_SAVE,
    TRANSLATION_CATEGORY_UPDATE_STATUS
} from "../../../../constants/ActionTypes";

import {
    getTranslationCategorySuccess,
    saveTranslationCategoryError,
    saveTranslationCategorySuccess,
    translationCategoryUpdateStatusSuccess
} from "../../../../actions/adminSettings/translations/categories/ActionsTranslationsCategories";

import {
    URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_CREATE,
    URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_LIST
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";

/************ methods : http *******************/

/**** Https : Translation Category Save ****/
const httpSaveTranslationCategory = async (data) =>
    await axios.post(baseUrl + URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : Translation Category update ****/
const httpUpdateTranslationCategory = async (data) =>
    await axios.patch(baseUrl + URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Translation Category : Get List ****/
const httpGetTranslationCategory = async (data) =>
    await axios.get(baseUrl + URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)


/************ methods : http response handler *******************/

/** http : Translation Category save/Edit : http response handler **/

function* handleHttpSaveTranslationCategory({payload}) {
    try {
        let dataValue = yield call(payload.id && payload.id !== "" && payload.id !== -1 ? httpUpdateTranslationCategory : httpSaveTranslationCategory, payload)
        if (dataValue.message) {
            yield put(saveTranslationCategoryError(dataValue.message))
        } else {
            yield put(saveTranslationCategorySuccess(dataValue))
        }
    } catch (error) {
        yield put(saveTranslationCategoryError(error))
    }
}

/** http : Translation Category : http response handler **/

function* handleHttpGetAllTranslationCategory() {
    try {
        let dataValue = yield call(httpGetTranslationCategory)
        if (dataValue.message) {
            yield put(saveTranslationCategoryError(dataValue.message))
        } else {
            yield put(getTranslationCategorySuccess(dataValue.data));
        }
    } catch (error) {
        yield put(saveTranslationCategoryError(error))
    }
}

/** http : Translation Category update status : http response handler **/

function* handleHttpUpdateTranslationCategoryStatus({payload}) {
    try {
        let dataValue = yield call(httpUpdateTranslationCategory, payload);
        if (dataValue.message) {
            yield put(saveTranslationCategoryError(dataValue.message));
        } else {
            yield put(translationCategoryUpdateStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveTranslationCategoryError(error));
    }
}

/************ methods : export access *******************/

/**** Listeners : Translation Category save ****/
export function* saveTranslationCategory() {
    yield takeEvery(TRANSLATION_CATEGORY_NEW_EDIT_SAVE, handleHttpSaveTranslationCategory)
}

/**** Listeners : Translation Category Get ****/
export function* getTranslationCategoryList() {
    yield takeEvery(TRANSLATION_CATEGORY_GET_ALL_RECORDS, handleHttpGetAllTranslationCategory)
}

/**** Listeners : Translation Category Update Status ****/
export function* updateTranslationCategoryStatus() {
    yield takeEvery(TRANSLATION_CATEGORY_UPDATE_STATUS, handleHttpUpdateTranslationCategoryStatus)
}

export default function* rootSaga() {
    yield all([
        fork(saveTranslationCategory),
        fork(getTranslationCategoryList),
        fork(updateTranslationCategoryStatus)
    ])
}