import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    getSurveyHistoryListSuccess,
    getSurveyHistoryListError,
} from "../../../actions/survey/survey/ActionsSurveyViewHistory";
import axios from "axios";
import {URL_SURVEY_VIEW_HISTORY} from "constants/Urls";
import { baseUrl } from "util/keys";
import AppUtils from "../../../util/util";
import { HTTP_STATUS_CODE } from "constants/config";
import {SURVEY_HISTORY_GET_LIST} from "../../../constants/actions/survey/survey/actioinTypesSurveyHistory";

/************ methods : http *******************/

/** http : Survey View History : To get survey history detail list **/

const httpGetSurveyHistoryList = async (url) => {
    return await axios.get(url, AppUtils.getHttpHeaders())
        .then(response => response)
        .catch(error => error);
};

/************ methods : http response handler *******************/

/** http : Survey View History : http get survey history list handler **/

function* handleHttpGetSurveyHistoryList(payload) {
    try {
        const { client_id, survey_id, sortBy } = payload.payload;
        let url = baseUrl + URL_SURVEY_VIEW_HISTORY + `?client_id=${client_id}&survey_id=${survey_id}`;
        if (sortBy) {
            url = url + (sortBy === 'asc' ? '&o=created_date' : '&o=-created_date')
        }
        let response = yield call(httpGetSurveyHistoryList, url);
        if (typeof response.status !== 'undefined' &&
            (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
            yield put(getSurveyHistoryListSuccess(response.data));
        } else {
            yield put(getSurveyHistoryListError(response.message));
        }
    } catch (error) {
        yield put(getSurveyHistoryListError(error))
    }
}


/************ methods : export access *******************/

export function* getSurveyHistoryList() {
    yield takeEvery(SURVEY_HISTORY_GET_LIST, handleHttpGetSurveyHistoryList);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveyHistoryList)
    ])
}