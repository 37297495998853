import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import AppUtils from "../../../../util/util";
import {
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE,

} from "../../../../constants/ActionTypes";
import {UUID_STATUS} from "../../../../constants/config";


const initialData = {
    loader: false,
    alertMessageRawData: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    connectRawDataResponseDownloadLink: "",
    rawDataDownloadUUID: "",
    rawDataDownloadUUIDCounter: 0,

}

const ReducerConnectReportsRawdata = (state = initialData, action) => {

    switch (action.type) {

        /**** connect raw data : Download rawdata none *****/
        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE:
            return {
                ...state,
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                connectRawDataResponseDownloadLink: "",
                loader: false
            };

        /**** connect raw data : Download rawdata error *****/
        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR:
            return {
                ...state,
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessageRawData: AppUtils.getErrorStringFromResponse(action.payload),
                loader: false
            };

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID:
            return {
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                connectRawDataResponseDownloadLink: "",
                rawDataDownloadUUID: "",
                loader: true,
                rawDataDownloadUUIDCounter: 0
            }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS: {
            let status = action.payload.status;
            let connectRawDataResponseDownloadLink = "";
            let rawDataDownloadUUID = "";
            let rawDataDownloadUUIDCounter = state.rawDataDownloadUUIDCounter;

            switch (status) {
                case UUID_STATUS.SUCCESS: {
                    connectRawDataResponseDownloadLink = AppUtils.redirectDownloadProdToReports(AppUtils.getAppBaseUrl() + action.payload.data.data);

                    rawDataDownloadUUID = "";
                    break
                }
                case UUID_STATUS.IN_PROGRESS: {
                    rawDataDownloadUUID = action.payload.uuid;
                    rawDataDownloadUUIDCounter = 1;
                    break
                }
                case UUID_STATUS.NOT_FOUND: {
                    rawDataDownloadUUID = "";
                    connectRawDataResponseDownloadLink = "";
                    break
                }
            }

            return {
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                connectRawDataResponseDownloadLink: connectRawDataResponseDownloadLink,
                rawDataDownloadUUID: rawDataDownloadUUID,
                rawDataDownloadUUIDCounter: rawDataDownloadUUIDCounter

            }
        }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR:

            return {
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                connectRawDataResponseDownloadLink: "",
                rawDataDownloadUUID: "",
                rawDataDownloadUUIDCounter: 0
            }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE:
            return {
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                connectRawDataResponseDownloadLink: "",
                rawDataDownloadUUID: "",
                rawDataDownloadUUIDCounter: 0
            }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS:
            let rawDataDownloadUUIDCounter = state.rawDataDownloadUUIDCounter;

            return {
                rawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                rawDataDownloadUUIDCounter: rawDataDownloadUUIDCounter
            }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS:{
            let status = action.payload.status;
            let connectRawDataResponseDownloadLink = state.connectRawDataResponseDownloadLink;
            let rawDataDownloadUUID = state.rawDataDownloadUUID;
            let rawDataDownloadUUIDCounter = state.rawDataDownloadUUIDCounter;

            switch (status) {
                case UUID_STATUS.SUCCESS: {
                    connectRawDataResponseDownloadLink = AppUtils.redirectDownloadProdToReports(AppUtils.getAppBaseUrl() + action.payload.data.data);

                    rawDataDownloadUUID = "";
                    break
                }
                case UUID_STATUS.IN_PROGRESS: {
                    rawDataDownloadUUID = action.payload.uuid;
                    rawDataDownloadUUIDCounter += 1;
                    break
                }
                case UUID_STATUS.NOT_FOUND: {
                    rawDataDownloadUUID = "";
                    connectRawDataResponseDownloadLink = "";
                    break
                }
            }

            return {
                rawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.SUCCESS,
                connectRawDataResponseDownloadLink: connectRawDataResponseDownloadLink,
                rawDataDownloadUUID: rawDataDownloadUUID,
                rawDataDownloadUUIDCounter: rawDataDownloadUUIDCounter
            }

        }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR:

            return {
                rawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.FAIL,
                rawDataDownloadUUID: "",
                rawDataDownloadUUIDCounter: 0
            }

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE:

            return {
                rawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                rawDataDownloadUUID: "",
                rawDataDownloadUUIDCounter: 0
            }

        /**** connect raw data : Download rawdata in progress *****/

        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS:
            return {
                ...state,
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                connectRawDataResponseDownloadLink: "",
                loader: true
            };


        /**** connect raw data : Download raw data : success ****/
        case CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS:
            let connectRawDataResponseDownloadLink = action.payload;
            if (action.payload && typeof action.payload != "undefined") {
                connectRawDataResponseDownloadLink = AppUtils.getAppBaseUrl() + action.payload;
                AppUtils.downloadFile(connectRawDataResponseDownloadLink)
            }

            return {
                ...state,
                connectRawDataResponseDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                alertMessageRawData: action.payload.message,
                connectRawDataResponseDownloadLink: ""
            };

        default:
            return state;
    }
}

export default ReducerConnectReportsRawdata;
