import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
	BELOW_THE_HEADER,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	HORIZONTAL_NAVIGATION,
	INSIDE_THE_HEADER,
} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import MailNotification from '../MailNotification/index';
import AppNotification from '../AppNotification/index';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import AppCache from '../../util/AppCache';
import InputLabel from '@material-ui/core/InputLabel';
import IntegrationAutoSuggest from '../../app/routes/company/list/IntegrationAutoSuggest';
import IntegrationReactSelect from '../custom/IntegrationReactSelect';
import CustomScrollbars from '../../util/CustomScrollbars';
import AppUtils from '../../util/util';
import { LOGO_BACKGROUND } from '../../constants/customUIConfig';
import { getClientsList, setSelectedClient } from '../../actions';
import ReducerClients from '../../reducers/clients/ReducerClients';
import StorageHandler from '../../util/storageHandler';
import {
	updateUserPreferredClient,
	updateUserPreferredClientInProgress,
	updateUserPreferredClientNone,
} from '../../actions/adminSettings/user/settings/actionsUserSettings';
import { WOVO_USER_ROLES } from '../../constants/userRoles';

class Header extends React.Component {
	onAppNotificationSelect = () => {
		this.setState({
			appNotification: !this.state.appNotification,
		});
	};
	onMailNotificationSelect = () => {
		this.setState({
			mailNotification: !this.state.mailNotification,
		});
	};
	onLangSwitcherSelect = (event) => {
		this.setState({
			langSwitcher: !this.state.langSwitcher,
			anchorEl: event.currentTarget,
		});
	};
	onSearchBoxSelect = () => {
		this.setState({
			searchBox: !this.state.searchBox,
		});
	};
	onClientSelect = () => {
		this.setState({
			isClientsOpen: !this.state.isClientsOpen,
		});
	};
	onUserInfoSelect = () => {
		this.setState({
			userInfo: !this.state.userInfo,
		});
	};
	handleRequestClose = () => {
		this.setState({
			langSwitcher: false,
			userInfo: false,
			mailNotification: false,
			appNotification: false,
			searchBox: false,
			apps: false,
		});
	};
	onToggleCollapsedNav = (e) => {
		const val = !this.props.navCollapsed;
		this.props.toggleCollapsedNav(val);
	};

	constructor() {
		super();
		this.state = {
			anchorEl: undefined,
			searchBox: false,
			searchText: '',
			mailNotification: false,
			userInfo: false,
			langSwitcher: false,
			appNotification: false,
			clients: [],
			selectedClient: {},
			isClientsOpen: false,
		};
	}

	/** mount event **/
	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		this.props.getClientsList();
	};

	updateSearchText(evt) {
		this.setState({
			searchText: evt.target.value,
		});
	}

	selectClient = (item) => {
		try {
			this.props.setSelectedClient({
				client: item,
			});

			AppUtils.setSelectedItem(item);

			this.setState({
				isClientsOpen: false,
			});

			let params = {
				preferred_client: item['client_key'],
			};

			//this.props.updateUserPreferredClientInProgress();
			this.props.updateUserPreferredClient(params);
		} catch (ex) {
			console.error('selectClient : ERROR : ', ex);
		}
	};

	renderClients = () => {
		const { clientsList, selectedClient } = this.props;

		return (
			<ul className="list-unstyled">
				{clientsList.map((item, index) => (
					<div
						className="media-body align-self-center p-2"
						key={item['client_key']}
						onClick={() => this.selectClient(item)}
					>
						{selectedClient['client_key'] === item['client_key'] ? (
							<p className="heading font-weight-bolder">{item.name}</p>
						) : (
							<p className="heading">{item.name}</p>
						)}
					</div>
				))}
			</ul>
		);
	};

	renderClientContainer = () => {
		const {
			selectedClient,
			clientsList,
			userAssociatedClients,
			currentUserRole,
		} = this.props;

		let userClientList = clientsList;
		if (currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER) {
			userClientList = userAssociatedClients;
		}

		return (
			<li
				className="list-inline-item"
				style={{ width: 'auto', minWidth: '25rem' }}
			>
				<IntegrationReactSelect
					items={userClientList}
					onChange={(key, value) => {
						this.selectClient(value);
					}}
					content_id={'name'}
					defaultValue={selectedClient.value ? selectedClient.value : -1}
				/>
				{/*<Dropdown
                    className="quick-menu app-notification"
                    isOpen={isClientsOpen}
                    toggle={this.onClientSelect.bind(this)}>

                    <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown">
                            <span className="app-notification-menu">
                                <i className="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"/>
                                <label className="pl-3 pt-1">{selectedClient.name ? "  " + selectedClient.name : ""}
                                </label>
                            </span>
                    </DropdownToggle>

                    <DropdownMenu>
                        <CustomScrollbars className="messages-list scrollbar" style={{minHeight: "20rem", maxHeight: "40rem"}}>
                            {this.renderClients()}
                        </CustomScrollbars>
                    </DropdownMenu>
                </Dropdown>*/}
			</li>
		);
	};

	render() {
		const {
			drawerType,
			locale,
			navigationStyle,
			horizontalNavPosition,
		} = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? 'd-block d-xl-none'
			: drawerType.includes(COLLAPSED_DRAWER)
			? 'd-block'
			: 'd-none';

		const { user } = this.props;

		return typeof user == 'undefined' || typeof user.email == 'undefined' ? (
			<Redirect to={'/signin'} />
		) : (
			<AppBar
				className={`app-main-header ${
					navigationStyle === HORIZONTAL_NAVIGATION &&
					horizontalNavPosition === BELOW_THE_HEADER
						? 'app-main-header-top'
						: ''
				}`}
			>
				<Toolbar className="app-toolbar" disableGutters={false}>
					{navigationStyle === HORIZONTAL_NAVIGATION ? (
						<div
							className="d-block d-md-none pointer mr-3"
							onClick={this.onToggleCollapsedNav}
						>
							<span className="jr-menu-icon">
								<span className="menu-icon" />
							</span>
						</div>
					) : (
						<IconButton
							className={`jr-menu-icon mr-3 ${drawerStyle}`}
							aria-label="Menu"
							onClick={this.onToggleCollapsedNav}
						>
							<span className="menu-icon" />
						</IconButton>
					)}

					<Link className="app-logo mr-2 d-none d-sm-block" to="/">
						<Avatar className="size-60" style={{ background: LOGO_BACKGROUND }}>
							<img
								src={require('assets/images/wovo.svg')}
								alt="Wovo"
								title="Wovo"
							/>
						</Avatar>
					</Link>

					{navigationStyle === HORIZONTAL_NAVIGATION &&
						horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

					<ul className="header-notifications list-inline ml-auto">
						{this.renderClientContainer()}

						{/*<li className="list-inline-item app-tour">
                                <Dropdown
                                    className="quick-menu"
                                    isOpen={this.state.appNotification}
                                    toggle={this.onAppNotificationSelect.bind(this)}>

                                    <DropdownToggle
                                        className="d-inline-block"
                                        tag="span"
                                        data-toggle="dropdown">

                                        <IconButton className="icon-btn">
                                            <i className="zmdi zmdi-notifications-none icon-alert animated infinite"/>
                                        </IconButton>

                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <CardHeader styleName="align-items-center" heading={<IntlMessages id="appNotification.title"/>}/>
                                        <AppNotification/>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>*/}

						{navigationStyle === HORIZONTAL_NAVIGATION && (
							<li className="list-inline-item user-nav">
								<Dropdown
									className="quick-menu"
									isOpen={this.state.userInfo}
									toggle={this.onUserInfoSelect.bind(this)}
								>
									<DropdownToggle
										className="d-inline-block"
										tag="span"
										data-toggle="dropdown"
									>
										<IconButton className="icon-btn size-30">
											<Avatar
												alt="..."
												src={'https://via.placeholder.com/150x150'}
												className="size-30"
											/>
										</IconButton>
									</DropdownToggle>

									<DropdownMenu right>
										<UserInfoPopup />
									</DropdownMenu>
								</Dropdown>
							</li>
						)}
					</ul>

					<div className="ellipse-shape"></div>
				</Toolbar>
			</AppBar>
		);
	}
}

const mapStateToProps = ({ settings, auth, ReducerClients }) => {
	const {
		drawerType,
		locale,
		navigationStyle,
		horizontalNavPosition,
	} = settings;

	const { user, currentUserRole } = auth;

	const {
		selectedClient,
		clientsList,
		clientsListMap,
		userAssociatedClients,
	} = ReducerClients;

	return {
		drawerType,
		locale,
		navigationStyle,
		horizontalNavPosition,
		user,
		clientsList,
		selectedClient,
		clientsListMap,
		userAssociatedClients,
		currentUserRole,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		toggleCollapsedNav,
		switchLanguage,
		getClientsList,
		setSelectedClient,
		updateUserPreferredClient,
		updateUserPreferredClientInProgress,
		updateUserPreferredClientNone,
	})(Header, ReducerClients)
);
