
import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_GET_CASE_STATUS
} from "../../../constants/Urls";
import {
    CONNECT_API_GET_CASE_STATUS_REQUEST,
    CONNECT_API_GET_CASE_STATUS_SUCCESS,
    CONNECT_API_GET_CASE_STATUS_FAILURE
} from '../../../constants/ActionTypes';

// function that makes the api request and returns a Promise for response
const fetchConnectCaseStatus = async ({payload}) =>
    await axios.post(baseUrl+URL_CONNECT_GET_CASE_STATUS, payload, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => error);


// worker saga: makes the api call when watcher saga sees the action
export function* connectGetCaseStatusSaga(payload) {
    try {
      const response = yield call(fetchConnectCaseStatus, payload);
      const {
          data,
          message
      } = response;
      if(data) {
        yield put({ type: CONNECT_API_GET_CASE_STATUS_SUCCESS, caseStatus:data });
      }
      if(message) {
        yield put({ type: CONNECT_API_GET_CASE_STATUS_FAILURE, error:message });
      }

    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: CONNECT_API_GET_CASE_STATUS_FAILURE, error });
    }
  }

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getCaseStatus() {
    yield takeEvery(CONNECT_API_GET_CASE_STATUS_REQUEST, connectGetCaseStatusSaga);
}

