import {
    E_LEARNING_REPORTS_ENROLMENT_GENERAL,
    E_LEARNING_REPORTS_ENROLMENT_TOPICS,
    E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
    E_LEARNING_REPORTS_ENROLMENT_STATUSES,
    E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN,
    E_LEARNING_REPORTS_ENROLMENT_COURSES,

    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY,
    E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY,

    E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT,


    E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES,
    E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS,

    E_LEARNING_REPORTS_COMPARISION_OVERTIME,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_GENERAL,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT,

    E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED,
    E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS

} from "../constants/ActionTypes";

/**** E Learning Reports : Comparision ratio : selected summarised by option ****/
export function setSummarisedByOption( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED,
        payload : params
    };
}

/**** E Learning Reports : Comparision ratio : selected summarised by option reset ****/
export function setSummarisedByOptionReset() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET
    };
}

/**** E Learning Reports : Comparision :  quiz score ****/
export function getELearningReportsComparisionQuizScore( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE,
        payload : params
    };
}

/**** E Learning Reports : Comparision :  quiz score success ****/
export function getELearningReportsComparisionQuizScoreSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Comparision :  content avg score ****/
export function getELearningReportsComparisionContentAvgScore( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE,
        payload : params
    };
}

/**** E Learning Reports : Comparision :  content avg score success ****/
export function getELearningReportsComparisionContentAvgScoreSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Comparision :  general ****/
export function getELearningReportsComparisionGeneral( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_GENERAL,
        payload : params
    };
}

/**** E Learning Reports : Comparision :  general success ****/
export function getELearningReportsComparisionGeneralSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Comparision :  Over time ****/
export function getELearningReportsComparisionOverTime( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_OVERTIME,
        payload : params
    };
}

/**** E Learning Reports : Comparision :  Over time success ****/
export function getELearningReportsComparisionOverTimeSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Comparision Ratio :  users ****/
export function getELearningReportsComparisionRatioUsers( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_USERS,
        payload : params
    };
}

/**** E Learning Reports : Comparision Ratio :  users success ****/
export function getELearningReportsComparisionRatioUsersSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS,
        payload: data
    };
}


/**** E Learning Reports : Comparision Ratio :  users uuid data  ****/
export function getELearningReportsComparisionRatioUsersUUIDData( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA ,
        payload : params
    };
}

/**** E Learning Reports : Comparision Ratio :  users uuid data success ****/
export function getELearningReportsComparisionRatioUsersUUIDDataSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS,
        payload: data
    };
}


/**** E Learning Reports : Comparision Ratio :  Courses ****/
export function getELearningReportsComparisionRatioCourses( params ) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES,
        payload : params
    };
}

/**** E Learning Reports : Comparision Ratio :  Courses success ****/
export function getELearningReportsComparisionRatioCoursesSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Enrolment :  General ****/
export function getELearningReportsEnrolmentGeneral( params ) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_GENERAL,
        payload : params
    };
}

/**** E Learning Reports : Enrolment :  General ****/
export function getELearningReportsEnrolmentGeneralSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Enrolment :  Topics ****/
export function getELearningReportsEnrolmentTopics(params) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_TOPICS,
        payload : params
    };
}

/**** E Learning Reports : Enrolment :  Topics ****/
export function getELearningReportsEnrolmentTopicsSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS,
        payload: data
    };
}

/**** E Learning Reports : Enrolment :  Proportion ****/
export function getELearningReportsEnrolmentProportion(params) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
        payload : params
    };
}

/**** E Learning Reports : Enrolment : Success :  Proportion ****/
export function getELearningReportsEnrolmentProportionSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : Enrolment :  Statuses ****/
export function getELearningReportsEnrolmentStatuses(params) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_STATUSES,
        payload : params
    };
}

/**** E Learning Reports : Enrolment : Success :  Statuses ****/
export function getELearningReportsEnrolmentStatusesSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : Enrolment :  Breakdown ****/
export function getELearningReportsEnrolmentBreakdown(params) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN,
        payload : params
    };
}

/**** E Learning Reports : Enrolment : success :  Breakdown ****/
export function getELearningReportsEnrolmentBreakdownSuccess( data ) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : Enrolment :  Courses ****/
export function getELearningReportsEnrolmentCourses(params) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_COURSES,
        payload : params
    };
}

/**** E Learning Reports : Enrolment : success :  Courses ****/
export function getELearningReportsEnrolmentCoursesSuccess( data ) {
    return {
        type: E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : User Activity :  Completion Ratio ****/
export function getELearningReportsUserActivityCompletionRatio(params) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
        payload : params
    };
}

/**** E Learning Reports : User Activity : Success :  Completion Ratio ****/
export function getELearningReportsUserActivityCompletionRatioSuccess( data ) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS,
        payload  : data
    };
}

/**** E Learning Reports : User Activity :  Quiz Score ****/
export function getELearningReportsUserActivityQuizScore(params) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
        payload : params
    };
}

/**** E Learning Reports : User Activity : success :  Quiz Score ****/
export function getELearningReportsUserActivityQuizScoreSuccess(data) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : User Activity :  Content Popularity ****/
export function getELearningReportsUserActivityContentPopularity(params) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY,
        payload : params
    };
}

/**** E Learning Reports : User Activity : success :  Content Popularity ****/
export function getELearningReportsUserActivityContentPopularitySuccess(data) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : User Activity :  Difficulty ****/
export function getELearningReportsUserActivityDifficulty(params) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY,
        payload : params
    };
}

/**** E Learning Reports : User Activity : Success :  Difficulty ****/
export function getELearningReportsUserActivityDifficultySuccess(data) {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS,
        payload : data
    };
}

/**** E Learning Reports : User Activity : drilling action : completion ration : increment ****/
export function triggerUserActivityCompletionRatioDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : User Activity : drilling action : completion ration : decrement ****/
export function triggerUserActivityCompletionRatioDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT
    };
}

/**** E Learning Reports : User Activity : drilling action : quiz score : increment ****/
export function triggerUserActivityQuizDistributionRatioDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : User Activity : drilling action : quiz score : decrement ****/
export function triggerUserActivityQuizDistributionRatioDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT
    };
}


/**** E Learning Reports : User Activity : drilling action : quiz score : increment ****/
export function triggerUserActivityContentDifficultyDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : User Activity : drilling action : quiz score : decrement ****/
export function triggerUserActivityContentDifficultyDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT
    };
}

/**** E Learning Reports : comparison : drilling action : over time : increment ****/
export function triggerComparisonOvertimeDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : comparison : drilling action : over time : decrement ****/
export function triggerComparisonOvertimeDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT
    };
}

/**** E Learning Reports : comparison : drilling action : content avg score : increment ****/
export function triggerComparisonContentAvgScoreDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : comparison : drilling action :  content avg score : decrement ****/
export function triggerComparisonContentAvgScoreDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT
    };
}


/**** E Learning Reports : comparison : drilling action : quiz score : increment ****/
export function triggerComparisonQuizScoreDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : comparison : drilling action :  quiz score : decrement ****/
export function triggerComparisonQuizScoreDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT
    };
}

/**** E Learning Reports : comparison : drilling action : general : increment ****/
export function triggerComparisonGeneralDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : comparison : drilling action :  general : decrement ****/
export function triggerComparisonGeneralDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT
    };
}

/**** E Learning Reports : comparison ratio : drilling action : general : increment ****/
export function triggerComparisonRatioDrillingIncrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT
    };
}

/**** E Learning Reports : comparison ratio: drilling action :  general : decrement ****/
export function triggerComparisonRatioDrillingDecrement() {
    return {
        type: E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT
    };
}



export const showErrorMessage = (val) => {
    return {
        type: "",
        payload: val
    };
};