import {
    ACCOUNT_MANAGERS_SAVE,
    ACCOUNT_MANAGERS_SAVE_SUCCESS,
    ACCOUNT_MANAGERS_SAVE_IN_PROGRESS,
    ACCOUNT_MANAGERS_SAVE_NONE,
    ACCOUNT_MANAGERS_GET_LIST,
    ACCOUNT_MANAGERS_GET_LIST_SUCCESS,
    ACCOUNT_MANAGERS_DELETE,
    ACCOUNT_MANAGERS_DELETE_NONE,
    ACCOUNT_MANAGERS_EDIT,
    ACCOUNT_MANAGERS_SET_CURRENT_RECORD, ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS
}

    from "../../../constants/ActionTypes";


/****
 Account Managers : To get all saved record
 ****/
export function setCurrentRecord(params) {
    return {
        type: ACCOUNT_MANAGERS_SET_CURRENT_RECORD,
        payload: params
    };
}

/****
Account Managers : To get all record
 ****/
export function getAccountManagers() {
    return {
        type: ACCOUNT_MANAGERS_GET_LIST
    };
}

/****
 Account Managers : To get all record : in progress
 ****/
export function getAccountManagersInProgress() {
    return {
        type: ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS
    };
}



/****
Account Managers : To get all Account Managers success
 ****/
export function getAccountManagersSuccess(data) {
    return {
        type: ACCOUNT_MANAGERS_GET_LIST_SUCCESS,
        payload: data
    };
}


/**** ReducerAccountManagers  : save ****/
export function saveAccountManagers(params) {
    return {
        type: ACCOUNT_MANAGERS_SAVE,
        payload: params
    };
}

/**** ReducerAccountManagers  : save success****/
export function saveAccountManagersSuccess(data) {
    return {
        type: ACCOUNT_MANAGERS_SAVE_SUCCESS,
        payload: data
    };
}


/**** ReducerAccountManagers : save in progress action ****/
export function saveAccountManagersInProgress() {
    return {
        type: ACCOUNT_MANAGERS_SAVE_IN_PROGRESS
    };
}

/**** ReducerAccountManagers: reset progress action ****/
export function saveAccountManagersNone() {
    return {
        type: ACCOUNT_MANAGERS_SAVE_NONE
    };
}

/**** ReducerAccountManagers : delete ****/

export function deleteAccountManagers(param) {
    return {
        type: ACCOUNT_MANAGERS_DELETE,
        payload: param
    }
}



/*** To reset the progress *****/

export function deleteAccountManagersNone() {
    return {
        type: ACCOUNT_MANAGERS_DELETE_NONE
    }

}

/*** To edit the Account Manager Record */

export function EditAccountManager(params) {
    return {
        type: ACCOUNT_MANAGERS_EDIT,
        payload: params
    }
}

export const showErrorMessage = (val) => {
    return {
        type: "",
        payload: val
    };
};
