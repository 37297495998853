import{
    TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP,
    TRANSLATION_SUBMODULE_GET_ALL_RECORDS,
    TRANSLATION_SUBMODULE_GET_ALL_RECORDS_ERROR,
    TRANSLATION_SUBMODULE_GET_ALL_RECORDS_IN_PROGRESS,
    TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS,
    TRANSLATION_SUBMODULE_UPDATE_STATUS,
    TRANSLATION_SUBMODULE_UPDATE_STATUS_ERROR,
    TRANSLATION_SUBMODULE_UPDATE_STATUS_IN_PROGRESS,
    TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE,
    TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS
} from "../../../../constants/ActionTypes";

/****
 Translation Submodule : to get all records
 ****/
export function getTranslationSubmodule() {
    return {
        type: TRANSLATION_SUBMODULE_GET_ALL_RECORDS
    };
}

/****
 Translation Submodule : to get all records in progress
 ****/

export function getTranslationSubmoduleInProgress() {
    return {
        type: TRANSLATION_SUBMODULE_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Translation Submodule : to get all records :error
 ****/
export function getTranslationSubmoduleError(params) {
    return {
        type: TRANSLATION_SUBMODULE_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}


/****
 Translation Submodule : to get all records : Success
 ****/
export function getTranslationSubmoduleSuccess(params) {
    return {
        type: TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Translation Submodule : to display new edit popup
 ****/

export function displayNewEditTranslationSubmodulePopup( params ) {
    return {
        type: TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
 Translation Submodule : to hide new edit popup
 ****/

export function hideNewEditTranslationSubmodulePopup( params ) {
    return {
        type: TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
  Translation Submodule : save action 
 *****/
export function saveTranslationSubmodule( params ) {
    return {
        type: TRANSLATION_SUBMODULE_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Translation Submodule : save success action
  *****/

export function saveTranslationSubmoduleSuccess(data) {
    return {
        type: TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Translation Submodule : save error action 
 *****/
export function saveTranslationSubmoduleError(data) {
    return {
        type: TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Translation Submodule : save in progress action 
 *****/

export function saveTranslationSubmoduleProgress() {
    return {
        type: TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    };
}

/**** Translation Submodule : save None 
 *****/

export function saveTranslationSubmoduleNone() {
    return {
        type: TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE,
    };
}

/****
 Translation Submodule  : status update
 ****/

export function translationSubmoduleUpdateStatus(params) {
    return {
        type: TRANSLATION_SUBMODULE_UPDATE_STATUS,
        payload : params
    };
}

/****
 Translation Submodule  : status update in progress
 ****/
export function translationSubmoduleUpdateStatusInProgress() {
    return {
        type: TRANSLATION_SUBMODULE_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
  Translation Submodule  : Update status to None
 ****/
export function translationSubmoduleUpdateStatusNone() {
    return {
        type: TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE
    };
}

/****
   Translation Submodule : status update : success
 ****/
export function translationSubmoduleUpdateStatusSuccess(params) {
    return {
        type: TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}


/****
  Translation Submodule : status update : error
 ****/
export function translationSubmoduleUpdateStatusError(params) {
    return {
        type: TRANSLATION_SUBMODULE_UPDATE_STATUS_ERROR,
        payload : params
    };
}