import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS,
    TRANSLATION_FONT_GET_ALL_RECORDS,
    TRANSLATION_FONT_NEW_EDIT_SAVE,
    TRANSLATION_FONT_UPDATE_STATUS
} from "../../../../constants/ActionTypes";


import {
    URL_TRANSLATIONS_FONTS_CREATE_UPDATE,
    URL_TRANSLATIONS_FONTS_GET_ACTIVE_LIST,
    URL_TRANSLATIONS_FONTS_GET_LIST,
    URL_TRANSLATIONS_FONTS_SET_STATUS
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";
import {
    getAllActiveTranslationFontsError,
    getAllActiveTranslationFontsSuccess,
    getAllTranslationFontsError,
    getAllTranslationFontsSuccess,
    saveTranslationFontError,
    saveTranslationFontSuccess,
    updateTranslationFontStatusError,
    updateTranslationFontStatusSuccess
} from "../../../../actions";

/************ methods : http *******************/

/**** Https : Translation fonts Save ****/
const httpSaveTranslationFonts = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_FONTS_CREATE_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : Translation fonts update ****/
const httpUpdateTranslationFontStatus = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_FONTS_SET_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Translation fonts : get all records ****/
const httpGetAllTranslationFonts = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_FONTS_GET_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Translation fonts : get all records ****/
const httpGetActiveTranslationFonts = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_FONTS_GET_ACTIVE_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)


/************ methods : http response handler *******************/

/**** http : Translation fonts create update : http response handler ****/
function* handleHttpSaveTranslationFont({payload}) {
    try {
        let dataValue = yield call(httpSaveTranslationFonts, payload);

        if (dataValue.message) {
            yield put(saveTranslationFontError(dataValue.response));
        } else {
            yield put(saveTranslationFontSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveTranslationFontError(error));
    }
}

/**** http : Translation fonts update status : http response handler ****/
function* handleHttpUpdateTranslationFontStatus({payload}) {
    try {
        let dataValue = yield call(httpUpdateTranslationFontStatus, payload);

        if (dataValue.message) {
            yield put(updateTranslationFontStatusError(dataValue.response));
        } else {
            yield put(updateTranslationFontStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(updateTranslationFontStatusError(error));
    }
}

/**** http : Translation fonts get all records : http response handler ****/
function* handleHttpGetAllTranslationFonts({payload}) {
    try {
        let dataValue = yield call(httpGetAllTranslationFonts, payload);

        if (dataValue.message) {
            yield put(getAllTranslationFontsError(dataValue.response));
        } else {
            yield put(getAllTranslationFontsSuccess(dataValue));
        }
    } catch (error) {
        yield put(getAllTranslationFontsError(error));
    }
}


/**** http : Translation fonts update status : http response handler ****/
function* handleHttpGetActiveTranslationFonts({payload}) {
    try {
        let dataValue = yield call(httpGetActiveTranslationFonts, payload);

        if (dataValue.message) {
            yield put(getAllActiveTranslationFontsError(dataValue.response));
        } else {
            yield put(getAllActiveTranslationFontsSuccess(dataValue));
        }
    } catch (error) {
        yield put(getAllActiveTranslationFontsError(error));
    }
}

/************ methods : export access *******************/

/**** Listeners : Translation fonts save ****/
export function* saveTranslationFont() {
    yield takeEvery(TRANSLATION_FONT_NEW_EDIT_SAVE, handleHttpSaveTranslationFont)
}

/**** Listeners : Translation fonts update status ****/
export function* updateTranslationFontStatus() {
    yield takeEvery(TRANSLATION_FONT_UPDATE_STATUS, handleHttpUpdateTranslationFontStatus)
}


/**** Listeners : Translation fonts get all list ****/
export function* getTranslationsFontList() {
    yield takeEvery(TRANSLATION_FONT_GET_ALL_RECORDS, handleHttpGetAllTranslationFonts)
}

/**** Listeners : Translation fonts get active list ****/
export function* getTranslationsFontActiveList() {
    yield takeEvery(TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS, handleHttpGetActiveTranslationFonts)
}


export default function* rootSaga() {
    yield all([
        fork(saveTranslationFont),
        fork(getTranslationsFontList),
        fork(getTranslationsFontActiveList),
        fork(updateTranslationFontStatus)
    ])
}