import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Tour from '../components/Tour/index';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import {NotificationContainer} from "react-notifications";
import JiraWidget from 'components/JiraWidget';
import { MOBILE_VIEW } from '../constants/constants';

class App extends React.Component {

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            window.dataLayer.push({
                event: 'pageview',
                page: {
                    url: location.pathname
                }
            });
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition, isDefaultTopBar, enableMaintenanceBanner, showBanner} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Tour/>

                <Sidebar/>
                <div className={`app-main-container ${(enableMaintenanceBanner === true && showBanner === true && window.innerWidth > MOBILE_VIEW)? 'add_banner_to_top' : ''}`}>
                    {isDefaultTopBar && (
                        <div
                            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                            <TopNav styleName="app-top-header"/>}
                            <Header/>
                            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                            <TopNav/>}
                        </div>

                    )}

                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                <Route path={`${match.url}/dashboard/`} component={asyncComponent(() => import('./routes/dashboard'))}/>

                                {/* User Settings */}
                                <Route path={`${match.url}/user/settings/`} component={asyncComponent(() => import('./routes/user/settings/userSettings'))}/>


                                <Route path={`${match.url}/company/add/`} component={asyncComponent(() => import('./routes/company/add/'))}/>
                                <Route path={`${match.url}/company/index/`} component={asyncComponent(() => import('./routes/company/list'))}/>
                                <Route path={`${match.url}/company/edit/`} component={asyncComponent(() => import('./routes/company/add/'))}/>
                                <Route path={`${match.url}/company/view/`} component={asyncComponent(() => import('./routes/company/add/'))}/>

                                <Route path={`${match.url}/participants/index/`} component={asyncComponent(() => import('./routes/participants/list/'))}/>
                                <Route path={`${match.url}/participants/admin/`} component={asyncComponent(() => import('./routes/participants/admin/'))}/>
                                <Route path={`${match.url}/participants/info/`} component={asyncComponent(() => import('./routes/participants/list/participantInfo'))}/>

                                <Route path={`${match.url}/reports/index/`} component={asyncComponent(() => import('./routes/reports/index'))}/>

                                {/** connect**/}
                                {/** connect reports **/}
                                <Route path={`${match.url}/connect/reports/`} component={asyncComponent(() => import('./routes/reports/connect/reportsConnect'))}/>

                                {/** payslip **/}
                                {/** payslip list **/}
                                <Route path={`${match.url}/payslip/list`} component={asyncComponent(()=> import('./routes/payslip/PayslipList'))}/>
                                <Route path={`${match.url}/payslip/view`} component={asyncComponent(()=> import('./routes/payslip/PayslipView'))}/>

                                {/** E Learning **/}
                                {/** E Learning reports **/}
                                <Route path={`${match.url}/elearning/reports/`} component={asyncComponent(() => import('./routes/reports/elearning/reportsELearning'))}/>

                                {/** Company Post **/}
                                {/** Company Post reports **/}
                                <Route path={`${match.url}/companypost/reports/`} component={asyncComponent(() => import('./routes/reports/companyPost/companyPostRawData'))}/>


                                <Route path={`${match.url}/wovoimport/clients/`} component={asyncComponent(() => import('./routes/wovoImport/clients/index'))}/>

                                {/* Translations */}
                                <Route path={`${match.url}/admin/settings/localisation/`} component={asyncComponent(() => import('./routes/adminSettings/localisations/index'))}/>

                                {/* Account Managers */}
                                <Route path={`${match.url}/accountManagers/new/`} component={asyncComponent(() => import('./routes/adminSettings/accountManagers/addAccountManager'))}/>
                                <Route path={`${match.url}/accountManagers/view/`} component={asyncComponent(() => import('./routes/adminSettings/accountManagers/addAccountManager'))}/>
                                <Route path={`${match.url}/accountManagers/edit/`} component={asyncComponent(() => import('./routes/adminSettings/accountManagers/addAccountManager'))}/>
                                <Route path={`${match.url}/accountManagers/index/`} component={asyncComponent(() => import('./routes/adminSettings/accountManagers/index'))}/>

                                {/* Client PIC */}
                                <Route path={`${match.url}/admin/settings/clientPIC/`} component={asyncComponent(() => import('./routes/adminSettings/clientPIC/index'))}/>

                                {/* Industry Types */}
                                <Route path={`${match.url}/admin/settings/industryTypes/`} component={asyncComponent(() => import('./routes/adminSettings/industryTypes/index'))}/>

                                {/* Client module config */}
                                <Route path={`${match.url}/admin/settings/clientModuleConfig/`} component={asyncComponent(() => import('./routes/adminSettings/clientModules/clientModuleConfig'))}/>

                                {/* Survey Categories */}
                                <Route path={`${match.url}/survey/categories`} component={asyncComponent(() => import('./routes/survey/category/index'))}/>
                                <Route path={`${match.url}/survey/questiontypes`} component={asyncComponent(() => import('./routes/survey/questionTypes/index'))}/>
                                <Route path={`${match.url}/survey/questiontype`} component={asyncComponent(() => import('./routes/survey/questionTypes/newQuestionType'))}/>

                                {/* Survey Question Bank */}
                                <Route path={`${match.url}/survey/questionbank`} component={asyncComponent(() => import('./routes/survey/questionBank/index'))}/>
                                <Route path={`${match.url}/survey/question_bank/new`} component={asyncComponent(() => import('./routes/survey/questionBank/newSurveyQuestionBank'))}/>

                                {/* Survey Reporting Categories */}
                                <Route path={`${match.url}/survey/reporting/categories`} component={asyncComponent(() => import('./routes/survey/reportingCategory/index'))}/>

                                {/* Survey templates */}
                                <Route path={`${match.url}/survey/templates`} component={asyncComponent(() => import('./routes/survey/templates/index'))}/>
                                <Route path={`${match.url}/survey/newtemplates`} component={asyncComponent(() => import('./routes/survey/templates/newSurveyTemplate'))}/>

                                {/* Survey */}
                                <Route path={`${match.url}/survey/home`} component={asyncComponent(() => import('./routes/survey/survey/index'))}/>
                                <Route path={`${match.url}/survey/newsurvey`} component={asyncComponent(() => import('./routes/survey/survey/newSurvey'))}/>

                                {/* Survey Reports */}
                                <Route path={`${match.url}/survey/surveyResults`} component={asyncComponent(() => import('./routes/survey/survey/surveyQuickInsights'))}/>

                                {/* Survey Template Reports */}
                                <Route path={`${match.url}/questionnaire/report`} component={asyncComponent(() => import('./routes/survey/templates/surveyTemplateQuickInsights'))}/>

                                {/* Survey navigation*/}
                                <Route path={`${match.url}/survey/info/:id`} component={asyncComponent(() => import('./routes/survey/components/SurveyNavigationHandler'))}/>
                                <Route path={`${match.url}/survey/info/`} component={asyncComponent(() => import('./routes/survey/components/SurveyNavigationHandler'))}/>
                                <Route path={`${match.url}/questionnaire/info/`} component={asyncComponent(() => import('./routes/survey/components/SurveyNavigationHandler'))}/>


                                {/* admin  */}
                                <Route path={`${match.url}/admin/activate_survey`} component={asyncComponent(() => import('./routes/adminControls/survey/activateSurvey'))}/>
                                <Route path={`${match.url}/admin/survey_duplicate_responses`} component={asyncComponent(() => import('./routes/adminControls/survey/surveyDuplicateResponses'))}/>
                                <Route path={`${match.url}/admin/survey_all_raw_data_report`} component={asyncComponent(() => import('./routes/adminControls/survey/surveyAllRawDataReport'))}/>
                                <Route path={`${match.url}/admin/survey_responses_update`} component={asyncComponent(() => import('./routes/adminControls/survey/surveyResponsesDemoGraphicDetails'))}/>
                                <Route path={`${match.url}/admin/client_modules`} component={asyncComponent(() => import('./routes/adminControls/clientModules/adminClientModules'))}/>
                                <Route path={`${match.url}/admin/new_edit_client_modules`} component={asyncComponent(() => import('./routes/adminControls/clientModules/newEditClientModule'))}/>
                                <Route path={`${match.url}/admin/increase_survey_responses`} component={asyncComponent(() => import('./routes/adminControls/survey/increaseSurveyResponses'))}/>
                                <Route path={`${match.url}/admin/reset_survey_cached_data`} component={asyncComponent(() => import('./routes/adminControls/survey/resetSurveyCachedData'))}/>
                                <Route path={`${match.url}/admin/save_survey_response_by_request_id`} component={asyncComponent(() => import('./routes/adminControls/survey/saveSurveyResponseByRequest'))}/>
                                <Route path={`${match.url}/admin/worker_contacts`} component={asyncComponent(() => import('./routes/adminControls/worker_contacts/adminWorkerContacts'))}/>

                                {/* worker contact  */}
    
                                {/* list */}
                                <Route path={`${match.url}/worker_contacts/list`} component={asyncComponent(() => import('./routes/wc/WorkerContacts'))}/>
                                {/* new contact */}
                                <Route path={`${match.url}/worker_contacts/contact`} component={asyncComponent(() => import('./routes/wc/WorkerContactView'))}/>
                                {/* import */}
                                <Route path={`${match.url}/worker_contacts/import`} component={asyncComponent(() => import('./routes/wc/WorkerContactImport'))}/>
                                {/* reset registration */}
                                <Route path={`${match.url}/worker_contacts/reset_registration`} component={asyncComponent(() => import('./routes/wc/resetRegistration'))}/>
                                {/* groups */}
                                <Route path={`${match.url}/worker_contacts/groups`} component={asyncComponent(() => import('./routes/wc/groups/WorkerContactGroups'))}/>
                                
                                {/* contact settings  */}
                                
                                {/* home */}
                                <Route path={`${match.url}/contact_settings/home`} component={asyncComponent(() => import('./routes/contactSettings/ContactSettings'))}/>
                                
                                
                                <Route component={asyncComponent(() => import('components/Error404'))}/>
                            </Switch>
                            <NotificationContainer/>
                        </div>
                    
                    </main>
                    <JiraWidget/>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({settings, ReducerMaintenanceBanner}) => {
    const {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        isDefaultTopBar
    } = settings;

    const {enableBanner, showBanner} = ReducerMaintenanceBanner;

    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        isDefaultTopBar,
        enableMaintenanceBanner : enableBanner,
        showBanner
    }
};
export default withRouter(connect(mapStateToProps)(App));
