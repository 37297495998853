import {
    E_LEARNING_REPORTS_SET_FILTER_VIEW,
    E_LEARNING_REPORTS_SET_FILTER_GROUP_BY,
    E_LEARNING_REPORTS_SET_FILTER_METRIC,
    E_LEARNING_REPORTS_RESET_FILTERS,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
    E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER,
    E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER,
    E_LEARNING_REPORTS_SET_FILTER_LESSON,
} from "../../../constants/actions/reports/elearning/actionsELearningReports";


/****
 E Learning reports : raw data download uuid
 ****/

export function eLearningRawDataDownloadUUIDDownload(params) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
        payload: params
    };
}

/****
 E Learning reports : raw data download uuid : success
 ****/

export function eLearningRawDataDownloadUUIDSuccess(data) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
        payload: data
    };
}

/****
 E Learning reports : raw data download uuid : error
 ****/

export function eLearningRawDataDownloadUUIDError(params) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
        payload: params
    }
}

/****
 E Learning reports : raw data download uuid :  None
 ****/
export function eLearningRawDataDownloadUUIDNone() {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE
    };
}

/****
 E Learning reports : raw data download uuid status
 ****/

export function eLearningRawDataUUIDDownloadStatus(params) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
        payload: params
    };
}

/****
 E Learning reports : raw data download uuid status : success
 ****/

export function eLearningRawDataDownloadUUIDStatusSuccess(data) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
        payload: data
    };
}

/****
 E Learning reports : raw data download uuid status : error
 ****/

export function eLearningRawDataDownloadUUIDStatusError(params) {
    return {
        type: E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
        payload: params
    }
}

/****
 E Learning Report Filters : To set view value
 ****/
export function setELearningReportFilterViewValue(payload) {
    return {
        type: E_LEARNING_REPORTS_SET_FILTER_VIEW,
        payload: payload
    };
}

/****
 E Learning Report Filters : To set group by value
 ****/
export function setELearningReportFilterGroupByValue(payload) {
    return {
        type: E_LEARNING_REPORTS_SET_FILTER_GROUP_BY,
        payload: payload
    };
}

/****
 E Learning Report Filters : To set metric value
 ****/
export function setELearningReportFilterMetricValue(payload) {
    return {
        type: E_LEARNING_REPORTS_SET_FILTER_METRIC,
        payload: payload
    };
}

export function setELearningReportFilterLessonValue(payload) {
    return {
        type: E_LEARNING_REPORTS_SET_FILTER_LESSON,
        payload: payload
    };
}

/****
 E Learning Report Filters : To reset all filters
 ****/
export function resetELearningReportFilters(payload) {
    return {
        type: E_LEARNING_REPORTS_RESET_FILTERS,
        payload: payload
    };
}

/****
 E Learning reports : summary table
 ****/
export function getELearningSummaryTableData(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA,
        payload: payload
    };
}

/****
 E Learning reports : summary table : success
 ****/
export function getELearningSummaryTableDataSuccess(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS,
        payload: payload
    };
}

/****
 E Learning reports : summary table : error
 ****/
export function getELearningSummaryTableDataError(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR,
        payload: payload
    };
}

/****
 E Learning reports : summary table : none
 ****/
export function getELearningSummaryTableDataNone(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE,
        payload: payload
    };
}

/****
 E Learning reports : summary table uuid
 ****/
export function getELearningSummaryTableDataUUID(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA,
        payload: payload
    };
}

/****
 E Learning reports : summary table uuid  : success
 ****/
export function getELearningSummaryTableDataUUIDSuccess(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS,
        payload: payload
    };
}

/****
 E Learning reports : summary table uuid  : error
 ****/
export function getELearningSummaryTableDataUUIDError(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR,
        payload: payload
    };
}

/****
 E Learning reports : summary table uuid  : none
 ****/
export function getELearningSummaryTableDataUUIDNone(payload) {
    return {
        type: E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE,
        payload: payload
    };
}

/****
 E Learning reports : set lesson filter
 ****/

export function setELearningReportsSummaryTableLessonFilter(params) {
    return {
        type: E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER,
        payload: params
    }
}

/****
 E Learning reports : reset lesson filter
 ****/

export function resetELearningReportsSummaryTableLessonFilter(params) {
    return {
        type: E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER,
        payload: params
    }
}
