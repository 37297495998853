import {
    COMPANY_POST_RAW_DATA_RESPONSES_UUID,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
    COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS
} from "../../../constants/ActionTypes";

/****
 Company Post reports : To get the responses uuid
 ****/
export function companyPostRawDataResponsesUUID(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID,
        payload: params
    };
}

/****
Company Post reports : To get the responses uuid success
****/
export function companyPostRawDataResponsesUUIDSuccess(data) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS,
        payload: data
    };
}

/****
Company Post reports : To get the responses uuid error
****/
export function companyPostRawDataResponsesUUIDError(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR,
        payload: params
    }
}

/****
Company Post reports : To get the responses uuid in progress
 ****/
export function companyPostRawDataResponsesUUIDInProgress() {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS
    };
}

/****
Company Post reports : To get the responses uuid None
****/
export function companyPostRawDataResponsesUUIDNone() {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE
    };
}

/****
 Company Post reports : To get the responses uuid status
 ****/
export function companyPostRawDataResponsesUUIDStatus(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS,
        payload: params
    };
}

/****
Company Post reports : To get the responses uuid status success
****/
export function companyPostRawDataResponsesUUIDStatusSuccess(data) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS,
        payload: data
    };
}

/****
Company Post reports : To get the responses uuid status error
****/
export function companyPostRawDataResponsesUUIDStatusError(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR,
        payload: params
    }
}

/****
 Company Post reports : raw data download uuid
 ****/

export function companyPostRawDataDownloadUUIDDownload(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
        payload: params
    };
}

/** Company Post  : to Download Summary Table UUID in progess */
export function companyPostRawDataDownloadUUIDInProgress() {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS
    };
}

/****
 Company Post reports : raw data download uuid : success
 ****/

export function companyPostRawDataDownloadUUIDSuccess(data) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
        payload: data
    };
}

/****
 Company Post reports : raw data download uuid : error
 ****/

export function companyPostRawDataDownloadUUIDError(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
        payload: params
    }
}

/****
 Company Post reports : raw data download uuid :  None
 ****/
export function companyPostRawDataDownloadUUIDNone() {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE
    };
}

/****
 Company Post reports : raw data download uuid status
 ****/

export function companyPostRawDataUUIDDownloadStatus(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
        payload: params
    };
}

/****
 Company Post reports : raw data download uuid status : success
 ****/

export function companyPostRawDataDownloadUUIDStatusSuccess(data) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
        payload: data
    };
}

/****
 Company Post reports : raw data download uuid status : error
 ****/

export function companyPostRawDataDownloadUUIDStatusError(params) {
    return {
        type: COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
        payload: params
    }
}