import {HTTP_REQUEST_STATUS} from "../../constants/constants";
import {
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE,
    CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE,
    CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS
} from "../../constants/actions";
import UIUtils from "../../util/ui_utils";
import IntlMessages from "../../util/IntlMessages";
import React from "react";

const contactSettingsFilterConfig = [
    {
        'key': 'contact_settings_header_info',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CONTACT_SETTINGS_LABEL_DETAIL_INFORMATION"/>
    }
];

const CONTACT_SETTINGS_RIGHT_PANEL_DETAILS = [
    {
        'key': 'custom_field',
        'type': UIUtils.UI_COMPONENT_TYPES.OUTLINE_TEXT_BOX,
        'localisation': "DIC_KEY_CONTACT_SETTINGS_VIEW_PLACE_HOLDER_CUSTOM_FIELD_TYPE",
        'style': {marginBottom: 0},
        'parent_class': "form_container_root_no_padding contact_settings_element_bottom"
    },
    {
        'key': 'custom_field_type',
        'type': UIUtils.UI_COMPONENT_TYPES.OUTLINE_DROPDOWN,
        'localisation': "DIC_KEY_CONTACT_SETTINGS_VIEW_PLACE_HOLDER_CUSTOM_FIELD_TYPE",
        'style': {marginBottom: 0},
        'parent_class': "form_container_root_no_padding contact_settings_element_bottom"
    },
    {
        'key': 'custom_field_values',
        'type': UIUtils.UI_COMPONENT_TYPES.CHIPS_LIST_BOX,
        'style': {},
        'parent_class': "form_container_root_no_padding contact_settings_element_bottom"
    },
    {
        'key': 'is_required',
        'type': UIUtils.UI_COMPONENT_TYPES.FORM_HORIZONTAL_RIGHT_LABEL_SWITCH,
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_CONTACT_SETTINGS_INFO_REQUIRED_FIELD",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root contact_settings_element_bottom",
        'placeholderKey': "",
        'variant': ""
    },
    {
        'key': 'is_active',
        'type': UIUtils.UI_COMPONENT_TYPES.FORM_HORIZONTAL_RIGHT_LABEL_SWITCH,
        'style': {},
        'labelClass': "",
        'localisation': "DIC_KEY_CONTACT_SETTINGS_INFO_ACTIVE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root contact_settings_element_bottom",
        'placeholderKey': "",
        'variant': "",
        'toastLabel': <IntlMessages id="DIC_KEY_CONTACT_SETTINGS_INFO_ACTIVE_TOAST_LABEL" />
    }
];

const initialData = {
    contactSettingsFilterConfig: contactSettingsFilterConfig,
    CONTACT_SETTINGS_RIGHT_PANEL_DETAILS: CONTACT_SETTINGS_RIGHT_PANEL_DETAILS,
    
    getClientCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    removeCustomFieldValuesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    alertMessage: "",
    
    currentContactSettings: []
    
    
}

const ReducerContactSettings = (state = initialData, action) => {
    
    try {
        
        switch (action.type) {
            
            /**** contact settings : removing custom field value for client ****/
            case CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES: {
                return {
                    ...state,
                    removeCustomFieldValuesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                    alertMessage: ""
                }
            }
            
            case CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_SUCCESS: {
                
                const currentContactSettings = state.currentContactSettings;
                
                if (typeof action.payload.data != "undefined") {
                    for (let i = 0; i < currentContactSettings.length; i++) {
                        if (currentContactSettings[i]["id"] === action.payload.data["id"]) {
                            currentContactSettings[i] = action.payload.data;
                            break;
                        }
                    }
                }
                
                return {
                    ...state,
                    removeCustomFieldValuesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                    alertMessage: action.payload.message,
                    currentContactSettings: currentContactSettings
                }
            }
            
            case CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_ERROR: {
                return {
                    ...state,
                    removeCustomFieldValuesStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: action.payload.data.error
                }
            }
            
            case CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES_NONE: {
                return {
                    ...state,
                    removeCustomFieldValuesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                    alertMessage: ""
                }
            }
            
            
            /** to get client custom field config **/
            case CONTACT_SETTINGS_GET_CUSTOM_FIELDS: {
                
                return {
                    ...state,
                    getClientCustomFieldsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                    currentContactSettings: []
                }
            }
            
            case CONTACT_SETTINGS_GET_CUSTOM_FIELDS_SUCCESS: {
                
                let currentContactSettings = [];
                
                if (typeof action.payload.data != "undefined") {
                    currentContactSettings = action.payload.data;
                }
                
                return {
                    ...state,
                    getClientCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                    currentContactSettings: currentContactSettings
                }
            }
            
            case CONTACT_SETTINGS_GET_CUSTOM_FIELDS_ERROR: {
                
                return {
                    ...state,
                    getClientCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                    currentContactSettings: []
                }
            }
            
            case CONTACT_SETTINGS_GET_CUSTOM_FIELDS_NONE: {
                
                return {
                    ...state,
                    getClientCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED
                }
            }
            
            
            default:
                return {...state};
        }
        
    } catch (ex) {
    
    }
    
}

export default ReducerContactSettings;
