import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO,
    SURVEY_TEMPLATE_GET_QUESTIONS,
    SURVEY_TEMPLATE_SET_STATUS,
    SURVEY_TEMPLATE_REDEPLOY, 
    SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY
} from "../../../constants/ActionTypes";

import {
    URL_SURVEY_TEMPLATE_GET_ALL_RECORDS,
    URL_SURVEY_TEMPLATE_GET_QUESTIONS,
    URL_SURVEY_TEMPLATE_GET_RECORD_BY_ID,
    URL_SURVEY_TEMPLATE_SAVE_INFO,
    URL_SURVEY_TEMPLATE_SET_STATUS,
    URL_TEMPLATE_SAVE_REDEPLOY,
    URL_SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS,
    URL_SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS,
    URL_SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY
} from "../../../constants/Urls";

import AppUtils from "../../../util/util";

import {
    getSurveyTemplateByIdError, getSurveyTemplateByIdSuccess,
    getSurveyTemplatePaginatedListError,
    getSurveyTemplatePaginatedListSuccess,
    getSurveyTemplateQuestionsError,
    getSurveyTemplateQuestionsSuccess,
    saveSurveyTemplateInfoError,
    saveSurveyTemplateInfoSuccess,
    setSurveyTemplateStatusError,
    setSurveyTemplateStatusSuccess,
    surveyTemplateGetAllRecordsError,
    surveyTemplateGetAllRecordsSuccess,
    redeploySurveyTemplateError, 
    redeploySurveyTemplateSuccess,
    getSurveyTemplateRedeploySeriesQuestionsSuccess,
    getSurveyTemplateRedeploySeriesQuestionsError,
    saveSurveyTemplateRedeploySeriesQuestionsSuccess,
    saveSurveyTemplateRedeploySeriesQuestionsError,
    getSurveyTemplateRedeployQuestionSummarySuccess,
    getSurveyTemplateRedeployQuestionSummaryError
} from "../../../actions/survey/templates/ActionsSurveyTemplates";

import {
    SURVEY_TEMPLATE_GET_NEXT_LIST,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";


/************ methods : http *******************/
/** http : Survey Category : http save **/
const httpSaveSurveyTemplateInfo = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_SAVE_INFO, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Category : http update **/
const httpUpdateSurveyTemplateInfo = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_TEMPLATE_SAVE_INFO, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Category : get all records **/
const httpGetSurveyTemplatesList = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_ALL_RECORDS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey template : get all questions **/
const httpGetSurveyTemplatesQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : Survey template : to set status **/
const httpSetSurveyTemplatesStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_SET_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : Survey template : get paginated list **/
const httpGetSurveyTemplatePaginatedList = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_ALL_RECORDS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey template : by id **/
const httpGetSurveyTemplateById = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_RECORD_BY_ID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Template redeploy : http update **/
const httpSaveSurveyTemplateRedeploy = async (data) =>
await axios.post(baseUrl + URL_TEMPLATE_SAVE_REDEPLOY, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/** http : Survey Template redeploy : get series questions **/
const httpGetSurveyTemplateRedeploySeriesQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Template redeploy : save series questions **/
const httpSaveSurveyTemplateRedeploySeriesQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Template redeploy : to get line graph details **/
const httpGetRedeployTemplateQuestionSummary = async (data) =>
await axios.post(baseUrl + URL_SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/************ methods : http response handler *******************/

/** http : Survey template save : http response handler **/
function* handleHttpSaveSurveyTemplateInfo({payload}) {
    try {

        let result = yield call(httpSaveSurveyTemplateInfo, payload);

        if (result.message) {
            yield put(saveSurveyTemplateInfoError(result.response));
        } else {
            yield put(saveSurveyTemplateInfoSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyTemplateInfoError(error));
    }
}

/** http : Survey template update : http response handler **/
function* handleHttpUpdateSurveyTemplateInfo({payload}) {
    try {

        let result = yield call(httpUpdateSurveyTemplateInfo, payload);

        if (result.message) {
            yield put(saveSurveyTemplateInfoError(result.response));
        } else {
            yield put(saveSurveyTemplateInfoSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyTemplateInfoError(error));
    }
}


/** http : Survey Question types : http response handler **/
function* handleHttpGetSurveyTemplatesList({payload}) {
    try {

        let result = yield call(httpGetSurveyTemplatesList, payload);

        if (result.message) {
            yield put(surveyTemplateGetAllRecordsError(result.response));
        } else {
            yield put(surveyTemplateGetAllRecordsSuccess(result));
        }
    } catch (error) {
        yield put(surveyTemplateGetAllRecordsError(error));
    }
}


/** http : Survey template Question : http response handler **/
function* handleHttpGetSurveyTemplatesQuestions({payload}) {
    try {

        let result = yield call(httpGetSurveyTemplatesQuestions, payload);

        if (result.message) {
            yield put(getSurveyTemplateQuestionsError(result.response));
        } else {
            yield put(getSurveyTemplateQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplateQuestionsError(error));
    }
}


/** http : Survey status : http response handler **/
function* handleHttpSetSurveyTemplatesStatus({payload}) {
    try {

        let result = yield call(httpSetSurveyTemplatesStatus, payload);

        if (result.message) {
            yield put(setSurveyTemplateStatusError(result.response));
        } else {
            yield put(setSurveyTemplateStatusSuccess(result));
        }
    } catch (error) {
        yield put(setSurveyTemplateStatusError(error));
    }
}

/** http : Survey template : get paginated records **/
function* handleHttpGetSurveyTemplatePaginatedList({payload}) {
    try {

        let result = yield call(httpGetSurveyTemplatePaginatedList, payload);

        if (result.message) {
            yield put(getSurveyTemplatePaginatedListError(result.response));
        } else {
            yield put(getSurveyTemplatePaginatedListSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplatePaginatedListError(error));
    }
}

/** http : Survey template : get paginated records **/
function* handleHttpGetSurveyTemplateById({payload}) {
    try {

        let result = yield call(httpGetSurveyTemplateById, payload);

        if (result.message) {
            yield put(getSurveyTemplateByIdError(result.response));
        } else {
            yield put(getSurveyTemplateByIdSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplateByIdError(error));
    }
}

/** http : save redeploy Template : http response handler **/
function* handleHttpSaveSurveyTemplateRedeploy({payload}) {
    try {
        let result = yield call(httpSaveSurveyTemplateRedeploy, payload);
        if (result.message) {
            yield put(redeploySurveyTemplateError(result.response));
        } else {
            yield put(redeploySurveyTemplateSuccess(result));
        }
    } catch (error) {
        yield put(redeploySurveyTemplateError(error));
    }
}

/** http : Survey Template redeploy get series questions : http response handler **/
function* handleHttpGetSurveyTemplateRedeploySeriesQuestions({payload}) {
    try {
        let result = yield call(httpGetSurveyTemplateRedeploySeriesQuestions, payload);
        if (result.message) {
            yield put(getSurveyTemplateRedeploySeriesQuestionsError(result.response));
        } else {
            yield put(getSurveyTemplateRedeploySeriesQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplateRedeploySeriesQuestionsError(error));
    }
}

/** http : Survey redeploy save series questions : http response handler **/
function* handleHttpSaveSurveyTemplateRedeploySeriesQuestions({payload}) {
    try {
        let result = yield call(httpSaveSurveyTemplateRedeploySeriesQuestions, payload);
        if (result.message) {
            yield put(saveSurveyTemplateRedeploySeriesQuestionsError(result.response));
        } else {
            yield put(saveSurveyTemplateRedeploySeriesQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyTemplateRedeploySeriesQuestionsError(error));
    }
}

/** http : Survey template redeploy save series questions : http response handler **/
function* handleHttpGetSurveyTemplateRedeployQuestionsSummary({payload}) {
    try {

        let result = yield call(httpGetRedeployTemplateQuestionSummary, payload);

        if (result.message) {
            yield put(getSurveyTemplateRedeployQuestionSummaryError(result.response));
        } else {
            yield put(getSurveyTemplateRedeployQuestionSummarySuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplateRedeployQuestionSummaryError(error));
    }
}

/************ methods : export access *******************/

/** Survey template info : save **/
export function* saveSurveyTemplateInfo() {
    yield takeEvery(SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO, handleHttpSaveSurveyTemplateInfo);
}

/** Survey template info : update **/
export function* updateSurveyTemplateInfo() {
    yield takeEvery(SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO, handleHttpUpdateSurveyTemplateInfo);
}

/** Survey template : get records **/
export function* setSurveyTemplatesList() {
    yield takeEvery(SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS, handleHttpGetSurveyTemplatesList);
}


/** Survey template questions : get records **/
export function* setSurveyTemplatesQuestions() {
    yield takeEvery(SURVEY_TEMPLATE_GET_QUESTIONS, handleHttpGetSurveyTemplatesQuestions);
}

/** Survey template : to set status **/
export function* setSurveyTemplatesStatus() {
    yield takeEvery(SURVEY_TEMPLATE_SET_STATUS, handleHttpSetSurveyTemplatesStatus);
}

/** Survey template : get paginated records **/
export function* getSurveyTemplatePaginatedList() {
    yield takeEvery(SURVEY_TEMPLATE_GET_NEXT_LIST, handleHttpGetSurveyTemplatePaginatedList);
}

/** Survey template : get record by id **/
export function* getSurveyTemplateById() {
    yield takeEvery(SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID, handleHttpGetSurveyTemplateById);
}

/** Survey template Redeploy : save **/
export function* saveRedeployedSurveyTemplate() {
    yield takeEvery(SURVEY_TEMPLATE_REDEPLOY, handleHttpSaveSurveyTemplateRedeploy);
}

/** Survey template Redeploy : save **/
export function* sagaGetSurveyTemplateRedeploySeriesQuestions() {
    yield takeEvery(SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS, handleHttpGetSurveyTemplateRedeploySeriesQuestions);
}

/** Survey template Redeploy : save question series **/
export function* sagaSaveSurveyTemplateRedeploySeriesQuestions() {
    yield takeEvery(SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS, handleHttpSaveSurveyTemplateRedeploySeriesQuestions);
}

/** Survey template Redeploy : to get line graph details **/
export function* sagaGetSurveyTemplateRedeployQuestionsSummary() {
    yield takeEvery(SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY, handleHttpGetSurveyTemplateRedeployQuestionsSummary);
}

export default function* rootSaga() {
    yield all([
        fork(saveSurveyTemplateInfo),
        fork(updateSurveyTemplateInfo),
        fork(setSurveyTemplatesList),
        fork(setSurveyTemplatesQuestions),
        fork(setSurveyTemplatesStatus),
        fork(getSurveyTemplatePaginatedList),
        fork(getSurveyTemplateById),
        fork(saveRedeployedSurveyTemplate),
        fork(sagaGetSurveyTemplateRedeploySeriesQuestions),
        fork(sagaSaveSurveyTemplateRedeploySeriesQuestions),
        fork(sagaGetSurveyTemplateRedeployQuestionsSummary)
    ]);
}