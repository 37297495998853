import React from "react";
import { HTTP_REQUEST_STATUS } from "../../../constants/constants";
import {
  REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES,
  REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR,
  REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE,
  REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS,
  REPORT_FILTERS_CUSTOM_FIELD_SELECTED,
  ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
  ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR,
  ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE,
  ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS,
  UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES,
  REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET,
} from "../../../constants/actions/reports/actionsCustomFields";
import IntlMessages from "util/IntlMessages";

const initialData = {
  clientParticipantCustomFields: [],
  selectedCustomFields: {},
  accountCustomFieldFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
  fetchedAccountCustomFields: [],
  selectedAccountCustomFields: {},
};

/**static option to be added to custom fields */
const unDisclosedOption = {
  id: null,
  key: null,
  // name: <IntlMessages id="DIC_KEY_REPORT_RILTERS_UNDISCLOSED_OPTION_LABEL" />,
  label: "DIC_KEY_REPORT_RILTERS_UNDISCLOSED_OPTION_LABEL",
};

const ReducerCustomFields = (state = initialData, action) => {
  switch (action.type) {
    case REPORT_FILTERS_CUSTOM_FIELD_SELECTED_RESET: {
      return {
        ...state,
        selectedCustomFields: {},
      };
    }
    case REPORT_FILTERS_CUSTOM_FIELD_SELECTED: {
      return {
        ...state,
        selectedCustomFields: action.payload,
      };
    }

    case REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES: {
      return {
        ...state,
      };
    }

    case REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS: {
      const wCCustomField = modifyCustomFieldValues(
        action.payload.filter((item) => item.is_active)
      );
      const data = {};
      wCCustomField.forEach((field) => {
        if (field.field_type === "SINGLE_SELECTION") {
          data[field.key] = [...field.option_values];
        } else {
          data[field.key] = [...field.custom_field_values];
        }
      });
      const initCustomFilters = wCCustomField.reduce((acc, val) => {
        acc[`custom-field-${val.id}`] = [
          "SINGLE_SELECTION",
          "MULTIPLE_SELECTION",
        ].includes(val.fieldType)
          ? val.custom_field_values
          : undefined;
        return acc;
      }, {});
      return {
        ...state,
        clientParticipantCustomFields: wCCustomField.filter(
          (ft) =>
            ft.fieldType === "MULTIPLE_SELECTION" ||
            ft.fieldType === "SINGLE_SELECTION"
        ),
        allClientParticipantCustomFields: wCCustomField,
        selectedCustomFields: initCustomFilters,
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.SUCCESS,
      };
    }

    case REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR: {
      return {
        ...state,
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.FAIL,
      };
    }

    case REPORT_FILTER_GET_CUSTOM_FIELDS_WITH_VALUES_NONE: {
      return {
        ...state,
        wcContactsGetCustomFieldsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        clientParticipantCustomFields: [],
      };
    }

    case ACCOUNT_CUSTOM_FIELD_WITH_VALUES:
      return {
        ...state,
      };

    case ACCOUNT_CUSTOM_FIELD_WITH_VALUES_ERROR:
      return {
        ...state,
        accountCustomFieldFetchStatus: HTTP_REQUEST_STATUS.FAIL,
      };

    case ACCOUNT_CUSTOM_FIELD_WITH_VALUES_SUCCESS: {
      let accountCustomField = modifyCustomFieldValues(
        action.payload.filter(
          (item) =>
            item.is_active &&
            (item.field_type === "SINGLE_SELECTION" ||
              item.field_type === "MULTIPLE_SELECTION")
        )
      );
      const data = {};
      accountCustomField.forEach((field) => {
        if (field.field_type === "SINGLE_SELECTION") {
          data[field.key] = [...field.option_values];
        } else {
          data[field.key] = [...field.custom_field_values];
        }
      });
      return {
        ...state,
        accountCustomFieldFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
        fetchedAccountCustomFields: accountCustomField,
        selectedAccountCustomFields: data,
      };
    }

    case ACCOUNT_CUSTOM_FIELD_WITH_VALUES_NONE:
      return {
        ...state,
        accountCustomFieldFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
        fetchedAccountCustomFields: [],
      };

    case UPDATE_ACCOUNT_CUSTOM_FIELD_WITH_VALUES: {
      return {
        ...state,
        selectedAccountCustomFields: action.payload,
      };
    }

    default:
      return state;
  }
};

let fieldTypes = {
  MULTIPLE_SELECTION: "multiselect_react_chips_dropdown",
  SINGLE_SELECTION: "outline_dropdown",
  EMAIL: "textfield",
  GENERAL: "textfield",
  DATE: "datefield",
  PHONE: "phone_number",
};

function modifyCustomFieldValues(data) {
  try {
    data = data.map((field) => {
      return {
        id: field.id,
        key: "custom-field-" + field.id,
        localisation: field.name,
        fieldType: field.field_type,
        type: fieldTypes[field.field_type],
        style: { color: "#9DA3A3" },
        required: field.required,
        custom_field_values: getCustomFieldValues(field),
      };
    });
  } catch (ex) {
    console.error("modifyCustomFieldValues : error : ", ex);
  }
  return data;
}

function getCustomFieldValues(field) {
  let customFieldOptions = [];
  try {
    if (field.custom_field_values) {
      customFieldOptions = field.custom_field_values.map((fd) => {
        return {
          id: fd.id,
          key: fd.id,
          name: fd.name,
        };
      });
    } else {
      customFieldOptions = field.option_values.map((fd) => {
        return {
          id: `${fd.custom_field_id}-${fd.id}`,
          key: fd.id,
          name: fd.value,
        };
      });
    }
    ["SINGLE_SELECTION", "MULTIPLE_SELECTION"].includes(field.field_type) &&
      customFieldOptions.push(unDisclosedOption);
  } catch (error) {}
  return customFieldOptions;
}

export default ReducerCustomFields;
