import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {FILTER_CLEAR_BUTTON_TRANSPARENT_COLOR, FILTER_SEARCH_BUTTON_COLOR} from "../../constants/customUIConfig";
import IntlMessages from "../../util/IntlMessages";
import AppUtils from "../../util/util";
import {defaultStaticRanges, createStaticRanges} from "react-date-range";
import {
    updateLocalTimezoneSelection
} from "../../actions/Auth"
import { getAllTimezones } from "../../actions"

class WoVoDateRangePicker extends Component {

    isInitDone = false

    constructor(props) {
        super(props);
        
        let selectionRange = AppUtils.getDefaultDateRangeSelection();

        let defaultDateRangeValue = {
            label: <IntlMessages id="DIC_REPORTS_DATE_RANGE_PAST_ONE_YEAR" />,
            range: () => ({
                startDate: selectionRange['startDate'],
                endDate: selectionRange['endDate'],
            }),
        };

        const defaultDateRange = createStaticRanges([defaultDateRangeValue]);

        this.state = {
            selectionRange: selectionRange,
            defaultDateRange: defaultDateRange,
            selectedTimeZone: (props.localSelectedTimeZone || props.user.timezone),
        }
    }

    componentDidMount(){
        this.props.getAllTimezones()
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectionRange, isVisible} = this.props;

        if ( ( isVisible && !prevProps.isVisible ) || (!this.isInitDone && selectionRange && (selectionRange.endDate !== prevProps.endDate || selectionRange.startDate !== prevProps.startDate))) {

            this.isInitDone = true;

            this.setState({
                selectionRange: selectionRange
            });
        }
    }

    /** date range selection change callback **/
    handleDateRangeSelectCallback = (ranges) => {
        try {
            let selectionRange = ranges['selection'];
            this.setState({
                selectionRange: selectionRange
            });
        } catch (ex) {
            console.log("handleDateRangeSelectCallback : error : ", ex);
        }
    };

    /** ok button callback **/
    handleOKButtonAction = () => {
        try {
            const {callbackOK} = this.props;
            const {selectionRange} = this.state;

            if (callbackOK) {
                callbackOK(selectionRange);
            }
        } catch (ex) {
            console.log("handleOKButtonAction : error : ", ex);
        }
    };

    /** cancel button callback **/
    handleCancelButtonAction = () => {
        try {
            const {callbackCancel} = this.props;
            if (callbackCancel) {
                callbackCancel();
            }
        } catch (ex) {
            console.log("handleCancelButtonAction : error : ", ex);
        }
    };

    getItemsList = (key) => {
        switch (key) {
            case "time_zone":
                return this.props.timeZoneList;
            default:
                return [];
        }
    };

    /****
     * to handle item get events
     ****/
    getItemValue = (key) => {
        switch (key) {
            case "time_zone": 
                return this.state.selectedTimeZone;
            default:
                return "";
        }
    };

    handleChange = (key, value) => {
        switch (key) {
            case "time_zone": {
                this.props.updateLocalTimezoneSelection(value)
                break;
            }
            default:
                break;
        }
    };

    renderTimeZoneComponent = () => {
        const item = {
            'key': 'time_zone',
            'type': 'dropdown',
            'style': {},
            'labelClass': "",
            'localisation': "DIC_USER_SETTINGS_TIME_ZONE",
            'isMandatory': true,
        }
        return AppUtils.getFormComponent({
            'key': item['key'],
            'type': item['type'],
            'style': item['style'],
            'labelClass': item['labelClass'],
            'localisation': item['localisation'],
            'isMandatory': item['isMandatory'],
            'changeCallback': this.handleChange,
            'items': this.getItemsList(item['key']),
            'value': this.getItemValue(item['key']),
            'defaultValue': this.getItemValue(item['key']),
        })
    }



    render() {

        const {
            isVisible,
            callbackClosePopup,
            withTimeZone
        } = this.props;

        const {selectionRange, defaultDateRange} = this.state;

        return (
            <Dialog open={isVisible} onClose={callbackClosePopup && callbackClosePopup}>

                <DialogContent>
                    {withTimeZone && (
                        <div className="col-md-12 col-sm-12 col-12" style={{height: "100px"}}>
                            {this.renderTimeZoneComponent()}
                        </div>
                    )}
                    <DateRangePicker
                        className={"DateRangeWrapper"}
                        ranges={[selectionRange]}
                        onChange={this.handleDateRangeSelectCallback}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        maxDate={new Date()}
                        staticRanges = {[...defaultStaticRanges, ...defaultDateRange]}
                    />

                </DialogContent>

                <DialogActions className="pb-3 mr-2">

                    <Button variant="contained" className="jr-btn text-black transparentButton" style={{backgroundColor: FILTER_CLEAR_BUTTON_TRANSPARENT_COLOR}} onClick={this.handleCancelButtonAction}>
                        <span className="transparentButton"><IntlMessages id="DIC_CONNECT_REPORTS_DATE_RANGE_CANCEL"/></span>
                    </Button>

                    <Button variant="contained" className="jr-btn text-white" style={{backgroundColor: FILTER_SEARCH_BUTTON_COLOR}} onClick={this.handleOKButtonAction} color="primary">
                        <span className="pl-2 pr-2"><IntlMessages id="DIC_CONNECT_REPORTS_DATE_RANGE_OK"/></span>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, settings } = state;
    const {
        user,
        localSelectedTimeZone
    } = auth;

    return {localSelectedTimeZone, user, timeZoneList: settings.timeZoneList}
};

export default connect(mapStateToProps, {
    updateLocalTimezoneSelection,
    getAllTimezones
})(WoVoDateRangePicker);