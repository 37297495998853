
/** client module save **/
export const CLIENT_MODULE_NEW_MODULE_SAVE = 'CLIENT_MODULE_NEW_MODULE_SAVE';
export const CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS = 'CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS';
export const CLIENT_MODULE_NEW_MODULE_SAVE_ERROR = 'CLIENT_MODULE_NEW_MODULE_SAVE_ERROR';
export const CLIENT_MODULE_NEW_MODULE_SAVE_NONE = 'CLIENT_MODULE_NEW_MODULE_SAVE_NONE';

/** client module admin list **/
export const CLIENT_MODULE_ADMIN_LIST = 'CLIENT_MODULE_ADMIN_LIST';
export const CLIENT_MODULE_ADMIN_LIST_SUCCESS = 'CLIENT_MODULE_ADMIN_LIST_SUCCESS';
export const CLIENT_MODULE_ADMIN_LIST_ERROR = 'CLIENT_MODULE_ADMIN_LIST_ERROR';
export const CLIENT_MODULE_ADMIN_LIST_NONE = 'CLIENT_MODULE_ADMIN_LIST_NONE';

/** client module set current item for edit **/
export const CLIENT_MODULE_SET_CURRENT_MODULE = 'CLIENT_MODULE_SET_CURRENT_MODULE';
export const CLIENT_MODULE_RESET_CURRENT_MODULE = 'CLIENT_MODULE_RESET_CURRENT_MODULE';
export const CLIENT_MODULE_CONFIGURATION_SET_MODULE = 'CLIENT_MODULE_CONFIGURATION_SET_MODULE';

/** for getting client module configuration  **/
export const CLIENT_MODULE_GET_CONFIGURATION = 'CLIENT_MODULE_GET_CONFIGURATION';
export const CLIENT_MODULE_GET_CONFIGURATION_SUCCESS = 'CLIENT_MODULE_GET_CONFIGURATION_SUCCESS';
export const CLIENT_MODULE_GET_CONFIGURATION_ERROR = 'CLIENT_MODULE_GET_CONFIGURATION_ERROR';
export const CLIENT_MODULE_GET_CONFIGURATION_NONE = 'CLIENT_MODULE_NEW_MODULE_SAVE_NONE';

/** for setting client module configuration  **/
export const CLIENT_MODULE_SET_CONFIGURATION = 'CLIENT_MODULE_SET_CONFIGURATION';
export const CLIENT_MODULE_SET_CONFIGURATION_SUCCESS = 'CLIENT_MODULE_SET_CONFIGURATION_SUCCESS';
export const CLIENT_MODULE_SET_CONFIGURATION_ERROR = 'CLIENT_MODULE_SET_CONFIGURATION_ERROR';
export const CLIENT_MODULE_SET_CONFIGURATION_NONE = 'CLIENT_MODULE_SET_CONFIGURATION_NONE';

