import {
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE,

     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE,

     RESET_REGISTRATION_GET_LIST,
     RESET_REGISTRATION_GET_LIST_SUCCESS,
     RESET_REGISTRATION_GET_LIST_ERROR,
     RESET_REGISTRATION_GET_LIST_INPROGRESS,
     RESET_REGISTRATION_GET_LIST_NONE,

     UPDATE_RESET_REGISTRATION_SUMMARY_DATA,

     RESET_REGISTRATION_DOWNLOAD_REPORT,
     RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS,
     RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR,

     RESET_REGISTRATION_GLOBAL_STATE
} from '../../../constants/actions/wc/resetRegistration/actionTypesResetRegistration';

/**Action to handle click on download template */
export function resetRegistrationDownloadTemplate(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_TEMPLATE,
          payload: params
     }
};

/**Action to handle download template api call in progress */
export function resetRegistrationDownloadTemplateInProgress() {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS
     }
};

/**Action to handle download template api call success */
export function resetRegistrationDownloadTemplateSuccess(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS,
          payload: params
     }
};

/**Action to handle download template api call error */
export function resetRegistrationDownloadTemplateError(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR,
          payload: params
     }
};

/**Action to reset download template api call status */
export function resetRegistrationDownloadTemplateNone() {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE
     }
};

/**Action to handle the file upload */
export function resetRegistrationVerifyFileUpload(params) {
     return {
          type: RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION,
          payload: params
     }
};

/**Action to handle the file upload in progress*/
export function resetRegistrationVerifyFileUploadInProgress() {
     return {
          type: RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS
     }
};

/**Action to handle file upload api call success */
export function resetRegistrationVerifyFileUploadSuccess(params) {
     return {
          type: RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS,
          payload: params
     }
};

/**Action to handle file upload api call error */
export function resetRegistrationVerifyFileUploadError(params) {
     return {
          type: RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR,
          payload: params
     }
};

/**Action to reset file upload api call status */
export function resetRegistrationVerifyFileUploadNone() {
     return {
          type: RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE
     }
};

/**Action to handle the get list */
export function resetRegistrationGetList(params) {
     return {
          type: RESET_REGISTRATION_GET_LIST,
          payload: params
     }
};

/**Action to handle the get list in progress*/
export function resetRegistrationGetListInProgress() {
     return {
          type: RESET_REGISTRATION_GET_LIST_INPROGRESS
     }
};

/**Action to handle get list api call success */
export function resetRegistrationGetListSuccess(params) {
     return {
          type: RESET_REGISTRATION_GET_LIST_SUCCESS,
          payload: params
     }
};

/**Action to handle get list api call error */
export function resetRegistrationGetListError(params) {
     return {
          type: RESET_REGISTRATION_GET_LIST_ERROR,
          payload: params
     }
};

/**Action to reset get list api call status */
export function resetRegistrationGetListNone() {
     return {
          type: RESET_REGISTRATION_GET_LIST_NONE
     }
};

/**Action to update state for summary data */
export function updateSummaryData(params) {
     return {
          type: UPDATE_RESET_REGISTRATION_SUMMARY_DATA,
          payload: params
     }
}

/**Action to download report */
export function downloadReport(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_REPORT,
          payload: params
     }
}

/**Action to download report success*/
export function downloadReportSuccess(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS,
          payload: params
     }
}

/**Action to download report error*/
export function downloadReportError(params) {
     return {
          type: RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR,
          payload: params
     }
}

/**Acton to reset all the global state */
export function resetGlobalState() {
     return {
          type: RESET_REGISTRATION_GLOBAL_STATE
     }
}