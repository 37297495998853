
export const SURVEY_RESPONSE_GET_ALL_RECORDS = 'SURVEY_RESPONSE_SURVEY_GET_ALL_RECORDS';
export const SURVEY_RESPONSE_GET_ALL_RECORDS_SUCCESS = 'SURVEY_RESPONSE_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_RESPONSE_SAVE_RESPONSE = "SURVEY_RESPONSE_SAVE_RESPONSE";
export const SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS = "SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS";
export const SURVEY_RESPONSE_SAVE_RESPONSE_ERROR = "SURVEY_RESPONSE_SAVE_RESPONSE_ERROR";

export const SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND = "SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND";
export const SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS = "SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS";
export const SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR = "SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR";
export const SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_NONE = "SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_NONE";

export const SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER = 'SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER';
export const SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS = 'SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS';
export const SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR = 'SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR';
export const SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET = 'SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET';
export const SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR = 'SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR';

export const SURVEY_RESPONSE_UPDATE_LANGUAGE = 'SURVEY_RESPONSE_UPDATE_LANGUAGE';
export const SURVEY_RESPONSE_SET_EMPLOYEE_ID = 'SURVEY_RESPONSE_SET_EMPLOYEE_ID';
export const SURVEY_RESPONSE_SET_IDENTIFIER = 'SURVEY_RESPONSE_SET_IDENTIFIER';

export const SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS = 'SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS';

export const SURVEY_RESPONSE_CHANGE_SURVEY_STATUS = 'SURVEY_RESPONSE_CHANGE_SURVEY_STATUS';
export const SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS = 'SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS';
export const SURVEY_RESPONSE_SET_CURRENT_CLIENT = 'SURVEY_RESPONSE_SET_CURRENT_CLIENT';