
export const WOVO_USER_ROLES = {
    MEMBER : "Member",
    DASHBOARD_AGENT : "Dashboard Agent",
    DASHBOARD_MANAGER : "Dashboard Manager",
    BRAND_DASHBOARD_MANAGER : "Brand Dashboard Manager",
    BRAND_DASHBOARD_MANAGER_PHASE_1 : "Brand Dashboard Manager - Phase 1",
    BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR : "Brand Dashboard Manager - Under Armour",
    SUPER_ADMIN : "Super Admin",
    DASHBOARD_AGENT_PHASE_1 : "Dashboard Agent - Phase 1",
    DASHBOARD_MANAGER_PHASE_1 : "Dashboard Manager - Phase 1",
    DASHBOARD_MANAGER_PHASE_1_CONTRIBUTE : "Dashboard Manager (Contribute) - Phase 1",
    DASHBOARD_AGENT_PHASE_1_CONTRIBUTE : "Dashboard Agent (Contribute) - Phase 1",
    DO_NOT_USE : "Do Not Use",
    DASHBOARD_MANAGER_PAYSLIP : "Dashboard Manager (Payslip Capable)",
    DASHBOARD_AGENT_PAYSLIP : "Dashboard Agent (Payslip Capable)",
    DASHBOARD_AGENT_PAYSLIP_CONTRIBUTE : "Dashboard Agent (Payslip Capable Contribute)",
    ADIDAS_SURVEY_PILOT_DASHBOARD_MANAGER : "adidasSurveyPilotDashboardManager",
    DASHBOARD_MANAGER_FAST_RETAILING : "Dashboard Manager Fast Retailing",
    BRAND_DASHBOARD_TCP : "Brand Dashboard TCP",
    DEMO_DASHBOARD_USER : "Demo Dashboard User",
    DASHBOARD_MANAGER_QA : "dashboard Manager QA",
    HR_PAYROLL_TEAM : "HR Payroll Team",
    PAYROLL_TEAM_ONLY : "PayrollTeamOnly",
    DASHBOARD_MANAGER_PAYSLIP_ROLE : "Dashboard manager Payslip role"
};
