import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    SURVEY_CATEGORY_GET_ALL_RECORDS,
    SURVEY_CATEGORY_NEW_EDIT_SAVE,
    SURVEY_CATEGORY_UPDATE_STATUS,

} from "../../../constants/ActionTypes";

import {
    HTTP_TIMEOUT,
    URL_SURVEY_CATEGORY_LIST,
    URL_SURVEY_CATEGORY_SAVE, URL_SURVEY_CATEGORY_UPDATE,
    URL_SURVEY_CATEGORY_UPDATE_STATUS
} from "../../../constants/Urls";

import {
    getSurveyCategoriesError, getSurveyCategoriesSuccess,
    saveSurveyCategoryError,
    saveSurveyCategorySuccess,
    surveyCategoryUpdateStatusError,
    surveyCategoryUpdateStatusSuccess

} from "../../../actions";
import AppUtils from "../../../util/util";


/************ methods : http *******************/

/** http : Survey Category : http save **/
const httpSaveSurveyCategory = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_CATEGORY_SAVE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Category : http update **/
const httpUpdateSurveyCategory = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_CATEGORY_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Category : all records **/
const httpGetSurveyCategories = async () =>
    await axios.get(baseUrl + URL_SURVEY_CATEGORY_LIST, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Category : http update status **/
const httpSurveyCategoryUpdateStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_CATEGORY_UPDATE_STATUS, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/************ methods : http response handler *******************/

/** http : Survey Category : http response handler **/
function* handleHttpSaveSurveyCategoryResponse({payload}) {
    try {

        let result = yield call( payload.id ? httpUpdateSurveyCategory : httpSaveSurveyCategory,payload);

        if (result.message) {
            yield put(saveSurveyCategoryError(result.response));
        } else {
            yield put(saveSurveyCategorySuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyCategoryError(error));
    }
}

/** http : Survey Category list : http response handler **/
function* handleHttpGetSurveyCategoriesListResponse() {
    try {

        let result = yield call(httpGetSurveyCategories);

        if (result.message) {
            yield put(getSurveyCategoriesError(result.response));
        } else {
            yield put(getSurveyCategoriesSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyCategoriesError(error));
    }
}

/** http : Survey Category status update : http response handler **/
function* handleHttpUpdateSurveyCategoryStatusResponse({payload}) {
    try {

        let result = yield call(httpSurveyCategoryUpdateStatus, payload);

        if (result.message) {
            yield put(surveyCategoryUpdateStatusError(result.response));
        } else {
            yield put(surveyCategoryUpdateStatusSuccess(result));
        }
    } catch (error) {
        yield put(surveyCategoryUpdateStatusError(error));
    }
}

/************ methods : export access *******************/

/** Survey Category : save **/
export function* saveSurveyCategory() {
    yield takeEvery(SURVEY_CATEGORY_NEW_EDIT_SAVE, handleHttpSaveSurveyCategoryResponse);
}

/** Survey Category : get all records **/
export function* getSurveyCategoriesList() {
    yield takeEvery(SURVEY_CATEGORY_GET_ALL_RECORDS, handleHttpGetSurveyCategoriesListResponse);
}

/** Survey Category : update status **/
export function* updateSurveyCategoryStatus() {
    yield takeEvery(SURVEY_CATEGORY_UPDATE_STATUS, handleHttpUpdateSurveyCategoryStatusResponse);
}

export default function* rootSaga() {
    yield all([
        fork(saveSurveyCategory),
        fork(getSurveyCategoriesList),
        fork(updateSurveyCategoryStatus),

    ]);
}
