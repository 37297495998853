import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import commonStyles from "../../../util/commonStyle";
import {
    INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR,
    INDUSTRY_TYPE_GET_ALL_RECORDS_ERROR,
    INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS,
    INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS,
    INDUSTRY_TYPE_UPDATE_STATUS_NONE,
    INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE
} from "../../../constants/ActionTypes";

const industryTypeHeaders = [
    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_INDUSTRY_TYPE_SL.NO"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_INDUSTRY_TYPE_HEADER_NAME"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_INDUSTRY_TYPE_ACTIONS"/>
    }
]

const industryTypeInputs = [
    {
        'key': 'name',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_INDUSTRY_TYPE_EDIT_NEW_NAME",
        'isMandatory': true,
    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'localisation': "DIC_SETTINGS_INDUSTRY_TYPE_NEW_EDIT_IS_ACTIVE",
        'style': {
            paddingBottom: "10%",
            marginTop: "1%",
            marginLeft: "-10px"
        }
    },
]

const initialData = {
    tableColumns: industryTypeHeaders,
    industryTypeInputs: industryTypeInputs,
    industryTypes: [],
    dropDownIndustries: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedItem: null,
    industryTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    industryTypeFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    industryTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    windowWidth: 0
}

const IndustryTypes = (state = initialData, action) => {
    
    switch (action.type) {
        
        case INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedItem: action.payload
            }
        
        case INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false
            }
        
        case INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                industryTypeSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        
        case INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isErrorMessage: false,
                isSuccessMessage: true,
                isPopUpVisible: false,
                selectedItem: null,
                industryTypeSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };
        
        case INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: false,
                selectedItem: null,
                industryTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false
            };
        
        case INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS:
            
            let dropDownIndustries = generateDropdownIndustriesList(action.payload.data);
            
            return {
                ...state,
                industryTypeFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                industryTypes: action.payload.data,
                dropDownIndustries: dropDownIndustries
            };
        
        case INDUSTRY_TYPE_GET_ALL_RECORDS_ERROR:
            return {
                ...state,
                industryTypeFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                industryTypes: action.payload.data,
                dropDownIndustries: []
            };
        
        case INDUSTRY_TYPE_UPDATE_STATUS_NONE:
            return {
                ...state,
                industryTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            };
        
        case INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                industryTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
            };
        
        case INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE:
            return {
                ...state,
                industryTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        
        default:
            return state
    }
}

function generateDropdownIndustriesList(data) {
    let result = [];
    try {
        for (let i = 0; i < data.length; i++) {
            
            if (data[i]["is_active"]) {
                result.push({
                    "label": data[i]["name"],
                    "value": data[i]["id"]
                });
            }
        }
    } catch (ex) {
        console.error("generateDropdownIndustriesList : error : ", ex);
    }
    
    return result;
}

export default IndustryTypes
