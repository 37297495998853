import React from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import {
  SWITCH_BASE_ON_ENABLE,
  SWITCH_BASE_ON_DISABLE,
  SWITCH_BASE_OFF_ENABLE,
  SWITCH_BASE_OFF_DISABLE,
  SWITCH_TRACK_ON_ENABLE,
  SWITCH_TRACK_ON_DISABLE,
  SWITCH_TRACK_OFF_ENABLE,
  SWITCH_TRACK_OFF_DISABLE
} from '../../constants/customUIConfig';


const useStyles = makeStyles(theme => ({
  track: {
    backgroundColor: (props) => !props.disabled ? SWITCH_TRACK_OFF_ENABLE : SWITCH_TRACK_OFF_DISABLE,
  },
  switchBase: {
    color: (props) => !props.disabled ? SWITCH_BASE_OFF_ENABLE : SWITCH_BASE_OFF_DISABLE,
    "&.Mui-checked": {
      color: (props) => !props.disabled ? SWITCH_BASE_ON_ENABLE : SWITCH_BASE_ON_DISABLE
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: (props) => !props.disabled ? SWITCH_TRACK_ON_ENABLE : SWITCH_TRACK_ON_DISABLE,
      opacity: 1
    }
  }
}));

function LSSwitch(props) {

  const classes = useStyles(props);

  return (
      <Switch
        classes={{
          switchBase: classes.switchBase,
          track: classes.track
        }}
        checked={props.value}
        onChange={(e) => {

          if (props.changeCallback) {
            props.changeCallback(props.key, e.target.value)
          }
        }}
        aria-label={props.key}
        disabled={props.disabled}
      />
  );
}

export default LSSwitch;
