import { HTTP_REQUEST_STATUS, NEW_PAYSLIP_ACTION_TYPES } from "../../constants/constants";
import React from "react";
import IntlMessages from "../../util/IntlMessages";
import {
    PAYSLIP_GET_PAGINATED_LIST_PROGRESS,
    PAYSLIP_GET_PAGINATED_LIST_SUCCESS,
    PAYSLIP_GET_PAGINATED_LIST_ERROR,
    PAYSLIP_GET_PAGINATED_LIST_NONE,
    PAYSLIP_PREVIEW_DETAILS_UPDATE,
    RELOAD_PAYSLIP_LIST,
    SEND_PAYSLIP_ERROR,
    SEND_PAYSLIP_SUCCESS,
    SEND_PAYSLIP_NONE,
    UPDATE_PAYSLIP_ERROR,
    UPDATE_PAYSLIP_SUCCESS,
    UPDATE_PAYSLIP_NONE,
    VERIFY_PAYSLIP_DATA_UPLOADED_ERROR,
    VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS,
    VERIFY_PAYSLIP_DATA_UPLOADED_NONE,
    VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR,
    NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS,
    SET_SELECTED_INPUT_VALUES,
    UPDATE_UPLOAD_CONTAINER_TEXT,
    PAYSLIP_RESET_STATE
} from "../../constants/actions/payslip/actionTypesPayslip";
import AppUtils from "../../util/util";
import UIUtils from "../../util/ui_utils";
import IconListItem from "../../components/IconListItem";
import {
    PAYSLIP_COMMUNICATION_CHANNELS,
    PAYSLIP_STATUS_MAPPING,
    PAYSLIP_PREVIEW_ACTIONS,
    PAYSLIP_STATUS,
    APPLICATION_DATE_FORMAT,
    APPLICATION_DATE_TIME_FORMAT
} from "../../constants/config";
import { baseServerUrl } from "util/keys";
import  MomentTimezone from "moment-timezone";

export const TABLE_PAGINATION_OPTIONS = [5, 15, 50, 100, 150, 200];

const statusConfig = {
    1: "DIC_KEY_PAY_SLIP_FILTER_DRAFT",
    2: "DIC_KEY_PAY_SLIP_FILTER_SCHEDULED",
    3: "DIC_KEY_PAY_SLIP_FILTER_SENDING_INPROGRESS",
    4: "DIC_KEY_PAY_SLIP_FILTER_COMPLETED",
    5: "DIC_KEY_PAY_SLIP_FILTER_PARTIAL",
    6: "DIC_KEY_PAY_SLIP_FILTER_FAILED",
    7: "DIC_KEY_PAY_SLIP_FILTER_CANCELLED_INPROGRESS",
    8: "DIC_KEY_PAY_SLIP_FILTER_LOW_CREDIT",
    0: "DIC_KEY_PAY_SLIP_FILTER_DELETED",
};

const channelConfig = {
    1: "DIC_KEY_PAY_SLIP_FILTER_ALL",
    2: "DIC_KEY_PAY_SLIP_FILTER_APP",
    3: "DIC_KEY_PAY_SLIP_FILTER_SMS",
};

const payslipTableHeaderConfig = [

    {
        id: 'title',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_TITLE" />,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_highlight",
        isDynamicComponent: true
    },
    {
        id: 'id',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_ID" />,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'created_by',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_CREATED_BY" />,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'channel_label',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_CHANNEL" />,
        visible: true,
        sortable: false,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'total_contacts',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_CONTACTS" />,
        visible: true,
        sortable: false,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'status_label',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_STATUS" />,
        className: "ls_table_column_text_normal",
        visible: true,
        sortable: true,
        isClickable: true,
        isDynamicComponent: true
    },
    {
        id: 'delivery_end_date_label',
        label: <IntlMessages id="DIC_KEY_TABLE_PAY_SLIP_DELIVERY_DATE" />,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal cursor-pointer"
    }
];

let newPayslipRightContainerFilters = [
    {
        'key': NEW_PAYSLIP_ACTION_TYPES.TITLE_NEW_PAYSLIP,
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_PAYSLIP_WITH_INFO,
        'style': { maginBottom: '0' },
        'localisation': "DIC_MAIN_PAGE_TITLE_NEW_PAY_SLIP",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholder': "ex: Pay Slip January",
        'variant': "outlined",
        'parent_class': "float-none",
        'minRows': '1',
        'labelClass': "payslip_titles_for_inputs"
    },
    {
        'key': NEW_PAYSLIP_ACTION_TYPES.DESCRIPTION_NEW_PAYSLIP,
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO,
        'style': {},
        'localisation': "DIC_MAIN_PAGE_DESCRIPTION_NEW_PAY_SLIP",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'variant': "outlined",
        'parent_class': "float-none",
        'placeholder': "ex: here is how your pay is broken down...",
        'minRows': '2',
        'labelClass': "payslip_titles_for_inputs"
    },
    {
        'key': NEW_PAYSLIP_ACTION_TYPES.MESSAGE_NEW_PAYSLIP,
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO_AND_TEXT_COUNT,
        'style': {},
        'localisation': "DIC_MAIN_PAGE_MESSAGE_NEW_PAY_SLIP",
        'parent_class': "form_container_root",
        'variant': "outlined",
        'minRows': '2',
        'placeholder': "ex: Your Pay Slip [Link Pay Slip auto generated]",
        'textCount': 25,
        'maxTextCount': '160 Characters',
        'labelClass': "payslip_titles_for_inputs",
        'maxCount': 160,
    }
]

let newPayslipLeftContainerFilters = [
    {
        'key': 'wc_view_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_WC_VIEW_ACTION_LABEL_DETAIL_INFORMATION" />,
    }
]

let deleteButtonDetails = [
    {
        "key": NEW_PAYSLIP_ACTION_TYPES.DELETE_PAYSLIP,
        'title': <IntlMessages id="DIC_MAIN_PAGE_DELETE_NEW_PAY_SLIP" />,
        "type": UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'parent_class': "float_right"
    }
]

let cancelButtonDetails = [
    {
        "key": NEW_PAYSLIP_ACTION_TYPES.CANCEL_NEW_PAYSLIP,
        "label_id": "DIC_MAIN_PAGE_CANCEL_NEW_PAY_SLIP",
        'title': <IntlMessages id="DIC_MAIN_PAGE_CANCEL_NEW_PAY_SLIP" />,
        "type": UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'parent_class': "float_right"
    }
]

let saveButtonDetails = [
    {
        "key": NEW_PAYSLIP_ACTION_TYPES.SAVE_NEW_PAYSLIP,
        "label_id": "DIC_MAIN_PAGE_SAVE_NEW_PAY_SLIP",
        'title': <IntlMessages id="DIC_MAIN_PAGE_SAVE_NEW_PAY_SLIP" />,
        "type": UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'parent_class': "float_right payslip_hover_class"
    }
]

let previewButtonDetails = [
    {
        "key": NEW_PAYSLIP_ACTION_TYPES.PREVIEW_NEW_PAYSLIP,
        "label_id": "DIC_MAIN_PAGE_PREVIEW_NEW_PAY_SLIP",
        'title': <IntlMessages id="DIC_MAIN_PAGE_PREVIEW_NEW_PAY_SLIP" />,
        "type": UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'parent_class': "float_right"
    }
]

const PAYSLIP_STATUS_FILTER_OPTIONS = [
    {
        "content": <IconListItem
            headerText={"DIC_KEY_WC_LIST_FILTER_VIEW_STATUS_ALL"}
        />,
        "headerText": "DIC_KEY_WC_LIST_FILTER_VIEW_STATUS_ALL",
        "value": PAYSLIP_STATUS_MAPPING.ALL,
        "id": PAYSLIP_STATUS.ALL,
        "key": PAYSLIP_STATUS.ALL
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_DRAFT"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_DRAFT",
        "value": PAYSLIP_STATUS_MAPPING.DRAFT,
        "id": PAYSLIP_STATUS.DRAFT,
        "key": PAYSLIP_STATUS.DRAFT
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_SCHEDULED"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_SCHEDULED",
        "value": PAYSLIP_STATUS_MAPPING.SCHEDULED,
        "id": PAYSLIP_STATUS.SCHEDULED,
        "key": PAYSLIP_STATUS.SCHEDULED
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_COMPLETED"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_COMPLETED",
        "value": PAYSLIP_STATUS_MAPPING.COMPLETED,
        "id": PAYSLIP_STATUS.COMPLETED,
        "key": PAYSLIP_STATUS.COMPLETED
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_PARTIAL"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_PARTIAL",
        "value": PAYSLIP_STATUS_MAPPING.PARTIAL,
        "id": PAYSLIP_STATUS.PARTIAL,
        "key": PAYSLIP_STATUS.PARTIAL
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_SENDING"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_SENDING",
        "value": PAYSLIP_STATUS_MAPPING.SENDING,
        "id": PAYSLIP_STATUS.SENDING,
        "key": PAYSLIP_STATUS.SENDING
    },
    {
        "content": <IconListItem
            headerText={"DIC_KEY_PAY_SLIP_FILTER_FAILED"}
        />,
        "headerText": "DIC_KEY_PAY_SLIP_FILTER_FAILED",
        "value": PAYSLIP_STATUS_MAPPING.FAILED,
        "id": PAYSLIP_STATUS.FAILED,
        "key": PAYSLIP_STATUS.FAILED
    }
]
const initialData = {
    TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,
    PAYSLIP_STATUS_FILTER_OPTIONS: PAYSLIP_STATUS_FILTER_OPTIONS,
    payslipTableHeaderConfig: payslipTableHeaderConfig,
    selectedStatusData: [],
    getPaySlipListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    outReachHeaderDetails: {},
    verificationReportLink: '',
    deliveryReportLink: '',
    outReachSummary: {},
    breakDown: [],
    status: PAYSLIP_STATUS_MAPPING.VERIFY,
    activeButtonDetails: {},
    nonActiveButtonDetails: {},
    payslipTitle: '',
    payslipDescription: '',
    payslipMessage: '',
    channel: PAYSLIP_COMMUNICATION_CHANNELS['App'],
    payslipDetails: {},
    isLowCredit: false,
    payslipId: 0,
    reloadPayslipList: false,
    uplodedExcelLink: '',
    alertMessage: '',
    sendPayslipStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    updatePayslipStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    deliveryStartDate: '',
    deliveryEndDate: '',
    newPayslipLeftContainerFilters: newPayslipLeftContainerFilters,
    newPayslipRightContainerFilters: newPayslipRightContainerFilters,
    verifyPayslipDataStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    downloadPayslipAttachmentStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    payslipDateTime: '',
    payslipImmediateStatus: false,
    payslipEmail: "",
    payslipFile: null,
    isDeletedStatus: false,
    payslipUploadSectionContent: {
        supText: 'DIC_MAIN_PAGE_TEXT_TEMPLATE_BESIDE_UPLOAD_NEW_PAY_SLIP',
        subText: 'DIC_MAIN_PAGE_TEXT_TEMPLATE_BESIDE_UPLOAD_SUB_TEXT_NEW_PAY_SLIP',
        subTextClass: 'payslip_upload_container_info_sub_header'
    },
    deleteButtonDetails: deleteButtonDetails,
    cancelButtonDetails: cancelButtonDetails,
    saveButtonDetails: saveButtonDetails,
    previewButtonDetails: previewButtonDetails,
    showAppOutreach: false,
    showSMSOutreach: false,
    showOnlySMSOutreach: false,
    defaultVerificationSummary: {},
    defaultDeliverySummary: {},
    userTimeZone: ''
};

const ReducerPayslip = (state = initialData, action) => {

    switch (action.type) {

        case PAYSLIP_GET_PAGINATED_LIST_PROGRESS: {
            return {
                ...state,
                getPaySlipListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case PAYSLIP_GET_PAGINATED_LIST_SUCCESS: {

            let selectedStatusData = [];
            const {data, timezone} = action.payload;

            if (data && data.results) {
                let paySlipData = parsePayslipData(data.results, timezone);
                let count = data.count;
                selectedStatusData = [...paySlipData];

                return {
                    ...state,
                    count,
                    selectedStatusData: selectedStatusData,
                    getPaySlipListStatus: HTTP_REQUEST_STATUS.SUCCESS,
                    userTimeZone: timezone

                };
            } else {
                return state;
            }
        }

        case PAYSLIP_GET_PAGINATED_LIST_ERROR: {
            return {
                ...state,
                selectedStatusData: [],
                getPaySlipListStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload
            }
        }

        case PAYSLIP_GET_PAGINATED_LIST_NONE: {
            return {
                ...state,
                getPaySlipListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ''
            }
        }

        case PAYSLIP_PREVIEW_DETAILS_UPDATE: {

            let outReachSummary = {};
            let breakDown = [];
            let status = '';
            let channel = '';
            let title = '';
            let message = '';
            let description = '';
            let isLowCredit = false;
            let outReachHeaderDetails = {};
            let activeButtonDetails = {};
            let nonActiveButtonDetails = {};
            let payslipDetails = {};
            let payslipId = 0;
            let scheduledDate = '';
            let uplodedExcelLink = '';
            let deliveryReportLink = '';
            let deliveryStartDate = '';
            let deliveryEndDate = '';
            let payslipUploadSectionContent = state.payslipUploadSectionContent;
            let payslipImmediateStatus = state.payslipImmediateStatus;
            let defaultVerificationSummary = {};
            let defaultDeliverySummary = {};
            let verificationReportLink = '';

            let data = action.payload;

            if (typeof data !== 'undefined') {
                payslipId = typeof data.id !== 'undefined' && data.id;
                status = typeof data.status !== 'undefined' && data.status;
                title = typeof data.title !== 'undefined' && data.title;
                message = typeof data.message !== 'undefined' && data.message;
                description = typeof data.description !== 'undefined' && data.description;
                channel = typeof data.channel !== 'undefined' && data.channel;
                scheduledDate = typeof data.scheduled_date !== 'undefined' && data.scheduled_date;
                
                deliveryStartDate = AppUtils.convertDateToGivenFormat(data.delivery_start_date, APPLICATION_DATE_TIME_FORMAT, state.userTimeZone);
                deliveryEndDate = AppUtils.convertDateToGivenFormat(data.delivery_end_date, APPLICATION_DATE_TIME_FORMAT, state.userTimeZone);
                defaultVerificationSummary = data.verification_summary;
                defaultDeliverySummary = data.delivery_summary;
                payslipImmediateStatus = typeof data.immediate !== 'undefined' && data.immediate;

                outReachHeaderDetails = getOutreachHeaderDetails(data.status);
                activeButtonDetails = getSendButtonLabelAndAction(data.status);
                nonActiveButtonDetails = getCancelButtonLabelAndAction(data.status);
                payslipDetails = getPayslipDetails(data.id, data.scheduled_date, data.channel, state.userTimeZone);
                isLowCredit = checkLowCredit(data.channel, data.verification_summary, data.delivery_summary, data.status);
                outReachSummary = parseSummaryData(data.verification_summary, data.delivery_summary, channel, data.status);
                payslipUploadSectionContent = getUploadContainerTextForDraft(data.uploaded_file_name);

                breakDown = typeof data.breakdown !== 'undefined' && data.breakdown;
                uplodedExcelLink = typeof data.excel_file_download_link !== 'undefined' && data.excel_file_download_link;
                deliveryReportLink = typeof data.delivery_report_download_link !== 'undefined' && data.delivery_report_download_link;
                verificationReportLink = typeof data.verification_report_download_link !== 'undefined' && data.verification_report_download_link;
            }

            return {
                ...state,
                outReachHeaderDetails: outReachHeaderDetails,
                deliveryReportLink: deliveryReportLink,
                outReachSummary: outReachSummary,
                breakDown: breakDown,
                status: status,
                activeButtonDetails: activeButtonDetails,
                nonActiveButtonDetails: nonActiveButtonDetails,
                payslipDetails: payslipDetails,
                payslipTitle: title,
                payslipDescription: description,
                payslipMessage: message,
                payslipDateTime: scheduledDate,
                channel: channel,
                isLowCredit: isLowCredit,
                payslipId: payslipId,
                uplodedExcelLink: uplodedExcelLink,
                deliveryStartDate: deliveryStartDate,
                deliveryEndDate: deliveryEndDate,
                showAppOutreach: (channel === PAYSLIP_COMMUNICATION_CHANNELS['All'] || channel === PAYSLIP_COMMUNICATION_CHANNELS['App']),
                showSMSOutreach: channel === PAYSLIP_COMMUNICATION_CHANNELS['All'],
                showOnlySMSOutreach: channel === PAYSLIP_COMMUNICATION_CHANNELS['SMS'],
                payslipUploadSectionContent: payslipUploadSectionContent,
                payslipImmediateStatus: payslipImmediateStatus,
                payslipFile: null,
                defaultVerificationSummary: defaultVerificationSummary,
                defaultDeliverySummary: defaultDeliverySummary,
                verificationReportLink: verificationReportLink
            }
        }

        case RELOAD_PAYSLIP_LIST: {
            return {
                ...state,
                reloadPayslipList: action.payload
            }
        }

        case SEND_PAYSLIP_ERROR: {
            let alertMessage = '';

            if (action.payload && action.payload.response && action.payload.response.data) {
                if ('scheduled_date' in action.payload.response.data) {
                    alertMessage = <IntlMessages id={action.payload.response.data['scheduled_date'][0]} />;
                }
            }
            else if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return {
                ...state,
                sendPayslipStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }

        case SEND_PAYSLIP_SUCCESS: {
            let alertMessage = <IntlMessages id="DIC_KEY_PAYSLIP_SAVE_SUCCESS_MESSAGE" />;

            if (action.payload) {
                if (action.payload.message) {
                    alertMessage = action.payload.message;
                }
            }
            return {
                ...state,
                sendPayslipStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }

        case SEND_PAYSLIP_NONE: {
            return {
                ...state,
                sendPayslipStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ''
            }
        }

        case UPDATE_PAYSLIP_ERROR: {

            let alertMessage = '';

            if (action.payload && action.payload.response && action.payload.response.data) {
                if ('scheduled_date' in action.payload.response.data) {
                    alertMessage = <IntlMessages id={action.payload.response.data['scheduled_date'][0]} />;
                }
            }
            else if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return {
                ...state,
                updatePayslipStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }

        case UPDATE_PAYSLIP_SUCCESS: {

            let alertMessage = <IntlMessages id="DIC_KEY_PAYSLIP_UPDATE_SUCCESS_MESSAGE" />;

            if (action.payload) {
                if (action.payload.data && action.payload.data.is_deleted === true) {
                    alertMessage = <IntlMessages id="DIC_KEY_PAYSLIP_DELETE_SUCCESS_MESSAGE" />
                }
                else if (action.payload.message) {
                    alertMessage = action.payload.message;
                }
            }
            return {
                ...state,
                updatePayslipStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }

        case UPDATE_PAYSLIP_NONE: {
            return {
                ...state,
                updatePayslipStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ''
            }
        }

        case VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS: {

            let content = getUploadContainerText(HTTP_REQUEST_STATUS.IN_PROGRESS)

            return {
                ...state,
                verifyPayslipDataStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                payslipUploadSectionContent: content
            }
        }

        case VERIFY_PAYSLIP_DATA_UPLOADED_ERROR: {

            let content = getUploadContainerText(HTTP_REQUEST_STATUS.FAIL)

            return {
                ...state,
                verifyPayslipDataStatus: HTTP_REQUEST_STATUS.FAIL,
                payslipUploadSectionContent: content
            }
        }

        case VERIFY_PAYSLIP_DATA_UPLOADED_NONE: {

            return {
                ...state,
                verifyPayslipDataStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS: {

            let verificationReportLink = '';
            let outReachSummary = {};
            let breakDown = [];
            let channel = state.channel;
            let outReachHeaderDetails = {};
            let activeButtonDetails = {};
            let nonActiveButtonDetails = {};
            let status = PAYSLIP_STATUS_MAPPING.VERIFY;
            let payslipDetails = {};
            let isLowCredit = false;
            let defaultVerificationSummary = {};


            if (action.payload && action.payload.data && action.payload.data.data) {

                let data = action.payload.data.data;

                verificationReportLink = typeof data.download_link !== 'undefined' && data.download_link;
                defaultVerificationSummary = data.summary;
                outReachHeaderDetails = getOutreachHeaderDetails(status);
                activeButtonDetails = getSendButtonLabelAndAction(status);
                nonActiveButtonDetails = getCancelButtonLabelAndAction(status);
                outReachSummary = parseSummaryData(data.summary, data.delivery_summary, channel, status);
                breakDown = data.breakdown && data.breakdown;
                payslipDetails = getPayslipDetails(data.id, state.payslipDateTime, channel, state.userTimeZone);
                isLowCredit = checkLowCredit(data.channel, data.summary, data.delivery_summary, status);
            }

            let content = getUploadContainerText(HTTP_REQUEST_STATUS.SUCCESS, state.payslipFile)

            return {
                ...state,
                verificationReportLink: verificationReportLink,
                verifyPayslipDataStatus: HTTP_REQUEST_STATUS.SUCCESS,
                payslipUploadSectionContent: content,
                outReachHeaderDetails: outReachHeaderDetails,
                outReachSummary: outReachSummary,
                breakDown: breakDown,
                activeButtonDetails: activeButtonDetails,
                nonActiveButtonDetails: nonActiveButtonDetails,
                payslipDetails: payslipDetails,
                isLowCredit: isLowCredit,
                showAppOutreach: channel === PAYSLIP_COMMUNICATION_CHANNELS['All'] || channel === PAYSLIP_COMMUNICATION_CHANNELS['App'],
                showSMSOutreach: channel === PAYSLIP_COMMUNICATION_CHANNELS['All'],
                showOnlySMSOutreach: channel === PAYSLIP_COMMUNICATION_CHANNELS['SMS'],
                defaultVerificationSummary: defaultVerificationSummary
            }
        }

        /** payslip contact attachment download **/
        case NEW_PAYSLIP_ATTACHMENT_DOWNLOAD: {
            return {
                ...state,
                downloadPayslipAttachmentStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        /** payslip attachment download : success **/
        case NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS: {

            let alertMessage = '';

            if (action.payload) {
                alertMessage = typeof action.payload.message !== 'undefined' && action.payload.message;

                if (action.payload.data && action.payload.data.path) {
                    AppUtils.downloadFile(baseServerUrl + action.payload.data.path);
                }
            }

            return {
                ...state,
                downloadPayslipAttachmentStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
        }

        /** payslip attachment download : fail **/
        case NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR: {
            let alertMessage = "";

            if (action.payload.error) {
                alertMessage = action.payload.error;
            }
            return {
                ...state,
                downloadPayslipAttachmentStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: alertMessage
            }
        }

        /** payslip attachment download : none **/
        case NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE: {
            return {
                ...state,
                downloadPayslipAttachmentStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case SET_SELECTED_INPUT_VALUES: {

            let stateProp = '';
            let value = '';
            let showAppOutreach = state.showAppOutreach;
            let showSMSOutreach = state.showSMSOutreach;
            let showOnlySMSOutreach = state.showOnlySMSOutreach;
            let payslipDetails = state.payslipDetails;
            let outReachHeaderDetails = state.outReachHeaderDetails;
            let activeButtonDetails = state.activeButtonDetails;
            let nonActiveButtonDetails = state.nonActiveButtonDetails;
            let outReachSummary = state.outReachSummary;
            let isLowCredit = state.isLowCredit;

            if (typeof action.payload !== 'undefined') {

                for (const key in action.payload) {
                    stateProp = key;
                    value = action.payload[key];
                }
            }

            if (stateProp === 'channel') {
                showAppOutreach = (value === PAYSLIP_COMMUNICATION_CHANNELS['All'] || value === PAYSLIP_COMMUNICATION_CHANNELS['App']);
                showSMSOutreach = (value === PAYSLIP_COMMUNICATION_CHANNELS['All']);
                showOnlySMSOutreach = (value === PAYSLIP_COMMUNICATION_CHANNELS['SMS']);

                payslipDetails = getPayslipDetails(state.payslipId, state.payslipDateTime, value, state.userTimeZone);

                if (value === PAYSLIP_COMMUNICATION_CHANNELS['All'] || value === PAYSLIP_COMMUNICATION_CHANNELS['SMS']) {
                    isLowCredit = checkLowCredit(value, state.defaultVerificationSummary, state.defaultDeliverySummary, state.status);
                }
                else if (value === PAYSLIP_COMMUNICATION_CHANNELS['App']) {
                    isLowCredit = false;
                }
                outReachSummary = parseSummaryData(state.defaultVerificationSummary, state.defaultDeliverySummary, value, state.status);
            }
            if (stateProp === 'payslipImmediateStatus') {
                payslipDetails = getPayslipDetails(state.payslipId, state.payslipDateTime, state.channel, state.userTimeZone);
            }

            return {
                ...state,
                [stateProp]: value,
                showAppOutreach: showAppOutreach,
                showSMSOutreach: showSMSOutreach,
                showOnlySMSOutreach: showOnlySMSOutreach,
                payslipDetails: payslipDetails,
                outReachHeaderDetails: outReachHeaderDetails,
                activeButtonDetails: activeButtonDetails,
                nonActiveButtonDetails: nonActiveButtonDetails,
                outReachSummary: outReachSummary,
                isLowCredit: isLowCredit
            }
        }

        case UPDATE_UPLOAD_CONTAINER_TEXT: {

            const { supText, subText, subTextClass } = action.payload;

            let payslipUploadSectionContent = {
                supText: supText,
                subText: subText,
                subTextClass: subTextClass
            }
            return {
                ...state,
                payslipUploadSectionContent: payslipUploadSectionContent
            }
        }

        case PAYSLIP_RESET_STATE: {
            return initialData
        }

        default:
            return state
    }
};

export default ReducerPayslip;


function parsePayslipData(data, timezone) {
    let result = data;
    try {
        if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
                if (typeof result[i] !== "undefined") {

                    let deliveryTotal = 0;

                    if (typeof result[i]["verification_summary"] !== "undefined" &&
                        typeof result[i]["verification_summary"]["total"] !== "undefined") {

                        let verificationTotal = result[i]["verification_summary"]["total"];

                        if (typeof result[i]["delivery_summary"] !== "undefined" &&
                            typeof result[i]["delivery_summary"]["total_contacts_reached"] !== "undefined") {

                            deliveryTotal = result[i]["delivery_summary"]["total_contacts_reached"];
                        }

                        if (result[i]["status"] === PAYSLIP_STATUS_MAPPING.DRAFT ||
                            result[i]["status"] === PAYSLIP_STATUS_MAPPING.SCHEDULED ||
                            result[i]["status"] === PAYSLIP_STATUS_MAPPING.SENDING) {
                            result[i]["total_contacts"] = `${verificationTotal}`;
                        } else {
                            result[i]["total_contacts"] = `${deliveryTotal}/${verificationTotal}`;
                        }
                    }

                    result[i]["channel_label"] = "";
                    result[i]["status_label"] = "";

                    if (typeof result[i]["channel"] !== 'undefined') {
                        result[i]["channel_label"] = <IntlMessages id={channelConfig[result[i]["channel"]]} />;
                    }

                    if (typeof result[i]["status"] !== 'undefined') {
                        result[i]["status_label"] = <IntlMessages id={statusConfig[result[i]["status"]]} />;
                    }

                    if ([PAYSLIP_STATUS_MAPPING.COMPLETED, PAYSLIP_STATUS_MAPPING.PARTIAL, PAYSLIP_STATUS_MAPPING.FAILED].indexOf(result[i]["status"]) !== -1) {
                        result[i]["delivery_end_date_label"] = AppUtils.convertDateToGivenFormat(result[i]["delivery_end_date"], APPLICATION_DATE_FORMAT, timezone);
                    } else {
                        result[i]["delivery_end_date_label"] = null;
                    }

                    result[i]["delivery_start_date_label"] = AppUtils.convertDateToGivenFormat(result[i]["delivery_start_date"], APPLICATION_DATE_FORMAT, timezone);

                }
            }
        }
    } catch (e) {
        console.error("parsePayslipData error: ", e);
    }

    return result;
}

function getOutreachHeaderDetails(status) {

    let outreachLabelObj = {
        outreachHeaderLabel: '',
        info: '',
        reportDownloadLabel: ''
    };

    try {
        if (status === PAYSLIP_STATUS_MAPPING.VERIFY ||
            status === PAYSLIP_STATUS_MAPPING.DRAFT ||
            status === PAYSLIP_STATUS_MAPPING.SCHEDULED ||
            status === PAYSLIP_STATUS_MAPPING.SENDING) {
            outreachLabelObj.outreachHeaderLabel = 'DIC_KEY_PAYSLIP_PREVIEW_OUT_REACH_LABEL';
            outreachLabelObj.info = 'DIC_KEY_PAYSLIP_PREVIEW_OUT_REACH_INFO';
            outreachLabelObj.reportDownloadLabel = 'DIC_KEY_PAYSLIP_PREVIEW_VERIFICATION_REPORT_BUTTON_LABEL';
        } else {
            outreachLabelObj.outreachHeaderLabel = 'DIC_KEY_PAYSLIP_PREVIEW_SUMMARY_LABEL';
            outreachLabelObj.info = 'DIC_KEY_PAYSLIP_PREVIEW_SUMMARY_INFO';
            outreachLabelObj.reportDownloadLabel = 'DIC_KEY_PAYSLIP_PREVIEW_DELIVERY_REPORT_BUTTON_LABEL';
        }
    } catch (err) {
        console.error("getOutreachHeaderLabels : error : ", err);
    }
    return outreachLabelObj;
};

function getCancelButtonLabelAndAction(status) {

    let buttonObj = {
        label: '',
        action: ''
    };

    try {
        switch (status) {
            case PAYSLIP_STATUS_MAPPING.SENDING:
            case PAYSLIP_STATUS_MAPPING.SCHEDULED:
                buttonObj.label = 'DIC_KEY_PAYSLIP_PREVIEW_CANCEL_DELIVER_BUTTON';
                buttonObj.action = PAYSLIP_PREVIEW_ACTIONS.CANCEL_DELIVERY
                break;

            case PAYSLIP_STATUS_MAPPING.FAILED:
                buttonObj.label = 'DIC_KEY_PAYSLIP_PREVIEW_DELTE_BUTTON';
                buttonObj.action = PAYSLIP_PREVIEW_ACTIONS.DELETE_PAYSLIP
                break;

            default:
                break;
        }
    } catch (err) {
        console.error("getButtonLabelAndAction : error : ", err);
    }
    return buttonObj;
};

function getSendButtonLabelAndAction(status) {

    let buttonObj = {
        label: '',
        action: ''
    };

    try {
        switch (status) {
            case PAYSLIP_STATUS_MAPPING.VERIFY:
            case PAYSLIP_STATUS_MAPPING.DRAFT:
                buttonObj.label = 'DIC_KEY_PAYSLIP_PREVIEW_SEND_BUTTON';
                buttonObj.action = PAYSLIP_PREVIEW_ACTIONS.SEND_PAYSLIP
                break;

            case PAYSLIP_STATUS_MAPPING.FAILED:
            case PAYSLIP_STATUS_MAPPING.PARTIAL:
                buttonObj.label = 'DIC_KEY_PAYSLIP_PREVIEW_RE_SEND_BUTTON';
                buttonObj.action = PAYSLIP_PREVIEW_ACTIONS.RESEND_PAYSLIP
                break;

            default:
                break;
        }
    } catch (err) {
        console.error("getButtonLabelAndAction : error : ", err);
    }
    return buttonObj;
};

function getPayslipDetails(payslipId, scheduledDate, channel, userTimeZone) {

    let details = {
        'DIC_KEY_PAYSLIP_PREVIEW_PAYSLIP_ID_LABEL': '',
        'DIC_KEY_PAYSLIP_PREVIEW_DATE_TIME_LABEL': '',
        'DIC_KEY_PAYSLIP_PREVIEW_CHANNEL_LABEL': ''
    };

    try {
        details['DIC_KEY_PAYSLIP_PREVIEW_PAYSLIP_ID_LABEL'] = (typeof payslipId !== 'undefined' && payslipId !== 0) ? payslipId : '-';

        if(scheduledDate === '') {

            let displayDate = MomentTimezone.tz(userTimeZone).format(APPLICATION_DATE_FORMAT);

            details['DIC_KEY_PAYSLIP_PREVIEW_DATE_TIME_LABEL'] = displayDate;
        }
        
        if (typeof scheduledDate !== 'undefined' && scheduledDate !== '') {
            details['DIC_KEY_PAYSLIP_PREVIEW_DATE_TIME_LABEL'] = AppUtils.convertDateToGivenFormat(scheduledDate, APPLICATION_DATE_FORMAT, userTimeZone);
        }

        switch (channel) {
            case PAYSLIP_COMMUNICATION_CHANNELS['All']:
                details['DIC_KEY_PAYSLIP_PREVIEW_CHANNEL_LABEL'] = <IntlMessages id="DIC_KEY_PAYSLIP_PREVIEW_OUT_REACH_CHANNEL_APP_AND_SMS" />;
                break;

            case PAYSLIP_COMMUNICATION_CHANNELS['App']:
                details['DIC_KEY_PAYSLIP_PREVIEW_CHANNEL_LABEL'] = <IntlMessages id="DIC_KEY_PAYSLIP_PREVIEW_OUT_REACH_CHANNEL_APP" />;
                break;

            case PAYSLIP_COMMUNICATION_CHANNELS['SMS']:
                details['DIC_KEY_PAYSLIP_PREVIEW_CHANNEL_LABEL'] = <IntlMessages id="DIC_KEY_PAYSLIP_PREVIEW_OUT_REACH_CHANNEL_SMS" />;
                break;

            default:
                break;
        }
    } catch (err) {
        console.error("getPayslipDetails : error : ", err);
    }
    return details;
}

function checkLowCredit(channel, summary, deliverySummary, status) {

    let isLowCredit = false;

    try {

        switch (status) {
            case PAYSLIP_STATUS_MAPPING.VERIFY:
            case PAYSLIP_STATUS_MAPPING.DRAFT: {
                if (typeof summary !== 'undefined' && typeof channel !== 'undefined') {
                    if (channel === PAYSLIP_COMMUNICATION_CHANNELS.All) {
                        if (typeof summary.sms !== 'undefined' && typeof summary.credits_available !== 'undefined') {
                            return summary.credits_available < summary.sms;
                        }
                    }
                    else if (channel === PAYSLIP_COMMUNICATION_CHANNELS.SMS) {
                        if (typeof summary.sms_only !== 'undefined' && typeof summary.credits_available !== 'undefined') {
                            return summary.credits_available < summary.sms_only;
                        }
                    }
                }
                break;
            }

            case PAYSLIP_STATUS_MAPPING.PARTIAL:
            case PAYSLIP_STATUS_MAPPING.FAILED: {

                if (typeof summary !== 'undefined' && typeof deliverySummary !== 'undefined' && typeof channel !== 'undefined') {

                    if (channel === PAYSLIP_COMMUNICATION_CHANNELS.All) {

                        if (typeof summary.sms !== 'undefined' && typeof summary.credits_available !== 'undefined' && typeof deliverySummary.sms_sent !== 'undefined') {

                            let requiredCredits = summary.sms - deliverySummary.sms_sent;

                            return summary.credits_available < requiredCredits;
                        }
                    }
                    else if (channel === PAYSLIP_COMMUNICATION_CHANNELS.SMS) {

                        if (typeof summary.sms_only !== 'undefined' && typeof summary.credits_available !== 'undefined' && typeof deliverySummary.sms_sent !== 'undefined') {

                            let requiredCredits = summary.sms_only - deliverySummary.sms_sent;

                            return summary.credits_available < requiredCredits;
                        }
                    }
                }
                break;
            }

            default:
                break;
        }
    } catch (err) {
        console.error("checkLowCredit : error : ", err);
    }
    return isLowCredit;
}

function parseSummaryData(verifiedSummaryData, deliverySummaryDate, channel, status) {

    let result = {};

    try {

        switch (status) {
            case PAYSLIP_STATUS_MAPPING.VERIFY:
            case PAYSLIP_STATUS_MAPPING.DRAFT:
            case PAYSLIP_STATUS_MAPPING.SCHEDULED:
            case PAYSLIP_STATUS_MAPPING.SENDING: {

                if (verifiedSummaryData) {

                    result['totalContacts'] = typeof verifiedSummaryData['total'] !== 'undefined' ? verifiedSummaryData['total'] : '';

                    if (typeof channel !== 'undefined' && channel !== '') {
                        if (channel === PAYSLIP_COMMUNICATION_CHANNELS['SMS']) {
                            result['sms'] = typeof verifiedSummaryData['sms_only'] !== 'undefined' && verifiedSummaryData['sms_only'];

                            let requiredCredits = typeof verifiedSummaryData['sms_only'] !== 'undefined' && verifiedSummaryData['sms_only'];
                            let avaialbleCredits = typeof verifiedSummaryData['credits_available'] !== 'undefined' ? verifiedSummaryData['credits_available'] : '';

                            result['credits'] = `${requiredCredits}/${avaialbleCredits}`;
                        }
                        else if (channel === PAYSLIP_COMMUNICATION_CHANNELS['All']) {
                            result['app'] = typeof verifiedSummaryData['app'] !== 'undefined' ? verifiedSummaryData['app'] : '';
                            result['sms'] = typeof verifiedSummaryData['sms'] !== 'undefined' && verifiedSummaryData['sms'];

                            let requiredCredits = typeof verifiedSummaryData['sms'] !== 'undefined' && verifiedSummaryData['sms'];
                            let avaialbleCredits = typeof verifiedSummaryData['credits_available'] !== 'undefined' ? verifiedSummaryData['credits_available'] : '';

                            result['credits'] = `${requiredCredits}/${avaialbleCredits}`;
                        }
                        else {
                            result['app'] = typeof verifiedSummaryData['app_only'] !== 'undefined' ? verifiedSummaryData['app_only'] : '';
                        }
                    }
                }
                break;
            }

            case PAYSLIP_STATUS_MAPPING.PARTIAL:
            case PAYSLIP_STATUS_MAPPING.FAILED: {

                if (deliverySummaryDate && verifiedSummaryData) {

                    if (typeof deliverySummaryDate['total_contacts_reached'] !== 'undefined' && typeof verifiedSummaryData['total'] !== 'undefined') {
                        result['totalContacts'] = `${deliverySummaryDate['total_contacts_reached']}/${verifiedSummaryData['total']}`;
                        if (deliverySummaryDate['total_contacts_reached'] < verifiedSummaryData['total']) {
                            result['hilightTotalContacts'] = true;
                        }
                    }

                    if (typeof channel !== 'undefined' && channel !== '') {
                        if (channel === PAYSLIP_COMMUNICATION_CHANNELS['SMS']) {
                            if (typeof deliverySummaryDate['sms_sent'] !== 'undefined' && typeof verifiedSummaryData['sms_only'] !== 'undefined') {
                                result['sms'] = `${deliverySummaryDate['sms_sent']}/${verifiedSummaryData['sms_only']}`;
                                if (deliverySummaryDate['sms_sent'] < verifiedSummaryData['sms_only']) {
                                    result['hilightSMS'] = true;
                                }
                                result['credits'] = typeof deliverySummaryDate['credits_used'] !== 'undefined' && deliverySummaryDate['credits_used'];
                            }
                        }
                        else if (channel === PAYSLIP_COMMUNICATION_CHANNELS['All']) {
                            if (typeof deliverySummaryDate['app_sent'] !== 'undefined' && typeof verifiedSummaryData['app'] !== 'undefined') {
                                result['app'] = `${deliverySummaryDate['app_sent']}/${verifiedSummaryData['app']}`;
                                if (deliverySummaryDate['app_sent'] < verifiedSummaryData['app']) {
                                    result['hilightApp'] = true;
                                }
                            }
                            if (typeof deliverySummaryDate['sms_sent'] !== 'undefined' && typeof verifiedSummaryData['sms'] !== 'undefined') {
                                result['sms'] = `${deliverySummaryDate['sms_sent']}/${verifiedSummaryData['sms']}`;
                                if (deliverySummaryDate['sms_sent'] < verifiedSummaryData['sms']) {
                                    result['hilightSMS'] = true;
                                }
                                result['credits'] = typeof deliverySummaryDate['credits_used'] !== 'undefined' && deliverySummaryDate['credits_used'];
                            }
                        }
                        else {
                            if (typeof deliverySummaryDate['app_sent'] !== 'undefined' && typeof verifiedSummaryData['app_only'] !== 'undefined') {
                                result['app'] = `${deliverySummaryDate['app_sent']}/${verifiedSummaryData['app_only']}`;
                                if (deliverySummaryDate['app_sent'] < verifiedSummaryData['app_only']) {
                                    result['hilightApp'] = true;
                                }
                            }
                        }
                    }
                }
                break;
            }

            case PAYSLIP_STATUS_MAPPING.COMPLETED: {

                if (deliverySummaryDate && verifiedSummaryData) {

                    if (typeof deliverySummaryDate['total_contacts_reached'] !== 'undefined' && typeof verifiedSummaryData['total'] !== 'undefined') {
                        result['totalContacts'] = `${deliverySummaryDate['total_contacts_reached']}/${verifiedSummaryData['total']}`;
                    }

                    if (typeof channel !== 'undefined' && channel !== '') {
                        if (channel === PAYSLIP_COMMUNICATION_CHANNELS['SMS']) {
                            if (typeof deliverySummaryDate['sms_sent'] !== 'undefined' && typeof verifiedSummaryData['sms_only'] !== 'undefined') {
                                result['sms'] = `${deliverySummaryDate['sms_sent']}/${verifiedSummaryData['sms_only']}`;
                            }
                            result['credits'] = typeof deliverySummaryDate['credits_used'] !== 'undefined' && deliverySummaryDate['credits_used'];
                        }
                        else if (channel === PAYSLIP_COMMUNICATION_CHANNELS['All']) {
                            if (typeof deliverySummaryDate['app_sent'] !== 'undefined' && typeof verifiedSummaryData['app'] !== 'undefined') {
                                result['app'] = `${deliverySummaryDate['app_sent']}/${verifiedSummaryData['app']}`;
                            }
                            if (typeof deliverySummaryDate['sms_sent'] !== 'undefined' && typeof verifiedSummaryData['sms'] !== 'undefined') {
                                result['sms'] = `${deliverySummaryDate['sms_sent']}/${verifiedSummaryData['sms']}`;
                            }
                            result['credits'] = typeof deliverySummaryDate['credits_used'] !== 'undefined' && deliverySummaryDate['credits_used'];
                        }
                        else {
                            if (typeof deliverySummaryDate['app_sent'] !== 'undefined' && typeof verifiedSummaryData['app_only'] !== 'undefined') {
                                result['app'] = `${deliverySummaryDate['app_sent']}/${verifiedSummaryData['app_only']}`;
                            }
                        }
                    }
                }
                break;
            }

            default:
                break;
        }
    } catch (err) {
        console.error("parseSummaryData : error : ", err);
    }
    return result;
}

function getUploadContainerText(status, file) {

    let content = {
        supText: '',
        subText: '',
        subTextClass: ''
    }

    try {
        switch (status) {

            case HTTP_REQUEST_STATUS.IN_PROGRESS: {
                content['supText'] = 'DIC_KEY_PAYSLIP_IMPORT_POPUP_ERROR_INVALID_EXCEL';
                content['subText'] = 'DIC_MAIN_PAGE_TEXT_TEMPLATE_BESIDE_UPLOAD_VERIFY_INSTANCE_NEW_PAY_SLIP';
                content['subTextClass'] = 'payslip_upload_container_info_sub_header_verify';
                break;
            }

            case HTTP_REQUEST_STATUS.FAIL: {
                content['supText'] = 'DIC_KEY_PAYSLIP_IMPORT_POPUP_ERROR_INVALID_EXCEL';
                content['subText'] = 'DIC_KEY_PAYSLIP_IMPORT_POPUP_ERROR_INVALID_EXCEL_FAIL_DUE_TO_FORMAT';
                content['subTextClass'] = 'payslip_upload_container_info_sub_header_error';
                break;
            }

            case HTTP_REQUEST_STATUS.SUCCESS: {
                content['supText'] = 'DIC_KEY_PAYSLIP_IMPORT_POPUP_ERROR_INVALID_EXCEL';
                content['subText'] = file && file.name ? file.name : '';
                content['subTextClass'] = 'payslip_upload_container_info_sub_header_success';
                break;
            }

            default:
                break;
        }
    }
    catch (err) {
        console.error("Reducer Payslip: getVerifyUploadSectionText : error : ", err);
    }
    return content;
}

function getUploadContainerTextForDraft(uploadedFileName) {

    let content = {
        supText: '',
        subText: '',
        subTextClass: ''
    }

    try {
        let subText = 'DIC_KEY_PAYSLIP_VIEW_UPLOAD_TEXT_VERIFIED_SUCCESS';

        if (typeof uploadedFileName !== 'undefined' && uploadedFileName !== '' && uploadedFileName !== null) {
            subText = uploadedFileName;
        }

        content['supText'] = 'DIC_KEY_PAYSLIP_IMPORT_POPUP_ERROR_INVALID_EXCEL';
        content['subText'] = subText;
        content['subTextClass'] = 'payslip_upload_container_info_sub_header_success';
    }
    catch (err) {
        console.error("Reducer Payslip: getUploadContainerTextForDraft : error : ", err);
    }
    return content;
}