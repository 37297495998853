/** to get clients paginated list **/
export const WOVO_CLIENTS_GET_PAGINATED_LIST = 'WOVO_CLIENTS_GET_PAGINATED_LIST';
export const WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS = 'WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS';
export const WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR = 'WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR';
export const WOVO_CLIENTS_GET_PAGINATED_LIST_NONE = 'WOVO_CLIENTS_GET_PAGINATED_LIST_NONE';

export const WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT = 'WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT';
export const WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_SUCCESS = 'WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_SUCCESS';
export const WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_ERROR = 'WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_ERROR';
export const WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_NONE = 'WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT_NONE';

/** client save **/
export const WOVO_CLIENTS_SAVE_CLIENT = 'WOVO_CLIENTS_SAVE_CLIENT';
export const WOVO_CLIENTS_SAVE_CLIENT_SUCCESS = 'WOVO_CLIENTS_SAVE_CLIENT_SUCCESS';
export const WOVO_CLIENTS_SAVE_CLIENT_ERROR = 'WOVO_CLIENTS_SAVE_CLIENT_ERROR';
export const WOVO_CLIENTS_SAVE_CLIENT_NONE = 'WOVO_CLIENTS_SAVE_CLIENT_NONE';

/** to get client by client key **/
export const WOVO_CLIENTS_GET_CLIENT_BY_KEY = 'WOVO_CLIENTS_GET_CLIENT_BY_KEY';
export const WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS = 'WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS';
export const WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR = 'WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR';
export const WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE = 'WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE';

/** to get client keys by user **/
export const WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER = 'WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER';
export const WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS = 'WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS';
export const WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR = 'WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR';
export const WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE = 'WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE';

/** to get country list **/
export const COUNTRY_GET_ALL_COUNTRIES = 'COUNTRY_GET_ALL_COUNTRIES';
export const COUNTRY_GET_ALL_COUNTRIES_SUCCESS = 'COUNTRY_GET_ALL_COUNTRIES_SUCCESS';
export const COUNTRY_GET_ALL_COUNTRIES_ERROR = 'COUNTRY_GET_ALL_COUNTRIES_ERROR';
export const COUNTRY_GET_ALL_COUNTRIES_NONE = 'COUNTRY_GET_ALL_COUNTRIES_NONE';
