/**To get the list of payslip */
export const PAYSLIP_GET_PAGINATED_LIST = 'PAYSLIP_GET_PAGINATED_LIST';
export const PAYSLIP_GET_PAGINATED_LIST_SUCCESS = 'PAYSLIP_GET_PAGINATED_LIST_SUCCESS';
export const PAYSLIP_GET_PAGINATED_LIST_ERROR = 'PAYSLIP_GET_PAGINATED_LIST_ERROR';
export const PAYSLIP_GET_PAGINATED_LIST_NONE = 'PAYSLIP_GET_PAGINATED_LIST_NONE';
export const PAYSLIP_GET_PAGINATED_LIST_PROGRESS = 'PAYSLIP_GET_PAGINATED_LIST_PROGRESS';

// for PaySlip Upload Button Related 
export const VERIFY_PAYSLIP_DATA_UPLOADED = 'VERIFY_PAYSLIP_DATA_UPLOADED';
export const VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS = 'VERIFY_PAYSLIP_DATA_UPLOADED_SUCCESS';
export const VERIFY_PAYSLIP_DATA_UPLOADED_ERROR = 'VERIFY_PAYSLIP_DATA_UPLOADED_ERROR';
export const VERIFY_PAYSLIP_DATA_UPLOADED_NONE = 'VERIFY_PAYSLIP_DATA_UPLOADED_NONE';
export const VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS = 'VERIFY_PAYSLIP_DATA_UPLOADED_INPROGRESS';

// for PaySlip Download Button Related
export const NEW_PAYSLIP_ATTACHMENT_DOWNLOAD = 'NEW_PAYSLIP_ATTACHMENT_DOWNLOAD';
export const NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS = 'NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_SUCCESS';
export const NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR = 'NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_ERROR';
export const NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE = 'NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_NONE';
export const NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_INPROGRESS = 'NEW_PAYSLIP_ATTACHMENT_DOWNLOAD_INPROGRESS';

// for PaySlip Update Input Value in payslip
export const SET_SELECTED_INPUT_VALUES = 'SET_SELECTED_INPUT_VALUES';

/**To update text for upload container based on status */
export const UPDATE_UPLOAD_CONTAINER_TEXT = 'UPDATE_UPLOAD_CONTAINER_TEXT';

/**update payslip details on table row click */
export const PAYSLIP_PREVIEW_DETAILS_UPDATE  = 'PAYSLIP_PREVIEW_DETAILS_UPDATE';

/**Send or save payslip */
export const SEND_PAYSLIP = 'SEND_PAYSLIP';
export const SEND_PAYSLIP_SUCCESS = 'SEND_PAYSLIP_SUCCESS';
export const SEND_PAYSLIP_ERROR = 'SEND_PAYSLIP_ERROR';
export const SEND_PAYSLIP_NONE = 'SEND_PAYSLIP_NONE';
export const SEND_PAYSLIP_INPROGRESS = 'SEND_PAYSLIP_INPROGRESS';

/**update or delete payslip */
export const UPDATE_PAYSLIP = 'UPDATE_PAYSLIP';
export const UPDATE_PAYSLIP_SUCCESS = 'UPDATE_PAYSLIP_SUCCESS';
export const UPDATE_PAYSLIP_ERROR = 'UPDATE_PAYSLIP_ERROR';
export const UPDATE_PAYSLIP_NONE = 'UPDATE_PAYSLIP_NONE';
export const UPDATE_PAYSLIP_INPROGRESS = 'UPDATE_PAYSLIP_INPROGRESS';

/**To reload payslip list */
export const RELOAD_PAYSLIP_LIST = 'RELOAD_PAYSLIP_LIST';

/**To reset all state for new page */
export const PAYSLIP_RESET_STATE = 'PAYSLIP_RESET_STATE';