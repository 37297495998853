import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../util/keys";
import AppUtils from "../../../util/util";
import {
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
import {
    getSurveyTemplateSummaryTableDataSuccess,
    getSurveyTemplateSummaryTableDataError,
    downloadSurveyTemplateSummaryTableDataSuccess,
    downloadSurveyTemplateSummaryTableDataError
} from "../../../actions/survey/summaryTable/ActionSurveyTemplateSummaryTable";

import {
    URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_QUESTION_DATA,
    URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_CATEGORY_DATA,
    URL_SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA
} from "../../../constants/Urls";
import {
    SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS,
    HTTP_STATUS_CODE
} from "../../../constants/config";


/************ methods : http *******************/

/*** http : Survey Template Summary Table : get summary table Question data***/
const httpGetSurveyTemplateSummaryTableQuestionData = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_QUESTION_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/*** http : Survey Template Summary Table : get summary table Category data***/
const httpGetSurveyTemplateSummaryTableCategoryData = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_CATEGORY_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/*** http : Survey Template Summary Table : download summary table Category data***/
const httpDownloadSurveyTemplateSummaryTableData = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/************ methods : http response handler *******************/


/** http : Survey Template Summary Table : http response handler **/

function* handleHttpDownloadSurveyTemplateSummaryTableData({payload}) {
    try {
        let dataValue = yield call(httpDownloadSurveyTemplateSummaryTableData, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(downloadSurveyTemplateSummaryTableDataSuccess(dataValue));
        } else {
            yield put(downloadSurveyTemplateSummaryTableDataError(dataValue.response.data.error));
        }
    } catch (error) {
        yield put(downloadSurveyTemplateSummaryTableDataError(error));
    }
}


/** http : Survey Template Summary Table : http response handler **/

function* handleHttpGetSurveyTemplateSummaryTableData({payload}) {
    try {
        let dataValue = yield call(payload.report_type === SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_QUESTION ?
            httpGetSurveyTemplateSummaryTableQuestionData : httpGetSurveyTemplateSummaryTableCategoryData, payload);

        if (dataValue.status && dataValue.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(getSurveyTemplateSummaryTableDataSuccess(dataValue));
        } else {
            yield put(getSurveyTemplateSummaryTableDataError(dataValue.response.data.error));
        }
    } catch (error) {
        yield put(getSurveyTemplateSummaryTableDataError(error));
    }
}

/************ methods : export access *******************/

/** Survey Template Summary Table: get Table data **/
export function* getSurveyTemplateSummaryTableListData() {
    yield takeEvery(SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA, handleHttpGetSurveyTemplateSummaryTableData);
}

/** Survey Template Summary Table: get Table data **/
export function* downloadSurveyTemplateSummaryTableListData() {
    yield takeEvery(SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA, handleHttpDownloadSurveyTemplateSummaryTableData);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveyTemplateSummaryTableListData),
        fork(downloadSurveyTemplateSummaryTableListData)
    ]);
}