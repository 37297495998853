import React from 'react';

/**
 * This component is called in signin component
 * If there are 502 errors from APIs, which means server is stopped, 
 * we start showing this component instead of signin component
 */
function maintenancePage() {
     return (
          <div className='row justify-content-center align-items-center w-100'>
               <div className='maintenance_page'>
                    <div className='maintenance_page_inner'>
                         <div className='maintenance_page_wovo_icon mb-4'></div>
                         <div className='maintenance_page_inner_heading'>
                              We’ll be back.
                         </div>
                         <div className='maintenance_page_inner_message'>
                              WOVO is under maintenance. Please check back soon.
                         </div>
                    </div>
               </div>               
          </div>
     )
};

export default maintenancePage;
