import {
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS,
    SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE,
    SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE,
    SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";

/****
 Survey Template: get Summary Table Data
 ****/
export function getSurveyTemplateSummaryTableData(params) {
    return {
        type: SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA,
        payload: params
    };
}

/****
 Survey  Template: get  Summary Table Data success
 ****/
export function getSurveyTemplateSummaryTableDataSuccess(data) {
    return {
        type: SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS,
        payload: data
    };
}

/****
 Survey  Template: get Summary Table Data  error
 ****/
export function getSurveyTemplateSummaryTableDataError(params) {
    return {
        type: SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR,
        payload: params
    };
}

/****
 Survey Template: get Summary Table Data : in progress
 ****/
export function getSurveyTemplateSummaryTableDataInProgress() {
    return {
        type: SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
    };
}

/****
 Survey  Template: get Summary Table Data status to none
 ****/
export function getSurveyTemplateSummaryTableDataStatusNone(params) {
    return {
        type: SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE,
        payload: params
    };
}

/****
 Survey Template: download Summary Table Data
 ****/
export function downloadSurveyTemplateSummaryTableData(params) {
    return {
        type: SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA,
        payload: params
    };
}

/****
 Survey Template: download : Summary Table Data : success
 ****/
export function downloadSurveyTemplateSummaryTableDataSuccess(data) {
    return {
        type: SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
        payload: data
    };
}

/****
 Survey Template: download : Summary Table Data : error
 ****/
export function downloadSurveyTemplateSummaryTableDataError(params) {
    return {
        type: SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
        payload: params
    };
}

/****
 Survey Template: download : Summary Table Data : in progress
 ****/
export function downloadSurveyTemplateSummaryTableDataInProgress() {
    return {
        type: SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
    };
}

/****
 Survey Template: download : Summary Table Data :  none
 ****/
export function downloadSurveyTemplateSummaryTableDataStatusNone(params) {
    return {
        type: SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
        payload: params
    };
}


/****
 * To set survey questionnaire summary table report type
 ****/
export function setSurveyTemplateReportType(params){
    return{
        type : SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE,
        payload: params
    };
}

/****
 * To set survey questionnaire summary table group by type
 ****/
export function setSurveyTemplateGroupByType(params){
    return{
        type : SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE,
        payload: params
    };
}

/****
 * To reload survey questionnaire summary table
 ****/
export function setSurveyQuestionnaireSummaryTableReload(params){
    return{
        type : SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD,
        payload: params
    };
}