import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    TRANSLATION_SUBMODULE_GET_ALL_RECORDS,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE,
    TRANSLATION_SUBMODULE_UPDATE_STATUS
} from "../../../../constants/ActionTypes";

import {
    getTranslationSubmoduleSuccess,
    saveTranslationSubmoduleError,
    saveTranslationSubmoduleSuccess,
    translationSubmoduleUpdateStatusSuccess
} from "../../../../actions/adminSettings/translations/submodules/ActionsTranslationsSubmodules";

import {
    URL_TRANSLATIONS_SUBMODULE_CREATE,
    URL_TRANSLATIONS_SUBMODULE_LIST
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";

/************ methods : http *******************/

/**** Https : Translation Submodule Save ****/
const httpSaveTranslationSubmodule = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_SUBMODULE_CREATE, data , AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : Translation Submodule update ****/
const httpUpdateTranslationSubmodule= async (data) =>
    await axios.patch(baseUrl + URL_TRANSLATIONS_SUBMODULE_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Translation Submodule : Get List ****/
const httpGetTranslationSubmodule = async (data)=>
    await axios.get(baseUrl + URL_TRANSLATIONS_SUBMODULE_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/************ methods : http response handler *******************/

/** http : Translation Submodule save/Edit : http response handler **/

function* handleHttpSaveTranslationSubmodule({payload}) {
    try {
        let dataValue = yield call( payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateTranslationSubmodule : httpSaveTranslationSubmodule,payload)
            if (dataValue.message) {
                yield put(saveTranslationSubmoduleError(dataValue.message))
            } else {
                yield put(saveTranslationSubmoduleSuccess(dataValue))
            }
    }
    catch (error) {
        yield put(saveTranslationSubmoduleError(error))
    }
}

/** http : Translation Submodule : http response handler **/

function* handleHttpGetAllTranslationSubmodule() {
    try {
        let dataValue = yield call(httpGetTranslationSubmodule)
        if (dataValue.message) {
            yield put(saveTranslationSubmoduleError(dataValue.message))
        } else {
            yield put(getTranslationSubmoduleSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(saveTranslationSubmoduleError(error))
    }
}

/** http : Translation Submodule update status : http response handler **/

function* handleHttpUpdateTranslationSubmoduleStatus({payload}) {
    try {
        let dataValue = yield call(httpUpdateTranslationSubmodule, payload);
        if (dataValue.message) {
            yield put(saveTranslationSubmoduleError(dataValue.message));
        } else {
            yield put(translationSubmoduleUpdateStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveTranslationSubmoduleError(error));
    }
}

/************ methods : export access *******************/

/**** Listeners : Translation Submodule save ****/
export function* saveTranslationSubmodule(){
    yield takeEvery(TRANSLATION_SUBMODULE_NEW_EDIT_SAVE,handleHttpSaveTranslationSubmodule)
}
    
/**** Listeners : Translation Submodule Get ****/
export function* getTranslationSubmoduleList(){
        yield takeEvery(TRANSLATION_SUBMODULE_GET_ALL_RECORDS,handleHttpGetAllTranslationSubmodule)
}
    
/**** Listeners : Translation Submodule Update Status ****/
export function* updateTranslationSubmoduleStatus(){
        yield takeEvery(TRANSLATION_SUBMODULE_UPDATE_STATUS,handleHttpUpdateTranslationSubmoduleStatus)
}
    
export default function* rootSaga() {
        yield all([
            fork(saveTranslationSubmodule),
            fork(getTranslationSubmoduleList),
            fork(updateTranslationSubmoduleStatus)
        ])
}