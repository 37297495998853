import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../util/keys";
import {store} from '../../../MainApp';
import AppUtils from "../../../util/util";

import {
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD,
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";

import {
    getSurveyTemplateQuickInsightsError,
    getSurveyTemplateQuickInsightsSuccess,
    getSurveyTemplateQuestionSummaryError,
    getSurveyTemplateQuestionSummarySuccess,
    surveyTemplateDownloadResponsesSuccess,
    surveyTemplateDownloadResponsesError
} from "../../../actions/survey/quickInsights/ActionSurveyTemplateQuickInsights";


import {
    URL_SURVEY_TEMPLATE_GET_QUICK_INSIGHT_DATA,
    URL_SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_DATA,
    URL_SURVEY_TEMPLATE_RESPONSES_DOWNLOAD
} from "../../../constants/Urls";

/************ methods : http *******************/


/** http : Survey Template Quick Insights : get insights data**/
const httpGetSurveyTemplateQuickInsights = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_QUICK_INSIGHT_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey Template Template Question Summary : get question summary data**/
const httpGetSurveyTemplateQuestionSummary = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey Template Question Summary : To download the text responses**/

const httpSurveyTemplateResponsesDownload = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_RESPONSES_DOWNLOAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/************ methods : http response handler *******************/

/** http : Survey Template Quick Insights List : http response handler **/
function* handleHttpGetSurveyQuickInsightsListResponse({payload}) {
    try {
        let dataValue = yield call(httpGetSurveyTemplateQuickInsights, payload);
        if (dataValue.message) {
            yield put(getSurveyTemplateQuickInsightsSuccess(dataValue.data));
        } else {
            yield put(getSurveyTemplateQuickInsightsError(dataValue.message));
        }
    } catch (error) {
        yield put(getSurveyTemplateQuickInsightsError(error));
    }
}

/** http : Survey Template Question Summary List : http response handler **/
function* handleHttpGetSurveyTemplateQuestionSummaryListResponse({payload}) {
    try {
        let dataValue = yield call(httpGetSurveyTemplateQuestionSummary, payload);
        if (dataValue.message) {
            yield put(getSurveyTemplateQuestionSummarySuccess(dataValue.data));
        } else {
            yield put(getSurveyTemplateQuestionSummaryError(dataValue.message));
        }
    } catch (error) {
        yield put(getSurveyTemplateQuestionSummaryError(error));
    }
}

/** http : Survey Template Text response Download: http response handler **/

function* handleHttpSurveyTemplateResponsesDownload({payload}) {
    try {
        let dataValue = yield call(httpSurveyTemplateResponsesDownload, payload);

        if ( AppUtils.isResponseSuccess(dataValue)){
            yield put(surveyTemplateDownloadResponsesSuccess(dataValue));
        } else {
            yield put(surveyTemplateDownloadResponsesError(dataValue.response));
        }
    } catch (error) {
        yield put(surveyTemplateDownloadResponsesError(error));
    }
}

/************ methods : export access *******************/

/** Survey Template quick insights : get all records **/
export function* getSurveyTemplateQuickInsightsList() {
    yield takeEvery(SURVEY_TEMPLATE_GET_QUICK_INSIGHTS, handleHttpGetSurveyQuickInsightsListResponse);
}

/** Survey Template question summary : get all records **/
export function* getSurveyTemplateQuestionSummaryList() {
    yield takeEvery(SURVEY_TEMPLATE_GET_QUESTION_SUMMARY, handleHttpGetSurveyTemplateQuestionSummaryListResponse);
}

/** Survey Template text responses download **/

export function* surveyTemplateDownloadResponses() {
    yield takeEvery(SURVEY_TEMPLATE_RESPONSES_DOWNLOAD, handleHttpSurveyTemplateResponsesDownload);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveyTemplateQuickInsightsList),
        fork(getSurveyTemplateQuestionSummaryList),
        fork(surveyTemplateDownloadResponses)
    ]);
}
