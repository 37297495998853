import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    URL_SURVEY_CLONE_SURVEY,
    URL_SURVEY_CLONE_SURVEY_TEMPLATE
} from "../../../constants/Urls";


import AppUtils from "../../../util/util";
import {
    surveyCloneResponseError,
    surveyCloneResponseSuccess,
    surveyTemplateCloneResponseError,
    surveyTemplateCloneResponseSuccess
} from "../../../actions/survey/clone/ActionsSurveyCloneHandler";

import {
    SURVEY_CLONE_SURVEY_START,
    SURVEY_CLONE_SURVEY_TEMPLATE_START, SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE
} from "../../../constants/actions/survey/clone/actionTypesSurveyCloneHandler";
import {startTemplateCloneFromTemplateError, startTemplateCloneFromTemplateSuccess} from "../../../actions";



/************ methods : http *******************/

/** http : Survey clone  : http save **/
const httpSaveSurveyClone = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_CLONE_SURVEY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey template clone  : http save **/
const httpSaveSurveyTemplateClone = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_CLONE_SURVEY_TEMPLATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/************ methods : http response handler *******************/

/** http : Survey clone : http response handler **/
function* handleHttpSaveSurveyClone({payload}) {
    try {

        let result = yield call(httpSaveSurveyClone,payload);

        if (result.message) {
            yield put(surveyCloneResponseError(result.response));
        } else {
            yield put(surveyCloneResponseSuccess(result));
        }
    } catch (error) {
        yield put(surveyCloneResponseError(error));
    }
}

/** http : Survey template clone : http response handler **/
function* handleHttpSaveSurveyTemplateClone({payload}) {
    try {

        let result = yield call(httpSaveSurveyClone,payload);

        if (result.message) {
            yield put(surveyTemplateCloneResponseError(result.response));
        } else {
            yield put(surveyTemplateCloneResponseSuccess(result));
        }
    } catch (error) {
        yield put(surveyTemplateCloneResponseError(error));
    }
}

/** http : Survey template clone : http response handler **/
function* handleHttpCloneTemplateFromTemplate({payload}) {
    try {

        let result = yield call(httpSaveSurveyTemplateClone,payload);

        if (result.message) {
            yield put(startTemplateCloneFromTemplateError(result.response));
        } else {
            yield put(startTemplateCloneFromTemplateSuccess(result));
        }
    } catch (error) {
        yield put(startTemplateCloneFromTemplateError(error));
    }
}

/************ methods : export access *******************/

/** Survey clone start **/
export function* startSurveyClone() {
    yield takeEvery(SURVEY_CLONE_SURVEY_START, handleHttpSaveSurveyClone);
}

/** Survey template clone start **/
export function* startSurveyTemplateClone() {
    yield takeEvery(SURVEY_CLONE_SURVEY_TEMPLATE_START, handleHttpSaveSurveyTemplateClone);
}


/** template clone from another template **/
export function* startTemplateCloneFromTemplate() {
    yield takeEvery(SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE, handleHttpCloneTemplateFromTemplate);
}


export default function* rootSaga() {
    yield all([
        fork(startSurveyClone),
        fork(startSurveyTemplateClone),
        fork(startTemplateCloneFromTemplate)
    ]);
}
