import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../../util/keys";
import {
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA
} from "../../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
import {
    URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID,
    URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA
} from "../../../../constants/Urls";
import AppUtils from "../../../../util/util";
import {
    surveyTemplateRawDataDownloadUUIDDataError,
    surveyTemplateRawDataDownloadUUIDDataSuccess,
    surveyTemplateRawDataDownloadUUIDError,
    surveyTemplateRawDataDownloadUUIDSuccess
} from "../../../../actions";
import {ERROR_KEY} from '../../../../constants/config';

/************ methods : http *******************/

/** http : Survey template reports raw data uuid : To download the uuid**/
const httpSurveyTemplateRawDataDownloadUUID = async (data) =>{
   return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID , data, AppUtils.getHttpHeaders())
       .then(authUser => authUser.data)
       .catch(error => {
           return error;
       });
}

/** http : Survey template reports raw data uuid data : To download the data**/
const httpSurveyTemplateRawDataDownloadUUIDData = async (data) =>{
   return await axios.post(baseUrl + URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA , data, AppUtils.getHttpHeaders())
       .then(authUser => authUser.data)
       .catch(error => {
           return error;
       });
}

/************ methods : http response handler *******************/


/** http : Survey Template Rawdata uuid Download: http response handler **/

function* handleHttpSurveyTemplateRawDataDownloadUUID({payload}) {
   try {
       let result = yield call(httpSurveyTemplateRawDataDownloadUUID,payload);

       if (result.message) {
           yield put(surveyTemplateRawDataDownloadUUIDError(result.response));
       } else {
           yield put(surveyTemplateRawDataDownloadUUIDSuccess(result));

       }
   } catch (error) {
       yield put(surveyTemplateRawDataDownloadUUIDError(error));
   }
}

/** http : Survey Rawdata uuid data Download: http response handler **/

function* handleHttpSurveyTemplateRawDataDownloadUUIDData({payload}) {
   try {
       let result = yield call(httpSurveyTemplateRawDataDownloadUUIDData,payload);

       if (ERROR_KEY in result.data) {
           yield put(surveyTemplateRawDataDownloadUUIDDataError(result.data));
       } else {
           yield put(surveyTemplateRawDataDownloadUUIDDataSuccess(result));
       }
   } catch (error) {
       yield put(surveyTemplateRawDataDownloadUUIDDataError(error));
   }
}


/************ methods : export access *******************/


/** Survey template raw data uuid  download **/
export function* surveyTemplateRawDataDownloadUUID() {
   yield takeEvery(SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID, handleHttpSurveyTemplateRawDataDownloadUUID);
}

/** Survey template raw data uuid  download **/
export function* surveyTemplateReportsRawDataDownloadUUIDData() {
    yield takeEvery(SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA, handleHttpSurveyTemplateRawDataDownloadUUIDData);
 }

export default function* rootSaga() {
   yield all([
       fork(surveyTemplateRawDataDownloadUUID),
       fork(surveyTemplateReportsRawDataDownloadUUIDData),
   ]);
}