import {
    SURVEY_CLIENT_COUNTRIES,
    SURVEY_CLIENT_COUNTRIES_ERROR,
    SURVEY_CLIENT_COUNTRIES_NONE,
    SURVEY_CLIENT_COUNTRIES_SUCCESS,
    TEMPLATE_SURVEY_INFO_SET_ITEM
} from "../../../constants/actions/survey/survey/actionTypesSurvey";


/****
 Survey template : to set current template
 ****/
export function setTemplateSurveyCurrentItem(params) {
    return {
        type: TEMPLATE_SURVEY_INFO_SET_ITEM,
        payload: params
    };
}


/****
 Survey client countries : to get the list of countries based on selected client
 ****/
export function getClientCountries(params) {
    return {
        type: SURVEY_CLIENT_COUNTRIES,
        payload: params
    };
}


/****
 Survey client countries : success
 ****/
export function getClientCountriesSuccess(params) {
    return {
        type: SURVEY_CLIENT_COUNTRIES_SUCCESS,
        payload: params
    };
}

/****
 Survey client countries : none
 ****/
export function getClientCountriesNone() {
    return {
        type: SURVEY_CLIENT_COUNTRIES_NONE
    };
}

/****
 Survey client countries : error
 ****/
export function getClientCountriesError(params) {
    return {
        type: SURVEY_CLIENT_COUNTRIES_ERROR,
        payload: params
    };
}

