

export const SURVEY_NEW_QUESTION_NEW_EDIT_SAVE = 'SURVEY_NEW_QUESTION_NEW_EDIT_SAVE';
export const SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR = 'SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR';
export const SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS = 'SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS';
export const SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE = 'SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE';


export const SURVEY_TEMPLATE_QUESTION_DELETE = 'SURVEY_TEMPLATE_QUESTION_DELETE';
export const SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS = 'SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS';
export const SURVEY_TEMPLATE_QUESTION_DELETE_ERROR = 'SURVEY_TEMPLATE_QUESTION_DELETE_ERROR';
export const SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS = 'SURVEY_TEMPLATE_QUESTION_DELETE_IN_PROGRESS';
export const SURVEY_TEMPLATE_QUESTION_DELETE_NONE = 'SURVEY_TEMPLATE_QUESTION_DELETE_NONE';
export const SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE = "SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE";
export const SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS = 'SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS';
export const SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR = 'SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_ERROR';
export const SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE = 'SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_NONE';
export const SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS = 'SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_IN_PROGRESS';


export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_ERROR';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_IN_PROGRESS';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_NONE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_ENABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_CANCEL_QUESTION_DISABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_ENABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE = 'SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_DELETE_QUESTION_DISABLE';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT = 'SURVEY_SURVEY_NEW_QUESTION_NEW_ENABLE_LAYOUT';
export const SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT = 'SURVEY_SURVEY_NEW_QUESTION_NEW_DISABLE_LAYOUT';
export const SURVEY_SURVEY_QUESTION_ORDER_CHANGE = "SURVEY_SURVEY_QUESTION_ORDER_CHANGE";
export const SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS = 'SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS';
export const SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR = 'SURVEY_SURVEY_QUESTION_ORDER_CHANGE_ERROR';
export const SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE = 'SURVEY_SURVEY_QUESTION_ORDER_CHANGE_NONE';
export const SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS = 'SURVEY_SURVEY_QUESTION_ORDER_CHANGE_IN_PROGRESS';


export const SURVEY_SURVEY_QUESTION_DELETE = 'SURVEY_SURVEY_QUESTION_DELETE';
export const SURVEY_SURVEY_QUESTION_DELETE_SUCCESS = 'SURVEY_SURVEY_QUESTION_DELETE_SUCCESS';
export const SURVEY_SURVEY_QUESTION_DELETE_ERROR = 'SURVEY_SURVEY_QUESTION_DELETE_ERROR';
export const SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS = 'SURVEY_SURVEY_QUESTION_DELETE_IN_PROGRESS';
export const SURVEY_SURVEY_QUESTION_DELETE_NONE = 'SURVEY_SURVEY_QUESTION_DELETE_NONE';

/**** Action types for duplicating the question in survey/template ****/
export const SURVEY_SURVEY_QUESTION_DUPLICATE='SURVEY_SURVEY_QUESTION_DUPLICATE';
export const SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR='SURVEY_SURVEY_QUESTION_DUPLICATE_ERROR';
export const SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS='SURVEY_SURVEY_QUESTION_DUPLICATE_IN_PROGRESS';
export const SURVEY_SURVEY_QUESTION_DUPLICATE_NONE='SURVEY_SURVEY_QUESTION_DUPLICATE_NONE';
export const SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS='SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS';


export const SURVEY_TEMPLATE_QUESTION_DUPLICATE='SURVEY_TEMPLATE_QUESTION_DUPLICATE';
export const SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR='SURVEY_TEMPLATE_QUESTION_DUPLICATE_ERROR';
export const SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS='SURVEY_TEMPLATE_QUESTION_DUPLICATE_IN_PROGRESS';
export const SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE='SURVEY_TEMPLATE_QUESTION_DUPLICATE_NONE';
export const SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS='SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS';

/**** Survey questions randomize : action types ****/
export const SURVEY_QUESTION_RANDOMIZE = 'SURVEY_QUESTION_RANDOMIZE';
export const SURVEY_QUESTION_RANDOMIZE_ERROR = 'SURVEY_QUESTION_RANDOMIZE_ERROR';
export const SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS = 'SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS';
export const SURVEY_QUESTION_RANDOMIZE_NONE = 'SURVEY_QUESTION_RANDOMIZE_NONE';
export const SURVEY_QUESTION_RANDOMIZE_SUCCESS = 'SURVEY_QUESTION_RANDOMIZE_SUCCESS';

export const SURVEY_UPDATE_RANDOMIZE_OPTION = 'SURVEY_UPDATE_RANDOMIZE_OPTION';