import React from 'react';
import {
	REPORT_FILTERS_RESET_STATE,
	REPORT_FILTERS_SET_CHANNEL,
	REPORT_FILTERS_SET_CLASSIFICATION,
	REPORT_FILTERS_SET_DATE_RANGE,
	REPORT_FILTERS_SET_END_DATE,
	REPORT_FILTERS_SET_SELECTED_CLIENT,
	REPORT_FILTERS_SET_START_DATE,
	REPORT_FILTERS_UPDATE_REFRESH_COUNTER,
	REPORT_FILTERS_CONNECT_COUNTRY_FILTER,
	REPORT_FILTERS_CONNECT_COMPANY_STATUS,
	REPORT_FILTERS_ELEARNING_JOB_ROLE,
} from '../../../constants/actions/reports/actionsReportFilters';
import {
	COMPANY_STATUS_VALUE_MAP,
	WC_JOB_ROLES,
} from '../../../constants/config';
import AppUtils from '../../../util/util';
import { createStaticRanges } from 'react-date-range';
import IntlMessages from '../../../util/IntlMessages';
import { DROPDOWN_NAME_LABEL_COLOR } from 'constants/customUIConfig';

let commonFilters = [
	{
		key: 'filter_classifications',
		type: 'outline_dropdown',
		localisation: 'DIC_REPORTS_FILTERS_CLASSIFICATION',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
	{
		key: 'filter_channel',
		type: 'outline_dropdown',
		localisation: 'DIC_REPORTS_FILTERS_CHANNEL',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
];

let brandFilters = [
	{
		key: 'filter_job_role',
		type: 'multiselect_react_chips_dropdown',
		localisation: 'DIC_ELEARNING_REPORTS_FILTER_JOB_ROLE',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
	{
		key: 'filter_client_status',
		type: 'outline_dropdown',
		localisation: 'DIC_KEY_CONNECT_REPORTS_COMPANY_STATUS',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
	{
		key: 'filter_child_client',
		type: 'outline_dropdown',
		localisation: 'DIC_REPORTS_FILTERS_COMPANY',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
	{
		key: 'filter_child_country',
		type: 'multiselect_react_chips_dropdown',
		localisation: 'DIC_KEY_MULTISELECT_DROPDOWN_NAME_LABEL',
		style: { color: DROPDOWN_NAME_LABEL_COLOR },
	},
];

let filterClassificationList = [
	{
		key: 'all',
		value: 'all',
		headerValue: 'all',
		label: 'DIC_REPORTS_CONNECT_ALL',
	},
	{
		key: 'ask',
		value: 'ask',
		headerValue: 'ask',
		label: 'DIC_REPORTS_CONNECT_ASK',
	},
	{
		key: 'report',
		value: 'report',
		headerValue: 'report',
		label: 'DIC_REPORTS_CONNECT_REPORT',
	},
	{
		key: 'suggest',
		value: 'suggest',
		headerValue: 'suggest',
		label: 'DIC_REPORTS_CONNECT_SUGGEST',
	},
];

let filterChannelList = [
	{
		key: 'all',
		value: 'all',
		headerValue: 'all',
		label: 'DIC_REPORTS_CONNECT_ALL',
	},
	{
		key: 'app',
		value: 'app',
		headerValue: 'app',
		label: 'DIC_REPORTS_CONNECT_APP',
	},
	{
		key: 'sms',
		value: 'sms',
		headerValue: 'sms',
		label: 'DIC_REPORTS_CONNECT_SMS',
	},
	{
		key: 'third_party',
		value: 'third_party',
		headerValue: 'third_party',
		label: 'DIC_REPORTS_CONNECT_THIRD_PARTY',
	},
	{
		key: 'manual',
		value: 'manual',
		headerValue: 'manual',
		label: 'DIC_REPORTS_CONNECT_MANUAL',
	},
];

let companyStatusList = [
	{
		key: 'all',
		value: COMPANY_STATUS_VALUE_MAP['ALL'],
		label: 'DIC_REPORTS_CONNECT_ALL',
	},
	{
		key: 'active',
		value: COMPANY_STATUS_VALUE_MAP['ACTIVE'],
		label: 'DIC_REPORTS_CONNECT_COMPANY_STATUS_ACTIVE',
	},
	{
		key: 'inactive',
		value: COMPANY_STATUS_VALUE_MAP['INACTIVE'],
		label: 'DIC_REPORTS_CONNECT_COMPANY_STATUS_INACTIVE',
	},
];

const filterValuesJobRole = [
	{
		id: WC_JOB_ROLES.WORKER,
		key: WC_JOB_ROLES.WORKER,
		localisation: 'DIC_SURVEY_REPORT_FILTER_JOB_ROLE_WORKER',
	},
	{
		id: WC_JOB_ROLES.SUPERVISOR,
		key: WC_JOB_ROLES.SUPERVISOR,
		localisation: 'DIC_SURVEY_REPORT_FILTER_JOB_ROLE_SUPERVISOR',
	},
	{
		id: WC_JOB_ROLES.MANAGER,
		key: WC_JOB_ROLES.MANAGER,
		localisation: 'DIC_SURVEY_REPORT_FILTER_JOB_ROLE_MANAGER',
	},
	{
		id: WC_JOB_ROLES.HEAD,
		key: WC_JOB_ROLES.HEAD,
		localisation: 'DIC_SURVEY_REPORT_FILTER_JOB_ROLE_HEAD',
	},
	{
		id: WC_JOB_ROLES.DEFAULT,
		key: WC_JOB_ROLES.DEFAULT,
		localisation: 'DIC_SURVEY_REPORT_FILTER_JOB_ROLE_UNDISCLOSED',
	},
];

let selectionRange = AppUtils.getDefaultDateRangeSelection();

let defaultDateRangeValue = {
	label: <IntlMessages id="DIC_REPORTS_DATE_RANGE_PAST_ONE_YEAR" />,
	range: () => ({
		startDate: selectionRange['startDate'],
		endDate: selectionRange['endDate'],
	}),
};

let timeResult = AppUtils.getReportsDates();

let defaultDateRange = createStaticRanges([defaultDateRangeValue]);

const initialData = {
	selectedReportClient: 'all',
	selectedClassification: 'all',
	selectedChannel: 'all',
	reportStartDate: timeResult['start_date'],
	reportEndDate: timeResult['end_date'],
	reportEndDateStr: timeResult['end_date_str'],
	reportStartDateStr: timeResult['start_date_str'],
	reportFilters: commonFilters,
	brandFilters: brandFilters,
	filterClassificationList: filterClassificationList,
	filterChannelList: filterChannelList,
	refreshCounter: 0,
	selectionRange: selectionRange,
	defaultDateRange: defaultDateRange,
	selectedCountries: [],
	selectedLessons: -1,
	companyStatusList: companyStatusList,
	selectedCompanyStatus: COMPANY_STATUS_VALUE_MAP.ALL,
	filterValuesJobRole: filterValuesJobRole,
	selectedJobRle: filterValuesJobRole,
};

const ReducerReportFilters = (state = initialData, action) => {
	switch (action.type) {
		/** to update refresh counter **/
		case REPORT_FILTERS_UPDATE_REFRESH_COUNTER: {
			return {
				...state,
				refreshCounter: state.refreshCounter + 1,
			};
		}

		/** to set date range**/
		case REPORT_FILTERS_SET_DATE_RANGE: {
			let timeResult = AppUtils.getReportsDates(
				action.payload.startDate,
				action.payload.endDate
			);

			return {
				...state,
				reportStartDate: timeResult['start_date'],
				reportEndDate: timeResult['end_date'],
				reportEndDateStr: timeResult['end_date_str'],
				reportStartDateStr: timeResult['start_date_str'],
				refreshCounter: state.refreshCounter + 1,
			};
		}

		/** to set end date **/
		case REPORT_FILTERS_SET_END_DATE: {
			return {
				...state,
			};
		}

		/** to set start date **/
		case REPORT_FILTERS_SET_START_DATE: {
			return {
				...state,
			};
		}

		/** to set selected client **/
		case REPORT_FILTERS_SET_SELECTED_CLIENT: {
			return {
				...state,
				selectedReportClient: action.payload,
			};
		}

		/** to set channel **/
		case REPORT_FILTERS_SET_CHANNEL: {
			return {
				...state,
				selectedChannel: action.payload,
			};
		}

		/** to set classification **/
		case REPORT_FILTERS_SET_CLASSIFICATION: {
			return {
				...state,
				selectedClassification: action.payload,
			};
		}

		/** to reset filter status **/
		case REPORT_FILTERS_RESET_STATE: {
			return {
				...state,
				selectedReportClient: 'all',
				selectedClassification: 'all',
				selectedChannel: 'all',
				selectedCompanyStatus: COMPANY_STATUS_VALUE_MAP.ALL,
				refreshCounter: 0,
				selectedJobRle: filterValuesJobRole,
			};
		}

		case REPORT_FILTERS_CONNECT_COUNTRY_FILTER: {
			return {
				...state,
				selectedCountries: action.payload,
			};
		}

		case REPORT_FILTERS_CONNECT_COMPANY_STATUS: {
			return {
				...state,
				selectedCompanyStatus: action.payload,
			};
		}

		case REPORT_FILTERS_ELEARNING_JOB_ROLE: {
			return {
				...state,
				selectedJobRle: action.payload,
			};
		}

		default:
			return state;
	}
};

export default ReducerReportFilters;
