import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";

import axios from "axios";
import {
    CLIENT_MODULE_ADMIN_LIST, CLIENT_MODULE_GET_CONFIGURATION,
    CLIENT_MODULE_NEW_MODULE_SAVE, CLIENT_MODULE_SET_CONFIGURATION
} from "../../constants/actions/clientModules/actionConfigClientModules";
import {
    getClientModulesAdminListError,
    getClientModulesAdminListSuccess, getClientModulesConfigurationError, getClientModulesConfigurationSuccess,
    saveClientModulesAdminRecordError,
    saveClientModulesAdminRecordSuccess, setClientModulesConfigurationError, setClientModulesConfigurationSuccess
} from "../../actions/clientModules/actionsClientModules";
import {baseUrl} from "../../util/keys";
import {
    URL_CLIENT_MODULE_ADMIN_LIST, URL_CLIENT_MODULE_GET_CONFIGURATION,
    URL_CLIENT_MODULE_SAVE_MODULE, URL_CLIENT_MODULE_SET_CONFIGURATION
} from "../../constants/Urls";
import AppUtils from "../../util/util";

/************ methods : http *******************/

/** http : To save client module **/
const httpSaveClientModuleRecord = async (data) =>
    await axios.post(baseUrl + URL_CLIENT_MODULE_SAVE_MODULE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To client modules for admin **/
const httpGetAdminClientModules = async (data) =>
    await axios.post(baseUrl + URL_CLIENT_MODULE_ADMIN_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : To get client module configuration **/
const httpGetClientModuleConfiguration = async (data) =>
    await axios.post(baseUrl + URL_CLIENT_MODULE_GET_CONFIGURATION, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : To set client module configuration **/
const httpSetClientModuleConfiguration = async (data) =>
    await axios.post(baseUrl + URL_CLIENT_MODULE_SET_CONFIGURATION, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/

/** http : To save client module configuration **/
function* handleHttpSaveClientModuleRecord({payload}) {
    try {

        let result = yield call(httpSaveClientModuleRecord, payload);

        if (result.message) {
            yield put(saveClientModulesAdminRecordError(result.response));
        } else {
            yield put(saveClientModulesAdminRecordSuccess(result));
        }
    } catch (error) {
        yield put(saveClientModulesAdminRecordError(error));
    }
}

/** http : To get client modules for admin **/
function* handleHttpGetAdminClientModules({payload}) {
    try {

        let result = yield call(httpGetAdminClientModules, payload);

        if (result.message) {
            yield put(getClientModulesAdminListError(result.response));
        } else {
            yield put(getClientModulesAdminListSuccess(result));
        }
    } catch (error) {
        yield put(getClientModulesAdminListError(error));
    }
}

/** http : To get client module configuration **/
function* handleHttpGetClientModuleConfiguration({payload}) {
    try {

        let result = yield call(httpGetClientModuleConfiguration, payload);

        if (result.message) {
            yield put(getClientModulesConfigurationError(result.response));
        } else {
            yield put(getClientModulesConfigurationSuccess(result));
        }
    } catch (error) {
        yield put(getClientModulesConfigurationError(error));
    }
}

/** http : To set client module configuration **/
function* handleHttpSetClientModuleConfiguration({payload}) {
    try {

        let result = yield call(httpSetClientModuleConfiguration, payload);

        if (result.message) {
            yield put(setClientModulesConfigurationError(result.response));
        } else {
            yield put(setClientModulesConfigurationSuccess(result));
        }
    } catch (error) {
        yield put(setClientModulesConfigurationError(error));
    }
}

/************ methods : export access *******************/

/** To save client module **/
export function* saveClientModuleRecord() {
    yield takeEvery(CLIENT_MODULE_NEW_MODULE_SAVE, handleHttpSaveClientModuleRecord);
}

/** To get admin client module **/
export function* getAdminClientModules() {
    yield takeEvery(CLIENT_MODULE_ADMIN_LIST, handleHttpGetAdminClientModules);
}

/** To get client module configuration **/
export function* getClientModuleConfiguration() {
    yield takeEvery(CLIENT_MODULE_GET_CONFIGURATION, handleHttpGetClientModuleConfiguration);
}

/** To get client module configuration **/
export function* setClientModuleConfiguration() {
    yield takeEvery(CLIENT_MODULE_SET_CONFIGURATION, handleHttpSetClientModuleConfiguration);
}


export default function* rootSaga() {
    yield all([
        fork(saveClientModuleRecord),
        fork(getAdminClientModules),
        fork(getClientModuleConfiguration),
        fork(setClientModuleConfiguration)
    ]);
}
