import { takeEvery, call, put, all, fork } from 'redux-saga/effects';
import axios from 'axios';
import {
	HTTP_TIMEOUT,
	URL_SURVEY_RESPONSE_ANONYMOUS_SAVE,
	URL_SURVEY_RESPONSE_AUTHENTICATE_USER,
	URL_SURVEY_RESPONSE_SAVE,
} from '../../../constants/Urls';

import {
	SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER,
	SURVEY_RESPONSE_SAVE_RESPONSE,
	SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND,
} from '../../../constants/actions/survey/responses/actionTypesSurveyResponses';

import {
	authenticateSurveyUserError,
	authenticateSurveyUserSuccess,
	saveSurveyResponseError,
	saveSurveyResponseSuccess,
	saveSurveyResponseBackgroundError,
	saveSurveyResponseBackgroundSuccess,
} from '../../../actions/survey/responses/ActtionsSurveyResponses';

import { baseUrl } from '../../../util/keys';
import AppUtils from '../../../util/util';
import { store } from '../../../MainApp';

/************ methods : http *******************/

/**** Https : Survey response Save ****/
const httpSaveSurveyResponse = async (data) =>
	await axios
		.post(baseUrl + URL_SURVEY_RESPONSE_SAVE, data, AppUtils.getHttpHeaders())
		.then((authUser) => authUser)
		.catch((error) => error);

/**** Https : Survey response Save ****/
const httpSaveAnonymousSurveyResponse = async (data) =>
	await axios
		.post(baseUrl + URL_SURVEY_RESPONSE_ANONYMOUS_SAVE, data, {
			timeout: HTTP_TIMEOUT,
		})
		.then((authUser) => authUser)
		.catch((error) => error);

/**** Https : Survey   : authenticate user  ****/
const httpAuthenticateSurveyUser = async (data) =>
	await axios
		.post(baseUrl + URL_SURVEY_RESPONSE_AUTHENTICATE_USER, data, {
			timeout: HTTP_TIMEOUT,
		})
		.then((authUser) => authUser)
		.catch((error) => error);

/************ methods : http response handler *******************/

/** http : Survey response save : http response handler **/
function* handleHttpSaveSurveyResponse({ payload }) {
	try {
		let dataValue = yield call(httpSaveSurveyResponse, payload);

		if (dataValue.message) {
			yield put(
				saveSurveyResponseError(
					typeof dataValue.response != 'undefined'
						? dataValue.response.data
						: dataValue
				)
			);
		} else {
			yield put(saveSurveyResponseSuccess(dataValue.data));
		}
	} catch (error) {
		yield put(saveSurveyResponseError(error));
	}
}

/** http : Survey response save : http response handler **/
function* handleHttpSaveSurveyResponseInBackground({ payload }) {
	try {
		let dataValue = yield call(httpSaveSurveyResponse, payload);

		if (dataValue.message) {
			yield put(
				saveSurveyResponseBackgroundError(
					typeof dataValue.response != 'undefined'
						? dataValue.response.data
						: dataValue
				)
			);
		} else {
			yield put(saveSurveyResponseBackgroundSuccess(dataValue.data));
		}
	} catch (error) {
		yield put(saveSurveyResponseBackgroundError(error));
	}
}

/** http : Survey authenticate user : http response handler **/
function* handleHttpAuthenticateSurveyUser({ payload }) {
	try {
		let dataValue = yield call(httpAuthenticateSurveyUser, payload);

		if (dataValue.message) {
			yield put(authenticateSurveyUserError(dataValue.response.data));
		} else {
			yield put(authenticateSurveyUserSuccess(dataValue.data));
		}
	} catch (error) {
		yield put(authenticateSurveyUserError(error));
	}
}

/************ methods : export access *******************/

/**** Listeners : Survey response save ****/
export function* saveSurveyResponse() {
	yield takeEvery(SURVEY_RESPONSE_SAVE_RESPONSE, handleHttpSaveSurveyResponse);
}

/**** Listeners : Survey response save ****/
export function* saveSurveyResponseInBackground() {
	yield takeEvery(
		SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND,
		handleHttpSaveSurveyResponseInBackground
	);
}

/**** Listeners : Survey : authenticate user ****/
export function* authenticateSurveyUser() {
	yield takeEvery(
		SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER,
		handleHttpAuthenticateSurveyUser
	);
}

export default function* rootSaga() {
	yield all([
		fork(saveSurveyResponse),
		fork(authenticateSurveyUser),
		fork(saveSurveyResponseInBackground),
	]);
}
