import {
  SURVEY_GET_QUICK_INSIGHTS,
  SURVEY_GET_QUICK_INSIGHTS_ERROR,
  SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS,
  SURVEY_GET_QUICK_INSIGHTS_NONE,
  SURVEY_GET_QUICK_INSIGHTS_SUCCESS,
  SURVEY_GET_QUESTION_SUMMARY,
  SURVEY_GET_QUESTION_SUMMARY_ERROR,
  SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS,
  SURVEY_GET_QUESTION_SUMMARY_NONE,
  SURVEY_GET_QUESTION_SUMMARY_SUCCESS,
  SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE,
  SURVEY_RESPONSES_DOWNLOAD,
  SURVEY_RESPONSES_DOWNLOAD_ERROR,
  SURVEY_RESPONSES_DOWNLOAD_SUCCESS,
  SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS,
  SURVEY_RESPONSES_DOWNLOAD_NONE,
  SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
  SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
  SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE,
  SURVEY_QUICK_INSIGHTS_FILTERS_RESET,
  SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES,
  SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
  SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE,
  SURVEY_QUICK_INSIGHTS_CLIENTS_CHANGE,
  SURVEY_QUICK_INSIGHTS_SELECTED_CLIENTS,
} from "../../../constants/actions/survey/survey/actionTypesSurvey";

/****
  Survey : get Quick Insights
 ****/
export function surveyGetQuickInsights(params) {
  return {
    type: SURVEY_GET_QUICK_INSIGHTS,
    payload: params,
  };
}

/****
 Survey  : get Quick insights data success
 ****/
export function getSurveyQuickInsightsSuccess(data) {
  return {
    type: SURVEY_GET_QUICK_INSIGHTS_SUCCESS,
    payload: data,
  };
}

/****
 Survey  : get Quick insights data error
 ****/
export function getSurveyQuickInsightsError(params) {
  return {
    type: SURVEY_GET_QUICK_INSIGHTS_ERROR,
    payload: params,
  };
}

/****
  Survey : get Quick insights : in progress
 ****/
export function surveyGetQuickInsightsInProgress() {
  return {
    type: SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS,
  };
}

/****
 Survey  : get quick insights status to none
 ****/
export function getSurveyQuickInsightsStatusNone(params) {
  return {
    type: SURVEY_GET_QUICK_INSIGHTS_NONE,
    payload: params,
  };
}

/****
  Survey : get Question Summary
 ****/
export function surveyGetQuestionSummary(params) {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY,
    payload: params,
  };
}

/****
 Survey  : get Question Summary data success
 ****/
export function getSurveyQuestionSummarySuccess(data) {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY_SUCCESS,
    payload: data,
  };
}

/****
 Survey  : get Question Summary data error
 ****/
export function getSurveyQuestionSummaryError(params) {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY_ERROR,
    payload: params,
  };
}

/****
  Survey : get Question Summary : in progress
 ****/
export function surveyGetQuestionSummaryInProgress() {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS,
  };
}

/****
 Survey  : get Question Summary status to none
 ****/
export function getSurveyQuestionSummaryStatusNone(params) {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY_NONE,
    payload: params,
  };
}

/****
 Survey  : To get the Graph Type
 ****/

export function setCurrentGraphType(params) {
  return {
    type: SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE,
    payload: params,
  };
}

/****
 Survey  : To Download the text responses
 ****/

export function surveyDownloadResponses(params) {
  return {
    type: SURVEY_RESPONSES_DOWNLOAD,
    payload: params,
  };
}

/****
 Survey  : To Download the text responses success
 ****/

export function surveyDownloadResponsesSuccess(data) {
  return {
    type: SURVEY_RESPONSES_DOWNLOAD_SUCCESS,
    payload: data,
  };
}

/****
 Survey  : To Download the text responses error
 ****/

export function surveyDownloadResponsesError(params) {
  return {
    type: SURVEY_RESPONSES_DOWNLOAD_ERROR,
    payload: params,
  };
}

/****
 Survey  : To Download the responses in progress
 ****/

export function surveyDownloadResponsesInProgress() {
  return {
    type: SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS,
  };
}

/****
 Survey  : To Download the responses None
 ****/

export function surveyDownloadResponsesNone(params) {
  return {
    type: SURVEY_RESPONSES_DOWNLOAD_NONE,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : on question order filter dropdow change
 ****/

export function surveyFilterQuestionOrderChange(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : on responder access filter dropdow change
 ****/

export function surveyFilterResponderAccessChange(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : on invited media filter dropdow change
 ****/

export function surveyFilterInvitedMediaChange(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : to set selected countries list
 ****/
export function surveyReportFilterCompanyStatusChange(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
    payload: params,
  };
}

/****
 Survey quick insights setting filter : to set selected job role list
 ****/
export function surveyReportFilterJobRoleChange(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE,
    payload: params,
  };
}

export function updateSelectedClients(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_CLIENTS_CHANGE,
    payload: params,
  };
}

export function getSelectedClients(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_SELECTED_CLIENTS,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : to set selected countries list
 ****/

export function surveyFilterUpdateSelectedCountries(params) {
  return {
    type: SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES,
    payload: params,
  };
}

/****
 Survey quickinsights setting filter : reset
 ****/

export function surveySettingFilterReset() {
  return {
    type: SURVEY_QUICK_INSIGHTS_FILTERS_RESET,
  };
}
