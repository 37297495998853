

import {
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS, USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE,
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS,
    USER_SETTINGS_UPDATE_TIME_ZONE_ERROR,
    USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS,
    USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS
} from "../../../constants/actions/user/actionUserSettings";

import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import StorageHandler from "../../../util/storageHandler";


let userSettingsConfig = [
    {
        'key': 'supported_language',
        'type': 'dropdown',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_USER_SETTINGS_VIEW_LANGUAGE",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    },
    {
        'key': 'time_zone',
        'type': 'dropdown',
        'style': {},
        'labelClass': "",
        'localisation': "DIC_USER_SETTINGS_TIME_ZONE",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    }
];

const initialData = {
    userSettingsConfig: userSettingsConfig,
    updateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessage: "",
    currentSettings: {},
    updatePreferredClientStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
};


const ReducerUserSettings = (state = initialData, action) => {


    switch (action.type) {

        /** User settings update success **/
        case USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }

        /** User settings update error **/
        case USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.message
            }
        }

        /** User settings update in progress **/
        case USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        /** User settings update success **/
        case USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }

        /** User settings update error **/
        case USER_SETTINGS_UPDATE_TIME_ZONE_ERROR: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.message
            }
        }

        /** User settings update in progress **/
        case USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        /** User settings update in progress **/
        case USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE: {

            return {
                ...state,
                updateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS:{

            if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" &&
                typeof action.payload.data.data != "undefined" && typeof action.payload.data.data.preferred_client != "undefined"){
                let preferred_client = action.payload.data.data.preferred_client;
                StorageHandler.saveItem(StorageHandler.keys.PREFERRED_CLIENT, preferred_client);
            }

            return {
                ...state,
                updatePreferredClientStatus : HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR:{
            return {
                ...state,
                updatePreferredClientStatus : HTTP_REQUEST_STATUS.FAIL
            }
        }

        case USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS:{
            return {
                ...state,
                updatePreferredClientStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE:{
            return {
                ...state,
                updatePreferredClientStatus : HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }


        default:
            return state;
    }

};

export default ReducerUserSettings;
