import {all, fork} from "redux-saga/effects";
import {getConnectClient, getConnectClientStatus} from './outcoming/getConnectClient';
import {getCaseOverview} from './incoming/getCaseOverview';
import {getCaseProportion} from './outcoming/getCaseProportion';
import {getCasePopularity} from './outcoming/getCasePopularity';
import {getMessageCount} from './incoming/getMessageCount';
import {getCaseStatus} from './incoming/getCaseStatus';
import {getCaseTypeOvertime} from './incoming/getCaseTypeOvertime';
import {getCaseResolvedCount} from './outcoming/getCaseResolvedCount';
import {getCaseHandlingMessageCount} from './outcoming/caseHandlingMessageCount';
import {getReplyAvgCount} from "./outcoming/getReplyAvgCount";
import {getCaseSatisfaction} from "./outcoming/getCaseSatisfaction";
import {getManagementPerfomance} from "./outcoming/managementPerfomance";
import {getConnectGeneralSaga, getConnectGeneralStatus} from "./incoming/getConnectGeneral";
import {getConnectSummaryTable} from "./summaryTable"
import {getConnectSummaryTableUUID} from "./summaryTable/summaryTableUUID";

export default function* rootSaga() {
    yield all([
        fork(getConnectClient),
        fork(getConnectClientStatus),
        fork(getCaseOverview),
        fork(getCaseProportion),
        fork(getCasePopularity),
        fork(getMessageCount),
        fork(getCaseStatus),
        fork(getCaseTypeOvertime),
        fork(getCaseResolvedCount),
        fork(getCaseHandlingMessageCount),
        fork(getReplyAvgCount),
        fork(getCaseSatisfaction),
        fork(getManagementPerfomance),
        fork(getConnectGeneralSaga),
        fork(getConnectGeneralStatus),
        fork(getConnectSummaryTable),
        fork(getConnectSummaryTableUUID)
    ]);
}
