

export const TRANSLATION_STRINGS_UPLOAD = 'TRANSLATION_STRINGS_UPLOAD';
export const TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS = 'TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS';
export const TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR = 'TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR';
export const TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS = 'TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS';
export const TRANSLATION_STRINGS_UPLOAD_SAVE_NONE = 'TRANSLATION_STRINGS_UPLOAD_SAVE_NONE';


export const TRANSLATION_STRINGS_TRANSLATIONS_LIST = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST';
export const TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS';
export const TRANSLATION_STRINGS_TRANSLATIONS_LIST_ERROR = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST_ERROR';
export const TRANSLATION_STRINGS_TRANSLATIONS_LIST_IN_PROGRESS = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST_IN_PROGRESS';
export const TRANSLATION_STRINGS_TRANSLATIONS_LIST_NONE = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST_NONE';
export const TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED = 'TRANSLATION_STRINGS_TRANSLATIONS_LIST_TRANSLATED';

export const TRANSLATION_STRINGS_DOWNLOAD = 'TRANSLATION_STRINGS_DOWNLOAD';
export const TRANSLATION_STRINGS_DOWNLOAD_SUCCESS = 'TRANSLATION_STRINGS_DOWNLOAD_SUCCESS';
export const TRANSLATION_STRINGS_DOWNLOAD_ERROR = 'TRANSLATION_STRINGS_DOWNLOAD_ERROR';
export const TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS = 'TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS';
export const TRANSLATION_STRINGS_DOWNLOAD_NONE = 'TRANSLATION_STRINGS_DOWNLOAD_NONE';

