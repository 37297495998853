import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "actions/Auth";
import {SIGNIN_USER, SIGNOUT_USER} from "../constants/ActionTypes";
import axios from "axios";
import {HTTP_TIMEOUT, URL_LOGIN_AUTHENTICATE, URL_LOGOUT, URL_LOGIN_REFRESH_TOKEN} from "../constants/Urls";
import {baseUrl} from "../util/keys";
import StorageHandler from "util/storageHandler";
import AppUtils from "../util/util";
import {store} from 'MainApp';

const default_config = {timeout: HTTP_TIMEOUT};

const signOutRequest = async () => {
    return await axios.post(`${baseUrl}${URL_LOGOUT}`, {}, AppUtils.getHttpHeaders())
        .then(response => response )
        .catch(error => error);
}


export const refreshToken = async () => {
    return await axios.post(`${baseUrl}${URL_LOGIN_REFRESH_TOKEN}`, { refresh: store.getState().auth.refreshKey }, AppUtils.getHttpHeaders())
        .then(authUser => {
            store.dispatch(userSignInSuccess(authUser.data))
        })
        .catch(error => error);
}


const signInUserWithServer = async (username, password) =>
    await axios.post(`${baseUrl}${URL_LOGIN_AUTHENTICATE}`, {username:username, password:password}, default_config)
        .then(authUser => authUser)
        .catch(error => error);

function* signInUserWithEmailPassword({payload}) {
    const {username, password} = payload;
    try {
        const signInUser = yield call(signInUserWithServer, username, password);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', signInUser.data['user']['user_name']);
            yield put(userSignInSuccess(signInUser.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        const useCookieToValidate = process.env.REACT_APP_USE_COOKIES_TO_AUTHENTICATE === "true"
        if (signOutUser.status === 200) {
            if (!useCookieToValidate) {
                StorageHandler.eraseCookie("access_token")
            }
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(signOutUser)]);
}
