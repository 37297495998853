import {store} from '../../../MainApp';

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
    ACCOUNT_MANAGERS_GET_LIST,
    ACCOUNT_MANAGERS_SAVE,
    ACCOUNT_MANAGERS_DELETE,
}
    from "../../../constants/ActionTypes";


import {
    saveAccountManagersSuccess,
    showErrorMessage,
    deleteAccountManagers,
    getAccountManagersSuccess
}
    from "../../../actions/adminSettings/accountManagers/actionsAccountManagers";

import {
    HTTP_TIMEOUT,
    URL_ADMINSETTINGS_MANAGERS_CREATE,
    URL_ADMINSETTINGS_MANAGERS_LIST,
    URL_ADMINSETTINGS_MANAGERS_DELETE

}
    from "../../../constants/Urls"





/************************************************************************************************ https ************************************************************************************************/

/****
 Https : get account manager records
 ****/
const httpGetAccountManagers = async (data) =>
    await axios.post(baseUrl + URL_ADMINSETTINGS_MANAGERS_LIST, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/****
 Https : to save the account manager record
 ****/
const httpSaveAccountManagers = async (data) =>
    await axios.post(baseUrl + URL_ADMINSETTINGS_MANAGERS_CREATE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/*****
 Https: Edit Account Manager record
 *****/

const httpUpdateAccountManagerRecord = async (data) =>
    await axios.patch(baseUrl + URL_ADMINSETTINGS_MANAGERS_CREATE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);


/***
 Https: Delete the Account Manager Record
 ****/

const httpDeleteAccountManagers = async (data) => {
    await axios.patch(baseUrl + URL_ADMINSETTINGS_MANAGERS_DELETE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);
}

/************************************************************************************************ handlers ************************************************************************************************/


/****
 Handler : To get the Account Manager Records
 ****/
function* handleGetAccountManagers({payload}) {
    try {

        let dataValue = yield call(httpGetAccountManagers, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getAccountManagersSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/****
 Handler : Account Manager Save
 ****/
function* handleSaveAccountManagers({payload}) {
    try {

        let dataValue = yield call(payload.id !== -1 ? httpUpdateAccountManagerRecord : httpSaveAccountManagers, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(saveAccountManagersSuccess(dataValue));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


/*****
 * Handler: Delete Account Manager Record
 * ****/

function* handleDeleteAccountManagers({payload}) {
    try {
        let dataValue = yield call(httpDeleteAccountManagers, payload);
        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(deleteAccountManagers(dataValue));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }

}

/************************************************************************************************ receivers ************************************************************************************************/

/****
 Receivers : get all saved records
 ****/
export function* getAccountManagerRecords() {
    yield takeEvery(ACCOUNT_MANAGERS_GET_LIST, handleGetAccountManagers);
}

/****
 Receivers : account manager records save
 ****/
export function* saveAccountManagers() {
    yield takeEvery(ACCOUNT_MANAGERS_SAVE, handleSaveAccountManagers);
}

/****
 Receivers: Delete account manager
 ****/
export function* deleteAccountManagersRecord() {
    yield takeEvery(ACCOUNT_MANAGERS_DELETE, handleDeleteAccountManagers);

}


export default function* rootSaga() {
    yield all([
        fork(getAccountManagerRecords),
        fork(saveAccountManagers),
        fork(deleteAccountManagersRecord),
    ])
}

