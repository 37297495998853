import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    TRANSLATION_MODULE_GET_ALL_RECORDS,
    TRANSLATION_MODULE_NEW_EDIT_SAVE,
    TRANSLATION_MODULE_UPDATE_STATUS
} from "../../../../constants/ActionTypes";

import {
    getTranslationModuleSuccess,
    saveTranslationModuleError,
    saveTranslationModuleSuccess,
    translationModuleUpdateStatusSuccess
} from "../../../../actions/adminSettings/translations/modules/ActionsTranslationsModules";

import {
    URL_TRANSLATIONS_MODULE_CREATE,
    URL_TRANSLATIONS_MODULE_LIST
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";

/************ methods : http *******************/

/**** Https : Translation Module Save ****/
const httpSaveTranslationModule = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_MODULE_CREATE, data , AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/**** Https : Translation Module update ****/
const httpUpdateTranslationModule= async (data) =>
    await axios.patch(baseUrl + URL_TRANSLATIONS_MODULE_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/**** Https : Translation Module : Get List ****/
const httpGetTranslationModule = async (data)=>
    await axios.get(baseUrl + URL_TRANSLATIONS_MODULE_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error)

/************ methods : http response handler *******************/

/** http : Translation Module save/Edit : http response handler **/

function* handleHttpSaveTranslationModule({payload}) {
    try {
        let dataValue = yield call( payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateTranslationModule : httpSaveTranslationModule,payload)
            if (dataValue.message) {
                yield put(saveTranslationModuleError(dataValue.message))
            } else {
                yield put(saveTranslationModuleSuccess(dataValue))
            }
    }
    catch (error) {
        yield put(saveTranslationModuleError(error))
    }
}

/** http : Translation Module : http response handler **/

function* handleHttpGetAllTranslationModule() {
    try {
        let dataValue = yield call(httpGetTranslationModule)
        if (dataValue.message) {
            yield put(saveTranslationModuleError(dataValue.message))
        } else {
            yield put(getTranslationModuleSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(saveTranslationModuleError(error))
    }
}

/** http : Translation Module update status : http response handler **/

function* handleHttpUpdateTranslationModuleStatus({payload}) {
    try {
        let dataValue = yield call(httpUpdateTranslationModule, payload);
        if (dataValue.message) {
            yield put(saveTranslationModuleError(dataValue.message));
        } else {
            yield put(translationModuleUpdateStatusSuccess(dataValue));
        }
    } catch (error) {
        yield put(saveTranslationModuleError(error));
    }
}

/************ methods : export access *******************/

/**** Listeners : Translation Module save ****/
export function* saveTranslationModule(){
    yield takeEvery(TRANSLATION_MODULE_NEW_EDIT_SAVE,handleHttpSaveTranslationModule)
    }
    
/**** Listeners : Translation Module Get ****/
export function* getTranslationModuleList(){
    yield takeEvery(TRANSLATION_MODULE_GET_ALL_RECORDS,handleHttpGetAllTranslationModule)
    }
    
/**** Listeners : Translation Module Update Status ****/
export function* updateTranslationModuleStatus(){
    yield takeEvery(TRANSLATION_MODULE_UPDATE_STATUS,handleHttpUpdateTranslationModuleStatus)
    }
    
export default function* rootSaga() {
    yield all([
        fork(saveTranslationModule),
        fork(getTranslationModuleList),
        fork(updateTranslationModuleStatus)
        ])
}