import {
    TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP, TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS, TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_ERROR, TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_SUCCESS,
    TRANSLATION_FONT_GET_ALL_RECORDS,
    TRANSLATION_FONT_GET_ALL_RECORDS_ERROR,
    TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP,
    TRANSLATION_FONT_LIST_SORT,
    TRANSLATION_FONT_NEW_EDIT_SAVE,
    TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_FONT_NEW_EDIT_SAVE_NONE,
    TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS, TRANSLATION_FONT_UPDATE_PREFERRED_FONT,
    TRANSLATION_FONT_UPDATE_STATUS,
    TRANSLATION_FONT_UPDATE_STATUS_ERROR,
    TRANSLATION_FONT_UPDATE_STATUS_NONE,
    TRANSLATION_FONT_UPDATE_STATUS_SUCCESS,
    GET_TIMEZONE_LIST
} from "../../../../constants/ActionTypes";

/****
 Translation font : to get all records
 ****/
export function setUserPreferredFont(params) {
    return {
        type: TRANSLATION_FONT_UPDATE_PREFERRED_FONT,
        payload:params
    };
}

/****
 Translation font : to get all records
 ****/
export function getAllTranslationFonts() {
    return {
        type: TRANSLATION_FONT_GET_ALL_RECORDS
    };
}

export function getAllTimezones() {
    return {
        type: GET_TIMEZONE_LIST
    }
}


/****
 Translation font : to get all records :error
 ****/
export function getAllTranslationFontsError(params) {
    return {
        type: TRANSLATION_FONT_GET_ALL_RECORDS_ERROR,
        payload: params
    };
}


/****
 Translation font : to get all records : Success
 ****/
export function getAllTranslationFontsSuccess(params) {
    return {
        type: TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS,
        payload: params
    };
}


/****
 Translation font : to get all records
 ****/
export function getAllActiveTranslationFonts() {
    return {
        type: TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS
    };
}


/****
 Translation font : to get all records :error
 ****/
export function getAllActiveTranslationFontsError(params) {
    return {
        type: TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_ERROR,
        payload: params
    };
}


/****
 Translation font : to get all records : Success
 ****/
export function getAllActiveTranslationFontsSuccess(params) {
    return {
        type: TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_SUCCESS,
        payload: params
    };
}

/****
 Translation Font : to display new edit popup
 ****/

export function displayNewEditTranslationFontPopup(params) {
    return {
        type: TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP,
        payload: params
    };
}


/****
 Translation Font : to hide new edit popup
 ****/

export function hideNewEditTranslationFontPopup(params) {
    return {
        type: TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP,
        payload: params
    };
}


/****
 Translation Font : save action
 *****/
export function saveTranslationFont(params) {
    return {
        type: TRANSLATION_FONT_NEW_EDIT_SAVE,
        payload: params
    };
}

/**** Translation Font : save success action
 *****/

export function saveTranslationFontSuccess(data) {
    return {
        type: TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Translation Font : save error action
 *****/
export function saveTranslationFontError(data) {
    return {
        type: TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}


/**** Translation Font : save None
 *****/

export function saveTranslationFontNone() {
    return {
        type: TRANSLATION_FONT_NEW_EDIT_SAVE_NONE,
    };
}


/****
 * Translation Font : update status
 *****/
export function updateTranslationFontStatus(params) {
    return {
        type: TRANSLATION_FONT_UPDATE_STATUS,
        payload: params
    };
}

/****
 * Translation Font : update status : success
 *****/

export function updateTranslationFontStatusSuccess(data) {
    return {
        type: TRANSLATION_FONT_UPDATE_STATUS_SUCCESS,
        payload: data
    };
}

/****
 * Translation Font : update status : error
 *****/
export function updateTranslationFontStatusError(data) {
    return {
        type: TRANSLATION_FONT_UPDATE_STATUS_ERROR,
        payload: data
    };
}


/****
 * Translation Font : update status : none
 *****/

export function updateTranslationFontStatusNone() {
    return {
        type: TRANSLATION_FONT_UPDATE_STATUS_NONE,
    };
}

/****
 * Translation Font list sort
 *****/
export function updateTranslationFontListSort(data) {
    return {
        type: TRANSLATION_FONT_LIST_SORT,
        payload: data
    };
}
