import {
    CLIENT_MODULE_ADMIN_LIST,
    CLIENT_MODULE_ADMIN_LIST_ERROR,
    CLIENT_MODULE_ADMIN_LIST_NONE,
    CLIENT_MODULE_ADMIN_LIST_SUCCESS,
    CLIENT_MODULE_CONFIGURATION_SET_MODULE,
    CLIENT_MODULE_GET_CONFIGURATION,
    CLIENT_MODULE_GET_CONFIGURATION_ERROR,
    CLIENT_MODULE_GET_CONFIGURATION_NONE,
    CLIENT_MODULE_GET_CONFIGURATION_SUCCESS,
    CLIENT_MODULE_NEW_MODULE_SAVE,
    CLIENT_MODULE_NEW_MODULE_SAVE_ERROR,
    CLIENT_MODULE_NEW_MODULE_SAVE_NONE,
    CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS,
    CLIENT_MODULE_RESET_CURRENT_MODULE,
    CLIENT_MODULE_SET_CONFIGURATION,
    CLIENT_MODULE_SET_CONFIGURATION_ERROR,
    CLIENT_MODULE_SET_CONFIGURATION_NONE,
    CLIENT_MODULE_SET_CONFIGURATION_SUCCESS,
    CLIENT_MODULE_SET_CURRENT_MODULE
} from "../../constants/actions/clientModules/actionConfigClientModules";


/**** client modules : admin : to save record  ****/
export function saveClientModulesAdminRecord(params) {
    return {
        type: CLIENT_MODULE_NEW_MODULE_SAVE,
        payload: params
    };
}

/**** client modules : admin : to save record : success  ****/
export function saveClientModulesAdminRecordSuccess(data) {
    return {
        type: CLIENT_MODULE_NEW_MODULE_SAVE_SUCCESS,
        payload: data
    };
}


/**** client modules : admin : to save record : error  ****/
export function saveClientModulesAdminRecordError(data) {
    return {
        type: CLIENT_MODULE_NEW_MODULE_SAVE_ERROR,
        payload: data
    };
}

/**** client modules : admin : to save record : none  ****/
export function saveClientModulesAdminRecordNone() {
    return {
        type: CLIENT_MODULE_NEW_MODULE_SAVE_NONE
    };
}

/**** client modules : admin : to get all list  ****/
export function getClientModulesAdminList(params) {
    return {
        type: CLIENT_MODULE_ADMIN_LIST,
        payload: params
    };
}

/**** client modules : admin : to get all list : success  ****/
export function getClientModulesAdminListSuccess(data) {
    return {
        type: CLIENT_MODULE_ADMIN_LIST_SUCCESS,
        payload: data
    };
}


/**** client modules : admin : to get all list : error  ****/
export function getClientModulesAdminListError() {
    return {
        type: CLIENT_MODULE_ADMIN_LIST_ERROR
    };
}

/**** client modules : admin : to get all list : none  ****/
export function getClientModulesAdminListNone() {
    return {
        type: CLIENT_MODULE_ADMIN_LIST_NONE
    };
}

/**** client modules : admin : to set client module for edit ****/
export function setCurrentClientModuleData(data) {
    return {
        type: CLIENT_MODULE_SET_CURRENT_MODULE,
        payload: data
    };
}

/**** client modules : admin : to reset client module ****/
export function resetCurrentClientModuleData(data) {
    return {
        type: CLIENT_MODULE_RESET_CURRENT_MODULE,
        payload: data
    };
}

/**** client modules : to get configuration  ****/
export function getClientModulesConfiguration(params) {
    return {
        type: CLIENT_MODULE_GET_CONFIGURATION,
        payload: params
    };
}

/**** client modules : to get configuration : success  ****/
export function getClientModulesConfigurationSuccess(data) {
    return {
        type: CLIENT_MODULE_GET_CONFIGURATION_SUCCESS,
        payload: data
    };
}


/**** client modules : to get configuration : error  ****/
export function getClientModulesConfigurationError() {
    return {
        type: CLIENT_MODULE_GET_CONFIGURATION_ERROR
    };
}

/**** client modules : to get configuration : none  ****/
export function getClientModulesConfigurationNone() {
    return {
        type: CLIENT_MODULE_GET_CONFIGURATION_NONE
    };
}

/**** client modules : to set configuration  ****/
export function setClientModulesConfiguration(params) {
    return {
        type: CLIENT_MODULE_SET_CONFIGURATION,
        payload: params
    };
}

/**** client modules : to set configuration : success  ****/
export function setClientModulesConfigurationSuccess(data) {
    return {
        type: CLIENT_MODULE_SET_CONFIGURATION_SUCCESS,
        payload: data
    };
}


/**** client modules : to set configuration : error  ****/
export function setClientModulesConfigurationError() {
    return {
        type: CLIENT_MODULE_SET_CONFIGURATION_ERROR
    };
}

/**** client modules : to set configuration : none  ****/
export function setClientModulesConfigurationNone() {
    return {
        type: CLIENT_MODULE_SET_CONFIGURATION_NONE
    };
}

/**** client modules : handle checkbox click event  ****/
export function updateClientModuleConfiguration(data) {
    return {
        type: CLIENT_MODULE_CONFIGURATION_SET_MODULE,
        payload : data
    };
}