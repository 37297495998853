import UIUtils from "../../util/ui_utils";
import IntlMessages from "../../util/IntlMessages";
import React from "react";
import {
    COUNTRY_GET_ALL_COUNTRIES,
    COUNTRY_GET_ALL_COUNTRIES_ERROR,
    COUNTRY_GET_ALL_COUNTRIES_NONE,
    COUNTRY_GET_ALL_COUNTRIES_SUCCESS,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE,
    WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE,
    WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS,
    WOVO_CLIENTS_GET_PAGINATED_LIST,
    WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR,
    WOVO_CLIENTS_GET_PAGINATED_LIST_NONE,
    WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS,
    WOVO_CLIENTS_SAVE_CLIENT,
    WOVO_CLIENTS_SAVE_CLIENT_ERROR,
    WOVO_CLIENTS_SAVE_CLIENT_NONE,
    WOVO_CLIENTS_SAVE_CLIENT_SUCCESS,
    WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT
} from "../../constants/actions/clients/actionTypesWoVoClients";
import {HTTP_REQUEST_STATUS} from "../../constants/constants";
import {PAGINATION_DATA_STATUS} from "../../constants/config";
import AppUtils from "../../util/util";
import {
    CLIENT_MODULE_ADMIN_LIST_SUCCESS,
    CLIENT_MODULE_GET_CONFIGURATION_SUCCESS
} from "../../constants/actions/clientModules/actionConfigClientModules";

const clientsViewActionConfig = [
    {
        'key': 'wc_view_action_save',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_SAVE"/>,
        'parent_class': "float_right "
    },
    {
        'key': 'clients_view_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_COMPANY_INFORMATION"/>
    }
];

const clientsViewBasicInformationConfig = [
    {
        'key': 'client_legal_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_WITH_INFO,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_COMPANY_LEGAL_NAME",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_COMPANY_LEGAL_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_WITH_INFO,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_COMPANY_NAME",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_COMPANY_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_code',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_COMPANY_CODE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_COMPANY_CODE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'advisor_email',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_ADVISOR_EMAIL",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_ADVISOR_EMAIL_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'country',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_COUNTRY",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root mt-1",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_COUNTRY_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'language',
        'type': UIUtils.UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_LANGUAGE",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root form_container_root_next_multi_select_dropdown",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_LANGUAGE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'industries',
        'type': UIUtils.UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_COMPANY_INDUSTRY",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root industry_dropdown",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_COMPANY_INDUSTRY_PLACEHOLDER",
        'variant': "outlined"
    }
];

const clientsViewRelationsWovoClientConfig = [
    {
        'key': 'parent_relation',
        'type': UIUtils.UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_PARENT",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_PARENT_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'child_relation',
        'type': UIUtils.UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_CHILD",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_CHILD_PLACEHOLDER",
        'variant': "outlined"
    }
];

const clientsViewRelationsNonWovoClientConfig = [
    {
        'key': 'parent_relation',
        'type': UIUtils.UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN,
        'style': {},
        'labelClass': "pl-3",
        'localisation': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_PARENT",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_RELATIONSHIP_LABEL_PARENT_PLACEHOLDER",
        'variant': "outlined"
    }
];

const clientsTableHeaderConfig = [
    /*{
        id: 'info_error',
        label: <div className="info_round_icon ls_table_header_info_icon"/>,
        visible: true,
        sortable: false
    },*/
    {
        id: 'list_checkbox',
        label: <div className="info_round_checkbox ls_table_header_checkbox_icon"/>,
        visible: true,
        sortable: false,
        isDynamicComponent: true
    },
    {
        id: 'name',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_COMPANY_NAME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_highlight"
    },
    {
        id: 'client_key',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_COMPANY_CODE"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'parent_dashboard',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_PARENT_DASH_BOARD"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'country',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_COUNTRY"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    },
    {
        id: 'status',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_STATUS"/>,
        visible: true,
        sortable: true,
        isClickable: false,
        className: "ls_table_column_text_normal",
        isDynamicComponent: true
    },
    {
        id: 'modified_date_label',
        label: <IntlMessages id="DIC_KEY_CLIENTS_LIST_TABLE_DATE_MODIFIED"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "ls_table_column_text_normal"
    }
];

const clientsListFilterConfig = [
    {
        'key': 'clients_select_actions',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'localisation': "DIC_KEY_CLIENTS_LIST_TABLE_FILTER_ACTIONS",
        'parent_class': "table_filter_action_dropdown float_right "
    },
    {
        'key': 'clients_button_search',
        'type': UIUtils.UI_COMPONENT_TYPES.ICON_SQUARE_BUTTON_SEARCH,
        'localisation': "",
        'isStar': false,
        'isMandatory': false,
        'isRight': true
    },
    {
        'key': 'clients_search_input',
        'type': UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
        'localisation': "",
        'isStar': false,
        'isMandatory': false
    },
    {
        'key': 'clients_status_dropdown',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'isValueTitle': true,
        'localisation': "",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "table_search_input_dropdown"
    }
];

export const TABLE_PAGINATION_OPTIONS = [50, 100, 150, 200];

const initialData = {
    TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,
    clientsListFilterConfig: clientsListFilterConfig,
    clientsTableHeaderConfig: clientsTableHeaderConfig,
    clientsViewActionConfig: clientsViewActionConfig,
    clientsViewBasicInformationConfig: clientsViewBasicInformationConfig,
    clientsViewRelationsWovoClientConfig: clientsViewRelationsWovoClientConfig,
    clientsViewRelationsNonWovoClientConfig: clientsViewRelationsNonWovoClientConfig,
    
    wovoClientsPaginationFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    wovoClientsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    wovoClientsGetClientKeysByUser: HTTP_REQUEST_STATUS.NOT_STARTED,
    wovoClientSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    alertMessage: "",
    
    wovoClientsListData: [],
    wovoClientsListDataTotalRecords: 0,
    wovoClientsListDataStart: 0,
    wovoClientsListDataPages: {},
    wovoClientsListDataIdList: {},
    
    selectedViewClient: {},
    selectedClientModuleConfig: [],
    clientModuleConfig: {},
    currentClient: {},
    countriesList: [],
    userAccessClients: []
}

const ReducerWoVoClients = (state = initialData, action) => {
    switch (action.type) {
        
        case WOVO_CLIENTS_SAVE_CLIENT: {
            
            return {
                ...state,
                wovoClientSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case WOVO_CLIENTS_SAVE_CLIENT_SUCCESS: {
            let currentClient = state.currentClient;
            let alertMessage = "";
            
            if (typeof action.payload.data != "undefined") {
                currentClient = action.payload.data;
            }
            
            if (typeof action.payload.message != "undefined") {
                alertMessage = action.payload.message;
            }
            
            return {
                ...state,
                wovoClientSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentClient: currentClient,
                alertMessage: alertMessage
            }
        }
        
        case WOVO_CLIENTS_SAVE_CLIENT_ERROR: {
            
            return {
                ...state,
                wovoClientSaveStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }
        
        case WOVO_CLIENTS_SAVE_CLIENT_NONE: {
            
            return {
                ...state,
                wovoClientSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        /** clients module list **/
        case CLIENT_MODULE_ADMIN_LIST_SUCCESS: {
            
            let clientModuleConfig = {};
            
            
            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.data != "undefined") {
                clientModuleConfig = parseWoVoClientsMoules(action.payload.data.data);
            }
            
            return {
                ...state,
                clientModuleConfig: clientModuleConfig
            }
        }
        
        case CLIENT_MODULE_GET_CONFIGURATION_SUCCESS: {
            const clientModuleConfig = state.clientModuleConfig;
            
            let selectedClientModuleConfig = [];
            
            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.data != "undefined") {
                selectedClientModuleConfig = parseSelectedClientsMoules(clientModuleConfig, action.payload.data.data);
            }
            
            console.log("selectedClientModuleConfig : ", selectedClientModuleConfig)
            
            return {
                ...state,
                selectedClientModuleConfig: selectedClientModuleConfig
            }
        }
        
        case WOVO_CLIENTS_SET_SELECTED_VIEW_CLIENT: {
            
            return {
                ...state,
                selectedViewClient: action.payload
            }
        }
        
        case WOVO_CLIENTS_GET_PAGINATED_LIST: {
            
            let wovoClientsListData = state.wovoClientsListData;
            let wovoClientsListDataPages = state.wovoClientsListDataPages;
            let wovoClientsListDataIdList = state.wovoClientsListDataIdList;
            
            if (typeof action.payload.start === "undefined") {
                wovoClientsListData = [];
                wovoClientsListDataPages = {};
                wovoClientsListDataIdList = {};
            } else {
                let currentIndex = parseInt(action.payload.start / TABLE_PAGINATION_OPTIONS[TABLE_PAGINATION_OPTIONS.length - 1]);
                wovoClientsListDataPages[currentIndex]["dataStatus"] = PAGINATION_DATA_STATUS.IN_PROGRESS;
            }
            
            return {
                ...state,
                wovoClientsPaginationFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                wovoClientsListData: wovoClientsListData,
                wovoClientsListDataPages: wovoClientsListDataPages,
                wovoClientsListDataIdList: wovoClientsListDataIdList
            }
        }
        
        case WOVO_CLIENTS_GET_PAGINATED_LIST_SUCCESS: {
            
            let wovoClientsListData = state.wovoClientsListData;
            let wovoClientsListDataPages = state.wovoClientsListDataPages;
            let wovoClientsListDataTotalRecords = state.wovoClientsListDataTotalRecords;
            let wovoClientsListDataIdList = state.wovoClientsListDataIdList;
            
            if (action.payload.data && action.payload.data.data) {
                let content = action.payload.data;
                
                if (typeof content.start !== "undefined" && content.start === 0) {
                    wovoClientsListDataTotalRecords = content.total;
                    
                    wovoClientsListData = new Array(wovoClientsListDataTotalRecords).fill({});
                    
                    let totalPages = parseInt(wovoClientsListDataTotalRecords / TABLE_PAGINATION_OPTIONS[TABLE_PAGINATION_OPTIONS.length - 1]) + 1;
                    
                    wovoClientsListDataPages = {};
                    wovoClientsListDataIdList = {}
                    
                    for (let i = 1; i <= totalPages; i++) {
                        wovoClientsListDataPages[i] = {
                            "dataStatus": PAGINATION_DATA_STATUS.NO_DATA,
                            "data": []
                        };
                        wovoClientsListDataIdList[i] = [];
                    }
                }
                
                let currentIndex = parseInt(content.start / TABLE_PAGINATION_OPTIONS[TABLE_PAGINATION_OPTIONS.length - 1]);
                
                wovoClientsListDataPages[currentIndex] = {
                    "dataStatus": PAGINATION_DATA_STATUS.COMPLETED,
                    "data": content.data
                };
                
                let parsedData = parseWoVoClientsPaginatedList(content.data);
                
                for (let i = 0; i < parsedData.length; i++) {
                    if (content.start + i < wovoClientsListData.length) {
                        wovoClientsListData[content.start + i] = parsedData[i];
                        
                        if (typeof wovoClientsListDataIdList[currentIndex] == "undefined") {
                            wovoClientsListDataIdList[currentIndex] = [];
                        }
                        wovoClientsListDataIdList[currentIndex].push(parsedData[i].id);
                    }
                }
            }
            
            wovoClientsListData = JSON.parse(JSON.stringify(wovoClientsListData));
            
            
            return {
                ...state,
                wovoClientsPaginationFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                wovoClientsListData: wovoClientsListData,
                wovoClientsListDataPages: wovoClientsListDataPages,
                wovoClientsListDataTotalRecords: wovoClientsListDataTotalRecords,
                wovoClientsListDataIdList: wovoClientsListDataIdList
            }
        }
        
        case WOVO_CLIENTS_GET_PAGINATED_LIST_ERROR: {
            
            return {
                ...state,
                wovoClientsPaginationFetchStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }
        
        case WOVO_CLIENTS_GET_PAGINATED_LIST_NONE: {
            
            return {
                ...state,
                wovoClientsPaginationFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_BY_KEY: {
            
            return {
                ...state,
                wovoClientsGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                currentClient: {}
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_BY_KEY_SUCCESS: {
            
            let currentClient = state.currentClient;
            
            if (typeof action.payload.data != "undefined") {
                currentClient = action.payload.data;
            }
            
            return {
                ...state,
                wovoClientsGetStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentClient: currentClient
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_BY_KEY_ERROR: {
            
            return {
                ...state,
                wovoClientsGetStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_BY_KEY_NONE: {
            
            return {
                ...state,
                wovoClientsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        case COUNTRY_GET_ALL_COUNTRIES: {
            
            return {
                ...state
            }
        }
        
        case COUNTRY_GET_ALL_COUNTRIES_SUCCESS: {
            
            let countriesList = state.countriesList;
            
            if (typeof action.payload.data != "undefined") {
                countriesList = parseCountryList(action.payload.data);
            }
            
            return {
                ...state,
                countriesList: countriesList
            }
        }
        
        case COUNTRY_GET_ALL_COUNTRIES_ERROR: {
            
            return {
                ...state
            }
        }
        
        case COUNTRY_GET_ALL_COUNTRIES_NONE: {
            
            return {
                ...state
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER: {
            
            return {
                ...state,
                wovoClientsGetClientKeysByUser: HTTP_REQUEST_STATUS.IN_PROGRESS,
                userAccessClients: []
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_SUCCESS: {
            
            let userAccessClients = [];
            
            if (typeof action.payload.data != "undefined") {
                userAccessClients = parseClientKeysResult(action.payload.data);
            }
            
            return {
                ...state,
                wovoClientsGetClientKeysByUser: HTTP_REQUEST_STATUS.NOT_STARTED,
                userAccessClients: userAccessClients
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_ERROR: {
            
            return {
                ...state,
                wovoClientsGetClientKeysByUser: HTTP_REQUEST_STATUS.NOT_STARTED,
                userAccessClients: []
            }
        }
        
        case WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER_NONE: {
            
            return {
                ...state,
                wovoClientsGetClientKeysByUser: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        
        default: {
            return {
                ...state
            }
        }
    }
}

function parseClientKeysResult(data) {
    try {
        
        for (let i = 0; i < data.length; i++) {
            if (data[i]['alias_name'] === "") {
                data[i]['alias_name'] = data[i]['name'];
            }
            
            data[i]['label'] = data[i]['name'];
            data[i]['value'] = data[i]['client_key'];
        }
        
        AppUtils.sortJSONListByKey(data, 'name');
        
        
    } catch (ex) {
        console.error("parseClientKeysResult : error : ", ex);
    }
    
    return data;
}

function parseCountryList(data) {
    try {
        for (let i = 0; i < data.length; i++) {
            data[i]['label'] = data[i]['name'];
            data[i]['value'] = data[i]['short_code'];
        }
        
        AppUtils.sortJSONListByKey(data, 'name');
        
    } catch (ex) {
        console.error("parseCountryList : error : ", ex);
    }
    
    return data;
    
}


function parseWoVoClientsPaginatedList(data) {
    let result = data;
    try {
        for (let i = 0; i < result.length; i++) {
            result[i]["isActive"] = result[i]["status"];
            result[i]["status"] = AppUtils.getTranslationFromKey(result[i]["status"] ? "DIC_KEY_CLIENTS_VIEW_LABEL_STATUS_ACTIVE" : "DIC_KEY_CLIENTS_VIEW_LABEL_STATUS_INACTIVE");
        }
    } catch (ex) {
        console.error("parseWoVoClientsPaginatedList : error : ", ex);
    }
    
    return result;
    
}

/****
 * to generate wovo all config map
 ****/
function parseWoVoClientsMoules(data) {
    let result = {};
    try {
        
        for (let i = 0; i < data.length; i++) {
            if (data[i]["parent"] === null) {
                result[data[i]["id"]] = data[i];
            }
        }
        
    } catch (ex) {
        console.error("parseWoVoClientsMoules : error : ", ex);
    }
    
    return result;
    
}

/****
 * to generate selected client module configuration
 ****/
function parseSelectedClientsMoules(configuration, data) {
    let result = [];
    try {
        
        let configMap = {};
        
        for (let i = 0; i < data.length; i++) {
            configMap[data[i]["client_module"]] = data[i]["is_active"];
        }
        
        
        for (let key in configuration) {
            let currentItem = configuration[key];
            
            if (typeof configMap[key] != "undefined") {
                currentItem["status"] = configMap[key];
            } else {
                currentItem["status"] = currentItem["is_enabled_by_default"];
            }
            
            currentItem["key"] = key;
            currentItem["type"] = UIUtils.UI_COMPONENT_TYPES.FORM_HORIZONTAL_LABEL_SWITCH;
            
            result.push(currentItem);
        }
        
        
    } catch (ex) {
        console.error("parseSelectedClientsMoules : error : ", ex);
    }
    
    return result;
    
}


export default ReducerWoVoClients;
