
import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_GET_SUMMARY_TABLE
} from "../../../constants/Urls";
import {
    CONNECT_API_GET_SUMMARY_TABLE_REQUEST,
    CONNECT_API_GET_SUMMARY_TABLE_SUCCESS,
    CONNECT_API_GET_SUMMARY_TABLE_FAILURE, CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS, CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE
} from '../../../constants/ActionTypes';


// function that makes the api request and returns a Promise for response
const fetchConnectSummaryTable = async ({payload}) =>
    await axios.post(baseUrl+URL_CONNECT_GET_SUMMARY_TABLE, payload, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => error);


// worker saga: makes the api call when watcher saga sees the action
export function* connectGetSummaryTable(payload) {
    try {
      const response = yield call(fetchConnectSummaryTable, payload);
      const connectSummaryTable = response.data;
      yield put({ type: CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS, connectSummaryTable });
    
    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE, error });
    }
  }

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getConnectSummaryTable() {
    yield takeEvery(CONNECT_API_GET_SUMMARY_TABLE_REQUEST, connectGetSummaryTable);
}

