import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA
} from "../../../../constants/actions/survey/survey/actionTypesSurvey";

import {
    URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD,
    URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID,
    URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";

import {
    surveyRawdataDownloadResponsesSuccess,
    surveyRawdataDownloadResponsesError,
    surveyRawDataDownloadResponsesUUIDDataError,
    surveyRawDataDownloadResponsesUUIDDataSuccess,
    surveyRawDataDownloadResponsesUUIDError,
    surveyRawDataDownloadResponsesUUIDSuccess
} from "../../../../actions";
import {ERROR_KEY} from '../../../../constants/config';


/************ methods : http *******************/

/** http : Survey reports raw data: To download the responses**/

const httpSurveyRawDataResponsesDownload = async (data) =>{
     return await axios.post(baseUrl + URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey reports raw data uuid : To download the responses**/
const httpSurveyRawDataResponsesDownloadUUID = async (data) =>{
    return await axios.post(baseUrl + URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey reports raw data uuid data : To download the responses**/
const httpSurveyRawDataResponsesDownloadUUIDData = async (data) =>{
    return await axios.post(baseUrl + URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/************ methods : http response handler *******************/


/** http : Survey Rawdata response Download: http response handler **/

function* handleHttpSurveyRawDataResponsesDownload({payload}) {
    try {
        let result = yield call(httpSurveyRawDataResponsesDownload,payload);
        if ( AppUtils.isResponseSuccess(result)){
            yield put(surveyRawdataDownloadResponsesSuccess(result));
        } else {
            yield put(surveyRawdataDownloadResponsesError(result.response));
        }
    } catch (error) {
        yield put(surveyRawdataDownloadResponsesError(error));
    }
}

/** http : Survey Rawdata uuid response Download: http response handler **/

function* handleHttpSurveyRawDataResponsesDownloadUUID({payload}) {
    try {
        let result = yield call(httpSurveyRawDataResponsesDownloadUUID,payload);

        if (result.message) {
            yield put(surveyRawDataDownloadResponsesUUIDError(result.response));
        } else {
            yield put(surveyRawDataDownloadResponsesUUIDSuccess(result));

        }
    } catch (error) {
        yield put(surveyRawDataDownloadResponsesUUIDError(error));
    }
}

/** http : Survey Rawdata uuid data response Download: http response handler **/

function* handleHttpSurveyRawDataResponsesDownloadUUIDData({payload}) {
    try {
        let result = yield call(httpSurveyRawDataResponsesDownloadUUIDData,payload);

        if (ERROR_KEY in result.data) {
            yield put(surveyRawDataDownloadResponsesUUIDDataError(result.data));
        } else {
            yield put(surveyRawDataDownloadResponsesUUIDDataSuccess(result));
        }
    } catch (error) {
        yield put(surveyRawDataDownloadResponsesUUIDDataError(error));
    }
}


/************ methods : export access *******************/

/** Survey rawdata responses download **/
export function* surveyReportsRawdataDownloadResponses() {
    yield takeEvery(SURVEY_RAW_DATA_RESPONSES_DOWNLOAD, handleHttpSurveyRawDataResponsesDownload);
}

/** Survey raw data responses uuid download **/
export function* surveyReportsRawDataDownloadResponsesUUID() {
    yield takeEvery(SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID, handleHttpSurveyRawDataResponsesDownloadUUID);
}

/** Survey raw data responses uuid data download **/
export function* surveyReportsRawDataDownloadResponsesUUIDData() {
    yield takeEvery(SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA, handleHttpSurveyRawDataResponsesDownloadUUIDData);
}

export default function* rootSaga() {
    yield all([
        fork(surveyReportsRawdataDownloadResponses),
        fork(surveyReportsRawDataDownloadResponsesUUID),
        fork(surveyReportsRawDataDownloadResponsesUUIDData),
    ]);
}