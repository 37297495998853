import {
    INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS,
    INDUSTRY_TYPE_NEW_EDIT_SAVE,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS,
    INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS,
    INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP,
    INDUSTRY_TYPE_GET_ALL_RECORDS,
    INDUSTRY_TYPE_GET_ALL_RECORDS_IN_PROGRESS,
    INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE,
    INDUSTRY_TYPE_UPDATE_STATUS_NONE,
    INDUSTRY_TYPE_UPDATE_STATUS_IN_PROGRESS,
    INDUSTRY_TYPE_UPDATE_STATUS,
    INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS
} from "../../../constants/ActionTypes";

/**** Industry types : to display new edit popup ****/
export function displayNewEditIndustryTypePopup( params ) {
    return {
        type: INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}

/**** Industry types : to hide new edit popup ****/
export function hideNewEditIndustryTypePopup( params ) {
    return {
        type: INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}

/**** Industry types : save in progress action ****/
export function saveIndustryTypeProgress() {
    return {
        type: INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS
    };
}

/**** Industry types : save action ****/
export function saveIndustryType( params ) {
    return {
        type: INDUSTRY_TYPE_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Industry types : save error action ****/
export function saveIndustryTypeError(data) {
    return {
        type: INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Industry types : save success action ****/
export function saveIndustryTypeSuccess(data) {
    return {
        type: INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Industry types : get success action ****/
export function getIndustryTypesSuccess(data) {
    return {
        type: INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS,
        payload: data
    };
}

/**** Industry types : get action ****/
export function getIndustryTypes(data) {
    return {
        type: INDUSTRY_TYPE_GET_ALL_RECORDS,
        payload: data
    };
}

/**** Industry types : get in progress action ****/
export function getIndustryTypesInProgress(data) {
    return {
        type: INDUSTRY_TYPE_GET_ALL_RECORDS_IN_PROGRESS,
        payload: data
    };
}

/**** Industry types : save none ****/
export function saveIndustryTypeNone() {
    return {
        type: INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE
    };
}

/**** Industry types : update none ****/
export function IndustryTypeUpdateStatusNone() {
    return {
        type: INDUSTRY_TYPE_UPDATE_STATUS_NONE
    };
}

/**** Industry types : update in progress action ****/
export function IndustryTypeUpdateStatusInProgress() {
    return {
        type: INDUSTRY_TYPE_UPDATE_STATUS_IN_PROGRESS
    };
}

/**** Industry types : update action ****/
export function IndustryTypeUpdateStatus(params) {
    return {
        type: INDUSTRY_TYPE_UPDATE_STATUS,
        payload : params
    };
}

/**** Industry types : update success action ****/
export function IndustryTypeUpdateStatusSuccess(params) {
    return {
        type: INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}
