import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { baseUrl } from '../../../util/keys';
import {
	URL_SURVEY_NEW_SURVEY_QUESTIONS_DELETE,
	URL_SURVEY_NEW_SURVEY_QUESTIONS_SAVE,
	URL_SURVEY_TEMPLATE_QUESTIONS_DELETE,
	URL_SURVEY_TEMPLATE_QUESTIONS_SAVE,
	URL_SURVEY_QUESTIONS_DUPLICATE,
	URL_SURVEY_TEMPLATE_QUESTIONS_DUPLICATE,
	URL_SURVEY_QUESTIONS_ORDER_UPDATE,
	URL_SURVEY_TEMPLATE_QUESTIONS_ORDER_UPDATE,
	URL_SURVEY_QUESTION_RANDOMIZE,
} from '../../../constants/Urls';
import AppUtils from '../../../util/util';
import {
	SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
	SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
	SURVEY_SURVEY_QUESTION_DELETE,
	SURVEY_TEMPLATE_QUESTION_DELETE,
	SURVEY_SURVEY_QUESTION_DUPLICATE,
	SURVEY_TEMPLATE_QUESTION_DUPLICATE,
	SURVEY_SURVEY_QUESTION_ORDER_CHANGE,
	SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE,
	SURVEY_QUESTION_RANDOMIZE,
} from '../../../constants/actions/survey/questions/actionTypesSurveyQuestions';
import {
	deleteSurveyTemplateQuestionError,
	deleteSurveyTemplateQuestionSuccess,
	saveSurveyTemplateQuestionError,
	saveSurveyTemplateQuestionSuccess,
	saveNewSurveyQuestionError,
	saveNewSurveyQuestionSuccess,
	deleteSurveyQuestionSuccess,
	deleteSurveyQuestionError,
	duplicateSurveyQuestionSuccess,
	duplicateSurveyQuestionError,
	duplicateSurveyTemplateQuestionSuccess,
	duplicateSurveyTemplateQuestionError,
	updateSurveyQuestionOrderSucess,
	updateSurveyQuestionOrderError,
	updateSurveyTemplateQuestionOrderSucess,
	updateSurveyTemplateQuestionOrderError,
	surveyQuestionRandomizeSuccess,
	surveyQuestionRandomizeError,
} from '../../../actions/survey/questions/ActionsSurveyQuestions';

/************ methods : http *******************/
/** http : Survey template question : http save **/
const httpSaveSurveyTemplateQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_TEMPLATE_QUESTIONS_SAVE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey template question : http update **/
const httpUpdateSurveyTemplateQuestion = async (data) =>
	await axios
		.patch(
			baseUrl + URL_SURVEY_TEMPLATE_QUESTIONS_SAVE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey template question : http delete **/
const httpDeleteSurveyTemplateQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_TEMPLATE_QUESTIONS_DELETE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey Question : http save **/
const httpSaveSurveyQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_NEW_SURVEY_QUESTIONS_SAVE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey Question : http update **/
const httpUpdateSurveyQuestion = async (data) =>
	await axios
		.patch(
			baseUrl + URL_SURVEY_NEW_SURVEY_QUESTIONS_SAVE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey question : http delete **/
const httpDeleteSurveyQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_NEW_SURVEY_QUESTIONS_DELETE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});
/** http : Survey question : http duplicate **/

const httpDuplicateSurveyQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_QUESTIONS_DUPLICATE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey template question : http duplicate **/

const httpDuplicateSurveyTemplateQuestion = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_TEMPLATE_QUESTIONS_DUPLICATE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey  : update questions order **/
const httpUpdateSurveyQuestionsOrder = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_QUESTIONS_ORDER_UPDATE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey template : update questions order **/
const httpUpdateSurveyTemplateQuestionsOrder = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_TEMPLATE_QUESTIONS_ORDER_UPDATE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/** http : Survey question : randomize **/
const httSurveyQuestionRandomize = async (data) =>
	await axios
		.post(
			baseUrl + URL_SURVEY_QUESTION_RANDOMIZE,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser)
		.catch((error) => {
			return error;
		});

/************ methods : http response handler *******************/

/** http : Survey template question save : http response handler **/
function* handleHttpSaveSurveyTemplateQuestion({ payload }) {
	try {
		let result = yield call(
			payload.question && payload.question !== '' && payload.question !== -1
				? httpUpdateSurveyTemplateQuestion
				: httpSaveSurveyTemplateQuestion,
			payload
		);

		if (result.message) {
			yield put(saveSurveyTemplateQuestionError(result.response));
		} else {
			yield put(saveSurveyTemplateQuestionSuccess(result));
		}
	} catch (error) {
		yield put(saveSurveyTemplateQuestionError(error));
	}
}

/** http : Survey template Question : http response handler **/
function* handleHttpDeleteSurveyTemplateQuestion({ payload }) {
	try {
		let result = yield call(httpDeleteSurveyTemplateQuestion, payload);

		if (result.message) {
			yield put(deleteSurveyTemplateQuestionError(result.response));
		} else {
			yield put(deleteSurveyTemplateQuestionSuccess(result));
		}
	} catch (error) {
		yield put(deleteSurveyTemplateQuestionError(error));
	}
}

/** http : Survey template question save : http response handler **/
function* handleHttpSaveSurveyQuestion({ payload }) {
	try {
		let result = yield call(
			payload.question && payload.question !== '' && payload.question !== -1
				? httpUpdateSurveyQuestion
				: httpSaveSurveyQuestion,
			payload
		);

		if (result.message) {
			yield put(saveNewSurveyQuestionError(result.response));
		} else {
			yield put(saveNewSurveyQuestionSuccess(result));
		}
	} catch (error) {
		yield put(saveNewSurveyQuestionError(error));
	}
}

/** http : Survey Question delete : http response handler **/
function* handleHttpDeleteSurveyQuestion({ payload }) {
	try {
		let result = yield call(httpDeleteSurveyQuestion, payload);

		if (result.message) {
			yield put(deleteSurveyQuestionError(result.response));
		} else {
			yield put(deleteSurveyQuestionSuccess(result));
		}
	} catch (error) {
		yield put(deleteSurveyQuestionError(error));
	}
}

/** http : Survey Question duplicate : http response handler **/
function* handleHttpDuplicateSurveyQuestion({ payload }) {
	try {
		let result = yield call(httpDuplicateSurveyQuestion, payload);
		console.log(result);

		if (result.message) {
			yield put(duplicateSurveyQuestionError(result.response));
		} else {
			yield put(duplicateSurveyQuestionSuccess(result));
		}
	} catch (error) {
		yield put(duplicateSurveyQuestionError(error));
	}
}

/** http : Survey Template Question duplicate : http response handler **/
function* handleHttpDuplicateSurveyTemplateQuestion({ payload }) {
	try {
		let result = yield call(httpDuplicateSurveyTemplateQuestion, payload);

		if (result.message) {
			yield put(duplicateSurveyTemplateQuestionError(result.response));
		} else {
			yield put(duplicateSurveyTemplateQuestionSuccess(result));
		}
	} catch (error) {
		yield put(duplicateSurveyTemplateQuestionError(error));
	}
}

/** http : Survey  Question : update survey questions order **/
function* handleHttpUpdateSurveyQuestionOrder({ payload }) {
	try {
		let result = yield call(httpUpdateSurveyQuestionsOrder, payload);
		if (result.message) {
			yield put(updateSurveyQuestionOrderError(result.response));
		} else {
			yield put(updateSurveyQuestionOrderSucess(result));
		}
	} catch (error) {
		yield put(updateSurveyQuestionOrderError(error));
	}
}

/** http : Survey template Question : update survey template questions order **/
function* handleHttpUpdateSurveyTemplateQuestionOrder({ payload }) {
	console.log(JSON.stringify(payload));
	try {
		let result = yield call(httpUpdateSurveyTemplateQuestionsOrder, payload);
		if (result.message) {
			yield put(updateSurveyTemplateQuestionOrderError(result.response));
		} else {
			yield put(updateSurveyTemplateQuestionOrderSucess(result));
		}
	} catch (error) {
		yield put(updateSurveyTemplateQuestionOrderError(error));
	}
}

/** http : Survey question : Randomize **/
function* handleHttpSurveyQuestionRandomize({ payload }) {
	try {
		let result = yield call(httSurveyQuestionRandomize, payload);

		if (result.message) {
			yield put(surveyQuestionRandomizeError(result.response));
		} else {
			yield put(surveyQuestionRandomizeSuccess(result));
		}
	} catch (error) {
		yield put(surveyQuestionRandomizeError(error));
	}
}

/************ methods : export access *******************/

/** Survey template question : save **/
export function* saveSurveyTemplateQuestion() {
	yield takeEvery(
		SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
		handleHttpSaveSurveyTemplateQuestion
	);
}

/** Survey template question  : delete **/
export function* deleteSurveyTemplateQuestion() {
	yield takeEvery(
		SURVEY_TEMPLATE_QUESTION_DELETE,
		handleHttpDeleteSurveyTemplateQuestion
	);
}

/** Survey question : save **/
export function* saveSurveyQuestion() {
	yield takeEvery(
		SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE,
		handleHttpSaveSurveyQuestion
	);
}

/** Survey question  : delete **/
export function* deleteSurveyQuestion() {
	yield takeEvery(
		SURVEY_SURVEY_QUESTION_DELETE,
		handleHttpDeleteSurveyQuestion
	);
}

/** Survey question  : duplicate **/
export function* duplicateSurveyQuestion() {
	yield takeEvery(
		SURVEY_SURVEY_QUESTION_DUPLICATE,
		handleHttpDuplicateSurveyQuestion
	);
}

/** Survey tmplate question  : duplicate **/
export function* duplicateSurveyTemplateQuestion() {
	yield takeEvery(
		SURVEY_TEMPLATE_QUESTION_DUPLICATE,
		handleHttpDuplicateSurveyTemplateQuestion
	);
}

/** Survey  questions : update Survey Questions Order **/
export function* updateSurveyQuestionOrder() {
	yield takeEvery(
		SURVEY_SURVEY_QUESTION_ORDER_CHANGE,
		handleHttpUpdateSurveyQuestionOrder
	);
}

/** Survey template questions : update Survey Questions Order **/
export function* updateSurveyTemplateQuestionOrder() {
	yield takeEvery(
		SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE,
		handleHttpUpdateSurveyTemplateQuestionOrder
	);
}

/** Survey questions : randomize **/
export function* surveyRandomize() {
	yield takeEvery(SURVEY_QUESTION_RANDOMIZE, handleHttpSurveyQuestionRandomize);
}

export default function* rootSaga() {
	yield all([
		fork(saveSurveyTemplateQuestion),
		fork(deleteSurveyTemplateQuestion),
		fork(saveSurveyQuestion),
		fork(deleteSurveyQuestion),
		fork(duplicateSurveyQuestion),
		fork(duplicateSurveyTemplateQuestion),
		fork(updateSurveyQuestionOrder),
		fork(updateSurveyTemplateQuestionOrder),
		fork(surveyRandomize),
	]);
}
