/** survey : translations : download  */
export const SURVEY_TRANSLATIONS_DOWNLOAD = "SURVEY_TRANSLATIONS_DOWNLOAD";
export const SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS = 'SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS';
export const SURVEY_TRANSLATIONS_DOWNLOAD_ERROR = 'SURVEY_TRANSLATIONS_DOWNLOAD_ERROR';
export const SURVEY_TRANSLATIONS_DOWNLOAD_NONE = "SURVEY_TRANSLATIONS_DOWNLOAD_NONE";

/** survey : translations : upload  */
export const SURVEY_TRANSLATIONS_UPLOAD = "SURVEY_TRANSLATIONS_UPLOAD";
export const SURVEY_TRANSLATIONS_UPLOAD_SUCCESS = 'SURVEY_TRANSLATIONS_UPLOAD_SUCCESS';
export const SURVEY_TRANSLATIONS_UPLOAD_ERROR = 'SURVEY_TRANSLATIONS_UPLOAD_ERROR';
export const SURVEY_TRANSLATIONS_UPLOAD_NONE = "SURVEY_TRANSLATIONS_UPLOAD_NONE";

export const SURVEY_TRANSLATIONS_SHOW_POPUP = "SURVEY_TRANSLATIONS_SHOW_POPUP";
export const SURVEY_TRANSLATIONS_HIDE_POPUP = "SURVEY_TRANSLATIONS_HIDE_POPUP";

export const SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW = "SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW";
export const SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE = "SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE";