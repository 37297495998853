import{
SURVEY_GET_SUMMARY_TABLE_DATA,
SURVEY_GET_SUMMARY_TABLE_DATA_ERROR,
SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
SURVEY_GET_SUMMARY_TABLE_DATA_NONE,
SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS,
SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA,
SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
SURVEY_SET_CURRENT_REPORT_TYPE,
SURVEY_SET_CURRENT_GROUP_BY_TYPE,
SURVEY_SUMMARY_SET_TABLE_RELOAD,
SET_SUMMARY_TABLE_LAYOUT_TYPE,
SURVEY_SUMMARY_TABLE_SORTING,
SURVEY_SUMMARY_TABLE_SORTING_NONE
}from "../../../constants/actions/survey/survey/actionTypesSurvey";

/****
  Survey : get Summary Table Data
 ****/
export function getSurveySummaryTableData(params) {
    return {
        type: SURVEY_GET_SUMMARY_TABLE_DATA,
        payload:params
    };
}

/****
 Survey  : get  Summary Table Data success
 ****/
export function getSurveySummaryTableDataSuccess(data) {
    return {
        type: SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS,
        payload : data
    };
}

/****
 Survey  : get Summary Table Data  error
 ****/
export function getSurveySummaryTableDataError(params) {
    return {
        type: SURVEY_GET_SUMMARY_TABLE_DATA_ERROR,
        payload : params
    };
}

/****
  Survey : get Summary Table Data : in progress
 ****/
export function getSurveySummaryTableDataInProgress() {
    return {
        type: SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
    };
}


/****
 Survey  : get Summary Table Data status to none
 ****/
export function getSurveySummaryTableDataStatusNone(params) {
    return {
        type: SURVEY_GET_SUMMARY_TABLE_DATA_NONE,
        payload : params
    };
}

/****
 Survey : download Summary Table Data
 ****/
 export function downloadSurveySummaryTableData(params) {
    return {
        type: SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA,
        payload: params
    };
}

/****
 Survey : download : Summary Table Data : success
 ****/
export function downloadSurveySummaryTableDataSuccess(data) {
    return {
        type: SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
        payload: data
    };
}

/****
 Survey : download : Summary Table Data : error
 ****/
export function downloadSurveySummaryTableDataError(params) {
    return {
        type: SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
        payload: params
    };
}

/****
 Survey : download : Summary Table Data : in progress
 ****/
export function downloadSurveySummaryTableDataInProgress() {
    return {
        type: SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
    };
}

/****
 Survey : download : Summary Table Data :  none
 ****/
export function downloadSurveySummaryTableDataStatusNone(params) {
    return {
        type: SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
        payload: params
    };
}


/****
 * To set survey summary table report type
 ****/
export function setSurveyReportType(params){
    return{
        type : SURVEY_SET_CURRENT_REPORT_TYPE,
        payload: params
    };
}

/****
 * To set survey summary table group by type
 ****/
export function setSurveyGroupByType(params){
    return{
        type : SURVEY_SET_CURRENT_GROUP_BY_TYPE,
        payload: params
    };
}

/****
 * To reload survey summary table
 ****/
export function setSurveySummaryTableReload(params){
    return{
        type : SURVEY_SUMMARY_SET_TABLE_RELOAD,
        payload: params
    };
}

/****
 * To set survey summary table Layout type
 ****/
 export function setSurveySummaryTableLayoutType(params){
    return{
        type : SET_SUMMARY_TABLE_LAYOUT_TYPE,
        payload: params
    };
}

/**To Apply sorting for summary table */
export function sortSurveySummaryTable(params) {
    return {
        type: SURVEY_SUMMARY_TABLE_SORTING,
        payload: params
    };
}

/**To remove sorting for summary table */
export function sortSurveySummaryTableNone(params) {
    return {
        type: SURVEY_SUMMARY_TABLE_SORTING_NONE,
        payload: params
    };
}