import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import {
    SURVEY_CLONE_HIDE,
    SURVEY_CLONE_SET_CLONE_TYPE,
    SURVEY_CLONE_SET_FILTER,
    SURVEY_CLONE_SHOW, SURVEY_CLONE_SURVEY_SAVE_ERROR,
    SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS,
    SURVEY_CLONE_SURVEY_SAVE_NONE, SURVEY_CLONE_SURVEY_SAVE_SUCCESS, SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE, SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS
} from "../../../constants/actions/survey/clone/actionTypesSurveyCloneHandler";
import {SURVEY_CLONE_TYPES, SURVEY_CREATION_TYPES} from "../../../constants/config";
import {WINDOW_WIDTH} from "../../../constants/ActionTypes";
import AppUtils from "../../../util/util";

let filters = [
    {
        key: 'titles',
        type: "textfield",
        isMandatory: false,
        localisation: "DIC_SURVEY_CLONE_FILTER_TITLE_NAME"
    },
    {
        key: 'supported_languages',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_CLONE_FILTER_TITLE_SUPPORTED_LANGUAGES"
    },
    /*{
        key: 'categories',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_CLONE_FILTER_TITLE_CATEGORIES"
    }*/
];

let titleField =  {
    key: 'name_input',
    type: "textfield",
    isMandatory: true,
    localisation: "DIC_SURVEY_CLONE_TITLE"
};

let listIons = {
    survey  : {
        icon: "S",
        color: "#4caf50"
    },
    template : {
        icon: "T",
        color: "#ff9800"
    }
};

const initialData = {
    containerWidth : window.innerWidth * 0.8,
    isViewVisible: false,
    cloneType: "",
    filters : filters,
    titleField : titleField,
    viewTitle : "DIC_SURVEY_CLONE_TITLE_SURVEY_FROM_TEMPLATE",
    cloneStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    filteredItems : [],
    alertMessage : '',
    savedContent : {},
    loader : false,
    listIons : listIons

};


const ReducerSurveyCloneHandler = (state = initialData, action) => {

    switch (action.type) {

        case WINDOW_WIDTH : {

            return {
                ...state,
                containerWidth : action.width * 0.8
            };
        }

        /** template clone handle response : success **/
        case SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS:{
            let savedContent = state.savedContent;
            if (action.payload.data && action.payload.data.data) {
                savedContent = AppUtils.updateCurrentSurvey(action.payload.data.data);
                savedContent = JSON.parse(JSON.stringify(savedContent));
            }

            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage : action.payload.data.message,
                savedContent : savedContent,
                loader : false
            };
        }

        /** survey clone handle response : success **/
        case SURVEY_CLONE_SURVEY_SAVE_SUCCESS:{
            let savedContent = AppUtils.updateCurrentSurvey(action.payload.data.data);
            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage : action.payload.data.message,
                savedContent : savedContent,
                loader : false
            };
        }

        /** template clone handle response : error **/
        case SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR:

            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage : action.payload.data.error,
                savedContent : {},
                loader : false
            };

        /** survey clone handle response : error **/
        case SURVEY_CLONE_SURVEY_SAVE_ERROR:

            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage : action.payload.data.error,
                savedContent : {},
                loader : false
            };

        /** to set clone status reset **/
        case SURVEY_CLONE_SURVEY_SAVE_NONE:
        case SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE:
            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage : '',
                savedContent : {},
                loader : false
            };

        /** to set clone in progress **/
        case SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS:
        case SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS:
            return {
                ...state,
                cloneStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage : '',
                savedContent : {},
                loader : true
            };

        /** to set clone filters **/
        case SURVEY_CLONE_SET_FILTER:
            return {
                ...state
            };

        /** to set clone type **/
        case SURVEY_CLONE_SET_CLONE_TYPE:

            let viewTitle = "DIC_SURVEY_CLONE_TITLE_SURVEY_FROM_TEMPLATE";

            if ( action.payload === SURVEY_CLONE_TYPES.SURVEY_FROM_SURVEY ) {
                viewTitle = "DIC_SURVEY_CLONE_TITLE_SURVEY_FROM_SURVEY";
            }

            return {
                ...state,
                cloneType: action.payload,
                viewTitle : viewTitle
            };

        /** to show the clone view **/
        case SURVEY_CLONE_SHOW:
            return {
                ...state,
                isViewVisible: true,
                savedContent : {},
                cloneStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                filteredItems : [],
                alertMessage : ''
            };

        /** to hide the clone view **/
        case SURVEY_CLONE_HIDE:
            return {
                ...state,
                isViewVisible: false,
                savedContent : {},
                cloneType: "",
                viewTitle : "DIC_SURVEY_CLONE_TITLE_SURVEY_FROM_TEMPLATE",
                cloneStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                filteredItems : [],
                alertMessage : '',
                loader : false
            };

        default:
            return state;
    }

};

export default ReducerSurveyCloneHandler;