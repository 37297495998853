import {
    CLIENT_GET_ALL_CLIENTS,
    CLIENT_GET_ALL_CLIENTS_SUCCESS,
    CLIENT_SELECTED_CLIENT, E_LEARNING_REPORTS_PROGRESS_END, E_LEARNING_REPORTS_PROGRESS_START, E_LEARNING_REPORTS_SET_CHILD_CLIENTS,
    CLIENT_UPDATE_CLIENT_IN_LIST,
    CLIENT_GET_BY_STATUS
} from '../../constants/ActionTypes'


/**** Clients : To fetch all the clients info based on client ****/
export function setSelectedClient( params ) {
    return {
        type: CLIENT_SELECTED_CLIENT,
        payload : params
    };
}

/**** Clients : To fetch all the clients info based on client ****/
export function getClientsList( ) {
    return {
        type: CLIENT_GET_ALL_CLIENTS
    };
}

/**** Clients : Success : clients fetch ****/
export function getClientsListSuccess( params ) {
    return {
        type: CLIENT_GET_ALL_CLIENTS_SUCCESS,
        payload : params
    };
}

/**** Clients : To fetch all the clients info based on client ****/
export function setSelectedChildClient( params ) {
    return {
        type: E_LEARNING_REPORTS_SET_CHILD_CLIENTS,
        payload : params
    };
}

export function startELearningReportsProgress() {
    return {
        type: E_LEARNING_REPORTS_PROGRESS_START
    };
}

export function stopELearningReportsProgress() {
    return {
        type: E_LEARNING_REPORTS_PROGRESS_END
    };
}

/**** Clients : To update a client in the list ****/
export function updateClientInClientList( params ) {
    return {
        type: CLIENT_UPDATE_CLIENT_IN_LIST,
        payload : params
    };
}

/**** Clients : To get client list by its status ****/
export function getClientListByStatus( params ) {
    return {
        type: CLIENT_GET_BY_STATUS,
        payload : params
    };
};