import {
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS
} from "../../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import AppUtils from "../../../../util/util";

const initialData = {
    loader: false,
    alertMessageRawData: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    surveyTemplateRawDataDownloadStatus:HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyTemplateRawDataDownloadLink: "",
    surveyTemplateRawDataUUID: "",
    surveyTemplateRawDataUUIDCounter: 0
}

const ReducerSurveyTemplateReportsRawdata = (state = initialData, action) => {

    switch (action.type) {

        /**** survey template raw data : Download raw data uuid success*****/
        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS:{
            return {
                ...state,
                surveyTemplateRawDataUUID : action.payload.uuid,
                surveyTemplateRawDataUUIDCounter : 1
            }
        }

        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS:{
            return {
                ...state,
                surveyTemplateRawDataUUID : "",
                surveyTemplateRawDataUUIDCounter : 0,
                surveyTemplateRawDataDownloadStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        }

        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS:{
            if (action.payload.status === "SUCCESS") {

                let surveyTemplateRawDataDownloadLink = "";

                if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" ) {
                    surveyTemplateRawDataDownloadLink = AppUtils.getAppBaseUrl()  + action.payload.data.data;
                }

                return {
                    ...state,
                    surveyTemplateRawDataDownloadStatus : HTTP_REQUEST_STATUS.SUCCESS,
                    surveyTemplateRawDataUUID: "",
                    surveyTemplateRawDataUUIDCounter: 0,
                    surveyTemplateRawDataDownloadLink : surveyTemplateRawDataDownloadLink
                };
            } else if (action.payload.status === "IN_PROGRESS") {
                return {
                    ...state,
                    surveyTemplateRawDataUUID: action.payload.uuid,
                    surveyTemplateRawDataUUIDCounter: state.surveyTemplateRawDataUUIDCounter + 1,
                }
            } else if (action.payload.status === "NOT_FOUND") {
                return {
                    surveyTemplateRawDataUUID: "",
                    surveyTemplateRawDataUUIDCounter: 0,
                }
            }

            break;
        }

        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR:{
            return {
                ...state,
                surveyTemplateRawDataDownloadStatus : HTTP_REQUEST_STATUS.FAIL,
                surveyTemplateRawDataUUID : "",
                surveyTemplateRawDataUUIDCounter : 0,
                alertMessageRawData: action.payload.error
            }
        }

        /**** survey template raw data : Download rawdata in progress *****/
        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS:
            return {
                ...state,
                surveyTemplateRawDataDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyTemplateRawDataDownloadLink: ""
            };
            
        /**** survey template raw data : Download rawdata none *****/
        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE:
            return {
                ...state,
                surveyTemplateRawDataDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyTemplateRawDataDownloadLink: ""
            };           
        
       /**** survey template raw data : Download rawdata error *****/
        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR:
            return {
                ...state,
                surveyTemplateRawDataDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessageRawData: action.payload.error
            };

        /**** survey template raw data : Download raw data : success ****/
        case SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS:
            let surveyTemplateRawDataDownloadLink = "";

            if ( typeof action.payload != "undefined" && typeof action.payload.data != "undefined" ) {
                surveyTemplateRawDataDownloadLink = AppUtils.getAppBaseUrl()  + action.payload.data;
            }

            return {
                ...state,
                surveyTemplateRawDataDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                alertMessageRawData: action.payload.message,
                surveyTemplateRawDataDownloadLink:surveyTemplateRawDataDownloadLink    
            };

        default:
            return state;
    }
}


export default ReducerSurveyTemplateReportsRawdata;