import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import {SURVEY_HISTORY_GET_LIST_FAIL, SURVEY_HISTORY_GET_LIST_IN_PROGRESS, SURVEY_HISTORY_GET_LIST_NONE, SURVEY_HISTORY_GET_LIST_SUCCESS} from "../../../constants/actions/survey/survey/actioinTypesSurveyHistory";


const initialData = {
    surveyHistoryGetListStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyHistoryList: []
}


const ReducerSurveyViewHistory = ( state = initialData, action) => {

    switch (action.type) {
        case SURVEY_HISTORY_GET_LIST_IN_PROGRESS: {
            return{
                ...state,
                surveyHistoryGetListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        }

        case SURVEY_HISTORY_GET_LIST_NONE: {
            return{
                ...state,
                surveyHistoryGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
            }
        }

        case SURVEY_HISTORY_GET_LIST_SUCCESS: {
            return{
                ...state,
                surveyHistoryGetListStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyHistoryList: parseData(action.payload)
            }
        }

        case SURVEY_HISTORY_GET_LIST_FAIL: {
            return{
                ...state,
                surveyHistoryGetListStatus: HTTP_REQUEST_STATUS.FAIL,
            }
        }

        default:
            return state;

    }
}

function parseData(payload) {

    let result = [];

    try {
        if (payload?.results ?? "") {
            for (let i = 0; i < payload.results.length; i++) {

                let item = { ...payload.results[i] }

                switch (item.action) {
                    case 'CREATED':
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_CREATED";
                        break;
                    case "MODIFIED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_MODIFIED";
                        break;
                    case "OPENED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_OPENED";
                        break;
                    case "SCHEDULED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_SCHEDULED";
                        break;
                    case "CANCELLED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_CANCELLED";
                        break;
                    case "INVITED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_INVITED";
                        break;
                    case "INACTIVE":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_INACTIVE";
                        break;
                    case "CLOSED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_CLOSED";
                        break;
                    case "DELETED":
                        item.action = "DIC_KEY_SURVEY_LIST_ITEM_VIEW_HISTORY_ACTION_DELETED";
                        break;
                    default:
                        break;
                }
                result.push(item);
            }
        }
    } catch (error) {

    }
    return result;
}

export default ReducerSurveyViewHistory;