import {

    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE

} from "../../../../constants/ActionTypes";

/****
 Connect reports : To Download the responses
 ****/

export function connectRawdataDownloadResponses(params) {
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD,
        payload : params
    };
}

 /****
 Connect reports : To Download the responses success
 ****/

 export function connectRawdataDownloadResponsesSuccess(data){
        return {
            type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS,
            payload: data  
     };
}

/****
Connect reports : To Download the responses error
 ****/

export function connectRawdataDownloadResponsesError(params){
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR,
        payload: params
    }
}

/****
Connect reports : To Download the responses in progress
 ****/

export function connectRawdataDownloadResponsesInProgress(params){
     return{
         type:CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS,
         payload: params
     };
}

 /****
 Connect reports : To Download the responses None
 ****/

export function connectRawdataDownloadResponsesNone(){
    return{
        type:CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE
    };
}

/****
 connect reports : raw data download uuid
 ****/

export function connectRawDataDownloadUUID(params) {
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
        payload : params
    };
}

/****
 connect reports : raw data download uuid : success
 ****/

export function connectRawDataDownloadUUIDSuccess(data){
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
        payload: data
    };
}

/****
 connect reports : raw data download uuid : error
 ****/

export function connectRawDataDownloadUUIDError(params){
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
        payload: params
    }
}

/****
 connect reports : raw data download uuid :  None
 ****/
export function connectRawDataDownloadUUIDNone(){
    return{
        type:CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE
    };
}

/****
 connect reports : raw data download uuid status
 ****/

export function connectRawDataDownloadUUIDStatus(params) {
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS,
        payload : params
    };
}

/****
 connect reports : raw data download uuid status : success
 ****/

export function connectRawDataDownloadUUIDStatusSuccess(data){
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
        payload: data
    };
}

/****
 connect reports : raw data download uuid status : error
 ****/

export function connectRawDataDownloadUUIDStatusError(params){
    return {
        type: CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
        payload: params
    }
}

/****
 connect reports : raw data download uuid status :  None
 ****/
export function connectRawDataDownloadUUIDStatusNone(){
    return{
        type:CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE
    };
}


