import {
    CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP,
    CLIENT_PIC_GET_ALL_RECORDS,
    CLIENT_PIC_GET_ALL_RECORDS_ERROR,
    CLIENT_PIC_GET_ALL_RECORDS_IN_PROGRESS,
    CLIENT_PIC_GET_ALL_RECORDS_SUCCESS,
    CLIENT_PIC_HIDE_NEW_EDIT_POPUP,
    CLIENT_PIC_NEW_EDIT_SAVE,
    CLIENT_PIC_NEW_EDIT_SAVE_ERROR,
    CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS,
    CLIENT_PIC_NEW_EDIT_SAVE_NONE,
    CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS,
    CLIENT_PIC_UPDATE_STATUS,
    CLIENT_PIC_UPDATE_STATUS_ERROR,
    CLIENT_PIC_UPDATE_STATUS_IN_PROGRESS,
    CLIENT_PIC_UPDATE_STATUS_NONE,
    CLIENT_PIC_UPDATE_STATUS_SUCCESS
} from "../../../constants/ActionTypes";



/****
 Client PIC : to get all records
 ****/
export function getClientPICs() {
    return {
        type: CLIENT_PIC_GET_ALL_RECORDS
    };
}

/****
 Client PIC : to get all records in progress
 ****/

export function getClientPICsInProgress() {
    return {
        type: CLIENT_PIC_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Client PIC : to get all records :error
 ****/
export function getClientPICsError(params) {
    return {
        type: CLIENT_PIC_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}


/****
 Client PIC : to get all records : Success
 ****/
export function getClientPICSuccess(params) {
    return {
        type: CLIENT_PIC_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Client PIC : to display new edit popup
 ****/

export function displayNewEditClientPICPopup( params ) {
    return {
        type: CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
 Client PIC : to hide new edit popup
 ****/

export function hideNewEditClientPICPopup( params ) {
    return {
        type: CLIENT_PIC_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}


/**** Client PIC : save action ****/
export function saveClientPIC( params ) {
    return {
        type: CLIENT_PIC_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Client PIC : save success action ****/

export function saveClientPICSuccess(data) {
    return {
        type: CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Client PIC : save error action ****/
export function saveClientPICError(data) {
    return {
        type: CLIENT_PIC_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Client PIC : save in progress action ****/

export function saveClientPICProgress() {
    return {
        type: CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS,
    };
}

/**** Client PIC : save None ****/

export function saveClientPICNone() {
    return {
        type: CLIENT_PIC_NEW_EDIT_SAVE_NONE,
    };
}

/****
 Client PIC  : status update
 ****/

export function clientPICUpdateStatus(params) {
    return {
        type: CLIENT_PIC_UPDATE_STATUS,
        payload : params
    };
}

/****
 Client PIC  : status update in progress
 ****/
export function clientPICUpdateStatusInProgress() {
    return {
        type: CLIENT_PIC_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
  Client PIC  : Update status to None
 ****/
export function clientPICUpdateStatusNone() {
    return {
        type: CLIENT_PIC_UPDATE_STATUS_NONE
    };
}

/****
   Client PIC : status update : success
 ****/
export function clientPICUpdateStatusSuccess(params) {
    return {
        type: CLIENT_PIC_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}


/****
  Client PIC : status update : error
 ****/
export function clientPICUpdateStatusError(params) {
    return {
        type: CLIENT_PIC_UPDATE_STATUS_ERROR,
        payload : params
    };
}
