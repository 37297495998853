import {

    ACCOUNT_MANAGERS_SAVE_SUCCESS,
    ACCOUNT_MANAGERS_SAVE_IN_PROGRESS,
    ACCOUNT_MANAGERS_SAVE_SHOW_ERROR,
    ACCOUNT_MANAGERS_SAVE_HIDE_ERROR,
    ACCOUNT_MANAGERS_SAVE_NONE,
    ACCOUNT_MANAGERS_GET_LIST_SUCCESS,
    ACCOUNT_MANAGERS_EDIT,
    ACCOUNT_MANAGERS_DELETE,
    ACCOUNT_MANAGERS_DELETE_NONE,
    ACCOUNT_MANAGERS_SET_CURRENT_RECORD, ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS

}
    from "../../../constants/ActionTypes";

import {HTTP_REQUEST_STATUS} from "../../../constants/constants"
import IntlMessages from "../../../util/IntlMessages";
import React from "react";

const accountManagersTblHeader = [

    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_ADMIN_SETTINGS_ACCOUNT_MANAGERS_NAME"/>
    },
    {
        id: 'id',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_ADMIN_SETTINGS_ACCOUNT_MANAGERS_EMAIL"/>
    },
    {
        id: 'mailing_country',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_ADMIN_SETTINGS_ACCOUNT_MANAGERS_COUNTRY"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_ADMIN_SETTINGS_ACCOUNT_MANAGERS_ACTIONS"/>
    },
];


const initialSettings = {
    loader: false,
    isSuccessMessage: false,
    isErrorMessage: false,
    alertMessage: '',
    saveAlertMessage: '',
    deleteAlertMessage: '',
    accountManager: {},
    accountManagerRecordSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    accountManagerRecordEditStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    accountManagerRecordDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    accountManagerGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    accountManagersList: [],
    selectedAccountManager: {},
    accountManagersTblHeader: accountManagersTblHeader
}


const ReducerAccountManagers = (state = initialSettings, action) => {
    switch (action.type) {
        case ACCOUNT_MANAGERS_SET_CURRENT_RECORD:
            return {
                ...state,
                selectedAccountManager: action.payload
            };

        case ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS:{
            return {
                ...state,
                loader: true,
                alertMessage: "",
                isErrorMessage: false,
                accountManagerGetListStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        }

        case ACCOUNT_MANAGERS_GET_LIST_SUCCESS:

            return {
                ...state,
                accountManagersList: updateCountryLabels(action.payload.data),
                loader: false,
                alertMessage: action.payload.message,
                isErrorMessage: false,
                accountManagerGetListStatus : HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case ACCOUNT_MANAGERS_SAVE_SUCCESS:
            return {
                ...state,
                saveAlertMessage: action.payload.data.message,
                isSuccessMessage: true,
                accountManagerRecordSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                isErrorMessage: false
            };

        case ACCOUNT_MANAGERS_SAVE_NONE:
            return {
                ...state,
                alertMessage: '',
                accountManagerRecordSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isSuccessMessage: false,
                isErrorMessage: false,
                loader: false,
            };

        case ACCOUNT_MANAGERS_SAVE_IN_PROGRESS:
            return {
                ...state,
                accountManagerRecordSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case ACCOUNT_MANAGERS_SAVE_SHOW_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };
        case ACCOUNT_MANAGERS_SAVE_HIDE_ERROR:
            return {
                ...state,
                alertMessage: '',
                isErrorMessage: false

            };
        case ACCOUNT_MANAGERS_EDIT:
            return {
                ...state,
                isSuccessMessage: true,
                accountManagerRecordEditStatus: HTTP_REQUEST_STATUS.SUCCESS,
                AccountManagerID: action.payload,
                loader: false,
            };
        case ACCOUNT_MANAGERS_DELETE:
            let modifiedList = state.accountManagersList.map(list => {
                if (list.id === action.payload.id) {
                    return {...list, ...{is_active: action.payload.is_active}}
                }
                return list
            });

            return {
                ...state,
                deleteAlertMessage: <IntlMessages id={"DIC_ADMIN_SETTINGS_ACCOUNT_MANAGERS_STATUS"}/>,
                isSuccessMessage: true,
                accountManagerRecordDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                accountManagersList: modifiedList

            }
        case ACCOUNT_MANAGERS_DELETE_NONE: {
            return {
                ...state,
                alertMessage: '',
                accountManagerRecordDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isSuccessMessage: false,
                isErrorMessage: false,
                loader: false
            }
        }

        default:
            return state;

    }
};


function updateCountryLabels(list) {
    try {
        let temp = [];
        for (let i = 0; i < list.length; i++) {
            temp = [];
            for ( let j = 0 ; j < list[i].countries.length; j++ ) {
                temp.push( JSON.parse(list[i].countries[j].country.replace(/'/g, '"')).label);
            }
            list[i].country_label = temp.toString();
        }
    } catch (e) {

    }
    return list ;
}


export default ReducerAccountManagers;

