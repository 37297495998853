import IntlMessages from "../../../../util/IntlMessages";
import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import commonStyles from "../../../../util/commonStyle";


import {
    TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP, 
	TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP, 
	TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE,
    TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS, 
	TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE, 
	TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS, 
} from "../../../../constants/ActionTypes";

const translationSubmodulesTblHeader = [

    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_SL.NO"/>
    },

    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_NEW_EDIT_SUBMODULE_NAME"/>
    },
    {
        id: 'category',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_NEW_EDIT_SUBMODULE_MODULE_CODE"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_ACTIONS"/>
    },
];

const translationSubmodulesItems = [
    {
        'key': 'name',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_SUBMODULE_NEW_EDIT_SUBMODULE_NAME",
        'isMandatory': true,
    },
    {
        'key': 'module',
        'type': 'dropdown',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_SUBMODULE_NEW_EDIT_SUBMODULE_MODULE_CODE",
        'isMandatory': true,

    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'style': {
            paddingBottom: "10%",
            marginTop: "1%",
            marginLeft: "-10px"
        },
        'localisation':
            "DIC_SETTINGS_TRANSLATION_SUBMODULE_NEW_EDIT_IS_ACTIVE"
    },
];


const initialData = {
    tableColumns: translationSubmodulesTblHeader,
    translationSubmodulesdata: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    translationSubmodulesItems:translationSubmodulesItems,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedItem: null,
    translationSubmodulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationSubmodulesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationSubmodulesUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
};

const ReducerTranslationSubmodules = (state = initialData, action) => {
    switch (action.type) {
        case TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE:
            return {
                ...state,
                translationSubmodulesUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            };

        case TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                translationSubmodulesUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_UPDATE_MESSAGE"/>
            };


        case TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS:
            return {
                ...state,
                translationSubmodulesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                translationSubmodulesdata: action.payload
            };

        /**** Translation Submodule save error ****/

        case TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.message,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: false,
                selectedItem: null,
                translationSubmodulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false
            };

        /**** Translation Submodule save success ****/

        case TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANSLATION_SUBMODULE_SAVE_MESSAGE"/>,
                isErrorMessage: false,
                isSuccessMessage: true,
                isPopUpVisible: false,
                selectedItem: null,
                translationSubmodulesSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };

        /**** Translation Submodule http status to not started ****/

        case TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE:
            return {
                ...state,
                translationSubmodulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        /**** Translation Submodule http status to in progress ****/

        case TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                translationSubmodulesSaveStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        /**** to display translationSubmodules popup ****/

        case TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedItem : action.payload
            };

        /**** to hide translationSubmodules popup ****/
        
        case TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false
            };

        default:
            return state;

    }
}

export default ReducerTranslationSubmodules;