import {
    REPORT_FILTERS_RESET_STATE,
    REPORT_FILTERS_SET_CHANNEL,
    REPORT_FILTERS_SET_CLASSIFICATION,
    REPORT_FILTERS_SET_DATE_RANGE,
    REPORT_FILTERS_SET_END_DATE,
    REPORT_FILTERS_SET_SELECTED_CLIENT,
    REPORT_FILTERS_SET_START_DATE,
    REPORT_FILTERS_UPDATE_REFRESH_COUNTER,
    REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE,
    REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE,
    REPORT_FILTERS_ELEARNING_SET_DATE_RANGE,
    REPORT_FILTERS_CONNECT_SET_DATE_RANGE,
    REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE,
    REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE,
    REPORT_FILTERS_CONNECT_COUNTRY_FILTER,
    REPORT_FILTERS_CONNECT_COMPANY_STATUS,
    REPORT_FILTERS_ELEARNING_JOB_ROLE
} from "../../../constants/actions/reports/actionsReportFilters";

/****
 Report Filters : To set filter end date
 ****/
export function setReportFiltersEndDate(payload) {
    return {
        type: REPORT_FILTERS_SET_END_DATE,
        payload: payload
    };
}

/****
 Report Filters : To set filter start date
 ****/
export function setReportFiltersStartDate(payload) {
    return {
        type: REPORT_FILTERS_SET_START_DATE,
        payload: payload
    };
}

/****
 Report Filters : To set filter selected client
 ****/
export function setReportFiltersSelectedClient(payload) {
    return {
        type: REPORT_FILTERS_SET_SELECTED_CLIENT,
        payload: payload
    };
}

/****
 Report Filters : To set filter channel
 ****/
export function setReportFiltersChannel(payload) {
    return {
        type: REPORT_FILTERS_SET_CHANNEL,
        payload: payload
    };
}

/****
 Report Filters : To set filter classification
 ****/
export function setReportFiltersClassification(payload) {
    return {
        type: REPORT_FILTERS_SET_CLASSIFICATION,
        payload: payload
    };
}


/****
 Report Filters : To reset filters data
 ****/
export function resetReportFilters(payload) {
    return {
        type: REPORT_FILTERS_RESET_STATE,
        payload: payload
    };
}

/****
 Report Filters : To update refresh counter
 ****/
export function updateReportFilterUpdateCounter() {
    return {
        type: REPORT_FILTERS_UPDATE_REFRESH_COUNTER
    };
}

/****
 Report Filters : To set date range
 ****/
export function setReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_SET_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To update connect date range
 ****/
 export function updateConnectReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To update elearning date range
 ****/
 export function updateElearningReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To set elearning date range
 ****/
 export function setElearningReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_ELEARNING_SET_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To set connect date range
 ****/
 export function setConnectReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_CONNECT_SET_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To set company post date range
 ****/
 export function setCompanyPostReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To update companyPost date range
 ****/
 export function updateCompanyPostReportFilterDateRange(payload) {
    return {
        type: REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE,
        payload: payload
    };
}

/****
 Report Filters : To update country Filter
 ****/
export function updateCountryFilterSelectedValues(payload) {
    return {
        type: REPORT_FILTERS_CONNECT_COUNTRY_FILTER,
        payload: payload
    };
};

/*** 
 * Report Filters : To Update selected company status 
 **/
export function updateCompanyStatusSelectedValue(payload) {
    return {
        type: REPORT_FILTERS_CONNECT_COMPANY_STATUS,
        payload: payload
    };
};

/*** 
 * Report Filters : To Update selected job role status 
 **/
export function updateJobRoleSelectedValue(payload) {
    return {
        type: REPORT_FILTERS_ELEARNING_JOB_ROLE,
        payload: payload
    };
};