import { HTTP_REQUEST_STATUS } from 'constants/constants';
import {
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE,
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS,

     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS,

     RESET_REGISTRATION_GET_LIST_SUCCESS,
     RESET_REGISTRATION_GET_LIST_ERROR,
     RESET_REGISTRATION_GET_LIST_INPROGRESS,
     RESET_REGISTRATION_GET_LIST_NONE,

     UPDATE_RESET_REGISTRATION_SUMMARY_DATA,

     RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS,
     RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR,

     RESET_REGISTRATION_GLOBAL_STATE
} from '../../../constants/actions/wc/resetRegistration/actionTypesResetRegistration';
import AppUtils from '../../../util/util';
import { baseServerUrl } from '../../../util/keys';
import { APPLICATION_DATE_TIME_FORMAT, RESET_REGISTRATION_STATUS_MAPPING } from 'constants/config';

const initialData = {
     downloadTemplateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
     verifyUploadedFileStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
     resetListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
     downloadReportStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
     alertMessage: '',
     reportDownloadLink: '',
     uploadStartDate: '',
     uploadEndDate: '',
     status: '',
     resetContactList: [],
     uploadedDownloadLink: '',
     totalProcessed: 0,
     updatedCount: 0,
     failedCount: 0,
     totalRecordCount: 0
};

const ReducerResetRegistration = (state = initialData, action) => {

     switch (action.type) {

          case RESET_REGISTRATION_DOWNLOAD_TEMPLATE_INPROGRESS: {
               return {
                    ...state,
                    downloadTemplateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
               }
          }

          case RESET_REGISTRATION_DOWNLOAD_TEMPLATE_SUCCESS: {

               if (typeof action.payload !== 'undefined' &&
                    typeof action.payload.data !== 'undefined' &&
                    typeof action.payload.data.download_link !== 'undefined') {
                    AppUtils.downloadFile(baseServerUrl + action.payload.data.download_link);
               }
               return {
                    ...state,
                    downloadTemplateStatus: HTTP_REQUEST_STATUS.SUCCESS
               }
          }

          case RESET_REGISTRATION_DOWNLOAD_TEMPLATE_ERROR: {
               return {
                    ...state,
                    downloadTemplateStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: action.payload.message
               }
          }

          case RESET_REGISTRATION_DOWNLOAD_TEMPLATE_NONE: {
               return {
                    ...state,
                    downloadTemplateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
               }
          }

          case RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_INPROGRESS: {
               return {
                    ...state,
                    verifyUploadedFileStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
               }
          }

          case RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_SUCCESS: {
               return {
                    ...state,
                    verifyUploadedFileStatus: HTTP_REQUEST_STATUS.SUCCESS
               }
          }

          case RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_ERROR: {

               let alertMessage = '';

               if (typeof action.payload !== 'undefined' &&
                    typeof action.payload.response !== 'undefined' &&
                    typeof action.payload.response.data !== 'undefined') {
                    alertMessage = action.payload.response.data[0];
               }
               return {
                    ...state,
                    verifyUploadedFileStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: alertMessage
               }
          }

          case RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION_NONE: {
               return {
                    ...state,
                    verifyUploadedFileStatus: HTTP_REQUEST_STATUS.NOT_STARTED
               }
          }

          case RESET_REGISTRATION_GET_LIST_INPROGRESS: {
               return {
                    ...state,
                    resetListGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
               }
          }

          case RESET_REGISTRATION_GET_LIST_SUCCESS: {

               let tableData = [];
               let totalRecordCount = 0;

               if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {

                    tableData = parsedResponse(action.payload.data, action.payload.userTimeZone);

                    if (typeof action.payload.data.count !== 'undefined') {
                         totalRecordCount = action.payload.data.count
                    }
               }

               return {
                    ...state,
                    resetListGetStatus: HTTP_REQUEST_STATUS.SUCCESS,
                    resetContactList: tableData,
                    totalRecordCount: totalRecordCount
               }
          }

          case RESET_REGISTRATION_GET_LIST_ERROR: {
               return {
                    ...state,
                    resetListGetStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: action.payload.message
               }
          }

          case RESET_REGISTRATION_GET_LIST_NONE: {
               return {
                    ...state,
                    resetListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
               }
          }

          case UPDATE_RESET_REGISTRATION_SUMMARY_DATA: {
               let downloadLink = '';
               let startDate = '';
               let endDate = '';
               let status = '';
               let uploadedDownloadLink = '';
               let totalProcessed = 0;
               let updatedCount = 0;
               let failedCount = 0;


               if (typeof action.payload !== 'undefined') {

                    let data = action.payload;

                    downloadLink = typeof data['reportDownloadLink'] !== 'undefined' ? data['reportDownloadLink'] : '';
                    uploadedDownloadLink = typeof data['uploadedDownloadLink'] !== 'undefined' ? data['uploadedDownloadLink'] : '';

                    startDate = data['uploadStartDateTime'];
                    endDate = data['uploadEndDateTime'];
                    status = data['status'];
                    totalProcessed = data['totalProcessed'];
                    updatedCount = data['updated'];
                    failedCount = data['failed'];
               }

               return {
                    ...state,
                    reportDownloadLink: downloadLink,
                    uploadStartDate: startDate,
                    uploadEndDate: endDate,
                    status: status,
                    uploadedDownloadLink: uploadedDownloadLink,
                    totalProcessed: totalProcessed,
                    updatedCount: updatedCount,
                    failedCount: failedCount
               }
          }

          case RESET_REGISTRATION_DOWNLOAD_REPORT_SUCCESS: {
               if (typeof action.payload !== 'undefined' &&
                    typeof action.payload.data !== 'undefined' &&
                    typeof action.payload.data.download_link !== 'undefined') {
                    AppUtils.downloadFile(action.payload.data.download_link);
               }
               return {
                    ...state,
                    downloadReportStatus: HTTP_REQUEST_STATUS.SUCCESS
               }
          }

          case RESET_REGISTRATION_DOWNLOAD_REPORT_ERROR: {
               return {
                    ...state,
                    downloadReportStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: action.payload.message
               }
          }

          case RESET_REGISTRATION_GLOBAL_STATE: {
               return initialData
          }

          default:
               return state;
     }
};

/**To parse the list data from api */
function parsedResponse(data, userTimeZone) {

     let result = [];

     try {
          if (typeof data !== 'undefined') {
               if (typeof data.results !== 'undefined' && data.results.length > 0) {
                    for (let i = 0; i < data.results.length; i++) {

                         const item = data.results[i];
                         let resetContact = {};

                         /**Converting the UTC time based on user time zone */
                         let convertedDateTimeFormat = AppUtils.convertDateToGivenFormat(item['created_date'], APPLICATION_DATE_TIME_FORMAT, userTimeZone);

                         /**Created date is only used in all the places to show date */

                         resetContact['clientId'] = item['client_id'];
                         resetContact['fileUploaded'] = item['file_name'] ? (item['file_name'].length > 20 ? item['file_name'].substr(0, 19) + " ..." : item['file_name']) : "";
                         resetContact['fileId'] = item['id'];
                         resetContact['file_size'] = item['file_size'];
                         resetContact['uploded_by'] = item['created_by'];
                         resetContact['status'] = item['status'];
                         resetContact['statusLabel'] = getStatusLabel(item['status']);
                         resetContact['date_modified'] = convertedDateTimeFormat;
                         resetContact['summary'] = item['summary'];
                         resetContact['reportDownloadLink'] = item['report_download'];
                         resetContact['uploadedDownloadLink'] = item['uploaded_file_download'];
                         resetContact['uploadStartDateTime'] = convertedDateTimeFormat;
                         resetContact['uploadEndDateTime'] = typeof item['status'] !== 'undefined' ?
                              (item['status'] === RESET_REGISTRATION_STATUS_MAPPING.FAILED ? "-" : convertedDateTimeFormat) : "-";
                         resetContact['totalProcessed'] = getTotalProcessed(item['summary']);
                         resetContact['updated'] = getUpdatedCount(item['summary']);
                         resetContact['failed'] = getFailedCount(item['summary']);

                         result.push(resetContact);
                    }
               }
          }
     } catch (err) {
          console.error("parsedResponse error: ", err);
     }
     return result;
}

function getTotalProcessed(summary) {

     let totalProcessed = 0;

     try {
          if (typeof summary !== 'undefined' && summary !== null) {

               if (typeof summary['failed'] !== 'undefined' &&
                    typeof summary['updated'] !== 'undefined') {

                    return Number(summary['failed']) + Number(summary['updated']);
               }
          }
     } catch (err) {
          console.error("getTotalProcessed error: ", err);
     }
     return totalProcessed;
}

function getStatusLabel(status) {
     try {
          if (typeof status !== 'undefined') {
               switch (status) {
                    case RESET_REGISTRATION_STATUS_MAPPING.COMPLETED:
                         return 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_COMPLETED';

                    case RESET_REGISTRATION_STATUS_MAPPING.PARTIAL:
                         return 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_PARTIAL';

                    case RESET_REGISTRATION_STATUS_MAPPING.UPLOADING:
                         return 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_UPLOADING';

                    case RESET_REGISTRATION_STATUS_MAPPING.FAILED:
                         return 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_FAILED';

                    default:
                         return 'DIC_KEY_WC_RESET_REGISTRATION_SUMMARY_DIALOG_EMPTY_LABEL';
               }
          }
     } catch (err) {
          console.error("getStatusLabel error: ", err);
     }
}

function getUpdatedCount(summary) {

     let label = 0;

     try {
          if (typeof summary !== 'undefined' && summary !== null) {

               if (typeof summary['updated'] !== 'undefined') {

                    return summary['updated'];
               }
          }
     } catch (err) {
          console.error("getUpdatedCount error: ", err);
     }
     return label;
}

function getFailedCount(summary) {

     let label = 0;

     try {
          if (typeof summary !== 'undefined' && summary !== null) {

               if (typeof summary['failed'] !== 'undefined') {

                    return summary['failed'];
               }
          }
     } catch (err) {
          console.error("getFailedCount error: ", err);
     }
     return label;
}

export default ReducerResetRegistration;