import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";

import axios from "axios";

import {
    USER_SETTINGS_UPDATE_PREFERRED_CLIENT,
    USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE,
    USER_SETTINGS_UPDATE_TIME_ZONE,
    USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR,
    USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE
} from "../../../constants/actions/user/actionUserSettings";
import {
    updateUserPreferredClientError,
    updateUserPreferredClientSuccess,
    updateUserPreferredLanguageError,
    updateUserPreferredLanguageSuccess,
    updateUserTimeZoneError,
    updateUserTimeZoneSuccess,
    updateUserPreferredThemeColorError,
    updateUserPreferredThemeColorSuccess,
    updateUserProfileImageError,
    updateUserProfileImagerSuccess,
} from "../../../actions/adminSettings/user/settings/actionsUserSettings";

import {
    URL_USER_SETTINGS_UPDATE_PREFERRED_CLIENT,
    URL_USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE,
    URL_USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR,
    URL_USER_SETTINGS_UPDATE_PROFILE_IMAGE,
    URL_USER_SETTINGS_UPDATE_TIME_ZONE
} from "../../../constants/Urls";
import {baseUrl} from "../../../util/keys";
import AppUtils from "../../../util/util";
import { refreshToken } from "sagas/Auth"


/************ methods : http *******************/

/** http : user preferred language**/
const httpUpdatePreferredLanguage = async (data) =>
    await axios.post(baseUrl + URL_USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user preferred language**/
const httpUpdateTimeZone = async (data) =>
    await axios.patch(baseUrl + URL_USER_SETTINGS_UPDATE_TIME_ZONE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user preferred client **/
const httpUpdatePreferredClient = async (data) =>
    await axios.post(baseUrl + URL_USER_SETTINGS_UPDATE_PREFERRED_CLIENT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : user theme colour **/
const httpUpdateUserThemeColour = async (data) =>
    await axios.post(baseUrl + URL_USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : user profile image **/
const httpUpdateUserProfileImage = async (data) =>
    await axios.post(baseUrl + URL_USER_SETTINGS_UPDATE_PROFILE_IMAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/************ methods : http response handler *******************/

/** http : user preferred language : http response handler **/
function* handleHttpUpdateUserPreferredLanguage({payload}) {
    try {

        let result = yield call(httpUpdatePreferredLanguage, payload);

        if (result.message) {
            yield put(updateUserPreferredLanguageError(result.response));
        } else {
            refreshToken()
            yield put(updateUserPreferredLanguageSuccess(result));
        }
    } catch (error) {
        yield put(updateUserPreferredLanguageError(error));
    }
}

/** http : user preferred language : http response handler **/
function* handleHttpUpdateUserTimeZone({payload}) {
    try {
        let result = yield call(httpUpdateTimeZone, payload);

        if (result.message) {
            yield put(updateUserTimeZoneError(result.response));

        } else {
            refreshToken()
            yield put(updateUserTimeZoneSuccess(result));
        }
    } catch (error) {
        yield put(updateUserTimeZoneError(error));
    }
}


/** http : user preferred client : http response handler **/
function* handleHttpUpdateUserPreferredClient({payload}) {
    try {

        let result = yield call(httpUpdatePreferredClient, payload);

        if (result.message) {
            yield put(updateUserPreferredClientError(result.response));
        } else {
            refreshToken()
            yield put(updateUserPreferredClientSuccess(result));
        }
    } catch (error) {
        yield put(updateUserPreferredClientError(error));
    }
}

/** http : user preferred theme colour : http response handler **/
function* handleHttpUpdateUserPreferredThemeColour({payload}) {
    try {

        let result = yield call(httpUpdateUserThemeColour, payload);

        if (result.message) {
            yield put(updateUserPreferredThemeColorError(result.response));
        } else {
            refreshToken()
            yield put(updateUserPreferredThemeColorSuccess(result));
        }
    } catch (error) {
        yield put(updateUserPreferredThemeColorError(error));
    }
}

/** http : user profile image : http response handler **/
function* handleHttpUpdateUserProfileImage({payload}) {
    try {

        let result = yield call(httpUpdateUserProfileImage, payload);

        if (result.message) {
            yield put(updateUserProfileImageError(result.response));
        } else {
            refreshToken()
            yield put(updateUserProfileImagerSuccess(result));
        }
    } catch (error) {
        yield put(updateUserProfileImageError(error));
    }
}

/************ methods : export access *******************/

/** To update user preferred language **/
export function* updateUserPreferredLanguage() {
    yield takeEvery(USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE, handleHttpUpdateUserPreferredLanguage);
}

/** To update user preferred language **/
export function* updateUserTimeZone() {
    yield takeEvery(USER_SETTINGS_UPDATE_TIME_ZONE, handleHttpUpdateUserTimeZone);
}

/** To update user preferred client **/
export function* updateUserPreferredClient() {
    yield takeEvery(USER_SETTINGS_UPDATE_PREFERRED_CLIENT, handleHttpUpdateUserPreferredClient);
}

/** To update user preferred theme colour **/
export function* updateUserPreferredThemeColour() {
    yield takeEvery(USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR, handleHttpUpdateUserPreferredThemeColour);
}

/** To update user preferred theme colour **/
export function* updateUserProfileImage() {
    yield takeEvery(USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE, handleHttpUpdateUserProfileImage);
}


export default function* rootSaga() {
    yield all([
        fork(updateUserPreferredLanguage),
        fork(updateUserPreferredClient),
        fork(updateUserPreferredThemeColour),
        fork(updateUserProfileImage),
        fork(updateUserTimeZone)
    ]);
}
