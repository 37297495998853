

export const HTTP_REQUEST_STATUS = {
    "NOT_STARTED" : "NOT_STARTED",
    "IN_PROGRESS" : "IN_PROGRESS",
    "SUCCESS" : "SUCCESS",
    "FAIL" : "FAIL",
};

export const HTTP_REQUEST_STATUS_CODES = {
    OK : 200,
    CREATED : 201,
    SERVER_STOPPED: 418
};

export const NEW_PAYSLIP_ACTION_TYPES = {
    "CANCEL_NEW_PAYSLIP": "CANCEL_NEW_PAYSLIP",
    "OK_NEW_PAYSLIP": "OK_NEW_PAYSLIP",
    "SAVE_NEW_PAYSLIP": "SAVE_NEW_PAYSLIP",
    "PREVIEW_NEW_PAYSLIP": "PREVIEW_NEW_PAYSLIP",
    "TITLE_NEW_PAYSLIP": "TITLE_NEW_PAYSLIP",
    "DATE_TIME_NEW_PAYSLIP": "DATE_TIME_NEW_PAYSLIP",
    "DESCRIPTION_NEW_PAYSLIP": "DESCRIPTION_NEW_PAYSLIP",
    "MESSAGE_NEW_PAYSLIP": "MESSAGE_NEW_PAYSLIP",
    "CHANNEL_NEW_PAYSLIP": "CHANNEL_NEW_PAYSLIP",
    "DELETE_PAYSLIP": "DELETE_PAYSLIP",
    "UPLOAD_SAVE_NEW_PAYSLIP":"UPLOAD_SAVE_NEW_PAYSLIP",
    "UPLOAD_CANCEL_NEW_PAYSLIP":"UPLOAD_CANCEL_NEW_PAYSLIP",
    "DOWNLOAD_TEMPLATE_NEW_PAYSLIP":"DOWNLOAD_TEMPLATE_NEW_PAYSLIP"
};

export const MOBILE_VIEW = 768;
