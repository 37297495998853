import { CLIENT_MODULE_KEYS } from "constants/modules/moduleConfig";
import { WOVO_USER_ROLES } from "constants/userRoles";

export const isModuleHasAccess = (
  currentRole,
  module,
  selectedClient,
  moduleConfig
) => {
  let result = false;
  try {
    if (currentRole !== "") {
      if (currentRole === WOVO_USER_ROLES["SUPER_ADMIN"]) {
        result = true;
      } else {
        if (
          typeof selectedClient["client_key"] != "undefined" &&
          typeof moduleConfig[selectedClient["client_key"]] != "undefined" &&
          moduleConfig[selectedClient["client_key"]].includes(module)
        ) {
          result = true;
        }
      }
    }
  } catch (ex) {
    console.error("isModuleHasAccess : error : ", ex);
  }
  return result;
};

export const MENU_ITEMS = {
  VIEW_DASHBOARD: {
    name: "dashboard",
    url: "/app/dashboard",
    wovoIcon: "wovo_icon wovo_icon_dashboard",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_DASHBOARD",
  },

  MENU_CONNECT: {
    name: "connect",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_connect",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_CONNECT",
  },
  MENU_CONNECT_REPORT: {
    name: "connect_report",
    url: "/app/connect/reports/",
    dic_key: "DIC_SIDE_BAR_CONNECT_REPORTS",
  },
  MENU_PAY_SLIP: {
    name: "pay_slip",
    url: "/app/payslip/list/",
    dic_key: "DIC_SIDE_BAR_PAY_SLIP",
    tooltipItems: {
      name: "new_pay_slip",
      url: "/app/payslip/view/",
      dic_key: "DIC_SIDE_BAR_NEW_PAY_SLIP",
    },
  },

  MENU_SURVEY: {
    name: "survey",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_survey",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_SURVEY",
    is_admin: true,
  },
  MENU_SURVEY_HOME: {
    name: "survey_home",
    url: "/app/survey/home/",
    dic_key: "DIC_SIDE_BAR_SURVEY_HOME",
    is_admin: false,
  },
  MENU_SURVEY_QUESTIONNAIRE: {
    name: "survey_templates",
    url: "/app/survey/templates/",
    dic_key: "DIC_SIDE_BAR_SURVEY_TEMPLATES",
    is_admin: true,
  },
  MENU_SURVEY_QUESTION_TYPES: {
    name: "survey_question_types",
    url: "/app/survey/questiontypes/",
    dic_key: "DIC_SIDE_BAR_SURVEY_QUESTION_TYPES",
    is_admin: true,
  },
  MENU_SURVEY_QUESTION_BANK: {
    name: "survey_question_bank",
    url: "/app/survey/questionbank/",
    dic_key: "DIC_SIDE_BAR_SURVEY_QUESTION_BANK",
    is_admin: true,
  },
  MENU_SURVEY_CATEGORIES: {
    name: "survey_reporting_categories",
    url: "/app/survey/reporting/categories/",
    dic_key: "DIC_SIDE_BAR_SURVEY_REPORTING_CATEGORIES",
    is_admin: true,
  },

  MENU_E_LEARNING: {
    name: "e_learning",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_elearning",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_E_LEARNING",
  },
  MENU_E_LEARNING_REPORTS: {
    name: "e_learning_report",
    url: "/app/elearning/reports/",
    dic_key: "DIC_SIDE_BAR_E_LEARNING_REPORTS",
  },

  MENU_COMPANY_POST: {
    name: "company_post",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_post_raw_data",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_COMPANY_POST",
  },
  MENU_COMPANY_POST_RAW_DATA_REPORT: {
    name: "company_post_raw_data_report",
    url: "/app/companypost/reports/",
    dic_key: "DIC_SIDE_BAR_COMPANY_POST_RAW_DATA",
  },

  MY_WOVO: {
    name: "my_wovo",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_participants",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_MY_WOVO",
    is_admin: true,
  },
  MY_WOVO_ACCOUNT_INFO: {
    name: "account_info",
    url: "/next/app/mywovo/account-info",
    dic_key: "DIC_SIDE_BAR_WOVO_ACCOUNT_INFO",
    is_admin: false,
    isNextRoute: true,
  },
  MY_WOVO_ACCOUNT_INFO_BDM: {
    name: "account_info",
    url: "/next/app/mywovo/account-info/accountDetails?clientId={0}",
    dic_key: "DIC_SIDE_BAR_WOVO_ACCOUNT_INFO",
    is_admin: false,
    navigate_to_old_app: false,
    isNextRoute: true,
  },
  MY_WOVO_CLIENTS: {
    name: "clients",
    url: "/app/company/index/",
    dic_key: "DIC_SIDE_BAR_WOVO_CLIENTS",
    is_admin: false,
    tooltipItems: {
      name: "new_clients",
      url: "/app/client/view/",
      dic_key: "DIC_SIDE_BAR_WOVO_CLIENTS_NEW_COMPANY",
      is_admin: false,
    },
  },
  MY_WOVO_WC: {
    name: "worker_contacts",
    url: "/app/worker_contacts/list/",
    dic_key: "DIC_SIDE_BAR_WORKER_CONTACTS",
    is_admin: false,
  },

  WOVO_ADMIN: {
    name: "admin",
    url: "",
    wovoIcon: "wovo_icon wovo_icon_settings",
    dicClass: "wovo_span_label",
    dic_key: "DIC_SIDE_BAR_SETTINGS",
    is_admin: true,
  },
  WOVO_ADMIN_TRANSLATIONS: {
    name: "translations",
    url: "/app/admin/settings/localisation/",
    dic_key: "DIC_SIDE_BAR_SETTINGS_TRANSLATIONS",
    is_admin: true,
  },
  WOVO_ADMIN_MANAGE_TRANSLATIONS: {
    name: "manage-translations",
    url: "/next/app/admin/settings/manage-translations",
    dic_key: "DIC_SIDE_BAR_SETTINGS_MANAGE_TRANSLATIONS",
    is_admin: true,
    isNextRoute: true,
    navigate_to_old_app: false,
  },
  WOVO_ADMIN_CLIENT_MODULES: {
    name: "clientModuleConfig",
    url: "/app/admin/settings/clientModuleConfig/",
    dic_key: "DIC_KEY_ADMIN_CLIENT_MODULES",
    is_admin: true,
  },
  WOVO_ADMIN_INDUSTRY_TYPES: {
    name: "industryTypes",
    url: "/app/admin/settings/industryTypes/",
    dic_key: "DIC_SIDE_BAR_SETTINGS_INDUSTRY_TYPES",
    is_admin: true,
  },
  WOVO_ADMIN_ACCOUNT_MANAGERS: {
    name: "managers",
    url: "/app/accountManagers/index/",
    dic_key: "DIC_SIDE_BAR_SETTINGS_ACCOUNT_MANAGERS",
    is_admin: true,
  },

  WOVO_ADMIN_ACTIVATE_SURVEY: {
    name: "activate_survey",
    url: "/app/admin/activate_survey/",
    dic_key: "DIC_SIDE_BAR_ADMIN_ACTIVATE_SURVEY",
  },
  WOVO_ADMIN_SURVEY_DUPLICATE_RESPONSES: {
    name: "survey_duplicate_responses",
    url: "/app/admin/survey_duplicate_responses/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SURVEY_DUPLICATE_RESPONSES",
  },
  WOVO_ADMIN_SURVEY_ALL_RAW_DATA_RESPONSES: {
    name: "survey_all_raw_data_responses",
    url: "/app/admin/survey_all_raw_data_report/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SURVEY_RAW_DATA_REPORT",
  },
  WOVO_ADMIN_SURVEY_RESPONSES_UPDATE: {
    name: "survey_responses_update",
    url: "/app/admin/survey_responses_update/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SURVEY_RESPONSES_UPDATE",
  },
  WOVO_ADMIN_ADMIN_CLIENT_MODULES: {
    name: "admin_client_modules",
    url: "/app/admin/client_modules/",
    dic_key: "DIC_SIDE_BAR_ADMIN_CLIENT_MODULES",
  },
  WOVO_ADMIN_ADMIN_INCREASE_SURVEY_RESPONSES: {
    name: "admin_increase_survey_responses",
    url: "/app/admin/increase_survey_responses/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SURVEY_INCREASE_RESPONSES",
  },
  WOVO_ADMIN_RESET_SURVEY_CACHED_DATA: {
    name: "admin_reset_survey_cached_data",
    url: "/app/admin/reset_survey_cached_data/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SURVEY_RESET_CACHED_DATA",
  },
  WOVO_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID: {
    name: "admin_save_survey_response_by_request_id",
    url: "/app/admin/save_survey_response_by_request_id/",
    dic_key: "DIC_SIDE_BAR_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID",
  },
  WOVO_ADMIN_WORKER_CONTACTS: {
    name: "admin_worker_contacts",
    url: "/app/admin/worker_contacts/",
    dic_key: "DIC_SIDE_BAR_ADMIN_WORKER_CONTACTS",
  },
  SYNC_CLIENTS: {
    name: "clients",
    url: "/app/wovoimport/clients/",
    dic_key: "DIC_SIDE_BAR_SYNC_CLIENTS_SYNC",
  },

  TOOLTIP_MENU_CLIENTS: {
    name: "new_clients",
    url: "/app/client/view/",
    dic_key: "DIC_SIDE_BAR_WOVO_CLIENTS_NEW_COMPANY",
    is_admin: false,
  },
};

export const MENU_CONFIG = {
  ADMIN: {
    VIEW_DASHBOARD: {},
    MENU_CONNECT: {
      MENU_PAY_SLIP: [],
      MENU_CONNECT_REPORT: [],
    },
    MENU_SURVEY: {
      MENU_SURVEY_HOME: [],
      MENU_SURVEY_QUESTIONNAIRE: [],
      MENU_SURVEY_QUESTION_TYPES: [],
      MENU_SURVEY_QUESTION_BANK: [],
      MENU_SURVEY_CATEGORIES: [],
    },
    MENU_E_LEARNING: {
      MENU_E_LEARNING_REPORTS: [],
    },
    MENU_COMPANY_POST: {
      MENU_COMPANY_POST_RAW_DATA_REPORT: [],
    },
    MY_WOVO: {
      MY_WOVO_WC: [],
      MY_WOVO_ACCOUNT_INFO: [],
    },
    WOVO_ADMIN: {
      WOVO_ADMIN_TRANSLATIONS: [],
      WOVO_ADMIN_MANAGE_TRANSLATIONS: [],
      WOVO_ADMIN_CLIENT_MODULES: [],
      SYNC_CLIENTS: [],
    },
  },
  DM: {
    VIEW_DASHBOARD: {},
    MENU_CONNECT: {
      MENU_PAY_SLIP: [],
      MENU_CONNECT_REPORT: [],
    },
    MENU_SURVEY: {
      MENU_SURVEY_HOME: [],
      MENU_SURVEY_QUESTIONNAIRE: [],
      MENU_SURVEY_CATEGORIES: [],
    },
    MENU_E_LEARNING: {
      MENU_E_LEARNING_REPORTS: [],
    },
    MENU_COMPANY_POST: {
      MENU_COMPANY_POST_RAW_DATA_REPORT: [],
    },
    MY_WOVO: {
      /*"MY_WOVO_CLIENTS": [],*/
      MY_WOVO_WC: [],
      //MY_WOVO_ACCOUNT_INFO_BDM: [],
    },
  },
  DA: {
    VIEW_DASHBOARD: {},
    MENU_CONNECT: {
      MENU_CONNECT_REPORT: [],
    },
    MENU_SURVEY: {
      MENU_SURVEY_HOME: [],
      MENU_SURVEY_QUESTIONNAIRE: [],
      MENU_SURVEY_CATEGORIES: [],
    },
    MENU_E_LEARNING: {
      MENU_E_LEARNING_REPORTS: [],
    },
    MENU_COMPANY_POST: {
      MENU_COMPANY_POST_RAW_DATA_REPORT: [],
    },
    MY_WOVO: {
      //MY_WOVO_ACCOUNT_INFO_BDM: [],
      MY_WOVO_WC: [],
    },
  },
  BDM: {
    VIEW_DASHBOARD: {},
    MENU_CONNECT: {
      MENU_CONNECT_REPORT: [],
    },
    MENU_SURVEY: {
      MENU_SURVEY_HOME: [],
      MENU_SURVEY_QUESTIONNAIRE: [],
      MENU_SURVEY_CATEGORIES: [],
    },
    MENU_E_LEARNING: {
      MENU_E_LEARNING_REPORTS: [],
    },
    MENU_COMPANY_POST: {
      MENU_COMPANY_POST_RAW_DATA_REPORT: [],
    },
    MY_WOVO: {
      //MY_WOVO_ACCOUNT_INFO_BDM: [],
      MY_WOVO_WC: [],
    },
  },
  SUPER_ADMIN: {
    VIEW_DASHBOARD: {},
    MENU_CONNECT: {
      MENU_PAY_SLIP: [],
      MENU_CONNECT_REPORT: [],
    },
    MENU_SURVEY: {
      MENU_SURVEY_HOME: [],
      MENU_SURVEY_QUESTIONNAIRE: [],
      MENU_SURVEY_QUESTION_TYPES: [],
      MENU_SURVEY_QUESTION_BANK: [],
      MENU_SURVEY_CATEGORIES: [],
    },
    MENU_E_LEARNING: {
      MENU_E_LEARNING_REPORTS: [],
    },
    MENU_COMPANY_POST: {
      MENU_COMPANY_POST_RAW_DATA_REPORT: [],
    },
    MY_WOVO: {
      MY_WOVO_ACCOUNT_INFO: [],
      MY_WOVO_WC: [],
    },
    WOVO_ADMIN: {
      WOVO_ADMIN_TRANSLATIONS: [],
      WOVO_ADMIN_MANAGE_TRANSLATIONS: [],
      WOVO_ADMIN_CLIENT_MODULES: [],
      WOVO_ADMIN_ACTIVATE_SURVEY: [],
      WOVO_ADMIN_SURVEY_DUPLICATE_RESPONSES: [],
      WOVO_ADMIN_SURVEY_ALL_RAW_DATA_RESPONSES: [],
      WOVO_ADMIN_SURVEY_RESPONSES_UPDATE: [],
      WOVO_ADMIN_ADMIN_INCREASE_SURVEY_RESPONSES: [],
      WOVO_ADMIN_RESET_SURVEY_CACHED_DATA: [],
      WOVO_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID: [],
      WOVO_ADMIN_WORKER_CONTACTS: [],
      SYNC_CLIENTS: [],
    },
  },
};

export const getModuleKeyBasedOnKey = (key) => {
  let moduleKey = "";

  try {
    switch (key) {
      case "MENU_CONNECT": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT"];
        break;
      }

      case "MENU_SURVEY": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_SURVEY"];
        break;
      }

      case "MENU_E_LEARNING": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING"];
        break;
      }

      case "MENU_COMPANY_POST": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST"];
        break;
      }

      case "E_LEARNING_REPORT": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING_REPORTS"];
        break;
      }

      case "COMPANY_POST_RAW_DATA_REPORT": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST_REPORTS"];
        break;
      }

      case "COMPANY_POST_RAW_DATA_DOWNLOAD": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST_RAW_DATA"];
        break;
      }

      case "MENU_CONNECT_REPORT": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT_REPORTS"];
        break;
      }

      case "CONNECT_RAW_DATA_DOWNLOAD": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT_REPORTS_RAW_DATA"];
        break;
      }

      case "E_LEARNING_REPORTS_RAW_DATA": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING_REPORTS_RAW_DATA"];
        break;
      }

      case "MY_WOVO": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_WORKER_CONTACTS"];
        break;
      }

      case "MENU_PAY_SLIP": {
        moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_PAYSLIP"];
        break;
      }

      default: {
        break;
      }
    }
  } catch (ex) {
    console.error("getModuleKeyBasedOnKey : error : ", ex);
  }

  return moduleKey;
};

export const prepareUserMenu = (user, isSuperAdmin) => {
  let result = [];
  try {
    let menuRole = "DM";

    switch (user["role"]) {
      case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR:
      case WOVO_USER_ROLES.BRAND_DASHBOARD_TCP:
      case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1:
      case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER: {
        menuRole = "BDM";
        break;
      }

      case WOVO_USER_ROLES.SUPER_ADMIN: {
        menuRole = "ADMIN";
        break;
      }

      case WOVO_USER_ROLES.DASHBOARD_AGENT: {
        menuRole = "DA";
        break;
      }

      default: {
        break;
      }
    }

    if (isSuperAdmin) {
      menuRole = "SUPER_ADMIN";
    }

    let currentConfig = MENU_CONFIG[menuRole];
    let rootElement;
    let childElements;
    let childItems;

    const moduleConfig = user.moduleConfig;

    for (let rootItem in currentConfig) {
      childItems = currentConfig[rootItem];

      childElements = [];

      let moduleKey = getModuleKeyBasedOnKey(rootItem);
      let isValid = true;
      if (moduleKey !== "") {
        isValid = true;
      }

      if (!isValid) {
        continue;
      }

      for (let childItem in childItems) {
        let moduleKey = getModuleKeyBasedOnKey(childItem);
        let isValid = true;
        if (moduleKey !== "") {
          isValid = true;
        }

        if (!isValid) {
          continue;
        }

        let currentItem = JSON.parse(JSON.stringify(MENU_ITEMS[childItem]));
        let overflowItems = childItems[childItem];

        for (let i = 0; i < overflowItems.length; i++) {
          currentItem[overflowItems[i]] = JSON.parse(
            JSON.stringify(overflowItems[i])
          );
        }

        childElements.push(currentItem);
      }

      rootElement = JSON.parse(JSON.stringify(MENU_ITEMS[rootItem]));

      if (childElements.length > 0) {
        rootElement["items"] = childElements;
      }

      result.push(rootElement);
    }
  } catch (ex) {
    console.error(ex);
  }

  return result;
};
