import { SURVEY_STATUSES_VALUE, SURVEY_INVITE_FILTERS, SURVEY_INVITE_COMMUNICATION_CHANNELS } from "constants/config";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import React from "react";
import dayjs from "dayjs";
import { 
    SURVEY_INVITE_OUTREACH_NONE,
    SURVEY_INVITE_OUTREACH_INPROGRESS,
    SURVEY_INVITE_OUTREACH_SUCCESS,
    SURVEY_INVITE_OUTREACH_FAIL,

    SURVEY_INVITE_CREATE_NONE,
    SURVEY_INVITE_CREATE_INPROGRESS,
    SURVEY_INVITE_CREATE_SUCCESS,
    SURVEY_INVITE_CREATE_FAIL,

    SURVEY_INVITE_GET_LIST_NONE,
    SURVEY_INVITE_GET_LIST_INPROGRESS,
    SURVEY_INVITE_GET_LIST_SUCCESS,
    SURVEY_INVITE_GET_LIST_FAIL,

    SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS,
    SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL,

    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS,
    SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL,
    SURVEY_INVITE_RESET_SELECTED_INPUTS,
    SURVEY_INVITE_SET_SELECTED_INPUTS,

    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS,
    SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL,

    SURVEY_INVITE_SEND_REMINDER,
    SURVEY_INVITE_SEND_REMINDER_NONE,
    SURVEY_INVITE_SEND_REMINDER_INPROGRESS,
    SURVEY_INVITE_SEND_REMINDER_SUCCESS,
    SURVEY_INVITE_SEND_REMINDER_FAIL,

    SURVEY_INVITE_CREATE_STATUS_RESET,
    SURVEY_INVITE_OUTREACH_STATUS_RESET,
    
    SURVEY_INVITE_INVITED_MEDIA_DETAILS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS,
    SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE
 } from "constants/actions/survey/survey/actionTypesSurveyInvite";
import UIUtils from "util/ui_utils";
import IntlMessages from "util/IntlMessages";

const surveyStatusFilter = [
    {
        key: SURVEY_INVITE_FILTERS.STATUS,
        type: UIUtils.UI_COMPONENT_TYPES.DROPDOWN   
    }
]

const filterValueSurveyStatusOpen = [
    {
        value: SURVEY_STATUSES_VALUE.ACTIVE,
        label: "DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_STATUS_OPEN"
    },
    {
        value: SURVEY_STATUSES_VALUE.INACTIVE,
        label: "DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_STATUS_CLOSED"
    }
]

const filterValueSurveyStatusDraft = [
    {
        value: SURVEY_STATUSES_VALUE.DRAFT,
        label: "DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_STATUS_DRAFT"
    },
    {
        value: SURVEY_STATUSES_VALUE.ACTIVE,
        label: "DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_STATUS_OPEN"
    }
]

const initialData = {
    filterValueSurveyStatusOpen: filterValueSurveyStatusOpen,
    surveyStatusFilter: surveyStatusFilter,
    filterValueSurveyStatusDraft: filterValueSurveyStatusDraft,
    selectedSurveyStatusOpen: filterValueSurveyStatusOpen[0]['value'],
    selectedSurveyStatusDraft: filterValueSurveyStatusDraft[0]['value'],

    invitePageSendDateAndTime: '',
    invitePageSendTimeImmediateStatus: false,
    invitePageMessage: '',
    invitePageChannel: SURVEY_INVITE_COMMUNICATION_CHANNELS['App'],
    invitePageTargetAudience: {},

    surveyInviteCreateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteGetSpecificInviteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteUpdateSpecificInviteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteInvitedMediaDetailStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteInvitedMediaDetailData: 0,
    surveyInviteCreateFailureMessage: '',
    surveyOutreachFailureMessage: '',
    surveyInviteUpdateSurveyPeriodStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteSendReminderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInviteGetSpecificDetails: {},
    surveyInviteList: [],
    surveyInviteOutreachResponse: {},
    surveyInviteSendReminderKey: ""
}

const ReducerSurveyInvitePage = ( state = initialData, action) => {

    switch(action.type){
        // =============================Create Survey Invite=========================//
        case SURVEY_INVITE_CREATE_INPROGRESS: { 
            return{
                ...state,
                surveyInviteCreateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case SURVEY_INVITE_CREATE_SUCCESS: {
            let alertMessage = <IntlMessages id="DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_STATUS_INVITE_CREATED_MESSAGE" />
            if (action.payload) {
                if (action.payload.message) {
                    alertMessage = action.payload.message;
                }
            }
            return{
                ...state,
                surveyInviteCreateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage,
                invitePageMessage: initialData.invitePageMessage,
                invitePageChannel: initialData.invitePageChannel,
                invitePageSendDateAndTime: initialData.invitePageSendDateAndTime,
                invitePageSendTimeImmediateStatus: initialData.invitePageSendTimeImmediateStatus
            }
        }

        case SURVEY_INVITE_CREATE_NONE: {
            return {
                ...state,
                surveyInviteCreateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_CREATE_FAIL: {
            let errorMessage = '';
            if (action.payload && action.payload.message) {
                errorMessage = action.payload.message;
            }
            if(action.payload.response !== undefined && action.payload.response.data){
                if('non_field_errors' in action.payload.response.data){
                    errorMessage = action.payload.response.data['non_field_errors'][0];
                }
                else if('survey' in action.payload.response.data){
                    errorMessage = action.payload.response.data.survey[0];
                }
            }
            return {
                ...state,
                surveyInviteCreateStatus: HTTP_REQUEST_STATUS.FAIL,
                surveyInviteCreateFailureMessage: errorMessage
            }
        }

        case SURVEY_INVITE_CREATE_STATUS_RESET: {
            return {
                ...state,
                surveyInviteCreateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        // =================Survey Invite List=========================== //

        case SURVEY_INVITE_GET_LIST_INPROGRESS: {
            return{
                ...state,
                surveyInviteGetListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_GET_LIST_NONE: {
            return{
                ...state,
                surveyInviteGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_GET_LIST_SUCCESS: {
            let response = '';
            if(action.payload.results != undefined && action.payload.results !== null && action.payload.results !== ''){
                response = action.payload.results;
            }
            return{
                ...state,
                surveyInviteGetListStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyInviteList: [...response]
            }
        }

        case SURVEY_INVITE_GET_LIST_FAIL: {
            return{
                ...state,
                surveyInviteGetListStatus: HTTP_REQUEST_STATUS.FAIL,
                alertmessage: ''
            }
        }

        // =================Survey Invite GET specific Invite Details=========================== //

        case SURVEY_INVITE_GET_SPECIFIC_INVITE_INPROGRESS: {
            return{
                ...state,
                surveyInviteGetSpecificInviteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }
        case SURVEY_INVITE_GET_SPECIFIC_INVITE_SUCCESS: {
            let result = '';
            if (action.payload !== undefined && action.payload !== null && action.payload !== '') {
                result = action.payload;
            }
            return{
                ...state,
                surveyInviteGetSpecificInviteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyInviteGetSpecificDetails: result
            }
        }
        case SURVEY_INVITE_GET_SPECIFIC_INVITE_NONE: {
            return{
                ...state,
                surveyInviteGetSpecificInviteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }
        case SURVEY_INVITE_GET_SPECIFIC_INVITE_FAIL: {
            let alertMessage = '';
            if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return{
                ...state,
                surveyInviteGetSpecificInviteStatus: HTTP_REQUEST_STATUS.FAIL,
                alertmessage: alertMessage
            }
        }
        // =================Survey Invite Update specific Invite Details=========================== //

        case SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_INPROGRESS: {
            return{
                ...state,
                surveyInviteUpdateSpecificInviteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_SUCCESS: {
            let alertMessage = '';
            if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return{
                ...state,
                surveyInviteUpdateSpecificInviteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertmessage: alertMessage
            }
        }
        case SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_NONE: {
            return{
                ...state,
                surveyInviteUpdateSpecificInviteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }
        case SURVEY_INVITE_UPDATE_SPECIFIC_INVITE_FAIL: {
            let alertMessage = '';
            if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return{
                ...state,
                surveyInviteUpdateSpecificInviteStatus: HTTP_REQUEST_STATUS.FAIL,
                alertmessage: alertMessage
            }
        }

        // =================Survey Invite outreach=========================== //

        case SURVEY_INVITE_OUTREACH_INPROGRESS: {
            return{
                ...state,
                surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }
        case SURVEY_INVITE_OUTREACH_SUCCESS: {
            let alertMessage = '';
            let response = '';
            if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            if(action.payload !== undefined && action.payload !== null && action.payload !== ''){
                response = action.payload;
            }
            return{
                ...state,
                surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyInviteOutreachResponse: {...response},
                alertmessage: alertMessage
            }
        }
        case SURVEY_INVITE_OUTREACH_NONE: {
            return{
                ...state,
                surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }
        case SURVEY_INVITE_OUTREACH_FAIL: {
            let errorMessage = '';
            if (action.payload && action.payload.message) {
                errorMessage = action.payload.message;
            }
            if(action.payload.response !== undefined && action.payload.response.data){
                if('non_field_errors' in action.payload.response.data){
                    errorMessage = action.payload.response.data['non_field_errors'][0];
                }
                else if('survey' in action.payload.response.data){
                    errorMessage = action.payload.response.data.survey[0];
                }
            }
            return{
                ...state,
                surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.FAIL,
                surveyOutreachFailureMessage: errorMessage
            }
        }

        case SURVEY_INVITE_OUTREACH_STATUS_RESET : {
            return {
                ...state,
                surveyInviteOutreachStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

         // =================Survey invite update survey period======

         case SURVEY_INVITE_UPDATE_SURVEY_PERIOD_NONE: {
            return {
                ...state,
                surveyInviteUpdateSurveyPeriodStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
         }

          case SURVEY_INVITE_UPDATE_SURVEY_PERIOD_SUCCESS: {
            let alertMessage = <IntlMessages id="DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_PERIOD_UPDATE_SUCCESS_MESSAGE" />
            if (action.payload) {
                if (action.payload.message) {
                    alertMessage = action.payload.message;
                }
            }
            return{
                ...state,
                surveyInviteUpdateSurveyPeriodStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: alertMessage
            }
          }

          case SURVEY_INVITE_UPDATE_SURVEY_PERIOD_FAIL:{
            let alertMessage = <IntlMessages id="DIC_SURVEY_REPORTS_INVITE_PAGE_SURVEY_PERIOD_UPDATE_FAIL_MESSAGE" />;
            if (action.payload && action.payload.message) {
                alertMessage = action.payload.message;
            }
            return{
                ...state,
                surveyInviteUpdateSurveyPeriodStatus: HTTP_REQUEST_STATUS.FAIL,
                alertmessage: alertMessage
            }
          }

         case SURVEY_INVITE_UPDATE_SURVEY_PERIOD_INPROGRESS: {
            return{
                ...state,
                surveyInviteUpdateSurveyPeriodStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
         }

        // =================Survey Invite Send Reminder =========================== //

        case SURVEY_INVITE_SEND_REMINDER_INPROGRESS: {
            return{
                ...state,
                surveyInviteSendReminderStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_SEND_REMINDER_NONE: {
            return{
                ...state,
                surveyInviteSendReminderStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_SEND_REMINDER_SUCCESS: {
            return{
                ...state,
                surveyInviteSendReminderStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case SURVEY_INVITE_SEND_REMINDER_FAIL: {
            let errResponse = 'DIC_SURVEY_REPORTS_INVITE_PAGE_SEND_REMINDER_FAILURE';
            if(action.payload.response && action.payload.response.data && action.payload.response.data.error){
                    errResponse = action.payload.response.data.error
            }
            return{
                ...state,
                surveyInviteSendReminderStatus: HTTP_REQUEST_STATUS.FAIL,
                surveyInviteSendReminderKey: errResponse
            }
        }

        // =================Survey Invite Invited Media Detail =========================== //

        case SURVEY_INVITE_INVITED_MEDIA_DETAILS_INPROGRESS: {
            return{
                ...state,
                surveyInviteInvitedMediaDetailStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_INVITED_MEDIA_DETAILS_NONE: {
            return{
                ...state,
                surveyInviteInvitedMediaDetailStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertmessage: ''
            }
        }

        case SURVEY_INVITE_INVITED_MEDIA_DETAILS_SUCCESS: {
            let result = '';
            if (action.payload !== undefined && action.payload !== null && action.payload !== '') {
                result = action.payload.data ? action.payload.data.undelivered : 0;
            }
            return{
                ...state,
                surveyInviteInvitedMediaDetailStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyInviteInvitedMediaDetailData: result
            }
        }

        case SURVEY_INVITE_INVITED_MEDIA_DETAILS_FAIL: {
            let errResponse = 'DIC_SURVEY_REPORTS_INVITE_PAGE_SEND_REMINDER_FAILURE';
            if(action.payload.response && action.payload.response.data && action.payload.response.data.error){
                    errResponse = action.payload.response.data.error
            }
            return{
                ...state,
                surveyInviteInvitedMediaDetailStatus: HTTP_REQUEST_STATUS.FAIL,
                surveyInviteInvitedMediaDetailData: errResponse
            }
        }

         // =================Survey Invite input values from single response section=========================== //
            case SURVEY_INVITE_SET_SELECTED_INPUTS: {
                const {invitePageChannel, invitePageMessage, invitePageSendDateAndTime, invitePageSendTimeImmediateStatus} = action.payload;
                return{
                    ...state,
                    invitePageMessage: typeof invitePageMessage !== 'undefined' ? invitePageMessage : state.invitePageMessage,
                    invitePageChannel: typeof invitePageChannel !== 'undefined' ? invitePageChannel : state.invitePageChannel,
                    invitePageSendDateAndTime: typeof invitePageSendDateAndTime !== 'undefined' ?  invitePageSendDateAndTime : state.invitePageSendDateAndTime,
                    invitePageSendTimeImmediateStatus: typeof invitePageSendTimeImmediateStatus !== 'undefined' ? invitePageSendTimeImmediateStatus : state.invitePageSendTimeImmediateStatus
                }
            }

            case SURVEY_INVITE_RESET_SELECTED_INPUTS: {
                return {
                    ...initialData
                }
            }
        default:
            return state
    }
}

export default ReducerSurveyInvitePage;
    