import IntlMessages from "../../../../util/IntlMessages";
import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import commonStyles from "../../../../util/commonStyle";

import {
    TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP, 
	TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP, 
	TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS, 
	TRANSLATION_MODULE_UPDATE_STATUS_NONE, 
	TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS, 
} from "../../../../constants/ActionTypes";

const translationModulesTblHeader = [

    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_SL.NO"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_NEW_EDIT_MODULE_NAME"/>
    },
    {
        id: 'category',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_NEW_EDIT_MODULE_CATEGORY_CODE"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_ACTIONS"/>
    },
];


const translationModulesItems = [
    {
        'key': 'name',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_MODULE_NEW_EDIT_MODULE_NAME",
        'isMandatory': true
    },
    {
        'key': 'category',
        'type': 'dropdown',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_TRANSLATION_MODULE_NEW_EDIT_MODULE_CATEGORY_CODE",
        'isMandatory': true

    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'style': {
            paddingBottom: "10%",
            marginTop: "1%",
            marginLeft: "-10px"
        },
        'localisation':
            "DIC_SETTINGS_TRANSLATION_MODULE_NEW_EDIT_IS_ACTIVE"
    },
];



const initialData = {
    tableColumns: translationModulesTblHeader,
    translationModulesdata: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedItem: null,
    translationModulesItems:translationModulesItems,
    translationModulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationModulesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationModulesUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    translationModulesMap: [],
    translationModulesMapDropdown: []
    
};

const ReducerTranslationModules = (state = initialData, action) => {
    switch (action.type) {
        case TRANSLATION_MODULE_UPDATE_STATUS_NONE:
            return {
                ...state,
                translationModulesUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            };

        case TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                translationModulesUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_UPDATE_MESSAGE"/>
            };


        case TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS:

            let translationModulesMap = generatetranslationModulesMap(action.payload);

            let dropDownValues = [];
            dropDownValues.push({
                key: -1,
                value: -1,
            });

            for ( let i = 0 ; i < translationModulesMap.length; i++ ) {
                dropDownValues.push(translationModulesMap[i]);
            }
            return {
                ...state,
                translationModulesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                translationModulesdata: action.payload,
                translationModulesMap:translationModulesMap,
                translationModulesMapDropdown:dropDownValues
            };

        /**** Translation Module save error ****/

        case TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.message,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: false,
                selectedItem: null,
                translationModulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false
            };

        /**** Translation Module save success ****/

        case TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: <IntlMessages id="DIC_SETTINGS_TRANSLATION_MODULE_SAVE_MESSAGE"/>,
                isErrorMessage: false,
                isSuccessMessage: true,
                isPopUpVisible: false,
                selectedItem: null,
                translationModulesSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };

        /**** Translation Module http status to not started ****/

        case TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE:
            return {
                ...state,
                translationModulesSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        /**** Translation Module http status to in progress ****/

        case TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                translationModulesSaveStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        /**** to display translationModules popup ****/

        case TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedItem : action.payload
            };

        /**** to hide translationModules popup ****/
        
        case TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false
            };

        default:
            return state;

    }
}

/****
 Util : for generating the Module list map
 ****/
function generatetranslationModulesMap( data ) {
    let result = [];

    try{
        for ( let i = 0 ; i < data.length; i++ ){
            result.push({
                key: data[i]['id'],
                value: data[i]['id'],
                label: data[i]['id']
            })
        }
    } catch (ex) {
        console.error(ex);
    }
    return result;
}

export default ReducerTranslationModules;