import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {
     URL_RESET_REGISTRATION_VERIFY_EXCEL_UPLOADED,
     URL_RESET_REGISTRATION_DOWNLOAD_TEMPLATE,
     URL_RESET_REGISTRATION_GET_LIST
} from '../../../constants/Urls';
import {
     RESET_REGISTRATION_DOWNLOAD_TEMPLATE,
     RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION,
     RESET_REGISTRATION_GET_LIST,
     RESET_REGISTRATION_DOWNLOAD_REPORT
} from '../../../constants/actions/wc/resetRegistration/actionTypesResetRegistration';
import {
     resetRegistrationDownloadTemplateError,
     resetRegistrationVerifyFileUploadError,
     resetRegistrationDownloadTemplateSuccess,
     resetRegistrationVerifyFileUploadSuccess,
     resetRegistrationGetListError,
     resetRegistrationGetListSuccess,
     downloadReportSuccess,
     downloadReportError
} from '../../../actions/wc/resetRegistration/ActionsResetRegistration';
import { baseUrl } from "../../../util/keys";
import { HTTP_STATUS_CODE } from "../../../constants/config";

/*************************************HTTP Calls******************************************** */

/** http : Donwload reset registration Attachment Template**/
const httpResetRegistrationDownloadTemplate = async (data) => {
     return await axios.post(baseUrl + URL_RESET_REGISTRATION_DOWNLOAD_TEMPLATE, data, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/** http : verify reset registration file upload**/
const httpResetRegistrationVerifyFileUpload = async (data) => {
     return await axios.post(baseUrl + URL_RESET_REGISTRATION_VERIFY_EXCEL_UPLOADED, data, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/** http : reset registration get list**/
const httpResetRegistrationGetList = async (url) => {
     return await axios.get(url, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/** http : reset registration download report**/
const httpDownloadReport = async (url) => {
     return await axios.get(url, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/**************************************HTTP Call handlers*************************************** */

/**To handle the http cal for download of template */
function* handleHttpResetRegistrationDownloadTemplate({ payload }) {
     try {
          let response = yield call(httpResetRegistrationDownloadTemplate, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(resetRegistrationDownloadTemplateSuccess(response));
          } else {
               yield put(resetRegistrationDownloadTemplateError(response));
          }
     } catch (error) {
          yield put(resetRegistrationDownloadTemplateError(error));
     }
}

/**To handle the http call for varification of file upload */
function* handleHttpResetRegistrationVerifyFileUpload({ payload }) {
     try {
          let response = yield call(httpResetRegistrationVerifyFileUpload, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(resetRegistrationVerifyFileUploadSuccess(response));
          } else {
               yield put(resetRegistrationVerifyFileUploadError(response));
          }
     } catch (error) {
          yield put(resetRegistrationVerifyFileUploadError(error));
     }
}

/**To handle the http call for getting reset list */
function* httpResetRegistrationList({payload}) {
     try {
          const { client_id, id, limit,  o, offset, searchStatus, seachText} = payload;

          let url = `${baseUrl + URL_RESET_REGISTRATION_GET_LIST}?client_id=${client_id}`;
          
          if (typeof o !== 'undefined') {
               url += `&o=${o}`;
          }

          if (typeof id !== 'undefined') {
               url += `&id=${id}`;
          }

          if (typeof limit !== 'undefined') {
               url += `&limit=${limit}`;
          }

          if (typeof offset !== 'undefined') {
               url += `&offset=${offset}`;
          }

          if (typeof searchStatus !== 'undefined' && searchStatus !== -1) {
               url += `&status=${searchStatus}`;
          }

          if (typeof seachText !== 'undefined' && seachText !== '') {
               url += `&search=${encodeURIComponent(seachText)}`;
          }

          let response = yield call(httpResetRegistrationGetList, url);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {

                    /**Getting user time zone from store */
                    let userTimeZone = AppUtils.getUserTimeZone();
                    
                    /**Adding it to response so to utilize in reducer */
                    response['userTimeZone'] = userTimeZone;

               yield put(resetRegistrationGetListSuccess(response));
          } else {
               yield put(resetRegistrationGetListError(response));
          }
     } catch (error) {
          yield put(resetRegistrationGetListError(error));
     }
}

/**To handle the http call for download report */
function* handleDownloadReport({ payload }) {
     try {
          let response = yield call(httpDownloadReport, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(downloadReportSuccess(response));
          } else {
               yield put(downloadReportError(response));
          }
     } catch (error) {
          yield put(downloadReportError(error));
     }
}

/*************************************Listeners**************************************************** */

/**To listen to the action on click of download template */
export function* resetRegistrationDownloadTemplate() {
     yield takeEvery(RESET_REGISTRATION_DOWNLOAD_TEMPLATE, handleHttpResetRegistrationDownloadTemplate);
}

/**To listen to the action on upload of validated file */
export function* resetRegistrationVerifyFileUpload() {
     yield takeEvery(RESET_REGISTRATION_FILE_UPLOAD_VERIFICATION, handleHttpResetRegistrationVerifyFileUpload);
}

/**To listen to the action for getting reset list */
export function* getResetRegistrationList() {
     yield takeEvery(RESET_REGISTRATION_GET_LIST, httpResetRegistrationList)
}

/**To listen to action when clicked on download report */
export function* downloadReport() {
     yield takeEvery(RESET_REGISTRATION_DOWNLOAD_REPORT, handleDownloadReport)
}

export default function* rootSaga() {
     yield all([
          fork(resetRegistrationDownloadTemplate),
          fork(resetRegistrationVerifyFileUpload),
          fork(getResetRegistrationList),
          fork(downloadReport)
     ]);
}