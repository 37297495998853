

/** For update user preferred language **/
export const USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE = 'USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE';
export const USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS = 'USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_SUCCESS';
export const USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR = 'USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_ERROR';
export const USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS = 'USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_IN_PROGRESS';
export const USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE = 'USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE_NONE';

/** For update user preferred language **/
export const USER_SETTINGS_UPDATE_TIME_ZONE = 'USER_SETTINGS_UPDATE_TIME_ZONE';
export const USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS = 'USER_SETTINGS_UPDATE_TIME_ZONE_SUCCESS';
export const USER_SETTINGS_UPDATE_TIME_ZONE_ERROR = 'USER_SETTINGS_UPDATE_TIME_ZONE_ERROR';
export const USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS = 'USER_SETTINGS_UPDATE_TIME_ZONE_IN_PROGRESS';

/** For update user preferred client **/
export const USER_SETTINGS_UPDATE_PREFERRED_CLIENT = 'USER_SETTINGS_UPDATE_PREFERRED_CLIENT';
export const USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS = 'USER_SETTINGS_UPDATE_PREFERRED_CLIENT_SUCCESS';
export const USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR = 'USER_SETTINGS_UPDATE_PREFERRED_CLIENT_ERROR';
export const USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS = 'USER_SETTINGS_UPDATE_PREFERRED_CLIENT_IN_PROGRESS';
export const USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE = 'USER_SETTINGS_UPDATE_PREFERRED_CLIENT_NONE';

/** For update user preferred theme colour **/
export const USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR = 'USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR';
export const USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_SUCCESS = 'USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_SUCCESS';
export const USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_ERROR = 'USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_ERROR';
export const USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_IN_PROGRESS = 'USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_IN_PROGRESS';
export const USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_NONE = 'USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR_NONE';

/** For update user profile image **/
export const USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE = 'USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE';
export const USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_SUCCESS = 'USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_SUCCESS';
export const USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_ERROR = 'USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_ERROR';
export const USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_IN_PROGRESS = 'USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_IN_PROGRESS';
export const USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_NONE = 'USER_SETTINGS_UPDATE_USER_PROFILE_IMAGE_NONE';



