
export const WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE = 'WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE';
export const WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS = 'WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_SUCCESS';
export const WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR = 'WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_ERROR';
export const WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE = 'WC_DOWNLOAD_WORKER_CONTACT_TEMPLATE_NONE';

export const WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD = 'WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD';
export const WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS = 'WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_SUCCESS';
export const WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR = 'WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_ERROR';
export const WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE = 'WC_DOWNLOAD_WORKER_CONTACT_ATTACHMENT_DOWNLOAD_NONE';


export const WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST = 'WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST';
export const WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS = 'WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_SUCCESS';
export const WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR = 'WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_ERROR';
export const WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE = 'WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST_NONE';

export const WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL = 'WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL';
export const WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS = 'WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_SUCCESS';
export const WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR = 'WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_ERROR';
export const WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE = 'WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL_NONE';

export const WC_SYNC_MS_SQL_WORKER_CONTACTS = 'WC_SYNC_MS_SQL_WORKER_CONTACTS';
export const WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS = 'WC_SYNC_MS_SQL_WORKER_CONTACTS_SUCCESS';
export const WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR = 'WC_SYNC_MS_SQL_WORKER_CONTACTS_ERROR';
export const WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE = 'WC_SYNC_MS_SQL_WORKER_CONTACTS_NONE';

export const WC_GET_PAGINATED_LIST = 'WC_GET_PAGINATED_LIST';
export const WC_GET_PAGINATED_LIST_SUCCESS = 'WC_GET_PAGINATED_LIST_SUCCESS';
export const WC_GET_PAGINATED_LIST_ERROR = 'WC_GET_PAGINATED_LIST_ERROR';
export const WC_GET_PAGINATED_LIST_NONE = 'WC_GET_PAGINATED_LIST_NONE';

export const WC_WORKER_CONTACTS_DOWNLOAD = 'WC_WORKER_CONTACTS_DOWNLOAD';
export const WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS = 'WC_WORKER_CONTACTS_DOWNLOAD_SUCCESS';
export const WC_WORKER_CONTACTS_DOWNLOAD_ERROR = 'WC_WORKER_CONTACTS_DOWNLOAD_ERROR';
export const WC_WORKER_CONTACTS_DOWNLOAD_NONE = 'WC_WORKER_CONTACTS_DOWNLOAD_NONE';

export const WC_WORKER_CONTACTS_DEACTIVATE = 'WC_WORKER_CONTACTS_DEACTIVATE';
export const WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS = 'WC_WORKER_CONTACTS_DEACTIVATE_SUCCESS';
export const WC_WORKER_CONTACTS_DEACTIVATE_ERROR = 'WC_WORKER_CONTACTS_DEACTIVATE_ERROR';
export const WC_WORKER_CONTACTS_DEACTIVATE_NONE = 'WC_WORKER_CONTACTS_DEACTIVATE_NONE';

export const WC_WORKER_CONTACTS_REACTIVATE = 'WC_WORKER_CONTACTS_REACTIVATE';
export const WC_WORKER_CONTACTS_REACTIVATE_SUCCESS = 'WC_WORKER_CONTACTS_REACTIVATE_SUCCESS';
export const WC_WORKER_CONTACTS_REACTIVATE_ERROR = 'WC_WORKER_CONTACTS_REACTIVATE_ERROR';
export const WC_WORKER_CONTACTS_REACTIVATE_NONE = 'WC_WORKER_CONTACTS_REACTIVATE_NONE';

export const WC_WORKER_CONTACTS_DELETE = 'WC_WORKER_CONTACTS_DELETE';
export const WC_WORKER_CONTACTS_DELETE_SUCCESS = 'WC_WORKER_CONTACTS_DELETE_SUCCESS';
export const WC_WORKER_CONTACTS_DELETE_ERROR = 'WC_WORKER_CONTACTS_DELETE_ERROR';
export const WC_WORKER_CONTACTS_DELETE_NONE = 'WC_WORKER_CONTACTS_DELETE_NONE';

export const WC_WORKER_CONTACTS_GROUPS_GET_LIST = 'WC_WORKER_CONTACTS_GROUPS_GET_LIST';
export const WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS = 'WC_WORKER_CONTACTS_GROUPS_GET_LIST_SUCCESS';
export const WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR = 'WC_WORKER_CONTACTS_GROUPS_GET_LIST_ERROR';
export const WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE = 'WC_WORKER_CONTACTS_GROUPS_GET_LIST_NONE';
export const WC_WORKER_CONTACTS_GROUPS_FILTER_DATA = 'WC_WORKER_CONTACTS_GROUPS_FILTER_DATA';
export const WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS = 'WC_WORKER_CONTACT_GROUPS_FILTER_BY_STATUS';

export const WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP = 'WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP';
export const WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS = 'WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_SUCCESS';
export const WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR = 'WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_ERROR';
export const WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE = 'WC_WORKER_CONTACTS_GROUPS_SAVE_GROUP_NONE';

export const WC_WORKER_CONTACT_GROUPS_DEACTIVATE = 'WC_WORKER_CONTACT_GROUPS_DEACTIVATE';
export const WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS = 'WC_WORKER_CONTACT_GROUPS_DEACTIVATE_SUCCESS';
export const WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR = 'WC_WORKER_CONTACT_GROUPS_DEACTIVATE_ERROR';
export const WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE = 'WC_WORKER_CONTACT_GROUPS_DEACTIVATE_NONE';

export const WC_WORKER_CONTACT_GROUPS_ACTIVATE = 'WC_WORKER_CONTACT_GROUPS_ACTIVATE';
export const WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS = 'WC_WORKER_CONTACT_GROUPS_ACTIVATE_SUCCESS';
export const WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR = 'WC_WORKER_CONTACT_GROUPS_ACTIVATE_ERROR';
export const WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE = 'WC_WORKER_CONTACT_GROUPS_ACTIVATE_NONE';

export const WC_WORKER_CONTACTS_GET_CONTACT_DETAILS = 'WC_WORKER_CONTACTS_GET_CONTACT_DETAILS';
export const WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_SUCCESS = 'WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_SUCCESS';
export const WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_ERROR = 'WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_ERROR';
export const WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_NONE = 'WC_WORKER_CONTACTS_GET_CONTACT_DETAILS_NONE';
export const WC_WORKER_CONTACTS_SET_CURRENT_CONTACT = 'WC_WORKER_CONTACTS_SET_CURRENT_CONTACT';

export const WC_WORKER_CONTACT_SAVE_CONTACT = 'WC_WORKER_CONTACT_SAVE_CONTACT';
export const WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS = 'WC_WORKER_CONTACT_SAVE_CONTACT_SUCCESS';
export const WC_WORKER_CONTACT_SAVE_CONTACT_ERROR = 'WC_WORKER_CONTACT_SAVE_CONTACT_ERROR';
export const WC_WORKER_CONTACT_SAVE_CONTACT_NONE = 'WC_WORKER_CONTACT_SAVE_CONTACT_NONE';

export const WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST = 'WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST';
export const WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS = 'WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_SUCCESS';
export const WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR = 'WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_ERROR';
export const WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE = 'WC_WORKER_CONTACTS_GROUPS_GET_ACTIVE_LIST_NONE';

export const WC_WORKER_CONTACT_GET_CONTACT = 'WC_WORKER_CONTACT_GET_CONTACT';
export const WC_WORKER_CONTACT_GET_CONTACT_SUCCESS = 'WC_WORKER_CONTACT_GET_CONTACT_SUCCESS';
export const WC_WORKER_CONTACT_GET_CONTACT_ERROR = 'WC_WORKER_CONTACT_GET_CONTACT_ERROR';
export const WC_WORKER_CONTACT_GET_CONTACT_NONE = 'WC_WORKER_CONTACT_GET_CONTACT_NONE';

export const WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES = 'WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES';
export const WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS = 'WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_SUCCESS';
export const WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR = 'WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_ERROR';
export const WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE = 'WC_WORKER_CONTACT_GET_CUSTOM_FIELDS_WITH_VALUES_NONE';

export const WC_WORKER_CONTACT_DEACTIVATE_CONTACT = 'WC_WORKER_CONTACT_DEACTIVATE_CONTACT';
export const WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS = 'WC_WORKER_CONTACT_DEACTIVATE_CONTACT_SUCCESS';
export const WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR = 'WC_WORKER_CONTACT_DEACTIVATE_CONTACT_ERROR';
export const WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE = 'WC_WORKER_CONTACT_DEACTIVATE_CONTACT_NONE';

export const WC_WORKER_CONTACT_REACTIVATE_CONTACT = 'WC_WORKER_CONTACT_REACTIVATE_CONTACT';
export const WC_WORKER_CONTACT_REACTIVATE_CONTACT_SUCCESS = 'WC_WORKER_CONTACT_REACTIVATE_CONTACT_SUCCESS';
export const WC_WORKER_CONTACT_REACTIVATE_CONTACT_ERROR = 'WC_WORKER_CONTACT_REACTIVATE_CONTACT_ERROR';
export const WC_WORKER_CONTACT_REACTIVATE_CONTACT_NONE = 'WC_WORKER_CONTACT_REACTIVATE_CONTACT_NONE';

export const WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS = 'WC_WORKER_CONTACT_LIST_UPDATE_FILTER_TRANSLATIONS';
