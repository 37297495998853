export const BUTTON_COLOR_BLUE = "#2196F3";
export const BUTTON_COLOR_GREEN = "#a1c43a";
export const BUTTON_COLOR_RED = "#a31e22";
export const BUTTON_CLASS_COLOR_NEW = "jr-btn text-white bg-blue-grey";
export const BUTTON_COLOR_YELLOW = "#FEBB47";
export const FILTER_SEARCH_BUTTON_COLOR = "#2196F3";
export const FILTER_SEARCH_BUTTON_DISABLED_COLOR = "#70BFFF";
export const FILTER_CLEAR_BUTTON_COLOR = "#F9F9F9";
export const FILTER_CLEAR_BUTTON_TRANSPARENT_COLOR = "transparent";
export const BUTTON_DISABLE_COLOR = "#CBE7FF";

export const LOGO_BACKGROUND = "#FFFFFF";


export const E_LEARNING_REPORTS_USER_ACTIVITY_CR_NOT_ENROLLED = "#7DC7DA";// "#7193E9";
export const E_LEARNING_REPORTS_USER_ACTIVITY_CR_ENROLLED = "#7193E9";
export const E_LEARNING_REPORTS_USER_ACTIVITY_CR_LESSON_COMPLETED = "#FEBB47";
export const E_LEARNING_REPORTS_USER_ACTIVITY_CR_QUIZ_COMPLETED = "#96DB59";

export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_FALLING = "#FEBB47";
export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_RAISING = "#96DB59";


//survey responses
export const SURVEY_MAIN_COLOUR = "#42A1FF";
export const SURVEY_MAIN_HOVER_COLOUR = "#024EA1";
export const SURVEY_TEXT_COLOUR = "#4A4A4A";
export const SURVEY_HEADER_COLOUR = "#42A1FF";
export const SURVEY_HEADER_BACKGROUND_COLOUR = "#42A1FF";
export const SURVEY_BODY_CONTAINER_BACKGROUND_COLOUR = "#F8F8F8";

//survey question bank
export const SURVEY_QUESTION_BANK_COLOR ={
    "SURVEY_QUESTION_BANK_COLOR_GREEN": "#a1c43a",
    "SURVEY_QUESTION_BANK_COLOR_RED" : "#a31e22"
}


//Quick Insights

export const SCORE_DISPLAY_COLOR_YELLOW = "#FFB300";
export const SCORE_DISPLAY_COLOR_GREEN = "#097C14";
export const SCORE_DISPLAY_COLOR_BLUE = "#1141BB";

//Limit response per device switch 
export const SWITCH_BASE_ON_ENABLE = "#2196F3";
export const SWITCH_BASE_ON_DISABLE = "#CBE7FF";
export const SWITCH_BASE_OFF_ENABLE = "#797979";
export const SWITCH_BASE_OFF_DISABLE = "#C8C6C6";
export const SWITCH_TRACK_ON_ENABLE = "#A3D6FF";
export const SWITCH_TRACK_ON_DISABLE = "#E0F0FE";
export const SWITCH_TRACK_OFF_ENABLE = "#AAAAAA";
export const SWITCH_TRACK_OFF_DISABLE = "#F0EDED";


export const TABLE_HEADER_BACKGROUND_COLOR = "#2196F3";
export const TABLE_HEADER_SURVEY_AVERAGE_BACKGROUND_COLOR = "#EDF4F9";
export const TABLE_HEADER_RESPONSE_COUNT_BACKGROUND_COLOR = "#F8F7FA";
export const TABLE_RESPONSE_COUNT_COLOR = "#292D2D";
export const TABLE_CELL_BACKGROUB_COLOR = "#FFFFFF";

export const DOWNLOAD_ICON_DEFAULT_COLOR = "#4F4F4F";

export const PRIMARY_COLOR_BLUE = "#2196F3";

export const MANULA_LINK_FOOTER_COLOR = "#F2F2F2";

export const DROPDOWN_NAME_LABEL_COLOR = "#9DA3A3";