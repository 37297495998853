import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { baseUrl } from '../../util/keys';
import AppUtils from '../../util/util';
import { URL_BRAND_CHILD_CLIENTS_COUNTRIES } from '../../constants/Urls';
import {
	getChildClientCountriesSuccess,
	getChildClientCountriesError,
} from '../../actions/countries/actionCountries';
import { GET_BRAND_CHILD_CLIENT_COUNTRIES } from '../../constants/actions/countries/actionConfigCountries';
import { HTTP_STATUS_CODE } from '../../constants/config';

/************ methods : http *******************/

/** http : Get child client countries by brand**/
const httpGetCountriesByClient = async (data) => {
	return await axios
		.post(
			baseUrl + URL_BRAND_CHILD_CLIENTS_COUNTRIES,
			data,
			AppUtils.getHttpHeaders()
		)
		.then((authUser) => authUser.data)
		.catch((error) => {
			return error;
		});
};

/************ methods : http response handler *******************/

/** http : get company post raw data : http response handler **/
function* handleHttpGetCountriesByClient({ payload }) {
	try {
		let result = yield call(httpGetCountriesByClient, payload);

		if (
			result &&
			result.status &&
			result.status === HTTP_STATUS_CODE['SUCCESS']
		) {
			yield put(getChildClientCountriesSuccess(result));
		} else if (result && result.message) {
			yield put(getChildClientCountriesError(result.message));
		}
	} catch (error) {
		yield put(getChildClientCountriesError(error));
	}
}

/************ methods : export access *******************/

/**To get company post raw data uuid*/
export function* getCountriesByClient() {
	yield takeEvery(
		GET_BRAND_CHILD_CLIENT_COUNTRIES,
		handleHttpGetCountriesByClient
	);
}

export default function* rootSaga() {
	yield all([fork(getCountriesByClient)]);
}
