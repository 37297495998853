import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../../util/keys";
import {
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA
}from "../../../../constants/ActionTypes";
import {
    URL_CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID,
    URL_CONNECT_SUMMARY_TABLE_DOWNLOAD   
} from "../../../../constants/Urls";
import AppUtils from "../../../../util/util";
import {
    conncetSummaryTableDownloadUUIDDataSuccess,
    conncetSummaryTableDownloadUUIDDataError
}from "../../../../actions";
import {HTTP_STATUS_CODE} from "../../../../constants/config";

/************ methods : http *******************/

/** http : connect reports summarytable: To download the UUID**/

const httpConnectSummarTableDownloadUUID = async (data) =>{
    return await axios.post(baseUrl + URL_CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID , data, AppUtils.getHttpHeaders())
       .then(authUser => authUser.data)
       .catch(error => {
           return error;
       });
};

/** http : connect reports summarytable: To download the responses**/

const httpConnectSummarTableDownloadUUIDData = async (data) =>{
    return await axios.post(baseUrl + URL_CONNECT_SUMMARY_TABLE_DOWNLOAD , data, AppUtils.getHttpHeaders())
       .then(authUser => authUser.data)
       .catch(error => {
           return error;
       });
};

/************ methods : http response handler *******************/

/** http : connect summaytable Download: http response handler **/

function* handleHttpConnectSummaryTableDownloadUUID({payload}) {
    try {
        let result = yield call(httpConnectSummarTableDownloadUUID,payload);
        
        if (result.status === HTTP_STATUS_CODE['FAIL']) {
            yield put(conncetSummaryTableDownloadUUIDDataError(result.message));
        } else {
            yield put(conncetSummaryTableDownloadUUIDDataSuccess(result));            
        }
    } catch (error) {
        yield put(conncetSummaryTableDownloadUUIDDataError(error));
    }
}

/** http : connect summaytable Download data: http response handler **/

function* handleHttpConnectSummaryTableDownloadUUIDData({payload}) {
    try {
        let result = yield call(httpConnectSummarTableDownloadUUIDData,payload);
        
        if (result.status === HTTP_STATUS_CODE['FAIL']) {
            yield put(conncetSummaryTableDownloadUUIDDataError(result.message));
        } else {
            yield put(conncetSummaryTableDownloadUUIDDataSuccess(result));
        }
    } catch (error) {
        yield put(conncetSummaryTableDownloadUUIDDataError(error));
    }
}

/************ methods : export access *******************/

/** Connect summarytable download **/
export function* connectReportsSummaryTableUUIDDownload() {
    yield takeEvery(CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID, handleHttpConnectSummaryTableDownloadUUID);
}

/** Connect summarytable download data **/
export function* conncetSummaryTableDownloadUUIDData() {
    yield takeEvery(CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA, handleHttpConnectSummaryTableDownloadUUIDData);
}

export default function* rootSaga() {
    yield all([
        fork(connectReportsSummaryTableUUIDDownload),
        fork(conncetSummaryTableDownloadUUIDData)
    ]);
}