import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import { store } from '../MainApp';

import axios from "axios";
import {baseUrl} from "../util/keys";

import {
    E_LEARNING_REPORTS_ENROLMENT_GENERAL,
    E_LEARNING_REPORTS_ENROLMENT_TOPICS,
    E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
    E_LEARNING_REPORTS_ENROLMENT_STATUSES,
    E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN,
    E_LEARNING_REPORTS_ENROLMENT_COURSES,

    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY,
    E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY,

    E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS,

    E_LEARNING_REPORTS_COMPARISION_OVERTIME,
    E_LEARNING_REPORTS_COMPARISION_GENERAL,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA,

} from "../constants/ActionTypes";

import {
    getELearningReportsEnrolmentBreakdownSuccess,
    getELearningReportsEnrolmentCoursesSuccess,
    getELearningReportsEnrolmentGeneralSuccess,
    getELearningReportsEnrolmentProportionSuccess,
    getELearningReportsEnrolmentStatusesSuccess,
    getELearningReportsEnrolmentTopicsSuccess,
    getELearningReportsUserActivityCompletionRatioSuccess,
    getELearningReportsUserActivityContentPopularitySuccess,
    getELearningReportsUserActivityDifficultySuccess,
    getELearningReportsUserActivityQuizScoreSuccess,
    showErrorMessage,
    getELearningReportsComparisionQuizScoreSuccess,
    getELearningReportsComparisionContentAvgScoreSuccess,
    getELearningReportsComparisionGeneralSuccess,
    getELearningReportsComparisionOverTimeSuccess,
    getELearningReportsComparisionRatioCoursesSuccess,
    getELearningReportsComparisionRatioUsersUUIDDataSuccess,
    getELearningSummaryTableDataSuccess,
    getELearningSummaryTableDataError,
    getELearningSummaryTableDataUUIDError,
    getELearningSummaryTableDataUUIDSuccess,

    eLearningRawDataDownloadUUIDSuccess,
    eLearningRawDataDownloadUUIDError,
    eLearningRawDataDownloadUUIDStatusError,
    eLearningRawDataDownloadUUIDStatusSuccess
} from "../actions";

import {
    URL_E_LEARNING_REPORTS_ENROLMENT_GENERAL,
    URL_E_LEARNING_REPORTS_ENROLMENT_TOPICS,
    URL_E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
    URL_E_LEARNING_REPORTS_ENROLMENT_STATUSES,
    URL_E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN,
    URL_E_LEARNING_REPORTS_ENROLMENT_COURSES,
    URL_E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
    URL_E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
    URL_E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY,
    URL_E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY,
    HTTP_TIMEOUT,
    URL_E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES,
    URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS,
    URL_E_LEARNING_REPORTS_COMPARISION_OVERTIME,
    URL_E_LEARNING_REPORTS_COMPARISION_GENERAL,
    URL_E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE,
    URL_E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE,
    URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID,
    URL_E_LEARNING_REPORTS_SUMMARY_TABLE,
    URL_E_LEARNING_REPORTS_SUMMARY_TABLE_UUID,
    URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID,
    URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID_STATUS

} from "../constants/Urls";
import AppUtils from "../util/util";
import {
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA, 
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA,
    
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS
} from "../constants/actions/reports/elearning/actionsELearningReports";
import { HTTP_STATUS_CODE } from "../constants/config";
const httpEnrolmentGeneral = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_GENERAL, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpEnrolmentTopics = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_TOPICS, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpEnrolmentProportion = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_PROPORTION, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpEnrolmentStatuses = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_STATUSES, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpEnrolmentBreakdown = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpEnrolmentCourses = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_ENROLMENT_COURSES, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpUserActivityCompletionRatio = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpUserActivityQuizScore = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpUserActivityContentPopularity = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

const httpUserActivityDifficulty = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision ratio : courses **/
const httpComparisionRatioCourses = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision ratio : users **/
const httpComparisionRatioUsers = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision ratio : users data based on uuid **/
const httpComparisionRatioUsersUUIDResponse = async (data) =>
    await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision : over time **/
const httpComparisionOvertime = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_OVERTIME, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision : general **/
const httpComparisionGeneral = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_GENERAL, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision : content avg score **/
const httpComparisionContentAvgScore = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);

/** http : comparision : quiz score **/
const httpComparisionQuizScore = async (data) =>
        await axios.post(baseUrl + URL_E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE, data, {timeout: HTTP_TIMEOUT})
        .then(authUser => authUser)
        .catch(error => error);


/** http : summary table **/
const httpELearningSummaryTableData = async (data) =>
    await axios.post(baseUrl + URL_E_LEARNING_REPORTS_SUMMARY_TABLE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/** http : summary table uuid **/
const httpELearningSummaryTableUUIDData = async (data) =>
    await axios.post(baseUrl + URL_E_LEARNING_REPORTS_SUMMARY_TABLE_UUID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/** http : Get elearning raw data download uuid**/
const httpGetELearningRawDataDownloadUUID = async (data) => {
    return await axios.post(baseUrl + URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/** http : Get elearning raw data download uuid status**/
const httpGetELearningRawDataUUIDStatus = async (data) => {
    return await axios.post(baseUrl + URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

function* generateEnrolmentGeneral({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentGeneral, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentGeneralSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateEnrolmentTopics({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentTopics, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentTopicsSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateEnrolmentProportion({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentProportion, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentProportionSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateEnrolmentStatuses({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentStatuses, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentStatusesSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateEnrolmentBreakdown({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentBreakdown, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentBreakdownSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateEnrolmentCourses({ payload }) {
    try {

        let dataValue = yield call(httpEnrolmentCourses, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsEnrolmentCoursesSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateUserActivityCompletionRatio({ payload }) {
    try {

        let dataValue = yield call(httpUserActivityCompletionRatio, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsUserActivityCompletionRatioSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateUserActivityQuizScore({ payload }) {
    try {

        let dataValue = yield call(httpUserActivityQuizScore, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsUserActivityQuizScoreSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function* generateUserActivityContentPopularity({ payload }) {
    try {

        let dataValue = yield call(httpUserActivityContentPopularity, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsUserActivityContentPopularitySuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function* generateUserActivityDifficulty({ payload }) {
    try {

        let dataValue = yield call(httpUserActivityDifficulty, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsUserActivityDifficultySuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : courses **/
function* generateComparisionRatioCourses({ payload }) {
    try {

        let dataValue = yield call(httpComparisionRatioCourses, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionRatioCoursesSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : users **/
function* generateComparisionRatioUsers({ payload }) {
    try {

        let dataValue = yield call(httpComparisionRatioUsers, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionRatioUsersUUIDDataSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : users data uuid**/
function* generateComparisionRatioUsersUUIDData({ payload }) {
    try {

        let dataValue = yield call(httpComparisionRatioUsersUUIDResponse, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionRatioUsersUUIDDataSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : Overtime **/
function* generateComparisionOvertime({ payload }) {
    try {

        let dataValue = yield call(httpComparisionOvertime, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionOverTimeSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : General **/
function* generateComparisionGeneral({ payload }) {
    try {

        let dataValue = yield call(httpComparisionGeneral, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionGeneralSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : Content AVG score **/
function* generateComparisionContentAvgScore({ payload }) {
    try {

        let dataValue = yield call(httpComparisionContentAvgScore, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionContentAvgScoreSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** Comparision Ratio : quiz score **/
function* generateComparisionQuizScore({ payload }) {
    try {

        let dataValue = yield call(httpComparisionQuizScore, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getELearningReportsComparisionQuizScoreSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/** summary table **/
function* handleSummaryTableDataRequest({ payload }) {
    try {

        let dataValue = yield call(httpELearningSummaryTableData, payload);
        if (dataValue.message) {
            yield put(getELearningSummaryTableDataError(dataValue.message));
        } else {
            yield put(getELearningSummaryTableDataSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(getELearningSummaryTableDataError(error));
    }
}

/** summary table uuid **/
function* handleSummaryTableUUIDDataRequest({ payload }) {
    try {

        let dataValue = yield call(httpELearningSummaryTableUUIDData, payload);

        if (dataValue.message) {
            yield put(getELearningSummaryTableDataUUIDError(dataValue.message));
        } else {
            yield put(getELearningSummaryTableDataUUIDSuccess( dataValue.data ));
        }
    } catch (error) {
        yield put(getELearningSummaryTableDataUUIDError(error));
    }
}

/** http : elearning raw data download : http response handler **/
function* handleHttpELearningRawDataDownloadUUID({ payload }) {
    try {
        let result = yield call(httpGetELearningRawDataDownloadUUID, payload);
        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(eLearningRawDataDownloadUUIDSuccess(result));
        } else {
            yield put(eLearningRawDataDownloadUUIDError(result.message));
        }
    } catch (error) {
        yield put(eLearningRawDataDownloadUUIDError(error));
    }
}

/** http : elearning raw data download uuid : http response handler **/
function* handleHttpELearningRawDataDownloadUUIDStatus({ payload }) {
    try {
        let result = yield call(httpGetELearningRawDataUUIDStatus, payload);

        if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
            yield put(eLearningRawDataDownloadUUIDStatusSuccess(result));
        } else {
            yield put(eLearningRawDataDownloadUUIDStatusError(result.message));
        }
    } catch (error) {
        yield put(eLearningRawDataDownloadUUIDStatusError(error));
    }
}

export function* enrolmentGeneral( ) {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_GENERAL, generateEnrolmentGeneral);
}

export function* enrolmentTopics() {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_TOPICS, generateEnrolmentTopics);
}

export function* enrolmentProportion() {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_PROPORTION, generateEnrolmentProportion);
}

export function* enrolmentStatuses() {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_STATUSES, generateEnrolmentStatuses);
}

export function* enrolmentBreakdown() {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN, generateEnrolmentBreakdown);
}

export function* enrolmentCourses() {
    yield takeEvery(E_LEARNING_REPORTS_ENROLMENT_COURSES, generateEnrolmentCourses);
}

export function* userActivityCompletionRatio() {
    yield takeEvery(E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO, generateUserActivityCompletionRatio);
}

export function* userActivityQuizScore() {
    yield takeEvery(E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE, generateUserActivityQuizScore);
}

export function* userActivityContentPopularity() {
    yield takeEvery(E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY, generateUserActivityContentPopularity);
}

export function* userActivityDifficulty() {
    yield takeEvery(E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY, generateUserActivityDifficulty);
}

export function* comparisionRatioCourses() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES, generateComparisionRatioCourses);
}

export function* comparisionRatioUsers() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_RATIO_USERS, generateComparisionRatioUsers);
}

export function* comparisionRatioUsersUUIDData() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA, generateComparisionRatioUsersUUIDData);
}

export function* comparisionOvertime() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_OVERTIME, generateComparisionOvertime);
}

export function* comparisionGeneral() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_GENERAL, generateComparisionGeneral);
}

export function* comparisionContentAvgScore() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE, generateComparisionContentAvgScore);
}

export function* comparisionQuizScore() {
    yield takeEvery(E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE, generateComparisionQuizScore);
}

export function* getELearningSummaryTableData() {
    yield takeEvery(E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA, handleSummaryTableDataRequest);
}

export function* getELearningSummaryTableUUIDData() {
    yield takeEvery(E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA, handleSummaryTableUUIDDataRequest);
}

/**To get elearning raw data uuid */
export function* eLearningRawDataDownloadUUIDDownload() {
    yield takeEvery(E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID, handleHttpELearningRawDataDownloadUUID);
}

/**To get elearning raw data uuid status*/
export function* eLearningRawDataUUIDDownloadStatus() {
    yield takeEvery(E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS, handleHttpELearningRawDataDownloadUUIDStatus);
}

export default function* rootSaga() {
    yield all([
        fork(enrolmentGeneral),
        fork(enrolmentTopics),
        fork(enrolmentProportion),
        fork(enrolmentStatuses),
        fork(enrolmentBreakdown),
        fork(enrolmentCourses),
        fork(userActivityCompletionRatio),
        fork(userActivityQuizScore),
        fork(userActivityContentPopularity),
        fork(userActivityDifficulty),
        fork(comparisionRatioCourses),
        fork(comparisionRatioUsers),
        fork(comparisionGeneral),
        fork(comparisionOvertime),
        fork(comparisionContentAvgScore),
        fork(comparisionQuizScore),
        fork(comparisionRatioUsersUUIDData),
        fork(getELearningSummaryTableData),
        fork(getELearningSummaryTableUUIDData),
        fork(eLearningRawDataDownloadUUIDDownload),
        fork(eLearningRawDataUUIDDownloadStatus)
    ]);
}