import AppUtils from '../../../util/util';
import {
     ENABLE_MAINTENANCE_BANNER,
     SHOW_MAINTENANCE_BANNER,
     ENABLE_MAINTENANCE_PAGE,
     GET_DOWNTIME_DETAILS_SUCCESS,
     GET_DOWNTIME_DETAILS_ERROR,
     GET_DOWNTIME_DETAILS_INPROGRESS
} from '../../../constants/actions/adminSettings/maintenanceBanner/actionMaintenanceBanner';
import { HTTP_REQUEST_STATUS } from 'constants/constants';

const initialData = {
     enableBanner: false,
     showBanner: true,
     enableMaintenancePage: false,
     downTimeStartDate: '',
     diffInhrs: 0,
     downTimeDetailsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
}

const ReducerMaintenanceBanner = (state = initialData, action) => {

     switch (action.type) {

          case ENABLE_MAINTENANCE_BANNER: {
               return {
                    ...state,
                    enableBanner: action.data
               }
          }

          case SHOW_MAINTENANCE_BANNER: {
               return {
                    ...state,
                    showBanner: action.data
               }
          }

          case ENABLE_MAINTENANCE_PAGE: {
               return {
                    ...state,
                    enableMaintenancePage: action.data
               }
          }

          case GET_DOWNTIME_DETAILS_INPROGRESS: {
               return {
                    ...state,
                    downTimeDetailsFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
               }
          }

          case GET_DOWNTIME_DETAILS_SUCCESS: {

               let downTimeStartDate = '';
               let diffInhrs = 0;
               let enableBanner = state.enableBanner;

               /**If start and end date is present then enable the banner */
               if (typeof action.response !== 'undefined' &&
                    typeof action.response.data !== 'undefined') {

                    const startDate = typeof action.response.data.start !== 'undefined' ?
                         new Date(action.response.data.start) :
                         undefined;

                    const endDate = typeof action.response.data.end !== 'undefined' ?
                         new Date(action.response.data.end) :
                         undefined;

                    if (startDate && endDate) {

                         /**calculate the hrs of downtime */
                         diffInhrs = AppUtils.differenceBtwTwoDatesInHrs(startDate, endDate);

                         const formattedDate = new Intl.DateTimeFormat('en-UK', {
                              day: '2-digit',
                              month: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              timeZone: 'UTC'
                         }).format(startDate);
                         downTimeStartDate = formattedDate;

                         enableBanner = true;
                    }
               }
               return {
                    ...state,
                    downTimeStartDate: downTimeStartDate,
                    diffInhrs: diffInhrs,
                    enableBanner: enableBanner,
                    downTimeDetailsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS
               }
          }

          case GET_DOWNTIME_DETAILS_ERROR: {
               return {
                    ...state,
                    downTimeStartDate: '',
                    enableBanner: false,
                    downTimeDetailsFetchStatus: HTTP_REQUEST_STATUS.FAIL
               }
          }

          default:
               return state;
     }
};

export default ReducerMaintenanceBanner;