import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../../util/keys";

import {
    CONNECT_RAW_DATA_RESPONSES_DOWNLOAD, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID, CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS
} from "../../../../constants/ActionTypes";

import {
    URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD, URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID, URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID_STATUS,
} from "../../../../constants/Urls";

import AppUtils from "../../../../util/util";

import {
    connectRawdataDownloadResponsesSuccess,
    connectRawdataDownloadResponsesError, connectRawDataDownloadUUIDSuccess, connectRawDataDownloadUUIDError, connectRawDataDownloadUUIDStatusSuccess, connectRawDataDownloadUUIDStatusError
} from "../../../../actions";

/************ methods : http *******************/

/** http : connect reports raw data: To download the responses**/
const httpConnectRawDataResponsesDownload = async (data) =>{
    return await axios.post(baseUrl + URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD , data, AppUtils.getHttpHeaders())
       .then(authUser => authUser.data)
       .catch(error => {
           return error;
       });
}

/** http : connect reports raw data uuid**/
const httpConnectRawDataResponsesDownloadUUID = async (data) =>{
    return await axios.post(baseUrl + URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/** http : connect reports raw data uuid **/
const httpConnectRawDataResponsesDownloadUUIDStatus = async (data) =>{
    return await axios.post(baseUrl + URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID_STATUS , data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
};

/************ methods : http response handler *******************/

/** http : connect Rawdata response Download: http response handler **/
function* handleHttpConnectRawDataResponsesDownload({payload}) {
    try {
        let result = yield call(httpConnectRawDataResponsesDownload,payload);
        if ( AppUtils.isResponseSuccess(result)){
            yield put(connectRawdataDownloadResponsesSuccess(result.data));
        } else {
            yield put(connectRawdataDownloadResponsesError(result.message));
        }
    } catch (error) {
        yield put(connectRawdataDownloadResponsesError(error));
    }
}

/** http : connect raw data download : http response handler **/
function* handleHttpConnectRawDataDownloadUUID({payload}) {
    try {
        let result = yield call(httpConnectRawDataResponsesDownloadUUID,payload);
        if ( result.message){
            yield put(connectRawDataDownloadUUIDError(result.message));
        } else {
            yield put(connectRawDataDownloadUUIDSuccess(result));
        }
    } catch (error) {
        yield put(connectRawDataDownloadUUIDError(error));
    }
}

/** http : connect raw data download uuid : http response handler **/
function* handleHttpConnectRawDataDownloadUUIDStatus({payload}) {
    try {
        let result = yield call(httpConnectRawDataResponsesDownloadUUIDStatus,payload);
        if ( result.message ){
            yield put(connectRawDataDownloadUUIDStatusError(result.message));
        } else {
            yield put(connectRawDataDownloadUUIDStatusSuccess(result));
        }
    } catch (error) {
        yield put(connectRawDataDownloadUUIDStatusError(error));
    }
}

/************ methods : export access *******************/

/** Connect rawdata responses download **/
export function* connectReportsRawdataDownloadResponses() {
    yield takeEvery(CONNECT_RAW_DATA_RESPONSES_DOWNLOAD, handleHttpConnectRawDataResponsesDownload);
}

export function* connectRawDataDownloadUUID() {
    yield takeEvery(CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID, handleHttpConnectRawDataDownloadUUID);
}

export function* connectRawDataDownloadUUIDStatus() {
    yield takeEvery(CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS, handleHttpConnectRawDataDownloadUUIDStatus);
}

export default function* rootSaga() {
    yield all([
        fork(connectReportsRawdataDownloadResponses),
        fork(connectRawDataDownloadUUID),
        fork(connectRawDataDownloadUUIDStatus)
    ]);
}