import {
     ENABLE_MAINTENANCE_BANNER,
     SHOW_MAINTENANCE_BANNER,
     ENABLE_MAINTENANCE_PAGE,
     GET_DOWNTIME_DETAILS,
     GET_DOWNTIME_DETAILS_SUCCESS,
     GET_DOWNTIME_DETAILS_ERROR,
     GET_DOWNTIME_DETAILS_INPROGRESS
} from '../../../constants/actions/adminSettings/maintenanceBanner/actionMaintenanceBanner';

export function enableMaintenanceBanner(params) {
     return {
          type: ENABLE_MAINTENANCE_BANNER,
          data: params
     }
}

export function showMaintenanceBanner(params) {
     return {
          type: SHOW_MAINTENANCE_BANNER,
          data: params
     }
}

export function enableMaintenancePage(params) {
     return {
          type: ENABLE_MAINTENANCE_PAGE,
          data: params
     }
}

export function getDownTimeDetails() {
     return {
          type: GET_DOWNTIME_DETAILS
     }
}

export function getDownTimeDetailsInProgress() {
     return {
          type: GET_DOWNTIME_DETAILS_INPROGRESS
     }
}

export function getDownTimeDetailsSuccess(data) {
     return {
          type: GET_DOWNTIME_DETAILS_SUCCESS,
          response: data
     }
}

export function getDownTimeDetailsError(data) {
     return {
          type: GET_DOWNTIME_DETAILS_ERROR,
          response: data
     }
}