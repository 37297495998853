
import { call, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_CASE_OVERVIEW
} from "../../../constants/Urls";

import {
    CONNECT_API_GET_CASE_OVERVIEW_REQUEST,
    CONNECT_API_GET_CASE_OVERVIEW_SUCCESS,
    CONNECT_API_GET_CASE_OVERVIEW_FAILURE
} from '../../../constants/ActionTypes';

export function* getCaseOverview() {
    yield takeEvery(CONNECT_API_GET_CASE_OVERVIEW_REQUEST, connectGetCaseOverView);
}



const fetchConnectCaseOverview = async ({payload}) =>
    await axios.post(baseUrl+URL_CONNECT_CASE_OVERVIEW,payload, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => error);



// worker saga: makes the api call when watcher saga sees the action
export function* connectGetCaseOverView(payload) {
    try {
      const response = yield call(fetchConnectCaseOverview, payload);

        const {
            data,
            message
        } = response;
        if(data) {
            yield put({ type: CONNECT_API_GET_CASE_OVERVIEW_SUCCESS, caseOverView:data });
        }
        if(message) {
            yield put({ type: CONNECT_API_GET_CASE_OVERVIEW_FAILURE, error:message });
        }
        
    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: CONNECT_API_GET_CASE_OVERVIEW_FAILURE, error });
    }
}


