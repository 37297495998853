import IntlMessages from "../../../util/IntlMessages";
import React from "react";
import {
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_REPORTING_CATEGORY_SHOW_LOADER,
    SURVEY_REPORTING_CATEGORY_HIDE_LOADER,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SHOW_ERROR,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_HIDE_ERROR,
    SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP,
    SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR,
    SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR,
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS,
    SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE

} from "../../../constants/ActionTypes";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";

const surveyReportingCategoryHeaders = [
    {
        id: 'category_id',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_CATEGORY_ID"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_NAME"/>
    },
    {
        id: 'available_in',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_AVAILABLE_IN"/>
    },
    {
        id: 'actions',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_ACTIONS"/>
    }
];

const surveyReportingCategoryHeadersNonAdmin = [
    {
        id: 'category_id',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_CATEGORY_ID"/>
    },
    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_NAME"/>
    },
    {
        id: 'available_in',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SURVEY_REPORTING_CATEGORY_TBL_HEADER_AVAILABLE_IN"/>
    }
];

const initialData = {
    tableColumns: surveyReportingCategoryHeaders,
    tableColumnsNonAdmin: surveyReportingCategoryHeadersNonAdmin,
    surveyReportingCategories: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedReportingCategory: null,

    reportingCategorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    reportingCategoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    reportingCategoryUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    activeReportingCategoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyActiveReportingCategories: [],
    surveyActiveReportingCategoriesDropdown: [],
    alertMessageForReportingCategory: '',
    isSuccessMessageForReportingCategory: '',
    reportingCategoryLanguages: []

};

const ReducerSurveyReportingCategory = (state = initialData, action) => {
    switch (action.type) {

        case SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                reportingCategoryUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
            };

        case SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.error,
                isSuccessMessage: false,
                isErrorMessage: true,
            };

        case SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS:
            return {
                ...state,
                reportingCategoryUpdateStatusStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        case SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE:
            return {
                ...state,
                reportingCategoryUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS:
            return {
                ...state,
                reportingCategoryFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        case SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR:
            return {
                ...state,
                reportingCategoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS:
            let parsedReportingCategoryData = parseReportingCategoryData(action.payload.data.data);

            return {
                ...state,
                surveyReportingCategories: parsedReportingCategoryData,
                isSuccessMessage: false,
                isSuccessMessageForReportingCategory: false,
                isErrorMessage: false,
                reportingCategoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR:
            return {
                ...state,
                alertMessage: '',
                isSuccessMessage: false,
                isErrorMessage: false,
                reportingCategorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        case SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false,
                selectedCategory: null,
                alertMessage: '',
                isSuccessMessage: false,
                isSuccessMessageForReportingCategory: false,
                isErrorMessage: false,
                reportingCategorySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
            };

        case SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedReportingCategory: action.payload
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_HIDE_ERROR:
            return {
                ...state,
                alertMessage: '',
                isErrorMessage: false
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_SHOW_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                reportingCategorySaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.error,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: true,
                reportingCategorySaveStatus: HTTP_REQUEST_STATUS.FAIL,
                loader: false
            };

        case SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                alertMessageForReportingCategory: action.payload.data.message,
                isErrorMessage: false,
                isSuccessMessage: true,
                isSuccessMessageForReportingCategory: true,
                isPopUpVisible: false,
                selectedReportingCategory: null,
                reportingCategorySaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };

        case SURVEY_REPORTING_CATEGORY_SHOW_LOADER:
            return {
                ...state,
                loader: false
            };

        case SURVEY_REPORTING_CATEGORY_HIDE_LOADER:
            return {
                ...state,
                loader: false
            };

        case SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS:
            return {
                ...state,
                surveyActiveReportingCategories: action.payload.data.data,
                activeReportingCategoryFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                reportingCategoryLanguages: getReportingCategoryLanguages(action.payload.data.data)
            };

        case SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE:
            const data = getActiveReportingCategoriesByLanguageSelected(action.payload, state.surveyActiveReportingCategories);
            return {
                ...state,
                surveyActiveReportingCategoriesDropdown: data,
                activeReportingCategoryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };


        default:
            return state;
    }

};

function getActiveReportingCategoriesByLanguageSelected(languagesSelected, data) {
    let result = [];
    const firstSelectedLanguage = languagesSelected && languagesSelected.length > 0 ? languagesSelected[0].language_code : '';

    try {
        if (firstSelectedLanguage !== '') {
            for (let i = 0; i < data.length; i++) {
                const traslationObj = data[i].translations.find(translation => translation.language.language_code === firstSelectedLanguage);
                const categoryId = data[i]['category_id'];
                const label = traslationObj && (categoryId + ' - ' + traslationObj['translation']);
                if (typeof traslationObj !== "undefined") {
                    result.push({
                        key: data[i]['id'],
                        value: data[i]['id'],
                        label: label,
                        client_id: data[i]['client_id'],
                        category_id: data[i]['category_id'],
                        is_global : data[i]['is_global']
                    })
                }
            }
        }
    } catch (ex) {
        console.error(ex);
    }
    return result;
}

function parseReportingCategoryData(data) {
    let result = [];
    if (data) {
        try {
            for (let i = 0; i < data.length; i++) {

                let categoriesAvailable = []
                for (let j = 0; j < data[i].translations.length; j++) {
                    categoriesAvailable.push([data[i].translations[j].language.name.substring(0, 3)])
                }
                data[i]['available_in'] = [categoriesAvailable.length, [categoriesAvailable].toString()];

            }
            result = data
        } catch (e) {
            console.error(e)
        }
        ;

        return result;
    }

}

function getReportingCategoryLanguages(data){
    let result = [];
    try{
        for (let i = 0; i < data.length; i++) {
            data[i].translations.map(translation => {
                result.push(translation.language.language_code)
            });
        }

        result = [...new Set(result)];

    }catch (ex) {
        console.error(ex);
    }
    return result;

}


export default ReducerSurveyReportingCategory;

