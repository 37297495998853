import React from 'react';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import IntlMessages from "../../util/IntlMessages";

const InternetError = () => {
  return (
    <div style={{ margin: "0 auto" }}>
      <div style={{ textAlign: 'center' }}>
        <div style={{ margin: "0 auto ", textAlign: 'center' }}> <WifiOffIcon style={{ fontSize: 130 }} /> </div>

        <h2 style={{ fontWeight: 'bold' }}>
          <IntlMessages id="DIC_SURVEY_RESPONSE_NO_INTERNET" /> </h2> </div>
      <div className='row mb-2 align-items-center'>

        <IntlMessages id="DIC_SURVEY_RESPONSE_ERROR_MSG" /> </div>
      <div className='row mb-2 align-items-center  '>
        <CheckCircleOutlineRoundedIcon />
        <div className='pr-4'>
          <IntlMessages id="DIC_SURVEY_RESPONSE_ERROR_MSG1" /></div></div>
      <div className='row  mb-2 align-items-center '>
        <CheckCircleOutlineRoundedIcon />
        <div className='pr-4'>
          <IntlMessages id="DIC_SURVEY_RESPONSE_ERROR_MSG2" /></div>

      </div>
    </div>
  );
};

export default InternetError;