import {
    SURVEY_QUESTION_BANK_GET_ALL_RECORDS,
    SURVEY_QUESTION_BANK_GET_ALL_RECORDS_ERROR,
    SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS,
    SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS,

    SURVEY_QUESTION_BANK_NEW_EDIT_SAVE,
    SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR,
    SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE,
    SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS,

    SURVEY_QUESTION_BANK_UPDATE_STATUS,
    SURVEY_QUESTION_BANK_UPDATE_STATUS_ERROR,
    SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE,
    SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS,

    SURVEY_QUESTION_BANK_SET_STEP,
    SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED,
    SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED,

    SURVEY_QUESTION_BANK_UPLOAD,
    SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS,
    SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR,
    SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE

} from "../../../constants/ActionTypes";

export function disabledEditQuestionBank() {
    return {
        type: SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED
    };
}

/****
  Survey template : to set current template
 ****/
  export function setSurveyQuestionBank(params) {
    return {
        type: SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED,
        payload : params
    };
}

/****
  Survey template : to set current template
 ****/
  export function setSurveyQuestionBankStep(params) {
    return {
        type: SURVEY_QUESTION_BANK_SET_STEP,
        payload : params
    };
}

/****
 Survey question bank : to get all records
 ****/
export function getSurveyQuestionBank(params) {
    return {
        type: SURVEY_QUESTION_BANK_GET_ALL_RECORDS,
        payload : params
    };
}

/****
 Survey question bank : to get all records : error
 ****/
export function getSurveyQuestionBankError(params) {
    return {
        type: SURVEY_QUESTION_BANK_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}

/****
 Survey question bank : to get all records : success
 ****/
export function getSurveyQuestionBankSuccess(params) {
    return {
        type: SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Survey question bank : to get all active records : success
 ****/
export function getSurveyQuestionBankActiveSuccess(params) {
    return {
        type: SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS,
        payload : params
    };
}


/**** Survey question bank : save action ****/
export function saveSurveyQuestionBank( params ) {
    return {
        type: SURVEY_QUESTION_BANK_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Survey question bank : save success action ****/
export function saveSurveyQuestionBankSuccess(data) {
    return {
        type: SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Survey question bank : save error action ****/
export function saveSurveyQuestionBankError(data) {
    return {
        type: SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Survey question bank : save in progress action ****/
export function saveSurveyQuestionBankNone() {
    return {
        type: SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE
    };
}

/****
 Survey question bank : status update
 ****/
export function surveyQuestionBankUpdateStatus(params) {
    return {
        type: SURVEY_QUESTION_BANK_UPDATE_STATUS,
        payload : params
    };
}

/****
 Survey question bank : status update to none
 ****/
export function surveyQuestionBankUpdateStatusNone() {
    return {
        type: SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE
    };
}

/****
 Survey question bank : status update : success
 ****/
export function surveyQuestionBankUpdateStatusSuccess(params) {
    return {
        type: SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}

/****
 Survey question bank : status update : error
 ****/
export function surveyQuestionBankUpdateStatusError(params) {
    return {
        type: SURVEY_QUESTION_BANK_UPDATE_STATUS_ERROR,
        payload : params
    };
}

/****
 * To upload Question bank excel file
 ****/
 export function uploadQuestionBankFile( params ) {
    return {
        type: SURVEY_QUESTION_BANK_UPLOAD,
        payload : params
    };
}

/****
 * To upload Question bank excel file : success
 ****/
export function uploadQuestionBankFileSaveSuccess( params ) {
    return {
        type: SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS,
        payload : params
    };
}

/****
 * To upload Question bank excel file : error
 ****/
export function uploadQuestionBankFileSaveError( params ) {
    return {
        type: SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR,
        payload : params
    };
}

/****
 * To upload Question bank excel file : none
 ****/
export function uploadQuestionBankFileSaveNone( params ) {
    return {
        type: SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE,
        payload : params
    };
}
