import {
     GET_BRAND_CHILD_CLIENT_COUNTRIES_SUCCESS,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_ERROR,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_NONE,
     GET_BRAND_CHILD_CLIENT_COUNTRIES_INPROGRESS,
     GET_BRAND_CHILD_CLIENT_COUNTRIES
} from '../../constants/actions/countries/actionConfigCountries';
import { HTTP_REQUEST_STATUS } from "../../constants/constants";

const initialData = {
     childClientCountries: [],
     childClientCountriesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
     alertMessage: ''
};

const ReducerCountries = (state = initialData, action) => {

     switch (action.type) {

          /**Set state on fetch in progress */
          case GET_BRAND_CHILD_CLIENT_COUNTRIES:{
               return {
                    ...state,
                    childClientCountriesFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
               }
          }

          /**Set state on fetch error */
          case GET_BRAND_CHILD_CLIENT_COUNTRIES_ERROR: {

               let errorMessage = ''

               if (action.payload) {
                    if (action.payload.error) {
                         errorMessage = action.payload.error;
                    }
                    else {
                         errorMessage = action.payload;
                    }
               }
               return {
                    ...state,
                    childClientCountriesFetchStatus: HTTP_REQUEST_STATUS.FAIL,
                    alertMessage: errorMessage
               }
          }

          /**Set state on fetch success */
          case GET_BRAND_CHILD_CLIENT_COUNTRIES_SUCCESS: {

               let childClientCountries = [];
               if (typeof action.payload !== 'undefined' && typeof action.payload.data !== 'undefined') {
                    childClientCountries = processCountryData(action.payload.data['countries']);
               }

               return {
                    ...state,
                    childClientCountries: childClientCountries.sort((a, b) => a.name.localeCompare(b.name)),
                    childClientCountriesFetchStatus: HTTP_REQUEST_STATUS.SUCCESS
               }
          }

          /**Set state back to initial */
          case GET_BRAND_CHILD_CLIENT_COUNTRIES_NONE: {
               return {
                    ...state,
                    childClientCountriesFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
               }
          }

          default:
               return state;
     }
};

function processCountryData(data) {
     let result = [];
     
     try {
          if (typeof data !== 'undefined' && data.length > 0) {
               for (let i = 0; i < data.length; i++) {
                    result.push({
                         "id": data[i]["code"],
                         "key": data[i]["code"],
                         "name": data[i]["name"]
                    });
               }
          }
     } catch (err) {
          console.error('processCountryData : error', err);
     }
     
     return result;
}

export default ReducerCountries;
