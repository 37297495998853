import React from 'react';

const LSOverflowMenu = ({params}) => {

    let subContainerClick = (event) =>{
        if ( event ) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    let renderHeader = () => {
        return(
            <div className="ls_overflow_menu_header">
                <div className="ls_overflow_menu_header_back_button hand" onClick={() => params.handleRequestClose && params.handleRequestClose(params['key'])}>
                </div>
                <div className="ls_overflow_menu_header_title">
                    {params.header && params.header}
                </div>
            </div>
        )
    }
    
    
    return (
        
        <div key={params['key']} className={"ls_overflow_menu"} onClick={() => params.handleRequestClose && params.handleRequestClose(params['key'])}>
            <div className={"ls_overflow_menu_content " + (params["isRight"] ? "ls_overflow_menu_content_right" : "ls_overflow_menu_content_left")} onClick={subContainerClick}>
                {renderHeader()}
                <div className="ls_overflow_menu_margin">
                    {params.content && params.content()}
                </div>
            
            </div>
        </div>
    )
};

export default LSOverflowMenu;
