import React from 'react';
import IntlMessages from "../../util/IntlMessages";

const IconListItem = ({iconClass, headerText, subHeaderText, subHeaderContent}) => {
    
    return (
        <div>
            <span className={"float_right"}>
                {headerText && headerText !== "" && <div className={"menu_popover_item_header"}><IntlMessages id={headerText}/></div>}
                {subHeaderText && subHeaderText !== "" && <div className={"menu_popover_item_sub_header"}><IntlMessages id={subHeaderText}/></div>}
                {subHeaderContent && subHeaderContent}
            </span>
            
            {iconClass && iconClass !== "" && <span className={'menu_popover_item_icon ' + iconClass}/>}
        
        </div>
    )
};

export default IconListItem;
