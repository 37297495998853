import React from 'react';
import { connect } from 'react-redux';
import { MANULA_LINK_FOOTER_COLOR } from 'constants/customUIConfig';
import { JIRA_WIDGET_SCRIPT_SRC, MANULA_URL } from 'constants/config';
import IntlMessages from 'util/IntlMessages';

class JiraWidget extends React.Component {
    componentDidMount() {
        try {
            const widgetElement = document.getElementById('jsd-widget');
            if(widgetElement == null) {
                this.jiraHelpdesk(() => {
                    var DOMContentLoaded_event = document.createEvent('Event');
                    DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
                    window.document.dispatchEvent(DOMContentLoaded_event);
                });
            }
        }
        catch (ex) {
            console.error("componentDidMount : ERROR : ", ex);
        }
    };

    /**To create script element for JIRA widget */
    jiraHelpdesk = (callback) => {
        try {
            var jhdScript = document.createElement('script');
            jhdScript.type = 'text/javascript';
            jhdScript.setAttribute('data-jsd-embedded', null);
            jhdScript.setAttribute('data-key', JIRA_WIDGET_SCRIPT_SRC.DATA_KEY);
            jhdScript.setAttribute('data-base-url', JIRA_WIDGET_SCRIPT_SRC.DATA_BASE_URL);
            jhdScript.src = JIRA_WIDGET_SCRIPT_SRC.SOURCE;

            if (jhdScript.readyState) { // old IE support
                jhdScript.onreadystatechange = () => {
                    if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
                        jhdScript.onreadystatechange = null;
                        callback();
                    }
                };
            }
            else { //modern browsers
                jhdScript.onload = () => {
                    callback();
                    this.iframeStyle();
                };
            }
            document.getElementById('jiraWidget').appendChild(jhdScript);
        }
        catch (ex) {
            console.error("jiraHelpdesk : ERROR : ", ex);
        }
    };

    /**To get Manula url and text details based on selectd client */
    getManulaDetails = () => {
        let url = '';
        let text = '';

        try {
            if (this.props.isClientIsBrand) {
                url = MANULA_URL.BRAND_USER;
                text = <IntlMessages id="DIC_FOOTER_MANULA_CONTENT_BRAND_USER" />;
            } else {
                url = MANULA_URL.COMPANY_USER;
                text = <IntlMessages id="DIC_FOOTER_MANULA_CONTENT_COMPANY_USER" />;
            }
        }
        catch (ex) {
            console.error("getManulaDetails : ERROR : ", ex);
        }

        return {
            url: url,
            text: text
        }
    };
    iframeStyle = () => {
        try{
            let iframe = document.getElementById('jsd-widget');
            let style = document.createElement('style');
            style.textContent =
                '#button-container #help-button.icon {' +
                '   width: 32px !important;' +
                '   height: 32px !important;' +
                '   font-size: 22px !important;' +
                '   margin-bottom: -7px !important;'+
                '}' +
                '#button-container #help-button {'+
                '   line-height: 32px !important;' +
                '}'
            ;
            iframe.contentDocument.head.appendChild(style);

        }catch (ex){
            console.error("iframeStyle : error : ", ex );
        }
}

    render() {
        const details = this.getManulaDetails();

        return (
            <>
                <div className="row justify-content-center" style={styles.manulaFooter}>
                    <a href={details['url']} target='_blank' rel="noopener noreferrer">{details['text']}</a>
                </div>
                <div id="jiraWidget"></div>
            </>
        )
    }
}

const mapStateToProps = ({ ReducerClients }) => {
    const {
        isClientIsBrand
    } = ReducerClients;

    return {
        isClientIsBrand
    }
};

const styles = ({
    manulaFooter: { 
        backgroundColor: MANULA_LINK_FOOTER_COLOR, 
        fontSize: '12px',
        marginBottom: '18.5px'
    }
});

export default connect(mapStateToProps, {})(JiraWidget);