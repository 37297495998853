
import { call, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_GET_MESSAGE_COUNT
} from "../../../constants/Urls";

import {
    CONNECT_API_GET_MESSAGE_COUNT_REQUEST,
    CONNECT_API_GET_MESSAGE_COUNT_SUCCESS,
    CONNECT_API_GET_MESSAGE_COUNT_FAILURE
} from '../../../constants/ActionTypes';

export function* getMessageCount() {
    yield takeEvery(CONNECT_API_GET_MESSAGE_COUNT_REQUEST, connectGetMessageCount);
}

const fetchConnectGetMessageCount = async ({payload}) =>
    await axios.post(baseUrl+URL_CONNECT_GET_MESSAGE_COUNT, payload, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => error)
// worker saga: makes the api call when watcher saga sees the action
export function* connectGetMessageCount(payload) {
    try {
      const response = yield call(fetchConnectGetMessageCount, payload);
      const {
          data,
          message
      } = response;
      if(data) {
        yield put({ type: CONNECT_API_GET_MESSAGE_COUNT_SUCCESS, messageCount:data });
      }
      if(message) {
        yield put({ type: CONNECT_API_GET_MESSAGE_COUNT_FAILURE, error:message });
      }
    
    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: CONNECT_API_GET_MESSAGE_COUNT_FAILURE, error });
    }
}


