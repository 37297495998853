import {
    ADMIN_CONTROLS_GET_SURVEY_DETAILS,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE,
    ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS,
    ADMIN_CONTROLS_SET_SURVEY_STATUS,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE,
    ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE,
    ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE,
    ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR,
    ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS,
    ADMIN_CONTROLS_SURVEY_RESPONSES_RESET,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS,
    ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT,
    ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE,
    ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR,
    ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS,
    ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID
} from "../../../constants/actions/adminControls/survey/actionAdminControls";

/**** admin controls : get survey details ****/
export function adminControlsGetSurveyDetails(params) {
    return {
        type: ADMIN_CONTROLS_GET_SURVEY_DETAILS,
        payload: params
    };
}

/**** admin controls : get survey details : success  ****/
export function adminControlsGetSurveyDetailsSuccess(data) {
    return {
        type: ADMIN_CONTROLS_GET_SURVEY_DETAILS_SUCCESS,
        payload: data
    };
}


/**** admin controls : get survey details : error  ****/
export function adminControlsGetSurveyDetailsError() {
    return {
        type: ADMIN_CONTROLS_GET_SURVEY_DETAILS_ERROR
    };
}

/**** admin controls : get survey details : none  ****/
export function adminControlsGetSurveyDetailsNone() {
    return {
        type: ADMIN_CONTROLS_GET_SURVEY_DETAILS_NONE
    };
}

/**** admin controls : set survey status ****/
export function adminControlsSetSurveyStatus(params) {
    return {
        type: ADMIN_CONTROLS_SET_SURVEY_STATUS,
        payload: params
    };
}

/**** admin controls : set survey status : success  ****/
export function adminControlsSetSurveyStatusSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SET_SURVEY_STATUS_SUCCESS,
        payload: data
    };
}


/**** admin controls : set survey status : error  ****/
export function adminControlsSetSurveyStatusError() {
    return {
        type: ADMIN_CONTROLS_SET_SURVEY_STATUS_ERROR
    };
}

/**** admin controls : set survey status : none  ****/
export function adminControlsSetSurveyStatusNone() {
    return {
        type: ADMIN_CONTROLS_SET_SURVEY_STATUS_NONE
    };
}

/**** admin controls : survey : get duplicate responses ****/
export function adminControlsGetSurveyDuplicateResponses(params) {
    return {
        type: ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES,
        payload: params
    };
}

/**** admin controls : survey : get duplicate responses : success  ****/
export function adminControlsGetSurveyDuplicateResponsesSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : get duplicate responses : error  ****/
export function adminControlsGetSurveyDuplicateResponsesError() {
    return {
        type: ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_ERROR
    };
}

/**** admin controls : survey : get duplicate responses : none  ****/
export function adminControlsGetSurveyDuplicateResponsesNone() {
    return {
        type: ADMIN_CONTROLS_SURVEY_GET_DUPLICATE_RESPONSES_NONE
    };
}

/**** admin controls : survey : download all responses ****/
export function adminControlsDownloadAllSurveyResponses(params) {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD,
        payload: params
    };
}

/**** admin controls : survey : download all responses : success  ****/
export function adminControlsDownloadAllSurveyResponsesSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : download all responses : error  ****/
export function adminControlsDownloadAllSurveyResponsesError() {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_ERROR
    };
}

/**** admin controls : survey : download all responses : none  ****/
export function adminControlsDownloadAllSurveyResponsesNone() {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_NONE
    };
}


/**** admin controls : survey : download all responses uuid ****/
export function adminControlsDownloadAllSurveyResponsesStatus(params) {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS,
        payload: params
    };
}

/**** admin controls : survey : download all responses uuid : success  ****/
export function adminControlsDownloadAllSurveyResponsesStatusSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : download all responses uuid : error  ****/
export function adminControlsDownloadAllSurveyResponsesStatusError() {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_ERROR
    };
}

/**** admin controls : survey : download all responses uuid : none  ****/
export function adminControlsDownloadAllSurveyResponsesStatusNone() {
    return {
        type: ADMIN_CONTROLS_SURVEY_ALL_RAW_DATA_DOWNLOAD_STATUS_NONE
    };
}

/**** admin controls : survey : update demo graphic details ****/
export function adminControlsUpdateSurveyDemoGraphicDetails(params) {
    return {
        type: ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS,
        payload: params
    };
}

/**** admin controls : survey : update demo graphic details : success  ****/
export function adminControlsUpdateSurveyDemoGraphicDetailsSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : update demo graphic details : error  ****/
export function adminControlsUpdateSurveyDemoGraphicDetailsError() {
    return {
        type: ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_ERROR
    };
}

/**** admin controls : survey : update demo graphic details : none  ****/
export function adminControlsUpdateSurveyDemoGraphicDetailsNone() {
    return {
        type: ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS_NONE
    };
}

/**** admin controls : survey : to increase survey response count ****/
export function adminControlsIncreaseSurveyResponsesByCount(params) {
    return {
        type: ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT,
        payload: params
    };
}

/**** admin controls : survey : to increase survey response count : success  ****/
export function adminControlsIncreaseSurveyResponsesByCountSuccess(data) {
    return {
        type: ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : to increase survey response count : error  ****/
export function adminControlsIncreaseSurveyResponsesByCountError() {
    return {
        type: ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_ERROR
    };
}

/**** admin controls : survey : to increase survey response count : none  ****/
export function adminControlsIncreaseSurveyResponsesByCountNone() {
    return {
        type: ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT_NONE
    };
}

/**** admin controls : survey : to reset survey cached data ****/
export function adminControlsResetSurveyCachedData(params) {
    return {
        type: ADMIN_CONTROLS_SURVEY_RESPONSES_RESET,
        payload: params
    };
}

/**** admin controls : survey : to reset survey cached data : success  ****/
export function adminControlsResetSurveyCachedDataSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : to reset survey cached data : error  ****/
export function adminControlsResetSurveyCachedDataError() {
    return {
        type: ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_ERROR
    };
}

/**** admin controls : survey : to reset survey cached data : none  ****/
export function adminControlsResetSurveyCachedDataNone() {
    return {
        type: ADMIN_CONTROLS_SURVEY_RESPONSES_RESET_NONE
    };
}

/**** admin controls : survey : to save survey response by request id ****/
export function adminControlsSaveSurveyResponseByRequestId(params) {
    return {
        type: ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID,
        payload: params
    };
}

/**** admin controls : survey : to save survey response by request id : success  ****/
export function adminControlsSaveSurveyResponseByRequestIdSuccess(data) {
    return {
        type: ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_SUCCESS,
        payload: data
    };
}


/**** admin controls : survey : to save survey response by request id : error  ****/
export function adminControlsSaveSurveyResponseByRequestIdError() {
    return {
        type: ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_ERROR
    };
}

/**** admin controls : survey : to save survey response by request id : none  ****/
export function adminControlsSaveSurveyResponseByRequestIdNone() {
    return {
        type: ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID_NONE
    };
}
