import {
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS,

    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR,
    CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS
} from '../../../../constants/ActionTypes';

/** Connect  : Action to Download Summary Table UUID in progess */
export function conncetSummaryTableDownloadUUIDInProgress(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table UUID */
export function conncetSummaryTableDownloadUUID(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table UUID in error */
export function conncetSummaryTableDownloadUUIDError(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table UUID on success */
export function conncetSummaryTableDownloadUUIDSuccess(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table UUID to reset */
export function conncetSummaryTableDownloadUUIDNone() {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE
    };
}

/** Connect  : Action to Download Summary Table uuid data */
export function conncetSummaryTableDownloadUUIDData(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table uuid data */
export function conncetSummaryTableDownloadUUIDDataSuccess(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS,
        payload: params
    };
}

/** Connect  : Action to Download Summary Table uuid data */
export function conncetSummaryTableDownloadUUIDDataError(params) {
    return {
        type: CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR,
        payload: params
    };
}