export const CLIENT_MODULE_KEYS = {
    "LS_MODULE_EXECUTIVE_REPORTS": "LS_MODULE_EXECUTIVE_REPORTS",
    "LS_MODULE_CONNECT": "LS_MODULE_CONNECT",
    "LS_MODULE_CONNECT_REPORTS": "LS_MODULE_CONNECT_REPORTS",
    "LS_MODULE_CONNECT_REPORTS_RAW_DATA": "LS_MODULE_CONNECT_REPORTS_RAW_DATA",
    "LS_MODULE_SURVEY": "LS_MODULE_SURVEY",
    "LS_MODULE_SURVEY_REPORT": "LS_MODULE_SURVEY_REPORT",
    "LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT": "LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT",
    "LS_MODULE_SURVEY_REPORT_RAW_DATA": "LS_MODULE_SURVEY_REPORT_RAW_DATA",
    "LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT_RAW_DATA": "LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT_RAW_DATA",
    "LS_MODULE_E_LEARNING": "LS_MODULE_E_LEARNING",
    "LS_MODULE_E_LEARNING_REPORTS": "LS_MODULE_E_LEARNING_REPORTS",
    "LS_MODULE_E_LEARNING_REPORTS_RAW_DATA": "LS_MODULE_E_LEARNING_REPORTS_RAW_DATA",
    "LS_MODULE_COMPANY_POST": "LS_MODULE_COMPANY_POST",
    "LS_MODULE_COMPANY_POST_REPORTS": "LS_MODULE_COMPANY_POST_REPORTS",
    "LS_MODULE_COMPANY_POST_RAW_DATA": "LS_MODULE_COMPANY_POST_RAW_DATA",
    "LS_MODULE_WORKER_CONTACTS": "LS_MODULE_WORKER_CONTACTS",
    "LS_MODULE_PAYSLIP": "LS_MODULE_PAYSLIP",
    "LS_MODULE_ACCOUNTS": "LS_MODULE_ACCOUNTS",
    "LS_MODULE_MANUAL_CASE": "LS_MODULE_MANUAL_CASE"
};