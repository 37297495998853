import {
    TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP,
    TRANSLATION_MODULE_GET_ALL_RECORDS,
    TRANSLATION_MODULE_GET_ALL_RECORDS_ERROR,
    TRANSLATION_MODULE_GET_ALL_RECORDS_IN_PROGRESS,
    TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS,
    TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP,
    TRANSLATION_MODULE_NEW_EDIT_SAVE,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE,
    TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS,
    TRANSLATION_MODULE_UPDATE_STATUS,
    TRANSLATION_MODULE_UPDATE_STATUS_ERROR,
    TRANSLATION_MODULE_UPDATE_STATUS_IN_PROGRESS,
    TRANSLATION_MODULE_UPDATE_STATUS_NONE,
    TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS,
    TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS,
    TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_ERROR,
    TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_NONE,
    TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ERROR,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_NONE,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_SUCCESS,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_ERROR,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_NONE,
    TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_SUCCESS,
    GET_TIMEZONE_LIST_SUCCESS
} from "../../../../constants/ActionTypes";

/****
 Translation Module : to get all records
 ****/
export function getTranslationModule() {
    return {
        type: TRANSLATION_MODULE_GET_ALL_RECORDS
    };
}

/****
 Translation Module : to get all records in progress
 ****/

export function getTranslationModuleInProgress() {
    return {
        type: TRANSLATION_MODULE_GET_ALL_RECORDS_IN_PROGRESS
    };
}

/****
 Translation Module : to get all records :error
 ****/
export function getTranslationModuleError(params) {
    return {
        type: TRANSLATION_MODULE_GET_ALL_RECORDS_ERROR,
        payload : params
    };
}


/****
 Translation Module : to get all records : Success
 ****/
export function getTranslationModuleSuccess(params) {
    return {
        type: TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS,
        payload : params
    };
}

/****
 Translation Module : to display new edit popup
 ****/

export function displayNewEditTranslationModulePopup( params ) {
    return {
        type: TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
 Translation Module : to hide new edit popup
 ****/

export function hideNewEditTranslationModulePopup( params ) {
    return {
        type: TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP,
        payload : params
    };
}


/****
  Translation Module : save action 
 *****/
export function saveTranslationModule( params ) {
    return {
        type: TRANSLATION_MODULE_NEW_EDIT_SAVE,
        payload : params
    };
}

/**** Translation Module : save success action
  *****/

export function saveTranslationModuleSuccess(data) {
    return {
        type: TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS,
        payload: data
    };
}

/**** Translation Module : save error action 
 *****/
export function saveTranslationModuleError(data) {
    return {
        type: TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR,
        payload: data
    };
}

/**** Translation Module : save in progress action 
 *****/

export function saveTranslationModuleProgress() {
    return {
        type: TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS,
    };
}

/**** Translation Module : save None 
 *****/

export function saveTranslationModuleNone() {
    return {
        type: TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE,
    };
}

/****
 Translation Module  : status update
 ****/

export function translationModuleUpdateStatus(params) {
    return {
        type: TRANSLATION_MODULE_UPDATE_STATUS,
        payload : params
    };
}

/****
 Translation Module  : status update in progress
 ****/
export function translationModuleUpdateStatusInProgress() {
    return {
        type: TRANSLATION_MODULE_UPDATE_STATUS_IN_PROGRESS
    };
}

/****
  Translation Module  : Update status to None
 ****/
export function translationModuleUpdateStatusNone() {
    return {
        type: TRANSLATION_MODULE_UPDATE_STATUS_NONE
    };
}

/****
   Translation Module : status update : success
 ****/
export function translationModuleUpdateStatusSuccess(params) {
    return {
        type: TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS,
        payload : params
    };
}


/****
  Translation Module : status update : error
 ****/
export function translationModuleUpdateStatusError(params) {
    return {
        type: TRANSLATION_MODULE_UPDATE_STATUS_ERROR,
        payload : params
    };}

/****
 Survey translations  : to get all survey translations
 ****/

export function getAllSurveyTranslations(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS,
        payload : params
    };
}

/****
 Survey translations  : to get all survey translations : success
 ****/
export function getAllSurveyTranslationsSuccess(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS,
        payload : params
    };
}

export function getTimezoneListSuccess(params) {
    return {
        type: GET_TIMEZONE_LIST_SUCCESS,
        payload: params
    }
}


/****
 Survey translations  : to get all survey translations : error
 ****/
export function getAllSurveyTranslationsError(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_ERROR,
        payload : params
    };
}

/****
 Survey translations  : to get all survey translations : none
 ****/
export function getAllSurveyTranslationsNone() {
    return {
        type: TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_NONE
    };
}

/****
 Survey translations  : to get survey translations by language
 ****/

export function getAllSurveyTranslationsByLanguage(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE,
        payload : params
    };
}

/****
 Survey translations  : to get survey translations by language : success
 ****/
export function getAllSurveyTranslationsByLanguageSuccess(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_SUCCESS,
        payload : params
    };
}


/****
 Survey translations  : to get survey translations by language : error
 ****/
export function getAllSurveyTranslationsByLanguageError(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ERROR,
        payload : params
    };
}

/****
 Survey translations  : to get survey translations by language : none
 ****/
export function getAllSurveyTranslationsByLanguageNone() {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_NONE
    };
}

/****
 Survey translations  : to get survey translations by survey id
 ****/

export function getAllSurveyTranslationsBySurveyId(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID,
        payload : params
    };
}

/****
 Survey translations  : to get survey translations by survey id : success
 ****/
export function getAllSurveyTranslationsBySurveyIdSuccess(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_SUCCESS,
        payload : params
    };
}


/****
 Survey translations  : to get survey translations by survey id : error
 ****/
export function getAllSurveyTranslationsBySurveyIdError(params) {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_ERROR,
        payload : params
    };
}

/****
 Survey translations  : to get survey translations by survey id : none
 ****/
export function getAllSurveyTranslationsBySurveyIdNone() {
    return {
        type: TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_NONE
    };
}
