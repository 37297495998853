import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../util/keys";

import {
    TRANSLATION_SUPPORTED_LANG_GET_LIST,
    TRANSLATION_SUPPORTED_LANG_SAVE,
    TRANSLATION_SUPPORTED_SAVED_LANG_DELETE, TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS, TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE, TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID,
    GET_TIMEZONE_LIST
}
    from "../constants/ActionTypes";


import {
    saveSupportedLanguageSuccess,
    showErrorMessage,
    deleteSavedSupportedLanguage,
    getSupportedLanguagesSuccess
}
    from "../actions/Translations";

import {
    HTTP_TIMEOUT, URL_TRANSLATIONS_GET_ALL_SURVEY_TRANSLATIONS, URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ID, URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID,
    URL_TRANSLATIONS_LANGUAGE_CREATE,
    URL_TRANSLATIONS_LANGUAGE_LIST,
    URL_TIMEZONE_LIST,
}
    from "../constants/Urls"
import {getAllSurveyTranslationsByLanguageError, getAllSurveyTranslationsByLanguageSuccess, getAllSurveyTranslationsBySurveyIdError, getAllSurveyTranslationsBySurveyIdSuccess, getAllSurveyTranslationsError, getAllSurveyTranslationsSuccess, getTimezoneListSuccess} from "../actions/adminSettings/translations/modules/ActionsTranslationsModules";
import AppUtils from "../util/util";

const default_config = {
    timeout: HTTP_TIMEOUT
}

/************************************************************************************************ https ************************************************************************************************/

/****
 Https : get supported languages
 ****/
const httpGetSupportedLanguage = async (data) =>
    await axios.get(baseUrl + URL_TRANSLATIONS_LANGUAGE_LIST, data, default_config)
        .then(authUser => authUser)
        .catch(error => error);

/****
 Https : supported language save
 ****/
const httpSaveSupportedLanguage = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_LANGUAGE_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/*****
 Https: Edit Saved Language * ******/

const httpUpdateSavedLanguage = async (data) =>
    await axios.patch(baseUrl + URL_TRANSLATIONS_LANGUAGE_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/***
 Https: Delete the saved Language
 * ***/

const httpDeleteSavedLanguage = async (data) =>
    await axios.patch(baseUrl + URL_TRANSLATIONS_LANGUAGE_CREATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/***
 Http: to get all survey translations by survey id
 * ***/

const httpFetchAllSurveyTranslationsBySurveyId = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);

/***
 Http: to get all survey translations by language code
 * ***/

const httpFetchAllSurveyTranslationsByLanguageCode = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


const httpFetchAllTimezones = async () =>  {
    return await axios.get(baseUrl + URL_TIMEZONE_LIST, AppUtils.getHttpHeaders())
        .then(response => response.data.map(item => {
            const timezone = Object.keys(item)[0]
            return ({key: timezone, value: timezone, label: `${timezone} (${item[timezone]})`})
        }))
        .catch(_error => []);
}
/***
 Http: to get all survey translations
 * ***/

const httpFetchAllSurveyTranslations = async (data) =>
    await axios.post(baseUrl + URL_TRANSLATIONS_GET_ALL_SURVEY_TRANSLATIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => error);


/************************************************************************************************ handlers ************************************************************************************************/


/****
 Handler : get supported language
 ****/
function* handleGetSupportedLanguage({payload}) {
    try {

        let dataValue = yield call(httpGetSupportedLanguage, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(getSupportedLanguagesSuccess(dataValue.data));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/****
 Handler : supported language save
 ****/
function* handleSaveSupportedLanguage({payload}) {
    try {

        let dataValue = yield call(payload.id !== -1 ? httpUpdateSavedLanguage : httpSaveSupportedLanguage, payload);

        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.response.data.message));
        } else {
            yield put(saveSupportedLanguageSuccess(dataValue));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

/*****
 * Handler: Delete Saved Language
 * ****/

function* handleDeleteSavedSupportedLanguage({payload}) {
    try {
        let dataValue = yield call(httpDeleteSavedLanguage, payload);
        if (dataValue.message) {
            yield put(showErrorMessage(dataValue.message));
        } else {
            yield put(deleteSavedSupportedLanguage(dataValue));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }

}

/*****
 * Handler: to get survey translations by survey id
 * ****/

function* handleFetchAllSurveyTranslationsBySurveyId({payload}) {
    try {
        let dataValue = yield call(httpFetchAllSurveyTranslationsBySurveyId, payload);
        if (dataValue.message) {
            yield put(getAllSurveyTranslationsBySurveyIdError(dataValue.message));
        } else {
            yield put(getAllSurveyTranslationsBySurveyIdSuccess(dataValue));
        }
    } catch (error) {
        yield put(getAllSurveyTranslationsBySurveyIdError(error));
    }

}

/*****
 * Handler: to get survey translations by language code
 * ****/

function* handleFetchAllSurveyTranslationsByLanguage({payload}) {
    try {
        let dataValue = yield call(httpFetchAllSurveyTranslationsByLanguageCode, payload);
        if (dataValue.message) {
            yield put(getAllSurveyTranslationsByLanguageError(dataValue.message));
        } else {
            yield put(getAllSurveyTranslationsByLanguageSuccess(dataValue));
        }
    } catch (error) {
        yield put(getAllSurveyTranslationsByLanguageError(error));
    }
}

/*****
 * Handler: to get survey translations by language code
 * ****/
function* handleFetchAllSurveyTranslations({payload}) {
    try {
        let dataValue = yield call(httpFetchAllSurveyTranslations, payload);
        if (dataValue.message) {
            yield put(getAllSurveyTranslationsError(dataValue.message));
        } else {
            yield put(getAllSurveyTranslationsSuccess(dataValue));
        }
    } catch (error) {
        yield put(getAllSurveyTranslationsError(error));
    }
}

function* handleFetchAllTimezones() {
    const timeZone = yield call(httpFetchAllTimezones)
    yield put(getTimezoneListSuccess(timeZone))
}


/************************************************************************************************ receivers ************************************************************************************************/

/****
 Receivers : get all supported language
 ****/
export function* getSupportedLanguages() {
    yield takeEvery(TRANSLATION_SUPPORTED_LANG_GET_LIST, handleGetSupportedLanguage);
}

/****
 Receivers : supported language save
 ****/
export function* saveSupportedLanguage() {
    yield takeEvery(TRANSLATION_SUPPORTED_LANG_SAVE, handleSaveSupportedLanguage);
}

/****
 Receivers: Delete Saved Language
 ****/

export function* deleteSavedLanguage() {
    yield takeEvery(TRANSLATION_SUPPORTED_SAVED_LANG_DELETE, handleDeleteSavedSupportedLanguage)

}

export function* fetchAllSurveyTranslationsBySurveyId() {
    yield takeEvery(TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID, handleFetchAllSurveyTranslationsBySurveyId);
}

export function* fetchAllSurveyTranslationsByLanguage(){
    yield takeEvery(TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE, handleFetchAllSurveyTranslationsByLanguage);
}

export function* fetchAllSurveyTranslations(){
    yield takeEvery(TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS, handleFetchAllSurveyTranslations);
}

export function* fetchAllTimezones() {
    yield takeEvery(GET_TIMEZONE_LIST, handleFetchAllTimezones)
}


export default function* rootSaga() {
    yield all([
        fork(getSupportedLanguages),
        fork(saveSupportedLanguage),
        fork(deleteSavedLanguage),
        fork(fetchAllSurveyTranslationsBySurveyId),
        fork(fetchAllSurveyTranslationsByLanguage),
        fork(fetchAllSurveyTranslations),
        fork(fetchAllTimezones)
    ])
}