
import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import {baseUrl} from "../../../util/keys";
import {
    HTTP_TIMEOUT,
    URL_CONNECT_CASE_POPULARITY
} from "../../../constants/Urls";
import {
  CONNECT_API_GET_CASE_POPULARITY_REQUEST,
  CONNECT_API_GET_CASE_POPULARITY_SUCCESS,
  CONNECT_API_GET_CASE_POPULARITY_FAILURE
} from '../../../constants/ActionTypes';

// function that makes the api request and returns a Promise for response
const fetchConnectCasePopular = async ({payload}) =>
    await axios.post(baseUrl+URL_CONNECT_CASE_POPULARITY, payload,AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => error);


// worker saga: makes the api call when watcher saga sees the action
export function* connectGetCasePopularSaga(payload) {
    try {
      const response = yield call(fetchConnectCasePopular, payload);

      const {
        data,
        message
      } = response;
      if(data) {
        yield put({ type: CONNECT_API_GET_CASE_POPULARITY_SUCCESS, casePopularity:data });
      }
      if(message) {
        yield put({ type: CONNECT_API_GET_CASE_POPULARITY_FAILURE, error:message });
      }
    } catch (error) {
      // dispatch a failure action to the store with the error
      yield put({ type: CONNECT_API_GET_CASE_POPULARITY_FAILURE, error });
    }
  }

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* getCasePopularity() {
    yield takeEvery(CONNECT_API_GET_CASE_POPULARITY_REQUEST, connectGetCasePopularSaga);
}

