import IntlMessages from "../../../util/IntlMessages";
import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import commonStyles from "../../../util/commonStyle";

import {
    
    CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP, 
	CLIENT_PIC_GET_ALL_RECORDS_SUCCESS,
    CLIENT_PIC_HIDE_NEW_EDIT_POPUP, 
	CLIENT_PIC_NEW_EDIT_SAVE_ERROR,
    CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS,
    CLIENT_PIC_NEW_EDIT_SAVE_NONE,
    CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS, 
	CLIENT_PIC_UPDATE_STATUS_NONE, 
	CLIENT_PIC_UPDATE_STATUS_SUCCESS, 
} from "../../../constants/ActionTypes";

const clientPicTblHeader = [

    {
        id: 's_no',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_CLIENT_PIC_SL.NO"/>
    },

    {
        id: 'name',
        align: true,
        disablePadding: false,
        label: <IntlMessages id="DIC_SETTINGS_CLIENT_PIC_NEW_EDIT_NAME"/>
    },
    {
        id: 'email',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_CLIENT_PIC_NEW_EDIT_EMAIL"/>
    },
    {
        id: 'actions',
        align: false,
        disablePadding: true,
        label: <IntlMessages id="DIC_SETTINGS_CLIENT_PIC_ACTIONS"/>
    },
];

const clientPICItems = [
    {
        'key': 'name',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_CLIENT_PIC_NEW_EDIT_NAME",
        'isMandatory': true,
    },
    {
        'key': 'email',
        'type': 'textfield',
        'style': commonStyles.popupContainer,
        'localisation': "DIC_SETTINGS_CLIENT_PIC_NEW_EDIT_EMAIL",
        'isMandatory': true,

    },
    {
        'key': 'is_active',
        'type': 'checkbox',
        'style': {
            paddingBottom: "10%",
            marginTop: "1%",
            marginLeft: "-10px"
        },
        'localisation':
            "DIC_SETTINGS_CLIENT_PIC_NEW_EDIT_IS_ACTIVE"
    },
];

const initialData = {
    tableColumns: clientPicTblHeader,
    clientPICdata: [],
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    isPopUpVisible: false,
    selectedItem: null,
    clientPICSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientPICFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientPICUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientPICItems:clientPICItems

};

const clientPIC = (state = initialData, action) => {
    switch (action.type) {
        case CLIENT_PIC_UPDATE_STATUS_NONE:
            return {
                ...state,
                clientPICUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            };

        case CLIENT_PIC_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                clientPICUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message,
            };


        case CLIENT_PIC_GET_ALL_RECORDS_SUCCESS:
            return {
                ...state,
                clientPICFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                clientPICdata: action.payload.data
            };

        /**** client pic save error ****/
        case CLIENT_PIC_NEW_EDIT_SAVE_ERROR:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isErrorMessage: true,
                isSuccessMessage: false,
                isPopUpVisible: false,
                selectedItem: null,
                clientPICSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                loader: false
            };

        /**** client pic save success ****/
        case CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS:
            return {
                ...state,
                alertMessage: action.payload.data.message,
                isErrorMessage: false,
                isSuccessMessage: true,
                isPopUpVisible: false,
                selectedItem: null,
                clientPICSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false
            };

        /**** client pic http status to not started ****/
        case CLIENT_PIC_NEW_EDIT_SAVE_NONE:
            return {
                ...state,
                clientPICSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };

        /**** client pic http status to in progress ****/
        case CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS:
            return {
                ...state,
                clientPICSaveStatus : HTTP_REQUEST_STATUS.IN_PROGRESS
            };

        /**** to display clientPIC popup ****/
        case CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: true,
                selectedItem : action.payload
            };

        /**** to hide client pic popup ****/
        case CLIENT_PIC_HIDE_NEW_EDIT_POPUP:
            return {
                ...state,
                isPopUpVisible: false
            };

        default:
            return state;

    }
}

export default clientPIC;