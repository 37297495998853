/****
 To set current template or current survey for questions layout
 ****/
import {
    SURVEY_CLONE_HIDE,
    SURVEY_CLONE_SET_CLONE_TYPE,
    SURVEY_CLONE_SET_FILTER,
    SURVEY_CLONE_SHOW,
    SURVEY_CLONE_SURVEY_SAVE_ERROR,
    SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS,
    SURVEY_CLONE_SURVEY_SAVE_NONE,
    SURVEY_CLONE_SURVEY_SAVE_SUCCESS,
    SURVEY_CLONE_SURVEY_START,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE,
    SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS,
    SURVEY_CLONE_SURVEY_TEMPLATE_START,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_IN_PROGRESS,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE,
    SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS
} from "../../../constants/actions/survey/clone/actionTypesSurveyCloneHandler";

/****
 * To do survey template clone
 ****/
export function startCloneFromSurveyTemplate( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_TEMPLATE_START,
        payload : params
    };
}

/****
 * To set survey template clone status as in progress
 ****/
export function surveyTemplateCloneInProgress( ) {
    return {
        type: SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_IN_PROGRESS
    };
}

/****
 * To set survey template clone response status as to reset
 ****/
export function surveyTemplateCloneResponseReset( ) {
    return {
        type: SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_NONE
    };
}

/****
 * To set survey template clone responses status as success
 ****/
export function surveyTemplateCloneResponseSuccess( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS,
        payload : params
    };
}

/****
 * To set survey template clone response status as error
 ****/
export function surveyTemplateCloneResponseError( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_ERROR,
        payload : params
    };
}


/****
 * To do survey clone
 ****/
export function startCloneFromSurvey( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_START,
        payload : params
    };
}

/****
 * To set survey clone status as in progress
 ****/
export function surveyCloneInProgress( ) {
    return {
        type: SURVEY_CLONE_SURVEY_SAVE_IN_PROGRESS
    };
}

/****
 * To set survey clone response status as to reset
 ****/
export function surveyCloneResponseReset( ) {
    return {
        type: SURVEY_CLONE_SURVEY_SAVE_NONE
    };
}

/****
 * To set survey clone responses status as success
 ****/
export function surveyCloneResponseSuccess( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_SAVE_SUCCESS,
        payload : params
    };
}

/****
 * To set survey clone response status as error
 ****/
export function surveyCloneResponseError( params ) {
    return {
        type: SURVEY_CLONE_SURVEY_SAVE_ERROR,
        payload : params
    };
}

/****
 * To set clone view type
 ****/
export function setSurveyCloneFilter( params ) {
    return {
        type: SURVEY_CLONE_SET_FILTER,
        payload : params
    };
}

/****
 * To set clone view type
 ****/
export function setSurveyCloneType( params ) {
    return {
        type: SURVEY_CLONE_SET_CLONE_TYPE,
        payload : params
    };
}

/****
 * To hide the survey clone view
 ****/
export function hideSurveyCloneView() {
    return {
        type: SURVEY_CLONE_HIDE
    };
}

/****
 * To show the survey clone view
 ****/
export function showSurveyCloneView() {
    return {
        type: SURVEY_CLONE_SHOW
    };
}

/****
 * To do template clone from another template
 ****/
export function startTemplateCloneFromTemplate( params ) {
    return {
        type: SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE,
        payload : params
    };
}

/****
 * template clone from another template : in progress
 ****/
export function startTemplateCloneFromTemplateInProgress( ) {
    return {
        type: SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_IN_PROGRESS
    };
}

/****
 * template clone from another template:  to reset
 ****/
export function startTemplateCloneFromTemplateReset( ) {
    return {
        type: SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_NONE
    };
}

/****
 * template clone from another template : success
 ****/
export function startTemplateCloneFromTemplateSuccess( params ) {
    return {
        type: SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS,
        payload : params
    };
}

/****
 * template clone from another template : error
 ****/
export function startTemplateCloneFromTemplateError( params ) {
    return {
        type: SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_ERROR,
        payload : params
    };
}
