import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
     paySlipGetPaginatedListError,
     paySlipGetPaginatedListSuccess,
     updatePayslipSuccess,
     updatePayslipError,
     sendPayslipError,
     sendPayslipSuccess,
     verifyPayslipDataUploadedError,
     verifyPayslipDataUploadedSuccess,
     downloadTemplateAttachmentPayslipSuccess,
     downloadTemplateAttachmentPayslipError
} from "../../actions";
import {
     PAYSLIP_GET_PAGINATED_LIST,
     UPDATE_PAYSLIP,
     SEND_PAYSLIP,
     VERIFY_PAYSLIP_DATA_UPLOADED,
     NEW_PAYSLIP_ATTACHMENT_DOWNLOAD
} from "../../constants/actions/payslip/actionTypesPayslip";
import axios from "axios";
import {
     URL_PAY_SLIP_LIST,
     URL_SEND_PAYSLIP,
     URL_UPDATE_PAYSLIP,
     URL_PAYSLIP_VERIFY_DATA_UPLOADED,
     URL_PAYSLIP_DOWNLOAD_TEMPLATE
} from "../../constants/Urls";
import AppUtils from "../../util/util";
import { baseUrl } from "../../util/keys";
import { HTTP_STATUS_CODE } from "../../constants/config";

/************ methods : http *******************/

/** http : Pay Slip : to get paginated list **/
const httpGetPaySlipPaginatedList = async (url) => {
     return await axios.get(url, AppUtils.getHttpHeaders())
          .then(authUser => authUser)
          .catch(error => {
               return error
          });
};

/** http : update PaySlip */
const httpUpdatePayslip = async (url, data) => {
     return await axios.patch(url, data, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/** http : send PaySlip */
const httpSendPayslip = async (data) => {
     return await axios.post(baseUrl + URL_SEND_PAYSLIP, data, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => error);
};

/** http : Verify PaySlip Data Uploaded**/
const httpVerifyPayslipDataUploaded = async (data) => {
     return await axios.post(baseUrl + URL_PAYSLIP_VERIFY_DATA_UPLOADED, data, AppUtils.getHttpHeaders())
          .then(response => response)
          .catch(error => {
               return error;
          });
};

/** http : Donwload PaySlip Attachment Template**/
const httpDownloadNewPayslipTemplate = async (data) => {
     return await axios.post(baseUrl + URL_PAYSLIP_DOWNLOAD_TEMPLATE, data, AppUtils.getHttpHeaders())
          .then(authUser => authUser.data)
          .catch(error => {
               return error;
          });
};

/************ methods : http response handler *******************/

/** Pay Slip : to get paginated list **/
function* fetchPaySlipDataSaga({ payload }) {
     try {
          const { client_id, id, limit,  o, offset, query} = payload;

          let url = `${baseUrl + URL_PAY_SLIP_LIST}?client_id=${client_id}`;
          
          if (typeof o !== 'undefined') {
               url += `&o=${o}`;
          }

          if (typeof id !== 'undefined') {
               url += `&id=${id}`;
          }

          if (typeof limit !== 'undefined') {
               url += `&limit=${limit}`;
          }

          if (typeof offset !== 'undefined') {
               url += `&offset=${offset}`;
          }

          if (typeof query !== 'undefined' && query["PAYSLIP_STATUS_FILTER"] && query["PAYSLIP_STATUS_FILTER"] !== -1) {
               url += `&status=${query["PAYSLIP_STATUS_FILTER"]}`;
          }

          if (typeof query !== 'undefined' && query["PAYSLIP_FILTER_SEARCH_WORD"] && query["PAYSLIP_FILTER_SEARCH_WORD"] !== -1) {
               url += `&search=${encodeURIComponent(query["PAYSLIP_FILTER_SEARCH_WORD"])}`;
          }

          let result = yield call(httpGetPaySlipPaginatedList, url);

          if (result.message) {
               yield put(paySlipGetPaginatedListError(result.message));
          } else {

               let userTimeZone = AppUtils.getUserTimeZone();

               yield put(paySlipGetPaginatedListSuccess({data: result.data, timezone:userTimeZone}));              
          }
     } catch (error) {
          yield put(paySlipGetPaginatedListError(error));
     }
}

/**http : update payslip : : http response handler */
function* handleHttpUpdatePayslip({ payload }) {
     try {
          let url = baseUrl + URL_UPDATE_PAYSLIP;
          const payslipId = payload.get('payslipId');

          if (typeof payslipId !== 'undefined') {
               url += payslipId + '/';

               payload.delete('payslipId');
          }

          let response = yield call(httpUpdatePayslip, url, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(updatePayslipSuccess(response));
          } else {
               yield put(updatePayslipError(response));
          }
     } catch (error) {
          yield put(updatePayslipError(error));
     }
}

/**http : send payslip : : http response handler */
function* handleHttpSendPayslip({ payload }) {
     try {
          let response = yield call(httpSendPayslip, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(sendPayslipSuccess(response));
          } else {
               yield put(sendPayslipError(response));
          }
     } catch (error) {
          yield put(sendPayslipError(error));
     }
}

/** http : Verify PaySlip Data Uploaded : http response handler **/
function* handleHttpVerifyPaySlipDataUploaded({ payload }) {
     try {
          let result = yield call(httpVerifyPayslipDataUploaded, payload);

          if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
               yield put(verifyPayslipDataUploadedSuccess(result));
          } else {
               yield put(verifyPayslipDataUploadedError(result.message));
          }
     } catch (error) {
          yield put(verifyPayslipDataUploadedError(error));
     }
}

/** payslip : download payslip template **/
function* handleHttpPayslipDownloadTemplateAttachment({ payload }) {
     try {
          let result = yield call(httpDownloadNewPayslipTemplate, payload);

          if (result.status && result.status === HTTP_STATUS_CODE['SUCCESS']) {
               yield put(downloadTemplateAttachmentPayslipSuccess(result));
          } else {
               yield put(downloadTemplateAttachmentPayslipError(result.message));
          }
     } catch (error) {
          yield put(downloadTemplateAttachmentPayslipError(error));
     }
}

/************ methods : export access *******************/

export function* paySlipGetPaginatedList() {
     yield takeEvery(PAYSLIP_GET_PAGINATED_LIST, fetchPaySlipDataSaga);
}

/** * To update payslip based on the action item*/
export function* updatePayslip() {
     yield takeEvery(UPDATE_PAYSLIP, handleHttpUpdatePayslip)
}

/** * To send payslip*/
export function* sendPayslip() {
     yield takeEvery(SEND_PAYSLIP, handleHttpSendPayslip)
}

/**To verify Payslip Data Uploaded*/
export function* verifyPayslipDataUploaded() {
     yield takeEvery(VERIFY_PAYSLIP_DATA_UPLOADED, handleHttpVerifyPaySlipDataUploaded);
}

/** new payslip : download template attachment **/
export function* newPayslipDownloadTemplateAttachment() {
     yield takeEvery(NEW_PAYSLIP_ATTACHMENT_DOWNLOAD, handleHttpPayslipDownloadTemplateAttachment);
}

export default function* rootSaga() {
     yield all([
          fork(paySlipGetPaginatedList),
          fork(updatePayslip),
          fork(sendPayslip),
          fork(verifyPayslipDataUploaded),
          fork(newPayslipDownloadTemplateAttachment)
     ])
}