import {
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_NONE,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR,
    SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS
} from "../../../../constants/actions/survey/survey/actioTypesSurveyTemplate";

/****
 Survey Template reports : To Download the as uuid
 ****/

 export function surveyTemplateRawDataDownloadUUID(params) {
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID,
        payload : params
    };
}

/****
 Survey Template reports : To Download the as uuid : success
 ****/

export function surveyTemplateRawDataDownloadUUIDSuccess(data){
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_SUCCESS,
        payload: data
    };
}

/****
 Survey Template reports : To Download the uuid : error
 ****/
export function surveyTemplateRawDataDownloadUUIDError(params){
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_ERROR,
        payload: params
    }
}

/****
 Survey Template reports : To Download the uuid : in progress
 ****/
export function surveyTemplateRawDataDownloadUUIDInProgress(params){
    return{
        type:SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_IN_PROGRESS,
        payload: params
    };
}

/****
 Survey Template reports : To Download the uuid : None
 ****/
export function surveyTemplateRawDataDownloadUUIDNone(){
    return{
        type:SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_NONE
    };
}

/****
 Survey template reports : To Download the  as uuid
 ****/
 export function surveyTemplateRawDataDownloadUUIDData(params) {
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA,
        payload : params
    };
}

/****
 Survey template reports : To Download the  as uuid data : success
 ****/
export function surveyTemplateRawDataDownloadUUIDDataSuccess(data){
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_SUCCESS,
        payload: data
    };
}

/****
 Survey template reports : To Download the as uuid data : error
 ****/
export function surveyTemplateRawDataDownloadUUIDDataError(params){
    return {
        type: SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_ERROR,
        payload: params
    }
}

/****
 Survey template reports : To Download the uuid data: in progress
 ****/
export function surveyTemplateRawDataDownloadUUIDDataInProgress(params){
    return{
        type:SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_IN_PROGRESS,
        payload: params
    };
}

/****
 Survey template reports : To Download the  uuid data : None
 ****/
export function surveyTemplateRawDataDownloadUUIDDataNone(){
    return{
        type:SURVEY_TEMPLATE_RAW_DATA_DOWNLOAD_UUID_DATA_NONE
    };
}