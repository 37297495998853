/**** Surveys : get action ****/
import {
    SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS,
    SURVEY_RESPONSE_SAVE_RESPONSE,
    SURVEY_RESPONSE_SAVE_RESPONSE_ERROR,
    SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS,
    SURVEY_RESPONSE_SET_EMPLOYEE_ID,
    SURVEY_RESPONSE_UPDATE_LANGUAGE,
    SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS,
    SURVEY_RESPONSE_CHANGE_SURVEY_STATUS,
    SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS,
    SURVEY_RESPONSE_SET_CURRENT_CLIENT,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_NONE,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND,
    SURVEY_RESPONSE_SET_IDENTIFIER
} from "../../../constants/actions/survey/responses/actionTypesSurveyResponses";



/**** Surveys : save response action ****/
export function saveSurveyResponse(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE,
        payload: data
    };
}

/**** Surveys : save response action success****/
export function saveSurveyResponseSuccess(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS,
        payload: data
    };
}

/**** Surveys : save response action error****/
export function saveSurveyResponseError(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_ERROR,
        payload: data
    };
}

/**** Survey : to authenticate the survey user ****/
export function authenticateSurveyUser(data) {
    return {
        type: SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER,
        payload: data
    };
}


/**** Survey : to authenticate the survey user : success ****/
export function authenticateSurveyUserSuccess(data) {
    return {
        type: SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS,
        payload: data
    };
}

/**** Survey : to authenticate the survey user : Error ****/
export function authenticateSurveyUserError(data) {
    return {
        type: SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR,
        payload: data
    };
}

/**** Survey Response : reset the error messages status ****/
export function resetSurveyResponseMessages() {
    return {
        type: SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET
    };
}

/**** Survey Response : to set the error messages status ****/
export function setSurveyResponseMessages(data) {
    return {
        type: SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR,
        payload: data
    };
}

/**** Survey : update language ****/
export function changeSurveyResponsesLanguage(payload) {
    return {
        type: SURVEY_RESPONSE_UPDATE_LANGUAGE,
        payload: payload
    };
}

/**** Survey : to set current user employee id ****/
export function setSurveyResponseUserId(payload) {
    return {
        type: SURVEY_RESPONSE_SET_EMPLOYEE_ID,
        payload: payload
    };
}

/**** Survey : to set isCurrentLanguageFetched to false ****/
export function setCurrentLanguageFetchStatusNone() {
    return {
        type: SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS
    };
}

/**** Survey : to change survey status  to start ****/
export function startSurveyAfterWelcomePage() {
    return {
        type: SURVEY_RESPONSE_CHANGE_SURVEY_STATUS
    };
}

/**** Survey : To apply trasnlations for alert messages ****/
export function applyTranslationsForAlertMessages(payload) {
    return {
        type: SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS,
        payload: payload
    };
}

/**** survey : to set current client to reducer ****/
export function setSurveyResponseClient(data) {
    return {
        type: SURVEY_RESPONSE_SET_CURRENT_CLIENT,
        payload: data
    };
}

/**** survey response save background  ****/
export function saveSurveyResponseBackground(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND,
        payload: data
    };
}

/**** survey response save background : success****/
export function saveSurveyResponseBackgroundSuccess(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS,
        payload: data
    };
}

/**** survey response save background : error****/
export function saveSurveyResponseBackgroundError(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR,
        payload: data
    };
}

/**** survey response save background : none ****/
export function saveSurveyResponseBackgroundNone(data) {
    return {
        type: SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_NONE,
        payload: data
    };
}

/**** Survey : to set current user identifier ****/
export function setIdentifier(payload) {
    return {
        type: SURVEY_RESPONSE_SET_IDENTIFIER,
        payload: payload
    };
}