import IntlMessages from '../../../util/IntlMessages';
import React from 'react';
import { HTTP_REQUEST_STATUS } from '../../../constants/constants';
import {
	SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP,
	SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS,
	SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP,
	SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR,
	SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS,
	SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE,
	SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS,
	SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE,
	SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS,
	WINDOW_WIDTH,
} from '../../../constants/ActionTypes';
import { SURVEY_QUESTION_DROPDOWN_TYPES } from '../../../constants/config';
import commonStyles from '../../../util/commonStyle';
import AppUtils from '../../../util/util';

const questionTypeHeaders = [
	{
		id: 's_no',
		align: true,
		disablePadding: false,
		label: <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TBL_HEADER_S_NO" />,
	},
	{
		id: 'name',
		align: true,
		disablePadding: false,
		label: <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TBL_HEADER_NAME" />,
	},
	{
		id: 'name',
		align: true,
		disablePadding: false,
		label: <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TBL_HEADER_TYPE" />,
	},
	{
		id: 'actions',
		align: false,
		disablePadding: true,
		label: <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TBL_HEADER_ACTIONS" />,
	},
];

const questionTypeInputs = [
	{
		key: 'name',
		type: 'textfield',
		style: commonStyles.popupContainer,
		localisation: 'DIC_SURVEY_QUESTION_TYPES_NEW_EDIT_NAME',
		isMandatory: true,
	},
	{
		key: 'type',
		type: 'dropdown',
		style: commonStyles.popupContainer,
		localisation: 'DIC_SURVEY_QUESTION_TYPES_NEW_EDIT_TYPE',
		isMandatory: true,
		items: SURVEY_QUESTION_DROPDOWN_TYPES,
	},
	{
		key: 'is_active',
		type: 'checkbox',
		localisation: 'DIC_SURVEY_QUESTION_TYPES_NEW_EDIT_IS_ACTIVE',
		style: {
			paddingBottom: '10%',
			marginTop: '-10%',
		},
	},
];

const initialData = {
	tableColumns: questionTypeHeaders,
	questionTypeInputs: questionTypeInputs,
	surveyQuestionTypes: [],
	surveyQuestionTypesMap: {},
	loader: false,
	alertMessage: '',
	isSuccessMessage: false,
	isErrorMessage: false,
	isPopUpVisible: false,
	selectedItem: null,
	questionTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionTypeFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	questionTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
	windowWidth: 0,
};

const SurveyQuestionTypes = (state = initialData, action) => {
	switch (action.type) {
		case WINDOW_WIDTH:
			return {
				...state,
				windowWidth: action.width,
			};

		case SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE:
			return {
				...state,
				questionTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				alertMessage: '',
			};

		case SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS:
			return {
				...state,
				questionTypeUpdateStatusStatus: HTTP_REQUEST_STATUS.SUCCESS,
				alertMessage: action.payload.data.message,
			};

		case SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS:
			let questionTypes = parseSurveyQuestionTypes(action.payload.data);
			let surveyQuestionTypesMap = parseSurveyQuestionTypesMap(
				action.payload.data
			);

			return {
				...state,
				questionTypeFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				surveyQuestionTypes: questionTypes,
				surveyQuestionTypesMap: surveyQuestionTypesMap,
			};

		/**** question type save error ****/
		case SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR:
			return {
				...state,
				alertMessage: action.payload.data.message,
				isErrorMessage: true,
				isSuccessMessage: false,
				isPopUpVisible: false,
				selectedItem: null,
				questionTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				loader: false,
			};

		/**** question type save success ****/
		case SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS:
			return {
				...state,
				alertMessage: action.payload.data.message,
				isErrorMessage: false,
				isSuccessMessage: true,
				isPopUpVisible: false,
				selectedItem: null,
				questionTypeSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
				loader: false,
			};

		/**** question type http status to not started ****/
		case SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE:
			return {
				...state,
				questionTypeSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
				selectedItem: null,
			};

		/**** question type http status to in progress ****/
		case SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS:
			return {
				...state,
				questionTypeSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
			};

		/**** to display question type popup ****/
		case SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP:
			return {
				...state,
				isPopUpVisible: true,
				selectedItem: action.payload,
			};

		/**** to hide question type popup ****/
		case SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP:
			return {
				...state,
				isPopUpVisible: false,
			};

		default:
			return state;
	}
};

function parseSurveyQuestionTypes(payload) {
	let result = [];
	try {
		for (let i = 0; i < payload.data.length; i++) {
			for (let j = 0; j < SURVEY_QUESTION_DROPDOWN_TYPES.length; j++) {
				if (
					payload.data[i]['question_type'] ===
					SURVEY_QUESTION_DROPDOWN_TYPES[j]['typeValue']
				) {
					payload.data[i]['question_type_name'] =
						SURVEY_QUESTION_DROPDOWN_TYPES[j]['label'];
					break;
				}
			}
		}
		result = payload.data;
		result.sort(AppUtils.sortJSONArray('question_type'));
	} catch (e) {
		console.error(e);
	}

	return result;
}

function parseSurveyQuestionTypesMap(payload) {
	let result = {};

	try {
		for (let i = 0; i < payload.data.length; i++) {
			for (let j = 0; j < SURVEY_QUESTION_DROPDOWN_TYPES.length; j++) {
				if (
					payload.data[i]['question_type'] ===
					SURVEY_QUESTION_DROPDOWN_TYPES[j]['typeValue']
				) {
					result[payload.data[i]['question_type']] =
						SURVEY_QUESTION_DROPDOWN_TYPES[j]['label'];
					break;
				}
			}
		}
	} catch (ex) {
		console.error('parseSurveyQuestionTypesMap : error : ', ex);
	}

	return result;
}

export default SurveyQuestionTypes;
