import CryptoJS from "crypto-js";

import {
    URL_CLIENT_FETCH_NEW_DATA,
    URL_CLIENT_SAVE_NEW_CLIENT,
    URL_CLIENT_SAVE_CLIENT_LOGO,
    URL_CLIENT_ALL,
    URL_CLIENT_FILTERS_GET,
    URL_CLIENT_FILTERS_DATA,
    URL_CLIENT_CHANGE_STATUS,
    URL_PARTICIPANTS_SAVE_NEW_GROUP,
    URL_PARTICIPANTS_GROUPS_ALL,
    URL_PARTICIPANTS_DELETE_GROUP,
    URL_PARTICIPANTS_FIELD_CONFIG_ALL,
    URL_PARTICIPANTS_FIELD_CONFIG_SAVE_CUSTOM_FIELD,
    URL_PARTICIPANTS_FIELD_CONFIG_DELETE_CUSTOM_FIELD,
    URL_PARTICIPANTS_FIELD_CONFIG_MANDATORY_FIELD_CONFIG,
    URL_PARTICIPANTS_FORM_DATA,
    URL_E_LEARNING_REPORTS_ENROLMENT_GENERAL,
    URL_E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
    URL_E_LEARNING_REPORTS_ENROLMENT_TOPICS,
    URL_E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO, URL_E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE, URL_WOVO_DB_SYNC_CLIENTS, URL_CLIENT_LIST_ALL

} from '../constants/Urls'

import {
    baseUrl
} from '../util/keys'
import axios from "axios";

import {store} from '../MainApp';

const SERVER_BASE_URL = baseUrl;

const CONST_HTTP_CODE_SUCCESS_START = 200;
const CONST_HTTP_CODE_SUCCESS_END = 226;

const CONST_API_POST = "POST";
const CONST_API_GET = "GET";
const CONST_API_PATCH = "PATCH";
const CONST_API_PUT = "PUT";
const CONST_API_DELETE = "DELETE";

var AppCache = {

    userName: "",
    accessToken: "",
    refreshToken: "",
    refreshTimer: -1,
    isLoginSuccess: false,
    stages: null,
    welcomeScreenCallback: {},
    clients: {},

    getHeader: function (isAuthorization, message) {

        return {
            'Content-Type': 'application/json',
        }


    },

    getHeaderMultipart: function (isAuthorization, message) {
        return {
            'Content-Type': 'multipart/form-data',
        }
    },

    processRequest: function (request) {

        console.log("request url : " + request.url);
        console.log(request);

        fetch(request.url, request.content)
            .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then((responseJson) => {

                console.log("response : " + responseJson[0] + " : " + request.url);
                console.log(responseJson[1]);

                if (responseJson[0] >= CONST_HTTP_CODE_SUCCESS_START && responseJson[0] <= CONST_HTTP_CODE_SUCCESS_END) {
                    if (request.callback) {
                        request.callback(responseJson[1], null);
                    }
                } else {
                    let errorValue = this.getErrorString(responseJson[1]);

                    /*if ( __DEV__ ) {
                        console.log("query failed with error : " + errorValue );
                    }*/

                    if (request.callback) {
                        request.callback(null, errorValue);
                    }
                }

            }).catch((error) => {
            request.callback(null, error);
            console.error(error);
        });
    },

    getErrorString: function (errObject) {
        let errorString = "";
        for (let prop in errObject) {
            if (typeof errObject[prop] == "object") {
                errorString = this.getErrorString(errObject[prop]);
            } else if (typeof errObject[prop] == "string") {
                errorString = errObject[prop];
            }

            if (errorString != "") {
                break
            }
        }
        return errorString;
    },

    //Fetching new Client data
    getNewClientData: function (callback) {
        let content = {
            method: CONST_API_GET,
            headers: this.getHeader(true, "")
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_FETCH_NEW_DATA,
            content: content,
            callback: callback
        });
    },

    //Saving new Client data
    saveNewClient: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_SAVE_NEW_CLIENT,
            content: content,
            callback: callback
        });
    },

    //Saving new Client data
    saveClientLogo: function (params, callback) {

        axios.post(SERVER_BASE_URL + URL_CLIENT_SAVE_CLIENT_LOGO, params, {
            headers: this.getHeaderMultipart(true, "")
        })
            .then(res => {
                console.log(res.data);
                if (callback) {
                    callback(res.data, null);
                }
            })
            .catch(err => {
                if (callback) {
                    callback(null, err);
                }
            })

    },

    //get clients data
    getAllClients: function (callback) {

        let header = JSON.parse( JSON.stringify( store.getState().auth.bearerHeader));
        header['Content-Type'] = 'application/json';

        let content = {
            method: CONST_API_GET,
            headers: header
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_LIST_ALL,
            content: content,
            callback: callback
        });
    },

    //get clients filters data
    getClientFilters: function (callback) {
        let content = {
            method: CONST_API_GET,
            headers: this.getHeader(true, "")
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_FILTERS_GET,
            content: content,
            callback: callback
        });
    },

    //get clients filters data
    searchClients: function (params, callback) {

        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_FILTERS_DATA,
            content: content,
            callback: callback
        });
    },

    //get clients filters data
    changeClientStatus: function (params, callback) {

        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_CLIENT_CHANGE_STATUS,
            content: content,
            callback: callback
        });
    },

    //Saving contact group
    saveContactGroup: function (params, isPatch, callback) {

        let content = {
            method: isPatch ? CONST_API_PATCH : CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_SAVE_NEW_GROUP,
            content: content,
            callback: callback
        });

    },

    //contact groups : list
    getContactGroups: function (params, callback) {

        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_GROUPS_ALL,
            content: content,
            callback: callback
        });

    },

    //delete contact group
    deleteContactGroup: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_DELETE_GROUP,
            content: content,
            callback: callback
        });
    },

    //Fetch participants field config
    fetchContactFiledConfig: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_FIELD_CONFIG_ALL,
            content: content,
            callback: callback
        });
    },

    //Save custom field
    saveCustomField: function (params, isEdit, callback) {
        let content = {
            method: isEdit ? CONST_API_PATCH : CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_FIELD_CONFIG_SAVE_CUSTOM_FIELD,
            content: content,
            callback: callback
        });
    },

    //delete custom field
    deleteCustomField: function (params, callback) {
        let content = {
            method: CONST_API_DELETE,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_FIELD_CONFIG_DELETE_CUSTOM_FIELD,
            content: content,
            callback: callback
        });
    },

    //Save mandatory field config
    saveMandatoryFieldConfig: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_FIELD_CONFIG_MANDATORY_FIELD_CONFIG,
            content: content,
            callback: callback
        });
    },

    //Save mandatory field config
    getParticipantFormData: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_PARTICIPANTS_FORM_DATA,
            content: content,
            callback: callback
        });
    },

    //E Learning reports : user activity : ratio
    getELearningReportsUserActivityCompletionRatio: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
            content: content,
            callback: callback
        });
    },

    //E Learning reports : user activity : quiz score
    getELearningReportsUserActivityQuizScore: function (params, callback) {
        let content = {
            method: CONST_API_POST,
            headers: this.getHeader(true, ""),
            body: JSON.stringify(params)
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
            content: content,
            callback: callback
        });
    },


    //WOVO database sync : clients
    syncWoVoClientDetails: function (callback) {
        let content = {
            method: CONST_API_GET,
            headers: this.getHeader(true, "")
        };

        this.processRequest({
            url: SERVER_BASE_URL + URL_WOVO_DB_SYNC_CLIENTS,
            content: content,
            callback: callback
        });
    },


};

export default AppCache




