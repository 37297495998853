import {store} from "../../MainApp";
import {DEFAULT_LANGUAGE_CODE} from "../../constants/config";

const LanguageUtils = {
    
    /****
     * To get the translation string from server translations
     * @param languageCode
     * @param dicKey
     */
    getLocalisationByLanguageAndKey: function (languageCode, dicKey) {
        let result = dicKey;
        try {
            const state = store.getState()
            const settings = state.settings
            let remoteTranslations = settings.remoteTranslations;

            if (typeof remoteTranslations[languageCode] !== "undefined" && typeof remoteTranslations[languageCode]["data"] !== "undefined" && typeof remoteTranslations[languageCode]["data"][dicKey] !== "undefined") {
                result = remoteTranslations[languageCode]["data"][dicKey];
            } else if (typeof remoteTranslations[DEFAULT_LANGUAGE_CODE] !== "undefined" && typeof remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"] !== "undefined" && typeof remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"][dicKey] !== "undefined") {
                result = remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"][dicKey];
            }
            
        } catch (ex) {
            console.error("getLocalisationByLanguageAndKey : error : ", ex);
        }
        
        return result;
    },
    
    /****
     * To get the translation string from server translations
     * @param dicKey
     */
    getLocalisationByKey: function (dicKey) {
        let result = dicKey;
        try {
            let languageCode = store.getState().settings.locale.locale;
            let remoteTranslations = store.getState().settings.remoteTranslations;
            
            if (typeof remoteTranslations[languageCode] !== "undefined" && typeof remoteTranslations[languageCode]["data"] !== "undefined" && typeof remoteTranslations[languageCode]["data"][dicKey] !== "undefined") {
                result = remoteTranslations[languageCode]["data"][dicKey];
            } else if (typeof remoteTranslations[DEFAULT_LANGUAGE_CODE] !== "undefined" && typeof remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"] !== "undefined" && typeof remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"][dicKey] !== "undefined") {
                result = remoteTranslations[DEFAULT_LANGUAGE_CODE]["data"][dicKey];
            }
            
        } catch (ex) {
            console.error("getLocalisationByKey : error : ", ex);
        }
        
        return result;
    }
    
};

export default LanguageUtils;
