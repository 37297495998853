import {
    TRANSLATION_STRINGS_DOWNLOAD_ERROR,
    TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS,
    TRANSLATION_STRINGS_DOWNLOAD_NONE,
    TRANSLATION_STRINGS_DOWNLOAD_SUCCESS,
    TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR,
    TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS,
    TRANSLATION_STRINGS_UPLOAD_SAVE_NONE,
    TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS
} from "../../../../constants/actions/adminSettings/translations/strings/actionTranslationStrings";
import {HTTP_REQUEST_STATUS} from "../../../../constants/constants";
import AppUtils from "../../../../util/util";


let uploadPopupConfig = [
    {
        'key': 'supported_language',
        'type': 'dropdown',
        'style': { minWidth : "400px"},
        'labelClass': "",
        'localisation': "DIC_TRANSLATIONS_STRINGS_POPUP_LANGUAGE",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    }
];


let downloadPopupConfig = [
    {
        'key': 'supported_language',
        'type': 'dropdown',
        'style': { minWidth : "400px"},
        'labelClass': "",
        'localisation': "DIC_TRANSLATIONS_STRINGS_POPUP_LANGUAGE",
        'isMandatory': true,
        'containerClass': 'col-md-12 col-sm-12 col-12 m-0 mr-4 pl-0 pr-4'
    }
];

const initialData = {

    uploadPopupConfig : uploadPopupConfig,
    uploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessage : "",

    downloadPopupConfig : downloadPopupConfig,
    downloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,


};


const ReducerTranslationStrings = (state = initialData, action) => {


    switch (action.type) {

        /** translation file upload save success **/
        case TRANSLATION_STRINGS_UPLOAD_SAVE_SUCCESS:{

            let data = action.payload.data;
            let message =  data.message + " ( New : " + data.new + ", Update : " + data.update + " )";

            return {
                ...state,
                uploadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage : message
            }
        }

        /** translation file upload save error **/
        case TRANSLATION_STRINGS_UPLOAD_SAVE_ERROR:{

            return {
                ...state,
                uploadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage : action.payload.message
            }
        }

        /** translation file upload save in progress **/
        case TRANSLATION_STRINGS_UPLOAD_SAVE_IN_PROGRESS:{

            return {
                ...state,
                uploadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage : ""
            }
        }

        /** translation file upload save in progress **/
        case TRANSLATION_STRINGS_UPLOAD_SAVE_NONE:{

            return {
                ...state,
                uploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage : ""
            }
        }

        /** translation file download success **/
        case TRANSLATION_STRINGS_DOWNLOAD_SUCCESS:{

            let data = action.payload.data;
            AppUtils.downloadFile(data.url);

            return {
                ...state,
                downloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage : data.message
            }
        }

        /** translation file download error **/
        case TRANSLATION_STRINGS_DOWNLOAD_ERROR:{

            return {
                ...state,
                downloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage : action.payload.message
            }
        }

        /** translation file download in progress **/
        case TRANSLATION_STRINGS_DOWNLOAD_IN_PROGRESS:{

            return {
                ...state,
                downloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage : ""
            }
        }

        /** translation file download in progress **/
        case TRANSLATION_STRINGS_DOWNLOAD_NONE:{

            return {
                ...state,
                downloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage : ""
            }
        }

        default:
            return state;
    }

};

export default ReducerTranslationStrings;
